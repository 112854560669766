// @flow
/**
 * This page is used to perform Slack authentication and redirect to the right domain
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { integrationsSelectors } from "state/ducks/integrations";
import { searchedGoalsOperations } from "state/ducks/searchedGoals";
import * as squadTypes from "squadTypes";

// Components
import Loader from "v2/components/Loader";
import GoalBlockPage from "v2/components/GoalBlockPage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 0;
  letter-spacing: -0.16px;

  h2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.857143em;
    font-style: inherit;
    font-weight: 600;
    margin-top: 20px;
    text-transform: uppercase;
    color: rgb(94, 108, 132);
    line-height: 12px;
    overflow: hidden;
    margin-bottom: 16px;
  }

  form {
    max-width: 500px;
  }

  button {
    margin-bottom: 10px;
  }
`;

type Props = {
  currentIntegration: squadTypes.Integration,
  currentWorkspace: squadTypes.Workspace,
  location: Object,
  ui: Object,
  fetchJiraConnectGoals: Function,
  goals: squadTypes.NormalizedList<squadTypes.Goal>
};

class JiraConnectSettings extends Component<Props> {
  componentDidMount() {
    const { currentIntegration, location, fetchJiraConnectGoals } = this.props;

    let issueKey = "";

    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      issueKey = params.get("issue_key") || "";
    }

    // get the params from the URL
    if (currentIntegration && currentIntegration.workspace_id) {
      const jiraInstance = currentIntegration.public_meta.instance;

      // Transform the Jira Cloud instance URL to fit the remote id format
      // https://acme.atlassian.net should become acme_atlassian_net
      const instanceRemoteId = jiraInstance.replace("https://", "").replace(/\./g, "_");
      const remoteIssueId = `jira_cloud:${instanceRemoteId}:${issueKey}`;
      const params = {
        task_integration: remoteIssueId
      };
      fetchJiraConnectGoals(currentIntegration.workspace_id, params);
    }
  }

  render() {
    const { ui, currentIntegration, currentWorkspace, goals } = this.props;

    const jiraConnectAddonKey = process.env.REACT_APP_JIRA_CONNECT_ADDON_KEY || "";
    if (currentIntegration && !currentIntegration.workspace_id) {
      const jiraInstance = currentIntegration.public_meta.instance;
      return (
        <Container>
          <p>Your instance needs to be associated with a Tability workspace.</p>
          <a
            href={`${jiraInstance}/plugins/servlet/ac/${jiraConnectAddonKey}/configure`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Configure Tability
          </a>
        </Container>
      );
    }

    if (ui.isFetching || !currentWorkspace) {
      return (
        <Container>
          <Loader />
        </Container>
      );
    }

    if (ui.error) {
      return (
        <Container>
          <h2>Related goals</h2>
          <p>Sorry, we could not load the goals.</p>
          <p>
            Please make sure that you're logged into the workspace{" "}
            <a href={currentWorkspace.url} target="_blank" rel="noopener noreferrer">
              {currentWorkspace.name}
            </a>
            .
          </p>
        </Container>
      );
    }

    if (goals.hasFetchedListOnce && goals.allIds.length === 0) {
      return (
        <Container>
          <h2>Related goals</h2>
          <p>There are no goals linked to this issue.</p>
        </Container>
      );
    }

    return (
      <Container>
        <h2>Related goals</h2>
        {goals.allIds.map(goalId => {
          const goal = goals.byId[goalId];
          if (goal) {
            return (
              <GoalBlockPage
                isEmbedded={true}
                key={goal.id}
                workspace={currentWorkspace}
                goal={goal}
                maskActions={true}
                maskTasks={true}
              />
            );
          }
          return null;
        })}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { location } = props;
  let remoteId = null;
  let currentIntegration = null;
  let currentWorkspace = null;

  if (location && location.search) {
    const params = new URLSearchParams(location.search);
    remoteId = params.get("remote_id");

    currentIntegration = integrationsSelectors.getListByParam(state.integrations.byId, "remote_id", remoteId)[0];
    if (currentIntegration) {
      const workspaceId = currentIntegration.workspace_id;
      if (workspaceId) {
        currentWorkspace = state.workspaces.byId[workspaceId];
      }
    }
  }
  return {
    currentIntegration,
    currentWorkspace,
    goals: state.searchedGoals,
    ui: state.ui.jiraConnectGoals
  };
};

const mapDispatchToProps = {
  fetchJiraConnectGoals: searchedGoalsOperations.fetchJiraConnectGoals
};

export default connect(mapStateToProps, mapDispatchToProps)(JiraConnectSettings);
