//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 15 15" {...props}>
    <title id="Add-Plus-title">Add</title>
    <g id="Add-Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Add-Icon/Plus" transform="translate(-8.000000, -8.000000)" fill="#6A6A6A" fillRule="nonzero">
        <g id="Add-plus-square" transform="translate(8.000000, 8.000000)">
          <path
            d="M7.5,0 C8.01283584,0 8.43550716,0.38604019 8.49327227,0.883378875 L8.5,1 L8.5,14 C8.5,14.5522847 8.05228475,15 7.5,15 C6.98716416,15 6.56449284,14.6139598 6.50672773,14.1166211 L6.5,14 L6.5,1 C6.5,0.44771525 6.94771525,0 7.5,0 Z"
            id="Add-Shape"
          ></path>
          <path
            d="M14,6.5 C14.5522847,6.5 15,6.94771525 15,7.5 C15,8.01283584 14.6139598,8.43550716 14.1166211,8.49327227 L14,8.5 L1,8.5 C0.44771525,8.5 0,8.05228475 0,7.5 C0,6.98716416 0.38604019,6.56449284 0.883378875,6.50672773 L1,6.5 L14,6.5 Z"
            id="Add-Shape2"
          ></path>
        </g>
      </g>
    </g>
  </Logo>
);
