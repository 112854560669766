import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

export default function withAuthentication(WrappedComponent) {
  type Props = {
    isAuthenticated: boolean
  };

  const WithSignedInRedirect = (props): Props => {
    if (props.isAuthenticated) {
      const redirectAfterPath = props.redirectAfterPath;
      return <Redirect to={redirectAfterPath} />;
    }

    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = state => ({
    isAuthenticated: state.session.isAuthenticated,
    redirectAfterPath: state.session.redirectAfterPath
  });

  const mapDispatchToProps = {};

  return connect(mapStateToProps, mapDispatchToProps)(WithSignedInRedirect);
}
