//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1.8rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 22 20" {...props}>
    <title id="Report-TV-TV">TV mode</title>
    <g
      id="Report-TV-Icon-dump"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Report-TV-monitor" transform="translate(1.000000, 1.000000)" stroke="#6A6A6A" strokeWidth="2">
        <rect id="Report-TV-Rectangle-path" x="0" y="0" width="20" height="14" rx="2"></rect>
        <line x1="6" y1="18" x2="14" y2="18" id="Report-TV-Shape"></line>
        <line x1="10" y1="14" x2="10" y2="18" id="Report-TV-Shape2"></line>
      </g>
    </g>
  </Logo>
);
