// @flow
/**
 * This page is used to perform SAML authentication and redirect to the right domain
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import MobileDetect from "mobile-detect";
import { tabilityMobileUrl } from "config/services";

import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";

// Components
import { Link } from "react-router-dom";
import Loader from "v2/components/Loader";

const Container = styled.main`
  display: flex;
  justify-content: center;
  margin: 10vh 0 0 0;
  ion-icon {
    font-size: 2.2rem;
    padding-right: 0.8rem;
  }
`;

type Props = {
  clearPage: Function,
  magicLogin: Function,
  tokens: Object,
  ui: Object,
  location: Object
};

type State = {};

class GoogleAuth extends Component<Props, State> {
  componentDidMount() {
    const { location } = this.props;
    this.props.clearPage();
    // get the params from the URL

    if (location && location.search) {
      const params = new URLSearchParams(location.search);

      const state = params.get("state");

      const stateDecoded = state ? JSON.parse(atob(state)) : null;

      if (stateDecoded) {
        const data = stateDecoded;
        const magicToken = data["magic_token"];

        if (!magicToken) {
          return;
        }

        // Redirect to the app if we see that this login is happening on a mobile phone
        // There can be a case where it's happening on a mobile different from the one
        // existing for the user, but that should be rare.
        const md = new MobileDetect(window.navigator.userAgent);

        const isMobile = md.mobile();
        if (isMobile) {
          const mobileUrl = `${tabilityMobileUrl}?magic_token=${magicToken}`;
          window.location.replace(mobileUrl);
        } else {
          this.props.magicLogin(magicToken);
        }

        return;
      }
    }
  }

  render() {
    const { ui } = this.props;
    return (
      <Container>
        {!ui.isFetching && !ui.error && <div>Redirecting you.</div>}

        {!ui.isFetching && ui.error && (
          <div>
            <p>Sorry, we could not log you in.</p>
            <p>
              <Link to="/">Go back to login</Link>
            </p>
          </div>
        )}
        {ui.isFetching && <Loader />}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.session.isAuthenticated,
  tokens: state.session.tokens,
  ui: state.ui.googleAuth
});

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  magicLogin: sessionOperations.magicLogin
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuth);
