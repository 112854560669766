//@flow
import { request } from "state/utils";
import * as types from "./types";

export const createGoal = (sectionId: string, goalParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/sections/${sectionId}/goals`, { goal: goalParams }),
    payload: { goalParams }
  };
};

export const updateGoal = (goalId: string, goalParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/goals/${goalId}`, { goal: goalParams }),
    payload: { goalParams }
  };
};

export const moveGoal = (goalId: string, goalParams: Object) => {
  return {
    types: [types.MOVE_REQUESTED, types.MOVE_COMPLETED, types.MOVE_FAILED],
    callAPI: () => request.put(`/goals/${goalId}/move`, { goal: goalParams }),
    payload: {
      goalId,
      goalParams
    }
  };
};

export const deleteGoal = (goalId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/goals/${goalId}`),
    payload: { id: goalId }
  };
};

export const selectGoal = (goalId: string) => ({
  type: types.SELECT,
  selectedId: goalId
});

export const unSelectGoal = () => ({
  type: types.UNSELECT,
  selectedId: null
});

export const fetchGoalList = (sectionId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/sections/${sectionId}/goals?page=${page}`)
  };
};

export const fetchProjectGoalList = (projectId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/projects/${projectId}/goals?page=${page}`)
  };
};

export const fetchMembershipGoalList = (membershipId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/memberships/${membershipId}/goals?page=${page}`)
  };
};

export const fetchGoalDetails = (goalId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/goals/${goalId}`)
  };
};

export const resetGoalList = () => ({
  type: types.RESET_LIST
});
