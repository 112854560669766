//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { handleChange } from "v2/utils/forms";
import { tasksOperations } from "state/ducks/tasks";
import IconTask from "v2/components/_assets/IconTask.png";

// Components

import { spacing } from "v2/components/styles/spacing";

const Container = styled.div`
  form {
    margin: 0;
    display: flex;
    align-items: center;
  }
  input[type="text"] {
    padding: 0;
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #d1d1d1;
    width: auto;
    flex: 1;
    margin-left: ${spacing.x1};

    &:focus {
      border-radius: 0;
      border-left: 0;
      border-top: 0;
      border-right: 0;
    }
  }
  button {
    display: none;
  }

  &:focus-within {
    button {
      margin-top: ${spacing.x1};
      display: block;
      height: 3rem;
      line-height: 3rem;
    }
  }
`;

const TaskButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;

  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
`;

type Props = {
  createTask: Function,
  goal: squadTypes.Goal,
  roadmapState?: string,
  ui: Object
};

type State = {
  description: string
};

class TaskForm extends Component<Props, State> {
  focusInput: any;
  state = {
    description: ""
  };

  componentDidMount() {
    //this.focusInput.focus();
  }

  handleChange = e => handleChange(this, e);

  createTask = e => {
    e.preventDefault();
    const { goal } = this.props;
    const { description } = this.state;
    const roadmap_state = this.props.roadmapState ? this.props.roadmapState : "later";
    const params = {
      description,
      roadmap_state
    };

    return this.props.createTask(goal.id, params).then(() => {
      this.setState(
        {
          description: ""
        },
        () => {
          this.focusInput.focus();
        }
      );
    });
  };

  render() {
    const disabled = this.props.ui.isFetchingTasks;
    return (
      <Container>
        <form onSubmit={this.createTask}>
          <TaskButton bgImage={IconTask} />
          <input
            type="text"
            ref={input => {
              this.focusInput = input;
            }}
            onChange={this.handleChange}
            name="description"
            placeholder="Track your projects and action items"
            autoComplete="off"
            value={this.state.description}
            disabled={disabled}
          />
        </form>
        <button onClick={this.createTask} className="primary">
          Add
        </button>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  ui: state.ui.goalTasks
});

const mapDispatchToProps = {
  createTask: tasksOperations.createTask,
  updateTask: tasksOperations.updateTask,
  deleteTask: tasksOperations.deleteTask,
  resetTaskList: tasksOperations.resetTaskList
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
