// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { membershipsOperations } from "state/ducks/memberships";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import Avatar from "v2/components/Avatar";
import { Link } from "react-router-dom";
import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: normal;
  line-height: normal;
  font-weight: normal;

  th {
    color: ${colors.subtleText};
  }

  th,
  td {
    border: 0;
    border-collapse: collapse;
    text-align: left;
    margin: 0;
    padding: ${spacing.x1};
    vertical-align: middle;

    &.ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
    }

    ion-icon {
      font-size: 1.8rem;
    }

    &.center {
      text-align: center;
    }
  }

  td {
    big a {
      font-size: 1.8rem;
    }
  }

  tr {
    &.top td {
      vertical-align: top !important;
    }
  }

  .actions {
    min-width: 200px;
    text-align: right;
    white-space: nowrap;

    button,
    a {
      margin-left: ${spacing.x1};
    }

    a:hover {
      text-decoration: underline;
    }
  }
`;

const RoleLabel = styled.div`
  padding: ${spacing.x1} ${spacing.x2};
  background: ${colors.infoBg};
  text-transform: uppercase;
  display: inline-block;
  font-size: 1.2rem;
  border-radius: 4px;
  &.owner {
    background: ${colors.dangerBg};
  }
  &.admin {
    background: ${colors.warningBg};
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  fetchMembershipList: Function,
  memberships: squadTypes.NormalizedList<squadTypes.Membership>,
  showModal: Function,
  ui: Object
};

class WorkspaceSettingsMembershipList extends Component<Props> {
  componentDidMount() {
    this._fetchMore();
  }

  _fetchMore = () => {
    const { memberships } = this.props;
    const params = {
      page: memberships.currentPage + 1
    };

    return this.props.fetchMembershipList(params);
  };

  showInviteForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "membership",
      contentAction: "new"
    });
  };

  render() {
    const { currentWorkspace, memberships, ui } = this.props;
    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>Users</h1>
            <button className="primary" onClick={this.showInviteForm}>
              Invite users
            </button>
          </Header>
          <Main>
            {memberships.hasFetchedListOnce && <h3>{memberships.total} users</h3>}
            <Table>
              <tbody>
                <tr>
                  <th style={{ width: "5rem" }} />
                  <th>Name</th>
                  <th>Confirmed</th>
                  <th>Enabled</th>
                  {currentWorkspace.auth_type === "shared" && <th>2-factor auth</th>}
                  {currentWorkspace.slack_team_name && <th>Connected to Slack</th>}
                  <th>Role</th>
                  <th />
                </tr>
                {memberships.allIds.map(membershipId => {
                  const membership = memberships.byId[membershipId];
                  const { user } = membership;
                  return (
                    <tr key={membership.id}>
                      <td>
                        <Avatar user={membership.user} size={32} hideName={true} to={`/team/${membership.user_id}`} />
                      </td>
                      <td>
                        <Link to={`/settings/users/${membership.id}`}>
                          {membership.user.fullname || membership.user.email}
                        </Link>
                        <br />
                        <small className="light">{membership.user.email}</small>
                      </td>
                      <td className="center">
                        {membership.user.confirmed_at && (
                          <span role="img" aria-label="confirmed">
                            &#x2714;
                          </span>
                        )}
                        {!membership.user.confirmed_at && (
                          <span role="img" aria-label="pending">
                            &#x274c;
                          </span>
                        )}
                      </td>
                      <td className="center">
                        {membership.is_enabled && (
                          <span role="img" aria-label="confirmed">
                            &#x2714;
                          </span>
                        )}
                        {!membership.is_enabled && (
                          <span role="img" aria-label="pending">
                            &#x274c;
                          </span>
                        )}
                      </td>
                      {currentWorkspace.auth_type === "shared" && (
                        <td className="center">
                          {user.otp_required_for_login && (
                            <span role="img" aria-label="2-fa enabled">
                              &#x2714;
                            </span>
                          )}
                          {!user.otp_required_for_login && (
                            <span role="img" aria-label="2-fa disabled">
                              &#x274c;
                            </span>
                          )}
                        </td>
                      )}
                      {currentWorkspace.slack_team_name && (
                        <td className="center">
                          {membership.has_slack_id && (
                            <span role="img" aria-label="confirmed">
                              &#x2714;
                            </span>
                          )}
                          {!membership.has_slack_id && (
                            <span role="img" aria-label="pending">
                              &#x274c;
                            </span>
                          )}
                        </td>
                      )}
                      <td className="light">
                        <RoleLabel className={membership.role}>{membership.role}</RoleLabel>
                      </td>
                      <td className="actions">
                        <Link to={`/team/${membership.id}`}>View profile</Link>
                        <Link to={`/settings/users/${membership.id}`}>Edit</Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {memberships.hasNext && !ui.isFetchingGoals && (
              <button
                className="secondary"
                onClick={e => {
                  this._fetchMore();
                  e.preventDefault();
                }}
              >
                Load more
              </button>
            )}
            {(!memberships.hasFetchedListOnce || ui.isFetchingMembers) && <Loader />}
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  memberships: state.memberships,
  ui: state.ui.membershipSetting
});

const mapDispatchToProps = {
  fetchMembershipList: membershipsOperations.fetchMembershipList,
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettingsMembershipList);
