// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import { goalsSelectors } from "state/ducks/goals";
import { sectionsOperations } from "state/ducks/sections";
import { uiOperations } from "state/ducks/ui";

import { spacing } from "v2/components/styles/spacing";

const TitleContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: ${spacing.x1};
  h3 {
    font-size: 2.8rem;
    line-height: 2.8rem;
    font-weight: 500;
    color: #fff;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 4px;
  margin: ${spacing.x2} 0 ${spacing.x4} 0;
  flex: 1;
`;

const SectionProgress = styled.div`
  background-color: #373737;
  opacity: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 8rem;
  min-width: 8rem;
  height: 4rem;
  font-size: 2rem;
  border-radius: 20px;
  margin-right: ${spacing.x2};
  font-weight: 500;

  position: relative;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type Props = {
  children: any,
  currentGoal: squadTypes.Goal,
  fetchGoalList: Function,
  goalsCount: number,
  onDragEnd: Function,
  section: squadTypes.Section,
  sectionsCount: number,
  showModal: Function,
  updateSection: Function,
  goalItems: Array<squadTypes.Goal>,
  updateGoalItems: Function
};

type State = {
  displayGoalForm: Object
};

class Section extends Component<Props, State> {
  sectionTitleInput: any;

  state = {
    displayGoalForm: {},
    goalItems: []
  };

  handlePress = e => {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.sectionTitleInput.blur();
    }

    if (e.keyCode === 13) {
      e.preventDefault();
      this.sectionTitleInput.blur();
    }
  };

  updateSection = (e: Object) => {
    e.preventDefault();
    const { section } = this.props;
    const title = e.target.value;
    const params = {
      title
    };
    this.props.updateSection(section.id, params);
  };

  sectionTitle = () => {
    const { sectionsCount, section } = this.props;
    const progressPrct = Math.round(section.cached_prct_progress_normalized * 100);
    if (sectionsCount < 2) {
      return false;
    } else {
      return (
        <TitleContainer>
          <SectionProgress>
            <div>{progressPrct}%</div>
          </SectionProgress>

          <h3>{section.title}</h3>
        </TitleContainer>
      );
    }
  };

  render() {
    const { children, section } = this.props;
    if (section) {
      return (
        <SectionContainer>
          {this.sectionTitle()}
          <Content>{children}</Content>
        </SectionContainer>
      );
    } else {
      return false;
    }
  }
}

const mapStateToProps = (state, props) => ({
  currentGoal: goalsSelectors.getObjectById(state.goals.byId, props.match.params.goal),
  selectedGoal: goalsSelectors.getObjectById(state.goals.byId, state.goals.selectedId)
});

const mapDispatchToProps = {
  deleteSection: sectionsOperations.deleteSection,
  showModal: uiOperations.showModal,
  updateSection: sectionsOperations.updateSection
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section));
