/**
 * This is the page that manages permissions. Here's how it's supposed to work.
 *
 * Requirements:
 *   - Workspace has to be a paid workspace otherwise you can't edit permissions
 *   - User needs to have edit permissions (owner, admin, or have individual permissions)
 *
 * Public access for report view
 *   - You can decide to make reports accessible to everyone, or require login
 *
 * Workspace access
 *   - You can select everyone can view & edit -> this will remove existing user permissions
 *   - You can select everyone can view, or none:
 *       - This will let you add individual permissions to users
 *       - It will also make sure that you have edit permission
 *       - It will make sure that you can't kick yourself out
 *
 * Technical implementation of workspace access:
 *   - Only use update calls for projectOperations and projectPermissionsOperation
 *   - When you switch settings, the form should always:
 *       - Display yourself with edit permission if the global_permission for the project is view or none
 *       - Display the list of existing to begin with
 *       - Let you add more users as you see fit
 *   - New permissions should be applied on save.
 *
 */

// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import { projectPermissionsOperations } from "state/ducks/projectPermissions";
import { handleChange } from "v2/utils/forms";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import _ from "lodash";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

// Components
import AsyncSelect from "v2/components/AsyncSelect";
import FormField from "v2/components/FormField";
import FormActions from "v2/components/FormActions";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";
import IconLocked from "v2/components/svg/IconLocked";
import IconUnlocked from "v2/components/svg/IconUnlocked";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Loader from "v2/components/Loader";

const Container = styled.div`
  h3 {
    margin: 0 0 ${spacing.x2} 0;
  }

  p {
    padding: ${spacing.x2} 0 0 0;
  }

  label {
    font-weight: 600;
    display: inline-block;
    margin-top: ${spacing.x3};
  }

  .line-icon {
    height: 1.7rem;
    vertical-align: middle;
    filter: grayscale(0.7);
    margin-right: ${spacing.x1};
  }

  .email-select {
    margin: ${spacing.x1} 0;
  }
`;

const Info = styled.div`
  background-color: ${colors.infoBg};
  padding: ${spacing.x2};
  margin: 0 0 ${spacing.x2} 0;
  border-radius: 4px;
`;

const ToggleContainer = styled.div`
  padding-bottom: 1rem;

  .icon-container {
    display: flex;
    align-items: center;

    img {
      width: 1.6rem;
    }
  }

  svg {
    &.red {
      g {
        stroke: ${colors.red};
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    margin-bottom: ${spacing.x2};
  }

  select {
    margin: 0 ${spacing.x2};
    width: 30%;
  }

  label {
    margin: 0 0 0 ${spacing.x1};
    font-weight: normal;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x2};

  .select-container {
    width: 50%;
    margin-right: ${spacing.x2};
  }
  .action {
    margin-left: ${spacing.x2};
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace, // Workspace visited
  hideForm: Function, // Used to close the modal
  currentMembership: squadTypes.Membership, // Current membership (role, etc)
  currentProject: squadTypes.Project, // Current project
  currentUser: squadTypes.User, // User
  projectPermissions: squadTypes.NormalizedList<squadTypes.ProjectPermission>, // List of permissions in redux
  updateProject: Function,
  fetchProjectPermissionList: Function,
  resetProjectPermissionList: Function,
  ui: Object
};

type State = {
  global_permission_type: string,
  newMembershipsSelected: Array<any>,
  newPermissionType: string,
  permissions: Array<any>
};

class ProjectShareForm extends Component<Props, State> {
  state = {
    global_permission_type: this.props.currentProject.global_permission_type,
    newMembershipsSelected: [],
    newPermissionType: "edit",
    permissions: []
  };
  debouncedFetchMembershipsForAutocomplete: Function;
  constructor(props) {
    super(props);
    this.debouncedFetchMembershipsForAutocomplete = _.debounce(this._fetchMembershipsForAutocomplete, 300);
  }

  // Permissions are cached in state.permissions until we hit save.
  componentDidMount() {
    const {
      currentMembership,
      currentProject,
      currentUser,
      fetchProjectPermissionList,
      resetProjectPermissionList
    } = this.props;

    // Create a default edit permission for the current user. This will help prevent people from kicking themselves out.
    const permissions = [
      {
        membership_id: currentMembership.id,
        permission_type: "edit",
        membership_name: currentUser.fullname || currentUser.email
      }
    ];
    this.setState({ permissions });

    // Now fetch existing permissions to cache them
    resetProjectPermissionList();
    fetchProjectPermissionList(currentProject.id);
  }

  componentDidUpdate(prevProps) {
    const { projectPermissions } = this.props;

    const { permissions } = this.state;

    // Get list of existing ids in state.permissions to avoid duplicates
    const membershipIdsToIgnore = permissions.map(permission => permission.membership_id);

    // If we have loaded the permissions (did not have any before, and now have plenty!) then we cache them in state
    if (prevProps.projectPermissions.allIds.length === 0 && projectPermissions.allIds.length > 0) {
      // Filter the existing ids
      const newPermissionsIds = projectPermissions.allIds.filter(pId => {
        const perm = projectPermissions.byId[pId];
        return !membershipIdsToIgnore.includes(perm.membership_id);
      });

      // Return filtered perms
      const newPermissions = newPermissionsIds.map(pId => {
        const perm = projectPermissions.byId[pId];
        const user = perm.user;
        const permission = {
          membership_id: perm.membership_id,
          permission_type: perm.permission_type,
          membership_name: user.fullname || user.email
        };
        return permission;
      });

      // Merge with existing state.permissions
      const _permissions = permissions.concat(newPermissions);

      // Set the new state.permissions
      this.setState({ permissions: _permissions });
    }
  }

  handleChange = e => handleChange(this, e);

  _handleHideForm = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.hideForm();
  };

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchMembershipsForAutocomplete = (inputValue: ?string, callback: Function) => {
    const { permissions } = this.state;

    // Get list of existing IDs to avoid dupes
    const membershipIdsToIgnore = permissions.map(permission => permission.membership_id);
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const membershipsApiURL = `/workspaces/${slug}/memberships`;
    let membershipsParams = inputValue
      ? {
          name: inputValue
        }
      : null;
    request.get(membershipsApiURL, { params: membershipsParams }).then(response => {
      const allMemberships = response.data;

      // Filter out dupes
      const filteredMemberships = allMemberships.filter(membership => !membershipIdsToIgnore.includes(membership.id));
      if (filteredMemberships && filteredMemberships.length > 0) {
        const userOptions = filteredMemberships.map(result => {
          const user = result.user;
          return {
            value: result.id,
            label: user.fullname || user.email
          };
        });
        callback(userOptions);
      } else {
        callback();
      }
    });
    return;
  };

  _handleMembershipSelect = (options: any) => {
    this.setState({
      newMembershipsSelected: options
    });
  };

  // New permissions are added on top of the existing ones in state.permissions
  // We're using the list of memberships selected in the AsyncSelect and the newPermissionType in the select
  _handleAddPermissions = () => {
    const { newMembershipsSelected, newPermissionType, permissions } = this.state;

    // Get list of ids to filter out dupes
    const membershipIdsToIgnore = permissions.map(permission => permission.membership_id);
    const permissionsToAdd = [];
    newMembershipsSelected.forEach(membership => {
      // Avoid dupes
      if (!membershipIdsToIgnore.includes(membership.value)) {
        const permission = {
          membership_id: membership.value,
          membership_name: membership.label,
          permission_type: newPermissionType
        };
        permissionsToAdd.push(permission);
      }
    });

    const newPermissions = permissions.concat(permissionsToAdd);
    this.setState({
      permissions: newPermissions,
      newMembershipsSelected: []
    });
  };

  // Remove permission by filtering it out of the state
  _handleRemovePermission = e => {
    const membership_id = e.target.dataset.membership;
    const { permissions } = this.state;

    const newPermissions = permissions.filter(p => p.membership_id !== membership_id);

    this.setState({
      permissions: newPermissions
    });
  };

  // Update permission by changing its permission_type in state
  _handleUpdatePermission = e => {
    const membership_id = e.target.dataset.membership;
    const permission_type = e.target.value;
    const { permissions } = this.state;

    const newPermissions = permissions.map(_permission => {
      if (_permission.membership_id !== membership_id) {
        return _permission;
      } else {
        _permission.permission_type = permission_type;
        return _permission;
      }
    });

    this.setState({
      permissions: newPermissions
    });
  };

  // Saves permissions and resets the AsyncSelect
  _handleSave = e => {
    e.preventDefault();
    const { currentProject, updateProject } = this.props;
    const { global_permission_type } = this.state;
    let { permissions } = this.state;

    // Reset project_permissions if we're giving full permissions

    if (global_permission_type === "edit") {
      permissions = null;
    }
    const params = {
      global_permission_type,
      permissions
    };
    // TODO: handle errors
    updateProject(currentProject.id, params).then(() => {
      this._handleHideForm();
    });
  };

  render() {
    const { currentWorkspace, currentMembership, currentProject, ui } = this.props;
    const { global_permission_type, newPermissionType, newMembershipsSelected, permissions } = this.state;
    let submitText;
    if (ui.formState === "requesting") {
      submitText = <Loader size="small" />;
    } else {
      submitText = "Save";
    }

    const permissionsDisabled = currentWorkspace && !currentWorkspace.stripe_cache_subscription_plan;

    return (
      <Container className="modal-wrapper">
        <div className="modal-header">
          <h2>
            Manage access & permissions for <span className="light">{currentProject.title}</span>
          </h2>
          <ButtonIcon onClick={this.props.hideForm}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          {permissionsDisabled && (
            <div>
              <Info>
                {currentMembership.role === "owner" && <Link to="/settings/billing">Upgrade your plan</Link>}
                {currentMembership.role !== "owner" && <span>Upgrade your plan</span>} to manage permissions.
              </Info>
            </div>
          )}
          {permissionsDisabled && (
            <ReactTooltip id="permissionsDisabled" place="bottom" type="dark" effect="solid" delayShow={200}>
              You cannot edit permissions under the Free plan — please upgrade to edit permissions
            </ReactTooltip>
          )}
          <p>
            <b>Workspace access:</b> Control who can view and edit this page.
          </p>
          <ToggleContainer>
            <div data-tip data-for="permissionsDisabled">
              {global_permission_type === "edit" && <IconUnlocked />}
              {global_permission_type === "view" && <IconLocked />}
              {global_permission_type === "none" && <IconLocked className="red" />}
              <select
                name="global_permission_type"
                value={global_permission_type}
                className="auto"
                disabled={permissionsDisabled}
                onChange={this.handleChange}
              >
                <option value="edit">Anyone can view and edit</option>
                <option value="view">Editing restricted </option>
                <option value="none">Editing and viewing restricted</option>
              </select>
              {global_permission_type === "edit" && <span>All workspace users can view and edit the page.</span>}
              {global_permission_type === "view" && <span>All workspace users can view, some can edit.</span>}
              {global_permission_type === "none" && <span>Only some workspace users can view or edit.</span>}
            </div>
          </ToggleContainer>
          {global_permission_type !== "edit" && (
            <FormField>
              <Line>
                <div className="select-container">
                  <AsyncSelect
                    isMulti
                    placeholder="Type a name or email"
                    name="membership"
                    loadOptions={this.debouncedFetchMembershipsForAutocomplete}
                    defaultOptions={true}
                    className={`email-select`}
                    classNamePrefix="react-select"
                    value={newMembershipsSelected}
                    onChange={this._handleMembershipSelect}
                  />
                </div>
                <div>
                  {global_permission_type === "view" && <span>Edit</span>}
                  {global_permission_type === "none" && (
                    <select
                      name="newPermissionType"
                      value={newPermissionType}
                      onChange={this.handleChange}
                      className="auto"
                    >
                      <option value="edit">View and edit</option>
                      <option value="view">View</option>
                    </select>
                  )}
                </div>
                <div className="action">
                  <button onClick={this._handleAddPermissions}>Add</button>
                </div>
              </Line>
              <Line>
                <div className="select-container">
                  <span>Workspace users</span>
                </div>
                <div>
                  {global_permission_type === "view" && <span>View</span>}
                  {global_permission_type === "none" && <span>No access</span>}
                </div>
              </Line>
              {permissions.map((permission, index) => {
                if (permission.membership_id === currentMembership.id) {
                  return (
                    <Line key={index}>
                      <div className="select-container">
                        <span>{permission.membership_name}</span>
                      </div>
                      <div>
                        {global_permission_type === "view" && <span>Edit</span>}
                        {global_permission_type === "none" && <span>View and edit</span>}
                      </div>
                    </Line>
                  );
                }
                return (
                  <Line key={index}>
                    <div className="select-container">
                      <span>{permission.membership_name}</span>
                    </div>
                    <div>
                      {global_permission_type === "view" && <span>Edit</span>}
                      {global_permission_type === "none" && (
                        <select
                          className="auto"
                          name="user_permission"
                          value={permission.permission_type}
                          data-membership={permission.membership_id}
                          onChange={this._handleUpdatePermission}
                        >
                          <option value="edit">View and edit</option>
                          <option value="view">View</option>
                        </select>
                      )}
                    </div>
                    <div className="action">
                      <button
                        className="secondary"
                        data-membership={permission.membership_id}
                        onClick={this._handleRemovePermission}
                      >
                        Remove
                      </button>
                    </div>
                  </Line>
                );
              })}
            </FormField>
          )}
        </div>
        <div className="modal-footer">
          <FormActions>
            <button onClick={this._handleSave} className="primary">
              {submitText}
            </button>
            <button className="secondary" onClick={this.props.hideForm}>
              Cancel
            </button>
          </FormActions>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: state.session.currentUser,
  currentWorkspace: state.session.currentWorkspace,
  currentMembership: state.session.currentMembership,
  currentProject: projectsSelectors.getObjectById(state.projects.byId, props.project.id),
  projectPermissions: state.projectPermissions,
  ui: state.ui.projectSettingsPermissions
});

const mapDispatchToProps = {
  updateProject: projectsOperations.updateProject,
  fetchProjectPermissionList: projectPermissionsOperations.fetchProjectPermissionList,
  resetProjectPermissionList: projectPermissionsOperations.resetProjectPermissionList
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectShareForm);
