// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// V2 Components
import Loader from "v2/components/Loader";
import ProjectPanel from "./ProjectPanel";
import Toggle from "v2/components/Toggle";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${devices.tablet} {
    flex-direction: column;
  }
`;

const HeaderLeft = styled.div`
  .user-profile {
    display: flex;
    align-items: center;
    margin-top: ${spacing.x1};

    a {
      margin-right: ${spacing.x1};
    }
  }
`;

const HeaderRight = styled.div`
  @media ${devices.tablet} {
    margin-top: ${spacing.x1};
  }
`;

const PanelWrapper = styled.div`
  position: relative;
  margin-top: ${spacing.x4};
  flex: 1;
`;

const PanelContainer = styled.div`
  border-radius: 8px;
  flex: 1;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  border: 1px solid #d1d1d1;
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  showModal: Function,
  toggleArchived: Function,
  ui: Object
};

class ProjectList extends Component<Props> {
  _toggleShowArchived = (e: Object) => {
    this.props.toggleArchived();
  };

  render() {
    const { currentWorkspace, projects, ui } = this.props;
    const pagesCount = this.props.ui.showArchived
      ? currentWorkspace.projects_count
      : currentWorkspace.active_projects_count;

    const { selectedChildren } = ui; // Get the list of selected children from the redux state
    return (
      <WorkspaceLayout>
        <Container>
          <Header>
            <HeaderLeft>
              <h1>
                Pages ({pagesCount}) {ui.isFetching && <Loader size="small" />}
              </h1>
            </HeaderLeft>
            <HeaderRight>
              <Toggle>
                <input
                  className="tgl tgl-light"
                  id="cb1"
                  type="checkbox"
                  checked={this.props.ui.showArchived}
                  onChange={this._toggleShowArchived}
                />
                <label className="tgl-btn" htmlFor="cb1" aria-label="Show archived pages" />
                <div>Show archives</div>
              </Toggle>
            </HeaderRight>
          </Header>
          <PanelWrapper>
            <PanelContainer>
              {selectedChildren.map(projectId => {
                const project = projectId === "root" ? null : projects.byId[projectId];
                return <ProjectPanel key={`panel-${projectId}`} currentProject={project} />;
              })}
            </PanelContainer>
          </PanelWrapper>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  projects: state.projects,
  ui: state.ui.projectList
});

const mapDispatchToProps = {
  showModal: uiOperations.showModal,
  toggleArchived: uiOperations.toggleArchived
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
