// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

// Styles helpers
import styled from "styled-components";

// Ducks stuff
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";

// Utils
import { handleChange } from "v2/utils/forms";

// Library Components
import { Link } from "react-router-dom";

// Tability Components
import FormField from "v2/components/FormField";
import InlineMessage from "v2/components/InlineMessage";
import Loader from "v2/components/Loader";
import SessionLayout, { HelpLinks } from "v2/components/SessionLayout";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

type Props = {
  clearResetPassword: Function,
  resetPassword: Function,
  ui: Object
};

type State = {
  email: string
};

class ResetPassword extends Component<Props, State> {
  state = {
    email: ""
  };

  componentDidMount() {
    this.props.clearResetPassword();
    document.title = "Reset your password | Tability";
  }

  resetPassword = (e: Object) => {
    const { email } = this.state;

    const params = {
      email
    };

    this.props.resetPassword(params);

    e.preventDefault();
  };

  handleChange = e => handleChange(this, e);

  render() {
    const { ui } = this.props;
    return (
      <Container>
        <SessionLayout title="Reset your password">
          <InlineMessage message={ui.message} messageType={ui.messageType} />
          <form onSubmit={this.resetPassword}>
            <FormField>
              <input
                className="form-control"
                type="email"
                required
                autoComplete="off"
                aria-label="Add your email"
                placeholder="Email"
                name="email"
                onChange={this.handleChange}
              />
            </FormField>
            {!ui.isFetching && <button className="primary">Reset your password</button>}
            {ui.isFetching && (
              <button disabled className="primary">
                <Loader size="small" />
              </button>
            )}
          </form>
          <HelpLinks>
            <p>
              Already have an account? <Link to="/login">Log In</Link>
            </p>
          </HelpLinks>
        </SessionLayout>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.session.isAuthenticated,
  ui: state.ui.sessionResetPassword
});

const mapDispatchToProps = {
  resetPassword: sessionOperations.resetPassword,
  clearResetPassword: uiOperations.clearSessionResetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
