// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { accountOperations } from "state/ducks/account";
import { workspacesOperations } from "state/ducks/workspaces";
import { sessionOperations } from "state/ducks/session";
import * as squadTypes from "squadTypes";

import Loader from "v2/components/Loader";

import * as pages from "v2/pages";
import NotFound from "v2/components/NotFound";

const routes = [
  {
    path: "/workspaces/new",
    component: pages.WorkspaceNew,
    exact: true
  },
  {
    path: "/onboarding",
    component: pages.Onboarding,
    exact: true
  },
  {
    path: "/terms",
    component: pages.AcceptTerms,
    exact: true
  },
  {
    path: "/account",
    component: pages.AccountSettings,
    exact: true
  },
  {
    path: "/account/2fa",
    component: pages.AccountSettings2FA,
    exact: true
  },

  {
    path: "/account/email",
    component: pages.AccountSettingsEmail,
    exact: true
  },
  {
    path: "/account/delete",
    component: pages.AccountSettingsDelete,
    exact: true
  },
  {
    path: "/account/password",
    component: pages.AccountSettingsPassword,
    exact: true
  },
  {
    path: "/",
    component: pages.AppHome,
    exact: true
  },
  {
    component: NotFound
  }
];

type Props = {
  currentUser: squadTypes.User,
  fetchAccount: Function,
  fetchWorkspaceList: Function,
  isAuthenticated: boolean,
  location: Object,
  setRedirectAfterPath: Function,
  workspaces: squadTypes.NormalizedList<squadTypes.Workspace>
};

type State = {
  workspacesAreLoaded: boolean
};

class MainPrivateRoutes extends Component<Props, State> {
  state = {
    workspacesAreLoaded: false
  };
  componentDidMount() {
    const { fetchAccount, fetchWorkspaceList, setRedirectAfterPath } = this.props;

    // Set the redirection in case the user is logged out
    setRedirectAfterPath(window.location.pathname);

    // Fetch the account and the list of workspaces
    fetchAccount();
    fetchWorkspaceList().then(() => {
      this.setState({
        workspacesAreLoaded: true
      });
    });
  }

  render() {
    const { currentUser, isAuthenticated, location, workspaces } = this.props;
    const { workspacesAreLoaded } = this.state;

    // Return to signup if the user is not authenticated
    if (!isAuthenticated) {
      return <Redirect to="/signup" />;
    }

    if (!currentUser || !workspacesAreLoaded) {
      return <Loader />;
    }

    /**
     * At this stage we know that we have a user. We need now to decide whether
     * to show them the onboarding screens or let them navigate the app.
     *
     * We'll onboard them if their fullname is not set, or if they don't have
     * a membership yet as it means that they're aren't part of any workspace.
     */
    if (
      (!currentUser.fullname || workspaces.total === 0) &&
      location.pathname !== "/onboarding" && // Don't redirect in onboarding
      !location.pathname.includes("/account") && // Don't redirect on /account pages
      !location.pathname.includes("/settings") // Don't redirect on settings pages
    ) {
      return <Redirect to="/onboarding" />;
    }

    // Let's check if they have accepted the terms
    if (
      !currentUser.privacy_policy_accepted &&
      location.pathname !== "/terms" && // Don't redirect in terms
      location.pathname !== "/onboarding" && // Don't redirect in onboarding
      !location.pathname.includes("/account") && // Don't redirect on /account pages
      !location.pathname.includes("/settings") // Don't redirect on settings pages
    ) {
      // Redirect to onboarding so that the user can update their profile
      return <Redirect to="/terms" />;
    }

    // Ok! We reached the end here, which means that we can safely display the routes.
    return (
      <Switch>
        {routes.map(route => (
          <Route key={route.path || "notfound"} {...route} />
        ))}
      </Switch>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.session.currentUser,
    isAuthenticated: state.session.isAuthenticated,
    workspaces: state.workspaces
  };
};

const mapDispatchToProps = {
  fetchAccount: accountOperations.fetchAccount,
  fetchWorkspaceList: workspacesOperations.fetchWorkspaceList,
  setRedirectAfterPath: sessionOperations.setRedirectAfterPath
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPrivateRoutes));
