// @flow
import { createReducer } from "state/utils";
import * as sessionTypes from "state/ducks/session/types";

const INITIAL_STATE = {
  error: false,
  isFetching: false,
  isUnsubscribed: false,
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [sessionTypes.UNSUBSCRIBE_FAILED]: (state, action) => ({
    error: true,
    isFetching: false,
    isUnsubscribed: false,
    message: "Sorry, we could not change your notifications. Please contact support at support@tability.io.",
    messageType: "success"
  }),
  [sessionTypes.UNSUBSCRIBE_COMPLETED]: (state, action) => ({
    error: false,
    isFetching: false,
    isUnsubscribed: true,
    message: action.response.data.message,
    messageType: "success"
  }),
  [sessionTypes.UNSUBSCRIBE_REQUESTED]: () => ({
    error: false,
    isFetching: true,
    isUnsubscribed: false,
    message: ""
  })
});
