// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import * as squadTypes from "squadTypes";
import * as dateFormatUtils from "v2/utils/dateFormat";
import { uiOperations } from "state/ducks/ui";

// V2 styling
import { colors } from "v2/components/styles/colors";

import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryArea,
  VictoryLabel,
  VictoryLine,
  VictoryAxis,
  VictoryScatter,
  VictoryTooltip,
  VictoryTheme
} from "victory";

import IconCommentG from "v2/components/svg/IconCommentG";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  h3 {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    margin-top: 2.5rem;
    border-top: 1px solid #d4d4d4;
  }
`;

type CommentProps = {
  x?: number,
  y?: number,
  datum?: any
};

class CommentPoint extends Component<CommentProps> {
  render() {
    const { x, y, datum } = this.props;
    if (!datum || !x || !y) {
      return null;
    }
    const { statusUpdate } = datum;
    if (statusUpdate && statusUpdate.comments_count > 0) {
      return (
        <g transform={`translate(${x},${y - 26})`}>
          <IconCommentG />
        </g>
      );
    }
    return null;
  }
}

type Props = {
  goal: squadTypes.Goal,
  showModal: Function,
  statusUpdates: Array<squadTypes.StatusUpdate>
};

type State = {
  containerWidth: number
};

class UpdatesChart extends Component<Props, State> {
  state = {
    containerWidth: 590
  };
  container = null;

  componentDidMount() {
    if (this.container) {
      this.setState({
        containerWidth: this.container.offsetWidth
      });
    }
  }

  getColor = (reference: string) => {
    switch (reference) {
      case "grey":
        return {
          stroke: colors.grey,
          fill: colors.grey
        };
      case "red":
        return {
          stroke: colors.red,
          fill: colors.red
        };
      case "yellow":
        return {
          stroke: colors.yellow,
          fill: colors.yellow
        };
      case "green":
        return {
          stroke: colors.green,
          fill: colors.green
        };
      default:
        return {
          stroke: colors.grey,
          fill: colors.Grey
        };
    }
  };
  getChartData = () => {
    const { goal, statusUpdates } = this.props;

    const startDate = moment(goal.start_date);

    let currentData = [];
    let targetData = [];
    // Add the first point of the chart

    let currentFirstPoint, targetFirstPoint;

    // If we have a deadline we start at initial value
    if (goal.deadline) {
      currentFirstPoint = {
        x: startDate.toDate(),
        y: 0,
        timestamp: startDate.valueOf(),
        color: this.getColor("grey")
      };

      targetFirstPoint = {
        x: startDate.toDate(),
        y: 0,
        timestamp: startDate.valueOf()
      };
    } else {
      // If we don't have a deadline we'll trace a horizontal line at the target.
      currentFirstPoint = {
        x: startDate.toDate(),
        y: 0,
        timestamp: startDate.valueOf(),
        color: this.getColor("grey")
      };

      targetFirstPoint = {
        x: startDate.toDate(),
        y: 0,
        timestamp: startDate.valueOf()
      };
    }

    // We should only push the currentFirstPoint if we don't have a statusUpdateValue before this one.
    let addCurrentFirstPoint = true;
    const firstUpdate = statusUpdates[0];
    if (firstUpdate) {
      const pointDate = moment(firstUpdate.update_date).toDate();
      if (pointDate <= currentFirstPoint.x) {
        addCurrentFirstPoint = false;
      }
    }
    if (addCurrentFirstPoint) {
      currentData.push(currentFirstPoint);
    }
    targetData.push(targetFirstPoint);

    // If we have a deadline, add the last point for it.
    if (goal.deadline) {
      const deadlineDate = moment(goal.deadline);

      const targetLastPoint = {
        x: deadlineDate.toDate(),
        timestamp: deadlineDate.valueOf(),
        y: 0
      };

      targetData.push(targetLastPoint);
    }

    statusUpdates.forEach(update => {
      const pointDate = moment(update.update_date);
      const point = {
        x: pointDate.toDate(),
        timestamp: pointDate.valueOf(),
        y: 0,
        color: this.getColor(update.color),
        statusUpdate: update
      };

      currentData.push(point);
    });

    if (firstUpdate) {
      const pointDate = moment(firstUpdate.update_date);

      const targetPoint = {
        x: pointDate.toDate(),
        timestamp: pointDate.valueOf(),
        y: 0
      };

      if (moment(goal.deadline).valueOf() < targetPoint.timestamp) {
        targetData.push(targetPoint);
      }
    }

    // Add the last point
    const now = moment();
    // Add another point to flatten the target if we're past the deadline or
    // if we don't have a deadline
    if (!goal.deadline) {
      const flatTarget = {
        x: now.toDate(),
        timestamp: now.valueOf(),
        y: 0
      };
      targetData.push(flatTarget);
    }

    const currentDataSorted = currentData.sort((a, b): any => {
      return a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0;
    });

    const targetDataSorted = targetData.sort((a, b): any => {
      return a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0;
    });

    return {
      currentDataSorted,
      targetDataSorted
    };
  };

  showComments = statusUpdate => {
    this.props.showModal({
      contentType: "comments",
      contentAction: "view",
      object: statusUpdate,
      parent: this.props.goal,
      centered: false
    });
  };

  showGoalComments = () => {
    const { goal } = this.props;
    this.props.showModal({
      contentType: "goal.comments",
      contentAction: "view",
      object: goal,
      centered: false
    });
  };

  render() {
    const { goal } = this.props;
    if (!goal) {
      return false;
    }
    const data = this.getChartData();
    const currentData = data.currentDataSorted;
    const targetData = data.targetDataSorted;

    const ratio = 200 / 590;
    const chartWidth = this.state.containerWidth;
    const chartHeight = ratio * chartWidth;

    return (
      <Container innerRef={el => (this.container = el)}>
        <VictoryChart
          width={chartWidth}
          height={chartHeight}
          padding={{ top: 0, bottom: 0, left: 20, right: 20 }}
          domainPadding={{ x: [20, 20], y: 20 }}
          theme={VictoryTheme.material}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryAxis
            dependentAxis
            data={targetData}
            style={{
              axis: { stroke: "transparent" },
              ticks: { stroke: "transparent" },
              tickLabels: { fill: "transparent" }
            }}
            angle={45}
          />
          <VictoryAxis
            ticksCount={5}
            scale="time"
            data={targetData}
            tickLabelComponent={<VictoryLabel angle={45} dy={10} />}
            tickFormat={t => dateFormatUtils.formatShortDate(t)}
          />
          <VictoryGroup
            style={{
              data: { strokeWidth: 2 }
            }}
            scale="time"
            domain={{ y: [-1, 1] }}
          >
            <VictoryArea
              style={{
                data: {
                  fill: "#7E98E6",
                  fillOpacity: 0.1,
                  stroke: "none"
                }
              }}
              data={targetData}
            />
            <VictoryLine
              style={{
                data: {
                  stroke: "#7E98E6",
                  strokeWidth: 3
                }
              }}
              data={currentData}
              interpolation="linear"
            />
            <VictoryScatter data={currentData} dataComponent={<CommentPoint />} />

            <VictoryScatter
              data={currentData}
              size={5}
              labels={({ datum }) => {
                if (datum.color.stroke === colors.grey) {
                  return [dateFormatUtils.formatLabelDate(datum.x), `Start`];
                }
                if (datum.color.stroke === colors.red) {
                  return [dateFormatUtils.formatLabelDate(datum.x), `Off track`];
                }
                if (datum.color.stroke === colors.yellow) {
                  return [dateFormatUtils.formatLabelDate(datum.x), `At risk`];
                }
                if (datum.color.stroke === colors.green) {
                  return [dateFormatUtils.formatLabelDate(datum.x), `On track`];
                }
              }}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={4}
                  flyoutStyle={{
                    fill: "rgba(255, 255, 255, 1)",
                    stroke: colors.blockBorder
                  }}
                  flyoutHeight={80}
                  flyoutWidth={100}
                  labelComponent={
                    <VictoryLabel
                      lineHeight={2}
                      style={[
                        {
                          fontWeight: "600",
                          textTransform: "uppercase",
                          fill: colors.subtleText,
                          fontSize: "12px"
                        },
                        {
                          fontWeight: "400",
                          fill: colors.black,
                          fontSize: "16px",
                          marginBottom: 4
                        }
                      ]}
                    />
                  }
                />
              }
              style={{
                data: {
                  fill: ({ datum }) => {
                    return "#fff";
                  },
                  stroke: ({ datum }) => {
                    return datum.color.stroke;
                  },
                  strokeWidth: 3,
                  cursor: "pointer"
                }
              }}
              events={[
                {
                  target: "data",
                  eventHandlers: {
                    onClick: () => {
                      return [
                        {
                          target: "data",
                          mutation: props => {
                            const point = props.data[props.index];
                            if (props.index === 0) {
                              this.showGoalComments();
                            } else {
                              if (point && point.statusUpdate) {
                                this.showComments(point.statusUpdate);
                              }
                            }
                          }
                        }
                      ];
                    }
                  }
                }
              ]}
            />
          </VictoryGroup>
        </VictoryChart>
        <small className="subtle">Click on the dots to see the check-ins</small>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatesChart);
