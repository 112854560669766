//@flow
import { request } from "state/utils";
import * as types from "./types";
import { getSubdomain } from "state/utils/url";
import { tabilityAuthUrl } from "config/services";
import * as squadTypes from "squadTypes";

const slug = getSubdomain() || "";

export const createMembership = (membershipParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/workspaces/${slug}/memberships`, { membership: membershipParams }),
    payload: { membershipParams }
  };
};

export const updateMembership = (membershipId: string, membershipParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/memberships/${membershipId}`, { membership: membershipParams }),
    payload: { membershipParams }
  };
};

export const deleteMembership = (membershipId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/memberships/${membershipId}`),
    payload: { id: membershipId }
  };
};

export const fetchMembershipList = (params?: Object) => {
  const filters = [];
  if (params) {
    if (params.page) {
      filters.push(`page=${params.page}`);
    }
    if (params.name) {
      filters.push(`name=${params.name}`);
    }
  }
  const filtersQuery = filters.join("&");
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/workspaces/${slug}/memberships?${filtersQuery}`)
  };
};

export const fetchMembershipDetails = (membershipId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/memberships/${membershipId}`)
  };
};

export const resetMembershipList = () => ({
  type: types.RESET_LIST
});

export const resendConfirmationEmail = (credentials: squadTypes.Credentials) => ({
  types: [types.RESEND_CONFIRMATION_REQUESTED, types.RESEND_CONFIRMATION_COMPLETED, types.RESEND_CONFIRMATION_FAILED],
  callAPI: () => {
    if (!tabilityAuthUrl) {
      return false;
    }
    const body = {
      user: credentials
    };
    return request.post(`${tabilityAuthUrl}/users/confirmation`, body, {
      ignoreAuthHeaders: true
    });
  }
});

export const syncSlackAccount = (membershipId: string) => {
  return {
    types: [types.SYNC_SLACK_REQUESTED, types.SYNC_SLACK_COMPLETED, types.SYNC_SLACK_FAILED],
    callAPI: () => request.put(`/memberships/${membershipId}/slack`)
  };
};
