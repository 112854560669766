//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as squadTypes from "squadTypes";
import moment from "moment";
import numeral from "numeral";
import { goalsSelectors } from "state/ducks/goals";
import styled from "styled-components";

import { spacing } from "v2/components/styles/spacing";

// Components
import Avatar from "v2/components/Avatar";
import IconNotify from "v2/components/svg/IconNotify";

const GoalWarning = styled.div`
  position: absolute;
  right: -0.8rem;
  top: -0.8rem;

  svg {
    height: 2rem;
  }
`;

type Props = {
  goal: squadTypes.Goal,
  isSelected: boolean,
  className: string
};

class GoalLine extends Component<Props> {
  render() {
    const { goal } = this.props;
    const { owner } = goal;

    // This gets executed if displayGoalEditForm === false
    let progressScore = goal.cached_score;
    let prctProgress = 0;
    let progressWithFormat = "";
    let colorToString = "";
    let statusColor = "#ABABAB";

    switch (goal.cached_color) {
      case "red":
        colorToString = "Off track";
        statusColor = "#D0402E";
        break;
      case "yellow":
        colorToString = "At risk";
        statusColor = "#D3A627";
        break;
      case "green":
        colorToString = "On track";
        statusColor = "#408C76";
        break;
      default:
        colorToString = "Pending";
        statusColor = "#ABABAB";
        break;
    }

    // Set the progress score using the format if provided.
    if (goal.score_format && goal.target) {
      if (goal.cached_score || goal.cached_score === 0) {
        progressScore = numeral(goal.cached_score).format("0[.]00a");
        //progressScore = parseFloat(goal.cached_score).toLocaleString("en-US");
        prctProgress = goal.prct_target_normalized * 100;
        progressWithFormat = goal.score_format.replace("_target_", progressScore);
      } else {
        progressScore = "?";
      }
    }

    let statusString = progressWithFormat || colorToString;

    const Card = styled.div`
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: ${spacing.x2};
      margin: ${spacing.x2} ${spacing.x3} ${spacing.x2} 0;
      background-color: #373737;
      color: #fff;
      min-width: 500px;
      width: 500px;
      min-height: 300px;
      max-width: 500px;
      border-radius: 4px;

      .title-closed {
        text-decoration: line-through;
      }
    `;

    const titleFontSize = goal.title.length > 65 ? "2.2rem" : "2.8rem";

    const Title = styled.div`
      border-radius: 4px;
      background-color: ${statusColor};
      font-size: ${titleFontSize};
      flex: 3;
      padding: ${spacing.x2};
      font-weight: 400;
    `;

    const MetaContainer = styled.div`
      margin-top: ${spacing.x4};

      display: flex;
    `;

    const MetaWrapper = styled.div`
      flex: 1;
    `;

    const AvatarWrapper = styled.div`
      margin-left: ${spacing.x2};
    `;

    const Meta = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        color: #949494;
        font-size: 2rem;
      }
    `;

    const Progress = styled.div`
      height: 1rem;
      background-color: #151515;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: ${spacing.x1};
    `;

    const ProgressBar = styled.div`
      height: 1rem;
      width: ${Math.round(prctProgress)}%;
      border-radius: 5px;
      background-color: #a9a9a9;
      overflow: hidden;
    `;

    return (
      <Card>
        <Title className={`title-${goal.state}`}>{goal.title}</Title>
        <MetaContainer>
          <MetaWrapper>
            {(progressScore || progressScore === 0) && (goal.initial_value || goal.initial_value === 0) && (
              <Progress>
                <ProgressBar />
              </Progress>
            )}
            <Meta>
              <div>
                {goal.deadline && moment(goal.deadline).isAfter(moment()) && (
                  <span>
                    {moment(goal.deadline)
                      .fromNow(true)
                      .replace("a ", "1 ")}{" "}
                    left
                  </span>
                )}
                {goal.deadline && moment(goal.deadline).isBefore(moment()) && (
                  <span className={goal.state === "open" ? "danger" : ""}>Due {moment(goal.deadline).fromNow()}</span>
                )}
                {!goal.deadline && "-"}
              </div>
              {(progressScore || progressScore === 0) && (goal.initial_value || goal.initial_value === 0) && (
                <div>{statusString}</div>
              )}
            </Meta>
            {goal.is_pending_update && !goal.is_overdue && goal.state === "open" && (
              <GoalWarning role="img" aria-label="warning">
                <IconNotify />
              </GoalWarning>
            )}
          </MetaWrapper>
          <AvatarWrapper>
            <Avatar user={owner} to={`/team/${goal.membership_id}`} hideName={true} size={48} />
          </AvatarWrapper>
        </MetaContainer>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentGoal: goalsSelectors.getObjectById(state.goals.byId, props.match.params.goal),
  memberships: state.memberships
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoalLine));
