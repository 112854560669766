//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 16 19" {...props}>
    <title id="Page-title">Pages</title>
    <g
      id="Page-Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Page-sidebar" transform="translate(-21.000000, -183.000000)" stroke="#6A6A6A">
        <g id="Page-Group-17-Copy" transform="translate(13.000000, 32.000000)">
          <g id="Page-Projects" transform="translate(7.000000, 152.000000)">
            <g id="Page-file" transform="translate(2.000000, 0.000000)">
              <path
                d="M9,0 L1.625,0 C0.727537282,0 1.77635684e-15,0.761115925 1.77635684e-15,1.7 L1.77635684e-15,15.3 C1.77635684e-15,16.2388841 0.727537282,17 1.625,17 L12.375,17 C13.2724627,17 14,16.2388841 14,15.3 L14,5 L9,0 Z"
                id="Page-Shape"
                strokeWidth="2"
              />
              <polyline id="Page-Shape2" strokeWidth="2" points="9 0 9 5 14 5" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
