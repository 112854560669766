//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 20 22" {...props}>
    <title id="Lock-title">Locked</title>
    <g
      id="Lock-Icon-dump"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Lock-lock" transform="translate(1.000000, 1.000000)" stroke="#6A6A6A" strokeWidth="2">
        <rect id="Lock-Rectangle-path" x="0" y="9" width="18" height="11" rx="2"></rect>
        <path
          d="M4,9 L4,5 C4,2.23857625 6.23857625,8.8817842e-16 9,8.8817842e-16 C11.7614237,8.8817842e-16 14,2.23857625 14,5 L14,9"
          id="Lock-Shape"
        ></path>
      </g>
    </g>
  </Logo>
);
