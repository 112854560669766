//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg``;
export default (props: Object) => (
  <Logo viewBox="0 0 17 17" {...props}>
    <title id="title">Search</title>
    <g
      id="Buttons-and-Forms"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Search-Button" transform="translate(-10.000000, -9.000000)" stroke="#6A6A6A" strokeWidth="2">
        <g id="search" transform="translate(11.000000, 10.000000)">
          <circle id="Search-Oval" cx="6.66666667" cy="6.66666667" r="6.66666667"></circle>
          <path d="M15,15 L11.375,11.375" id="Search-Shape"></path>
        </g>
      </g>
    </g>
  </Logo>
);
