//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg``;
export default (props: Object) => (
  <Logo viewBox="0 0 22 22" {...props}>
    <g
      id="Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="user" transform="translate(1.000000, 1.000000)" stroke="#8089A2" strokeWidth="2">
        <path d="M16,18 L16,16 C16,13.790861 14.209139,12 12,12 L4,12 C1.790861,12 0,13.790861 0,16 L0,18" id="Shape" />
        <circle id="Oval" cx="8" cy="4" r="4" />
      </g>
    </g>
  </Logo>
);
