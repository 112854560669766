// @flow
import { createReducer } from "state/utils";
import * as notificationsTypes from "state/ducks/notifications/types";

const INITIAL_STATE = {
  isFetchingNotifications: true
};

export default createReducer(INITIAL_STATE)({
  [notificationsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    isFetchingNotifications: false
  }),
  [notificationsTypes.FETCH_LIST_COMPLETED]: state => ({
    isFetchingNotifications: false
  }),
  [notificationsTypes.FETCH_LIST_REQUESTED]: state => ({
    isFetchingNotifications: true
  })
});
