//@flow
import * as workspaceTypes from "./types";
import { checkCurrentWorkspace } from "state/ducks/session/actions";

import {
  cancelSubscription,
  fetchSubscriptionDetails,
  updateSubscription,
  updateCardDetails,
  deleteCardDetails
} from "./actions";

const updateAndFetchSubscription = (workspaceParams: Object) => (dispatch: Function) => {
  dispatch(updateSubscription(workspaceParams)).then(action => {
    if (action.type === workspaceTypes.UPDATE_SUBSCRIPTION_COMPLETED) {
      dispatch(fetchSubscriptionDetails());
      dispatch(checkCurrentWorkspace());
    }
  });
};

const updateCardAndFetchSubscription = (workspaceParams: Object) => (dispatch: Function) => {
  dispatch(updateCardDetails(workspaceParams)).then(action => {
    if (action.type === workspaceTypes.UPDATE_STRIPE_COMPLETED) {
      dispatch(fetchSubscriptionDetails());
      dispatch(checkCurrentWorkspace());
    }
  });
};

const cancelAndFetchSubscription = () => (dispatch: Function) => {
  dispatch(cancelSubscription()).then(action => {
    if (action.type === workspaceTypes.CANCEL_SUBSCRIPTION_COMPLETED) {
      dispatch(fetchSubscriptionDetails());
      dispatch(checkCurrentWorkspace());
    }
  });
};

const addCardAndSubscription = (tokenParams: Object, subscriptionParams: Object) => (dispatch: Function) => {
  dispatch(updateCardDetails(tokenParams)).then(action => {
    if (action.type === workspaceTypes.UPDATE_STRIPE_COMPLETED) {
      dispatch(updateSubscription(subscriptionParams)).then(action => {
        if (action.type === workspaceTypes.UPDATE_SUBSCRIPTION_COMPLETED) {
          dispatch(fetchSubscriptionDetails());
          dispatch(checkCurrentWorkspace());
        }
      });
    }
  });
};

const deleteCardAndFetchWorkspace = () => (dispatch: Function) => {
  dispatch(deleteCardDetails()).then(action => {
    if (action.type === workspaceTypes.DELETE_STRIPE_CARD_COMPLETED) {
      dispatch(fetchSubscriptionDetails());
      dispatch(checkCurrentWorkspace());
    }
  });
};

export {
  cancelSubscription,
  cancelAndFetchSubscription,
  updateSubscription,
  updateAndFetchSubscription,
  updateCardAndFetchSubscription,
  addCardAndSubscription,
  deleteCardAndFetchWorkspace
};

export * from "./actions";
