import React, { Component } from "react";
import { connect } from "react-redux";
import { projectsOperations } from "state/ducks/projects";
import * as projectTypes from "state/ducks/projects/types";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import { push } from "react-router-redux";

// Components
import DropdownMenu from "v2/components/DropdownMenu";
import { Link } from "react-router-dom";
import IconMenu from "v2/components/svg/IconMenu";
import ButtonIcon from "v2/components/ButtonIcon";

import { spacing } from "v2/components/styles/spacing";

type Props = {
  currentProject: squadTypes.Project,
  updateProject: Function,
  push: Function,
  showModal: Function
};

class ProjectMenu extends Component<Props> {
  archiveProject = e => {
    e.preventDefault();
    const { currentProject } = this.props;
    if (window.confirm(`Are you sure you want to archive this page? This action will also archive all its sub-pages`)) {
      const params = {
        is_archived: true
      };
      this.props.updateProject(currentProject.id, params);
    }
  };

  unarchiveProject = e => {
    e.preventDefault();
    const { currentProject } = this.props;
    if (
      window.confirm(
        `Are you sure you want to un-archive this page? This action will also un-archive all its sub-pages`
      )
    ) {
      const params = {
        is_archived: false
      };
      this.props.updateProject(currentProject.id, params);
    }
  };

  copyProject = e => {
    e.preventDefault();
    const { currentProject } = this.props;

    this.props.copyProject(currentProject.id).then(action => {
      if (action.type === projectTypes.COPY_COMPLETED) {
        const copiedProject = action.response.data;
        this.props.push(`/pages/${copiedProject.id}`);
      }
    });
  };

  showTrends = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "trends",
      object: this.props.currentProject
    });
  };

  _showShareModal = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "share",
      object: this.props.currentProject
    });
  };

  getMenuItems = () => {
    const { currentProject } = this.props;
    const items = [];
    if (currentProject.current_user_permission === "edit") {
      items.push(
        <div>
          <Link to={`/pages/${currentProject.id}/settings`}>Page settings</Link>
        </div>
      );
      items.push(
        <button className="link-button" data-id={currentProject.id} onClick={this.copyProject}>
          Duplicate
        </button>
      );
      if (!currentProject.is_archived) {
        items.push(
          <button className="link-button" data-id={currentProject.id} onClick={this.archiveProject}>
            Archive
          </button>
        );
      } else {
        items.push(
          <button className="link-button" data-id={currentProject.id} onClick={this.unarchiveProject}>
            Un-archive
          </button>
        );
      }
    }

    return items;
  };

  render() {
    return (
      <DropdownMenu
        trigger={
          <ButtonIcon style={{ marginLeft: `${spacing.x1}` }}>
            <IconMenu />
          </ButtonIcon>
        }
        items={this.getMenuItems()}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  push,
  showModal: uiOperations.showModal,
  updateProject: projectsOperations.updateProject,
  copyProject: projectsOperations.copyProject
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMenu);
