//@flow
import { request } from "state/utils";
import axios from "axios";
import { tabilityAuthUrl } from "config/services";
import * as types from "./types";

export const fetchAccount = () => {
  // Get the current subdomain and passes it to the request
  const subdomain = window.location.host.split(".")[0];
  return {
    types: [types.FETCH_ACCOUNT_REQUESTED, types.FETCH_ACCOUNT_COMPLETED, types.FETCH_ACCOUNT_FAILED],
    callAPI: () => request.get(`/me?from=${subdomain}`)
  };
};

export const fetchAssetPostUrl = () => {
  return {
    types: [
      types.FETCH_ASSET_POST_URL_REQUESTED,
      types.FETCH_ASSET_POST_URL_COMPLETED,
      types.FETCH_ASSET_POST_URL_FAILED
    ],
    callAPI: () => request.get(`/me/asset_post_url`)
  };
};

// Upload data to S3
export const uploadAsset = (file: string, assetPostUrl: Object) => {
  // Preparing the form data
  const { cloudinary_signature, cloudinary_params } = assetPostUrl;
  const data = new FormData();

  // Set all the keys from the assetPostUrl Object. These keys are required
  // to sign the URL and be able to post to S3
  Object.keys(cloudinary_params).forEach(k => {
    const v = cloudinary_params[k];
    data.append(k, v);
  });
  data.append("signature", cloudinary_signature);

  // Add the file
  data.append("api_key", process.env.REACT_APP_CLOUDINARY_API_KEY || "123");

  const cloudinary_cloud = process.env.REACT_APP_CLOUDINARY_CLOUD || "tability";

  const cloudinary_upload_url = `https://api.cloudinary.com/v1_1/${cloudinary_cloud}/image/upload`;

  data.append("file", file);

  // Return the API call
  return {
    types: [types.UPLOAD_ASSET_REQUESTED, types.UPLOAD_ASSET_COMPLETED, types.UPLOAD_ASSET_FAILED],
    callAPI: () => axios.post(cloudinary_upload_url, data, { responseType: "text" })
  };
};

export const updateAccount = (userParams: Object) => {
  return {
    types: [types.UPDATE_ACCOUNT_REQUESTED, types.UPDATE_ACCOUNT_COMPLETED, types.UPDATE_ACCOUNT_FAILED],
    callAPI: () => request.put(`/me`, { user: userParams }),
    payload: { userParams }
  };
};

export const updatePassword = (userParams: Object) => {
  return {
    types: [
      types.UPDATE_ACCOUNT_PASSWORD_REQUESTED,
      types.UPDATE_ACCOUNT_PASSWORD_COMPLETED,
      types.UPDATE_ACCOUNT_PASSWORD_FAILED
    ],
    callAPI: () => request.put(`${tabilityAuthUrl}/users`, { user: userParams }),
    payload: { userParams }
  };
};

export const deleteAccount = () => {
  return {
    types: [types.DELETE_ACCOUNT_REQUESTED, types.DELETE_ACCOUNT_COMPLETED, types.DELETE_ACCOUNT_FAILED],
    callAPI: () => request.delete(`${tabilityAuthUrl}/users`)
  };
};

export const create2FA = () => {
  // Get the current subdomain and passes it to the request
  return {
    types: [types.CREATE_2FA_REQUESTED, types.CREATE_2FA_COMPLETED, types.CREATE_2FA_FAILED],
    callAPI: () => request.post(`/me/2fa`)
  };
};

export const update2FA = (userParams: Object) => {
  return {
    types: [types.UPDATE_2FA_REQUESTED, types.UPDATE_2FA_COMPLETED, types.UPDATE_2FA_FAILED],
    callAPI: () => request.put(`/me/2fa`, { user: userParams }),
    payload: { userParams }
  };
};

export const create2FABackupCodes = (userParams: Object) => {
  return {
    types: [
      types.CREATE_2FA_BACKUP_CODES_REQUESTED,
      types.CREATE_2FA_BACKUP_CODES_COMPLETED,
      types.CREATE_2FA_BACKUP_CODES_FAILED
    ],
    callAPI: () => request.post(`/me/2fa/codes`, { user: userParams }),
    payload: { userParams }
  };
};
