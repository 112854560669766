//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { goalsOperations } from "state/ducks/goals";
import { linksOperations, linksSelectors } from "state/ducks/links";
import moment from "moment";

// Components
import LinkForm from "./LinkForm";
import IconAsanaPng from "v2/components/_assets/IconAsana.png";
import IconBitbucketPng from "v2/components/_assets/IconBitbucket.png";
import IconConfluencePng from "v2/components/_assets/IconConfluence.png";
import IconDropboxPng from "v2/components/_assets/IconDropbox.png";
import IconGithubPng from "v2/components/_assets/IconGithub.png";
import IconGoogleDrivePng from "v2/components/_assets/IconGoogleDrive.png";
import IconJiraPng from "v2/components/_assets/IconJira.png";
import IconLinkPng from "v2/components/_assets/IconLink.png";
import IconNotionPng from "v2/components/_assets/IconNotion.png";
import IconSlackPng from "v2/components/_assets/IconSlack.png";
import IconTrelloPng from "v2/components/_assets/IconTrello.png";
import IconTwitterPng from "v2/components/_assets/IconTwitter.png";
import IconMenu from "v2/components/svg/IconMenu";
import IconPlus from "v2/components/svg/IconPlus";
import DropdownMenu from "v2/components/DropdownMenu";
import Loader from "v2/components/Loader";
import ButtonIcon from "v2/components/ButtonIcon";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

const Container = styled.div`
  label {
    font-weight: 600;
    margin-bottom: ${spacing.x1};
    display: inline-block;
  }

  .header {
    display: flex;

    .action {
      display: none;
    }

    &:hover .action {
      display: inline-block;
    }
  }
`;

const PrimaryLinkSeparator = styled.div`
  margin-bottom: ${spacing.x2};
  background-color: #f4f4f4;
  height: 2px;
`;

const Link = styled.div`
  margin-bottom: ${spacing.x2};
  display: flex;

  &:hover .actions {
    opacity: 1;
  }
  .icon {
    display: block;
    background-color: #f2f2f2;
    border-radius: 4px;
    min-width: 4rem;
    min-height: 4rem;
    width: 4rem;
    height: 4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 2.4rem;
    }
  }

  .content {
    margin: 0 ${spacing.x2};
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;

    a.header {
      color: ${colors.black};
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    small {
      white-space: nowrap;
    }
  }

  .actions {
    opacity: 0;
    transition: all ease 0.3s;
  }
`;

type Props = {
  createLink: Function,
  currentLinks: Array<squadTypes.Link>,
  deleteLink: Function,
  deleteReaction: Function,
  fetchLinkList: Function,
  goal: squadTypes.Goal,
  links: Object,
  resetLinkList: Function,
  ui: Object,
  updateGoal: Function
};

type State = {
  displayLinkInput: string,
  url: string,
  title: ?string
};

class Links extends Component<Props, State> {
  focusInput: any;
  state = {
    displayLinkInput: "",
    url: "",
    title: ""
  };

  componentDidMount() {
    const { goal, fetchLinkList, resetLinkList } = this.props;
    resetLinkList();
    fetchLinkList(goal.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.goal.id !== prevProps.goal.id) {
      const { goal, fetchLinkList, resetLinkList } = this.props;
      resetLinkList();
      fetchLinkList(goal.id);
    }
  }

  _getLinkIcon = (link: squadTypes.Link) => {
    const url = link.url;
    let icon = IconLinkPng;

    switch (true) {
      case /asana.com/.test(url):
        icon = IconAsanaPng;
        break;
      case /bitbucket.org/.test(url):
        icon = IconBitbucketPng;
        break;
      case /atlassian.net\/wiki/.test(url):
        icon = IconConfluencePng;
        break;
      case /dropbox.com/.test(url):
        icon = IconDropboxPng;
        break;
      case /github.com/.test(url):
        icon = IconGithubPng;
        break;
      case /docs.google.com/.test(url):
        icon = IconGoogleDrivePng;
        break;
      case /atlassian.net/.test(url):
        icon = IconJiraPng;
        break;
      case /notion.so/.test(url):
        icon = IconNotionPng;
        break;
      case /slack.com/.test(url):
        icon = IconSlackPng;
        break;
      case /trello.com/.test(url):
        icon = IconTrelloPng;
        break;
      case /twitter.com/.test(url):
        icon = IconTwitterPng;
        break;
      default:
        break;
    }

    return icon;
  };

  _getMenuItems = (link: squadTypes.Link) => {
    const items = [];
    items.push(
      <button
        className="link-button"
        onClick={e => {
          e.preventDefault();
          this._makePrimaryLink(link);
        }}
      >
        Make reference link
      </button>
    );
    items.push(
      <button
        className="link-button"
        onClick={e => {
          e.preventDefault();
          this.props.deleteLink(link.id);
        }}
      >
        Delete
      </button>
    );

    return items;
  };

  _makePrimaryLink = (link: squadTypes.Link) => {
    const params = {
      primary_link_id: link.id
    };

    this.props.updateGoal(link.goal_id, params);
  };

  showLinkInput = e => {
    e.preventDefault();
    this.setState({
      displayLinkInput: "new"
    });
  };

  hideLinkInput = () => {
    this.setState({
      displayLinkInput: ""
    });
  };

  openNewWindow = e => {
    e.preventDefault();
    const url = e.target.dataset.url;
    const win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    const { currentLinks, goal, links, ui } = this.props;
    const primaryLink = goal.primary_link_id && links ? links.byId[goal.primary_link_id] : null;
    return (
      <Container>
        {ui.isFetchingLinks && <Loader size="small" />}
        <div className="link-list">
          {primaryLink && (
            <Link key={primaryLink.id} className="primary-link">
              <a className="icon" href={primaryLink.url} target="_blank" rel="noopener noreferrer">
                <img src={this._getLinkIcon(primaryLink)} alt="" />
              </a>
              <div className="content">
                <a href={primaryLink.url} target="_blank" rel="noopener noreferrer" className="header">
                  {primaryLink.integration_title || primaryLink.title}
                </a>
                <small className="light">
                  {moment(primaryLink.created_at).format("D MMM YYYY")}
                  {" | "}
                  <a href={primaryLink.url} target="_blank" rel="noopener noreferrer" className="light">
                    {primaryLink.url}
                  </a>
                </small>
              </div>
              <div className="actions">
                <DropdownMenu
                  trigger={
                    <ButtonIcon>
                      <IconMenu />
                    </ButtonIcon>
                  }
                  items={this._getMenuItems(primaryLink)}
                />
              </div>
            </Link>
          )}
          {primaryLink && <PrimaryLinkSeparator />}
          {currentLinks.map(link => {
            if (primaryLink && link.id === primaryLink.id) {
              return false;
            }
            return (
              <Link key={link.id}>
                <a className="icon" href={link.url} target="_blank" rel="noopener noreferrer">
                  <img src={this._getLinkIcon(link)} alt="" />
                </a>
                <div className="content">
                  <a href={link.url} target="_blank" rel="noopener noreferrer" className="header">
                    {link.integration_title || link.title}
                  </a>
                  <small className="subtle">
                    <a href={link.url} target="_blank" rel="noopener noreferrer" className="subtle">
                      {link.url}
                    </a>
                  </small>
                </div>
                <div className="actions">
                  <DropdownMenu
                    trigger={
                      <ButtonIcon>
                        <IconMenu />
                      </ButtonIcon>
                    }
                    items={this._getMenuItems(link)}
                  />
                </div>
              </Link>
            );
          })}
        </div>
        {!this.state.displayLinkInput && (
          <ButtonIcon onClick={this.showLinkInput}>
            <IconPlus style={{ width: "1.2rem" }} />
          </ButtonIcon>
        )}
        {this.state.displayLinkInput === "new" && (
          <LinkForm goal={this.props.goal} hideLinkInput={this.hideLinkInput} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentLinks: linksSelectors.getListByParentId(state.links.byId, "goal", props.goal.id),
  links: state.links,
  ui: state.ui.goalLinks
});

const mapDispatchToProps = {
  updateGoal: goalsOperations.updateGoal,
  fetchLinkList: linksOperations.fetchLinkList,
  deleteLink: linksOperations.deleteLink,
  resetLinkList: linksOperations.resetLinkList
};

export default connect(mapStateToProps, mapDispatchToProps)(Links);
