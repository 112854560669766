// @flow
import { createReducer } from "state/utils";
import * as statusUpdateViewsTypes from "state/ducks/statusUpdateViews/types";

const INITIAL_STATE = {
  isFetching: false
};

export default createReducer(INITIAL_STATE)({
  [statusUpdateViewsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    isFetching: false
  }),
  [statusUpdateViewsTypes.FETCH_LIST_COMPLETED]: state => ({
    isFetching: false
  }),
  [statusUpdateViewsTypes.FETCH_LIST_REQUESTED]: state => ({
    isFetching: true
  })
});
