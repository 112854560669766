// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { goalsOperations } from "state/ducks/goals";
import { projectsOperations } from "state/ducks/projects";
import * as goalTypes from "state/ducks/goals/types";

import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";

// Components
import FormField from "v2/components/FormField";
import FormActions from "v2/components/FormActions";

import Select from "v2/components/Select";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";

const Container = styled.form`
  .small {
    width: 50%;
  }

  em {
    font-weight: 600;
  }
`;

type Props = {
  goal: squadTypes.Goal,
  hideForm: Function,
  setErrorFields: Function,
  moveGoal: Function,
  ui: Object,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  push: Function,
  fetchProjectGoalList: Function,
  fetchProjectList: Function
};

type State = {
  selectedProject: ?Object,
  projectOptions: Array<Object>,
  emailErrorClass?: string
};

class GoalForm extends Component<Props, State> {
  state = {
    emailErrorClass: "",
    projectOptions: [],
    selectedProject: {
      value: "",
      label: "Pick a project"
    }
  };

  componentDidMount() {
    this._fetchProjectsForAutocomplete();
  }

  componentDidUpdate() {
    const { goal, projects } = this.props;
    const { selectedProject } = this.state;
    // if nextProps has projects and selectedProject == ""
    // Then set the selectedProject
    // fetch the sections

    if (selectedProject && selectedProject.value === "" && projects.byId && projects.byId[goal.project_id]) {
      const project = projects.byId[goal.project_id];
      this.setState({
        selectedProject: {
          value: project.id,
          label: project.title
        }
      });
    }
  }

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  moveGoal = (e: Object) => {
    e.preventDefault();
    const { goal } = this.props;
    const cached_project_id = goal.project_id;
    const { selectedProject } = this.state;

    const project_id = selectedProject ? selectedProject.value : null;

    if (!project_id) {
      return false;
    }

    const params = {
      project_id
    };
    this.props.moveGoal(goal.id, params).then(action => {
      if (action.type === goalTypes.MOVE_COMPLETED) {
        this.props.fetchProjectGoalList(goal.project_id);
        this.props.hideForm(e);
        this.props.push(`/pages/${cached_project_id}`);
      }
    });
  };

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchProjectsForAutocomplete = params => {
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const projectsApiURL = `/workspaces/${slug}/projects`;
    request.get(projectsApiURL, { params }).then(response => {
      const projects = response.data;
      if (projects && projects.length > 0) {
        const projectOptions = projects.map(project => {
          return {
            value: project.id,
            label: project.title,
            path: project.path
          };
        });
        // Sets the parent project if it exists
        const { goal } = this.props;
        let defaultProjectValue = projectOptions.filter(projectOption => projectOption.value === goal.project_id);
        if (defaultProjectValue && defaultProjectValue.length > 0) {
          defaultProjectValue = defaultProjectValue[0];
        } else {
          defaultProjectValue = null;
        }
        this.setState({
          projectOptions,
          selectedProject: defaultProjectValue
        });
      } else {
        this.setState({
          projectOptions: [],
          selectedProject: null
        });
      }
    });
  };

  handleProjectSelect = (option: any, actionMeta: any) => {
    if (option) {
      this.setState({ selectedProject: option });
    } else {
      this.setState({ selectedProject: null });
    }
  };

  handleEscPress = e => {
    if (e.keyCode === 27) {
      this.props.hideForm(e);
    }
  };

  _formatProjectOptionLabel = (props, params) => {
    if (params.context === "menu") {
      return (
        <div>
          <div className="option-label">{props.label}</div>
          <div className="option-path">Pages / {props.path.label}</div>
        </div>
      );
    } else {
      return <div className="">{props.label}</div>;
    }
  };

  render() {
    const { projectOptions, selectedProject } = this.state;
    const { goal, hideForm, ui } = this.props;

    const { errorFields } = ui;

    return (
      <Container className="modal-wrapper">
        <div className="modal-header">
          <h2>Move to another page</h2>
          <ButtonIcon onClick={this.props.hideForm}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <p>
            Use this form to move the goal <em>{goal.title}</em> to another page.
          </p>
          <FormField>
            <label className="label">Parent page</label>
            <Select
              value={selectedProject}
              options={projectOptions}
              classNamePrefix="react-select"
              onChange={this.handleProjectSelect}
              className={`${this.getErrorClass("project_id")} small`}
              formatOptionLabel={this._formatProjectOptionLabel}
            />
            {errorFields["project_id"] && (
              <span className={`${this.getErrorClass("project_id")}`}>
                <small>{errorFields["project_id"]}</small>
              </span>
            )}
          </FormField>
        </div>
        <div className="modal-footer">
          <FormActions>
            <button onClick={this.moveGoal} type="submit" className="primary" disabled={ui.formState === "requesting"}>
              Move
            </button>
            <button onClick={hideForm} type="submit" className="secondary">
              Cancel
            </button>
          </FormActions>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  ui: state.ui.moveGoalForm,
  projects: state.projects,
  sections: state.sections
});

const mapDispatchToProps = {
  moveGoal: goalsOperations.moveGoal,
  fetchProjectGoalList: goalsOperations.fetchProjectGoalList,
  fetchProjectList: projectsOperations.fetchProjectList,
  push: push
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalForm);
