//@flow
import { request } from "state/utils";
import * as types from "./types";

export const createReactionForGoal = (goalId: string, params: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/goals/${goalId}/reactions`, { reaction: params }),
    payload: { params }
  };
};

export const createReactionForStatusUpdate = (statusUpdateId: string, params: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/status_updates/${statusUpdateId}/reactions`, { reaction: params }),
    payload: { params }
  };
};

export const updateReaction = (reactionId: string, params: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/reactions/${reactionId}`, { reaction: params }),
    payload: { params }
  };
};

export const deleteReaction = (reactionId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/reactions/${reactionId}`),
    payload: { id: reactionId }
  };
};

export const fetchReactionListForGoal = (goalId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/goals/${goalId}/reactions?page=${page}`)
  };
};

export const fetchReactionListForStatusUpdate = (statusUpdateId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/status_updates/${statusUpdateId}/reactions?page=${page}`)
  };
};

export const fetchReactionDetails = (reactionId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/reactions/${reactionId}`)
  };
};

export const resetReactionList = () => ({
  type: types.RESET_LIST
});
