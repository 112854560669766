// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import { Redirect } from "react-router-dom";
import { getSubdomain } from "state/utils/url";

// Components
import Policy from "./Policy";
import ProfileNew from "./ProfileNew";
import WorkspaceNew from "v2/pages/workspaceNew";

type Props = {
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  memberships: Array<squadTypes.Membership>,
  logout: Function,
  workspaces: squadTypes.NormalizedList<squadTypes.Workspace>
};

type State = {};

class Onboarding extends Component<Props, State> {
  render() {
    const { currentUser, currentWorkspace, memberships } = this.props;

    // Test if the user has accepted the privacy policy
    if (!currentUser.privacy_policy_accepted) {
      return <Policy />;
    }
    // Test if the user has a name attached to their profile
    // If they don't then display the profileUpdate component
    if (!currentUser.fullname) {
      return <ProfileNew />;
    }

    const subdomain = getSubdomain();

    // Added the test for the subdomain because a user reported that Tability took him to the create workspace screen
    // while they were already in a workspace. That would only happen if the app failed to fetch the existing
    // workspace details -- so testing the subdomain should be a safeguard.
    if (currentWorkspace || subdomain !== "app") {
      return <Redirect to="/" />;
    }

    /**
     * Below we need to decide if we redirect to the home page, or if we display
     * the workspace creation screen. The issue is that the user can be either on
     * app.tability.io (generic place) or in a workspace.
     *
     * We can tackle this by simply checking if the user has existing memberships.
     * - User in workspace but not a member: redirected to join via
     *   the withAuthentication wrapper
     * - User in workspace and a member: membership exists so memberships.length
     *   is at least 1
     * - User in app.tability.io: either they need to create their first
     *   workspace or we'll display the list of workspaces in the home page.
     */

    // If we have a membership, we redirect to the homepage.
    if (subdomain === "app" && memberships && memberships[0]) {
      return <Redirect to="/" />;
    }

    return <WorkspaceNew />;
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  currentWorkspace: state.session.currentWorkspace,
  memberships: state.session.memberships
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
