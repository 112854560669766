// @flow
import React from "react";
import { Link, NavLink } from "react-router-dom";

import styled from "styled-components";

//V2 styling
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

const Container = styled.div`
  padding: ${spacing.x4};
  background: #f8f8f8;
  height: 100%;
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.black};
    font-weight: 600;
    font-size: 1.4rem;
  }
  .title {
    font-size: 1.8rem;
    font-weight: 600;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;

  .header {
    text-transform: uppercase;
    margin-top: ${spacing.x4};
    font-weight: 600;
    font-size: 1.4rem;
    color: ${colors.black};

    &:first-of-type {
      margin-top: 0;
    }
  }
  a {
    color: ${colors.subtleText};
    transition: all ease 0.2s;
    &.active,
    &:hover {
      color: ${colors.black};
      font-weight: 600;
      border-left: 4px solid ${colors.blockBorder};
      padding-left: ${spacing.x2};
    }

    display: inline-block;
    margin: ${spacing.x1} 0;
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: ${colors.blockBorder};
  }
`;
const subdomain = window.location.host.split(".")[0];

export default (props: any) => (
  <Container>
    <Header>
      <span>{props.user && <Link to={`/account`}>{props.user.fullname || props.user.email}</Link>}</span>
      <div className="title">Account settings</div>
    </Header>
    <Main>
      <div className="header">General</div>
      <NavLink to={`/account`} exact={true}>
        Account details
      </NavLink>
      <NavLink to={`/account/email`} exact={true}>
        Email
      </NavLink>
      {subdomain === "app" && (
        <NavLink to={`/account/delete`} exact={true}>
          Delete your account
        </NavLink>
      )}
      {subdomain !== "app" && (
        <NavLink to={`/account/leave`} exact={true}>
          Leave workspace
        </NavLink>
      )}
      {subdomain !== "app" && <div className="header">Notifications</div>}
      {subdomain !== "app" && (
        <NavLink to={`/account/notifications`} exact={true}>
          Notifications
        </NavLink>
      )}
      <div className="header">Security</div>
      <NavLink to={`/account/password`} exact={true}>
        Password
      </NavLink>
      <NavLink to={`/account/2fa`} exact={true}>
        2-Factor Authentication
      </NavLink>
    </Main>
  </Container>
);
