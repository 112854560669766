// @flow
import React, { Component } from "react";
import type { Node } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { membershipsOperations } from "state/ducks/memberships";

// Components
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import FormField from "v2/components/FormField";
import FormActions from "v2/components/FormActions";
import Loader from "v2/components/Loader";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";

const Container = styled.form`
  .small {
    max-width: 50%;
  }
`;

type Props = {
  handleSubmit: Function,
  history: any,
  hideForm: Function,
  createMembership: Function,
  resetForm: Function,
  ui: Object
};

class MembershipNew extends Component<Props> {
  componentDidUpdate(prevProps) {
    const currentFormState = this.props.ui.formState;

    // We only display a flag if the formState has changed and if it's due to a
    // completed or failed request.
    const prevFormState = prevProps.ui.formState;
    if (currentFormState !== prevFormState && currentFormState === "completed") {
      this.props.hideForm();
    }
  }

  inviteUsers = formValues => {
    formValues.invites.forEach(invite => {
      if (invite.email) {
        const membershipParams = {
          user_email: invite.email
        };
        this.props.createMembership(membershipParams);
      }
    });
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  renderInvites = ({ fields }): Node => {
    //const { errorFields } = this.props.ui;
    return fields.map((member, index) => (
      <FormField key={index}>
        <Field
          component="input"
          type="email"
          className={`small`}
          name={`${member}.email`}
          placeholder={`email@company.com`}
        />
      </FormField>
    ));
  };

  render() {
    const { handleSubmit, hideForm, ui } = this.props;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Invite";

    return (
      <Container className="modal-wrapper" onSubmit={handleSubmit(this.inviteUsers)}>
        <div className="modal-header">
          <h2>Invite your teammates</h2>
          <ButtonIcon onClick={this.props.hideForm}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <FieldArray name="invites" component={Invites} />
        </div>
        <div className="modal-footer">
          <FormActions>
            <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
              {submitText}
            </button>
            <button onClick={hideForm} type="submit" className="secondary">
              Cancel
            </button>
          </FormActions>
        </div>
      </Container>
    );
  }
}

type InvitesProps = {
  fields: any
};
class Invites extends Component<InvitesProps> {
  render() {
    const { fields } = this.props;
    return fields.map((member, index) => (
      <FormField key={index}>
        <Field
          component="input"
          type="email"
          className={`small`}
          name={`${member}.email`}
          placeholder={`email@company.com`}
        />
      </FormField>
    ));
  }
}

const mapStateToProps = state => ({
  initialValues: { invites: [{}, {}, {}, {}] },
  ui: state.ui.membershipForm
});

const mapDispatchToProps = {
  createMembership: membershipsOperations.createMembership,
  resetForm: reset
};

const _MembershipNew = reduxForm({
  form: "membershipForm"
})(MembershipNew);

export default connect(mapStateToProps, mapDispatchToProps)(_MembershipNew);
