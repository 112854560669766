// @flow
import { createReducer } from "state/utils";
import * as searchedGoalsTypes from "state/ducks/searchedGoals/types";

const INITIAL_STATE = {
  isFetchingGoals: true
};

export default createReducer(INITIAL_STATE)({
  [searchedGoalsTypes.SEARCH_COMPLETED]: state => ({
    isFetchingGoals: false
  }),
  [searchedGoalsTypes.SEARCH_COMPLETED]: state => ({
    isFetchingGoals: false
  }),
  [searchedGoalsTypes.SEARCH_REQUESTED]: state => ({
    isFetchingGoals: true
  })
});
