//@flow

/**
This is the main application loader. In this file we decided which routes to pick, and do the early config loading.
Routes are subdomain based:
- https://jira-connect-tability.tability.io: subdomain used for the Jira/Tability integration settings
- https://app.tability.io: base page (when you don't have a workspace)
- https://<subdomain>.tability.io workspace domain

Each subdomain has a specific set of routes that you can find in the /routes folder. Those routes are also divided
in sub-routes:
- Public routes if you do not need to be logged in (login, signup, etc...)
- Private routes if you need to be logged in

 */

import React, { Component, Fragment } from "react";
import { getSubdomain } from "state/utils/url";

import MainApp from "./_mainApp"; // routes and logic for the main domain
import WorkspaceApp from "./_workspaceApp"; // routes and logic for a given workspace
import JiraConnectApp from "./_jiraConnectApp"; // routes and logic for the Jira/Tability add-on

// Global styles
import GlobalStyles from "v2/components/GlobalStyles";

type Props = {};

class App extends Component<Props> {
  render() {
    // We're going to use the subdomain to determine which app we should be showing to the user.
    const subdomain = getSubdomain();
    // If subdomain === jira-connect-tability, show the Jira Connect views
    if (subdomain === "jira-connect-tability") {
      return (
        <Fragment>
          <GlobalStyles />
          <JiraConnectApp />
        </Fragment>
      );
    }

    if (subdomain === "app") {
      return (
        <Fragment>
          <GlobalStyles />
          <MainApp />
        </Fragment>
      );
    }

    // If we're here it means we're in a workspace
    return (
      <Fragment>
        <GlobalStyles />
        <WorkspaceApp />
      </Fragment>
    );
  }
}

export default App;
