// @flow
import { createReducer } from "state/utils";
import * as goalsTypes from "state/ducks/goals/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  errorFields: {},
  formState: "pending",
  avatarState: "pending",
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [goalsTypes.CREATE_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [goalsTypes.CREATE_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "",
      messageType: "success"
    };
  },
  [goalsTypes.CREATE_FAILED]: (state, action) => {
    const errors = action.error.response.data.errors;
    const errorFields = {};
    if (errors["title"]) {
      errorFields["title"] = `The title ${errors["title"][0]}`;
    }
    if (errors["deadline"]) {
      errorFields["deadline"] = `The deadline ${errors["deadline"][0]}`;
    }
    if (errors["membership"]) {
      errorFields["membership"] = `You need to select an owner.`;
    }
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not save your goal",
      messageType: "warning"
    };
  },
  [goalsTypes.UPDATE_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [goalsTypes.UPDATE_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "",
      messageType: "success"
    };
  },
  [goalsTypes.UPDATE_FAILED]: (state, action) => {
    const errors = action.error.response.data;
    const errorFields = {};
    if (errors["title"]) {
      errorFields["title"] = `The title ${errors["title"][0]}`;
    }
    if (errors["deadline"]) {
      errorFields["deadline"] = `The deadline ${errors["deadline"][0]}`;
    }
    if (errors["membership"]) {
      errorFields["membership"] = `You need to select an owner.`;
    }
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not save your goal",
      messageType: "warning"
    };
  },
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE,
  [uiTypes.SET_ERROR_FIELDS]: (state, action) => {
    return {
      ...state,
      errorFields: action.errorFields
    };
  }
});
