// This will be used for rem spacing definitions
const baseSpacing = 0.8;

export const spacing = {
  x1: `${baseSpacing}rem`,
  x2: `${baseSpacing * 2}rem`,
  x3: `${baseSpacing * 3}rem`,
  x4: `${baseSpacing * 4}rem`,
  x5: `${baseSpacing * 5}rem`,
  x6: `${baseSpacing * 6}rem`,
  x7: `${baseSpacing * 7}rem`,
  x8: `${baseSpacing * 8}rem`,
  x9: `${baseSpacing * 9}rem`,
  x10: `${baseSpacing * 10}rem`,
  x11: `${baseSpacing * 11}rem`,
  x12: `${baseSpacing * 12}rem`,
  x13: `${baseSpacing * 13}rem`,
  x14: `${baseSpacing * 14}rem`
};
