// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { workspacesOperations } from "state/ducks/workspaces";
import * as workspaceTypes from "state/ducks/workspaces/types";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  form {
    max-width: 50rem;
  }

  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  handleSubmit: Function,
  name: string,
  ui: Object,
  deleteWorkspace: Function
};

class WorkspaceSettingsDelete extends Component<Props> {
  deleteWorkspace = workspaceValues => {
    const { currentWorkspace } = this.props;
    this.props.deleteWorkspace(currentWorkspace.id).then(action => {
      if (action.type === workspaceTypes.DELETE_COMPLETED) {
        setTimeout(() => {
          window.location = `https://app.${process.env.REACT_APP_DOMAIN || ""}`;
        }, 3000);
      }
    });
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentWorkspace, name, handleSubmit, ui } = this.props;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Delete permanently";

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>Delete your workspace</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <p>
              Use this form to delete <b>{currentWorkspace.name}</b> and all its data.
            </p>
            <p>
              This action cannot be reversed and will permanently delete your workspace and all the historic data
              associated.
            </p>
            <p>You will need to type in the name of the workspace to confirm.</p>
            <form onSubmit={handleSubmit(this.deleteWorkspace)}>
              <FormField>
                <label htmlFor="name">Workspace name</label>
                <Field component="input" type="text" name="name" className="tiny" />
              </FormField>
              <FormActions>
                <button
                  type="submit"
                  className="danger-primary auto-size"
                  disabled={ui.formState === "requesting" || name !== currentWorkspace.name}
                >
                  {submitText}
                </button>
              </FormActions>
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  ui: state.ui.workspaceSettingsDelete,
  name: formSelector(state, "name")
});

const mapDispatchToProps = {
  deleteWorkspace: workspacesOperations.deleteWorkspace
};

const formSelector = formValueSelector("workspaceSettingsDelete");

const _WorkspaceSettingsDelete = reduxForm({
  form: "workspaceSettingsDelete"
})(WorkspaceSettingsDelete);

export default connect(mapStateToProps, mapDispatchToProps)(_WorkspaceSettingsDelete);
