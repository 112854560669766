// @flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { accountOperations } from "state/ducks/account";
import { membershipsOperations } from "state/ducks/memberships";
import { subscriptionsOperations } from "state/ducks/subscriptions";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import AccountSettingsNav from "v2/components/AccountSettingsNav";
import FormFlag from "v2/components/FormFlag";
import { Link } from "react-router-dom";
import IconEyeOn from "v2/components/svg/IconEyeOn";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin: ${spacing.x4} 0 ${spacing.x2};

    &:first-of-type {
      margin-top: 0;
    }
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: ${colors.blockBorder};
  }
`;

const Subscription = styled.div`
  display: flex;
  align-items: center;
  a,
  .link-button {
    margin-right: ${spacing.x1};
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x1};
  label {
    margin: 0;
    color: ${colors.black};
  }
  input {
    margin-right: ${spacing.x1};
  }
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  subscriptions: squadTypes.NormalizedList<squadTypes.Subscription>,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  deleteSubscription: Function,
  handleSubmit: Function,
  syncSlackAccount: Function,
  ui: Object,
  updateAccount: Function,
  updateMembership: Function
};

type State = {
  email_subscribed_report: boolean,
  email_notifications: boolean,
  notification_active: boolean,
  notification_weekly_report_email: boolean,
  notification_reminders_email: boolean,
  notification_reminders_push: boolean,
  notification_reminders_chat: boolean,
  notification_events_email: boolean,
  notification_events_push: boolean,
  notification_mentions_email: boolean,
  notification_mentions_push: boolean
};

class AccountSettingNotifications extends Component<Props, State> {
  state = {
    email_focus_report: this.props.currentMembership.email_focus_report,
    email_subscribed_report: this.props.currentMembership.email_subscribed_report,
    email_notifications: this.props.currentMembership.email_notifications,
    privacy_email_product: this.props.currentUser.privacy_email_product,
    notification_active: this.props.currentMembership.notification_active,
    notification_weekly_report_email: this.props.currentMembership.notification_weekly_report_email,
    notification_reminders_email: this.props.currentMembership.notification_reminders_email,
    notification_reminders_push: this.props.currentMembership.notification_reminders_push,
    notification_reminders_chat: this.props.currentMembership.notification_reminders_chat,
    notification_events_email: this.props.currentMembership.notification_events_email,
    notification_events_push: this.props.currentMembership.notification_events_push,
    notification_mentions_email: this.props.currentMembership.notification_mentions_email,
    notification_mentions_push: this.props.currentMembership.notification_mentions_push
  };
  deleteSubscription = e => {
    e.preventDefault();
    const projectId = e.currentTarget.dataset.projectId;
    const subscriptionId = e.currentTarget.dataset.subscriptionId;
    this.props.deleteSubscription(projectId, subscriptionId);
  };

  updateMembership = membershipValues => {
    this.props.updateMembership(membershipValues.id, membershipValues);
  };

  _syncSlackAccount = e => {
    const { currentMembership, syncSlackAccount } = this.props;
    e.preventDefault();
    syncSlackAccount(currentMembership.id);
  };

  handleMembershipChange = e => {
    const { currentMembership } = this.props;

    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () => {
        const params = {
          [name]: value
        };
        this.props.updateMembership(currentMembership.id, params);
      }
    );
  };

  handleAccountChange = e => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () => {
        const params = {
          [name]: value
        };
        this.props.updateAccount(params);
      }
    );
  };

  render() {
    const { currentUser, currentMembership, currentWorkspace, subscriptions, projects, ui } = this.props;

    const slackText = ui.slackState === "requesting" ? <Loader size="small" /> : "Sync Slack account";

    const {
      //email_notifications,
      //email_subscribed_report,
      notification_active,
      notification_weekly_report_email,
      notification_reminders_email,
      notification_reminders_push,
      notification_reminders_chat,
      notification_events_email,
      //notification_events_push,
      notification_mentions_email
      //notification_mentions_push,
    } = this.state;

    return (
      <WorkspaceLayout secondNav={<AccountSettingsNav user={currentUser} />}>
        <Container>
          <Header>
            <h1>Notifications settings</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <h2>General preferences</h2>
            <CheckboxContainer>
              <input
                checked={notification_active}
                onChange={this.handleMembershipChange}
                name="notification_active"
                id="notification_active"
                component="input"
                type="checkbox"
              />{" "}
              <label for="notification_active">Enable notifications</label>
            </CheckboxContainer>
            {!notification_active && (
              <p className="attention">
                Disabling notifications means you won't receive reminders for your check-ins, which means you might
                forget about your goals.
                <br />
                We highly recommend disabling specific preferences instead.
              </p>
            )}
            {notification_active && (
              <Fragment>
                <h2>Email preferences</h2>
                <CheckboxContainer>
                  <input
                    checked={notification_reminders_email}
                    onChange={this.handleMembershipChange}
                    name="notification_reminders_email"
                    id="notification_reminders_email"
                    component="input"
                    type="checkbox"
                  />{" "}
                  <label for="notification_reminders_email">Receive check-ins reminders via email</label>
                </CheckboxContainer>
                <CheckboxContainer>
                  <input
                    checked={notification_weekly_report_email}
                    onChange={this.handleMembershipChange}
                    name="notification_weekly_report_email"
                    id="notification_weekly_report_email"
                    component="input"
                    type="checkbox"
                  />{" "}
                  <label for="notification_weekly_report_email">
                    Receive weekly summary report of pages and goals you're subscribed to
                  </label>
                </CheckboxContainer>
                <CheckboxContainer>
                  <input
                    checked={notification_events_email}
                    onChange={this.handleMembershipChange}
                    name="notification_events_email"
                    id="notification_events_email"
                    component="input"
                    type="checkbox"
                  />{" "}
                  <label for="notification_events_email">Receive activity notifications via email</label>
                </CheckboxContainer>
                <CheckboxContainer>
                  <input
                    checked={notification_mentions_email}
                    onChange={this.handleMembershipChange}
                    name="notification_mentions_email"
                    id="notification_mentions_email"
                    component="input"
                    type="checkbox"
                  />{" "}
                  <label for="notification_mentions_email">
                    Receive emails notifications when you're mentioned in a comment
                  </label>
                </CheckboxContainer>
                <h2>Push notifications preferences</h2>
                <CheckboxContainer>
                  <input
                    checked={notification_reminders_push}
                    onChange={this.handleMembershipChange}
                    name="notification_reminders_push"
                    id="notification_reminders_push"
                    component="input"
                    type="checkbox"
                  />{" "}
                  <label for="notification_reminders_push">
                    Receive check-ins reminders via push notifications on your phone
                  </label>
                </CheckboxContainer>
                <h2>Slack settings</h2>
                {!currentWorkspace.slack_team_name && (
                  <p>
                    Your workspace is not yet connected to Slack. Ask your workspace administrator to connect your Slack
                    space to be able to receive notifications via Slack.
                  </p>
                )}
                {currentWorkspace.slack_team_name && (
                  <div>
                    <CheckboxContainer>
                      <input
                        checked={notification_reminders_chat}
                        onChange={this.handleMembershipChange}
                        name="notification_reminders_chat"
                        id="notification_reminders_chat"
                        component="input"
                        type="checkbox"
                      />{" "}
                      <label for="notification_reminders_chat">Receive check-ins reminders via Slack</label>
                    </CheckboxContainer>
                    {currentMembership.slack_user_id && (
                      <p>
                        Connected to Slack via{" "}
                        <b>
                          {currentWorkspace.slack_team_name} ({currentUser.email})
                        </b>
                        .
                      </p>
                    )}
                    {!currentMembership.slack_user_id && (
                      <p>Your account is not yet connected to Slack. Use the button below to sync your account.</p>
                    )}
                    <button onClick={this._syncSlackAccount} className="auto-size">
                      {slackText}
                    </button>
                    {ui.slackState === "failed" && (
                      <p className="danger">
                        <br />
                        Sorry, we could not connect your account using your email <b>{currentUser.email}</b>.
                      </p>
                    )}
                  </div>
                )}
              </Fragment>
            )}

            <h2>Page subscriptions</h2>
            {subscriptions.total > 0 && (
              <p>Here's the list of pages that you are subscribed to. Click on the eye to unsubscribe.</p>
            )}
            {subscriptions.total === 0 && (
              <p>
                You do not have any subscribed page. You can subscribe to a page by clicking on the eye icon in its
                header.
              </p>
            )}
            {subscriptions.allIds.map(subscriptionId => {
              const subscription = subscriptions.byId[subscriptionId];
              if (subscription) {
                const project = projects.byId[subscription.project_id];
                if (project) {
                  return (
                    <Subscription key={subscription.id}>
                      <button
                        className="link-button subscription"
                        data-project-id={project.id}
                        data-subscription-id={subscription.id}
                        onClick={this.deleteSubscription}
                      >
                        <IconEyeOn />
                      </button>
                      <Link to={`/pages/${project.id}`}>{project.title}</Link>
                    </Subscription>
                  );
                } else {
                  return false;
                }
              } else {
                return false;
              }
            })}
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentMembership: state.session.currentMembership,
  currentUser: state.session.currentUser,
  currentWorkspace: state.session.currentWorkspace,
  initialValues: state.session.currentMembership,
  subscriptions: state.subscriptions,
  projects: state.projects,
  ui: state.ui.accountSettingsNotifications
});

const mapDispatchToProps = {
  deleteSubscription: subscriptionsOperations.deleteSubscription,
  updateAccount: accountOperations.updateAccount,
  updateMembership: membershipsOperations.updateMembership,
  syncSlackAccount: membershipsOperations.syncSlackAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingNotifications);
