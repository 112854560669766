// @flow
import { createReducer } from "state/utils";
import * as taskTypes from "state/ducks/tasks/types";

const INITIAL_STATE = {
  isFetchingTasks: false
};

export default createReducer(INITIAL_STATE)({
  [taskTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingTasks: false
  }),
  [taskTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingTasks: false
  }),
  [taskTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingTasks: true
  }),
  [taskTypes.CREATE_FAILED]: (state, action) => ({
    ...state,
    isFetchingTasks: false
  }),
  [taskTypes.CREATE_COMPLETED]: state => ({
    ...state,
    isFetchingTasks: false
  }),
  [taskTypes.CREATE_REQUESTED]: state => ({
    ...state,
    isFetchingTasks: true
  }),
  [taskTypes.UPDATE_FAILED]: (state, action) => ({
    ...state,
    isFetchingTasks: false
  }),
  [taskTypes.UPDATE_COMPLETED]: state => ({
    ...state,
    isFetchingTasks: false
  }),
  [taskTypes.UPDATE_REQUESTED]: state => ({
    ...state,
    isFetchingTasks: true
  }),
  [taskTypes.DELETE_FAILED]: (state, action) => ({
    ...state,
    isFetchingTasks: false
  }),
  [taskTypes.DELETE_COMPLETED]: state => ({
    ...state,
    isFetchingTasks: false
  }),
  [taskTypes.DELETE_REQUESTED]: state => ({
    ...state,
    isFetchingTasks: true
  })
});
