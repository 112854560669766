//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { handleChange } from "v2/utils/forms";
import { linksOperations } from "state/ducks/links";

// Components
import FormField from "v2/components/FormField";
import FormActions from "v2/components/FormActions";

const Container = styled.div``;

type Props = {
  createLink: Function,
  hideLinkInput: Function,
  updateLink: Function,
  link?: squadTypes.Link,
  goal: squadTypes.Goal,
  ui: Object
};

type State = {
  url: string,
  title: ?string
};

class LinkForm extends Component<Props, State> {
  focusInput: any;
  state = {
    url: this.props.link ? this.props.link.url : "",
    title: this.props.link ? this.props.link.title : ""
  };

  componentDidMount() {
    this.focusInput.focus();
  }

  handleChange = e => handleChange(this, e);

  createLink = e => {
    e.preventDefault();
    const { goal } = this.props;
    const { url, title } = this.state;
    const params = {
      url,
      title
    };

    return this.props.createLink(goal.id, params).then(() => {
      this.props.hideLinkInput();
      this.setState({
        title: "",
        url: ""
      });
    });
  };

  render() {
    return (
      <Container>
        <form onSubmit={this.createLink}>
          <FormField>
            <input
              ref={input => {
                this.focusInput = input;
              }}
              type="url"
              onChange={this.handleChange}
              name="url"
              placeholder="Paste any link here..."
              autoComplete="off"
            />
          </FormField>
          <FormField>
            <input type="text" placeholder="Link name (optional)" onChange={this.handleChange} name="title" />
          </FormField>
          <FormActions>
            <button type="submit" className="primary">
              Attach
            </button>
            <button className="secondary" onClick={() => this.props.hideLinkInput()}>
              Cancel
            </button>
          </FormActions>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  ui: state.ui.goalLinks
});

const mapDispatchToProps = {
  createLink: linksOperations.createLink,
  updateLink: linksOperations.updateLink,
  deleteLink: linksOperations.deleteLink,
  resetLinkList: linksOperations.resetLinkList
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkForm);
