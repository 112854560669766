//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 18 18" {...props}>
    <g
      id="Components"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon/Update" transform="translate(-6.000000, -6.000000)" stroke="#6A6A6A" strokeWidth="2">
        <g id="Update-Copy" transform="translate(7.000000, 7.000000)">
          <g id="edit">
            <path
              d="M16,8 L16,14.2222222 C16,15.2040618 15.2040618,16 14.2222222,16 L1.77777778,16 C0.795938223,16 0,15.2040618 0,14.2222222 L0,1.77777778 C0,0.795938223 0.795938223,0 1.77777778,0 L8,0"
              id="Shape"
            ></path>
            <polygon id="Shape" points="13.1428571 0 16 2.85714286 8.85714286 10 6 10 6 7.14285714"></polygon>
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
