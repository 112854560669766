//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import moment from "moment";
import { goalsOperations } from "state/ducks/goals";
import { reactionsOperations } from "state/ducks/reactions";
import { uiOperations } from "state/ducks/ui";

// Components
import Avatar from "v2/components/Avatar";
import ButtonIcon from "v2/components/ButtonIcon";
import IconLike from "v2/components/svg/IconLike";

import Markdown from "v2/components/Markdown";
import StatusBox from "v2/components/StatusBox";

import ReactTooltip from "react-tooltip";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

const UpdateContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.blockBorder};
  background: #fff;
  border-radius: 8px;
  padding: ${spacing.x2};
  margin-top: 0;
`;
const AvatarWrapper = styled.div`
  margin-right: ${spacing.x2};
`;
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.x1};
`;

const Progress = styled.div`
  display: flex;
`;

const Actions = styled.div`
  display: flex;
  button {
    margin-right: ${spacing.x1};
    span {
      padding-left: 4px;
    }
    padding: 0 ${spacing.x1};
  }
`;

const ContentMain = styled.div`
  margin-bottom: ${spacing.x1};
`;
const ContentFooter = styled.div``;

type Props = {
  goal: squadTypes.Goal,
  createReactionForGoal: Function,
  deleteReaction: Function,

  fetchGoalDetails: Function
};

class GoalCommentCard extends Component<Props> {
  addReaction = (e: Object) => {
    e.preventDefault();
    const { goal } = this.props;
    this.props.createReactionForGoal(goal.id, {}).then(() => {
      this.props.fetchGoalDetails(goal.id);
    });
  };

  deleteReaction = (e: Object) => {
    e.preventDefault();
    const { goal } = this.props;
    this.props.deleteReaction(goal.reactionable_id).then(() => {
      this.props.fetchGoalDetails(goal.id);
    });
  };
  render() {
    const { goal } = this.props;
    const { owner } = goal;
    return (
      <UpdateContainer>
        <AvatarWrapper>
          <Avatar user={owner} size={24} data-tip data-for={`status-${goal.id}-author`} />
          {owner && (
            <ReactTooltip id={`status-${goal.id}-author`} place="bottom" type="dark" effect="solid" delayShow={500}>
              {owner.fullname}
            </ReactTooltip>
          )}
        </AvatarWrapper>
        <ContentWrapper>
          <ContentHeader>
            <Progress>
              <StatusBox className="grey" />
              <span>Goal created</span>
            </Progress>
            <Actions>
              {!goal.reactionable_id && (
                <ButtonIcon className="auto-width" onClick={this.addReaction}>
                  <IconLike className="like" />
                  {goal.reactions_count > 0 && <span className="counter">{goal.reactions_count}</span>}
                </ButtonIcon>
              )}
              {goal.reactionable_id && (
                <ButtonIcon className="auto-width" onClick={this.deleteReaction}>
                  <IconLike className="like remove" />
                  {goal.reactions_count > 0 && <span className="counter">{goal.reactions_count}</span>}
                </ButtonIcon>
              )}
            </Actions>
          </ContentHeader>
          {goal.description && (
            <ContentMain>
              <Markdown source={goal.description} />
            </ContentMain>
          )}
          <ContentFooter>
            <small className="subtle">{moment(goal.created_at).fromNow()}</small>
          </ContentFooter>
        </ContentWrapper>
      </UpdateContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  deleteReaction: reactionsOperations.deleteReaction,
  fetchGoalDetails: goalsOperations.fetchGoalDetails,
  createReactionForGoal: reactionsOperations.createReactionForGoal,
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalCommentCard);
