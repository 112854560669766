//@flow
import { request } from "state/utils";
import * as types from "./types";
import { getSubdomain } from "state/utils/url";

const slug = getSubdomain() || "";

export const createSubscription = (subscriptionParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/workspaces/${slug}/subscriptions`, { subscription: subscriptionParams }),
    payload: { subscriptionParams }
  };
};

export const deleteSubscription = (projectId: string, subscriptionId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/subscriptions/${projectId}`),
    payload: { id: subscriptionId }
  };
};

export const fetchSubscriptionList = (params?: Object) => {
  const filters = [];
  if (params) {
    if (params.page) {
      filters.push(`page=${params.page}`);
    }
  }
  const filtersQuery = filters.join("&");
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/workspaces/${slug}/subscriptions?${filtersQuery}`)
  };
};

export const fetchSubscriptionDetails = (projectId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/subscriptions/${projectId}`)
  };
};

export const resetSubscriptionList = () => ({
  type: types.RESET_LIST
});
