// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as squadTypes from "squadTypes";

// Styling
import styled from "styled-components";
import { spacing } from "v2/components/styles/spacing";
import { devices } from "v2/components/styles/devices";

// Ducks stuff
import { workspacesOperations } from "state/ducks/workspaces";
import * as workspaceTypes from "state/ducks/workspaces/types";
import { sessionOperations } from "state/ducks/session";

// Components
import { Field, reduxForm } from "redux-form";
import FormField from "v2/components/FormField";
import InlineMessage from "v2/components/InlineMessage";
import { Link } from "react-router-dom";
import Loader from "v2/components/Loader";
import Wordmark from "v2/components/_assets/tability_logo_full.png";

const Container = styled.main`
  display: flex;
  height: 100%;
  > div {
    display: flex;
    flex: 1;
    align-items: stretch;
  }
  .left-panel {
    justify-content: flex-end;
    padding-right: ${spacing.x10};

    @media ${devices.tablet} {
      display: none;
    }
  }
  .right-panel {
    padding-left: ${spacing.x10};
    @media ${devices.tablet} {
      padding: ${spacing.x4};
      justify-content: center;
    }
    .form-actions {
      text-align: right;
    }
  }
`;

const Grid = styled.div`
  max-width: 35rem;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "header"
    "main"
    "footer";
  .grid-header {
    grid-area: header;
    display: flex;
    align-items: flex-end;
    margin-bottom: 69px;

    img {
      height: 40px;
    }
  }
  .grid-main {
    grid-area: main;
    h1 {
      margin-bottom: ${spacing.x2};
    }
  }
  .grid-footer {
    grid-area: footer;
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
  }

  @media ${devices.tablet} {
    max-width: initial;
  }
`;

const StepBlock = styled.div`
  display: flex;
  padding: 35px 25px;

  color: #ababab;

  border-bottom: 1px solid #d1d1d1;

  &:last-child {
    border-bottom: 0;
  }

  &.selected {
    color: #151515;
  }

  > div {
    &:first-child {
      width: 60px;
      font-size: 2.4rem;
      font-weight: 500;
    }

    &:nth-child(2) {
      big {
        display: block;
        font-weight: 500;
        font-size: 2.4rem;
      }
      span {
        margin-top: 20px;
        display: block;
      }
    }
  }
`;

const FooterBlock = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  span {
    display: block;
    margin: 0 ${spacing.x1};
  }
  a,
  .link-button {
    color: #6a6a6a;

    &:hover {
      color: #6a6a6a;
      filter: brightness(90%);
    }
    text-decoration: underline;
    margin-right: ${spacing.x2};
  }
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentWorkspace: squadTypes.Workspace,
  dispatch: Function,
  handleSubmit: Function,
  ui: Object,
  updateWorkspace: Function,
  logout: Function
};

class WorkspaceNotifications extends Component<Props> {
  updateWorkspace = workspaceValues => {
    // Send the subscribed reports 1 day after checkins
    this.props.updateWorkspace(workspaceValues.id, workspaceValues).then(action => {
      if (action.type === workspaceTypes.UPDATE_COMPLETED) {
        this.props.dispatch(push(`/`));
      }
    });
  };

  logout = (e: Object) => {
    e.preventDefault();
    this.props.logout();
  };

  render() {
    const { handleSubmit, ui, currentMembership, currentWorkspace } = this.props;

    const disabled = ui.isFetching;

    const submitText = ui.isFetching ? <Loader size="small" /> : "Go to your workspace";

    const displaySettingsLink =
      currentWorkspace && currentMembership && ["admin", "owner"].indexOf(currentMembership.role) >= 0;
    return (
      <Container>
        <div className="left-panel">
          <Grid>
            <div className="grid-header">
              <Link to="/">
                <img src={Wordmark} alt="Tability" />
              </Link>
            </div>
            <div className="grid-main">
              <StepBlock>
                <div>1</div>
                <div>
                  <big>Set up your account</big>
                </div>
              </StepBlock>
              <StepBlock>
                <div>2</div>
                <div>
                  <big>Set up your workspace</big>
                </div>
              </StepBlock>
              <StepBlock className="selected">
                <div>3</div>
                <div>
                  <big>Set up your workflow</big>
                  <span>Configure automatic reminders and reports.</span>
                </div>
              </StepBlock>
            </div>
            <div className="grid-footer">
              {currentMembership && (
                <FooterBlock>
                  <button className="link-button" onClick={this.logout}>
                    Logout
                  </button>
                  <Link to="/account">Account settings</Link>
                  {displaySettingsLink && <Link to="/settings">Workspace settings</Link>}
                </FooterBlock>
              )}
            </div>
          </Grid>
        </div>
        <div className="right-panel">
          <Grid>
            <div className="grid-main">
              <h1>Set up your workflow</h1>
              <InlineMessage message={ui.message} messageType={ui.messageType} />
              <form onSubmit={handleSubmit(this.updateWorkspace)}>
                <FormField>
                  <p>On what day does your team write progress updates on their projects?</p>
                  <Field
                    component="select"
                    type="text"
                    className="small"
                    name="weekly_reminder_day"
                    aria-label="Select the day when reminders should be sent"
                  >
                    <option value="0">Sunday</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                  </Field>
                </FormField>
                <FormField>
                  <p>When do you want to receive a weekly progress report?</p>
                  <Field
                    component="select"
                    type="text"
                    className="small"
                    name="weekly_subscribed_report_day"
                    aria-label="Select the day when reports should be sent"
                  >
                    <option value="0">Sunday</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                  </Field>
                </FormField>
                <div className="form-actions">
                  <button type="submit" className="primary" disabled={disabled}>
                    {submitText}
                  </button>
                </div>
              </form>
            </div>
          </Grid>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentWorkspace: state.session.currentWorkspace,
    currentMembership: state.session.currentMembership,
    initialValues: {
      ...state.session.currentWorkspace
    },
    ui: state.ui.workspaceOnboardingNotifications
  };
};

const mapDispatchToProps = {
  updateWorkspace: workspacesOperations.updateWorkspace,
  logout: sessionOperations.logout
};

const _WorkspaceNotifications = reduxForm({
  form: "workspaceOnboardingNotifications"
})(WorkspaceNotifications);

export default connect(mapStateToProps, mapDispatchToProps)(_WorkspaceNotifications);
