//@flow
import { request } from "state/utils";
import * as types from "./types";

export const createSection = (projectId: string, sectionParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/projects/${projectId}/sections`, { section: sectionParams }),
    payload: { sectionParams }
  };
};

export const updateSection = (sectionId: string, sectionParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/sections/${sectionId}`, { section: sectionParams }),
    payload: { sectionParams }
  };
};

export const moveSection = (sectionId: string, sectionParams: Object) => {
  return {
    types: [types.MOVE_REQUESTED, types.MOVE_COMPLETED, types.MOVE_FAILED],
    callAPI: () => request.put(`/sections/${sectionId}/move`, { section: sectionParams }),
    payload: { sectionParams }
  };
};

export const deleteSection = (sectionId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/sections/${sectionId}`),
    payload: { id: sectionId }
  };
};

export const fetchSectionList = (projectId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/projects/${projectId}/sections?page=${page}`),
    payload: {
      project_id: projectId
    }
  };
};

export const fetchSectionDetails = (sectionId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/sections/${sectionId}`)
  };
};

export const resetSectionList = () => ({
  type: types.RESET_LIST
});
