//@flow
export const CREATE_REQUESTED = "projects/CREATE_REQUESTED";
export const CREATE_COMPLETED = "projects/CREATE_COMPLETED";
export const CREATE_FAILED = "projects/CREATE_FAILED";

export const COPY_REQUESTED = "projects/COPY_REQUESTED";
export const COPY_COMPLETED = "projects/COPY_COMPLETED";
export const COPY_FAILED = "projects/COPY_FAILED";

export const SHARE_REQUESTED = "projects/SHARE_REQUESTED";
export const SHARE_COMPLETED = "projects/SHARE_COMPLETED";
export const SHARE_FAILED = "projects/SHARE_FAILED";

export const UPDATE_REQUESTED = "projects/UPDATE_REQUESTED";
export const UPDATE_COMPLETED = "projects/UPDATE_COMPLETED";
export const UPDATE_FAILED = "projects/UPDATE_FAILED";

export const DELETE_REQUESTED = "projects/DELETE_REQUESTED";
export const DELETE_COMPLETED = "projects/DELETE_COMPLETED";
export const DELETE_FAILED = "projects/DELETE_FAILED";

export const FETCH_LIST_REQUESTED = "projects/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "projects/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "projects/FETCH_LIST_FAILED";

export const FETCH_DETAILS_REQUESTED = "projects/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "projects/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "projects/FETCH_DETAILS_FAILED";

export const FETCH_TRENDS_REQUESTED = "projects/FETCH_TRENDS_REQUESTED";
export const FETCH_TRENDS_COMPLETED = "projects/FETCH_TRENDS_COMPLETED";
export const FETCH_TRENDS_FAILED = "projects/FETCH_TRENDS_FAILED";

export const FETCH_TASKS_REQUESTED = "projects/FETCH_TASKS_REQUESTED";
export const FETCH_TASKS_COMPLETED = "projects/FETCH_TASKS_COMPLETED";
export const FETCH_TASKS_FAILED = "projects/FETCH_TASKS_FAILED";

export const RESET_LIST = "projects/RESET_LIST";
