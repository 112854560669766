import React, { Component } from "react";
import { connect } from "react-redux";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

// Components
import ButtonIcon from "v2/components/ButtonIcon";
import IconLocked from "v2/components/svg/IconLocked";
import IconUnlocked from "v2/components/svg/IconUnlocked";

import ReactTooltip from "react-tooltip";

const Container = styled.div``;

type Props = {
  showModal: Function,
  currentProject: squadTypes.Project
};

class ProjectPermissions extends Component<Props> {
  _showPermissionModal = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "permissions",
      object: this.props.currentProject
    });
  };

  render() {
    const { currentProject } = this.props;

    if (currentProject.global_permission_type === "edit") {
      return (
        <Container {...this.props}>
          <ReactTooltip id="unlocked" place="bottom" type="dark" effect="solid" delayShow={200}>
            No restrictions - everyone can view & edit this page
          </ReactTooltip>
          {currentProject.current_user_permission === "edit" && (
            <ButtonIcon onClick={this._showPermissionModal}>
              <IconUnlocked data-tip data-for="unlocked" />
            </ButtonIcon>
          )}
          {currentProject.current_user_permission !== "edit" && (
            <ButtonIcon disabled={true}>
              <IconUnlocked data-tip data-for="unlocked" />
            </ButtonIcon>
          )}
        </Container>
      );
    }

    if (currentProject.global_permission_type === "view") {
      return (
        <Container {...this.props}>
          <ReactTooltip id="locked_view" place="bottom" type="dark" effect="solid" delayShow={200}>
            Editing restricted - only some users can edit this page
          </ReactTooltip>
          {currentProject.current_user_permission === "edit" && (
            <ButtonIcon onClick={this._showPermissionModal}>
              <IconLocked data-tip data-for="locked_view" />
            </ButtonIcon>
          )}
          {currentProject.current_user_permission !== "edit" && (
            <ButtonIcon disabled={true}>
              <IconLocked data-tip data-for="locked_view" />
            </ButtonIcon>
          )}
        </Container>
      );
    }

    if (currentProject.global_permission_type === "none") {
      return (
        <Container {...this.props}>
          <ReactTooltip id="locked" place="bottom" type="dark" effect="solid">
            Viewing and editing restricted
          </ReactTooltip>
          {currentProject.current_user_permission === "edit" && (
            <ButtonIcon onClick={this._showPermissionModal}>
              <IconLocked data-tip data-for="locked" className="red" />
            </ButtonIcon>
          )}
          {currentProject.current_user_permission !== "edit" && (
            <ButtonIcon disabled={true}>
              <IconLocked data-tip data-for="locked" className="red" />
            </ButtonIcon>
          )}
        </Container>
      );
    }

    return null;
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPermissions);
