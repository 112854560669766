//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { JiraConnectRoutes } from "v2/routes";

type Props = {
  currentUser: Object,
  location: Object,
  history: Object
};

type State = {};

class App extends Component<Props, State> {
  render() {
    return <JiraConnectRoutes />;
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
