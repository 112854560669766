// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import { goalsSelectors } from "state/ducks/goals";
import { sectionsOperations } from "state/ducks/sections";
import { uiOperations } from "state/ducks/ui";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

import SectionMenu from "./SectionMenu";
import ReactTooltip from "react-tooltip";
import ButtonIcon from "v2/components/ButtonIcon";
import IconPlus from "v2/components/svg/IconPlus";

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
  margin-bottom: ${spacing.x2};
`;

const EmptySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: 80%;
    line-height: 1.6;
    text-align: center;
    margin-bottom: ${spacing.x3};
  }
  margin-bottom: ${spacing.x2};
`;

const SideActions = styled.div`
  position: absolute;
  background: #fff;
  padding: ${spacing.x1};
  top: ${spacing.x2};
  right ${spacing.x2};
  display: flex;
  opacity: 0;
  z-index: 99;
  transition: all 0.2s ease;
`;

const SectionContainer = styled.div`
  padding: ${spacing.x2};
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${colors.blockBorder};
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.x3};
  position: relative;
  .add-goal-button {
    display: none;
    position: absolute;
    margin: 0;
    bottom: -1.5rem;
    right: ${spacing.x2};
  }

  &:hover {
    .section-actions {
      opacity: 1;
    }
  }
`;

const SectionProgress = styled.div`
  background-color: ${colors.progressBarBg};
  opacity: 1;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6rem;
  min-width: 6rem;
  height: 3.4rem;
  font-size: 1.6rem;
  border-radius: 16px;
  margin-left: ${spacing.x1};
  font-weight: 600;

  position: relative;
  overflow: hidden;

  .progress-bar {
    background-color: ${colors.progressBar};
    border-radius: 4px;
    height: 0.4rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const EditableSectionTitle = styled.h2`
  overflow-wrap: break-word;
  overflow: hidden;
  background: transparent;
  border: 2px solid transparent;
  font-weight: 700;
  font-size: ${props => props.fontSize};
  width: 100%;

  transition: all ease 0.2s;
  outline: none;
  line-height: 3rem;
  padding: 0 4px;
  padding-left: 0;
  display: block;
  border-radius: 0;
  color: ${colors.black};

  &:focus {
    width: 100%;
    border: 2px solid transparent;
    border-bottom: 2px solid ${colors.blue};
  }
`;

type Props = {
  children: any,
  hasCurrentGoal: boolean,
  currentGoal: squadTypes.Goal,
  project: squadTypes.Project,
  fetchGoalList: Function,
  goalsCount: number,
  goalItems: Array<Object>,
  onDragEnd: Function,
  section: squadTypes.Section,
  sectionsCount: number,
  showModal: Function,
  updateSection: Function,
  goalItems: Array<squadTypes.Goal>,
  updateGoalItems: Function
};

type State = {
  displayGoalForm: Object
};

class Section extends Component<Props, State> {
  sectionTitleInput: any;

  state = {
    displayGoalForm: {}
  };

  handlePress = e => {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.sectionTitleInput.blur();
    }

    if (e.keyCode === 13) {
      e.preventDefault();
      this.sectionTitleInput.blur();
    }
  };

  handlePaste = e => {
    e.preventDefault();
    let text = e.clipboardData.getData("text/plain");
    text = text.replace(/(\r\n|\n|\r)/gm, " "); // remove the line breaks
    document.execCommand("insertHTML", false, text);
  };

  updateSection = (e: Object) => {
    e.preventDefault();
    const { section } = this.props;
    const title = e.target.innerText;
    const params = {
      title
    };
    this.props.updateSection(section.id, params);
  };

  showGoalNewForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "goal",
      contentAction: "new",
      parent: this.props.section
    });
  };

  sectionTitle = () => {
    const { hasCurrentGoal, sectionsCount, project, section } = this.props;
    const progressPrct = Math.round(section.cached_prct_progress_normalized * 100);

    const fontSize = hasCurrentGoal ? "1.6rem" : "1.6rem";

    return (
      <TitleContainer>
        <div>
          <EditableSectionTitle
            fontSize={fontSize}
            ref={textarea => {
              this.sectionTitleInput = textarea;
            }}
            contentEditable={project && project.current_user_permission === "edit"}
            spellCheck={false}
            onKeyDown={this.handlePress}
            data-gramm_editor={false}
            onBlur={this.updateSection}
            onPaste={this.handlePaste}
            dangerouslySetInnerHTML={{ __html: section.title }}
          />
        </div>

        <div>
          {project && project.current_user_permission === "edit" && (
            <SideActions className="section-actions">
              <ButtonIcon
                data-tip
                data-for={"add-goal-button"}
                style={{ marginRight: spacing.x1 }}
                onClick={this.showGoalNewForm}
              >
                <IconPlus style={{ width: "1.4rem" }} />
              </ButtonIcon>
              <SectionMenu sectionsCount={sectionsCount} section={section} />
              <ReactTooltip id={`add-goal-button`} place="bottom" type="dark" effect="solid">
                Add a new goal
              </ReactTooltip>
            </SideActions>
          )}
          <SectionProgress>
            <div>{progressPrct}%</div>
          </SectionProgress>
        </div>
      </TitleContainer>
    );
  };

  render() {
    const { children, section, goalItems } = this.props;
    const goalItemsCount = goalItems ? goalItems.length : 0;
    if (section) {
      return (
        <SectionContainer>
          {this.sectionTitle()}
          {children}
          {section.cached_total_goals === 0 && goalItemsCount === 0 && (
            <EmptySection>
              <p>
                Use sections to group goals under an objective, theme or stream of work{" "}
                <span role="img" aria-label="">
                  ✨
                </span>
              </p>
              <button onClick={this.showGoalNewForm} className="compact">
                Add the first goal
              </button>
            </EmptySection>
          )}
        </SectionContainer>
      );
    } else {
      return false;
    }
  }
}

const mapStateToProps = (state, props) => ({
  currentGoal: goalsSelectors.getObjectById(state.goals.byId, props.match.params.goal),
  selectedGoal: goalsSelectors.getObjectById(state.goals.byId, state.goals.selectedId)
});

const mapDispatchToProps = {
  deleteSection: sectionsOperations.deleteSection,
  showModal: uiOperations.showModal,
  updateSection: sectionsOperations.updateSection
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section));
