// @flow
import { combineReducers } from "redux";
import * as types from "./types";
import * as accountTypes from "../account/types";
import * as goalsTypes from "../goals/types";
import * as projectsTypes from "../projects/types";
import * as membershipsTypes from "../memberships/types";
import * as statusUpdatesTypes from "../statusUpdates/types";
import * as workspacesTypes from "../workspaces/types";
import * as integrationsTypes from "../integrations/types";
import { createReducer } from "state/utils";
import moment from "moment";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

const cookies = new Cookies();
const COOKIE_STORE = "TABILITY_WORKSPACES";
const domain = process.env.REACT_APP_DOMAIN || "";
// Remove the port
const cookieDomain = domain.split(":")[0];
/* State shape
{
    isAuthenticated: bool,
}
*/

// isAppLoaded is used for the initial loading state
const isAppLoadedReducer = createReducer(false)({
  [types.INITIALIZE_COMPLETED]: () => true,
  [types.INITIALIZE_STARTED]: () => false
});

const currentUserReducer = createReducer(null)({
  [accountTypes.FETCH_ACCOUNT_COMPLETED]: (state, action) => {
    return action.response.data.user.user;
  },
  [accountTypes.UPDATE_ACCOUNT_COMPLETED]: (state, action) => {
    return action.response.data;
  },
  [accountTypes.FETCH_ACCOUNT_FAILED]: () => null,
  [accountTypes.UPDATE_2FA_COMPLETED]: (state, action) => {
    return action.response.data;
  },

  [types.LOGOUT]: () => null
});

const INITIAL_INTERCOM_STATE = {
  refresh_intercom: false,
  user_hash: null
};
const intercomReducer = createReducer(INITIAL_INTERCOM_STATE)({
  [goalsTypes.CREATE_COMPLETED]: state => {
    return {
      ...state,
      refresh_intercom: true
    };
  },
  [projectsTypes.CREATE_COMPLETED]: state => {
    return {
      ...state,
      refresh_intercom: true
    };
  },
  [statusUpdatesTypes.CREATE_COMPLETED]: state => {
    return {
      ...state,
      refresh_intercom: true
    };
  },
  [accountTypes.FETCH_ACCOUNT_COMPLETED]: (state, action) => {
    return {
      ...state,
      user_hash: action.response.data.intercom_hash
    };
  },
  [accountTypes.FETCH_ACCOUNT_FAILED]: () => INITIAL_INTERCOM_STATE,
  [types.LOGOUT]: () => INITIAL_INTERCOM_STATE
});

const currentMembershipReducer = createReducer(null)({
  [accountTypes.FETCH_ACCOUNT_COMPLETED]: (state, action) => {
    return action.response.data.membership;
  },
  [membershipsTypes.UPDATE_COMPLETED]: (state, action) => {
    const membership = action.response.data;
    // If we have updated the currentMembership, replace the value in the store
    if (state.id === membership.id) {
      return membership;
    }
    return state;
    //return action.response.data;
  },
  [accountTypes.FETCH_ACCOUNT_FAILED]: () => null,
  [types.LOGOUT]: () => null
});

const membershipsReducer = createReducer(null)({
  [accountTypes.FETCH_ACCOUNT_COMPLETED]: (state, action) => {
    const memberships = action.response.data.memberships;

    if (memberships.length > 0) {
      const workspaces = action.response.data.memberships.map(m => ({
        name: m.name,
        slug: m.slug
      }));

      workspaces.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      cookies.set(COOKIE_STORE, workspaces, {
        path: "/",
        domain: `.${cookieDomain}`,
        sameSite: "none",
        secure: true,
        expires: moment()
          .add(1, "month")
          .toDate()
      });
    }

    return action.response.data.memberships;
  },
  [accountTypes.FETCH_ACCOUNT_FAILED]: () => {
    cookies.remove(COOKIE_STORE, {
      path: "/",
      domain: `.${cookieDomain}`,
      secure: true
    });

    return null;
  },
  [types.LOGOUT]: () => {
    cookies.remove(COOKIE_STORE, {
      path: "/",
      domain: `.${cookieDomain}`,
      secure: true
    });
    return null;
  }
});

const currentWorkspaceReducer = createReducer(null)({
  [types.FETCH_CURRENT_WORKSPACE_COMPLETED]: (state, action) => {
    return action.response.data;
  },
  [types.CHECK_CURRENT_WORKSPACE_COMPLETED]: (state, action) => {
    return action.response.data;
  },
  [workspacesTypes.UPDATE_COMPLETED]: (state, action) => {
    return action.response.data;
  },
  [integrationsTypes.SLACK_UNINSTALL_COMPLETED]: (state, action) => {
    return action.response.data;
  },
  [integrationsTypes.JIRA_UNINSTALL_COMPLETED]: (state, action) => {
    return action.response.data;
  },
  [types.FETCH_CURRENT_WORKSPACE_FAILED]: (state, action) => {
    return null;
  },
  [workspacesTypes.FETCH_STRIPE_DETAILS_COMPLETED]: (state, action) => {
    if (action.response.data) {
      return {
        ...state,
        stripe_customer: action.response.data.stripe_customer,
        stripe_card: action.response.data.stripe_card,
        stripe_subscription: action.response.data.stripe_subscription
      };
    } else {
      return state;
    }
  }
});

/**
 * Users are logged out if:
 *     - They trigger the logout action.
 *     - Refreshing their credentials did not work.
 * Users are logged in if:
 *     - Login completed.
 *     - Refreshing the auth credentials worked (there aren't many use cases for this though).
 **/
const isAuthenticatedReducer = createReducer(false)({
  // Actions that log you out
  [types.CHECK_CURRENT_WORKSPACE_FAILED]: () => false,
  [types.EMAIL_CONFIRMATION_FAILED]: () => false,
  [types.GOOGLE_AUTH_FAILED]: () => false,
  [types.SLACK_AUTH_FAILED]: () => false,
  [types.LOGOUT]: () => false,
  [types.PASSWORD_SAVE_NEW_FAILED]: () => false,
  [types.REFRESH_TOKENS_FAILED]: () => false,
  [accountTypes.FETCH_ACCOUNT_FAILED]: () => false, // log you out if we couldn't fetch your account
  [accountTypes.DELETE_ACCOUNT_COMPLETED]: () => false, // log you out if we couldn't fetch your account
  // Actions that log you in
  [types.EMAIL_CONFIRMATION_COMPLETED]: () => true,
  [types.GOOGLE_AUTH_COMPLETED]: () => true,
  [types.SLACK_AUTH_COMPLETED]: () => true,
  [types.LOGIN_COMPLETED]: () => true,
  [types.MAGIC_LOGIN_COMPLETED]: () => true,
  [types.PASSWORD_SAVE_NEW_COMPLETED]: () => true,
  [types.REFRESH_TOKENS_COMPLETED]: () => true,
  [types.SET_SAML_TOKENS]: () => true
});

const redirectAfterPathReducer = createReducer("")({
  [types.SET_REDIRECT_AFTER_PATH]: (state, action) => action.redirectAfterPath,
  [types.REDIRECT_AFTER_LOGIN]: () => ""
});

// We're agressively deleting tokens if there's the slight auth issue.
const tokensReducer = createReducer(null)({
  // Actions that save your tokens
  [types.GOOGLE_AUTH_COMPLETED]: (state, action) => ({
    access_token: action.response.data.access_token,
    refresh_token: action.response.data.refresh_token
  }),
  [types.SLACK_AUTH_COMPLETED]: (state, action) => ({
    access_token: action.response.data.access_token,
    refresh_token: action.response.data.refresh_token
  }),
  [types.LOGIN_COMPLETED]: (state, action) => ({
    access_token: action.response.data.access_token,
    refresh_token: action.response.data.refresh_token
  }),
  [types.EMAIL_CONFIRMATION_COMPLETED]: (state, action) => ({
    access_token: action.response.data.access_token,
    refresh_token: action.response.data.refresh_token
  }),
  [types.PASSWORD_SAVE_NEW_COMPLETED]: (state, action) => ({
    access_token: action.response.data.access_token,
    refresh_token: action.response.data.refresh_token
  }),
  [types.MAGIC_LOGIN_COMPLETED]: (state, action) => {
    const access_token = action.response.data.access_token;
    const refresh_token = action.response.data.refresh_token;
    // decode the token to see if we need to store it here or not
    // We get the auth_type from the token itself.
    const { auth_type } = jwtDecode(access_token);

    // Return existing state if the auth_type of the token is not shared.
    // This is because it means the tokens are workspace-specific, and thus should be stored
    // at the workspace level using samlTokens
    if (auth_type !== "shared") {
      return state;
    }

    return {
      access_token,
      refresh_token
    };
  },
  [types.REFRESH_TOKENS_COMPLETED]: (state, action) => {
    // decode the token to see if we need to store it here or not
    const access_token = action.response.data.access_token;
    // We get the current_user params from the token itself.
    const { auth_type } = jwtDecode(access_token);

    // Return existing state if the auth_type of the token is not shared.
    // This is because it means the tokens are workspace-specific, and thus should be stored
    // at the workspace level using samlTokens
    if (auth_type !== "shared") {
      return state;
    }

    return {
      ...state,
      access_token: action.response.data.access_token
    };
  },
  // Actions that remove your tokens
  [types.CHECK_CURRENT_WORKSPACE_FAILED]: () => null,
  [types.GOOGLE_AUTH_FAILED]: () => null,
  [types.SLACK_AUTH_FAILED]: () => null,
  [types.LOGOUT]: () => null,
  [types.EMAIL_CONFIRMATION_FAILED]: () => null,
  [types.PASSWORD_SAVE_NEW_FAILED]: () => null,
  [types.REFRESH_TOKENS_FAILED]: () => null,
  [accountTypes.FETCH_ACCOUNT_FAILED]: () => null, // Remove your tokens if we couldn't fetch your account
  [accountTypes.DELETE_ACCOUNT_COMPLETED]: () => null // Remove your tokens if we couldn't fetch your account
});

const currentSubdomainReducer = createReducer(null)({
  [types.INITIALIZE_STARTED]: (state, action) => action.subdomain
});

const samlTokensReducer = createReducer({})({
  [types.SET_SAML_TOKENS]: (state, action) => ({
    ...state,
    [action.workspaceSlug]: action.tokens
  }),
  [types.CHECK_CURRENT_WORKSPACE_FAILED]: () => ({}),
  [types.LOGOUT]: () => ({}),
  [accountTypes.FETCH_ACCOUNT_FAILED]: () => ({}), // Remove your tokens if we couldn't fetch your account
  [accountTypes.DELETE_ACCOUNT_COMPLETED]: () => ({}), // Remove your tokens if we couldn't fetch your account
  [types.MAGIC_LOGIN_COMPLETED]: (state, action) => {
    // decode the token to see if we need to store it here or not
    const access_token = action.response.data.access_token;
    const refresh_token = action.response.data.refresh_token;
    // We get the current_user params from the token itself.
    const { auth_type, iss_workspace_slug } = jwtDecode(access_token);

    // Return existing state if the auth_type of the token is not shared.
    // This is because it means the tokens are workspace-specific, and thus should be stored
    // at the workspace level using samlTokens
    if (auth_type !== "restricted.sso") {
      return state;
    }

    return {
      ...state,
      [iss_workspace_slug]: {
        access_token,
        refresh_token
      }
    };
  },
  [types.REFRESH_TOKENS_COMPLETED]: (state, action) => {
    // decode the token to see if we need to store it here or not
    const access_token = action.response.data.access_token;
    // We get the current_user params from the token itself.
    const { auth_type, iss_workspace_slug } = jwtDecode(access_token);

    // Return existing state if the auth_type of the token is not shared.
    // This is because it means the tokens are workspace-specific, and thus should be stored
    // at the workspace level using samlTokens
    if (auth_type !== "restricted.sso") {
      return state;
    }

    const tokens = state[iss_workspace_slug];
    return {
      ...state,
      [iss_workspace_slug]: {
        ...tokens,
        access_token
      }
    };
  }
});

// Saves the last timestamp of when What's New was clicked
const whatsNewVisitedReducer = createReducer(null)({
  [types.WHATSNEW_VISITED]: () => moment().utc()
});

const discoveryFlagsReducer = createReducer(null)({
  [types.TOGGLE_FLAG]: () => moment().utc()
});

// Contains the list of recently visited stuff
const recentlyVisitedReducer = createReducer({})({
  [types.LOG_VISIT]: (state, action) => {
    const { page, subdomain } = action;

    // First we remove the page from the list of recently visited if it exist already

    if (!state[subdomain]) {
      state[subdomain] = [];
    }
    const subdomainState = state[subdomain].filter(p => page.pageUrl !== p.pageUrl);

    // Then we add the page add the beggining of the array
    subdomainState.unshift(page);

    // Finally if we have more than 3 items we pop the last one.
    if (subdomainState.length > 10) {
      subdomainState.pop();
    }

    state[subdomain] = subdomainState;

    return state;
  }
});

export default combineReducers({
  currentSubdomain: currentSubdomainReducer,
  currentUser: currentUserReducer,
  currentMembership: currentMembershipReducer,
  currentWorkspace: currentWorkspaceReducer,
  discoveryFlags: discoveryFlagsReducer,
  intercom: intercomReducer,
  isAppLoaded: isAppLoadedReducer,
  isAuthenticated: isAuthenticatedReducer,
  memberships: membershipsReducer,
  redirectAfterPath: redirectAfterPathReducer,
  recentlyVisited: recentlyVisitedReducer,
  tokens: tokensReducer,
  samlTokens: samlTokensReducer,
  whatsNewVisited: whatsNewVisitedReducer
});
