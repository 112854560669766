import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

export default function withAdmin(WrappedComponent) {
  const WithAdmin = props => {
    // Redirect to account if user doesn't have a fullname.
    if (!(props.currentMembership.role === "admin" || props.currentMembership.role === "owner")) {
      return <Redirect to="/" />;
    }

    return <WrappedComponent {...props} />;
  };

  const { any } = PropTypes;
  WithAdmin.propTypes = {
    currentMembership: any
  };

  const mapStateToProps = state => ({
    currentMembership: state.session.currentMembership
  });

  return connect(mapStateToProps)(WithAdmin);
}
