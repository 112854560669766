//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.6rem;

  &.white {
    g {
      stroke: #fff;
    }
  }
`;
export default (props: Object) => (
  <Logo viewBox="0 0 19 19" {...props}>
    <title id="Public-title">Public</title>
    <g
      id="Public-Components"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Public-Icon/global" transform="translate(-6.000000, -6.000000)" stroke="#324DD3" strokeWidth="2">
        <g id="Public-globe" transform="translate(7.000000, 7.000000)">
          <circle id="Public-Oval" cx="8.5" cy="8.5" r="8.5"></circle>
          <path
            d="M8.5,0 C10.6260883,2.32759996 11.8343398,5.34822877 11.9,8.5 C11.8343398,11.6517712 10.6260883,14.6724 8.5,17 C6.37391168,14.6724 5.16566015,11.6517712 5.1,8.5 C5.16566015,5.34822877 6.37391168,2.32759996 8.5,0 L8.5,0 Z"
            id="Public-Shape"
          ></path>
          <line x1="1" y1="6" x2="16" y2="6" id="Public-Shape-Copy"></line>
          <line x1="1" y1="11" x2="16" y2="11" id="Public-Shape2"></line>
        </g>
      </g>
    </g>
  </Logo>
);
