//@flow
import styled from "styled-components";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

export default styled.div`
  position: absolute;
  z-index: 999;
  width: 700px;
  top: ${spacing.x2};
  left: calc(50% - 350px);
  border-radius: 2px;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);

  background-color: ${colors.infoBg};
  border: 2px solid ${colors.blue};
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  padding: ${spacing.x2} ${spacing.x3};
  margin-bottom: ${spacing.x2};
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease 0.4s, opacity 0.2s ease;

  &.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.2s ease;
  }

  &.success {
    background-color: ${colors.successBg};
    border: 1px solid ${colors.green};
  }

  &.warning {
    background-color: ${colors.warningBg};
    border: 1px solid ${colors.yellow};
  }

  &.danger {
    background-color: ${colors.dangerBg};
    border: 1px solid ${colors.red};
  }
`;
