// @flow
import { createReducer } from "state/utils";
import * as sessionTypes from "state/ducks/session/types";
import * as uiTypes from "./types";

const INITIAL_STATE = {
  error: false,
  isFetching: true,
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [sessionTypes.GOOGLE_AUTH_FAILED]: (state, action) => {
    const errors = action.error.response.data.error;
    return {
      ...state,
      error: true,
      isFetching: false,
      message: errors,
      messageType: "warning"
    };
  },
  [sessionTypes.GOOGLE_AUTH_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    messageType: "success",
    message: "You have successfully signed in."
  }),
  [sessionTypes.GOOGLE_AUTH_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: ""
  }),
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
