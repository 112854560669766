import styled from "styled-components";
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

export default styled.div`
  span {
    display: block;
    margin: ${spacing.x1} 0;
  }

  .radio-group {
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    max-height: 3.8rem;
    height: 3.8rem;
    position: relative;
    border: 1px solid ${colors.inputBorder};

    input[type="radio"] {
      position: absolute;
      visibility: hidden;
      display: none;
    }

    label {
      color: #6a6a6a;
      background-color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 100%;
      height: 100%;
      border: 0;
      padding: 0 ${spacing.x2};
      transition: all 0.5s ease;
      white-space: nowrap;

      &.disabled {
        cursor: not-allowed;
        filter: grayscale(0.7);
        opacity: 0.7;
      }
    }

    &.compact {
      label {
        line-height: 3.2rem;
        height: 3.2rem;
      }
    }

    input[type="radio"]:checked + label {
      color: #fff;
      background: ${colors.blue};
      &.red {
        background: ${colors.red};
      }
      &.yellow {
        background: ${colors.yellow};
        color: ${colors.black};
      }
      &.green {
        background: ${colors.green};
      }

      svg {
        g {
          stroke: #fff;
        }
      }
    }

    &.text {
      label {
        padding: 0 ${spacing.x1};
      }
      input[type="radio"]:checked + label {
      }
    }

    label + input[type="radio"] + label {
      border-left: solid 1px ${colors.inputBorder};
    }
  }

  &.icons-only {
    .radio-group {
      display: flex;
      align-items: center;
    }
    label {
      display: flex;
      align-items: center;
    }
  }

  &.dark {
    .radio-group {
      border: 1px solid #599da0;
    }
    label + input[type="radio"] + label {
      border-left: solid 1px #599da0;
    }
    label {
      background-color: ${colors.black};
    }

    input[type="radio"]:checked + label {
      background: #599da0;
      svg {
        g {
          stroke: #fff;
        }
      }
    }

    svg {
      g {
        stroke: #599da0;
      }
    }
  }
`;
