// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { accountOperations } from "state/ducks/account";
import * as squadTypes from "squadTypes";
import moment from "moment";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import AccountSettingsNav from "v2/components/AccountSettingsNav";
import Avatar from "v2/components/Avatar";
import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm } from "redux-form";
import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: ${colors.blockBorder};
  }
`;

const AvatarUploader = styled.div`
  margin-top: ${spacing.x1};
  .actions {
    display: flex;
    margin: ${spacing.x1} 0;

    button {
      margin-right: ${spacing.x2};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

type Props = {
  currentUser: squadTypes.User,
  currentMembership: squadTypes.Membership,
  fetchAccountDetails: Function,
  handleSubmit: Function,
  memberships: Array<squadTypes.Membership>,
  ui: Object,
  updateAccount: Function,
  uploadAsset: Function,
  uploadedAsset: any
};

type State = {
  avatar_url: string
};

class AccountSettings extends Component<Props, State> {
  // By default the flag message is not visible.
  state = {
    avatar_url: this.props.currentUser.avatar_url
  };

  // No need to fetch the currentUser on componentDidMount because it's fetched
  // in one of the routes wrapper.

  componentWillReceiveProps(nextProps) {
    const { uploadedAsset } = nextProps;

    // If we've uploaded an asset, and this asset url is different from the avatar
    if (uploadedAsset && uploadedAsset.url && this.state.avatar_url !== uploadedAsset.url) {
      this.setState(
        {
          avatar_url: uploadedAsset.url
        },
        () => {
          this.updateAvatar();
        }
      );
    }
  }

  // This function pops up the file uploader
  handleUploadClick = e => {
    e.preventDefault();
    this.refs.fileUploader.click();
  };

  // This function uploads the file to S3
  handleUploadFile = event => {
    const file = event.target.files[0];
    this.props.uploadAsset(file);
  };

  updateAccount = accountValues => {
    const { fullname } = accountValues;
    const userParams = {
      fullname
    };
    this.props.updateAccount(userParams);
  };

  updateAvatar = () => {
    const { avatar_url } = this.state;
    const userParams = {
      avatar_url
    };
    this.props.updateAccount(userParams);
  };

  removeAvatar = () => {
    const userParams = {
      avatar_url: ""
    };
    this.props.updateAccount(userParams);
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  render() {
    const { currentUser, currentMembership, memberships, handleSubmit, ui } = this.props;
    const { errorFields } = ui;
    const personalDataObject = {
      account: { ...currentUser, refresh_token: null },
      memberships
    };
    const personalData = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(personalDataObject));

    // Check if we need to display the flag, and will use a CSS class to do that.
    // We're using CSS to animate the flag instead of completely removing the
    // component.

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Update";

    return (
      <WorkspaceLayout secondNav={<AccountSettingsNav user={currentUser} />}>
        <Container>
          <Header>
            <h1>Account details</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <FormField>
              <label htmlFor="avatar_url">Profile picture</label>
              <AvatarUploader>
                {ui.avatarState === "requesting" && <Loader />}
                {ui.avatarState !== "requesting" && <Avatar size={80} user={currentUser} pictureOnly={true} />}
                <div className="actions">
                  <button onClick={this.handleUploadClick} className="primary">
                    Upload picture
                  </button>
                  <button onClick={this.removeAvatar}>Remove picture</button>
                </div>
                <input
                  type="file"
                  id="file"
                  ref="fileUploader"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={this.handleUploadFile}
                />
              </AvatarUploader>
            </FormField>
            <hr />
            <form onSubmit={handleSubmit(this.updateAccount)}>
              <FormField>
                <label htmlFor="fullname">Full name</label>
                <Field
                  component="input"
                  type="text"
                  className={`tiny ${this.getErrorClass("fullname")}`}
                  name="fullname"
                />
                <span className={`${this.getErrorClass("fullname")}`}>
                  <small>{errorFields["fullname"]}</small>
                </span>
              </FormField>
              {currentMembership && (
                <FormField>
                  <label htmlFor="joined">Joined</label>
                  <span>{moment(currentMembership.created_at).fromNow()}</span>
                </FormField>
              )}
              <FormActions>
                <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                  {submitText}
                </button>
              </FormActions>
            </form>
            <hr />
            <a href={`data:${personalData}`} download={`tability-data.json`}>
              Download your personal data
            </a>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  currentMembership: state.session.currentMembership,
  initialValues: state.session.currentUser,
  memberships: state.session.memberships,
  ui: state.ui.accountSettings,
  uploadedAsset: state.account.uploadedAsset
});

const mapDispatchToProps = {
  updateAccount: accountOperations.updateAccount,
  uploadAsset: accountOperations.uploadAssetDance
};

const _AccountSettings = reduxForm({
  form: "accountSettings"
})(AccountSettings);

export default connect(mapStateToProps, mapDispatchToProps)(_AccountSettings);
