// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { accountOperations } from "state/ducks/account";
import { notificationsOperations } from "state/ducks/notifications";
import { sessionOperations } from "state/ducks/session";
import * as squadTypes from "squadTypes";

import { withAdmin, withOwner, withWorkspaceCheck } from "v2/routes/wrappers";

import Loader from "v2/components/Loader";

import * as pages from "v2/pages";
import Forbidden from "v2/components/Forbidden";
import OTPRequired from "v2/components/OTPRequired";
import Suspended from "v2/components/Suspended";

import NotFound from "v2/components/NotFound";

const routes = [
  {
    path: "/jira/goals",
    component: withWorkspaceCheck(pages.JiraConnectV2Goals),
    exact: true
  },
  {
    path: "/goals",
    component: withWorkspaceCheck(pages.GoalList),
    exact: true
  },
  {
    path: "/goals/:filter",
    component: withWorkspaceCheck(pages.GoalList),
    exact: true
  },
  {
    path: "/pages",
    component: withWorkspaceCheck(pages.ProjectList),
    exact: true
  },
  {
    path: "/pages/:project/settings",
    component: withWorkspaceCheck(pages.ProjectSettings),
    exact: true
  },
  {
    path: "/pages/:project/settings/delete",
    component: withWorkspaceCheck(pages.ProjectSettingsDelete),
    exact: true
  },
  {
    path: "/pages/:project/settings/notifications",
    component: withWorkspaceCheck(pages.ProjectSettingsNotifications),
    exact: true
  },
  {
    path: "/pages/:project/preview", // This is the public preview
    component: withWorkspaceCheck(pages.ProjectPreview),
    exact: true
  },

  {
    path: "/p/:project", // This is the public preview
    component: withWorkspaceCheck(pages.ProjectPreview, true),
    exact: true
  },
  {
    path: "/p/:project/tv", // This is the TV mode of the public preview
    component: withWorkspaceCheck(pages.ProjectPreviewTV, true),
    exact: true
  },
  {
    path: "/pages/:project",
    component: withWorkspaceCheck(pages.ProjectDetails),
    exact: true
  },
  {
    path: "/pages/:project/tasks",
    component: withWorkspaceCheck(pages.ProjectTasks),
    exact: true
  },
  {
    path: "/pages/:project/:goal",
    component: withWorkspaceCheck(pages.ProjectDetails),
    exact: true
  },
  {
    path: "/pages/:project/:goal/comments",
    component: withWorkspaceCheck(pages.ProjectDetails),
    exact: true
  },
  {
    path: "/pages/:project/:goal/:statusUpdate",
    component: withWorkspaceCheck(pages.ProjectDetails),
    exact: true
  },
  {
    path: "/team",
    component: withWorkspaceCheck(pages.MembershipList),
    exact: true
  },
  {
    path: "/team/:membership",
    component: withWorkspaceCheck(pages.MembershipHome),
    exact: true
  },
  {
    path: "/notifications",
    component: withWorkspaceCheck(pages.NotificationList),
    exact: true
  },
  {
    path: "/settings",
    component: withWorkspaceCheck(withAdmin(pages.WorkspaceSettings)),
    exact: true
  },
  {
    path: "/settings/security",
    component: withWorkspaceCheck(withAdmin(pages.WorkspaceSettingsSecurity)),
    exact: true
  },
  {
    path: "/settings/sso",
    component: withWorkspaceCheck(withAdmin(pages.WorkspaceSettingsSSO)),
    exact: true
  },
  {
    path: "/settings/notifications",
    component: withWorkspaceCheck(withAdmin(pages.WorkspaceSettingsNotifications)),
    exact: true
  },

  {
    path: "/settings/billing",
    component: withWorkspaceCheck(withOwner(pages.WorkspaceSettingsBilling)),
    exact: true
  },
  {
    path: "/settings/plan",
    component: withWorkspaceCheck(withOwner(pages.WorkspaceSettingsPlan)),
    exact: true
  },
  {
    path: "/settings/plan/confirm",
    component: withWorkspaceCheck(withOwner(pages.WorkspaceSettingsPlanConfirm)),
    exact: true
  },
  {
    path: "/settings/plan/changed",
    component: withWorkspaceCheck(withOwner(pages.WorkspaceSettingsPlanChanged)),
    exact: true
  },
  {
    path: "/settings/delete",
    component: withWorkspaceCheck(withOwner(pages.WorkspaceSettingsDelete)),
    exact: true
  },
  {
    path: "/settings/integrations",
    component: withWorkspaceCheck(withAdmin(pages.WorkspaceSettingsIntegrations)),
    exact: true
  },
  {
    path: "/settings/users",
    component: withWorkspaceCheck(withAdmin(pages.WorkspaceSettingsMembershipList)),
    exact: true
  },
  {
    path: "/settings/users/:membership",
    component: withWorkspaceCheck(withAdmin(pages.WorkspaceSettingsMembershipDetails)),
    exact: true
  },
  {
    path: "/terms",
    component: withWorkspaceCheck(pages.AcceptTerms),
    exact: true
  },
  {
    path: "/onboarding",
    component: withWorkspaceCheck(pages.Onboarding),
    exact: true
  },
  {
    path: "/onboarding/:step",
    component: withWorkspaceCheck(pages.WorkspaceOnboarding),
    exact: true
  },
  {
    path: "/join",
    component: withWorkspaceCheck(pages.WorkspaceJoin),
    exact: true
  },
  {
    path: "/",
    component: withWorkspaceCheck(pages.WorkspaceHome),
    exact: true
  },
  {
    path: "/account",
    component: withWorkspaceCheck(pages.AccountSettings),
    exact: true
  },
  {
    path: "/account/2fa",
    component: withWorkspaceCheck(pages.AccountSettings2FA),
    exact: true
  },
  {
    path: "/account/email",
    component: withWorkspaceCheck(pages.AccountSettingsEmail),
    exact: true
  },
  {
    path: "/account/leave",
    component: withWorkspaceCheck(pages.AccountSettingsLeave),
    exact: true
  },
  {
    path: "/account/notifications",
    component: withWorkspaceCheck(pages.AccountSettingsNotifications),
    exact: true
  },
  {
    path: "/account/password",
    component: withWorkspaceCheck(pages.AccountSettingsPassword),
    exact: true
  },
  {
    component: NotFound
  }
];

type Props = {
  checkCurrentWorkspace: Function,
  currentUser: squadTypes.User,
  fetchAccount: Function,
  fetchUnseenCount: Function,
  currentMembership: squadTypes.Membership,
  currentWorkspace: squadTypes.Workspace,
  isAuthenticated: boolean,
  location: Object,
  setRedirectAfterPath: Function
};

class WorkspacePrivateRoutes extends Component<Props> {
  componentDidMount() {
    const { checkCurrentWorkspace, currentUser, fetchAccount, location, setRedirectAfterPath } = this.props;

    // Set the redirection in case the user is logged out
    setRedirectAfterPath(window.location.pathname);

    // Then we perform a check on the workspace to get the right state
    if (location.pathname && location.pathname.indexOf("/p/") >= 0) {
      if (!currentUser) {
        fetchAccount();
      }
      this.props.fetchUnseenCount();
    } else {
      checkCurrentWorkspace().then(action => {
        if (!currentUser) {
          fetchAccount();
        }
        this.props.fetchUnseenCount();
      });
    }
  }

  _renderRoutes = () => {
    return (
      <Switch>
        {routes.map(route => (
          <Route key={route.path || "notfound"} {...route} />
        ))}
      </Switch>
    );
  };

  render() {
    const { currentMembership, currentUser, currentWorkspace, location, isAuthenticated } = this.props;

    if (location.pathname && location.pathname.indexOf("/p/") >= 0) {
      return this._renderRoutes();
    }

    // Redirect to login if not authenticated
    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }

    // Wait for the current user to be loaded
    if (!currentUser) {
      return <Loader />;
    }

    // ########## MEMBERSHIP CHECK ################

    // Now, the user might not be a member of the workspace, so we need to check that and
    // decide if we want to display the join page, or if we can let them into the workspace
    // (note that the API is protected, so if this checks fails the UI will be broken anyway)
    if (!currentMembership && location.pathname !== "/join") {
      return <Redirect to="/join" />;
    }

    // Render the routes if we're on the join page. We have to do this here as the next checks
    // require the user to have a membership.
    // (I did not render the page directly here to keep using the standard route rendering)
    if (location.pathname === "/join") {
      return this._renderRoutes();
    }

    // ########## WORKSPACE CHECK FOR SUSPENDED STATE ################

    // Display suspended message if workspace is suspended
    if (
      currentWorkspace.is_suspended &&
      !location.pathname.includes("/onboarding") && // Don't redirect in onboarding
      !location.pathname.includes("/account") && // Don't redirect on /account pages
      !location.pathname.includes("/settings") // Don't redirect on settings pages
    ) {
      return <Suspended membership={currentMembership} />;
    }

    // ########## ACCOUNT CHECKS (OTP, ACCESS RESTRICTED) ################

    // Display 2FA required message if workspace has 2FA required
    // This needs to be before the restricted message to make sure we redirect to 2FA
    if (
      currentWorkspace.otp_required &&
      currentWorkspace.auth_type === "shared" &&
      !currentUser.otp_required_for_login &&
      !location.pathname.includes("/onboarding") && // Don't redirect in onboarding
      !location.pathname.includes("/account") && // Don't redirect on /account pages
      !location.pathname.includes("/settings") // Don't redirect on settings pages
    ) {
      return <OTPRequired user={currentUser} />;
    }

    // Display restricted message if workspace is forbidden to current user
    if (
      currentWorkspace.access_restricted &&
      !location.pathname.includes("/onboarding") && // Don't redirect in onboarding
      !location.pathname.includes("/account") && // Don't redirect on /account pages
      !location.pathname.includes("/settings") // Don't redirect on settings pages
    ) {
      return <Forbidden membership={currentMembership} />;
    }

    // ######### ONBOARDING CHECKS #######################

    // Ok! If you got here it means that your user is ready to onboard the platform. We'll need to:
    // 1. Make sure that they have their account set up (fullname)
    // 2. Make sure that they have accepeted the terms

    // Let's check if they have a fullname!
    if (currentMembership && !currentUser.fullname && location.pathname !== "/onboarding") {
      // Redirect to onboarding so that the user can update their profile
      return <Redirect to="/onboarding" />;
    }

    // Let's check if they have accepted the terms
    if (
      !currentUser.privacy_policy_accepted &&
      location.pathname !== "/terms" && // Don't redirect in terms
      location.pathname !== "/onboarding" && // Don't redirect in onboarding
      !location.pathname.includes("/account") && // Don't redirect on /account pages
      !location.pathname.includes("/settings") // Don't redirect on settings pages
    ) {
      // Redirect to onboarding so that the user can update their profile
      return <Redirect to="/terms" />;
    }

    // Done! Now we can render the routes properly.

    return this._renderRoutes();
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.session.currentUser,
    currentWorkspace: state.session.currentWorkspace,
    isAuthenticated: state.session.isAuthenticated,
    currentMembership: state.session.currentMembership
  };
};

const mapDispatchToProps = {
  checkCurrentWorkspace: sessionOperations.checkCurrentWorkspace,
  fetchAccount: accountOperations.fetchAccount,
  fetchUnseenCount: notificationsOperations.fetchUnseenCount,
  setRedirectAfterPath: sessionOperations.setRedirectAfterPath
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacePrivateRoutes));
