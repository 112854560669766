import React, { Component } from "react";
import { connect } from "react-redux";
import { goalSubscriptionsOperations, goalSubscriptionsSelectors } from "state/ducks/goalSubscriptions";
import { subscriptionsSelectors } from "state/ducks/subscriptions";
import * as squadTypes from "squadTypes";

import { spacing } from "v2/components/styles/spacing";

// Components
import ButtonIcon from "v2/components/ButtonIcon";
import IconEyeOn from "v2/components/svg/IconEyeOn";
import IconEyeOff from "v2/components/svg/IconEyeOff";
import ReactTooltip from "react-tooltip";

type Props = {
  createGoalSubscription: Function,
  currentGoal: squadTypes.Goal,
  currentGoalSubscription: ?squadTypes.GoalSubscription,
  deleteGoalSubscription: Function,
  className?: string
};

class GoalSubscription extends Component<Props> {
  toggleGoalSubscription = e => {
    e.preventDefault();
    const { currentGoalSubscription } = this.props;
    currentGoalSubscription ? this.deleteGoalSubscription() : this.createGoalSubscription();
  };

  deleteGoalSubscription = e => {
    const { currentGoalSubscription } = this.props;
    if (currentGoalSubscription) {
      this.props.deleteGoalSubscription(currentGoalSubscription.goal_id, currentGoalSubscription.id);
    }
  };

  createGoalSubscription = e => {
    const { currentGoal } = this.props;

    const goalSubscriptionParams = {
      goal_id: currentGoal.id
    };
    this.props.createGoalSubscription(goalSubscriptionParams);
  };

  render() {
    const { currentGoalSubscription, currentProjectSubscription } = this.props;

    if (currentProjectSubscription) {
      return (
        <div
          className={this.props.className}
          data-tip
          data-for="goalSubscriptionTip"
          style={{ marginRight: spacing.x1 }}
        >
          <ButtonIcon className="goalSubscription" disabled={true}>
            <IconEyeOn />
          </ButtonIcon>
          <ReactTooltip id="goalSubscriptionTip" place="bottom" type="dark" effect="solid" delayShow={200}>
            You're subscribed to this goal through its parent page.
          </ReactTooltip>
        </div>
      );
    }
    return (
      <div className={this.props.className}>
        <ButtonIcon
          data-tip
          data-for="goalSubscriptionTip"
          className="goalSubscription"
          onClick={this.toggleGoalSubscription}
        >
          {currentGoalSubscription ? <IconEyeOn /> : <IconEyeOff />}
        </ButtonIcon>
        <ReactTooltip id="goalSubscriptionTip" place="bottom" type="dark" effect="solid" delayShow={200}>
          {currentGoalSubscription ? (
            <span>You’re watching this goal</span>
          ) : (
            <span>You are not watching this goal</span>
          )}
        </ReactTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProjectSubscription: subscriptionsSelectors.getListByParam(
    state.subscriptions.byId,
    "project_id",
    props.currentGoal.project_id
  )[0],
  currentGoalSubscription: goalSubscriptionsSelectors.getListByParam(
    state.goalSubscriptions.byId,
    "goal_id",
    props.currentGoal.id
  )[0]
});

const mapDispatchToProps = {
  createGoalSubscription: goalSubscriptionsOperations.createGoalSubscription,
  deleteGoalSubscription: goalSubscriptionsOperations.deleteGoalSubscription
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalSubscription);
