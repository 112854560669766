// @flow
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "state/utils";

/* State shape
{
    grouping: string,
    range: string,
}
*/

const projectTourDisplayedReducer = createReducer(false)({
  [types.PROJECT_TOUR_DISPLAYED]: () => {
    return true;
  }
});

export default combineReducers({
  projectTourDisplayed: projectTourDisplayedReducer
});
