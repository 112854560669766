// @flow
import { createReducer } from "state/utils";
import * as reducerUtils from "state/utils/reducers";
import * as types from "./types";
import * as projectTypes from "../projects/types";

import { INITIAL_LIST_STATE } from "config/constants";

const normalizedStateReducer = createReducer(INITIAL_LIST_STATE)({
  // Add single object to the reducer
  [types.CREATE_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  [types.FETCH_DETAILS_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  [types.UPDATE_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  // Remove single object from the reducer
  [types.FETCH_DETAILS_FAILED]: (state, action) => reducerUtils.removeObject(state, action),
  [types.DELETE_COMPLETED]: (state, action) => reducerUtils.removeObject(state, action),
  // Get list of objects
  [types.FETCH_LIST_COMPLETED]: (state, action) => reducerUtils.addList(state, action),
  [projectTypes.FETCH_TASKS_COMPLETED]: (state, action) => reducerUtils.addList(state, action),
  [types.RESET_LIST]: (state, action) => INITIAL_LIST_STATE
});

export default normalizedStateReducer;
