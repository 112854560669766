// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";

import { spacing } from "v2/components/styles/spacing";

import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";
import { Link } from "react-router-dom";
import TabbyWorried from "v2/components/_assets/tabbot-worried.png";
// Components

const Container = styled.div`
  .modal-content {
    padding: 20px;
    font-size: 1.8rem;
  }
  .modal-image {
    text-align: center;
    margin: ${spacing.x3} 0;
    img {
      width: 60px;
    }
  }
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentWorkspace: squadTypes.Workspace,
  hideForm: Function
};

type State = {};

class PagesQuotaReached extends Component<Props, State> {
  handleEscPress = e => {
    if (e.keyCode === 27) {
      this.props.hideForm(e);
    }
  };

  handleClose = e => {
    e.preventDefault();
    this.props.hideForm(e);
  };

  render() {
    const { currentMembership, currentWorkspace } = this.props;

    const isOwner = currentMembership.role === "owner";
    return (
      <Container className="modal-wrapper">
        <div className="modal-header">
          <h2>Your quota of pages has been reached</h2>
          <ButtonIcon onClick={this.handleClose}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <div className="modal-image">
            <img src={TabbyWorried} alt="" />
          </div>
          <p>Sorry, you cannot create more pages as you have reached the quota allowed for your plan.</p>
          <p>
            You are currently using{" "}
            <b>
              {currentWorkspace.active_projects_count} page
              {currentWorkspace.active_projects_count > 1 && <span>s</span>}
            </b>{" "}
            and your quota is{" "}
            <b>
              {currentWorkspace.active_projects_limit} active page
              {currentWorkspace.active_projects_limit > 1 && <span>s</span>}
            </b>
            .
          </p>
          <p>
            You can archive old pages to reduce the number of active pages, or you can &nbsp;
            {isOwner && (
              <Link to="/settings/billing" onClick={e => this.props.hideForm(e)}>
                upgrade your workspace{" "}
              </Link>
            )}
            {!isOwner && <span>upgrade your workspace </span>}
            to increase your quota.
          </p>
        </div>
        <div className="modal-footer">
          {isOwner && (
            <Link className="button primary" to="/settings/billing" onClick={e => this.props.hideForm(e)}>
              upgrade your workspace
            </Link>
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentMembership: state.session.currentMembership,
  currentWorkspace: state.session.currentWorkspace
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PagesQuotaReached);
