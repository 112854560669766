// @flow

/**
 * This here is a project panel. It takes a projectId as argument and displays
 * the children project at that ID. If id is null, then we display root projects.
 *
 * If there's a project ID, we will display a progress summary under the child
 * projects.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { projectsOperations } from "state/ducks/projects";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import * as constants from "config/constants";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";
// Components
import IconPlus from "v2/components/svg/IconPlus";
import ButtonIcon from "v2/components/ButtonIcon";
import ReactTooltip from "react-tooltip";

const Panel = styled.div`
  padding: ${spacing.x2};
  border: 1px solid ${colors.blockBorder};
  background-color: #fff;
  border-radius: 8px;
`;
const PanelInnerWrapper = styled.div`
  margin: 0 ${spacing.x1};

  &.space-1 {
    margin-top: ${spacing.x1};
    margin-left: ${spacing.x1};
  }

  &.space-2 {
    margin-top: ${spacing.x1};
    margin-left: ${spacing.x5};
  }
`;

const ChildrenList = styled.ul`
  margin-bottom: ${spacing.x2} 0;
  li {
    cursor: pointer;
    padding: 0 ${spacing.x1};
    height: 4rem;
    line-height: 4rem;

    button {
      font-size: 1.6rem;
      color: ${colors.black};
      &:focus {
        border: 0;
      }
      &:hover {
        text-decoration: none;
        color: ${colors.black};
      }
    }

    &:hover,
    &.selected,
    &:focus-within {
      font-weight: 600;
    }
    &.selected {
      font-weight: 600;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .panel-project-tree {
      border-left: 1px solid #d1d1d1;
      height: 4rem;
      width: 1.2rem;

      margin-right: ${spacing.x1};

      &::after {
        content: "";
        display: block;
        background-color: #d1d1d1;
        width: 1.2rem;
        height: 1px;
        margin-top: 20px;
      }
    }

    .panel-project-progress {
      font-weight: 600;
      margin-left: ${spacing.x1};
    }

    .panel-project-title {
      display: block;
      flex: 1;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.space-1 {
      margin-left: ${spacing.x2};
    }

    &.space-2 {
      margin-left: ${spacing.x6};
    }
  }
`;

const ArchivedLabel = styled.span`
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  font-weight: 500;
  color: #fff;
  background-color: #955be3;
  border-radius: 4px;
  padding: 0 ${spacing.x1};
  margin-right: ${spacing.x1};
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  currentProject: squadTypes.Project,
  fetchProjectDetails: Function,
  fetchProjectList: Function,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  push: Function,
  selectChildProject: Function,
  showModal: Function,
  toggleProjectNav: Function,
  ui: Object
};

class ProjectPanel extends Component<Props> {
  navToProject = (e: Object) => {
    e.preventDefault();

    const projectId = e.currentTarget.dataset.id;
    const url = `/pages/${projectId}`;
    this.props.push(url);
  };

  _showProjectNewForm = (e: Object) => {
    e.preventDefault();
    const { currentWorkspace, currentProject } = this.props;

    let pageQuotaExceeded = false;
    // Check if we have exceeded the quota of pages.
    // This is only applied to plans that have the new billing packages
    if (
      currentWorkspace.active_projects_limit !== 0 &&
      currentWorkspace.active_projects_count >= currentWorkspace.active_projects_limit &&
      !constants.BILLING_PER_USER_PLANS.includes(currentWorkspace.stripe_cache_subscription_plan)
    ) {
      pageQuotaExceeded = true;
    }

    if (pageQuotaExceeded) {
      this.props.showModal({
        contentType: "workspace",
        contentAction: "pages.quota.reached"
      });
    } else {
      this.props.showModal({
        contentType: "project",
        contentAction: "new",
        object: currentProject
      });
    }
  };

  componentDidMount() {
    this._fetchProjects();
  }

  _fetchProjects = () => {
    const { currentProject, fetchProjectDetails, fetchProjectList } = this.props;
    if (currentProject && currentProject.parent_id) {
      fetchProjectDetails(currentProject.parent_id);
    }
    if (currentProject.count_of_children_projects) {
      const params = {
        parent: currentProject.id,
        show_archived: currentProject.is_archived
      };
      fetchProjectList(params);
    }
  };

  // Fetch Project list if the show_archived has changed.
  componentDidUpdate(prevProps) {
    if (
      prevProps.currentProject !== this.props.currentProject ||
      prevProps.ui.showArchived !== this.props.ui.showArchived
    ) {
      this._fetchProjects();
    }
  }

  render() {
    const { projects, currentProject, ui } = this.props;

    const parent_id = currentProject ? currentProject.id : null;
    let parent = null;
    const show_archived = ui.showArchived;
    const projectToDisplay = projects.allIds.filter(projectId => {
      const projectToTest = projects.byId[projectId];
      if (show_archived) {
        return projectToTest.parent_id === parent_id;
      } else {
        return projectToTest.parent_id === parent_id && projectToTest.is_archived === false;
      }
    });

    // Use to space the list properly
    let classNameLevel = 0;
    if (currentProject) {
      parent = projects.byId[currentProject.parent_id];
    }
    if (parent) {
      classNameLevel = 1;
    }

    // Used to have some kind of id for the react tooltip
    const tooltipId = currentProject ? `panel-${currentProject.id}-create-button` : "panel-root-create-button";
    return (
      <Panel>
        <PanelInnerWrapper>
          <h3>Related pages</h3>
        </PanelInnerWrapper>
        <ChildrenList>
          {parent && (
            <li onClick={this.navToProject} data-id={parent.id} className="space-0">
              <div className="panel-project-title">
                <button className="link-button" onClick={this.navToProject} data-id={parent.id}>
                  {parent.title}
                </button>
              </div>
              <div className="panel-project-progress">
                {parent.is_archived && <ArchivedLabel>Archived</ArchivedLabel>}
              </div>
            </li>
          )}
          {currentProject && (
            <li onClick={this.navToProject} data-id={currentProject.id} className={`selected space-${classNameLevel}`}>
              {parent && <div className="panel-project-tree" />}
              <div className="panel-project-title">
                <button className="link-button" onClick={this.navToProject} data-id={currentProject.id}>
                  {currentProject.title}
                </button>
              </div>
              <div className="panel-project-progress">
                {currentProject.is_archived && <ArchivedLabel>Archived</ArchivedLabel>}
              </div>
            </li>
          )}
          {projectToDisplay.map(projectId => {
            const project = projects.byId[projectId];
            if (project) {
              return (
                <li
                  key={`p-${project.id}`}
                  onClick={this.navToProject}
                  data-id={project.id}
                  className={`space-${classNameLevel + 1}`}
                >
                  <div className="panel-project-tree" />
                  <div className="panel-project-title">
                    <button className="link-button" onClick={this.navToProject} data-id={project.id}>
                      {project.title}
                    </button>
                  </div>
                  <div className="panel-project-progress">
                    {project.is_archived && <ArchivedLabel>Archived</ArchivedLabel>}
                  </div>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ChildrenList>
        <PanelInnerWrapper className={`space-${classNameLevel + 1}`}>
          <ButtonIcon onClick={this._showProjectNewForm} className="colored compact" data-tip data-for={tooltipId}>
            <IconPlus />
          </ButtonIcon>
          <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
            Add a child page
          </ReactTooltip>
        </PanelInnerWrapper>
      </Panel>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  projects: state.projects,
  ui: state.ui.projectList
});

const mapDispatchToProps = {
  fetchProjectDetails: projectsOperations.fetchProjectDetails,
  fetchProjectList: projectsOperations.fetchProjectList,
  push,
  selectChildProject: uiOperations.selectChildProject,
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPanel);
