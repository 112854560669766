//@flow
import { request } from "state/utils";
import * as types from "./types";
import { getSubdomain } from "state/utils/url";

const slug = getSubdomain() || "";

export const selectGoal = (goalId: string) => ({
  type: types.SELECT,
  selectedId: goalId
});

export const unSelectGoal = () => ({
  type: types.UNSELECT,
  selectedId: null
});

export const fetchGoalSearch = (params?: Object) => {
  const page = params ? params.page || 1 : 1;
  const show_archived = params ? params.show_archived || null : null;
  const encodedParams = params ? btoa(JSON.stringify(params)) : "";
  return {
    types: [types.SEARCH_REQUESTED, types.SEARCH_COMPLETED, types.SEARCH_FAILED],
    callAPI: () => request.post(`/workspaces/${slug}/goals?page=${page}`, { filter: encodedParams, show_archived })
  };
};

export const fetchJiraConnectGoals = (slug: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  const show_archived = params ? params.show_archived || null : null;
  const encodedParams = params ? btoa(JSON.stringify(params)) : "";
  return {
    types: [types.SEARCH_REQUESTED, types.SEARCH_COMPLETED, types.SEARCH_FAILED],
    callAPI: () => request.post(`/workspaces/${slug}/goals?page=${page}`, { filter: encodedParams, show_archived })
  };
};

export const fetchGoalSearchStats = (params?: Object) => {
  const show_archived = params ? params.show_archived || null : null;
  const encodedParams = params ? btoa(JSON.stringify(params)) : "";
  return {
    types: [types.STATS_REQUESTED, types.STATS_COMPLETED, types.STATS_FAILED],
    callAPI: () => request.post(`/workspaces/${slug}/goals/stats`, { filter: encodedParams, show_archived })
  };
};

export const fetchGoalDetails = (goalId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/goals/${goalId}`)
  };
};

export const resetGoalList = () => ({
  type: types.RESET_LIST
});
