// @flow
import { createReducer } from "state/utils";
import * as sessionTypes from "state/ducks/session/types";
import * as uiTypes from "state/ducks/ui/types";

const INITIAL_STATE = {
  isFetching: false,
  error: false,
  message: "",
  messageType: "warning",
  isSendingConfirmation: false,
  needConfirmation: false
};

export default createReducer(INITIAL_STATE)({
  [sessionTypes.PASSWORD_SAVE_NEW_FAILED]: (state, action) => {
    let message = "Sorry, we could not save your new password";
    let needConfirmation = false;
    const errorList = action.error.response.data.errors;
    const errorString = action.error.response.data.error;

    // Determining what the error is by looking at the API
    if (errorList && errorList["reset_password_token"]) {
      message = `Your reset token ${errorList["reset_password_token"]}`;
    }
    if (errorList && errorList["password"]) {
      message = `Your password ${errorList["password"]}`;
    }
    if (errorList && errorList["password_confirmation"]) {
      message = `Your passwords are not matching`;
    }
    // If error is due to user needing to confirm their email
    if (/confirm.*email/.test(errorString)) {
      message = errorString;
      needConfirmation = true;
    }
    return {
      ...state,
      error: true,
      errorList: errorList,
      isFetching: false,
      message: message,
      messageType: "warning",
      needConfirmation: needConfirmation
    };
  },
  [sessionTypes.PASSWORD_SAVE_NEW_COMPLETED]: state => ({
    ...state,
    error: false,
    errorList: null,
    isFetching: false,
    message: "Your password has been updated.",
    messageType: "success"
  }),
  [sessionTypes.PASSWORD_SAVE_NEW_REQUESTED]: state => ({
    ...state,
    error: false,
    errorList: null,
    isFetching: true,
    message: ""
  }),
  [sessionTypes.RESEND_CONFIRMATION_FAILED]: (state, action) => ({
    ...state,
    error: true,
    isFetching: false,
    needConfirmation: false,
    message: "Sorry, we could not send you your confirmation email.",
    messageType: "warning"
  }),
  [sessionTypes.RESEND_CONFIRMATION_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    needConfirmation: false,
    message: "Your email confirmation has been sent.",
    messageType: "success"
  }),
  [sessionTypes.RESEND_CONFIRMATION_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: ""
  }),
  [uiTypes.CLEAR_SESSION_NEW_PASSWORD]: () => INITIAL_STATE
});
