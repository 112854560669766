//@flow
import { request } from "state/utils";
import * as types from "./types";

export const createStatusUpdate = (goalId: string, statusUpdateParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/goals/${goalId}/status_updates`, { status_update: statusUpdateParams }),
    payload: { statusUpdateParams }
  };
};

export const updateStatusUpdate = (statusUpdateId: string, statusUpdateParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/status_updates/${statusUpdateId}`, { status_update: statusUpdateParams }),
    payload: { statusUpdateParams }
  };
};

export const deleteStatusUpdate = (statusUpdateId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/status_updates/${statusUpdateId}`),
    payload: { id: statusUpdateId }
  };
};

export const fetchStatusUpdateList = (goalId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/goals/${goalId}/status_updates?page=${page}`)
  };
};

export const fetchStatusUpdateDetails = (statusUpdateId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/status_updates/${statusUpdateId}`)
  };
};

export const resetStatusUpdateList = () => ({
  type: types.RESET_LIST
});
