// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  background: #f3f5f8;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

type Props = {};

type State = {};

class NotFound extends Component<Props, State> {
  render() {
    return (
      <Container>
        <div>Sorry, you are not allowed to access this page.</div>
        <div>
          <Link to="/">Back to your dashboard</Link>
        </div>
      </Container>
    );
  }
}

export default NotFound;
