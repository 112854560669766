//@flow
import React, { Component } from "react";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import moment from "moment";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

// Components
import BotAvatarHappy from "v2/components/_assets/tabbot-happy.png";
import BotAvatarSurprised from "v2/components/_assets/tabbot-surprised.png";
import BotAvatarWorried from "v2/components/_assets/tabbot-worried.png";

const Container = styled.div`
  display: flex;
  background-color: #e9e9e9;
  margin-top: 0;
  margin-bottom: ${spacing.x2};
  padding: ${spacing.x2};
  border-radius: 4px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Author = styled.div`
  a {
    color: ${colors.black};
  }
`;

const Meta = styled.div`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${spacing.x1};

  .auto-label {
    display: inline-block;
    background-color: #a9a9a9;
    font-size: 1.2rem;
    padding: 0.4rem ${spacing.x1};
    border-radius: 2px;
  }
`;

const AvatarContainer = styled.div`
  margin-right: ${spacing.x2};
  img {
    height: 3.2rem;
  }
`;

const Details = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: ${spacing.x1};
  }
`;

type Props = {
  goal: squadTypes.Goal,
  closeGoal: Function,
  updateGoal: Function
};

class StatusUpdateCard extends Component<Props> {
  render() {
    const { goal } = this.props;
    let warningLevel = 0;

    if (goal.pending_notifications_count === 1) {
      warningLevel = 1;
    }
    if (goal.pending_notifications_count === 2) {
      warningLevel = 2;
    }
    if (goal.pending_notifications_count >= 3) {
      warningLevel = 3;
    }

    return (
      <Container>
        <AvatarContainer>
          {warningLevel === 0 && <img src={BotAvatarHappy} alt="Tabot" />}
          {warningLevel === 1 && <img src={BotAvatarHappy} alt="Tabot" />}
          {warningLevel === 2 && <img src={BotAvatarSurprised} alt="Tabot" />}
          {warningLevel === 3 && <img src={BotAvatarWorried} alt="Tabot" />}
        </AvatarContainer>
        <Card>
          <Meta>
            <div>
              <Author>Tabby</Author>
            </div>
          </Meta>
          {warningLevel === 0 && (
            <Details>
              {goal.cached_color !== "grey" && (
                <p>
                  Hi! This goal is due for an update. The last update was published{" "}
                  <b>{moment(goal.cached_status_update_date).fromNow()}</b>.
                </p>
              )}
              {goal.cached_color === "grey" && <p>This goal is ready for its first check-in!</p>}
            </Details>
          )}
          {warningLevel === 1 && (
            <Details>
              {goal.cached_color !== "grey" && (
                <p>
                  Hi! The status of this goal might be out of date. The last update was published{" "}
                  <b>{moment(goal.cached_status_update_date).fromNow()}</b>.
                </p>
              )}
              {goal.cached_color === "grey" && (
                <p>
                  Hi! This goal is due for an update. It was started{" "}
                  <b>{moment(goal.cached_status_update_date).fromNow()}</b>.
                </p>
              )}
              <ButtonContainer>
                <button className="primary" onClick={this.props.updateGoal}>
                  Update progress
                </button>
              </ButtonContainer>
            </Details>
          )}
          {warningLevel === 2 && (
            <Details>
              <p>
                Meep meep! This goal is still due for an update. You can share progress or close the goal to make this
                message disappear.
              </p>
              {goal.cached_color !== "grey" && (
                <p>
                  The last update was published <b>{moment(goal.cached_status_update_date).fromNow()}</b>.
                </p>
              )}
              {goal.cached_color === "grey" && (
                <p>
                  This goal was started <b>{moment(goal.cached_status_update_date).fromNow()}</b>.
                </p>
              )}
              <ButtonContainer>
                <button className="primary" onClick={this.props.updateGoal}>
                  Update goal
                </button>
                <button className="" onClick={this.props.closeGoal}>
                  Close goal
                </button>
              </ButtonContainer>
            </Details>
          )}
          {warningLevel === 3 && (
            <Details>
              {goal.cached_color !== "grey" && (
                <p>It's been a while since this goal was updated. Perhaps it's time to close it?</p>
              )}
              {goal.cached_color === "grey" && (
                <p>It's been a while since this goal was started. Perhaps it's time to close it?</p>
              )}
              {goal.cached_color !== "grey" && (
                <p>
                  The last update was published <b>{moment(goal.cached_status_update_date).fromNow()}</b>.
                </p>
              )}
              {goal.cached_color === "grey" && (
                <p>
                  This goal was started <b>{moment(goal.cached_status_update_date).fromNow()}</b>.
                </p>
              )}
              <ButtonContainer>
                <button className="primary" onClick={this.props.closeGoal}>
                  Close goal
                </button>
                <button className="" onClick={this.props.updateGoal}>
                  Update goal
                </button>
              </ButtonContainer>
            </Details>
          )}
        </Card>
      </Container>
    );
  }
}

export default StatusUpdateCard;
