import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import { uiOperations } from "state/ducks/ui";

// V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// Components
import TabbyBrowse from "./tabby_browse_template.png";
import TabbyWrite from "./tabby_write_goal.png";

const Container = styled.div`
  margin-top: 3rem;
  h2 {
    text-align: center;
    b {
      text-decoration: underline;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  margin: 4rem 0 5rem 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  padding: 3.5rem;

  > div.panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      text-align: center;
    }
    .img-wrapper {
      height: 164px;
    }
    img {
      height: 164px;

      &.img2 {
        height: 140px;
        position: relative;
        top: 10px;
      }
    }
    h3 {
      padding: ${spacing.x2} 0;
    }
    p {
      max-width: 30rem;
      text-align: center;
    }
  }

  div.separator {
    align-self: stretch;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 6rem;

    .line {
      width: 2px;
      background-color: rgba(21, 21, 21, 0.09);
      flex: 1;
    }
    span {
      border-radius: 4px;
      display: flex;
      background-color: rgba(21, 21, 21, 0.09);
      align-items: center;
      justify-content: center;
      font-weight: 600;
      width: 5rem;
      height: 5rem;
    }
  }

  @media ${devices.laptop} {
    flex-direction: column;

    div.separator {
      flex-direction: row;
      margin: ${spacing.x4} 0;
      .line {
        width: auto;
        flex: 1;
        height: 2px;
      }
    }
  }
`;

type Props = {
  showModal: Function,
  project: squadTypes.Project,
  showGoals: Function
};

type State = {
  displayGoalForm: boolean
};

class EmptyState extends Component<Props, State> {
  showTemplatesBrowser = (e: Object) => {
    const { project } = this.props;
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "templates",
      object: project
    });
  };

  render() {
    return (
      <Container>
        <h2>This page doesn't have any goals yet</h2>
        <Wrapper>
          <div className="panel">
            <div className="img-wrapper">
              <img src={TabbyWrite} alt="Tabby writing goals" />
            </div>
            <h3>Add your first goal</h3>
            <p>Ready to go? Start adding your goals to this page.</p>
            <button onClick={this.props.showGoals} className="primary">
              Add a goal
            </button>
          </div>
          <div className="separator">
            <div className="line" />
            <span>OR</span>
            <div className="line" />
          </div>
          <div className="panel">
            <div className="img-wrapper">
              <img src={TabbyBrowse} alt="Tabby looking for templates" className="img2" />
            </div>
            <h3>Use our templates</h3>
            <p>Not sure where to start? Check our templates for OKRs, AARRR funnel and more.</p>
            <button onClick={this.showTemplatesBrowser} className="primary">
              Browse templates
            </button>
          </div>
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyState);
