// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as membershipTypes from "state/ducks/memberships/types";
import { membershipsOperations } from "state/ducks/memberships";
import * as squadTypes from "squadTypes";

import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import AccountSettingsNav from "v2/components/AccountSettingsNav";
import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: ${colors.blockBorder};
  }
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentUser: squadTypes.User,
  handleSubmit: Function,
  ui: Object,
  deleteMembership: Function
};

class AccountSettingsLeave extends Component<Props> {
  deleteMembership = e => {
    e.preventDefault();
    if (!window.confirm("Are you sure you want to leave this workspace?")) {
      return false;
    }
    const { currentMembership } = this.props;
    this.props.deleteMembership(currentMembership.id).then(action => {
      if (action.type === membershipTypes.DELETE_COMPLETED) {
        setTimeout(() => {
          window.location = `https://app.${process.env.REACT_APP_DOMAIN || ""}`;
        }, 3000);
      }
    });
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentUser, ui } = this.props;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Leave workspace";

    return (
      <WorkspaceLayout secondNav={<AccountSettingsNav user={currentUser} />}>
        <Container>
          <Header>
            <h1>Leave workspace</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <p>Click on the button below to leave this workspace.</p>
            <button
              type="submit"
              className="danger-primary auto-size"
              disabled={ui.formState === "requesting"}
              onClick={this.deleteMembership}
            >
              {submitText}
            </button>
            <p />
            <p>
              If you want to delete your account instead of leaving this workspace please go to{" "}
              <a href={`https://app.${process.env.REACT_APP_DOMAIN || ""}/account/delete`}>
                {`https://app.${process.env.REACT_APP_DOMAIN || ""}/account/delete`}
              </a>
              .
            </p>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentMembership: state.session.currentMembership,
  currentUser: state.session.currentUser,
  ui: state.ui.workspaceSettingsDelete
});

const mapDispatchToProps = {
  deleteMembership: membershipsOperations.deleteMembership
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsLeave);
