//V2 styling

import styled from "styled-components";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

import IconCheck from "v2/components/_assets/check.png";

export default styled.div`
  background-color: ${colors.grey};
  border-radius: 4px;
  margin-right: ${spacing.x1};

  height: 2rem;
  width: 2rem;
  min-width: 2rem;

  &.closed {
    background-image: url(${IconCheck});
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: center center;
  }

  &.previous {
    margin-right: 0.2rem;
    width: 0.3rem;
    min-width: 0.3rem;
    height: 1.6rem;
    border-radius: 0.1rem;
    position: relative;
    top: 0.2rem;
  }

  &.grey {
    background-color: ${colors.grey};
  }

  &.transparent {
    background-color: transparent;
  }

  &.black {
    background-color: ${colors.black};
  }

  &.green {
    background-color: ${colors.green};
  }

  &.yellow {
    background-color: ${colors.yellow};
  }

  &.red {
    background-color: ${colors.red};
  }
`;
