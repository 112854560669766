// @flow
import { createReducer } from "state/utils";
import * as projectsTypes from "state/ducks/projects/types";
import * as uiTypes from "./types";

const INITIAL_STATE = {
  error: false,
  isFetching: false,
  isUpdated: false,
  hasCreatedProject: false,
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [projectsTypes.CREATE_FAILED]: (state, action) => {
    let message = "Sorry, we could not create your page.";
    if (action.error.response.data["errors"] && action.error.response.data["errors"]["fullname"]) {
      message = `Your name ${action.error.response.data["errors"]["fullname"]}`;
    }

    if (action.error.response.data["errors"] && action.error.response.data["errors"]["quota"]) {
      message = `Sorry you have exceeded the quota of pages for your plan. Please upgrade your plan to increase your quota.`;
    }

    return {
      ...state,
      error: true,
      isFetching: false,
      hasCreatedProject: false,
      message,
      messageType: "warning"
    };
  },
  [projectsTypes.CREATE_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    hasCreatedProject: true,
    message: "Your page has been created.",
    messageType: "success"
  }),
  [projectsTypes.CREATE_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    hasCreatedProject: false,
    message: ""
  }),
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE,
  [projectsTypes.UPDATE_FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      isUpdating: false
    };
  },
  [projectsTypes.UPDATE_COMPLETED]: state => ({
    ...state,
    error: false,
    isUpdating: false
  }),
  [projectsTypes.UPDATE_REQUESTED]: state => ({
    ...state,
    error: false,
    isUpdating: true
  })
});
