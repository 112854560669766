// @flow
import React, { Component } from "react";
import styled from "styled-components";
import * as squadTypes from "squadTypes";

import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import _ from "lodash";

// Components
import IconMembership from "v2/components/svg/IconMembership";
import Loader from "v2/components/Loader";
import { Link } from "react-router-dom";

const Container = styled.div``;

type Props = {
  query: ?string,
  hideForm: Function
};

type State = {
  isFetching: boolean,
  memberships: Array<squadTypes.Membership>
};

class MemberSearch extends Component<Props, State> {
  state = {
    isFetching: true,
    memberships: []
  };

  debouncedFetchMemberships: Function;

  constructor(props: Object) {
    super(props);
    this.debouncedFetchMemberships = _.debounce(this._fetchMemberships, 300);
    this.debouncedFetchMemberships(this.props.query);
  }

  componentDidUpdate(prevProps: Object) {
    if (this.props.query !== prevProps.query) {
      this.debouncedFetchMemberships(this.props.query);
    }
  }

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  _fetchMemberships = (query: ?string) => {
    if (!query) {
      this.setState({
        memberships: [],
        isFetching: false
      });
      return;
    }
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const membershipsApiURL = `/workspaces/${slug}/memberships`;
    let membershipsParams = {
      name: query,
      limit: 8
    };
    this.setState({ isFetching: true });
    request.get(membershipsApiURL, { params: membershipsParams }).then(response => {
      const memberships = response.data;
      this.setState({
        memberships,
        isFetching: false
      });
    });
  };

  render() {
    const { isFetching, memberships } = this.state;
    if (!isFetching && memberships.length === 0) {
      return (
        <Container>
          <b>Users</b>
          <div className="no-results">
            <Link to={`/team`} onClick={this.props.hideForm}>
              View all users
            </Link>
          </div>
        </Container>
      );
    }
    return (
      <Container>
        <b>Users</b> {isFetching && <Loader size="small" />}
        {!isFetching && (
          <ul>
            {memberships.map(membership => (
              <li key={membership.id}>
                <Link to={`/team/${membership.id}`} onClick={this.props.hideForm}>
                  <IconMembership />
                  <span>{membership.user.fullname || membership.user.email}</span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </Container>
    );
  }
}

export default MemberSearch;
