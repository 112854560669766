// @flow
import { createReducer } from "state/utils";
import * as membershipsTypes from "state/ducks/memberships/types";

const INITIAL_STATE = {
  isFetchingMembers: true
};

export default createReducer(INITIAL_STATE)({
  [membershipsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    isFetchingMembers: false
  }),
  [membershipsTypes.FETCH_LIST_COMPLETED]: state => ({
    isFetchingMembers: false
  }),
  [membershipsTypes.FETCH_LIST_REQUESTED]: state => ({
    isFetchingMembers: true
  })
});
