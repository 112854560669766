//@flow

// THIS FILE IS NOT USED YET. IT WILL BE FOR SIGNED API CALLS
import jwtDecode from "jwt-decode";

export const isAccessTokenExpired = (access_token: string) => {
  if (!access_token) return false;
  const { exp } = jwtDecode(access_token);
  // Adding a 20s buffer to the expiration date
  const now = Math.floor(Date.now() / 1000) + 20;
  return now > exp;
};
