//@flow
import { request } from "state/utils";
import * as types from "./types";

export const fetchStatusUpdateViewList = (statusUpdateId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  const ignore_author = params && params.ignore_author ? "&ignore_author=true" : "";
  const ignore_current_user = params && params.ignore_current_user ? "&ignore_current_user=true" : "";
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () =>
      request.get(`/status_updates/${statusUpdateId}/views?page=${page}${ignore_author}${ignore_current_user}`)
  };
};

export const resetStatusUpdateViewList = () => ({
  type: types.RESET_LIST
});
