// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { workspacesOperations } from "state/ducks/workspaces";
import * as squadTypes from "squadTypes";
import timeZones from "v2/utils/timeZones";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm } from "redux-form";
import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  handleSubmit: Function,
  ui: Object,
  updateWorkspace: Function
};

class WorkspaceSettings extends Component<Props> {
  componentWillReceiveProps(nextProps) {
    const { currentWorkspace } = nextProps;

    // Checking if we need to redirect to a new workspace if the slug has been
    // updated
    const subdomain = window.location.host.split(".")[0];
    if (currentWorkspace.slug !== subdomain) {
      setTimeout(() => {
        window.location = window.location.href.replace(subdomain, currentWorkspace.slug);
      }, 3000);
    }
  }

  updateWorkspace = workspaceValues => {
    const { currentWorkspace } = this.props;
    this.props.updateWorkspace(currentWorkspace.id, workspaceValues);
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentWorkspace, handleSubmit, ui } = this.props;
    const { errorFields } = ui;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Update";

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>Workspace Details</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <form onSubmit={handleSubmit(this.updateWorkspace)}>
              <FormField>
                <label htmlFor="name">Workspace name</label>
                <Field component="input" type="text" className={`tiny ${this.getErrorClass("name")}`} name="name" />
                <span className={`${this.getErrorClass("name")}`}>
                  <small>{errorFields["name"]}</small>
                </span>
              </FormField>
              <FormField>
                <label htmlFor="slug">Workspace slug</label>
                <Field component="input" type="text" className={`tiny ${this.getErrorClass("slug")}`} name="slug" />
                <span className={`${this.getErrorClass("slug")}`}>
                  <small>{errorFields["slug"]}</small>
                </span>
              </FormField>
              <FormField>
                <label htmlFor="slug">Time zone</label>
                <Field
                  component="select"
                  type="text"
                  className={`small ${this.getErrorClass("time_zone")} tiny`}
                  name="time_zone"
                >
                  {timeZones.map(t => (
                    <option value={t} key={t}>
                      {t}
                    </option>
                  ))}
                </Field>
                <span className={`${this.getErrorClass("time_zone")}`}>
                  <small>{errorFields["time_zone"]}</small>
                </span>
              </FormField>
              <FormField>
                <label htmlFor="name">Tability version</label>
                <div>1.0</div>
              </FormField>
              <FormField>
                <label htmlFor="name">Workspace Auth Token</label>
                <div>{currentWorkspace.auth_token}</div>
              </FormField>
              <FormActions>
                <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                  {submitText}
                </button>
              </FormActions>
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => {
  const { name, slug, time_zone } = state.session.currentWorkspace;
  return {
    currentWorkspace: state.session.currentWorkspace,
    initialValues: { name, slug, time_zone },
    ui: state.ui.workspaceSettings
  };
};

const mapDispatchToProps = {
  updateWorkspace: workspacesOperations.updateWorkspace
};

const _WorkspaceSettings = reduxForm({
  form: "workspaceSettings"
})(WorkspaceSettings);

export default connect(mapStateToProps, mapDispatchToProps)(_WorkspaceSettings);
