// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import ClassicDetails from "./ClassicDetails";
import NewDetails from "./NewDetails";

type Props = {
  location: Object
};

class WorkspaceOnboarding extends Component<Props> {
  render() {
    // The match below exist to keep the process flexible if we want to add more steps later
    const { location } = this.props;
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const view = params.get("view");
      if (view === "new") {
        return <NewDetails />;
      }
    }

    return <ClassicDetails />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceOnboarding);
