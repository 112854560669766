//@flow
import { request } from "state/utils";
import * as types from "./types";

export const createLink = (goalId: string, linkParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/goals/${goalId}/links`, { link: linkParams }),
    payload: { linkParams }
  };
};

export const updateLink = (linkId: string, linkParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/links/${linkId}`, { link: linkParams }),
    payload: { linkParams }
  };
};

export const deleteLink = (linkId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/links/${linkId}`),
    payload: { id: linkId }
  };
};

export const fetchLinkList = (goalId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/goals/${goalId}/links?page=${page}`)
  };
};

export const fetchLinkDetails = (linkId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/links/${linkId}`)
  };
};

export const resetLinkList = () => ({
  type: types.RESET_LIST
});
