//@flow
import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "v2/components/styles/colors";
import { Wrapper, Button, Menu, MenuItem } from "react-aria-menubutton";

type Props = {
  className?: string,
  items: Array<any>,
  trigger: any,
  position?: string
};

type State = {
  isOpen: boolean
};

class DropdownMenu extends Component<Props, State> {
  state = {
    isOpen: false
  };

  render() {
    const { items, trigger, position = "right", className } = this.props;

    const StyledWrapper = styled(Wrapper)`
      display: inline-block;
      position: relative;

      .AriaMenuButton-button {
        &:focus {
          outline: none;
        }
      }

      [aria-expanded="true"] {
        .button-icon {
          background-color: #eaeaea;
        }
      }

      .AriaMenuButton-menu {
        background: #fff;
        border: 1px solid rgba(200, 200, 200, 0.4);
        list-style-type: none;
        position: absolute;
        top: 90%;
        ${position}: 0;
        z-index: 200;
        padding-left: 0;
        border-radius: 3px;
        margin: 4px 0 0 0;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
        min-width: 115px;
      }

      &.nav .AriaMenuButton-menu {
        top: initial;
        bottom: 10px;
        left: 55px;
        right: initial;
      }

      .AriaMenuButton-menu--flushRight {
        right: 0;
      }

      .AriaMenuButton-menuItem {
        cursor: pointer;
        border-bottom: 1px solid ${colors.grey};
        a,
        .link-button {
          color: ${colors.black};
          display: block;
          width: 100%;
          padding: 8px;
          white-space: nowrap;
          margin-left: 0;
        }
      }

      .AriaMenuButton-menuItemWrapper:first-of-type .AriaMenuButton-menuItem {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
      }

      .AriaMenuButton-menuItemWrapper:last-of-type .AriaMenuButton-menuItem {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      .AriaMenuButton-menuItem:hover,
      .AriaMenuButton-menuItem:focus {
        background-color: ${colors.greyLight};
      }

      .AriaMenuButton-menuItem.is-selected {
        cursor: default;
        font-weight: bold;
      }
    `;
    return (
      <StyledWrapper
        className={`AriaMenuButton ${className || ""}`}
        {...this.props}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Button className={`AriaMenuButton-button`}>{trigger}</Button>
        <Menu className="AriaMenuButton-menu">
          <ul>
            {items.map((item, index) => (
              <li key={index}>
                <MenuItem className="AriaMenuButton-menuItem">{item}</MenuItem>
              </li>
            ))}
          </ul>
        </Menu>
      </StyledWrapper>
    );
  }
}

export default DropdownMenu;
