// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import { handleChange } from "v2/utils/forms";
import copy from "copy-to-clipboard";
import { EMAIL_REGEX } from "config/constants";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import { push } from "react-router-redux";
import _ from "lodash";

// Components

import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import FormField from "v2/components/FormField";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";
import IconPrivate from "v2/components/_assets/IconPrivate.png";
import IconPublic from "v2/components/svg/IconPublic";
import SlackIcon from "./_assets/IconSlack.png";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Loader from "v2/components/Loader";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

const Container = styled.div`
  .sorry {
    margin-top: ${spacing.x3};
    border-radius: 4px;
    padding: ${spacing.x2};
    background-color: ${colors.infoBg};
  }

  h3 {
    margin: 0 0 ${spacing.x2} 0;
  }

  p {
    padding: ${spacing.x2} 0 0 0;
  }

  label {
    font-weight: 600;
    display: inline-block;
    margin-top: ${spacing.x3};
  }

  textarea {
    width: 50%;
  }

  .line-icon {
    height: 1.7rem;
    vertical-align: middle;
    filter: grayscale(0.7);
    margin-right: ${spacing.x1};
  }

  .email-select {
    margin: ${spacing.x1} 0;
  }
`;

const Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  > div {
    display: flex;
  }
  button {
    margin-right: ${spacing.x1};
  }

  .link-button {
    align-items: center;
    margin-right: 0;
  }
  .slack-image {
    width: 1.6rem;
    margin-right: ${spacing.x1};
  }
`;

const Info = styled.div`
  background-color: ${colors.infoBg};
  padding: ${spacing.x2};
  margin: 0;
  border-radius: 4px;
  margin-bottom: ${spacing.x2};
`;

const URLContainer = styled.div`
  display: flex;
  align-items: center;
  .small {
    width: 50%;
    margin-right: ${spacing.x2};
  }
`;

const ToggleContainer = styled.div`
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #d4d4d4;

  .icon-container {
    display: flex;
    align-items: center;

    img {
      width: 1.6rem;
    }
  }

  svg {
    &.red {
      g {
        stroke: ${colors.red};
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    margin-bottom: ${spacing.x2};

    b {
      display: inline-block;
      margin-left: ${spacing.x2};
    }
  }

  select {
    margin: 0 ${spacing.x2};
    width: 30%;
  }

  label {
    margin: 0 0 0 ${spacing.x1};
    font-weight: normal;
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  hideForm: Function,
  currentMembership: squadTypes.Membership,
  currentProject: squadTypes.Project,
  currentUser: squadTypes.User,
  memberships: squadTypes.NormalizedList<squadTypes.Membership>,
  project: squadTypes.Project,
  push: Function,
  updateProject: Function,
  shareProject: Function,
  ui: Object
};

type State = {
  isCopied: boolean,
  text: string,
  emails: Array<string>
};

const DAILY_SHARES_LIMIT = 15; // It's hardcoded, double-check with the const value in the backend repo

class ProjectShareForm extends Component<Props, State> {
  state = {
    isCopied: false,
    emails: [],
    text: ""
  };
  debouncedFetchMembershipsForAutocomplete: Function;
  constructor(props) {
    super(props);
    this.debouncedFetchMembershipsForAutocomplete = _.debounce(this._fetchMembershipsForAutocomplete, 300);
  }

  handleChange = e => handleChange(this, e);

  _configureSlack = e => {
    e.preventDefault();
    const { currentProject, push } = this.props;
    const notificationURL = `/pages/${currentProject.id}/settings/notifications`;
    push(notificationURL);
    this.props.hideForm();
  };

  _handleHideForm = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.hideForm();
  };

  _togglePublicState = e => {
    e.preventDefault();
    const { currentProject, updateProject } = this.props;

    const params = {
      is_public: !currentProject.is_public
    };

    updateProject(currentProject.id, params);
  };

  _handleSelectIsPublic = e => {
    const is_public = e.target.value;
    const { currentProject, updateProject } = this.props;

    const params = {
      is_public
    };
    updateProject(currentProject.id, params);
  };

  _copyPublicURL = e => {
    const { currentWorkspace, currentProject } = this.props;
    e.preventDefault();
    this.setState({
      isCopied: false
    });
    const publicURL = `${currentWorkspace.url}/p/${currentProject.public_token}`;
    copy(publicURL);
    this.setState({
      isCopied: true
    });
  };

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchMembershipsForAutocomplete = (inputValue: ?string, callback: Function) => {
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const membershipsApiURL = `/workspaces/${slug}/memberships`;
    let membershipsParams = inputValue
      ? {
          name: inputValue
        }
      : null;
    request.get(membershipsApiURL, { params: membershipsParams }).then(response => {
      const users = response.data;
      if (users && users.length > 0) {
        const userOptions = users.map(result => {
          const user = result.user;
          return {
            value: user.email,
            label: user.fullname || user.email
          };
        });
        callback(userOptions);
      } else {
        callback();
      }
    });
    return;
  };

  _handleEmailSelect = (newValue: any, actionMeta: any) => {
    const emails = newValue.map(x => x.value);
    this.setState({ emails });
  };

  _handleShare = e => {
    e.preventDefault();
    const { currentProject, shareProject } = this.props;
    const { emails, text } = this.state;
    const params = {
      emails,
      text
    };
    shareProject(currentProject.id, params);
    this._handleHideForm();
  };

  render() {
    const { currentMembership, currentWorkspace, currentProject, currentUser, ui } = this.props;
    const { isCopied, text, emails } = this.state;
    let submitText;
    if (ui.formState === "requesting") {
      submitText = <Loader size="small" />;
    } else {
      submitText = "Share";
    }

    const { errorFields } = ui;

    const permissionsDisabled = currentWorkspace && !currentWorkspace.stripe_cache_subscription_plan;

    const currentUserCanEdit = currentProject.current_user_permission === "edit";

    const displaySlack = currentUserCanEdit && !currentProject.slack_channel_id;

    const noEmails = emails.length === 0;

    return (
      <Container className="modal-wrapper">
        <div className="modal-header">
          <h2>
            Share the report view of <span className="light">{currentProject.title}</span>
          </h2>
          <ButtonIcon onClick={this.props.hideForm}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          {permissionsDisabled && (
            <div>
              <Info>
                {currentMembership.role === "owner" && <Link to="/settings/billing">Upgrade your plan</Link>}
                {currentMembership.role !== "owner" && <span>Upgrade your plan</span>} to make the report link publicly
                accessible.
              </Info>
            </div>
          )}
          {!currentUserCanEdit && (
            <ToggleContainer>
              <div data-tip data-for="permissionsDisabled">
                {currentProject.is_public && <IconPublic />}
                {!currentProject.is_public && (
                  <div className="icon-container">
                    <img src={IconPrivate} alt="Private" />
                  </div>
                )}
                {currentProject.is_public && (
                  <span>
                    <b>Report view is public</b> — Anybody with the link can access the report view. No login required.
                  </span>
                )}
                {!currentProject.is_public && (
                  <span>
                    <b>Report view is private</b> — Only member of your workspace can access the report view.
                  </span>
                )}
              </div>
            </ToggleContainer>
          )}
          {currentUserCanEdit && (
            <ToggleContainer>
              <div data-tip data-for="permissionsDisabled">
                {currentProject.is_public && <IconPublic />}
                {!currentProject.is_public && (
                  <div className="icon-container">
                    <img src={IconPrivate} alt="Private" />
                  </div>
                )}
                <select
                  name="is_public"
                  value={currentProject.is_public}
                  onChange={this._handleSelectIsPublic}
                  className="auto"
                  disabled={permissionsDisabled}
                >
                  <option value={true}>Report view is public</option>
                  <option value={false}>Report view is private</option>
                </select>
                {currentProject.is_public && (
                  <span>Anybody with the link can access the report view. No login required.</span>
                )}
                {!currentProject.is_public && <span>Only member of your workspace can access the report view.</span>}
              </div>
            </ToggleContainer>
          )}
          <div>
            <p>Copy the public URL of your page or share via email.</p>
            <URLContainer>
              <input
                type="text"
                value={`${currentWorkspace.url}/p/${currentProject.public_token}`}
                className="small"
                readOnly={true}
              />
              <button onClick={this._copyPublicURL}>Copy URL</button>
              {isCopied && <small className="light">Copied!</small>}
            </URLContainer>
            {currentUser.daily_shares_count >= DAILY_SHARES_LIMIT && (
              <div className="sorry">
                Sorry, you have exceeded the number of shares that you can perform. Please contact{" "}
                <a href="https://docs.tability.io/support" target="_blank" rel="noopener noreferrer">
                  support
                </a>
                .
              </div>
            )}
            {currentUser.daily_shares_count < DAILY_SHARES_LIMIT && (
              <div>
                <label>Type emails or usernames</label>
                <FormField>
                  <AsyncCreatableSelect
                    isClearable
                    placeholder="Email or username"
                    loadOptions={this.debouncedFetchMembershipsForAutocomplete}
                    defaultOptions={true}
                    classNamePrefix="react-select"
                    isValidNewOption={value => {
                      return value.match(EMAIL_REGEX) !== null;
                    }}
                    formatCreateLabel={value => {
                      return `Share with "${value}"`;
                    }}
                    isMulti
                    closeOnSelect={true}
                    onChange={this._handleEmailSelect}
                    className={`small-select email-select`}
                  />
                  <textarea
                    name="text"
                    onChange={this.handleChange}
                    value={text}
                    placeholder="Add a message (optional)"
                  />
                </FormField>
                {ui.formState === "failed" && (
                  <span className="error">
                    <small>Sorry but we could not save your comment</small>
                  </span>
                )}
                {errorFields["text"] && (
                  <span className="error">
                    <small>You cannot submit an empty comment</small>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Actions>
            {currentUser.daily_shares_count < DAILY_SHARES_LIMIT && (
              <div>
                {currentWorkspace.stripe_cache_subscription_plan && (
                  <div data-tip data-for="no-emails">
                    <button
                      onClick={this._handleShare}
                      className="primary"
                      disabled={ui.formState === "requesting" || noEmails}
                    >
                      {submitText}
                    </button>
                  </div>
                )}
                {!currentWorkspace.stripe_cache_subscription_plan && (
                  <div className="share-container" style={{ display: "inline-block" }} data-tip data-for="share-button">
                    <button className="primary" disabled={true}>
                      {submitText}
                    </button>
                  </div>
                )}
                {!currentWorkspace.stripe_cache_subscription_plan && (
                  <ReactTooltip id="share-button" place="right" type="dark" effect="solid">
                    You need to upgrade your plan to use this feature.
                  </ReactTooltip>
                )}
                {noEmails && (
                  <ReactTooltip id="no-emails" place="right" type="dark" effect="solid">
                    You need to select an email or a user first.
                  </ReactTooltip>
                )}
                <button onClick={this._handleHideForm} className="secondary">
                  Cancel
                </button>
              </div>
            )}
            <div>
              {displaySlack && (
                <button className="link-button" onClick={this._configureSlack}>
                  <img src={SlackIcon} alt="Slack" className="slack-image" />
                  Connect your page to your team's Slack channel
                </button>
              )}
            </div>
          </Actions>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: state.session.currentUser,
  currentWorkspace: state.session.currentWorkspace,
  currentMembership: state.session.currentMembership,
  currentProject: projectsSelectors.getObjectById(state.projects.byId, props.project.id),
  ui: state.ui.projectShareForm
});

const mapDispatchToProps = {
  shareProject: projectsOperations.shareProject,
  updateProject: projectsOperations.updateProject,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectShareForm);
