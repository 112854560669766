//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as squadTypes from "squadTypes";
import moment from "moment";
import numeral from "numeral";
import { goalsSelectors } from "state/ducks/goals";
import styled from "styled-components";

// Components
import Avatar from "v2/components/Avatar";
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

type Props = {
  goal: squadTypes.Goal,
  isSelected: boolean,
  className: string
};

class GoalLine extends Component<Props> {
  render() {
    const { goal } = this.props;
    const { owner } = goal;

    // This gets executed if displayGoalEditForm === false
    let progressScore = goal.cached_score;
    let prctProgress = 0;
    let progressWithFormat = "";
    let colorToString = "";
    let statusColor = "#ABABAB";

    switch (goal.cached_color) {
      case "red":
        colorToString = "Off track";
        statusColor = "#D0402E";
        break;
      case "yellow":
        colorToString = "At risk";
        statusColor = "#D3A627";
        break;
      case "green":
        colorToString = "On track";
        statusColor = "#408C76";
        break;
      default:
        colorToString = "Pending";
        statusColor = "#ABABAB";
        break;
    }

    // Set the progress score using the format if provided.
    if (goal.score_format && goal.target) {
      if (goal.cached_score || goal.cached_score === 0) {
        progressScore = numeral(goal.cached_score).format("0[.]00a");
        //progressScore = parseFloat(goal.cached_score).toLocaleString("en-US");
        prctProgress = goal.prct_target_normalized * 100;
        progressWithFormat = goal.score_format.replace("_target_", progressScore);
      } else {
        progressScore = "?";
      }
    }

    let statusString = progressWithFormat || colorToString;

    if (goal.state === "closed") {
      statusString = "Closed";
    }

    const Card = styled.div`
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      margin: ${spacing.x2} 0 0 0;
    `;

    const Title = styled.div`
      border-radius: 4px;
      background-color: ${statusColor};
      flex: 2;
      padding: ${spacing.x2};
      font-weight: 600;
      color: #fff;
    `;

    const MetaContainer = styled.div`
      margin-top: ${spacing.x1};

      display: flex;
    `;

    const MetaWrapper = styled.div`
      flex: 1;
    `;

    const AvatarWrapper = styled.div`
      margin-left: ${spacing.x2};
    `;

    const Meta = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        font-size: 1.4rem;
        color: ${colors.subtleText};
      }
    `;

    const Progress = styled.div`
      height: ${spacing.x1};
      background-color: ${colors.progressBarBg};
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 0.4rem;
    `;

    const ProgressBar = styled.div`
      height: ${spacing.x1};
      width: ${Math.round(prctProgress)}%;
      border-radius: 5px;
      background-color: ${colors.progressBar};
      overflow: hidden;
    `;

    return (
      <Card>
        <Title>
          <div>{statusString}</div>
        </Title>
        <MetaContainer>
          <MetaWrapper>
            {(progressScore || progressScore === 0) && (goal.initial_value || goal.initial_value === 0) && (
              <Progress>
                <ProgressBar />
              </Progress>
            )}
            <Meta>
              <div>
                {goal.deadline && moment(goal.deadline).isAfter(moment()) && (
                  <span>
                    {moment(goal.deadline)
                      .fromNow(true)
                      .replace("a ", "1 ")}{" "}
                    left
                  </span>
                )}
                {goal.deadline && moment(goal.deadline).isBefore(moment()) && (
                  <span className={goal.state === "open" ? "danger" : ""}>Due {moment(goal.deadline).fromNow()}</span>
                )}
                {!goal.deadline && "-"}
              </div>
            </Meta>
          </MetaWrapper>
          <AvatarWrapper>
            <Avatar user={owner} to={`/team/${goal.membership_id}`} hideName={true} size={32} />
          </AvatarWrapper>
        </MetaContainer>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentGoal: goalsSelectors.getObjectById(state.goals.byId, props.match.params.goal),
  memberships: state.memberships
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoalLine));
