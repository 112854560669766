// @flow
import { createReducer } from "state/utils";
import * as sessionTypes from "state/ducks/session/types";
import * as uiTypes from "state/ducks/ui/types";

const INITIAL_STATE = {
  error: false,
  errorList: null,
  isFetching: false,
  message: ""
};

export default createReducer(INITIAL_STATE)({
  [sessionTypes.PASSWORD_RESET_FAILED]: (state, action) => ({
    error: true,
    errorList: action.error.response.data.errors,
    isFetching: false,
    message: "Sorry, we could not reset your password.",
    messageType: "warning"
  }),
  [sessionTypes.PASSWORD_RESET_COMPLETED]: () => ({
    error: false,
    errorList: null,
    isFetching: false,
    message: "An email has been sent to reset your password",
    messageType: "success"
  }),
  [sessionTypes.PASSWORD_RESET_REQUESTED]: () => ({
    error: false,
    errorList: null,
    isFetching: true,
    message: ""
  }),
  [uiTypes.CLEAR_SESSION_RESET_PASSWORD]: () => INITIAL_STATE
});
