//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.4rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 14 14" {...props}>
    <g
      id="01-Product-UI"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Dashboard---Goal-Detail" transform="translate(-1420.000000, -46.000000)" stroke="#8089A2" strokeWidth="2">
        <g id="Icons" transform="translate(1380.000000, 47.000000)">
          <g id="X" transform="translate(41.000000, 0.000000)">
            <path d="M12,0 L0,12" id="Shape" />
            <path d="M0,0 L12,12" id="Shape" />
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
