// @flow
import { createReducer } from "state/utils";
import * as projectsTypes from "state/ducks/projects/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  isFetchingData: true,
  data: null
};

export default createReducer(INITIAL_STATE)({
  [projectsTypes.FETCH_TRENDS_REQUESTED]: (state, action) => {
    return {
      ...state,
      isFetchingData: true
    };
  },
  [projectsTypes.FETCH_TRENDS_COMPLETED]: (state, action) => {
    return {
      ...state,
      isFetchingData: false,
      data: action.response.data
    };
  },
  [projectsTypes.FETCH_TRENDS_FAILED]: (state, action) => {
    return {
      ...state,
      isFetchingData: false
    };
  },
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
