// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
//import * as squadTypes from "squadTypes";
import { statusUpdateViewsOperations } from "state/ducks/statusUpdateViews";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

// Components
import Avatar from "v2/components/Avatar";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";
import Loader from "v2/components/Loader";
import { Link } from "react-router-dom";

const Container = styled.div``;

const AvatarRow = styled.li`
  a {
    display: flex;
    align-items: center;
    padding: ${spacing.x1} ${spacing.x1};
    border-radius: 8px;

    color: ${colors.black};

    &:hover {
      background-color: #f3f3f3;
    }
  }
`;

type Props = {
  statusUpdateViews: Object,
  statusUpdateId: string,
  hideForm: Function,
  fetchStatusUpdateViewList: Function,
  resetStatusUpdateViewList: Function,
  ui: Object
};

class StatusUpdateComments extends Component<Props> {
  componentDidMount() {
    this._fetchMore();
  }

  _fetchMore = () => {
    const { statusUpdateId, statusUpdateViews, fetchStatusUpdateViewList } = this.props;
    const params = {
      page: statusUpdateViews.currentPage + 1,
      ignore_author: true,
      ignore_current_user: true
    };
    fetchStatusUpdateViewList(statusUpdateId, params);
  };

  render() {
    const { statusUpdateViews, hideForm, ui } = this.props;
    return (
      <Container className="modal-wrapper-no-footer">
        <div className="modal-header">
          <h2>Recent views</h2>
          <ButtonIcon onClick={hideForm}>
            <IconClose style={{ width: "1.2rem" }} />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <ul>
            {statusUpdateViews.allIds.map(id => {
              const view = statusUpdateViews.byId[id];
              if (!view) {
                return false;
              }
              const user = view.user;
              return (
                <AvatarRow key={view.id}>
                  {user && (
                    <Link to={`/team/${view.membership_id}`} className="avatar-link" onClick={hideForm}>
                      <Avatar user={user} hideName={true} size={25} pictureOnly={true} />
                      &nbsp;
                      {user.fullname || user.email}
                    </Link>
                  )}
                  {!user && <span className="light">Account deleted</span>}
                </AvatarRow>
              );
            })}
          </ul>
          {ui.isFetching && (
            <div>
              <Loader size="small" />
            </div>
          )}
          {statusUpdateViews.hasNext && !ui.isFetching && (
            <button
              className="secondary"
              onClick={e => {
                this._fetchMore();
                e.preventDefault();
              }}
            >
              Load more
            </button>
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  statusUpdateViews: state.statusUpdateViews,
  ui: state.ui.statusUpdateViews
});

const mapDispatchToProps = {
  fetchStatusUpdateViewList: statusUpdateViewsOperations.fetchStatusUpdateViewList,
  resetStatusUpdateViewList: statusUpdateViewsOperations.resetStatusUpdateViewList
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusUpdateComments);
