// @flow
import { createReducer } from "state/utils";
import * as reducerUtils from "state/utils/reducers";
import * as types from "./types";

import { INITIAL_LIST_STATE } from "config/constants";

const normalizedStateReducer = createReducer(INITIAL_LIST_STATE)({
  // Add single object to the reducer
  [types.CREATE_COMPLETED]: (state, action) => {
    // In the case of a project we need to check if this project has a parent.
    // If that's the case we need to increment the count of children to allow
    // for expansion of the nav without having to fetch again all projects.
    const newState = reducerUtils.addObject(state, action);
    const project = action.response.data;
    if (project.parent_id) {
      newState.byId[project.parent_id].count_of_children_projects += 1;
    }
    //state.
    return newState;
  },
  [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
    const newState = reducerUtils.addObject(state, action);
    return { ...newState, fetchDetailsFailed: false };
  },
  [types.UPDATE_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  [types.COPY_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  // Remove single object from the reducer
  [types.FETCH_DETAILS_FAILED]: (state, action) => {
    const newState = reducerUtils.removeObject(state, action);
    return { ...newState, fetchDetailsFailed: true };
  },
  [types.DELETE_COMPLETED]: (state, action) => reducerUtils.removeObject(state, action),
  // Get list of objects
  [types.FETCH_LIST_COMPLETED]: (state, action) => reducerUtils.paginateList(state, action),
  [types.FETCH_LIST_FAILED]: (state, action) => ({ ...state, hasFetchedListOnce: true }),
  [types.RESET_LIST]: (state, action) => INITIAL_LIST_STATE
});

/*
// The initial state is an empty array
const paginatedListReducer = createReducer(INITIAL_PAGINATED_LIST_STATE)({
  [types.FETCH_LIST_COMPLETED]: (state, action) => {
    // We get the pagination data from the response
    const currentPage = Number(action.response.headers["x-page"]);
    const hasNext = action.response.headers.link && action.response.headers.link.includes('rel="next"');
    const items = [...state.items, ...action.response.data];
    const total = Number(action.response.headers["x-total"]);

    return {
      currentPage,
      hasNext,
      items,
      total
    };
  },
  [types.RESET_LIST]: () => INITIAL_PAGINATED_LIST_STATE
});
*/

export default normalizedStateReducer;
