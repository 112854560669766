//@flow
export const CREATE_REQUESTED = "tasks/CREATE_REQUESTED";
export const CREATE_COMPLETED = "tasks/CREATE_COMPLETED";
export const CREATE_FAILED = "tasks/CREATE_FAILED";

export const UPDATE_REQUESTED = "tasks/UPDATE_REQUESTED";
export const UPDATE_COMPLETED = "tasks/UPDATE_COMPLETED";
export const UPDATE_FAILED = "tasks/UPDATE_FAILED";

export const DELETE_REQUESTED = "tasks/DELETE_REQUESTED";
export const DELETE_COMPLETED = "tasks/DELETE_COMPLETED";
export const DELETE_FAILED = "tasks/DELETE_FAILED";

export const FETCH_LIST_REQUESTED = "tasks/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "tasks/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "tasks/FETCH_LIST_FAILED";

export const FETCH_DETAILS_REQUESTED = "tasks/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "tasks/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "tasks/FETCH_DETAILS_FAILED";

export const RESET_LIST = "tasks/RESET_LIST";

export const MOVE_REQUESTED = "goals/MOVE_REQUESTED";
export const MOVE_COMPLETED = "goals/MOVE_COMPLETED";
export const MOVE_FAILED = "goals/MOVE_FAILED";
