// @flow
import { createReducer } from "state/utils";
import * as workspacesTypes from "state/ducks/workspaces/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  errorFields: {},
  isCancelingSubscription: false,
  isFetchingStripeDetails: false,
  isUpdatingCardDetails: false
};

export default createReducer(INITIAL_STATE)({
  [workspacesTypes.FETCH_STRIPE_DETAILS_REQUESTED]: (state, action) => {
    return {
      ...state,
      isFetchingStripeDetails: true
    };
  },
  [workspacesTypes.FETCH_STRIPE_DETAILS_COMPLETED]: (state, action) => {
    return {
      ...state,
      isFetchingStripeDetails: false
    };
  },
  [workspacesTypes.FETCH_STRIPE_DETAILS_FAILED]: (state, action) => {
    return {
      ...state,
      isFetchingStripeDetails: false
    };
  },
  [workspacesTypes.UPDATE_STRIPE_REQUESTED]: (state, action) => {
    return {
      ...state,
      isUpdatingCardDetails: true
    };
  },
  [workspacesTypes.UPDATE_STRIPE_COMPLETED]: (state, action) => {
    return {
      ...state,
      isUpdatingCardDetails: false
    };
  },
  [workspacesTypes.UPDATE_STRIPE_FAILED]: (state, action) => {
    return {
      ...state,
      isUpdatingCardDetails: false
    };
  },
  [workspacesTypes.CANCEL_SUBSCRIPTION_REQUESTED]: (state, action) => {
    return {
      ...state,
      isCancelingSubscription: true
    };
  },
  [workspacesTypes.CANCEL_SUBSCRIPTION_COMPLETED]: (state, action) => {
    return {
      ...state,
      isCancelingSubscription: false
    };
  },
  [workspacesTypes.CANCEL_SUBSCRIPTION_FAILED]: (state, action) => {
    return {
      ...state,
      isCancelingSubscription: false
    };
  },
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
