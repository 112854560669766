// @flow
import { createReducer } from "state/utils";
import * as statusUpdatesTypes from "state/ducks/statusUpdates/types";

const INITIAL_STATE = {
  isFetching: false
};

export default createReducer(INITIAL_STATE)({
  [statusUpdatesTypes.CREATE_FAILED]: (state, action) => ({
    isFetching: false
  }),
  [statusUpdatesTypes.CREATE_COMPLETED]: state => ({
    isFetching: false
  }),
  [statusUpdatesTypes.CREATE_REQUESTED]: state => ({
    isFetching: true
  }),
  [statusUpdatesTypes.UPDATE_FAILED]: (state, action) => ({
    isFetching: false
  }),
  [statusUpdatesTypes.UPDATE_COMPLETED]: state => ({
    isFetching: false
  }),
  [statusUpdatesTypes.UPDATE_REQUESTED]: state => ({
    isFetching: true
  })
});
