// @flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Styling
import styled from "styled-components";
import { spacing } from "v2/components/styles/spacing";
import { devices } from "v2/components/styles/devices";

// Ducks stuff
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";

// Utils
import { handleChange } from "v2/utils/forms";

// Library Components
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// Tability Components
import BgSignup from "./_assets/bg_signup.png";
import FormField from "v2/components/FormField";
import GoogleButtonIcon from "v2/components/_assets/google-button-icon.png";
import InlineMessage from "v2/components/InlineMessage";
import Loader from "v2/components/Loader";
import SessionLayout, { HelpLinks } from "v2/components/SessionLayout";
import SlackButtonIcon from "v2/components/_assets/slack-button-icon.png";

const Container = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "left right";
  background-color: #fff;

  .button {
    img {
      width: 2rem;
      margin-right: ${spacing.x1};
    }
  }

  @media ${devices.tablet} {
    display: flex;
    width: 100%;
  }
`;

const HeroContainer = styled.div`
  grid-area: left;
  background-color: #0a5055;
  background-image: url(${BgSignup});
  background-repeat: no-repeat;
  background-size: 1000px;
  background-position: 0px bottom;
  @media ${devices.tablet} {
    display: none;
  }
`;

const FormContainer = styled.div`
  grid-area: right;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${devices.tablet} {
    padding: ${spacing.x3};
  }
`;

const OAuthContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    flex: 1;

    &:first-child {
      margin-right: ${spacing.x1};
    }
    &:last-child {
      margin-left: ${spacing.x1};
    }
  }

  @media ${devices.mobile} {
    flex-direction: column;
    > div {
      margin-bottom: ${spacing.x2};

      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-left: 0;
      }
    }
  }
`;

const Separator = styled.div`
  margin: ${spacing.x2} 0;
  border-bottom: 2px solid #d1d1d1;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 0.7rem;
  span {
    display: block;
    font-size: 1.4rem;
    line-height: 1.4rem;
    background-color: #fff;
    padding: 0 ${spacing.x2};
    position: relative;
    top: 0.7rem;
  }
`;

const SignupContainer = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  clearLogin: Function,
  redirectAfterPath: string,
  signup: Function,
  ui: Object
};

type State = {
  email: string,
  password: string,
  password_confirmation: string
};

class SignUp extends Component<Props, State> {
  state = {
    email: "",
    password: "",
    password_confirmation: ""
  };

  componentDidMount() {
    // Add listener for the google auth dance
    window.addEventListener("message", this.msgHandler);
    document.title = "Sign up | Tability";
  }

  // This message handler listens to messages sent by the Google Sign in iframe.
  // If a message === refresh-page then it means that the Google authentication was
  // successful and we will be logged in upon refresh as the session data will be
  // accessible (see how it's persisted in the root index.js file).
  msgHandler = e => {
    if (e.origin !== `https://app.${process.env.REACT_APP_DOMAIN || ""}`) {
      return;
    }
    if (e.data === "refresh-page") {
      window.location.reload();
    }
  };

  signup = (e: Object) => {
    const { email, password, password_confirmation } = this.state;

    const credentials = {
      email,
      password,
      password_confirmation
    };

    this.props.signup(credentials);

    e.preventDefault();
  };

  handleChange = e => handleChange(this, e);

  render() {
    const domain = window.location.port
      ? `${window.location.hostname}:${window.location.port}`
      : `${window.location.hostname}`;
    const oauthState = {
      domain: `${domain}${this.props.redirectAfterPath}`
    };

    const state = btoa(JSON.stringify(oauthState));

    // Creating the OAuth URL for Slack
    const slackClientID = process.env.REACT_APP_SLACK_CLIENT_ID || "";
    const slackRedirectURL = `https://app.${process.env.REACT_APP_DOMAIN || ""}/slack/authenticate`;
    const slackOAuthURL =
      "https://slack.com/oauth/authorize" +
      "?scope=identity.basic,identity.email,identity.avatar" +
      `&client_id=${slackClientID}` +
      `&state=${state}` +
      `&redirect_uri=${encodeURIComponent(slackRedirectURL)}`;

    // Creating the OAuth URL for Google
    const googleClienttID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
    const googleScope = "email profile";
    const googleRedirectURL = `https://app.${process.env.REACT_APP_DOMAIN || ""}/google/authenticate`;
    const googleOauthURL =
      "https://accounts.google.com/o/oauth2/v2/auth" +
      `?scope=${encodeURIComponent(googleScope)}` +
      `&state=${state}` +
      `&client_id=${googleClienttID}` +
      `&response_type=code` +
      `&prompt=select_account` +
      `&redirect_uri=${encodeURIComponent(googleRedirectURL)}`;

    const { ui } = this.props;
    const { email, password, password_confirmation } = this.state;
    return (
      <Container>
        <Helmet>
          <script src="https://apis.google.com/js/client:platform.js?onload=start" async defer type="text/javascript" />
        </Helmet>

        <HeroContainer />
        <FormContainer>
          <SessionLayout title="Sign up">
            <InlineMessage message={ui.message} messageType={ui.messageType} />
            <Fragment>
              <form onSubmit={this.signup}>
                <OAuthContainer>
                  <div>
                    <a href={googleOauthURL} className="button">
                      <img src={GoogleButtonIcon} alt="" />
                      <span>Sign in with Google</span>
                    </a>
                  </div>
                  <div>
                    <a href={slackOAuthURL} className="button">
                      <img src={SlackButtonIcon} alt="" />
                      <span>Sign in with Slack</span>
                    </a>
                  </div>
                </OAuthContainer>
                <Separator>
                  <span>OR</span>
                </Separator>
                <FormField>
                  <input
                    className="form-control"
                    type="email"
                    autoComplete="off"
                    aria-label="Enter your email"
                    placeholder="Email"
                    name="email"
                    required={true}
                    value={email}
                    onChange={this.handleChange}
                  />
                </FormField>
                <FormField>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    aria-label="Create a password"
                    name="password"
                    required={true}
                    value={password}
                    onChange={this.handleChange}
                  />
                </FormField>
                <FormField>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Confirm password"
                    aria-label="Confirm your password"
                    name="password_confirmation"
                    required={true}
                    value={password_confirmation}
                    onChange={this.handleChange}
                  />
                </FormField>
                <SignupContainer>
                  {!ui.isFetching && <button className="primary">Signup</button>}
                  {ui.isFetching && (
                    <button disabled className="primary">
                      <Loader size="small" />
                    </button>
                  )}
                </SignupContainer>
              </form>

              <HelpLinks>
                <p>
                  Already have an account? <Link to="/login">Log in</Link>
                </p>
              </HelpLinks>
            </Fragment>
          </SessionLayout>
        </FormContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui.sessionSignup,
  redirectAfterPath: state.session.redirectAfterPath
});

const mapDispatchToProps = {
  clearSignup: uiOperations.clearSessionSignup,
  signup: sessionOperations.signup
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
