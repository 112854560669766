//@flow
export const CREATE_REQUESTED = "goal_subscriptions/CREATE_REQUESTED";
export const CREATE_COMPLETED = "goal_subscriptions/CREATE_COMPLETED";
export const CREATE_FAILED = "goal_subscriptions/CREATE_FAILED";

export const DELETE_REQUESTED = "goal_subscriptions/DELETE_REQUESTED";
export const DELETE_COMPLETED = "goal_subscriptions/DELETE_COMPLETED";
export const DELETE_FAILED = "goal_subscriptions/DELETE_FAILED";

export const FETCH_LIST_REQUESTED = "goal_subscriptions/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "goal_subscriptions/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "goal_subscriptions/FETCH_LIST_FAILED";

export const FETCH_DETAILS_REQUESTED = "goal_subscriptions/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "goal_subscriptions/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "goal_subscriptions/FETCH_DETAILS_FAILED";

export const RESET_LIST = "goal_subscriptions/RESET_LIST";
