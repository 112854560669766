// @flow
import { createReducer } from "state/utils";
import * as accountTypes from "state/ducks/account/types";
import * as workspaceTypes from "state/ducks/workspaces/types";
import * as uiTypes from "./types";

const INITIAL_STATE = {
  error: false,
  isFetching: false,
  isUploadingAvatar: false,
  hasCreatedWorkspace: false,
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [accountTypes.UPDATE_ACCOUNT_FAILED]: (state, action) => {
    let message = "Sorry, we could not save your profile.";
    if (action.error.response.data["errors"] && action.error.response.data["errors"]["fullname"]) {
      message = `Your name ${action.error.response.data["errors"]["fullname"]}`;
    }

    return {
      ...state,
      error: true,
      isFetching: false,
      message,
      messageType: "warning"
    };
  },
  [accountTypes.UPDATE_ACCOUNT_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    message: "Your profile has been updated.",
    messageType: "success"
  }),
  [accountTypes.UPDATE_ACCOUNT_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: ""
  }),
  [accountTypes.UPLOAD_ASSET_FAILED]: (state, action) => ({
    ...state,
    error: true,
    isUploading: false,
    message: "Sorry, we could not save your avatar.",
    messageType: "warning"
  }),
  [accountTypes.UPLOAD_ASSET_COMPLETED]: state => ({
    ...state,
    error: false,
    isUploading: false,
    message: "",
    messageType: "success"
  }),
  [accountTypes.UPLOAD_ASSET_REQUESTED]: state => ({
    ...state,
    error: false,
    isUploading: true,
    message: ""
  }),
  [workspaceTypes.CREATE_FAILED]: (state, action) => {
    let message = "Sorry, we could not create your workspace.";
    if (action.error.response.data["errors"] && action.error.response.data["errors"]["slug"]) {
      message = `Your URL ${action.error.response.data["errors"]["slug"]}`;
    }
    if (action.error.response.data["errors"] && action.error.response.data["errors"]["accepted_domains"]) {
      message = `Sorry, we could not save your allowed email domains. Make sure they're in the format "acme.com"`;
    }
    return {
      ...state,
      error: true,
      isFetching: false,
      hasCreatedWorkspace: false,
      message,
      messageType: "warning"
    };
  },
  [workspaceTypes.CREATE_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    hasCreatedWorkspace: true,
    message: "Your workspace has been created!",
    messageType: "success"
  }),
  [workspaceTypes.CREATE_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    hasCreatedWorkspace: false,
    message: ""
  }),
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
