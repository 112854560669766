// @flow
import { createReducer } from "state/utils";
import * as sessionTypes from "state/ducks/integrations/types";
import * as uiTypes from "./types";

const INITIAL_STATE = {
  error: false,
  isFetching: true,
  message: "",
  messageType: "warning",
  jiras: []
};

export default createReducer(INITIAL_STATE)({
  [sessionTypes.JIRA_INSTALL_FAILED]: (state, action) => {
    const errors = action.error.response;
    return {
      ...state,
      error: true,
      isFetching: false,
      message: errors,
      messageType: "warning"
    };
  },
  [sessionTypes.JIRA_INSTALL_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    messageType: "success",
    message: "You have successfully signed in."
  }),
  [sessionTypes.JIRA_INSTALL_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: ""
  }),
  [sessionTypes.JIRA_LIST_FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      isFetching: false,
      jiras: []
    };
  },
  [sessionTypes.JIRA_LIST_COMPLETED]: (state, action) => ({
    ...state,
    error: false,
    isFetching: false,
    jiras: action.response.data
  }),
  [sessionTypes.JIRA_LIST_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true
  }),
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
