import React from "react";
import { connect } from "react-redux";

import NotFound from "v2/components/NotFound";

export default function withWorkspaceCheck(WrappedComponent) {
  type Props = {
    currentWorkspace: squadTypes.Workspace
  };

  const withWorkspaceCheck = (props): Props => {
    // Display 404 if the workspace doesn't exist
    if (!props.currentWorkspace) {
      return <NotFound />;
    }
    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = state => ({
    currentWorkspace: state.session.currentWorkspace
  });

  return connect(mapStateToProps)(withWorkspaceCheck);
}
