// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import styled from "styled-components";
import * as projectsTypes from "state/ducks/projects/types";
import moment from "moment";

import { handleChange } from "v2/utils/forms";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import { uiOperations } from "state/ducks/ui";

// Components

import { spacing } from "v2/components/styles/spacing";

import FormActions from "v2/components/FormActions";
import RadioField from "v2/components/RadioField";
import InlineMessage from "v2/components/InlineMessage";
import { Link } from "react-router-dom";
import Loader from "v2/components/Loader";
import Datetime from "react-datetime";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";
import ReactTooltip from "react-tooltip";

const Container = styled.div`
  a.tooltip {
    text-decoration: underline;
    color: #fff;
  }

  .line-wrapper {
    padding: ${spacing.x2};
  }

  .title-input {
    min-width: 30rem;
    width: 50%;
  }

  .radio-group {
    width: 50rem;
  }

  font-size: 1.6rem;
`;

const FormLine = styled.div`
  .inline {
    display: flex;
    margin-top: ${spacing.x2};

    div:first-of-type {
      margin-right: ${spacing.x2};
    }
  }
`;

type Props = {
  clearPage: Function,
  createProject: Function,
  currentMembership: squadTypes.Membership,
  createdProject: squadTypes.Project,
  currentWorkspace: squadTypes.Workspace,
  history: any,
  hideForm: Function,
  location: Object,
  push: Function,
  parentProject: ?squadTypes.Project,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  projectTitle?: string,
  ui: Object
};

type State = {
  title: string,
  global_permission_type: string,
  update_frequency: string,
  selectedOption: ?Object,
  periodPick: string,
  startDateForm: ?Date,
  deadlineForm: ?Date,
  projectOptions: Array<Object>
};

class ProjectsNew extends Component<Props, State> {
  focusInput: any;

  state = {
    title: "",
    global_permission_type: "edit",
    update_frequency: "weekly",
    periodPick: "next_quarter",
    selectedOption: null,
    startDateForm: null,
    deadlineForm: null,
    projectOptions: []
  };

  componentDidMount() {
    this.props.clearPage();
    const today = moment();
    const midQuarter = moment()
      .startOf("quarter")
      .add(2, "months");
    let default_start_date, default_deadline;
    let startMoment, deadlineMoment;

    // Sets the default dates to current quarter if in the first 2 months of the quarter
    if (today.isBefore(midQuarter)) {
      startMoment = moment().startOf("quarter");
      deadlineMoment = moment().endOf("quarter");

      default_start_date = startMoment.toISOString();
      default_deadline = deadlineMoment.toISOString();
      this.setState({
        periodPick: "current_quarter",
        startDateForm: default_start_date,
        deadlineForm: default_deadline
      });

      // If we're in the last month of the quarter, we default to the next quarter
    } else {
      startMoment = moment()
        .add(3, "months")
        .startOf("quarter");
      deadlineMoment = moment(startMoment).endOf("quarter");

      default_start_date = startMoment.toISOString();
      default_deadline = deadlineMoment.toISOString();
      this.setState({
        periodPick: "next_quarter",
        startDateForm: default_start_date,
        deadlineForm: default_deadline
      });
    }

    this.focusInput.focus();
  }

  handleCreateProject = (e: Object) => {
    e.preventDefault();
    const { parentProject } = this.props;
    const { title, update_frequency, global_permission_type, startDateForm, deadlineForm } = this.state;

    // Default settings
    let default_start_date = null;
    let default_deadline = null;
    let has_default_dates = false;

    if (startDateForm) {
      default_start_date = startDateForm;
      if (deadlineForm) {
        default_deadline = deadlineForm;
      }
      has_default_dates = true;
    }

    let parent_id = null;
    if (parentProject) {
      parent_id = parentProject.id;
    }

    const params = {
      title,
      global_permission_type,
      update_frequency,
      parent_id,
      default_deadline,
      default_start_date,
      has_default_dates
    };

    this.props.createProject(params).then(action => {
      if (action.type === projectsTypes.CREATE_COMPLETED) {
        const { push } = this.props;

        const projectId = action.response.data.id;
        push(`/pages/${projectId}`);
        this.props.hideForm();
      }
    });
  };

  handleChange = e => handleChange(this, e);

  handlePeriodPick = e => {
    const period = e.target.value;
    let default_start_date, default_deadline;
    let startMoment, deadlineMoment;
    if (period === "current_quarter") {
      startMoment = moment().startOf("quarter");
      deadlineMoment = moment().endOf("quarter");

      default_start_date = startMoment.toISOString();
      default_deadline = deadlineMoment.toISOString();
      this.setState({
        startDateForm: default_start_date,
        deadlineForm: default_deadline
      });
    }

    if (period === "next_quarter") {
      startMoment = moment()
        .add(3, "months")
        .startOf("quarter");
      deadlineMoment = moment(startMoment).endOf("quarter");

      default_start_date = startMoment.toISOString();
      default_deadline = deadlineMoment.toISOString();
      this.setState({
        startDateForm: default_start_date,
        deadlineForm: default_deadline
      });
    }
    if (period === "no_timeframe") {
      this.setState({
        startDateForm: null,
        deadlineForm: null
      });
    }

    this.handleChange(e);
  };

  handleDeadlineChange = moment => {
    let isoDate;
    try {
      isoDate = moment.toISOString();
    } catch (e) {
      isoDate = moment;
    }
    this.setState({
      deadlineForm: isoDate,
      periodPick: "custom"
    });
  };

  handleStartDateChange = moment => {
    let isoDate;
    try {
      isoDate = moment.toISOString();
    } catch (e) {
      isoDate = moment;
    }
    this.setState({
      startDateForm: isoDate,
      periodPick: "custom"
    });
  };

  goBack = e => {
    e.preventDefault();
    const { history } = this.props;
    history.goBack();
  };

  _formatProjectOptionLabel = (props, params) => {
    if (params.context === "menu") {
      return (
        <div>
          <div className="option-label">{props.label}</div>
          <div className="option-path">Pages / {props.path.label}</div>
        </div>
      );
    } else {
      return <div className="">{props.label}</div>;
    }
  };

  render() {
    const { currentWorkspace, currentMembership, parentProject, ui } = this.props;
    const { title, global_permission_type, update_frequency, periodPick, deadlineForm, startDateForm } = this.state;
    const deadlineFormMoment = moment(deadlineForm);
    const startDateFormMoment = moment(startDateForm);

    const parentProjectTitle = parentProject ? parentProject.title : null;

    // Check if you can edit permission
    const permissionsDisabled = currentWorkspace && !currentWorkspace.stripe_cache_subscription_plan;

    return (
      <Container className="modal-wrapper">
        <div className="modal-header">
          {parentProjectTitle && (
            <h2>
              Add a sub-page to <span>{parentProjectTitle}</span>
            </h2>
          )}
          {!parentProjectTitle && <h2>Add a new page</h2>}

          <ButtonIcon onClick={this.props.hideForm}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <form autoComplete="off" onSubmit={this.handleCreateProject}>
            <InlineMessage message={ui.message} messageType={ui.messageType} />
            <FormLine className="line-wrapper">
              <div className="label">This page will be used to track...</div>
              <div>
                <input
                  ref={input => {
                    this.focusInput = input;
                  }}
                  type="text"
                  className="title-input"
                  placeholder="Acme Q1 OKRs, Marketing goals, Strategic project..."
                  onChange={this.handleChange}
                  name="title"
                  value={title}
                  autoComplete="off"
                />
              </div>
            </FormLine>
            <FormLine className="line-wrapper">
              <div className="label">Remind the team to share progress every...</div>
              <div>
                <select name="update_frequency" value={update_frequency} onChange={this.handleChange}>
                  <option value="weekly">week</option>
                  <option value="monthly">month</option>
                  <option value="quarterly">quarter</option>
                </select>
              </div>
            </FormLine>
            {permissionsDisabled && (
              <ReactTooltip
                id="permissionsDisabled"
                place="right"
                type="dark"
                effect="solid"
                clickable={true}
                delayShow={200}
                delayHide={500}
              >
                You cannot edit permissions under the Free plan —{" "}
                {currentMembership.role === "owner" && (
                  <Link to="/settings/billing" className="tooltip" onClick={this.props.hideForm}>
                    please upgrade your plan
                  </Link>
                )}
                {currentMembership.role !== "owner" && <span>please upgrade your plan</span>} to edit access permissions
              </ReactTooltip>
            )}
            <FormLine className="line-wrapper">
              <div class="label">This page should be accessible to...</div>
              <div data-tip data-for="permissionsDisabled">
                <select
                  name="global_permission_type"
                  disabled={permissionsDisabled}
                  value={global_permission_type}
                  onChange={this.handleChange}
                >
                  <option value="edit">everyone in the workspace</option>
                  <option value="none">just me</option>
                </select>
              </div>
            </FormLine>
            <div className="line-wrapper">
              <RadioField>
                <div className="label">Pick a default timeframe for the goals:</div>
                <div className="radio-group text">
                  <input
                    type="radio"
                    id="current_quarter"
                    checked={periodPick === "current_quarter"}
                    name="periodPick"
                    value={"current_quarter"}
                    onChange={this.handlePeriodPick}
                  />
                  <label htmlFor="current_quarter">This quarter</label>
                  <input
                    type="radio"
                    id="next_quarter"
                    checked={periodPick === "next_quarter"}
                    name="periodPick"
                    value={"next_quarter"}
                    onChange={this.handlePeriodPick}
                  />
                  <label htmlFor="next_quarter">Next quarter</label>
                  <input
                    type="radio"
                    id="custom"
                    checked={periodPick === "custom"}
                    name="periodPick"
                    value={"custom"}
                    onChange={this.handlePeriodPick}
                  />
                  <label htmlFor="custom">Custom</label>
                  <input
                    type="radio"
                    id="no_timeframe"
                    checked={periodPick === "no_timeframe"}
                    name="periodPick"
                    value={"no_timeframe"}
                    onChange={this.handlePeriodPick}
                  />
                  <label htmlFor="no_timeframe">No timeframe</label>
                </div>
              </RadioField>
              {periodPick === "custom" && (
                <FormLine>
                  <div className="inline">
                    <div>
                      <div className="label">Goals should start on</div>
                      <div>
                        <Datetime
                          dateFormat="D MMM YYYY"
                          name="startDateForm"
                          value={startDateFormMoment}
                          onChange={this.handleStartDateChange}
                          inputProps={{ placeholder: "Start date" }}
                          timeFormat={false}
                          closeOnSelect={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div class="label">and finish on</div>
                      <div>
                        <Datetime
                          dateFormat="D MMM YYYY"
                          name="deadlineForm"
                          value={deadlineFormMoment}
                          onChange={this.handleDeadlineChange}
                          inputProps={{ placeholder: "Due date" }}
                          timeFormat={false}
                          closeOnSelect={true}
                        />
                      </div>
                    </div>
                  </div>
                </FormLine>
              )}
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <FormActions className="modal-actions">
            {!ui.isFetching && (
              <button onClick={this.handleCreateProject} type="submit" className="primary">
                Create
              </button>
            )}
            {ui.isFetching && (
              <button disabled className="primary">
                <Loader size="small" />
              </button>
            )}
            <button className="secondary" type="button" onClick={this.props.hideForm}>
              Cancel
            </button>
          </FormActions>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  createdProject: projectsSelectors.getObjectById(state.projects.byId, state.projects.createdId),
  currentWorkspace: state.session.currentWorkspace,
  currentMembership: state.session.currentMembership,
  projects: state.projects,
  ui: state.ui.projectNew
});

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  createProject: projectsOperations.createProject,
  push
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectsNew));
