// @flow

/**
 * This here is a project panel. It takes a projectId as argument and displays
 * the children project at that ID. If id is null, then we display root projects.
 *
 * If there's a project ID, we will display a progress summary under the child
 * projects.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { projectsOperations } from "state/ducks/projects";
import { sectionsOperations, sectionsSelectors } from "state/ducks/sections";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import * as constants from "config/constants";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// Components
import IconPlus from "v2/components/svg/IconPlus";
import IconChevron from "v2/components/svg/IconChevron";
import Loader from "v2/components/Loader";
import ButtonIcon from "v2/components/ButtonIcon";
import { Link } from "react-router-dom";
import ProjectStatsChartVictory from "v2/components/ProjectStatsChartVictory";
import ReactTooltip from "react-tooltip";

const Panel = styled.div`
  min-width: 42rem;
  width: 42rem;
  max-width: 100%;
  padding: ${spacing.x2} ${spacing.x2};
  border-right: 1px solid #d1d1d1;
  background-color: #fff;

  @media ${devices.tablet} {
    width: 100%;
    min-width: 100%;
  }

  .header {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: ${spacing.x1};
    color: #6a6a6a;
    font-weight: 600;
  }
`;
const PanelInnerWrapper = styled.div`
  margin: 0;
  &.add-button {
    margin-left: ${spacing.x1};
  }
`;

const PanelTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
  a {
    color: ${colors.black};
    align-items: center;
    display: flex;
    justify-content: space-between;
    svg {
      height: 1.4rem;
    }
    span {
      margin-right: ${spacing.x1};
    }
    &:hover span {
      text-decoration: underline;
    }
    .panel-loader {
      display: inline-block;
      margin-left: ${spacing.x1};
    }
  }
  margin-bottom: ${spacing.x2};
  .panel-loader;
`;

const ChildrenList = styled.ul`
  margin: ${spacing.x2} 0;

  li {
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0 ${spacing.x3};
    height: 4rem;
    line-height: 4rem;
    border-radius: 4px;

    button {
      font-size: 1.6rem;
      color: ${colors.black};
      &:focus {
        border: 0;
      }
      &:hover {
        text-decoration: none;
        color: ${colors.black};
      }
    }

    &:hover,
    &.selected,
    &:focus-within {
      background: #f3f3f3;
      border: 1px solid #d1d1d1;
    }
    &.selected {
      font-weight: 600;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      height: 1.6rem;
      margin-right: ${spacing.x1};
    }

    .panel-project-tree {
      border-left: 1px solid #d1d1d1;
      height: 4rem;
      width: 1.2rem;

      margin-right: ${spacing.x2};

      &::after {
        content: "";
        display: block;
        background-color: #d1d1d1;
        width: 1.2rem;
        height: 1px;
        margin-top: 20px;
      }
    }

    .panel-project-progress {
      font-weight: 600;
      margin-left: ${spacing.x1};
    }

    .panel-project-title {
      display: block;
      flex: 1;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

const SummaryWrapper = styled.div`
  padding: ${spacing.x2};
  border-radius: 8px;
  background-color: #f3f3f3;
`;

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.x2};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SummaryBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${spacing.x4};

  .content {
    display: flex;
    div {
      height: 24px;
      line-height: 24px;
      min-width: 24px;
      padding: 0 ${spacing.x1};
      font-weight: 600;
      color: #fff;
      border-radius: 4px;
      margin-right: ${spacing.x1};
      &.red {
        background-color: ${colors.red};
      }
      &.yellow {
        background-color: ${colors.yellow};
      }
      &.green {
        background-color: ${colors.green};
      }
      &.grey {
        background-color: ${colors.grey};
        color: #6a6a6a;
      }
      &.black {
        background-color: ${colors.black};
      }
    }
    span {
      font-weight: bold;
      font-size: 2rem;
    }
  }
`;

const SectionBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .content {
    li {
      span {
        font-weight: 400;
      }
      display: flex;
      justify-content: space-between;
      margin-bottom: ${spacing.x1};
    }
  }
`;

const ArchivedLabel = styled.span`
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  font-weight: 500;
  color: #fff;
  background-color: #955be3;
  border-radius: 4px;
  padding: 0 ${spacing.x1};
  margin-right: ${spacing.x1};
`;

const SectionProgress = styled.div`
  background-color: #d1d1d1;
  opacity: 1;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6rem;
  min-width: 6rem;
  height: 2.4rem;
  font-size: 1.4rem;
  border-radius: 16px;
  margin-left: ${spacing.x1};
  font-weight: 600;

  position: relative;
  overflow: hidden;
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  currentProject?: squadTypes.Project,
  fetchProjectList: Function,
  fetchSectionList: Function,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  projectSections: Array<squadTypes.Section>,
  selectChildProject: Function,
  showModal: Function,
  toggleProjectNav: Function,
  ui: Object
};

class ProjectPanel extends Component<Props> {
  myRef: any;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this._fetchChildrenProjects();
    this.myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  // Fetch Project list if the show_archived has changed
  componentDidUpdate(prevProps) {
    if (prevProps.ui.showArchived !== this.props.ui.showArchived) {
      this._fetchChildrenProjects();
    }
  }

  // This function expands a project and adds another panel
  _selectChild = (e: Object) => {
    e.preventDefault();

    const { currentProject } = this.props;

    // We use "root" as the parent if this is the first panel
    const projectId = currentProject ? currentProject.id : "root";
    const childId = e.currentTarget.dataset.id;
    this.props.selectChildProject(projectId, childId);
  };

  _showProjectNewForm = (e: Object) => {
    e.preventDefault();
    const { currentWorkspace, currentProject } = this.props;

    let pageQuotaExceeded = false;
    // Check if we have exceeded the quota of pages.
    // This is only applied to plans that have the new billing packages
    if (
      currentWorkspace.active_projects_limit !== 0 &&
      currentWorkspace.active_projects_count >= currentWorkspace.active_projects_limit &&
      !constants.BILLING_PER_USER_PLANS.includes(currentWorkspace.stripe_cache_subscription_plan)
    ) {
      pageQuotaExceeded = true;
    }

    if (pageQuotaExceeded) {
      this.props.showModal({
        contentType: "workspace",
        contentAction: "pages.quota.reached"
      });
    } else {
      this.props.showModal({
        contentType: "project",
        contentAction: "new",
        object: currentProject
      });
    }
  };

  // This function fetches the sub-pages for the current project
  _fetchChildrenProjects = () => {
    const { currentProject, fetchSectionList, ui } = this.props;
    const parent = currentProject ? currentProject.id : "";
    const params = {
      parent,
      show_archived: ui.showArchived
    };
    this.props.fetchProjectList(params);
    if (parent) {
      fetchSectionList(parent);
    }
  };

  render() {
    const { currentWorkspace, projects, currentProject, ui } = this.props;

    const parent_id = currentProject ? currentProject.id : null;
    const show_archived = ui.showArchived; // Show archived flags is stored in redux

    // Getting the list of projects that should be displayed
    const projectsToDisplay = projects.allIds.filter(projectId => {
      const projectToTest = projects.byId[projectId];
      // We need to double-check if archived projects should be shown.
      if (show_archived) {
        return projectToTest.parent_id === parent_id;
      } else {
        return projectToTest.parent_id === parent_id && projectToTest.is_archived === false;
      }
    });

    const pageTitle = currentProject ? currentProject.title : currentWorkspace.name;

    let progressPrct = null;
    if (currentProject) {
      progressPrct = Math.round(currentProject.cached_prct_progress_normalized * 100);
    }

    // Used to have some kind of id for the react tooltip
    const tooltipId = currentProject ? `panel-${currentProject.id}-create-button` : "panel-root-create-button";
    return (
      <Panel ref={this.myRef}>
        <PanelInnerWrapper>
          {currentProject && (
            <PanelTitle>
              <Link to={`/pages/${currentProject.id}`}>
                <span>
                  {pageTitle}
                  {ui.isFetchingChildrenOfProject[currentProject.id] && (
                    <div className="panel-loader">
                      <Loader size="small" />
                    </div>
                  )}
                </span>
                <div>
                  <IconChevron />
                </div>
              </Link>
            </PanelTitle>
          )}
          {!currentProject && <PanelTitle>{pageTitle}</PanelTitle>}
          {currentProject && (
            <SummaryWrapper>
              <SummaryContainer className="no-border">
                <SummaryBlock>
                  <div className="header">Progress</div>
                  <div className="content">
                    <span>{progressPrct}%</span>
                  </div>
                </SummaryBlock>
                <SummaryBlock className="hide-tablet">
                  <div className="header">Goals status</div>
                  <div className="content">
                    <div className="grey" data-tip data-for={`draftGoals-${currentProject.id}`}>
                      {currentProject.cached_draft_goals}
                    </div>
                    <div className="green" data-tip data-for={`greenGoals-${currentProject.id}`}>
                      {currentProject.cached_active_green_goals}
                    </div>
                    <div className="yellow" data-tip data-for={`yellowGoals-${currentProject.id}`}>
                      {currentProject.cached_active_yellow_goals}
                    </div>
                    <div className="red" data-tip data-for={`redGoals-${currentProject.id}`}>
                      {currentProject.cached_active_red_goals}
                    </div>
                    <div className="black" data-tip data-for={`closedGoals-${currentProject.id}`}>
                      {currentProject.cached_closed_goals}
                    </div>
                  </div>
                </SummaryBlock>
                <ReactTooltip id={`closedGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_closed_goals} goal{currentProject.cached_closed_goals !== 1 && "s"} closed
                </ReactTooltip>
                <ReactTooltip id={`draftGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_draft_goals} goal{currentProject.cached_draft_goals !== 1 && "s"} pending
                  updates
                </ReactTooltip>
                <ReactTooltip id={`greenGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_active_green_goals} goal
                  {currentProject.cached_active_green_goals !== 1 && "s"} on-track
                </ReactTooltip>
                <ReactTooltip id={`yellowGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_active_yellow_goals} goal
                  {currentProject.cached_active_yellow_goals !== 1 && "s"} at-risk
                </ReactTooltip>
                <ReactTooltip id={`redGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_active_red_goals} goal
                  {currentProject.cached_active_red_goals !== 1 && "s"} off-track
                </ReactTooltip>
              </SummaryContainer>
              {currentProject.has_default_dates && (
                <SummaryContainer>
                  <ProjectStatsChartVictory project={currentProject} />
                </SummaryContainer>
              )}
              <SummaryContainer>
                <SectionBlock>
                  <div className="header">Summary</div>
                  <div className="content">
                    <ul>
                      {this.props.projectSections.map(section => {
                        const progressPrct = Math.round(section.cached_prct_progress_normalized * 100);
                        return (
                          <li key={section.id}>
                            <span>{section.title}</span>
                            <SectionProgress>{progressPrct}%</SectionProgress>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </SectionBlock>
              </SummaryContainer>
            </SummaryWrapper>
          )}
        </PanelInnerWrapper>
        <ChildrenList>
          {currentProject && <div className="header">Sub-pages</div>}
          {!currentProject && <div className="header">Pages</div>}
          {projectsToDisplay.map(projectId => {
            const project = projects.byId[projectId];
            if (project) {
              let selectedClassname = "";
              if (ui.selectedChildren.includes(project.id)) {
                selectedClassname = "selected";
              }

              const progress = Math.round(project.cached_prct_progress_normalized * 100);

              return (
                <li
                  key={`p-${project.id}`}
                  className={selectedClassname}
                  onClick={this._selectChild}
                  data-id={project.id}
                >
                  <div className="panel-project-tree" />
                  <div className="panel-project-title">
                    <button className="link-button" onClick={this._selectChild}>
                      {project.title}
                    </button>
                  </div>
                  <div className="panel-project-progress">
                    {project.is_archived && <ArchivedLabel>Archived</ArchivedLabel>}
                    {progress}%
                  </div>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ChildrenList>
        <PanelInnerWrapper className="add-button">
          <ButtonIcon onClick={this._showProjectNewForm} data-tip data-for={tooltipId}>
            <IconPlus />
          </ButtonIcon>
          <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
            Create a new page
          </ReactTooltip>
        </PanelInnerWrapper>
      </Panel>
    );
  }
}

const mapStateToProps = (state, props) => {
  let projectSections = [];
  if (props.currentProject) {
    projectSections = sectionsSelectors.getListByParentId(state.sections.byId, "project", props.currentProject.id);
  }
  return {
    currentWorkspace: state.session.currentWorkspace,
    projects: state.projects,
    projectSections,
    ui: state.ui.projectList
  };
};

const mapDispatchToProps = {
  fetchProjectList: projectsOperations.fetchProjectList,
  fetchSectionList: sectionsOperations.fetchSectionList,
  selectChildProject: uiOperations.selectChildProject,
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPanel);
