import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { searchedGoalsOperations } from "state/ducks/searchedGoals";
import { goalsOperations } from "state/ducks/goals";
import { push } from "react-router-redux";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import moment from "moment";
import _ from "lodash";

//V2 styling
import { spacing } from "v2/components/styles/spacing";

// Components
import AsyncSelect from "react-select/lib/Async";
import Select from "v2/components/Select";
import Datetime from "react-datetime";

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${spacing.x2} 0;
  padding-bottom: ${spacing.x2};
  border-bottom: 1px solid #d4d4d4;

  > div {
    margin-bottom: ${spacing.x1};
  }
  .wide-menu .react-filter__menu {
    min-width: 400px;
    max-width: 500px;
  }
  .react-filter__control {
    box-shadow: none;
    min-width: 15rem;
    margin-right: ${spacing.x2};
  }

  .react-filter__control--is-focused {
    box-shadow: none;
  }

  .react-filter__value-container {
    padding: 0 0 0 ${spacing.x1};
    margin: 0;

    input {
      font-size: 1.4rem !important;
      height: 2.4rem !important;
      margin: 0;
      padding: 0;
    }
  }

  .timeline {
    width: 18rem;
  }
`;

const FormLine = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 1.6rem;
  }

  > label {
    font-weight: 600;
    display: inline-block;
    margin-right: 0.4rem;
  }

  .tiny {
    width: 12rem;
  }
`;

type Props = {
  currentGoal: squadTypes.Goal,
  fetchGoalDetails: Function,
  fetchGoalSearch: Function,
  fetchGoalSearchStats: Function,
  goals: squadTypes.NormalizedList<squadTypes.Goal>,
  location: Object,
  match: Object,
  push: Function,
  resetGoalList: Function,
  ui: Object
};

type State = {
  projectOptions: Array<Object>,
  tagOptions: Array<Object>
};

class GoalList extends Component<Props, State> {
  state = {
    projectOptions: [],
    tagOptions: []
  };

  debouncedFetchMembershipsForAutocomplete: Function;
  constructor(props) {
    super(props);
    this.debouncedFetchMembershipsForAutocomplete = _.debounce(this._fetchMembershipsForAutocomplete, 300);
  }

  componentDidMount() {
    document.title = "Goals | Tability";
    this._fetchProjectsForAutocomplete();
    this._fetchTagsForAutocomplete();
  }

  // This function extracts the current page from the Redux state and
  // fetches the next page.
  updateFilters(attribute, value) {
    const { filter } = this.props.match.params;
    let filterHash = {};
    if (filter) {
      const decodedFilter = atob(filter);
      filterHash = JSON.parse(decodedFilter);
    }
    filterHash[attribute] = value;

    let {
      project,
      membership,
      state,
      status,
      tag,
      timeline,
      custom_start_date,
      custom_deadline,
      order_attribute,
      order_direction
    } = filterHash;

    if (attribute === "timeline" && value === "custom") {
      custom_start_date = moment()
        .startOf("year")
        .toISOString();
      custom_deadline = moment()
        .endOf("year")
        .toISOString();
    }

    if (timeline === null) {
      custom_deadline = null;
      custom_start_date = null;
    }

    const params = {
      project,
      membership,
      state,
      status,
      tag,
      timeline,
      custom_start_date,
      custom_deadline,
      order_attribute,
      order_direction
    };
    const encodedParams = btoa(JSON.stringify(params));
    this.props.push(`/goals/${encodedParams}`);
  }

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchMembershipsForAutocomplete = (inputValue: ?string, callback: Function) => {
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const membershipsApiURL = `/workspaces/${slug}/memberships`;
    let membershipsParams = inputValue
      ? {
          name: inputValue
        }
      : null;
    request.get(membershipsApiURL, { params: membershipsParams }).then(response => {
      const users = response.data;
      if (users && users.length > 0) {
        const userOptions = users.map(result => {
          const user = result.user;
          return {
            value: result.id,
            label: user.fullname || user.email
          };
        });
        callback(userOptions);
      } else {
        callback();
      }
    });
    return;
  };

  handleOwnerSelect = (options: any) => {
    if (options) {
      //const membership = options.map(option => option.value);
      this.updateFilters("membership", options);
    }
  };

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchProjectsForAutocomplete = params => {
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const projectsApiURL = `/workspaces/${slug}/projects`;
    request.get(projectsApiURL, { params }).then(response => {
      const projects = response.data;
      if (projects && projects.length > 0) {
        const projectOptions = projects.map(project => {
          return {
            value: project.id,
            label: project.title,
            path: project.path
          };
        });
        this.setState({ projectOptions });
      } else {
        this.setState({
          projectOptions: []
        });
      }
    });
  };

  handleProjectSelect = (options: any) => {
    if (options) {
      const project = options.map(option => option.value);
      this.updateFilters("project", project);
    }
  };

  // This function is going to fetch the list of tags using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchTagsForAutocomplete = params => {
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const tagsApiURL = `/workspaces/${slug}/tags`;
    request.get(tagsApiURL, { params }).then(response => {
      const tags = response.data;
      if (tags && tags.length > 0) {
        const tagOptions = tags.map(tag => {
          return {
            value: tag.id,
            label: tag.title
          };
        });
        this.setState({ tagOptions });
      } else {
        this.setState({
          tagOptions: []
        });
      }
    });
  };

  handleTagSelect = (options: any) => {
    if (options) {
      const tag = options.map(option => option.value);
      this.updateFilters("tag", tag);
    }
  };

  handleStatusSelect = (options: any) => {
    if (options) {
      const status = options.map(option => option.value);
      this.updateFilters("status", status);
    }
  };

  handleTimelineSelect = (selectedOption: any, action) => {
    if (selectedOption) {
      const timeline = selectedOption.value;
      this.updateFilters("timeline", timeline);
    }

    if (action.action === "clear") {
      const timeline = null;
      this.updateFilters("timeline", timeline);
    }
  };

  handleDeadlineChange = moment => {
    let isoDate;
    try {
      isoDate = moment.toISOString();
    } catch (e) {
      isoDate = moment;
    }

    this.updateFilters("custom_deadline", isoDate);
  };

  handleStartDateChange = moment => {
    let isoDate;
    try {
      isoDate = moment.toISOString();
    } catch (e) {
      isoDate = moment;
    }
    this.updateFilters("custom_start_date", isoDate);
  };

  handleStateSelect = (options: any) => {
    if (options) {
      const state = options.map(option => option.value);
      this.updateFilters("state", state);
    }
  };

  _formatProjectOptionLabel = (props, params) => {
    if (params.context === "menu") {
      return (
        <div>
          <div className="option-label">{props.label}</div>
          <div className="option-path">Pages / {props.path.label}</div>
        </div>
      );
    } else {
      return <div className="ellipsed-label">{props.label}</div>;
    }
  };

  render() {
    const { projectOptions, tagOptions } = this.state;
    const stateOptions = [
      { label: "Open", value: "open" },
      { label: "Closed", value: "closed" }
    ];
    const timelineOptions = [
      { label: "This quarter", value: "current_quarter" },
      { label: "Last quarter", value: "last_quarter" },
      { label: "Next quarter", value: "next_quarter" },
      { label: "Custom", value: "custom" }
    ];
    const statusOptions = [
      { label: "Off-track", value: "red" },
      { label: "At-risk", value: "yellow" },
      { label: "On-track", value: "green" },
      { label: "Pending updates", value: "grey" }
    ];

    // Get the filter information from the URL itself
    const { filter } = this.props.match.params;
    let filterHash = {};
    if (filter) {
      const decodedFilter = atob(filter);
      filterHash = JSON.parse(decodedFilter);
    }

    // Set the default values from the filters
    const defaultProject = projectOptions.filter(project => {
      if (filterHash["project"] && project) {
        return filterHash["project"].indexOf(project.value) >= 0;
      } else {
        return false;
      }
    });

    const defaultMembership = filterHash["membership"];

    const defaultState = stateOptions.filter(state => {
      if (filterHash["state"] && state) {
        return filterHash["state"].indexOf(state.value) >= 0;
      } else {
        return false;
      }
    });
    const defaultStatus = statusOptions.filter(status => {
      if (filterHash["status"] && status) {
        return filterHash["status"].indexOf(status.value) >= 0;
      } else {
        return false;
      }
    });
    const defaultTimeline = timelineOptions.filter(timeline => {
      if (filterHash["timeline"] && timeline) {
        return filterHash["timeline"].indexOf(timeline.value) >= 0;
      } else {
        return false;
      }
    });
    const defaultTag = tagOptions.filter(tag => {
      if (filterHash["tag"] && tag) {
        return filterHash["tag"].indexOf(tag.value) >= 0;
      } else {
        return false;
      }
    });

    const customStartDate = moment(filterHash["custom_start_date"]);
    const customDeadline = moment(filterHash["custom_deadline"]);

    return (
      <FilterContainer>
        <Select
          isMulti
          placeholder="Page"
          name="project"
          value={defaultProject}
          options={projectOptions}
          className="basic-multi-select wide-menu"
          classNamePrefix="react-filter"
          formatOptionLabel={this._formatProjectOptionLabel}
          onChange={this.handleProjectSelect}
        />
        <AsyncSelect
          isMulti
          placeholder="Owner"
          name="membership"
          value={defaultMembership}
          loadOptions={this.debouncedFetchMembershipsForAutocomplete}
          defaultOptions={true}
          className="basic-multi-select wide-menu"
          classNamePrefix="react-filter"
          onChange={this.handleOwnerSelect}
        />
        <Select
          isMulti
          placeholder="Status"
          name="status"
          value={defaultStatus}
          options={statusOptions}
          className="basic-multi-select"
          classNamePrefix="react-filter"
          onChange={this.handleStatusSelect}
        />
        <Select
          isMulti
          placeholder="State"
          name="state"
          value={defaultState}
          options={stateOptions}
          className="basic-multi-select"
          classNamePrefix="react-filter"
          onChange={this.handleStateSelect}
        />
        <Select
          isMulti
          placeholder="Tag"
          name="tag"
          value={defaultTag}
          options={tagOptions}
          className="basic-multi-select"
          classNamePrefix="react-filter"
          onChange={this.handleTagSelect}
        />
        <Select
          placeholder="Timeline"
          name="timeline"
          value={defaultTimeline}
          options={timelineOptions}
          isClearable={true}
          className="basic-multi-select timeline"
          classNamePrefix="react-filter"
          onChange={this.handleTimelineSelect}
        />
        {defaultTimeline[0] && defaultTimeline[0].value === "custom" && (
          <FormLine>
            <label>Starts after</label>
            <Datetime
              dateFormat="D MMM YYYY"
              name="startDateForm"
              value={customStartDate}
              onChange={this.handleStartDateChange}
              inputProps={{ placeholder: "Start date" }}
              timeFormat={false}
              closeOnSelect={true}
              className="tiny"
            />
            <label>Ends before</label>
            <Datetime
              dateFormat="D MMM YYYY"
              name="deadlineForm"
              value={customDeadline}
              onChange={this.handleDeadlineChange}
              inputProps={{ placeholder: "Due date" }}
              timeFormat={false}
              closeOnSelect={true}
              className="tiny"
            />
          </FormLine>
        )}
      </FilterContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { location } = props;
  let currentGoal = null;
  if (location && location.search) {
    const params = new URLSearchParams(location.search);
    const selected = params.get("selected");
    if (selected && state.goals.byId) {
      currentGoal = state.goals.byId[selected];
    }
  }
  return {
    currentWorkspace: state.session.currentWorkspace,
    currentGoal,
    goals: state.searchedGoals,
    ui: state.ui.goalList
  };
};

const mapDispatchToProps = {
  fetchGoalDetails: goalsOperations.fetchGoalDetails,
  fetchGoalSearch: searchedGoalsOperations.fetchGoalSearch,
  fetchGoalSearchStats: searchedGoalsOperations.fetchGoalSearchStats,
  resetGoalList: searchedGoalsOperations.resetGoalList,
  push
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoalList));
