// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { commentsOperations } from "state/ducks/comments";
import { handleChange } from "v2/utils/forms";
import * as commentTypes from "state/ducks/comments/types";

import { spacing } from "v2/components/styles/spacing";

// Components
import Avatar from "v2/components/Avatar";
import FormField from "v2/components/FormField";
import MentionBox from "v2/components/MentionBox";

import Loader from "v2/components/Loader";

const Container = styled.div`
  display: flex;
  margin-top: ${spacing.x2};
`;

const AvatarContainer = styled.div`
  margin-right: ${spacing.x2};
`;

const StyledForm = styled.form`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  button {
    margin-right: ${spacing.x1};
  }
`;

type Props = {
  comment?: squadTypes.Comment,
  currentUser: squadTypes.User,
  createComment: Function,
  commentable: Object,
  hideEditForm: Function,
  updateComment: Function,
  ui: Object
};

type State = {
  text: string
};

class CommentForm extends Component<Props, State> {
  state = {
    text: ""
  };

  user: squadTypes.User;

  constructor(props) {
    super(props);
    const { comment, currentUser } = props;
    if (comment) {
      this.setState({
        text: comment.text
      });
      this.user = comment.user;
    } else {
      this.user = currentUser;
    }
  }

  handleChange = e => handleChange(this, e);

  createOrUpdateComment = (e: Object) => {
    e.preventDefault();
    const { comment, createComment, commentable, updateComment } = this.props;
    const { text } = this.state;
    const params = {
      text
    };

    if (comment) {
      updateComment(comment.id, params).then(action => {
        if (action.type === commentTypes.UPDATE_COMPLETED) {
          this.props.hideEditForm(comment.id);
        }
      });
    } else {
      createComment(commentable.id, params).then(action => {
        if (action.type === commentTypes.CREATE_COMPLETED) {
          this.setState({
            text: ""
          });
        }
      });
    }
  };

  hideCommentForm = e => {
    e.preventDefault();
    const { comment, hideEditForm } = this.props;
    if (comment) {
      hideEditForm(comment.id);
    }
  };

  _handleMentionChange = (value: string) => {
    this.setState({
      text: value
    });
  };

  render() {
    const { comment, ui } = this.props;
    const { text } = this.state;
    let submitText;
    if (ui.formState === "requestiong") {
      submitText = <Loader size="small" />;
    } else {
      if (comment) {
        submitText = "Update";
      } else {
        submitText = "Post";
      }
    }

    const { errorFields } = ui;

    const minimized = text !== "" ? false : true;

    return (
      <Container>
        <AvatarContainer>
          <Avatar user={this.user} hideName={true} size={25} />
        </AvatarContainer>
        <StyledForm>
          <MentionBox setValue={this._handleMentionChange} value={text} minimized={minimized} comment={true} />
          {ui.formState === "failed" && (
            <span className="error">
              <small>Sorry but we could not save your comment</small>
            </span>
          )}
          {errorFields["text"] && (
            <span className="error">
              <small>You cannot submit an empty comment</small>
            </span>
          )}
          <FormField className="comment-field">
            <Actions>
              {text && (
                <button
                  type="submit"
                  onClick={this.createOrUpdateComment}
                  className="primary"
                  disabled={ui.formState === "requesting" || text.trim() === ""}
                >
                  {submitText}
                </button>
              )}
              {comment && (
                <button onClick={this.hideCommentForm} className="secondary">
                  Cancel
                </button>
              )}
            </Actions>
          </FormField>
        </StyledForm>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  ui: state.ui.commentForm
});

const mapDispatchToProps = {
  updateComment: commentsOperations.updateComment
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
