// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { spacing } from "v2/components/styles/spacing";
import { devices } from "v2/components/styles/devices";

import { handleChange } from "v2/utils/forms";
import { accountOperations } from "state/ducks/account";
import { uiOperations } from "state/ducks/ui";

// Components
import EmptyLayout from "v2/components/EmptyLayout";
import Tabby from "./_assets/tabby.png";
import FormField from "v2/components/FormField";
import InlineMessage from "v2/components/InlineMessage";
import Loader from "v2/components/Loader";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

const Message = styled.div`
  display: flex;

  .profile_pic {
    img {
      width: 200px;
    }
  }

  .profile_detail {
    margin: 0 ${spacing.x4};
  }

  p {
    font-size: 1.8rem;
    line-height: 1.3;
    font-weight: 400;
  }

  @media ${devices.mobile} {
    flex-direction: column;

    .profile_detail {
      margin: ${spacing.x2} 0;
    }
  }
`;

const Agreement = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-right: ${spacing.x4};
  margin-top: ${spacing.x1};
`;

const CheckboxContainer = styled.div`
  border-top: 1px solid #d8d8d8;
  margin-top: ${spacing.x2};
  padding-top: ${spacing.x2};
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x1};
  font-size: 1.6rem;
  line-height: 1.3;
  input {
    margin-right: ${spacing.x1};
  }
`;

type Props = {
  clearPage: Function,
  currentUser: squadTypes.User,
  ui: Object,
  updateAccount: Function
};

type State = {
  privacy_email_product: boolean
};

class ProfileNew extends Component<Props, State> {
  state = {
    privacy_email_product: true
  };

  componentDidMount() {
    this.props.clearPage();
  }

  handleNext = e => {
    const { privacy_email_product } = this.state;

    const params = {
      privacy_email_product,
      privacy_policy_accepted: true
    };
    this.props.updateAccount(params);
  };

  handleChange = e => handleChange(this, e);

  render() {
    const { ui } = this.props;
    const { privacy_email_product } = this.state;
    return (
      <Container>
        <EmptyLayout title="Please agree to our terms and policy to continue using Tability">
          <Message>
            <div className="profile_pic">
              <img src={Tabby} alt="Tabby says hi!" />
            </div>
            <div className="profile_detail">
              <p>At Tability we work hard to keep things light and easy, including our communications.</p>
              <p>
                You are seeing this message because either you have just signed up, or we have recently updated our
                Terms of Service and Privacy Policy.
              </p>
              <p>You'll need to agree below to continue using Tability.</p>
              <p>
                This is also an occasion for you to review your notification preferences for product updates & training.
              </p>
              <FormField>
                <CheckboxContainer>
                  <input
                    checked={privacy_email_product}
                    onChange={this.handleChange}
                    name="privacy_email_product"
                    component="input"
                    type="checkbox"
                  />{" "}
                  Receive emails twice a month about product updates and training
                </CheckboxContainer>
              </FormField>
              <InlineMessage message={ui.message} messageType={ui.messageType} />
              {!ui.isFetching && (
                <button onClick={this.handleNext} type="submit" className="primary">
                  Continue
                </button>
              )}
              {ui.isFetching && (
                <button disabled className="primary">
                  <Loader size="small" />
                </button>
              )}
              <Agreement>
                By continuing, you agree to the{" "}
                <a href="https://docs.tability.io/privacy-policy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="https://docs.tability.io/terms-of-use" target="_blank" rel="noopener noreferrer">
                  Terms of use
                </a>
                .
              </Agreement>
            </div>
          </Message>
        </EmptyLayout>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  uploadedAsset: state.account.uploadedAsset,
  currentUser: state.session.currentUser,
  ui: state.ui.onboarding
});

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  updateAccount: accountOperations.updateAccount,
  uploadAsset: accountOperations.uploadAssetDance
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNew);
