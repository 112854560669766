//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg``;
export default (props: Object) => (
  <Logo viewBox="0 0 20 20" {...props}>
    <title id="Help-Help-Title">Guides and resources</title>
    <g id="Help-Buttons-and-Forms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Help-Help-Button" transform="translate(-9.000000, -7.000000)" stroke="#6A6A6A">
        <g id="Help-Group" transform="translate(10.000000, 8.000000)">
          <g id="Help-help-circle" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <circle id="Help-Oval" cx="9" cy="9" r="9"></circle>
          </g>
          <circle id="Help-Oval2" cx="9" cy="14" r="1"></circle>
          <path
            d="M9,11 L9,10 C11,10 12,9 12,7 C12,5 11,4 9,4 C7,4 6,5.34314575 6,7"
            id="Help-Oval3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </g>
    </g>
  </Logo>
);
