// @flow
import React from "react";
import styled from "styled-components";
import { spacing } from "./styles/spacing";

const Container = styled.div`
  background-color: #e7e7e7;
  border-radius: 3px;
  padding: ${spacing.x2} ${spacing.x3};
  margin-bottom: ${spacing.x2};

  &.info {
    background-color: #7e98e6;
  }
  &.warning {
    background-color: #f9dd79;
  }

  &.error {
    background-color: #f69380;
  }

  &.success {
    background-color: #8cc1b5;
  }
`;

type Props = {
  message: string,
  messageType: string
};

export default (props: Props) => {
  const { message, messageType } = props;
  if (message) {
    return (
      <Container className={messageType} role="status" aria-live="polite">
        {message}
      </Container>
    );
  } else {
    return null;
  }
};
