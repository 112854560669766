//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import moment from "moment";
import { commentsOperations } from "state/ducks/comments";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

// Components
import Avatar from "v2/components/Avatar";
import Markdown from "v2/components/Markdown";
import ReactTooltip from "react-tooltip";

const Container = styled.div`
  display: flex;
  margin-bottom: ${spacing.x2};
  padding-bottom: ${spacing.x2};
  border-bottom: 1px solid ${colors.blockBorder};

  &.mine .avatar {
    border: 2px solid #6ecad6;
  }

  .comment-actions {
    opacity: 0;
  }

  &:hover,
  &:focus {
    .comment-actions {
      opacity: 1;
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .comment-main {
  }
`;

const Meta = styled.div`
  align-self: stretch;
  display: flex;
  width: 100%;
  margin-top: ${spacing.x1};
  font-size: 1.2rem;
`;

const AvatarContainer = styled.div`
  margin-right: ${spacing.x2};
`;

type Props = {
  comment: squadTypes.Comment,
  currentMembership: squadTypes.Membership,
  currentUser: squadTypes.User,
  deleteComment: Function,
  showEditForm: Function
};

class CommentCard extends Component<Props> {
  handleEditClick = e => {
    e.preventDefault();
    const { comment, showEditForm } = this.props;
    showEditForm(comment.id);
  };

  handleDeleteClick = e => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to delete this comment?`)) {
      const { comment, deleteComment } = this.props;
      deleteComment(comment.id);
    }
  };

  render() {
    const { comment, currentMembership, currentUser } = this.props;
    const { user } = comment;
    const displayEditButton = user
      ? currentUser.id === user.id || ("admin", "owner").includes(currentMembership.role)
      : false;
    const ownClass = currentUser.id === user.id ? "mine" : "";
    return (
      <Container className={ownClass}>
        <AvatarContainer>
          <Avatar user={user} hideName={true} size={25} data-tip data-for={`comment-${user.id}-author`} />
          <ReactTooltip id={`comment-${user.id}-author`} place="right" type="dark" effect="solid" delayShow={500}>
            {user.fullname}
          </ReactTooltip>
        </AvatarContainer>
        <Card>
          {comment.text && (
            <div className="comment-main">
              <Markdown source={comment.text} />
            </div>
          )}
          <Meta>
            <small className="subtle">{moment(comment.created_at).fromNow()}&nbsp;</small>
            {displayEditButton && (
              <div className="comment-actions">
                {" "}
                &middot;{" "}
                <small>
                  <button className="subtle link-button" onClick={this.handleEditClick}>
                    Edit
                  </button>
                </small>{" "}
                &middot;{" "}
                <small>
                  <button className="subtle link-button" onClick={this.handleDeleteClick}>
                    Delete
                  </button>
                </small>
              </div>
            )}
          </Meta>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: state.session.currentUser,
  currentMembership: state.session.currentMembership
});

const mapDispatchToProps = {
  deleteComment: commentsOperations.deleteComment
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentCard);
