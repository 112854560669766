// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import * as squadTypes from "squadTypes";
import * as dateFormatUtils from "v2/utils/dateFormat";
import { uiOperations } from "state/ducks/ui";
import { projectsOperations } from "state/ducks/projects";

import { request } from "state/utils";

// V2 styling
import { colors } from "v2/components/styles/colors";

import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryArea,
  VictoryLabel,
  VictoryLine,
  VictoryAxis,
  //VictoryScatter,
  VictoryTooltip,
  VictoryTheme
} from "victory";

//import IconCommentG from "v2/components/svg/IconCommentG";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  h3 {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    margin-top: 2.5rem;
    border-top: 1px solid #d4d4d4;
  }
`;

type Props = {
  project: squadTypes.Project,
  showModal: Function,
  fetchProjectTrends: Function,
  ui: Object
};

type State = {
  containerWidth: number,
  statsData: Array<Object>,
  isLoading: boolean
};

/*
type CommentProps = {
  x?: number,
  y?: number,
  datum?: any
};

class CommentPoint extends Component<CommentProps> {
  render() {
    const { x, y, datum } = this.props;
    if (!datum || !x || !y) {
      return null;
    }
    const { statusUpdate } = datum;
    if (statusUpdate && statusUpdate.comments_count > 0) {
      return (
        <g transform={`translate(${x},${y - 26})`}>
          <IconCommentG comments_count={statusUpdate.comments_count} />
        </g>
      );
    }
    return null;
  }
}
*/
class UpdatesChart extends Component<Props, State> {
  state = {
    containerWidth: 355,
    isLoading: false,
    statsData: []
  };
  container = null;
  minDomain = 0;

  componentDidMount() {
    this._fetchData();
    if (this.container) {
      this.setState({
        containerWidth: this.container.offsetWidth
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project !== this.props.project) {
      this._fetchData();
    }
  }

  _fetchData = () => {
    const { project } = this.props;
    const projectsStatsApiURL = `/projects/${project.id}/trends`;
    this.setState(
      {
        isLoading: true,
        statsData: []
      },
      () => {
        request.get(projectsStatsApiURL).then(response => {
          const statsData = response.data;
          this.setState({
            statsData,
            isLoading: false
          });
        });
      }
    );
  };

  getChartData = () => {
    const { project } = this.props;
    const { statsData } = this.state;

    let currentData = [];
    let targetData = [];
    if (!statsData) {
      return {
        currentDataSorted: currentData,
        targetDataSorted: targetData
      };
    }
    if (project.default_start_date) {
      const startDate = moment(project.default_start_date);
      const startTimestamp = startDate.valueOf();
      const firstPoint = {
        timestamp: startTimestamp,
        x: startTimestamp,
        y: 0
      };
      targetData.push(firstPoint);

      const point = {
        x: startTimestamp,
        y: 0,
        timestamp: startTimestamp,
        total_goals: 0,
        draft_goals: 0,
        active_red_goals: 0,
        active_yellow_goals: 0,
        active_green_goals: 0,
        closed_goals: 0,
        prct_progress_normalized: 0
      };
      currentData.push(point);
    }

    if (project.default_deadline) {
      const deadlineDate = moment(project.default_deadline);
      const lastPoint = {
        timestamp: deadlineDate.valueOf(),
        x: deadlineDate.toDate(),
        y: 100
      };
      targetData.push(lastPoint);
    }

    statsData.forEach(dataPoint => {
      const pointDate = moment(dataPoint.date_index);
      const point = {
        x: pointDate.toDate(),
        y: Math.round((dataPoint.prct_progress_normalized || 0) * 100),
        timestamp: pointDate.valueOf(),
        total_goals: dataPoint.total_goals || 0,
        draft_goals: dataPoint.draft_goals || 0,
        active_red_goals: dataPoint.active_red_goals || 0,
        active_yellow_goals: dataPoint.active_yellow_goals || 0,
        active_green_goals: dataPoint.active_green_goals || 0,
        closed_goals: dataPoint.closed_goals || 0,
        prct_progress_normalized: Math.round((dataPoint.prct_progress_normalized || 0) * 100)
      };

      if (project.default_deadline && pointDate.valueOf() > moment(project.default_deadline).valueOf()) {
        const lastPoint = {
          timestamp: pointDate.valueOf(),
          x: pointDate.toDate(),
          y: 100
        };
        targetData.push(lastPoint);
      }

      currentData.push(point);
    });

    const currentDataSorted = currentData.sort((a, b): any => {
      return a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0;
    });
    const targetDataSorted = targetData.sort((a, b): any => {
      return a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0;
    });

    return {
      currentDataSorted,
      targetDataSorted
    };
  };

  render() {
    const { project } = this.props;
    if (!project) {
      return false;
    }

    const data = this.getChartData();
    const currentData = data.currentDataSorted;
    const targetData = data.targetDataSorted;

    const ratio = 190 / 355;
    const chartWidth = this.state.containerWidth;
    const chartHeight = ratio * chartWidth;

    return (
      <Container ref={el => (this.container = el)}>
        <VictoryChart
          width={chartWidth}
          height={chartHeight}
          padding={{ top: 20, bottom: 50, left: 50, right: 0 }}
          theme={VictoryTheme.material}
          domainPadding={{ x: [0, 20], y: 20 }}
          minDomain={{ y: this.minDomain }}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryAxis dependentAxis data={targetData} tickFormat={t => `${t}%`} tickCount={2} />
          <VictoryAxis
            tickCount={3}
            scale="time"
            data={targetData}
            tickLabelComponent={<VictoryLabel />}
            tickFormat={t => dateFormatUtils.formatShortDate(t)}
          />
          <VictoryGroup
            style={{
              data: { strokeWidth: 2 }
            }}
            scale="time"
          >
            <VictoryArea
              style={{
                data: {
                  fill: "#B184EA",
                  fillOpacity: 0.1,
                  stroke: "none"
                }
              }}
              data={targetData}
            />

            <VictoryLine
              style={{
                data: {
                  stroke: "#B184EA",
                  strokeWidth: 3
                }
              }}
              labels={({ datum }) => [dateFormatUtils.formatLabelDate(datum.x), `Progress: ${datum.y}%`]}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={4}
                  flyoutStyle={{
                    fill: "rgba(255, 255, 255, 1)",
                    stroke: colors.blockBorder
                  }}
                  flyoutHeight={80}
                  labelComponent={
                    <VictoryLabel
                      lineHeight={2}
                      style={[
                        {
                          fontWeight: "600",
                          textTransform: "uppercase",
                          fill: colors.subtleText,
                          fontSize: "12px"
                        },
                        {
                          fontWeight: "400",
                          fill: colors.black,
                          fontSize: "16px",
                          marginBottom: 4
                        }
                      ]}
                    />
                  }
                />
              }
              data={currentData}
              interpolation="linear"
            />
          </VictoryGroup>
        </VictoryChart>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  ui: state.ui.modalProjectTrends
});

const mapDispatchToProps = {
  showModal: uiOperations.showModal,
  fetchProjectTrends: projectsOperations.fetchProjectTrends
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatesChart);
