//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import * as squadTypes from "squadTypes";
import moment from "moment";
import { statusUpdatesOperations } from "state/ducks/statusUpdates";
import { reactionsOperations } from "state/ducks/reactions";
import { uiOperations } from "state/ducks/ui";

// Components
import Avatar from "v2/components/Avatar";
import ButtonIcon from "v2/components/ButtonIcon";
import DropdownMenu from "v2/components/DropdownMenu";
import IconMenu from "v2/components/svg/IconMenu";
import IconComment from "v2/components/svg/IconComment";
import IconLike from "v2/components/svg/IconLike";
import Markdown from "v2/components/Markdown";
import StatusBox from "v2/components/StatusBox";
import ReactTooltip from "react-tooltip";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

const UpdateContainer = styled.div`
  display: flex;
  border-top: 1px solid ${colors.blockBorder};
  padding: ${spacing.x2} ${spacing.x2} 0 ${spacing.x2};
  margin-top: ${spacing.x2};

  &.latest {
    background: #f3f3f3;
    border-radius: 8px;
    padding: ${spacing.x2};
    margin-top: 0;
    border-top: 0;
  }
`;
const AvatarWrapper = styled.div`
  margin-right: ${spacing.x2};
`;
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.x1};
`;

const Progress = styled.div`
  display: flex;
`;

const Actions = styled.div`
  display: flex;
  button {
    margin-right: ${spacing.x1};
    span {
      padding-left: 4px;
    }
    padding: 0 ${spacing.x1};
  }
`;

const ContentMain = styled.div`
  margin-bottom: ${spacing.x1};
`;
const ContentFooter = styled.div``;

type Props = {
  latest: boolean,
  currentMembership: squadTypes.Membership,
  createReactionForStatusUpdate: Function,
  deleteStatusUpdate: Function,
  deleteReaction: Function,
  fetchStatusUpdateDetails: Function,
  goal: squadTypes.Goal,
  match: Object,
  showModal: Function,
  statusUpdate: squadTypes.StatusUpdate
};

class StatusUpdateCard extends Component<Props> {
  componentDidMount() {
    const { statusUpdate, match } = this.props;
    if (statusUpdate && statusUpdate.id === match.params.statusUpdate) {
      this.props.showModal({
        contentType: "comments",
        contentAction: "view",
        object: this.props.statusUpdate,
        parent: this.props.goal,
        centered: false
      });
    }
  }
  deleteStatusUpdate = e => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to delete this update?`)) {
      const id = e.currentTarget.dataset.id;
      this.props.deleteStatusUpdate(id);
    }
  };

  showStatusUpdateEditForm = (e: Object) => {
    e.preventDefault();
    this.props.showModal({
      contentType: "statusUpdate",
      contentAction: "edit",
      object: this.props.statusUpdate,
      parent: this.props.goal
    });
  };

  showComments = (e: Object) => {
    e.preventDefault();
    this.props.showModal({
      contentType: "comments",
      contentAction: "view",
      object: this.props.statusUpdate,
      parent: this.props.goal,
      centered: false
    });
  };

  addReaction = (e: Object) => {
    e.preventDefault();
    const { statusUpdate } = this.props;
    this.props.createReactionForStatusUpdate(statusUpdate.id, {}).then(() => {
      this.props.fetchStatusUpdateDetails(statusUpdate.id);
    });
  };

  deleteReaction = (e: Object) => {
    e.preventDefault();
    const { statusUpdate } = this.props;
    this.props.deleteReaction(statusUpdate.reactionable_id).then(() => {
      this.props.fetchStatusUpdateDetails(statusUpdate.id);
    });
  };

  getMenuItems = () => {
    const { currentMembership, statusUpdate } = this.props;
    const { author } = statusUpdate;
    const canEdit =
      currentMembership.role === "admin" ||
      currentMembership.role === "owner" ||
      currentMembership.user_id === author.id;

    const items = [];
    if (canEdit) {
      items.push(
        <button className="link-button" onClick={this.showStatusUpdateEditForm}>
          Edit
        </button>
      );
      items.push(
        <button className="link-button" data-id={this.props.statusUpdate.id} onClick={this.deleteStatusUpdate}>
          Delete
        </button>
      );
    }

    return items;
  };

  render() {
    const { goal, statusUpdate } = this.props;
    const { author } = statusUpdate;
    let scoreWithFormat = "";
    let colorToString = "";
    const menuItems = this.getMenuItems();
    switch (statusUpdate.color) {
      case "red":
        colorToString = "Off track";
        break;
      case "yellow":
        colorToString = "At risk";
        break;
      case "green":
        colorToString = "On track";
        break;
      default:
        colorToString = "Pending";
        break;
    }
    if (statusUpdate.score || statusUpdate.score === 0) {
      if (goal.score_format) {
        scoreWithFormat = goal.score_format.replace("_target_", parseFloat(statusUpdate.score).toLocaleString("en-US"));
      } else {
        scoreWithFormat = statusUpdate.score.toString();
      }
    }
    const latestClass = this.props.latest ? "latest" : "";
    return (
      <UpdateContainer className={latestClass}>
        <AvatarWrapper>
          <Avatar user={author} size={24} data-tip data-for={`status-${statusUpdate.id}-author`} />
          {author && (
            <ReactTooltip
              id={`status-${statusUpdate.id}-author`}
              place="bottom"
              type="dark"
              effect="solid"
              delayShow={500}
            >
              {author.fullname}
            </ReactTooltip>
          )}
        </AvatarWrapper>
        <ContentWrapper>
          <ContentHeader>
            <Progress>
              <StatusBox className={statusUpdate.color} />
              {scoreWithFormat && <span>{scoreWithFormat}</span>}
              {!scoreWithFormat && <span>{colorToString}</span>}
            </Progress>
            <Actions>
              <ButtonIcon className="auto-width" onClick={this.showComments}>
                <IconComment />
                {statusUpdate.comments_count > 0 && <span className="counter">{statusUpdate.comments_count}</span>}
              </ButtonIcon>
              {!statusUpdate.reactionable_id && (
                <ButtonIcon className="auto-width" onClick={this.addReaction}>
                  <IconLike className="like" />
                  {statusUpdate.reactions_count > 0 && <span className="counter">{statusUpdate.reactions_count}</span>}
                </ButtonIcon>
              )}
              {statusUpdate.reactionable_id && (
                <ButtonIcon className="auto-width" onClick={this.deleteReaction}>
                  <IconLike className="like remove" />
                  {statusUpdate.reactions_count > 0 && <span className="counter">{statusUpdate.reactions_count}</span>}
                </ButtonIcon>
              )}
              {menuItems.length > 0 && (
                <DropdownMenu
                  trigger={
                    <ButtonIcon>
                      <IconMenu />
                    </ButtonIcon>
                  }
                  items={menuItems}
                />
              )}
            </Actions>
          </ContentHeader>
          {statusUpdate.text && (
            <ContentMain>
              <Markdown source={statusUpdate.text} />
            </ContentMain>
          )}
          <ContentFooter>
            <small className="subtle">{moment(statusUpdate.update_date).fromNow()}</small>
          </ContentFooter>
        </ContentWrapper>
      </UpdateContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentMembership: state.session.currentMembership
});

const mapDispatchToProps = {
  deleteStatusUpdate: statusUpdatesOperations.deleteStatusUpdate,
  deleteReaction: reactionsOperations.deleteReaction,
  fetchStatusUpdateDetails: statusUpdatesOperations.fetchStatusUpdateDetails,
  showModal: uiOperations.showModal,
  createReactionForStatusUpdate: reactionsOperations.createReactionForStatusUpdate
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatusUpdateCard));
