// @flow
import { createReducer } from "state/utils";
import * as accountTypes from "state/ducks/account/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  errorFields: {},
  formState: "pending",
  avatarState: "pending",
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [accountTypes.UPDATE_ACCOUNT_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [accountTypes.UPDATE_ACCOUNT_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "Your account has been updated",
      messageType: "success"
    };
  },
  [accountTypes.UPDATE_ACCOUNT_FAILED]: (state, action) => {
    let errorFields = {};
    if (action.error.response) {
      const errors = action.error.response.data.errors;
      if (errors["fullname"]) {
        errorFields["fullname"] = `Your name ${errors["fullname"][0]}`;
      }
    }
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not update your account",
      messageType: "warning"
    };
  },
  [accountTypes.UPLOAD_ASSET_FAILED]: (state, action) => ({
    ...state,
    avatarState: "failed",
    message: "Sorry, we could not save your avatar.",
    messageType: "warning"
  }),
  [accountTypes.UPLOAD_ASSET_COMPLETED]: state => ({
    ...state,
    avatarState: "completed",
    message: "Your avatar has been uploaded",
    messageType: "warning"
  }),
  [accountTypes.UPLOAD_ASSET_REQUESTED]: state => ({
    ...state,
    avatarState: "requesting",
    message: "Your avatar has been uploaded",
    messageType: "warning"
  }),
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
