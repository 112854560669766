//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { sessionOperations } from "state/ducks/session";
import * as squadTypes from "squadTypes";

import { spacing } from "v2/components/styles/spacing";
import { devices } from "v2/components/styles/devices";

import { Link } from "react-router-dom";
import Wordmark from "v2/components/_assets/tability_logo_full.png";

const Container = styled.div`
  width: 80rem;
  min-height: 60rem;

  @media ${devices.mobile} {
    width: 100%;
    padding: ${spacing.x4};
  }
`;

const Header = styled.header`
  img {
    height: 4rem;
    display: block;
    margin-bottom: ${spacing.x8};
  }

  h1 {
    margin-bottom: ${spacing.x4};
  }
`;

const Footer = styled.footer`
  border-top: 1px solid #d1d1d1;
  margin-top: ${spacing.x2};
  padding-top: ${spacing.x2};
  display: flex;
  justify-content: center;
  color: #6a6a6a;
  font-size: 1.2rem;

  a,
  .link-button {
    color: #6a6a6a;
    display: block;
    margin: 0 ${spacing.x1};
  }
`;

export const HelpLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${spacing.x3} 0 ${spacing.x2} 0;
`;

type Props = {
  children: any,
  currentWorkspace: squadTypes.Workspace,
  currentMembership: squadTypes.Membership,
  currentUser: squadTypes.User,
  logout: Function,
  title: string
};

class EmptyLayout extends Component<Props> {
  _logout = (e: Object) => {
    e.preventDefault();
    this.props.logout();
  };

  render() {
    const { children, currentMembership, currentUser, currentWorkspace, title } = this.props;
    const displaySettingsLink =
      currentWorkspace && currentMembership && ["admin", "owner"].indexOf(currentMembership.role) >= 0;
    return (
      <Container>
        <Header>
          <Link to="/">
            <img src={Wordmark} alt="Tability" />
          </Link>
          <h1>{title}</h1>
        </Header>
        <main>{children}</main>
        {currentUser && (
          <Footer>
            <button className="link-button" onClick={this._logout}>
              Logout
            </button>
            <Link to="/account">Account settings</Link>
            {displaySettingsLink && <Link to="/settings">Workspace settings</Link>}
          </Footer>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentMembership: state.session.currentMembership,
  currentUser: state.session.currentUser,
  currentWorkspace: state.session.currentWorkspace
});

const mapDispatchToProps = {
  logout: sessionOperations.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyLayout);
