//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";

import { tasksOperations } from "state/ducks/tasks";

// Components

import IconTaskClosed from "v2/components/_assets/IconTaskClosed.png";
import IconTaskOpen from "v2/components/_assets/IconTaskOpen.png";
import IconBin from "v2/components/svg/IconBin";
import ButtonIcon from "v2/components/ButtonIcon";
import ButtonIconSpan from "v2/components/ButtonIconSpan";
import IconJiraPng from "v2/components/_assets/IconJira.png";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

const JiraConnectIcon = styled.img`
  height: 1.6rem;
`;

const TaskButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;

  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  &:hover {
    background: transparent;
    background-image: url(${props => props.hoverImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    border: 0;
  }

  &:focus {
    border: 0;
  }
`;

const Label = styled.div`
  background-color: #f3f3f3;
  color: ${colors.subtleText};
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.4rem ${spacing.x1};
  text-align: center;
  vertical-align: baseline;
  text-transform: uppercase;
  position: relative;
  border-radius: 4px;
`;

const EditableTaskDescription = styled.div`
  overflow-wrap: break-word;
  overflow: hidden;
  background: transparent;
  border: 2px solid transparent;
  font-weight: 400;
  resize: none;
  color: ${colors.black};
  outline: none;
  max-width: 600px;

  transition: all ease 0.2s;

  display: block;
  border-radius: 0;

  &:focus {
    border: 2px solid transparent;
    border-bottom: 2px solid ${colors.blue};
  }
`;

type Props = {
  deleteTask: Function,
  isDragging?: boolean,
  draggingHorizontal?: boolean,
  task: squadTypes.Task,
  updateTask: Function,
  withDrag?: boolean
};

type State = {
  displayTaskInput: string,
  url: string,
  title: ?string
};

class Tasks extends Component<Props, State> {
  taskDescriptionInput: any;
  state = {
    displayTaskInput: "",
    url: "",
    title: ""
  };

  _updateTaskDescription = e => {
    e.preventDefault();
    const { task, updateTask } = this.props;
    const description = e.target.innerText;
    const params = {
      description
    };
    updateTask(task.id, params);
  };

  _toggleTask = (task: squadTypes.Task) => {
    const state = task.state === "open" ? "closed" : "open";

    const params = {
      state
    };

    this.props.updateTask(task.id, params);
  };

  _handlePress = e => {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.taskDescriptionInput.blur();
    }

    if (e.keyCode === 13) {
      e.preventDefault();
      this.taskDescriptionInput.blur();
    }
  };

  _handlePaste = e => {
    e.preventDefault();
    let text = e.clipboardData.getData("text/plain");
    text = text.replace(/(\r\n|\n|\r)/gm, " "); // remove the line breaks
    document.execCommand("insertHTML", false, text);
  };

  render() {
    const { isDragging, task } = this.props;

    const background = isDragging ? "#fff" : "transparent";

    const actionsOpacity = isDragging ? 1 : 0;
    const closedTaskOpacity = isDragging ? 1 : 0.5;
    const Task = styled.div`
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: 0;
      background-color: ${background};
      position: relative;
      padding: ${spacing.x1};
      border: 1px solid ${background};
      border-radius: 4px;

      &:hover,
      &:focus-within {
        opacity: 1;

        .task-actions {
          opacity: 1;
        }
      }

      &.closed {
        opacity: ${closedTaskOpacity};
        &:hover {
          opacity: 1;
        }

        .task-content {
          text-decoration: line-through;
        }
      }

      &:hover .actions {
        opacity: 1;
      }
      .task-state {
        align-self: flex-start;
        margin-right: ${spacing.x1};
      }

      .task-content {
        align-self: flex-start;
        flex: 1;
        margin: 0;
        display: flex;
        flex-direction: column;
      }

      .task-actions {
        display: flex;
        opacity: ${actionsOpacity};

        align-self: flex-start;
        transition: all ease 0.3s;

        .drag-icon {
          transform: ${props => (this.props.draggingHorizontal ? "rotate(0deg)" : "rotate(-90deg)")};
          color: ${colors.subtleText};
          margin: 0 ${spacing.x1};
        }
      }
    `;
    if (task.integration_type === "jira_cloud" && task.integration_remote_id) {
      return (
        <Task key={task.id} className={`${task.state} task`}>
          <div className="task-state">
            <ButtonIcon onClick={() => this._toggleTask(task)}>
              <JiraConnectIcon src={IconJiraPng} />
            </ButtonIcon>
          </div>
          <div className={`task-content`}>
            <EditableTaskDescription>
              <a href={task.description} target="_blank" rel="noopener noreferrer">
                {task.integration_description}
              </a>
              <Label>{task.integration_meta.fields.status.name}</Label>
            </EditableTaskDescription>
          </div>
          <div className="task-actions">
            {this.props.withDrag && <ButtonIconSpan className="drag-icon">&harr;</ButtonIconSpan>}
            <ButtonIcon
              onClick={e => {
                e.preventDefault();
                this.props.deleteTask(task.id);
              }}
            >
              <IconBin />
            </ButtonIcon>
          </div>
        </Task>
      );
    }
    return (
      <Task key={task.id} className={`${task.state} task`}>
        <div className="task-state">
          {task.state === "open" && (
            <TaskButton onClick={() => this._toggleTask(task)} bgImage={IconTaskOpen} hoverImage={IconTaskClosed} />
          )}
          {task.state === "closed" && (
            <TaskButton onClick={() => this._toggleTask(task)} bgImage={IconTaskClosed} hoverImage={IconTaskOpen} />
          )}
        </div>
        <div className={`task-content`}>
          <EditableTaskDescription
            ref={div => {
              this.taskDescriptionInput = div;
            }}
            contentEditable={true}
            autoComplete="off"
            spellCheck={false}
            data-gramm_editor={false}
            onKeyDown={this._handlePress}
            onBlur={this._updateTaskDescription}
            onPaste={this._handlePaste}
            suppressContentEditableWarning={true}
          >
            {task.description}
          </EditableTaskDescription>
        </div>
        <div className="task-actions">
          {this.props.withDrag && <ButtonIconSpan className="drag-icon">&harr;</ButtonIconSpan>}
          <ButtonIcon
            onClick={e => {
              e.preventDefault();
              this.props.deleteTask(task.id);
            }}
          >
            <IconBin />
          </ButtonIcon>
        </div>
      </Task>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  deleteTask: tasksOperations.deleteTask,
  updateTask: tasksOperations.updateTask
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
