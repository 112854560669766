//@flow
import { request } from "state/utils";
import * as types from "./types";
import { getSubdomain } from "state/utils/url";

const slug = getSubdomain() || "";

export const massUpdateNotification = (notificationParams: Object) => {
  return {
    types: [types.MASS_UPDATE_REQUESTED, types.MASS_UPDATE_COMPLETED, types.MASS_UPDATE_FAILED],
    callAPI: () => request.put(`/workspaces/${slug}/notifications`, notificationParams),
    payload: { notificationParams }
  };
};

export const updateNotification = (notificationId: string, notificationParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/notifications/${notificationId}`, { notification: notificationParams }),
    payload: { id: notificationId }
  };
};

export const fetchNotificationList = (params?: Object) => {
  const filters = [];
  if (params) {
    if (params.page) {
      filters.push(`page=${params.page}`);
    }
  }
  const filtersQuery = filters.join("&");
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/workspaces/${slug}/notifications?${filtersQuery}`)
  };
};

export const fetchUnseenCount = () => {
  return {
    types: [types.FETCH_UNSEEN_COUNT_REQUESTED, types.FETCH_UNSEEN_COUNT_COMPLETED, types.FETCH_UNSEEN_COUNT_FAILED],
    callAPI: () => request.get(`/workspaces/${slug}/unseen_notifications`)
  };
};

export const resetNotificationList = () => ({
  type: types.RESET_LIST
});
