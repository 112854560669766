// @flow
/**
 * This page is used to perform Slack authentication and redirect to the right domain
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { sessionOperations } from "state/ducks/session";
import * as sessionTypes from "state/ducks/session/types";
import { uiOperations } from "state/ducks/ui";

// Components
import { Link } from "react-router-dom";
import Loader from "v2/components/Loader";

const Container = styled.main`
  display: flex;
  justify-content: center;
  margin: 10vh 0 0 0;
  ion-icon {
    font-size: 2.2rem;
    padding-right: 0.8rem;
  }
`;

type Props = {
  clearPage: Function,
  googleAuth: Function,
  tokens: Object,
  ui: Object,
  location: Object
};

type State = {};

class GoogleAuth extends Component<Props, State> {
  componentDidMount() {
    const { location } = this.props;
    this.props.clearPage();
    // get the params from the URL

    if (location && location.search) {
      const params = new URLSearchParams(location.search);

      const code = params.get("code");
      const state = params.get("state");

      const stateDecoded = state ? JSON.parse(atob(state)) : null;

      if (stateDecoded) {
        const googleAuthParams = {
          code,
          state
        };

        // Then post the auth request to the backend
        this.props.googleAuth(googleAuthParams).then(action => {
          if (action.type === sessionTypes.GOOGLE_AUTH_COMPLETED) {
            if (stateDecoded.mobile) {
              window.location.href = `${stateDecoded.domain}?tokens=${btoa(JSON.stringify(this.props.tokens))}`;
            } else {
              window.location.href = `https://${stateDecoded.domain}`;
            }
          }
        });
      }
    }

    // If the action is successful, redirect to the domain param
  }

  render() {
    const { ui } = this.props;
    return (
      <Container>
        {!ui.isFetching && !ui.error && <div>Redirecting you.</div>}

        {!ui.isFetching && ui.error && (
          <div>
            <p>Sorry, we could not log you in.</p>
            <p>
              <Link to="/">Go back to login</Link>
            </p>
          </div>
        )}
        {ui.isFetching && <Loader />}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.session.isAuthenticated,
  tokens: state.session.tokens,
  ui: state.ui.googleAuth
});

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  googleAuth: sessionOperations.googleAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuth);
