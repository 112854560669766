//@flow
export const UPDATE_REQUESTED = "notifications/UPDATE_REQUESTED";
export const UPDATE_COMPLETED = "notifications/UPDATE_COMPLETED";
export const UPDATE_FAILED = "notifications/UPDATE_FAILED";

export const MASS_UPDATE_REQUESTED = "notifications/MASS_UPDATE_REQUESTED";
export const MASS_UPDATE_COMPLETED = "notifications/MASS_UPDATE_COMPLETED";
export const MASS_UPDATE_FAILED = "notifications/MASS_UPDATE_FAILED";

export const FETCH_LIST_REQUESTED = "notifications/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "notifications/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "notifications/FETCH_LIST_FAILED";

export const FETCH_UNSEEN_COUNT_REQUESTED = "notifications/FETCH_UNSEEN_COUNT_REQUESTED";
export const FETCH_UNSEEN_COUNT_COMPLETED = "notifications/FETCH_UNSEEN_COUNT_COMPLETED";
export const FETCH_UNSEEN_COUNT_FAILED = "notifications/FETCH_UNSEEN_COUNT_FAILED";

export const RESET_LIST = "notifications/RESET_LIST";
