//@flow
export const CREATE_REQUESTED = "reactions/CREATE_REQUESTED";
export const CREATE_COMPLETED = "reactions/CREATE_COMPLETED";
export const CREATE_FAILED = "reactions/CREATE_4_GOAL_FAILED";

export const UPDATE_REQUESTED = "reactions/UPDATE_REQUESTED";
export const UPDATE_COMPLETED = "reactions/UPDATE_COMPLETED";
export const UPDATE_FAILED = "reactions/UPDATE_FAILED";

export const DELETE_REQUESTED = "reactions/DELETE_REQUESTED";
export const DELETE_COMPLETED = "reactions/DELETE_COMPLETED";
export const DELETE_FAILED = "reactions/DELETE_FAILED";

export const FETCH_LIST_REQUESTED = "reactions/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "reactions/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "reactions/FETCH_LIST_FAILED";

export const FETCH_DETAILS_REQUESTED = "reactions/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "reactions/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "reactions/FETCH_DETAILS_FAILED";

export const RESET_LIST = "reactions/RESET_LIST";
