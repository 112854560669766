import React, { Component } from "react";
import { connect } from "react-redux";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

// V2 styling
import { spacing } from "v2/components/styles/spacing";

// Components
import IconPrivate from "v2/components/_assets/IconPrivate.png";
import IconPublic from "v2/components/svg/IconPublic";

import ReactTooltip from "react-tooltip";

const Container = styled.div`
  margin-right: ${spacing.x1};
  button {
    display: flex;
    align-items: center;

    span {
      width: 1.6rem;
      margin-right: ${spacing.x1};
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: auto;
      }
    }

    svg {
      margin-right: ${spacing.x1};
    }
  }
`;

type Props = {
  showModal: Function,
  currentProject: squadTypes.Project
};

class ProjectPermissions extends Component<Props> {
  _showPermissionModal = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "permissions",
      object: this.props.currentProject
    });
  };

  _showShareModal = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "share",
      object: this.props.currentProject
    });
  };

  render() {
    const { currentProject } = this.props;

    if (currentProject.is_public === true) {
      return (
        <Container>
          <button onClick={this._showShareModal} data-tip data-for="share-preview-report">
            <IconPublic data-tip data-for="public-report" /> Share
          </button>
          <ReactTooltip id="share-preview-report" place="bottom" type="dark" effect="solid" delayShow={200}>
            Share the report view with your team
          </ReactTooltip>
        </Container>
      );
    }

    if (currentProject.is_public === false) {
      return (
        <Container>
          <button onClick={this._showShareModal} data-tip data-for="share-preview-report">
            <span>
              <img alt="Private" src={IconPrivate} data-tip data-for="private-report" />
            </span>{" "}
            Share
          </button>
          <ReactTooltip id="share-preview-report" place="bottom" type="dark" effect="solid" delayShow={200}>
            Share the report view with your team
          </ReactTooltip>
        </Container>
      );
    }

    return false;
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPermissions);
