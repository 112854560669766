// @flow
import { combineReducers } from "redux";
import * as uiOperations from "./operations";
import accountSettings from "./accountSettingsReducer";
import accountSettings2FA from "./accountSettings2FAReducer";
import accountSettingsEmail from "./accountSettingsEmailReducer";
import accountSettingsNotifications from "./accountSettingsNotificationsReducer";
import accountSettingsPassword from "./accountSettingsPasswordReducer";
import commentForm from "./commentFormReducer";
import goalForm from "./goalFormReducer";
import goalLineNew from "./goalLineNewReducer";
import goalLinks from "./goalLinksReducer";
import goalDetails from "./goalDetailsReducer";
import goalList from "./goalListReducer";
import goalTasks from "./goalTasksReducer";
import googleAuth from "./googleAuthReducer";
import googleLogin from "./googleLoginReducer";
import jiraConnectSettings from "./jiraConnectSettingsReducer";
import jiraConnectGoals from "./jiraConnectGoalsReducer";
import jiraInstall from "./jiraInstallReducer";
import membershipForm from "./membershipFormReducer";
import membershipList from "./membershipListReducer";
import membershipSetting from "./membershipSettingReducer";
import notificationList from "./notificationListReducer";
import modal from "./modalReducer";
import modalProjectTrends from "./modalProjectTrendsReducer";
import moveGoalForm from "./moveGoalFormReducer";
import onboarding from "./onboardingReducer";
import projectNew from "./projectNewReducer";
import projectSettings from "./projectSettingsReducer";
import projectSettingsDelete from "./projectSettingsDeleteReducer";
import projectSettingsNotifications from "./projectSettingsNotificationsReducer";
import projectSettingsPermissions from "./projectSettingsPermissionsReducer";
import projectDetails from "./projectDetailsReducer";
import projectList from "./projectListReducer";
import projectPreview from "./projectPreviewReducer";
import projectPreviewTV from "./projectPreviewTVReducer";
import projectShareForm from "./projectShareFormReducer";
import sessionConfirm from "./sessionConfirmReducer";
import sessionLogin from "./sessionLoginReducer";
import sessionNewPassword from "./sessionNewPasswordReducer";
import sessionResetPassword from "./sessionResetPasswordReducer";
import sessionSignup from "./sessionSignupReducer";
import slackAuth from "./slackAuthReducer";
import slackInstall from "./slackInstallReducer";
import statusUpdateForm from "./statusUpdateFormReducer";
import statusUpdateComments from "./statusUpdateCommentsReducer";
import statusUpdateViews from "./statusUpdateViewsReducer";
import unsubscribe from "./unsubscribeReducer";
import workspaceHome from "./workspaceHomeReducer";
import workspaceNav from "./workspaceNavReducer";
import workspaceOnboardingInfo from "./workspaceOnboardingInfoReducer";
import workspaceOnboardingNotifications from "./workspaceOnboardingNotificationsReducer";
import workspaceOnboardingTeam from "./workspaceOnboardingTeamReducer";
import workspaceSettings from "./workspaceSettingsReducer";
import workspaceSettingsSecurity from "./workspaceSettingsSecurityReducer";
import workspaceSettingsSSO from "./workspaceSettingsSSOReducer";
import workspaceSettingsIntegrations from "./workspaceSettingsIntegrationsReducer";
import workspaceSettingsDelete from "./workspaceSettingsDeleteReducer";
import workspaceSettingsBilling from "./workspaceSettingsBillingReducer";
import workspaceSettingsMembershipDetails from "./workspaceSettingsMembershipDetailsReducer";
import workspaceSettingsNotifications from "./workspaceSettingsNotificationsReducer";
import workspaceSettingsPlan from "./workspaceSettingsPlanReducer";
import workspaceSettingsPlanConfirm from "./workspaceSettingsPlanConfirmReducer";

export default combineReducers({
  accountSettings,
  accountSettings2FA,
  accountSettingsEmail,
  accountSettingsNotifications,
  accountSettingsPassword,
  commentForm,
  goalForm,
  goalLineNew,
  goalLinks,
  goalDetails,
  goalList,
  goalTasks,
  googleLogin,
  googleAuth,
  jiraConnectGoals,
  jiraConnectSettings,
  jiraInstall,
  membershipForm,
  membershipList,
  membershipSetting,
  moveGoalForm,
  notificationList,
  modal,
  modalProjectTrends,
  onboarding,
  projectNew,
  projectSettings,
  projectSettingsDelete,
  projectSettingsNotifications,
  projectSettingsPermissions,
  projectShareForm,
  projectDetails,
  projectList,
  projectPreview,
  projectPreviewTV,
  sessionConfirm,
  sessionLogin,
  sessionNewPassword,
  sessionResetPassword,
  sessionSignup,
  slackAuth,
  slackInstall,
  statusUpdateComments,
  statusUpdateViews,
  statusUpdateForm,
  unsubscribe,
  workspaceHome,
  workspaceNav,
  workspaceOnboardingInfo,
  workspaceOnboardingNotifications,
  workspaceOnboardingTeam,
  workspaceSettings,
  workspaceSettingsSecurity,
  workspaceSettingsSSO,
  workspaceSettingsBilling,
  workspaceSettingsDelete,
  workspaceSettingsIntegrations,
  workspaceSettingsPlan,
  workspaceSettingsPlanConfirm,
  workspaceSettingsMembershipDetails,
  workspaceSettingsNotifications
});

export { uiOperations };
