import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import * as sectionTypes from "state/ducks/sections/types";
import { sectionsOperations } from "state/ducks/sections";

// Components
import ButtonIcon from "v2/components/ButtonIcon";
import DropdownMenu from "v2/components/DropdownMenu";
import IconMenu from "v2/components/svg/IconMenu";

type Props = {
  section: squadTypes.Section,
  sectionsCount: number,
  deleteSection: Function,
  fetchSectionList: Function,
  moveSection: Function
};

class SectionMenu extends Component<Props> {
  deleteSection = e => {
    const { section } = this.props;
    e.preventDefault();
    if (window.confirm(`Are you sure you want to delete this section?`)) {
      const id = section.id;
      this.props.deleteSection(id);
    }
  };

  moveSection = e => {
    e.preventDefault();
    const { fetchSectionList, section } = this.props;
    const position = parseInt(e.currentTarget.dataset.position, 10);
    const sectionParams = {
      position,
      oldPosition: section.position
    };
    this.props.moveSection(section.id, sectionParams).then(action => {
      if (action.type === sectionTypes.MOVE_COMPLETED) {
        fetchSectionList(section.project_id);
      }
    });
  };

  getMenuItems = () => {
    const { section, sectionsCount } = this.props;
    const items = [];
    if (section.position > 0) {
      items.push(
        <button className="link-button" onClick={this.moveSection} data-position={section.position - 1}>
          Move section up
        </button>
      );
    }

    if (section.position < sectionsCount - 1) {
      items.push(
        <button className="link-button" onClick={this.moveSection} data-position={section.position + 1}>
          Move section down
        </button>
      );
    }

    items.push(
      <button className="link-button" onClick={this.deleteSection}>
        Delete section
      </button>
    );

    return items;
  };

  render() {
    return (
      <DropdownMenu
        position="right"
        trigger={
          <ButtonIcon>
            <IconMenu />
          </ButtonIcon>
        }
        items={this.getMenuItems()}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  deleteSection: sectionsOperations.deleteSection,
  fetchSectionList: sectionsOperations.fetchSectionList,
  moveSection: sectionsOperations.moveSection
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionMenu);
