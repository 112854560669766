// @flow
import React, { Component } from "react";
import styled from "styled-components";
import * as squadTypes from "squadTypes";

import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import _ from "lodash";

// Components
import IconGoal from "v2/components/svg/IconGoal";
import Loader from "v2/components/Loader";
import { Link } from "react-router-dom";

const Container = styled.div``;

type Props = {
  query: ?string,
  hideForm: Function
};

type State = {
  isFetching: boolean,
  goals: Array<squadTypes.Goal>
};

class GoalSearch extends Component<Props, State> {
  state = {
    isFetching: true,
    goals: []
  };

  debouncedFetchGoals: Function;

  constructor(props: Object) {
    super(props);
    this.debouncedFetchGoals = _.debounce(this._fetchGoals, 300);
    this.debouncedFetchGoals(this.props.query);
  }

  componentDidUpdate(prevProps: Object) {
    if (this.props.query !== prevProps.query) {
      this.debouncedFetchGoals(this.props.query);
    }
  }

  // This function is going to fetch the list of goal using the value of the input as a starting point.
  _fetchGoals = (query: ?string) => {
    if (!query) {
      this.setState({
        goals: [],
        isFetching: false
      });
      return;
    }
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const goalsApiURL = `/workspaces/${slug}/goals/`;
    let goalsParams = {
      title: query,
      limit: 8
    };
    this.setState({ isFetching: true });
    request.post(goalsApiURL, goalsParams).then(response => {
      const goals = response.data;
      this.setState({
        goals,
        isFetching: false
      });
    });
  };

  render() {
    const { isFetching, goals } = this.state;
    if (!isFetching && goals.length === 0) {
      return (
        <Container>
          <b>Goals</b>
          <div className="no-results">
            <Link to={`/goals`} onClick={this.props.hideForm}>
              View all goals
            </Link>
          </div>
        </Container>
      );
    }
    return (
      <Container>
        <b>Goals</b> {isFetching && <Loader size="small" />}
        {!isFetching && (
          <ul>
            {goals.map(goal => (
              <li key={goal.id}>
                <Link to={`/pages/${goal.project_id}/${goal.id}`} className="flex-start" onClick={this.props.hideForm}>
                  <IconGoal />
                  <div>
                    <span>{goal.title}</span>
                    <br />
                    {goal.project && goal.project.path && <small className="light">{goal.project.path.label}</small>}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </Container>
    );
  }
}

export default GoalSearch;
