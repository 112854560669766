// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { goalsSelectors } from "state/ducks/goals";

// Components
import FormField from "v2/components/FormField";
import FormActions from "v2/components/FormActions";
import TaskList from "v2/components/TaskList";

import Select from "v2/components/Select";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";

const Container = styled.div`
  .small {
    width: 50%;
  }

  em {
    font-weight: 600;
  }
`;

const GroupLabel = styled.div``;

type Props = {
  project: squadTypes.Project,
  roadmapState: ?string,
  hideForm: Function,
  sections: squadTypes.NormalizedList<squadTypes.Section>,
  goals: squadTypes.NormalizedList<squadTypes.Goal>
};

type State = {
  selectedGoal: ?Object,
  goalTask: ?squadTypes.Goal,
  goalOptions: Array<Object>
};

class GoalForm extends Component<Props, State> {
  state = {
    goalOptions: [],
    goalTask: null,
    selectedGoal: {
      value: "",
      label: "Select a goal to add tasks to"
    }
  };

  componentDidMount() {
    const { sections, goals } = this.props;
    const groupedOptions = sections.allIds.map(sectionId => {
      const section = sections.byId[sectionId];

      if (section) {
        const sectionGoals = goalsSelectors.getListByParentId(goals.byId, "section", sectionId);
        const options = sectionGoals.map(goal => {
          return {
            id: goal.id,
            label: goal.title
          };
        });
        return {
          label: section.title,
          options
        };
      } else {
        return null;
      }
    });

    this.setState({
      goalOptions: groupedOptions
    });
  }

  handleGoalSelect = (option: any, actionMeta: any) => {
    if (option) {
      const goalTask = this.props.goals.byId[option.id];
      this.setState({ selectedGoal: option, goalTask });
    } else {
      this.setState({ selectedGoal: null });
    }
  };

  handleEscPress = e => {
    if (e.keyCode === 27) {
      this.props.hideForm(e);
    }
  };

  _formatProjectOptionLabel = (props, params) => {
    if (params.context === "menu") {
      return (
        <div>
          <div className="option-label">{props.label}</div>
          <div className="option-path">Pages / {props.path.label}</div>
        </div>
      );
    } else {
      return <div className="">{props.label}</div>;
    }
  };

  _formatGroupLabel = data => (
    <GroupLabel>
      <span>{data.label}</span>
      <span>{data.options.length}</span>
    </GroupLabel>
  );

  render() {
    const { hideForm } = this.props;
    const { goalOptions, goalTask } = this.state;
    return (
      <Container className="modal-wrapper">
        <div className="modal-header">
          <h2>Add tasks</h2>
          <ButtonIcon onClick={this.props.hideForm}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <FormField>
            <label className="label">Select a goal</label>
            <Select
              options={goalOptions}
              formatGroupLabel={this._formatGroupLabel}
              classNamePrefix="react-select"
              onChange={this.handleGoalSelect}
              className={`small`}
            />
          </FormField>
          {goalTask && (
            <div className="tasks">
              <h4>Tasks</h4>
              <TaskList goal={goalTask} hideTitle={true} displayTip={true} roadmapState={this.props.roadmapState} />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <FormActions>
            <button onClick={hideForm} type="submit" className="secondary">
              Close
            </button>
          </FormActions>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  ui: state.ui.moveGoalForm,
  goals: state.goals,
  sections: state.sections
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GoalForm);
