//@flow
import * as types from "./types";
import { fetchSectionList } from "./actions";
import { fetchGoalList } from "state/ducks/goals/actions";

export const fetchSectionListThenGoals = (projectId: string, params?: Object) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(fetchSectionList(projectId, params)).then((action, state) => {
    if (action.type === types.FETCH_LIST_COMPLETED) {
      const state = getState();
      const { sections } = state;
      if (sections.allIds) {
        for (let i in sections.allIds) {
          const sectionId = sections.allIds[i];
          dispatch(fetchGoalList(sectionId));
        }
      }
    }
  });
};

export * from "./actions";
