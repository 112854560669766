// flow
import styled from "styled-components";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

export default styled.div`
  display: flex;
  align-items: center;
  .tgl {
    display: none;
    
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .tgl-btn {
      box-sizing: border-box;
      &::selection {
        background: none;
      }
    }
    
    + .tgl-btn {
      outline: 0;
      display: block;
      margin: 0 ${spacing.x1} 0 0;
      width: 4rem;
      height: 2.2rem;
      position: relative;
      cursor: pointer;
      user-select: none;
      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }
      
      &:after {
        left: 0;
      }
      
      &:before {
        display: none;
      }

    }

    &:checked + .tgl-btn {
      background-color: ${colors.blue};
    }
    
    &:checked + .tgl-btn:after {
      left: 50%; 
    }
  }

  // themes
  .tgl-light {
    + .tgl-btn {
      background: ${colors.grey};
      border-radius: 2rem;
      padding: 2px;
      transition: all .4s ease;
      &:after {
        border-radius: 50%;
        background: #fff;
        transition: all .2s ease;
        
      }
    }
    
      
    }
  }
`;
