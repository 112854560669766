//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.8rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 22 22" {...props}>
    <title id="Goal-Goal-title">Goals</title>
    <g
      id="Goal-Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Goal-target" transform="translate(1.000000, 1.000000)" stroke="#6A6A6A" strokeWidth="2">
        <circle id="Goal-Oval" cx="10" cy="10" r="10" />
        <circle id="Goal-Oval2" cx="10" cy="10" r="6" />
        <circle id="Goal-Oval3" cx="10" cy="10" r="2" />
      </g>
    </g>
  </Logo>
);
