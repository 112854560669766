//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as squadTypes from "squadTypes";
import moment from "moment";
import { goalsOperations } from "state/ducks/goals";
import { uiOperations } from "state/ducks/ui";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { parseTargetWithFormat } from "v2/utils/forms";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

// Components
import Avatar from "v2/components/Avatar";
import ButtonIcon from "v2/components/ButtonIcon";
import StatusBox from "v2/components/StatusBox";

import DropdownMenu from "v2/components/DropdownMenu";
import IconMenu from "v2/components/svg/IconMenu";
import IconStatus from "v2/components/svg/IconStatus";
import IconChevron from "v2/components/svg/IconChevron";
import IconNotify from "v2/components/svg/IconNotify";

import IconList from "v2/components/svg/IconList";
import ReactTooltip from "react-tooltip";

const GoalWarning = styled.div`
  a:hover {
    filter: none;
  }
  .tooltip {
    text-align: center;
  }
`;

const GoalGridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 5rem 18rem 18rem 10rem 18rem 15rem;
  grid-template-rows: 1fr;
  grid-template-areas: "tgoal tdependency tproject tprogress towner tdeadline tactions";
  padding: ${spacing.x1} ${spacing.x2};
  background: #fff;

  border: 1px solid ${colors.blockBorder};
  margin-top: ${spacing.x1};
  border-radius: 8px;

  &:hover {
    background: #f3f3f3;
  }

  .tgoal {
    grid-area: tgoal;
    display: flex;

    align-items: center;

    > div {
      display: flex;
    }

    a {
      color: ${colors.black};
    }
  }
  .tdependency {
    display: flex;

    align-items: center;
    grid-area: tdependency;
  }
  .tproject {
    display: flex;

    align-items: center;
    grid-area: tproject;
  }
  .tprogress {
    grid-area: tprogress;
    display: flex;

    align-items: center;
  }
  .towner {
    display: flex;

    align-items: center;
    grid-area: towner;
  }
  .tdeadline {
    display: flex;

    align-items: center;
    grid-area: tdeadline;
  }
  .tactions {
    grid-area: tactions;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .icon {
      margin-right: ${spacing.x1};
    }
  }
  .theader {
    color: ${colors.subtleText};
  }
`;

const NavOrStatus = styled.div`
  width: 1rem;
  text-align: right;
  margin-left: ${spacing.x1};
`;

const Progress = styled.div`
  width: 100%;
  height: ${spacing.x1};
  margin-right: ${spacing.x2};
  background-color: ${colors.progressBarBg};
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  height: ${spacing.x1};
  width: ${props => props.prct}%;
  border-radius: 4px;
  background-color: ${colors.progressBar};
  overflow: hidden;
`;

type Props = {
  currentGoal: squadTypes.Goal,
  deleteGoal: Function,
  goal: squadTypes.Goal,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  showModal: Function,
  updateGoal: Function
};

class GoalLine extends Component<Props> {
  // This function pops up the status update form.
  showStatusUpdateNewForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "statusUpdate",
      contentAction: "new",
      parent: this.props.goal
    });
  };

  // This function pops up the dependencies form
  showDependenciesForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "goal",
      contentAction: "dependencies",
      object: this.props.goal
    });
  };

  updateGoal = (e: Object) => {
    e.preventDefault();
    const { updateGoal, goal } = this.props;

    let params = {};

    if (e.target.name === "targetWithFormat") {
      const targetWithFormat = e.target.value;
      const { target, score_format } = parseTargetWithFormat(targetWithFormat);

      // We make sure that the target has a numerical value if it was provided.
      if (targetWithFormat && target) {
        params = {
          target,
          score_format
        };
      }
    } else {
      params[e.target.name] = e.target.value;
    }

    return updateGoal(goal.id, params);
  };

  toggleCloseGoal = e => {
    e.preventDefault();
    const { goal, updateGoal } = this.props;
    if (goal) {
      const state = goal.state === "open" ? "closed" : "open";
      const params = { state };
      updateGoal(goal.id, params);
    }
  };

  showGoalEditForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "goal",
      contentAction: "edit",
      object: this.props.goal
    });
  };

  showMoveGoalForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "goal",
      contentAction: "move",
      object: this.props.goal
    });
  };

  closeGoal = (e: Object) => {
    const { goal, updateGoal } = this.props;
    e.preventDefault();

    const params = {
      state: "closed"
    };

    updateGoal(goal.id, params);
  };

  openGoal = (e: Object) => {
    const { goal, updateGoal } = this.props;
    e.preventDefault();

    const params = {
      state: "open"
    };

    updateGoal(goal.id, params);
  };

  deleteGoal = e => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to delete this goal?`)) {
      const id = e.currentTarget.dataset.id;
      this.props.deleteGoal(id);
    }
  };

  getMenuItems = () => {
    const { goal } = this.props;
    const items = [];
    if (goal.state === "open") {
      items.push(
        <button className="link-button" onClick={this.closeGoal}>
          Close goal
        </button>
      );
    }
    if (goal.state === "closed") {
      items.push(
        <button className="link-button" onClick={this.openGoal}>
          Re-open goal
        </button>
      );
    }
    items.push(
      <button className="link-button" onClick={this.showGoalEditForm}>
        Edit
      </button>
    );
    items.push(
      <button className="link-button" onClick={this.showMoveGoalForm}>
        Move
      </button>
    );
    items.push(
      <button className="link-button" data-id={goal.id} onClick={this.deleteGoal}>
        Delete
      </button>
    );

    return items;
  };

  render() {
    const { currentGoal, goal } = this.props;
    const { owner } = goal;
    const project = goal.project;

    const basePath = `${window.location.pathname}`;
    // This gets executed if displayGoalEditForm === false
    let progressScore = goal.cached_score;
    let prctProgress = 0;

    // Set the progress score using the format if provided.
    if (goal.score_format && goal.target) {
      if (goal.cached_score || goal.cached_score === 0) {
        progressScore = parseFloat(goal.cached_score).toLocaleString("en-US");
        prctProgress = goal.prct_target_normalized * 100;
      } else {
        progressScore = "?";
      }
    }

    return (
      <GoalGridRow>
        <div className={`tgoal`}>
          <div>
            {goal.cached_previous_color && <StatusBox className={`${goal.cached_previous_color} previous`} />}
            {!goal.cached_previous_color && <StatusBox className={`transparent previous`} />}
            <StatusBox className={`${goal.cached_color} ${goal.state}`} />
          </div>
          <Link className="title" to={`${basePath}?selected=${goal.id}`}>
            {goal.section && <span className="subtle">{goal.section.title} / </span>}
            {goal.title}
          </Link>
        </div>
        <div className="tdependency">
          {(goal.count_of_children_goals > 0 || goal.parent_goal_id) && (
            <button className="link-button" onClick={this.showDependenciesForm}>
              <IconList />
              {goal.count_of_children_goals > 0 && <span>{goal.count_of_children_goals}</span>}
            </button>
          )}
        </div>
        <div className="tproject">{project && <Link to={`/pages/${project.id}`}>{project.title}</Link>}</div>
        <div className="tprogress">
          {goal.state === "closed" && (
            <div data-role="progress">
              <span>Closed</span>
            </div>
          )}
          {(progressScore || progressScore === 0) && (goal.initial_value || goal.initial_value === 0) && (
            <Progress>
              <ProgressBar prct={Math.round(prctProgress)} />
            </Progress>
          )}
        </div>
        <div className="towner">
          <Avatar user={owner} to={`/team/${goal.membership_id}`} hideName={true} size={24} />
        </div>
        <div className="tdeadline">
          {!currentGoal && goal.state === "closed" && <span>-</span>}
          {goal.deadline && moment().isBefore(moment(goal.start_date)) && (
            <span>
              Starts in{" "}
              {moment(goal.start_date)
                .fromNow(true)
                .replace("a ", "1 ")}
            </span>
          )}
          {goal.deadline && moment().isAfter(moment(goal.start_date)) && moment(goal.deadline).isAfter(moment()) && (
            <span>
              {moment(goal.deadline)
                .fromNow(true)
                .replace("a ", "1 ")}{" "}
              left
            </span>
          )}
          {goal.deadline && moment().isAfter(moment(goal.start_date)) && moment(goal.deadline).isBefore(moment()) && (
            <span className={goal.state === "open" ? "danger" : ""}>Due {moment(goal.deadline).fromNow()}</span>
          )}
          {!goal.deadline && "-"}
        </div>
        <div className="tactions">
          {goal.state !== "open" && <div />}
          {goal.state === "open" && (
            <div className="icon">
              <ButtonIcon onClick={this.showStatusUpdateNewForm} data-tip data-for={`add-status-${goal.id}`}>
                <IconStatus />
              </ButtonIcon>
              <ReactTooltip
                id={`add-status-${goal.id}`}
                place="bottom"
                type="dark"
                effect="solid"
                className="tooltip"
                delayShow={500}
              >
                Add a new update
              </ReactTooltip>
            </div>
          )}
          {goal.state !== "open" && <div className="icon" />}
          <div className="icon">
            <DropdownMenu
              trigger={
                <ButtonIcon>
                  <IconMenu />
                </ButtonIcon>
              }
              items={this.getMenuItems()}
            />
          </div>
          <NavOrStatus>
            {goal.is_pending_update && !goal.is_overdue && goal.state === "open" && (
              <GoalWarning role="img" aria-label="warning">
                <button className="link-button" onClick={this.showStatusUpdateNewForm}>
                  <IconNotify data-tip data-for={`warning-${goal.id}`} />
                </button>
                <ReactTooltip id={`warning-${goal.id}`} place="bottom" type="dark" effect="solid" className="tooltip">
                  This goal is due for an update.
                  {goal.cached_status_update_date && (
                    <span>
                      <br />
                      The last update was {moment(goal.cached_status_update_date).fromNow()}.
                    </span>
                  )}
                </ReactTooltip>
              </GoalWarning>
            )}
            {goal.is_overdue && goal.state === "open" && (
              <GoalWarning role="img" aria-label="warning">
                <Link to={`/pages/${goal.project_id}/${goal.id}`}>
                  <IconNotify data-tip data-for={`warning-${goal.id}`} color={colors.black} />
                </Link>
                <ReactTooltip id={`warning-${goal.id}`} place="bottom" type="dark" effect="solid" className="tooltip">
                  This goal is overdue.
                  {goal.deadline && (
                    <span>
                      <br />
                      The deadline was {moment(goal.deadline).fromNow()}.
                    </span>
                  )}
                </ReactTooltip>
              </GoalWarning>
            )}
            {((!goal.is_pending_update && !goal.is_overdue) || goal.state !== "open") && (
              <Link className="title" to={`${basePath}?selected=${goal.id}`}>
                <IconChevron />
              </Link>
            )}
          </NavOrStatus>
        </div>
      </GoalGridRow>
    );
  }
}

const mapStateToProps = (state, props) => ({
  memberships: state.memberships,
  projects: state.projects
});

const mapDispatchToProps = {
  updateGoal: goalsOperations.updateGoal,
  deleteGoal: goalsOperations.deleteGoal,
  selectGoal: goalsOperations.selectGoal,
  showModal: uiOperations.showModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoalLine));
