// @flow
import { createReducer } from "state/utils";
import * as sectionsTypes from "state/ducks/sections/types";
import * as goalsTypes from "state/ducks/goals/types";
import * as projectsTypes from "state/ducks/projects/types";

const INITIAL_STATE = {
  isFetchingSection: true,
  isFetchingGoals: true,
  isFetchingProject: true,
  AccessIsForbidden: {},
  PlanRestrictionTriggers: []
};

export default createReducer(INITIAL_STATE)({
  [sectionsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingSection: false
  }),
  [sectionsTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingSection: false
  }),
  [sectionsTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingSection: true
  }),
  [goalsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingGoals: false
  }),
  [goalsTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingGoals: false
  }),
  [goalsTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingGoals: true
  }),
  [projectsTypes.FETCH_DETAILS_FAILED]: (state, action) => {
    let AccessIsForbidden = state.AccessIsForbidden;
    let PlanRestrictionTriggers = [];
    try {
      if (action.error.response.status === 401) {
        AccessIsForbidden[`${action.projectId}`] = true;
        PlanRestrictionTriggers = action.error.response.data.plan_restriction_triggers;
      } else {
        AccessIsForbidden[`${action.projectId}`] = false;
      }
    } catch (e) {
      // Do something
    }
    return {
      ...state,
      AccessIsForbidden,
      PlanRestrictionTriggers,
      isFetchingProject: false
    };
  },
  [projectsTypes.FETCH_DETAILS_COMPLETED]: state => ({
    ...state,
    isFetchingProject: false
  }),
  [projectsTypes.FETCH_DETAILS_REQUESTED]: state => ({
    ...state,
    isFetchingProject: true
  })
});
