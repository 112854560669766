//@flow
export const CLEAR_GOOGLE_AUTH = "googleLogin/CLEAR";
export const CLEAR_PAGE = "page/CLEAR";
export const CLEAR_SESSION_CONFIRM = "sessionConfirm/CLEAR";
export const CLEAR_SESSION_LOGIN = "sessionLogin/CLEAR";
export const CLEAR_SESSION_NEW_PASSWORD = "sessionNewPassword/CLEAR";
export const CLEAR_SESSION_RESET_PASSWORD = "sessionResetPassword/CLEAR";
export const CLEAR_SESSION_SIGNUP = "sessionSignup/CLEAR";

export const SHOW_MODAL = "modal/SHOW";
export const HIDE_MODAL = "modal/HIDE";
export const CLEAR_MODAL = "modal/CLEAR";

export const SET_ERROR_FIELDS = "errorFields/SET";
export const TOGGLE_PROJECT_NAV = "expandedProjects/TOGGLE_PROJECT_NAV";
export const EXPAND_PROJECT_NAV = "expandedProjects/EXPAND_PROJECT_NAV";
export const TOGGLE_ARCHIVED = "projectList/TOGGLE_ARCHIVED";
export const SELECT_CHILD_PROJECT = "projectList/SELECT_CHILD";
