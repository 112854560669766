// @flow
import { createReducer } from "state/utils";
import * as accountTypes from "state/ducks/account/types";
import * as sessionTypes from "state/ducks/session/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  errorFields: {},
  formState: "pending",
  avatarState: "pending",
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [accountTypes.UPDATE_ACCOUNT_PASSWORD_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [accountTypes.UPDATE_ACCOUNT_PASSWORD_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "Your password has been updated.",
      messageType: "success"
    };
  },
  [accountTypes.UPDATE_ACCOUNT_PASSWORD_FAILED]: (state, action) => {
    const errors = action.error.response.data.errors;
    const errorFields = {};
    if (errors["current_password"]) {
      errorFields["current_password"] = `Your current password ${errors["current_password"][0]}`;
    }
    if (errors["password"]) {
      errorFields["password"] = `Your password ${errors["password"][0]}`;
    }
    if (errors["password_confirmation"]) {
      errorFields["password_confirmation"] = `Your password confirmation ${errors["password_confirmation"][0]}`;
    }
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not update your password",
      messageType: "warning"
    };
  },
  [sessionTypes.PASSWORD_RESET_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [sessionTypes.PASSWORD_RESET_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "An email has been set to reset your password.",
      messageType: "success"
    };
  },
  [sessionTypes.PASSWORD_RESET_FAILED]: (state, action) => {
    const errors = action.error.response.data.errors;
    const errorFields = {};
    if (errors["email"]) {
      errorFields["email"] = `Your email ${errors["email"][0]}`;
    }
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not reset your password",
      messageType: "warning"
    };
  },
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
