// @flow
import { createReducer } from "state/utils";
import * as reducerUtils from "state/utils/reducers";
import * as types from "./types";
import * as goalsTypes from "state/ducks/goals/types";

import { INITIAL_LIST_STATE } from "config/constants";

// This is going to be a paginated list
const INITIAL_STATE = {
  ...INITIAL_LIST_STATE,
  isPaginated: true,
  stats: {}
};

const normalizedStateReducer = createReducer(INITIAL_STATE)({
  // Add single object to the reducer
  [goalsTypes.UPDATE_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  [goalsTypes.DELETE_COMPLETED]: (state, action) => reducerUtils.removeObject(state, action),
  [types.FETCH_DETAILS_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  [types.SEARCH_COMPLETED]: (state, action) => reducerUtils.paginateList(state, action),
  [types.STATS_COMPLETED]: (state, action) => ({
    ...state,
    stats: action.response.data
  }),
  [types.RESET_LIST]: (state, action) => INITIAL_STATE,
  [types.SELECT]: (state, action) => reducerUtils.selectObject(state, action),
  [types.UNSELECT]: (state, action) => ({
    ...state,
    selectedId: null
  })
});

export default normalizedStateReducer;
