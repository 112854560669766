//@flow
import jwtDecode from "jwt-decode";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import { tabilityAuthUrl } from "config/services";
import * as types from "./types";
import * as squadTypes from "squadTypes";

// ############# SESSION INIT ################
// The 2 actions below are used to send events when loading the app
export const initializeSessionStarted = () => ({
  type: types.INITIALIZE_STARTED,
  subdomain: window.location.host.split(".")[0]
});

export const initializeSessionCompleted = () => ({
  type: types.INITIALIZE_COMPLETED
});

// This function is used to know if the current workspace browsed exists
// No need to pass a param because it is extracted from the URL and set
// into the request helper
export const fetchCurrentWorkspace = () => ({
  types: [
    types.FETCH_CURRENT_WORKSPACE_REQUESTED,
    types.FETCH_CURRENT_WORKSPACE_COMPLETED,
    types.FETCH_CURRENT_WORKSPACE_FAILED
  ],
  callAPI: () => {
    const subdomain = getSubdomain();
    if (!subdomain) {
      return Promise.reject(new Error("Couldn't find a subdomain"));
    }
    return request.get(`/workspaces/${subdomain}`);
  }
});

export const checkCurrentWorkspace = () => ({
  types: [
    types.CHECK_CURRENT_WORKSPACE_REQUESTED,
    types.CHECK_CURRENT_WORKSPACE_COMPLETED,
    types.CHECK_CURRENT_WORKSPACE_FAILED
  ],
  callAPI: () => {
    const subdomain = getSubdomain();
    if (!subdomain) {
      return Promise.reject(new Error("Couldn't find a subdomain"));
    }
    return request.get(`/workspaces/${subdomain}/check`);
  }
});

//##################### LOGIN / LOGOUT #############################

export const checkCredentials = (email: string) => ({
  types: [types.CHECK_CREDENTIALS_REQUESTED, types.CHECK_CREDENTIALS_COMPLETED, types.CHECK_CREDENTIALS_FAILED],
  callAPI: () => {
    const body = {
      user: {
        email
      }
    };
    return request.post(`${tabilityAuthUrl}/users/check_credentials`, body, {
      ignoreAuthHeaders: true
    });
  }
});

export const login = (credentials: squadTypes.Credentials) => ({
  types: [types.LOGIN_REQUESTED, types.LOGIN_COMPLETED, types.LOGIN_FAILED],
  callAPI: () => {
    const body = {
      user: credentials
    };
    return request.post(`${tabilityAuthUrl}/users/authenticate`, body, {
      ignoreAuthHeaders: true
    });
  }
});

export const magicLogin = (magic_token: string) => ({
  types: [types.MAGIC_LOGIN_REQUESTED, types.MAGIC_LOGIN_COMPLETED, types.MAGIC_LOGIN_FAILED],
  callAPI: () => {
    const body = {
      magic_token
    };
    return request.post(`${tabilityAuthUrl}/users/magic_token`, body, {
      ignoreAuthHeaders: true
    });
  }
});

export const googleAuth = (body: Object) => ({
  types: [types.GOOGLE_AUTH_REQUESTED, types.GOOGLE_AUTH_COMPLETED, types.GOOGLE_AUTH_FAILED],
  callAPI: () => {
    return request.post(`${tabilityAuthUrl}/services/google/authenticate`, body, {
      ignoreAuthHeaders: true
    });
  }
});

export const slackAuth = (body: Object) => ({
  types: [types.SLACK_AUTH_REQUESTED, types.SLACK_AUTH_COMPLETED, types.SLACK_AUTH_FAILED],
  callAPI: () => {
    return request.post(`${tabilityAuthUrl}/services/slack/authenticate`, body, {
      ignoreAuthHeaders: true
    });
  }
});

export const logout = () => {
  return {
    type: types.LOGOUT
  };
};

export const setRedirectAfterPath = (path: string) => ({
  type: types.SET_REDIRECT_AFTER_PATH,
  redirectAfterPath: path
});

export const setSAMLTokens = (tokens: squadTypes.Tokens) => ({
  type: types.SET_SAML_TOKENS,
  tokens,
  workspaceSlug: window.location.host.split(".")[0]
});

//##################### SIGNUP #############################

export const signup = (credentials: squadTypes.Credentials) => ({
  types: [types.SIGNUP_REQUESTED, types.SIGNUP_COMPLETED, types.SIGNUP_FAILED],
  callAPI: () => {
    // Get the current subdomain and passes it to the request
    const subdomain = window.location.host.split(".")[0];
    const body = {
      user: credentials,
      from: subdomain
    };
    return request.post(`${tabilityAuthUrl}/users`, body, {
      ignoreAuthHeaders: true
    });
  }
});

// ############# REFRESHING TOKENS ################
// This functions is used to refresh the access tokens. It is used
// in state/utils/request to fetch new access_tokens when the existing
// one has expired.
export const refreshAccessTokens = (tokens: squadTypes.Tokens) => ({
  types: [types.REFRESH_TOKENS_REQUESTED, types.REFRESH_TOKENS_COMPLETED, types.REFRESH_TOKENS_FAILED],
  callAPI: () => {
    const { access_token, refresh_token } = tokens;

    // Abort if we're missing access or refresh token
    if (!access_token) {
      const failedPromise: any = Promise.reject("Access token is missing");
      return failedPromise;
    }
    if (!refresh_token) {
      const failedPromise: any = Promise.reject("Refresh token is missing");
      return failedPromise;
    }

    // We get the current_user params from the token itself.
    const { auth_type, user_id, iss_workspace_slug } = jwtDecode(access_token);

    const body = {
      auth_type,
      user_id,
      iss_workspace_slug,
      refresh_token: refresh_token
    };

    return request.post(`${tabilityAuthUrl}/users/refresh_token`, body, {
      ignoreAuthHeaders: true
    });
  }
});

//##################### EMAIL CONFIRMATION #############################

export const resendConfirmationEmail = (credentials: squadTypes.Credentials) => ({
  types: [types.RESEND_CONFIRMATION_REQUESTED, types.RESEND_CONFIRMATION_COMPLETED, types.RESEND_CONFIRMATION_FAILED],
  callAPI: () => {
    if (!tabilityAuthUrl) {
      return false;
    }
    const body = {
      user: credentials
    };
    return request.post(`${tabilityAuthUrl}/users/confirmation`, body, {
      ignoreAuthHeaders: true
    });
  }
});

export const confirmEmail = (token: string) => ({
  types: [types.EMAIL_CONFIRMATION_REQUESTED, types.EMAIL_CONFIRMATION_COMPLETED, types.EMAIL_CONFIRMATION_FAILED],
  callAPI: () => {
    if (!tabilityAuthUrl) {
      return false;
    }
    return request.get(`${tabilityAuthUrl}/users/confirmation?confirmation_token=${token}`, {
      ignoreAuthHeaders: true
    });
  }
});

//######################## RESET PASSWORD #############################

export const resetPassword = (params: { email: string }) => ({
  types: [types.PASSWORD_RESET_REQUESTED, types.PASSWORD_RESET_COMPLETED, types.PASSWORD_RESET_FAILED],
  callAPI: () => {
    const { email } = params;
    const body = {
      user: {
        email
      }
    };
    return request.post(`${tabilityAuthUrl}/users/password`, body);
  }
});

export const saveNewPassword = (params: {
  password: string,
  password_confirmation: string,
  reset_password_token: string
}) => ({
  types: [types.PASSWORD_SAVE_NEW_REQUESTED, types.PASSWORD_SAVE_NEW_COMPLETED, types.PASSWORD_SAVE_NEW_FAILED],
  callAPI: () => {
    const { password, password_confirmation, reset_password_token } = params;
    const body = {
      user: {
        password,
        password_confirmation,
        reset_password_token
      }
    };
    return request.put(`${tabilityAuthUrl}/users/password`, body);
  }
});

//######################## STORE PAGE VIST #############################

export const logVisit = (params: { pageTitle: string, pageType: string, pageUrl: string }) => ({
  type: types.LOG_VISIT,
  page: params,
  subdomain: getSubdomain()
});

export const visitWhatsNew = () => ({
  type: types.WHATSNEW_VISITED
});

export const toggleDiscoveryFlag = () => ({
  type: types.TOGGLE_FLAG
});

//######################## UNSUBSCRIBE #############################

export const unsubscribe = (unsubscribe_token: string, unsubscribe_setting: string) => ({
  types: [types.UNSUBSCRIBE_REQUESTED, types.UNSUBSCRIBE_COMPLETED, types.UNSUBSCRIBE_FAILED],
  callAPI: () => {
    const params = {
      unsubscribe_setting
    };
    return request.delete(`/memberships/${unsubscribe_token}/notifications`, {
      ignoreAuthHeaders: true,
      params
    });
  }
});
