// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";

import styled from "styled-components";
import { spacing } from "v2/components/styles/spacing";
import { devices } from "v2/components/styles/devices";
import { webmailDomains } from "v2/utils/emailDomains";
import * as namingUtils from "v2/utils/naming";
import { handleChange } from "v2/utils/forms";

// Ducks stuff
import { workspacesOperations, workspacesSelectors } from "state/ducks/workspaces";
import * as workspaceTypes from "state/ducks/workspaces/types";
import { uiOperations } from "state/ducks/ui";
import { sessionOperations } from "state/ducks/session";

// Components
import FormField from "v2/components/FormField";
import InlineMessage from "v2/components/InlineMessage";
import { Link } from "react-router-dom";
import Loader from "v2/components/Loader";
import Wordmark from "v2/components/_assets/tability_logo_full.png";

const Container = styled.main`
  display: flex;
  height: 100%;
  > div {
    display: flex;
    flex: 1;
    align-items: stretch;
  }
  .left-panel {
    display: flex;
    justify-content: flex-end;
    padding-right: ${spacing.x10};

    @media ${devices.tablet} {
      display: none;
    }
  }
  .right-panel {
    padding-left: ${spacing.x10};
    @media ${devices.tablet} {
      padding: ${spacing.x4};
      justify-content: center;
    }
    .form-actions {
      text-align: right;
    }
  }

  padding: ${spacing.x4};
`;

const FormLine = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-right: ${spacing.x1};

    &.spacer {
      margin-left: ${spacing.x1};
    }
  }

  &.space-bottom {
    margin-bottom: ${spacing.x2};
  }
`;

const Grid = styled.div`
  max-width: 410px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "header"
    "main"
    "footer";
  .grid-header {
    grid-area: header;
    display: flex;
    align-items: flex-end;
    margin-bottom: 69px;

    img {
      height: 40px;
    }
  }
  .grid-main {
    grid-area: main;
    h1 {
      margin-bottom: ${spacing.x2};
    }
  }
  .grid-footer {
    grid-area: footer;
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
  }
`;

const StepBlock = styled.div`
  display: flex;
  padding: 35px 25px;

  color: #ababab;

  border-bottom: 1px solid #d1d1d1;

  &:last-child {
    border-bottom: 0;
  }

  &.selected {
    color: #151515;
  }

  > div {
    &:first-child {
      width: 60px;
      font-size: 2.4rem;
      font-weight: 500;
    }

    &:nth-child(2) {
      big {
        display: block;
        font-weight: 500;
        font-size: 2.4rem;
      }
      span {
        margin-top: 20px;
        display: block;
      }
    }
  }
`;

const FooterBlock = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  span {
    display: block;
    margin: 0 ${spacing.x1};
  }
  a,
  .link-button {
    color: #6a6a6a;

    &:hover {
      color: #6a6a6a;
      filter: brightness(90%);
    }
    text-decoration: underline;
    margin-right: ${spacing.x2};
  }
`;

type Props = {
  clearPage: Function,
  createWorkspace: Function,
  createdWorkspace: squadTypes.Workspace,
  currentUser: squadTypes.User,
  title?: string,
  ui: Object,
  logout: Function
};

type State = {
  accepted_domains: string,
  use_accepted_domain: boolean,
  name: string,
  slug: string,
  slugPreview: string
};

class WorkSpaceNew extends Component<Props, State> {
  state = {
    accepted_domains: "",
    use_accepted_domain: false,
    name: "",
    slug: "",
    slugPreview: ""
  };
  logout = (e: Object) => {
    e.preventDefault();
    this.props.logout();
  };

  componentDidMount() {
    this.props.clearPage();
  }

  createWorkspace = (e: Object) => {
    e.preventDefault();
    let { currentUser } = this.props;
    let { use_accepted_domain, name, slug } = this.state;

    let accepted_domains = [];
    if (use_accepted_domain) {
      const emailDomain = currentUser.email.split("@")[1];
      accepted_domains = [emailDomain];
    }
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = {
      accepted_domains,
      name,
      slug,
      time_zone
    };
    this.props.createWorkspace(params).then(action => {
      if (action.type === workspaceTypes.CREATE_COMPLETED) {
        window.location = `https://${slug}.${process.env.REACT_APP_DOMAIN || ""}/onboarding/notifications`;
      }
    });
  };

  handleNameChange = (e: any) => {
    let { slugPreview } = this.state;
    const name = e.target.value;
    const slug = namingUtils.generateSlug(name);
    slugPreview = slug;
    this.setState({
      name,
      slug,
      slugPreview
    });
  };

  handleSlugChange = (e: any) => {
    const slugPreview = e.target.value;
    const slug = namingUtils.generateSlug(slugPreview);
    this.setState({
      slug,
      slugPreview
    });
  };

  handleChange = e => handleChange(this, e);

  render() {
    const { currentUser, ui } = this.props;
    const emailDomain = currentUser.email.split("@")[1];

    const isPrivateEmail = !webmailDomains.includes(emailDomain);
    const { use_accepted_domain, name, slug } = this.state;

    return (
      <Container>
        <div className="left-panel">
          <Grid>
            <div className="grid-header">
              <Link to="/">
                <img src={Wordmark} alt="Tability" />
              </Link>
            </div>
            <div className="grid-main">
              <StepBlock>
                <div>1</div>
                <div>
                  <big>Set up your account</big>
                </div>
              </StepBlock>
              <StepBlock className="selected">
                <div>2</div>
                <div>
                  <big>Set up your workspace</big>
                  <span>Create a space for you and your team.</span>
                </div>
              </StepBlock>
              <StepBlock>
                <div>3</div>
                <div>
                  <big>Set up your workflow</big>
                </div>
              </StepBlock>
            </div>
            <div className="grid-footer">
              {currentUser && (
                <FooterBlock>
                  <button className="link-button" onClick={this.logout}>
                    Logout
                  </button>
                  <Link to="/account">Account settings</Link>
                </FooterBlock>
              )}
            </div>
          </Grid>
        </div>
        <div className="right-panel">
          <Grid>
            <div className="grid-main">
              <h1>Set up your workspace</h1>
              <InlineMessage message={ui.message} messageType={ui.messageType} />
              <form>
                <FormField>
                  <input
                    type="text"
                    onChange={this.handleNameChange}
                    placeholder="Enter your team or company name"
                    aria-label="Enter your team or company name"
                    name="name"
                    value={name}
                    autoFocus={true}
                    autoComplete="off"
                  />
                </FormField>
                <FormField>
                  <FormLine>
                    <span>https://</span>
                    <input
                      type="text"
                      className="spacer"
                      onChange={this.handleSlugChange}
                      placeholder="Your workspace URL"
                      aria-label="Enter your workspace URL"
                      name="slugPreview"
                      value={slug}
                      autoComplete="off"
                    />
                    <span>.{process.env.REACT_APP_DOMAIN}</span>
                  </FormLine>
                </FormField>
                {isPrivateEmail && (
                  <FormLine className="space-bottom">
                    <input
                      type="checkbox"
                      name="use_accepted_domain"
                      onChange={this.handleChange}
                      value={use_accepted_domain}
                      id="use_accepted_domain"
                    />
                    <label htmlFor="use_accepted_domain">Anyone with a @{emailDomain} email can join</label>
                  </FormLine>
                )}
                <div className="form-actions">
                  {!ui.isFetching && (
                    <button onClick={this.createWorkspace} type="submit" className="primary">
                      Next step →
                    </button>
                  )}
                  {ui.isFetching && (
                    <button disabled className="primary">
                      <Loader size="small" />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Grid>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  createdWorkspace: workspacesSelectors.getObjectById(state.workspaces.byId, state.workspaces.createdId),
  currentUser: state.session.currentUser,
  ui: state.ui.onboarding
});

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  createWorkspace: workspacesOperations.createWorkspace,
  logout: sessionOperations.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkSpaceNew);
