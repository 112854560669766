//@flow
import styled from "styled-components";
import { spacing } from "v2/components/styles/spacing";

export default styled.div`
  display: flex;
  .action {
    margin-right: ${spacing.x1};
    &:last-child {
      margin-right: 0;
    }
  }

  button {
    margin-right: ${spacing.x2};
  }
`;
