//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 16 4" {...props}>
    <title>Menu</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="More" transform="translate(1.000000, 1.000000)" fill="#6A6A6A" stroke="#6A6A6A" strokeWidth="2">
        <g id="Oval">
          <circle cx="6.66666667" cy="0.833333333" r="1" />
          <circle cx="12.5" cy="0.833333333" r="1" />
          <circle cx="0.833333333" cy="0.833333333" r="1" />
        </g>
      </g>
    </g>
  </Logo>
);
