// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";

import styled from "styled-components";
import { spacing } from "v2/components/styles/spacing";
import { devices } from "v2/components/styles/devices";

import { handleChange } from "v2/utils/forms";
import { accountOperations } from "state/ducks/account";
import { uiOperations } from "state/ducks/ui";

// Components
import EmptyLayout from "v2/components/EmptyLayout";
import Tabby from "./_assets/tabby.png";
import FormField from "v2/components/FormField";
import InlineMessage from "v2/components/InlineMessage";
import Loader from "v2/components/Loader";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

const Message = styled.div`
  display: flex;
  .profile_pic {
    img {
      width: 20rem;
    }
  }

  .profile_detail {
    margin: 0 ${spacing.x4};
  }

  p {
    font-size: 1.8rem;
    line-height: 1.3;
    font-weight: 400;
  }

  label {
    display: inline;
    font-weight: normal;
    margin-bottom: 0;
  }

  @media ${devices.mobile} {
    flex-direction: column;

    .profile_detail {
      margin: ${spacing.x2} 0;
    }
  }
`;

const Agreement = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  margin-right: ${spacing.x4};
  margin-top: ${spacing.x1};
`;

const CheckboxContainer = styled.div`
  border-top: 1px solid #d8d8d8;
  margin-top: ${spacing.x2};
  padding-top: ${spacing.x2};
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x1};
  font-size: 1.6rem;
  line-height: 1.4;
  input {
    margin-right: ${spacing.x1};
  }
  label {
    font-size: 1.4rem;
  }
`;

type Props = {
  clearPage: Function,
  currentUser: squadTypes.User,
  ui: Object,
  updateAccount: Function
};

type State = {
  privacy_email_product: boolean,
  scaling_small: boolean
};

class ProfileNew extends Component<Props, State> {
  state = {
    privacy_email_product: true,
    scaling_small: true
  };

  componentDidMount() {
    this.props.clearPage();
  }

  handleNext = e => {
    const { scaling_small } = this.state;

    const params = {
      scaling_small,
      privacy_policy_accepted: true
    };
    this.props.updateAccount(params);
  };

  handleChange = e => handleChange(this, e);

  render() {
    const { ui } = this.props;
    const { scaling_small } = this.state;
    return (
      <Container>
        <EmptyLayout title="A quick word before getting started!">
          <Message>
            <div className="profile_pic">
              <img src={Tabby} alt="Tabby says hi!" />
            </div>
            <div className="profile_detail">
              <p>Tools are not everything — we'll be the first to acknowledge that.</p>
              <p>
                That's why we also have a{" "}
                <a href="https://tability.io/odt" target="_blank" rel="noopener noreferrer">
                  bi-weekly newsletter
                </a>{" "}
                with best practices around OKRS and goal-setting from industry experts.
              </p>
              <p>That's our way to be useful beyond the product, but we also want to make it easy to opt-out below.</p>
              <FormField>
                <CheckboxContainer>
                  <input
                    checked={scaling_small}
                    onChange={this.handleChange}
                    name="scaling_small"
                    component="input"
                    type="checkbox"
                    id="scaling_small"
                  />{" "}
                  <label htmlFor="scaling_small">
                    Subscribe to our newsletter about&nbsp;<em>Outcome Driven Teams</em>.
                  </label>
                </CheckboxContainer>
              </FormField>
              <InlineMessage message={ui.message} messageType={ui.messageType} />
              {!ui.isFetching && (
                <button onClick={this.handleNext} type="submit" className="primary">
                  Continue
                </button>
              )}
              {ui.isFetching && (
                <button disabled className="primary">
                  <Loader size="small" />
                </button>
              )}
              <Agreement>
                By continuing, you agree to the{" "}
                <a href="https://docs.tability.io/privacy-policy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="https://docs.tability.io/terms-of-use" target="_blank" rel="noopener noreferrer">
                  Terms of use
                </a>
                .
              </Agreement>
            </div>
          </Message>
        </EmptyLayout>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  uploadedAsset: state.account.uploadedAsset,
  currentUser: state.session.currentUser,
  ui: state.ui.onboarding
});

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  updateAccount: accountOperations.updateAccount,
  uploadAsset: accountOperations.uploadAssetDance
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNew);
