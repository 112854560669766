//@flow
import { request } from "state/utils";
import * as types from "./types";
import { getSubdomain } from "state/utils/url";

const slug = getSubdomain() || "";

export const fetchTagList = (params?: Object) => {
  const filters = [];
  if (params) {
    if (params.page) {
      filters.push(`page=${params.page}`);
    }
  }
  const filtersQuery = filters.join("&");
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/workspaces/${slug}/tags?${filtersQuery}`)
  };
};

export const resetTagList = () => ({
  type: types.RESET_LIST
});
