// @flow
import { createReducer } from "state/utils";
import * as types from "./types";
import * as projectsTypes from "state/ducks/projects/types";
import * as sectionsTypes from "state/ducks/sections/types";

const INITIAL_STATE = {
  expandedProjects: {},
  selectedChildren: ["root"],
  isFetchingChildrenOfProject: {},
  isFetching: false, // Start with the assumption that we're fetching. That way we don't flash the empty state.
  showArchived: false
};

export default createReducer(INITIAL_STATE)({
  [projectsTypes.FETCH_LIST_FAILED]: (state, action) => {
    const { isFetchingChildrenOfProject } = state;
    if (action.parent) {
      isFetchingChildrenOfProject[action.parent] = false;
    }
    return {
      ...state,
      isFetching: false,
      isFetchingChildrenOfProject
    };
  },
  [projectsTypes.FETCH_LIST_COMPLETED]: (state, action) => {
    const { isFetchingChildrenOfProject } = state;
    if (action.parent) {
      isFetchingChildrenOfProject[action.parent] = false;
    }
    return {
      ...state,
      isFetching: false,
      isFetchingChildrenOfProject
    };
  },
  [projectsTypes.FETCH_LIST_REQUESTED]: (state, action) => {
    const { isFetchingChildrenOfProject } = state;
    if (action.parent) {
      isFetchingChildrenOfProject[action.parent] = true;
    }
    return {
      ...state,
      isFetching: true,
      isFetchingChildrenOfProject
    };
  },
  [sectionsTypes.FETCH_LIST_FAILED]: (state, action) => {
    const { isFetchingChildrenOfProject } = state;
    if (action.project_id) {
      isFetchingChildrenOfProject[action.project_id] = false;
    }
    return {
      ...state,
      isFetching: false,
      isFetchingChildrenOfProject
    };
  },
  [sectionsTypes.FETCH_LIST_COMPLETED]: (state, action) => {
    const { isFetchingChildrenOfProject } = state;
    if (action.project_id) {
      isFetchingChildrenOfProject[action.project_id] = false;
    }
    return {
      ...state,
      isFetching: false,
      isFetchingChildrenOfProject
    };
  },
  [sectionsTypes.FETCH_LIST_REQUESTED]: (state, action) => {
    const { isFetchingChildrenOfProject } = state;
    if (action.project_id) {
      isFetchingChildrenOfProject[action.project_id] = true;
    }
    return {
      ...state,
      isFetching: true,
      isFetchingChildrenOfProject
    };
  },
  [types.TOGGLE_PROJECT_NAV]: (state, action) => {
    const { expandedProjects } = state;
    const { projectId } = action;

    if (expandedProjects[projectId]) {
      expandedProjects[projectId] = false;
    } else {
      expandedProjects[projectId] = true;
    }

    return {
      ...state,
      expandedProjects
    };
  },
  [types.EXPAND_PROJECT_NAV]: (state, action) => {
    const { expandedProjects } = state;
    const { projectId } = action;

    expandedProjects[projectId] = true;

    return {
      ...state,
      expandedProjects
    };
  },
  [types.SELECT_CHILD_PROJECT]: (state, action) => {
    const { selectedChildren } = state;

    let selectedChildrenCopy = [];
    const { childId, projectId } = action;

    // Iterate on the existing array to copy it
    for (let i = 0; i < selectedChildren.length; i++) {
      // Now, check if we reached the selected project
      if (selectedChildren[i] === projectId) {
        // check that if we're selecting a different child
        if (selectedChildren[i + 1] && selectedChildren[i + 1] === childId) {
          return state;
        } else {
          // First, copy the child in the new array
          selectedChildrenCopy.push(selectedChildren[i]);
          break;
        }
      }
      // First, copy the child in the new array
      selectedChildrenCopy.push(selectedChildren[i]);
    }
    selectedChildrenCopy.push(childId);

    return {
      ...state,
      selectedChildren: selectedChildrenCopy
    };
  },
  [types.TOGGLE_ARCHIVED]: state => {
    const { showArchived } = state;

    return {
      ...state,
      showArchived: !showArchived
    };
  }
});
