// @flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import { tasksOperations } from "state/ducks/tasks";
import { goalsOperations, goalsSelectors } from "state/ducks/goals";
import { sectionsOperations } from "state/ducks/sections";
import { subscriptionsOperations } from "state/ducks/subscriptions";
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

import IconPlus from "v2/components/svg/IconPlus";

import * as constants from "config/constants";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// V2 Components
import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

// Components
import ProjectAccessForbidden from "v2/components/ProjectAccessForbidden";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";

import ProjectMenu from "v2/pages/projectDetails/ProjectMenu";
import ProjectMode from "v2/pages/projectDetails/ProjectMode";
import ProjectPermissions from "v2/pages/projectDetails/ProjectPermissions";
import ProjectPublic from "v2/pages/projectDetails/ProjectPublic";
import ProjectSubscription from "v2/pages/projectDetails/ProjectSubscription";
import ProjectTitle from "v2/pages/projectDetails/ProjectTitle";

// Components
import GoalTaskBlock from "v2/components/GoalTaskBlock";
import TaskItem from "v2/components/TaskItem";

const TasksGrid = styled.div`
  display: grid;
  position: absolute;
  top: 0;
  bottom: ${spacing.x4};
  right: 0;
  left: 0;
  column-gap: ${spacing.x3};
  row-gap: ${spacing.x3};
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "this-week next-week later";
`;

const TaskColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  flex: 1;
  padding: ${spacing.x3} 0;
  background: ${props => (props.isDraggingOver ? "#f3f3f3" : "#fff")};
  height: 100%;
  max-height: 100%;
  overflow: auto;

  border: 1px solid ${colors.blockBorder};

  h3 {
    margin: 0 ${spacing.x3} ${spacing.x3} ${spacing.x3};
  }

  &.task-next-week {
    grid-area: next-week;
  }
  &.task-this-week {
    grid-area: this-week;
  }
  &.task-later {
    grid-area: later;
  }
  &.task-separator {
    grid-column: 1 / span 3;
  }

  button.compact {
    height: 3.2rem;
    width: 3.2rem;
    padding: 0;
    margin: 0;
    svg {
      width: 1.4rem;
    }
  }
`;

const TaskWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 0 ${spacing.x3};
`;

const GoalTasks = styled.div`
  margin-bottom: ${spacing.x2};
  padding-bottom: ${spacing.x2};
  border-bottom: 1px solid ${colors.blockBorder};

  .task {
    margin-left: ${spacing.x3};
  }
`;

const Breadcrumbs = styled.ul`
  li {
    display: inline-block;
    font-weight: 400;
    font-size: 1.4rem;
  }

  li:last-child {
    a {
      font-weight: 600;
    }
  }

  li + li:before {
    content: "/";
    color: ${colors.subtleText};
    padding: 0 8px;
  }
`;

const ArchivedBanner = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #955be3;
  color: #fff;
  height: 4rem;
  line-height: 4rem;
  align-items: center;
  border-radius: 4px;
  padding: 0 1.4rem;
  font-weight: 500;
  margin-bottom: 2.2rem;

  a,
  .link-button {
    color: #fff;
    text-decoration: underline;
  }
`;

const Container = styled.div`
  padding: ${spacing.x4};
  padding-bottom: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.x4};

  @media ${devices.tablet} {
    margin-bottom: ${spacing.x2};
  }

  .project-goals-nav-tablet {
    z-index: 80;
    display: none;
    margin-right: ${spacing.x2};

    button {
      background: #e9e9e9;

      &:hover,
      &:focus {
        background: #d4d4d4;
      }
    }
  }

  &.with-goal {
    .project-goals-nav-tablet {
      @media ${devices.tablet} {
        display: flex;
      }
    }
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: ${spacing.x1};
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  @media ${devices.tablet} {
    display: none;
  }
`;

const Main = styled.div`
  flex: 1;
  position: relative;
`;

const EmptyColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: 70%;
    line-height: 1.6;
    text-align: center;
    margin-bottom: ${spacing.x3};
  }
  margin-bottom: ${spacing.x2};
`;

type Props = {
  currentProject: squadTypes.Project,
  currentWorkspace: squadTypes.Workspace,
  fetchProjectDetails: Function,
  fetchProjectGoalList: Function,
  fetchProjectTasks: Function,
  fetchSectionList: Function,
  fetchSubscriptionDetails: Function,
  goals: squadTypes.NormalizedList<squadTypes.Goal>,
  location: Object,
  logVisit: Function,
  match: Object,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  push: Function,
  sections: squadTypes.NormalizedList<squadTypes.Section>,
  showModal: Function,
  tasks: squadTypes.NormalizedList<squadTypes.Task>,
  updateProject: Function,
  updateTask: Function,
  resetGoalList: Function,
  resetSectionList: Function,
  ui: Object
};

class ProjectDetails extends Component<Props> {
  componentDidMount() {
    const {
      currentProject,
      fetchProjectDetails,
      fetchProjectGoalList,
      fetchProjectTasks,
      fetchSectionList,
      fetchSubscriptionDetails,
      match,
      resetGoalList,
      resetSectionList
    } = this.props;

    // First we unselect the goal and reset the list from the redux store. This
    // avoids having discrepancies in the UI.

    // Only fetch the project if we don't already have the data
    //const preloadedProjectId = currentProject ? currentProject.id : "";
    const matchedProjectId = match.params.project;

    resetGoalList();
    //if (matchedProjectId !== preloadedProjectId) {
    resetSectionList();
    fetchProjectDetails(matchedProjectId);
    fetchSubscriptionDetails(matchedProjectId);
    //}

    fetchSectionList(matchedProjectId);
    fetchProjectGoalList(matchedProjectId);
    fetchProjectTasks(matchedProjectId, { filter: { state: "open" } });

    if (currentProject) {
      // Log the visit
      if (!match.params.goal) {
        const page = {
          pageType: "project",
          pageTitle: currentProject.title,
          pageUrl: `/pages/${currentProject.id}`
        };
        this.props.logVisit(page);
      }

      document.title = `${currentProject.title} | Tability`;
    }
  }

  componentDidUpdate(prevProps) {
    // Reload project if we switched project
    const {
      currentProject,
      fetchProjectDetails,
      fetchProjectTasks,
      fetchProjectGoalList,
      fetchSectionList,
      match,
      resetSectionList
    } = this.props;

    // Reload everything if we switched projects
    if (match.params.project !== prevProps.match.params.project) {
      resetSectionList();
      fetchProjectDetails(match.params.project);
      fetchProjectGoalList(match.params.project);
      fetchSectionList(match.params.project);
      fetchProjectTasks(match.params.project, { filter: { state: "open" } });
    }

    // If we have a different project
    if (
      (currentProject && !prevProps.currentProject) ||
      (currentProject && prevProps.currentProject && prevProps.currentProject.id !== currentProject.id)
    ) {
      // Log the visit
      // If this is not a goal page
      document.title = `${currentProject.title} | Tability`;
      if (!match.params.goal) {
        const page = {
          pageType: "project",
          pageTitle: currentProject.title,
          pageUrl: `/pages/${currentProject.id}`
        };
        this.props.logVisit(page);
      }
    }
  }

  unarchiveProject = e => {
    e.preventDefault();
    const { currentProject } = this.props;
    if (
      window.confirm(
        `Are you sure you want to un-archive this page? This action will also un-archive all its sub-pages`
      )
    ) {
      const params = {
        is_archived: false
      };
      this.props.updateProject(currentProject.id, params);
    }
  };

  _onDragEnd = result => {
    const { source, destination, draggableId } = result;
    const { updateTask } = this.props;
    // Don't do anything if we're in the same column
    if (!source || !destination || source === destination) {
      return;
    }

    const roadmap_state = destination.droppableId.replace("-droppable", "");
    const taskParams = {
      roadmap_state
    };
    updateTask(draggableId, taskParams);
  };

  openIntercom = (e: Object) => {
    e.preventDefault();
    window.Intercom("show");
  };

  showTaskForm = (e: Object) => {
    const { currentProject } = this.props;
    e.preventDefault();
    const roadmapState = e.target.dataset.roadmapState;
    this.props.showModal({
      contentType: "task",
      contentAction: "form",
      object: currentProject,
      parent: roadmapState
    });
  };

  renderColumn = (roadmapState, provided, snapshot) => {
    const { sections, goals, tasks } = this.props;
    let taskIndex = 0;
    return (
      <TaskWrapper key={`${roadmapState}-drop`} ref={provided.innerRef} {...provided.droppableProps}>
        {sections.allIds.map(sectionId => {
          const section = sections.byId[sectionId];
          if (!section) {
            return false;
          }
          const sectionGoals = goalsSelectors.getListByParentId(goals.byId, "section", sectionId);
          return (
            <Fragment key={sectionId}>
              {sectionGoals &&
                sectionGoals.map(goal => {
                  const goalTasks = tasks.allIds.filter(taskId => {
                    // Look for tasks matching goal and roadmap_state
                    const task = tasks.byId[taskId];
                    return task.goal_id === goal.id && task.roadmap_state === roadmapState && task.state === "open";
                  });
                  if (goalTasks.length > 0) {
                    return (
                      <GoalTasks key={goal.id}>
                        <GoalTaskBlock goal={goal} />
                        {goalTasks.map((taskId, index) => {
                          const task = tasks.byId[taskId];
                          const _index = taskIndex;
                          taskIndex += 1;
                          if (task) {
                            return (
                              <Draggable key={task.id} draggableId={task.id} index={_index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <TaskItem
                                      key={task.id}
                                      withDrag={true}
                                      task={task}
                                      draggingHorizontal={true}
                                      isDragging={snapshot.isDragging}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                          return false;
                        })}
                      </GoalTasks>
                    );
                  }
                  return false;
                })}
            </Fragment>
          );
        })}
        {provided.placeholder}
        {taskIndex === 0 && (
          <EmptyColumn>
            {roadmapState === "now" && (
              <p>
                Move here the tasks and projects that you need to take care of this week{" "}
                <span role="img" aria-label="">
                  ✨
                </span>
              </p>
            )}
            {roadmapState === "next" && (
              <p>
                Start planning your next week here — we'll move the tasks to the column on the left next Monday{" "}
                <span role="img" aria-label="">
                  ✨
                </span>
              </p>
            )}
            {roadmapState === "later" && (
              <p>
                Capture your backlog here{" "}
                <span role="img" aria-label="">
                  ✨
                </span>
              </p>
            )}
          </EmptyColumn>
        )}
        <button onClick={this.showTaskForm} data-roadmap-state={roadmapState} className="compact">
          <IconPlus />
        </button>
      </TaskWrapper>
    );
  };

  render() {
    const { currentProject, currentWorkspace, projects, match, ui } = this.props;

    const projectIdFromUrl = match.params.project;
    if (ui.AccessIsForbidden[projectIdFromUrl]) {
      return <ProjectAccessForbidden />;
    }

    if (currentProject && currentProject.current_user_permission === "none") {
      return <ProjectAccessForbidden />;
    }
    if (!currentProject) {
      return (
        <WorkspaceLayout>
          <Container>
            <Loader />
          </Container>
        </WorkspaceLayout>
      );
    }

    const breadcrumbs = [];

    if (currentProject.parent_id && projects.byId[currentProject.parent_id]) {
      const parentProject = projects.byId[currentProject.parent_id];
      breadcrumbs.unshift({
        label: parentProject.title,
        path: `/pages/${parentProject.id}`
      });

      if (parentProject.parent_id) {
        breadcrumbs.unshift({
          label: "..."
        });
      }
    }

    breadcrumbs.unshift({
      label: "Pages",
      path: "/pages"
    });

    let pageQuotaExceeded = false;
    // Check if we have exceeded the quota of pages.
    // This is only applied to plans that have the new billing packages
    if (
      currentWorkspace.active_projects_limit !== 0 &&
      currentWorkspace.active_projects_count >= currentWorkspace.active_projects_limit &&
      !constants.BILLING_PER_USER_PLANS.includes(currentWorkspace.stripe_cache_subscription_plan)
    ) {
      pageQuotaExceeded = true;
    }

    return (
      <WorkspaceLayout>
        <Container>
          <Breadcrumbs>
            {breadcrumbs.map((crumb, index) => {
              if (crumb.path) {
                return (
                  <li key={index}>
                    <Link className="subtle" to={crumb.path}>
                      {crumb.label}
                    </Link>
                  </li>
                );
              } else {
                return <li>{crumb.label}</li>;
              }
            })}
          </Breadcrumbs>
          <Header>
            <HeaderLeft>
              <ProjectTitle currentProject={currentProject} />
              <ProjectPermissions currentProject={currentProject} className="hide-tablet" />
              <ProjectSubscription currentProject={currentProject} className="hide-tablet" />
              {ui.isFetchingGoals && <Loader size="small" />}
            </HeaderLeft>
            <HeaderRight>
              <ProjectPublic currentProject={currentProject} />
              <ProjectMode currentProject={currentProject} match={match} />
              <ProjectMenu currentProject={currentProject} />
            </HeaderRight>
          </Header>
          {currentProject.is_archived && (
            <ArchivedBanner>
              <div>This page is archived</div>
              <div data-tip data-for="quota-exceeded">
                <button className="link-button" onClick={this.unarchiveProject} disabled={pageQuotaExceeded}>
                  Unarchive
                </button>
              </div>
            </ArchivedBanner>
          )}
          <Main>
            <DragDropContext onDragEnd={this._onDragEnd}>
              <TasksGrid>
                <Droppable droppableId={`now-droppable`}>
                  {(provided, snapshot) => {
                    return (
                      <TaskColumn className="task-this-week" isDraggingOver={snapshot.isDraggingOver}>
                        <h3>This week</h3>
                        {this.renderColumn("now", provided, snapshot)}
                      </TaskColumn>
                    );
                  }}
                </Droppable>
                <Droppable droppableId={`next-droppable`}>
                  {(provided, snapshot) => {
                    return (
                      <TaskColumn className="task-next-week" isDraggingOver={snapshot.isDraggingOver}>
                        <h3>Next week</h3>
                        {this.renderColumn("next", provided, snapshot)}
                      </TaskColumn>
                    );
                  }}
                </Droppable>
                <Droppable droppableId={`later-droppable`}>
                  {(provided, snapshot) => {
                    return (
                      <TaskColumn className="task-later" isDraggingOver={snapshot.isDraggingOver}>
                        <h3>Later</h3>
                        {this.renderColumn("later", provided, snapshot)}
                      </TaskColumn>
                    );
                  }}
                </Droppable>
              </TasksGrid>
            </DragDropContext>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentWorkspace: state.session.currentWorkspace,
  currentProject: projectsSelectors.getObjectById(state.projects.byId, props.match.params.project),
  sections: state.sections,
  goals: state.goals,
  projects: state.projects,
  tasks: state.tasks,
  ui: state.ui.projectDetails
});

const mapDispatchToProps = {
  push,
  fetchProjectDetails: projectsOperations.fetchProjectDetails,
  fetchProjectTasks: projectsOperations.fetchProjectTasks,
  fetchProjectGoalList: goalsOperations.fetchProjectGoalList,
  fetchProjectList: projectsOperations.fetchProjectList,
  fetchSectionList: sectionsOperations.fetchSectionList,
  fetchSubscriptionDetails: subscriptionsOperations.fetchSubscriptionDetails,
  logVisit: sessionOperations.logVisit,
  resetGoalList: goalsOperations.resetGoalList,
  resetSectionList: sectionsOperations.resetSectionList,
  updateProject: projectsOperations.updateProject,
  updateTask: tasksOperations.updateTask,
  showModal: uiOperations.showModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDetails));
