// @flow
import { createReducer } from "state/utils";
import * as reducerUtils from "state/utils/reducers";
import * as types from "./types";

import { INITIAL_LIST_STATE } from "config/constants";

const normalizedStateReducer = createReducer(INITIAL_LIST_STATE)({
  // Get list of objects
  [types.FETCH_LIST_COMPLETED]: (state, action) => reducerUtils.paginateList(state, action),
  [types.RESET_LIST]: (state, action) => INITIAL_LIST_STATE
});

export default normalizedStateReducer;
