//@flow
import React, { Component } from "react";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import moment from "moment";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

// Components
import BotAvatarHappy from "v2/components/_assets/tabbot-happy.png";
import BotAvatarSurprised from "v2/components/_assets/tabbot-surprised.png";

const Container = styled.div`
  display: flex;
  background-color: #e9e9e9;
  margin-top: 0;
  margin-bottom: ${spacing.x2};
  padding: ${spacing.x2};
  border-radius: 4px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Author = styled.div`
  a {
    color: ${colors.black};
  }
`;

const Meta = styled.div`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${spacing.x1};

  .auto-label {
    display: inline-block;
    background-color: #a9a9a9;
    font-size: 1.2rem;
    padding: 0.4rem ${spacing.x1};
    border-radius: 2px;
  }
`;

const StatusData = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  margin-right: ${spacing.x2};
  img {
    height: 3.2rem;
  }
`;

const Details = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: ${spacing.x1};
  }
`;

type Props = {
  goal: squadTypes.Goal,
  closeGoal: Function
};

class OverdueCard extends Component<Props> {
  render() {
    const { goal } = this.props;
    let warningLevel = 0;

    const baseDate = moment();

    if (moment(goal.deadline).isBefore(baseDate.subtract(7, "days"))) {
      warningLevel = 1;
    }
    if (moment(goal.deadline).isBefore(moment().subtract(14, "days"))) {
      warningLevel = 2;
    }

    return (
      <Container>
        <AvatarContainer>
          {warningLevel <= 1 && <img src={BotAvatarHappy} alt="Tabot" />}
          {warningLevel === 2 && <img src={BotAvatarSurprised} alt="Tabot" />}
        </AvatarContainer>
        <Card>
          <Meta>
            <div>
              <Author>Tabby</Author>
            </div>
            <StatusData>
              <span className="auto-label">Automated</span>
            </StatusData>
          </Meta>
          {warningLevel === 0 && (
            <Details>
              <p>
                Hi! This goal is past it's due date and it might be time to close it. That way you'll stop getting
                notifications and reminders about it.
              </p>
              <p>
                The deadline was <b>{moment(goal.deadline).fromNow()}</b>.
              </p>
              <ButtonContainer>
                <button className="primary" onClick={this.props.closeGoal}>
                  Close goal
                </button>
              </ButtonContainer>
            </Details>
          )}
          {warningLevel === 1 && (
            <Details>
              <p>
                Hi! This goal is 1 week past it's due date and it might be time to close it. That way you'll stop
                getting notifications and reminders about it.
              </p>
              <p>
                The deadline was <b>{moment(goal.deadline).fromNow()}</b>.
              </p>
              <ButtonContainer>
                <button className="primary" onClick={this.props.closeGoal}>
                  Close goal
                </button>
              </ButtonContainer>
            </Details>
          )}
          {warningLevel === 2 && (
            <Details>
              <p>
                Oops! This goal is still open but it's <b>{moment(goal.deadline).fromNow(true)}</b> past its due date.
                We'll close it for you next week unless you extend the deadline.
              </p>
              <ButtonContainer>
                <button className="primary" onClick={this.props.closeGoal}>
                  Close goal
                </button>
              </ButtonContainer>
            </Details>
          )}
        </Card>
      </Container>
    );
  }
}

export default OverdueCard;
