//@flow
import { request } from "state/utils";
import * as types from "./types";
import { getSubdomain } from "state/utils/url";

export const createWorkspace = (workspaceParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/workspaces`, { workspace: workspaceParams }),
    payload: { workspaceParams }
  };
};

export const updateWorkspace = (workspaceId: string, workspaceParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/workspaces/${workspaceId}`, { workspace: workspaceParams }),
    payload: { workspaceParams }
  };
};

export const deleteWorkspace = (workspaceId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/workspaces/${workspaceId}`)
  };
};

export const fetchWorkspaceList = (params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/workspaces?page=${page}`)
  };
};

export const fetchWorkspaceDetails = (workspaceId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/workspaces/${workspaceId}`)
  };
};

export const resetWorkspaceList = () => ({
  type: types.RESET_LIST
});

export const fetchSubscriptionDetails = () => {
  const subdomain = getSubdomain();
  if (!subdomain) {
    return Promise.reject(new Error("Couldn't find a subdomain"));
  }

  return {
    types: [
      types.FETCH_STRIPE_DETAILS_REQUESTED,
      types.FETCH_STRIPE_DETAILS_COMPLETED,
      types.FETCH_STRIPE_DETAILS_FAILED
    ],
    callAPI: () => request.get(`/workspaces/${subdomain}/stripe`)
  };
};

export const updateCardDetails = (workspaceParams: Object) => {
  const subdomain = getSubdomain();
  if (!subdomain) {
    return Promise.reject(new Error("Couldn't find a subdomain"));
  }

  return {
    types: [types.UPDATE_STRIPE_REQUESTED, types.UPDATE_STRIPE_COMPLETED, types.UPDATE_STRIPE_FAILED],
    callAPI: () => request.put(`/workspaces/${subdomain}/stripe`, { workspace: workspaceParams })
  };
};

export const updateSubscription = (workspaceParams: Object) => {
  const subdomain = getSubdomain();
  if (!subdomain) {
    return Promise.reject(new Error("Couldn't find a subdomain"));
  }
  return {
    types: [types.UPDATE_SUBSCRIPTION_REQUESTED, types.UPDATE_SUBSCRIPTION_COMPLETED, types.UPDATE_SUBSCRIPTION_FAILED],
    callAPI: () => request.put(`/workspaces/${subdomain}/subscription`, { workspace: workspaceParams }),
    payload: { workspaceParams }
  };
};

export const cancelSubscription = () => {
  const subdomain = getSubdomain();
  if (!subdomain) {
    return Promise.reject(new Error("Couldn't find a subdomain"));
  }
  return {
    types: [types.CANCEL_SUBSCRIPTION_REQUESTED, types.CANCEL_SUBSCRIPTION_COMPLETED, types.CANCEL_SUBSCRIPTION_FAILED],
    callAPI: () => request.delete(`/workspaces/${subdomain}/subscription`)
  };
};

export const deleteCardDetails = () => {
  const subdomain = getSubdomain();
  if (!subdomain) {
    return Promise.reject(new Error("Couldn't find a subdomain"));
  }
  return {
    types: [types.DELETE_STRIPE_CARD_REQUESTED, types.DELETE_STRIPE_CARD_COMPLETED, types.DELETE_STRIPE_CARD_FAILED],
    callAPI: () => request.delete(`/workspaces/${subdomain}/stripe`)
  };
};
