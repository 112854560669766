// @flow
import { createReducer } from "state/utils";
import * as sessionTypes from "state/ducks/session/types";

const INITIAL_STATE = {
  error: false,
  isFetching: false,
  isConfirmed: false,
  tokenInvalid: false,
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [sessionTypes.EMAIL_CONFIRMATION_FAILED]: (state, action) => {
    let message = "Sorry, we could not confirm your email.";
    if (action.error.response.data["errors"] && action.error.response.data["errors"]["email"]) {
      message = `Your email ${action.error.response.data["errors"]["email"]}`;
    }
    if (action.error.response.data["errors"] && action.error.response.data["errors"]["confirmation_token"]) {
      message = `Your confirmation token ${action.error.response.data["errors"]["confirmation_token"]}`;
    }
    let isConfirmed = false;

    // Test if the error is that the email is already confirmed.
    if (
      action.error.response.data["errors"] &&
      action.error.response.data["errors"]["email"] &&
      action.error.response.data["errors"]["email"][0].includes("already confirmed")
    ) {
      isConfirmed = true;
    }

    return {
      error: true,
      isFetching: false,
      isConfirmed,
      tokenInvalid: true,
      message: message,
      messageType: "warning"
    };
  },
  [sessionTypes.EMAIL_CONFIRMATION_COMPLETED]: () => ({
    error: false,
    isFetching: false,
    isConfirmed: true,
    message: "Your email has been confirmed.",
    messageType: "success",
    tokenInvalid: false
  }),
  [sessionTypes.EMAIL_CONFIRMATION_REQUESTED]: () => ({
    error: false,
    isFetching: true,
    isConfirmed: false,
    message: "",
    tokenInvalid: false
  }),
  [sessionTypes.RESEND_CONFIRMATION_FAILED]: (state, action) => ({
    ...state,
    error: true,
    isFetching: false,
    needConfirmation: false,
    message: "Sorry, we could not send you your confirmation email.",
    messageType: "warning",
    tokenInvalid: false
  }),
  [sessionTypes.RESEND_CONFIRMATION_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    needConfirmation: false,
    message: "Your email confirmation has been sent.",
    messageType: "success",
    tokenInvalid: false
  }),
  [sessionTypes.RESEND_CONFIRMATION_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: "",
    tokenInvalid: false
  })
});
