//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 22 22" {...props}>
    <g
      id="Icon-dump"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="play-circle" transform="translate(1.000000, 1.000000)" stroke="#6A6A6A" strokeWidth="2">
        <circle id="Oval" cx="10" cy="10" r="10"></circle>
        <polygon id="Shape" points="8 6 14 10 8 14"></polygon>
      </g>
    </g>
  </Logo>
);
