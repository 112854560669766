import React, { Component } from "react";
import { connect } from "react-redux";
import { projectsOperations } from "state/ducks/projects";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

const EditableProjectTitle = styled.h1`
  overflow-wrap: break-word;
  overflow: hidden;
  border-bottom: 2px solid transparent;
  resize: none;
  outline: none;

  transition: all ease 0.2s;
  margin-right: ${spacing.x2};

  &:focus {
    border-bottom: 2px solid ${colors.blue};
  }
`;

type Props = {
  currentProject: squadTypes.Project,
  updateProject: Function
};

class ProjectTitle extends Component<Props> {
  projectTitleInput: any;

  updateProjectTitle = e => {
    e.preventDefault();
    const { currentProject, updateProject } = this.props;
    const title = e.target.innerText;
    const params = {
      title
    };

    updateProject(currentProject.id, params);
  };

  handlePress = e => {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.projectTitleInput.blur();
    }

    if (e.keyCode === 13) {
      e.preventDefault();
      this.projectTitleInput.blur();
    }
  };

  handlePaste = e => {
    e.preventDefault();
    let text = e.clipboardData.getData("text/plain");
    text = text.replace(/(\r\n|\n|\r)/gm, " "); // remove the line breaks
    document.execCommand("insertHTML", false, text);
  };

  render() {
    const { currentProject } = this.props;

    return (
      <EditableProjectTitle
        ref={div => {
          this.projectTitleInput = div;
        }}
        contentEditable={currentProject && currentProject.current_user_permission === "edit"}
        spellCheck={false}
        onKeyDown={this.handlePress}
        onBlur={this.updateProjectTitle}
        onPaste={this.handlePaste}
        dangerouslySetInnerHTML={{ __html: currentProject.title }}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  updateProject: projectsOperations.updateProject
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTitle);
