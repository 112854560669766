//@flow
export const CREATE_REQUESTED = "workspaces/CREATE_REQUESTED";
export const CREATE_COMPLETED = "workspaces/CREATE_COMPLETED";
export const CREATE_FAILED = "workspaces/CREATE_FAILED";

export const UPDATE_REQUESTED = "workspaces/UPDATE_REQUESTED";
export const UPDATE_COMPLETED = "workspaces/UPDATE_COMPLETED";
export const UPDATE_FAILED = "workspaces/UPDATE_FAILED";

export const DELETE_REQUESTED = "workspaces/DELETE_REQUESTED";
export const DELETE_COMPLETED = "workspaces/DELETE_COMPLETED";
export const DELETE_FAILED = "workspaces/DELETE_FAILED";

export const FETCH_LIST_REQUESTED = "workspaces/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "workspaces/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "workspaces/FETCH_LIST_FAILED";

export const FETCH_DETAILS_REQUESTED = "workspaces/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "workspaces/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "workspaces/FETCH_DETAILS_FAILED";

export const RESET_LIST = "workspaces/RESET_LIST";

export const UPDATE_STRIPE_REQUESTED = "workspaces/UPDATE_STRIPE_REQUESTED";
export const UPDATE_STRIPE_COMPLETED = "workspaces/UPDATE_STRIPE_COMPLETED";
export const UPDATE_STRIPE_FAILED = "workspaces/UPDATE_STRIPE_FAILED";

export const UPDATE_SUBSCRIPTION_REQUESTED = "workspaces/UPDATE_SUBSCRIPTION_REQUESTED";
export const UPDATE_SUBSCRIPTION_COMPLETED = "workspaces/UPDATE_SUBSCRIPTION_COMPLETED";
export const UPDATE_SUBSCRIPTION_FAILED = "workspaces/UPDATE_SUBSCRIPTION_FAILED";

export const FETCH_STRIPE_DETAILS_REQUESTED = "workspaces/FETCH_STRIPE_DETAILS_REQUESTED";
export const FETCH_STRIPE_DETAILS_COMPLETED = "workspaces/FETCH_STRIPE_DETAILS_COMPLETED";
export const FETCH_STRIPE_DETAILS_FAILED = "workspaces/FETCH_STRIPE_DETAILS_FAILED";

export const CANCEL_SUBSCRIPTION_REQUESTED = "workspaces/CANCEL_SUBSCRIPTION_REQUESTED";
export const CANCEL_SUBSCRIPTION_COMPLETED = "workspaces/CANCEL_SUBSCRIPTION_COMPLETED";
export const CANCEL_SUBSCRIPTION_FAILED = "workspaces/CANCEL_SUBSCRIPTION_FAILED";

export const DELETE_STRIPE_CARD_REQUESTED = "workspaces/DELETE_STRIPE_CARD_REQUESTED";
export const DELETE_STRIPE_CARD_COMPLETED = "workspaces/DELETE_STRIPE_CARD_COMPLETED";
export const DELETE_STRIPE_CARD_FAILED = "workspaces/DELETE_STRIPE_CARD_FAILED";
