// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { membershipsOperations, membershipsSelectors } from "state/ducks/memberships";
import * as membershipsTypes from "state/ducks/memberships/types";
import { goalsOperations, goalsSelectors } from "state/ducks/goals";
import { sessionOperations } from "state/ducks/session";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// V2 Components
import Avatar from "v2/components/Avatar";
import GoalBlockPage from "v2/components/GoalBlockPage";
import Loader from "v2/components/Loader";
import { Link } from "react-router-dom";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x2};
`;

const Main = styled.div``;

const GoalsContainer = styled.div`
  padding-left: 0;

  h3 {
    margin-bottom: ${spacing.x2};
  }
`;

const GoalsSection = styled.div`
  margin-bottom: ${spacing.x4};

  padding: ${spacing.x2};
  border: 1px solid ${colors.blockBorder};
  background: #fff;
  border-radius: 8px;

  .new-section {
    margin-top: ${spacing.x2};

    &:first-child {
      margin-top: 0;
    }
  }
`;

const ProfileMeta = styled.div`
  margin-left: 0.8rem;
`;

const ProjectTitle = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: ${spacing.x2};

  a {
    color: ${colors.black};
  }

  span {
    display: inline-block;
    font-size: 1.4;
    font-weight: 500;
    color: ${colors.subtleText};
    margin-left: 0.4rem;
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  currentMembership: squadTypes.Membership,
  fetchMembershipGoalList: Function,
  fetchMembershipDetails: Function,
  logVisit: Function,
  match: Object,
  weeklyGoals: Array<squadTypes.Goal>,
  monthlyGoals: Array<squadTypes.Goal>,
  quarterlyGoals: Array<squadTypes.Goal>,
  goals: squadTypes.NormalizedList<squadTypes.Goal>,
  recentlyVisited: Array<Object>,
  resetGoalList: Function,
  ui: Object,
  goalDetailsUI: Object
};

type State = {};

class Home extends Component<Props, State> {
  componentWillMount() {
    this.props.resetGoalList();
  }

  componentDidMount() {
    const { currentMembership, fetchMembershipGoalList, fetchMembershipDetails, logVisit, match } = this.props;

    const preloadedMembershipId = currentMembership ? currentMembership.id : "";
    if (match.params.membership !== preloadedMembershipId) {
      fetchMembershipDetails(match.params.membership).then(action => {
        if (action.type === membershipsTypes.FETCH_DETAILS_COMPLETED) {
          const membership = action.response.data;
          logVisit({
            pageType: "membership",
            pageId: membership.id,
            pageTitle: membership.user.fullname,
            pageUrl: `/team/${membership.id}`
          });
        }
      });
    } else {
      logVisit({
        pageType: "membership",
        pageId: currentMembership.id,
        pageTitle: currentMembership.user.fullname,
        pageUrl: `/team/${currentMembership.id}`
      });
    }
    fetchMembershipGoalList(match.params.membership);
  }

  componentWillReceiveProps(nextProps) {
    const { fetchMembershipGoalList, fetchMembershipDetails, logVisit, match } = this.props;
    if (match.params.membership !== nextProps.match.params.membership) {
      this.props.resetGoalList();
      fetchMembershipDetails(nextProps.match.params.membership).then(action => {
        if (action.type === membershipsTypes.FETCH_DETAILS_COMPLETED) {
          const membership = action.response.data;
          logVisit({
            pageType: "membership",
            pageId: membership.id,
            pageTitle: membership.user.fullname,
            pageUrl: `/team/${membership.id}`
          });
        }
      });
      fetchMembershipGoalList(nextProps.match.params.membership);
    }
  }

  displayGoals = (goals, frequency) => {
    if (!goals[0].project) {
      return false;
    }

    let prevProjectId = null;
    let prevSectionId = null;
    let projectTitle = null;
    let sectionTitle = null;
    let blockClass = "";

    /**
     * In the script below we iterate on the goals and display the corresponding project / section if necessary
     */
    return (
      <div>
        <h3>Your {frequency} check-ins</h3>
        <GoalsSection>
          {goals.map(goal => {
            if (!goal.project) {
              return false;
            }
            // If current project id is different from prevProjectId
            // - set prevProjectId to currentProjectId
            // - set projectTitle to currentProjectTitle
            // Otherwise
            // - set projectTitle to null
            if (goal.project_id !== prevProjectId) {
              prevProjectId = goal.project_id;
              projectTitle = goal.project.title;
              blockClass = "new-section";
            } else {
              projectTitle = null;
              blockClass = "";
            }

            // If current section id is different from prevSectionId
            // - set prevSectionId to currentSectionId
            // - set projectTitle to currentSectionTitle
            // Otherwise
            // - set sectionTitle to null
            if (goal.section_id !== prevSectionId && goal.project.sections_count > 1) {
              prevSectionId = goal.section_id;
              if (goal.section) {
                projectTitle = goal.project.title;
                sectionTitle = goal.section.title;
                blockClass = "new-section";
              } else {
                sectionTitle = null;
                blockClass = "";
              }
            } else {
              sectionTitle = null;
            }

            // Display the goal, with project and section if necessary
            return (
              <div key={goal.id} className={blockClass}>
                {projectTitle && (
                  <ProjectTitle>
                    <Link to={`/pages/${goal.project_id}`}>{projectTitle}</Link>
                    {sectionTitle && <span>/</span>}
                    {sectionTitle && <span>{sectionTitle}</span>}
                  </ProjectTitle>
                )}
                <GoalBlockPage key={goal.id} goal={goal} />
              </div>
            );
          })}
        </GoalsSection>
      </div>
    );
  };

  render() {
    const { currentMembership, goals, weeklyGoals, monthlyGoals, quarterlyGoals, goalDetailsUI } = this.props;

    if (!currentMembership) {
      return (
        <WorkspaceLayout>
          <Container>
            <Loader />
          </Container>
        </WorkspaceLayout>
      );
    }

    if (goalDetailsUI.isFetchingGoals) {
      return (
        <WorkspaceLayout>
          <Container>
            <Loader />
          </Container>
        </WorkspaceLayout>
      );
    }

    const { user } = currentMembership;
    if (!goalDetailsUI.isFetchingGoals && goals.total === 0) {
      return (
        <WorkspaceLayout>
          <Container>
            {user && (
              <Header>
                <Avatar user={user} to={`/team/${currentMembership.id}`} hideName={true} size={60} />
                <ProfileMeta>
                  <h1>{user.fullname || user.email}</h1>
                </ProfileMeta>
              </Header>
            )}
            <Main>
              <GoalsContainer>
                <h3>This user doesn't have any goal yet.</h3>
              </GoalsContainer>
            </Main>
          </Container>
        </WorkspaceLayout>
      );
    }

    return (
      <WorkspaceLayout>
        <Container>
          {user && (
            <Header>
              <Avatar user={user} to={`/team/${currentMembership.id}`} hideName={true} size={60} />
              <ProfileMeta>
                <h1>{user.fullname || user.email}</h1>
              </ProfileMeta>
            </Header>
          )}
          <Main>
            <GoalsContainer>
              {weeklyGoals.length > 0 && this.displayGoals(weeklyGoals, "weekly")}
              {monthlyGoals.length > 0 && this.displayGoals(monthlyGoals, "monthly")}
              {quarterlyGoals.length > 0 && this.displayGoals(quarterlyGoals, "quarterly")}
            </GoalsContainer>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentWorkspace: state.session.currentWorkspace,
    currentMembership: membershipsSelectors.getObjectById(state.memberships.byId, props.match.params.membership),
    goals: state.goals,
    weeklyGoals: goalsSelectors.getListByParam(state.goals.byId, "update_frequency", "weekly"),
    monthlyGoals: goalsSelectors.getListByParam(state.goals.byId, "update_frequency", "monthly"),
    quarterlyGoals: goalsSelectors.getListByParam(state.goals.byId, "update_frequency", "quarterly"),
    ui: state.ui.workspaceHome,
    goalDetailsUI: state.ui.goalDetails,
    recentlyVisited: state.session.recentlyVisited
  };
};

const mapDispatchToProps = {
  fetchMembershipDetails: membershipsOperations.fetchMembershipDetails,
  fetchMembershipGoalList: goalsOperations.fetchMembershipGoalList,
  logVisit: sessionOperations.logVisit,
  resetGoalList: goalsOperations.resetGoalList
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
