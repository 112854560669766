// @flow
import { createReducer } from "state/utils";
import * as projectsTypes from "state/ducks/projects/types";
import * as projectPermissionsTypes from "state/ducks/projectPermissions/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  errorFields: {},
  formState: "pending",
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [projectsTypes.UPDATE_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [projectsTypes.UPDATE_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "Your page has been updated",
      messageType: "success"
    };
  },
  [projectsTypes.UPDATE_FAILED]: (state, action) => {
    const errors = action.error.response.data;
    const errorFields = {};
    if (errors["title"]) {
      errorFields["title"] = "Title is invalid";
    }
    if (errors["update_frequency"]) {
      errorFields["update_frequency"] = "Update frequency is invalid";
    }
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not update your page",
      messageType: "warning"
    };
  },
  [projectPermissionsTypes.UPDATE_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [projectPermissionsTypes.UPDATE_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "The permission has been updated",
      messageType: "success"
    };
  },
  [projectPermissionsTypes.UPDATE_FAILED]: (state, action) => {
    return {
      errorFields: [],
      formState: "failed",
      message: "Sorry but we could not change this permssion",
      messageType: "warning"
    };
  },
  [projectPermissionsTypes.DELETE_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [projectPermissionsTypes.DELETE_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "The permission has been removed",
      messageType: "success"
    };
  },
  [projectPermissionsTypes.DELETE_FAILED]: (state, action) => {
    return {
      errorFields: [],
      formState: "failed",
      message: "Sorry but we could not remove this permssion",
      messageType: "warning"
    };
  },
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
