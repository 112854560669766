// @flow
export const getObjectById = (objectsById: Object, id: string) => {
  if (!objectsById || !id) {
    return null;
  }
  return objectsById[id];
};

// This functions returns a list of object where. object.<parent_type>_id === id
export const getListByParentId = (objectsById: Object, parent_type: string, parent_id: string) => {
  if (!objectsById || !parent_type || !parent_id) {
    return [];
  }

  const arrayList = Object.keys(objectsById).map(key => objectsById[key]);
  // $FlowFixMe
  return arrayList.filter(object => object[`${parent_type}_id`] === parent_id);
};

// This functions returns a list of object where. object.<parent_type>_id === id
export const getListByParam = (objectsById: Object, param: string, value: any) => {
  if (!objectsById || !param || !value) {
    return [];
  }

  const arrayList = Object.keys(objectsById).map(key => objectsById[key]);
  // $FlowFixMe
  return arrayList.filter(object => object[`${param}`] === value);
};
