// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

// Components
import IconPage from "v2/components/svg/IconPage";
import IconMembership from "v2/components/svg/IconMembership";
import IconGoal from "v2/components/svg/IconGoal";
import { Link } from "react-router-dom";

const Container = styled.div``;

type Props = {
  recentlyVisited: Array<Object>,
  hideForm: Function
};

type State = {};

class RecentlyVisited extends Component<Props, State> {
  render() {
    return (
      <Container>
        <b>Recently visited</b>
        <ul>
          {this.props.recentlyVisited.map((item, index) => {
            return (
              <li key={index}>
                <Link to={item.pageUrl} onClick={this.props.hideForm}>
                  {item.pageType === "project" && <IconPage />}
                  {item.pageType === "membership" && <IconMembership />}
                  {item.pageType === "goal" && <IconGoal />}
                  <span>{item.pageTitle}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    recentlyVisited: state.session.recentlyVisited[state.session.currentWorkspace.slug] || []
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyVisited);
