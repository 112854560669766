import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { uiOperations } from "state/ducks/ui";
import { goalsSelectors } from "state/ducks/goals";
import * as squadTypes from "squadTypes";

// Components
import IconGoal from "v2/components/svg/IconGoal";
import IconTV from "v2/components/svg/IconTV";
import IconReport from "v2/components/svg/IconReport";
import IconTaskToggleOff from "v2/components/_assets/IconTaskToggleOff.png";
import IconTaskToggleOn from "v2/components/_assets/IconTaskToggleOn.png";

import RadioField from "v2/components/RadioField";

import ReactTooltip from "react-tooltip";

type Props = {
  match: any,
  push: Function,
  currentProject: squadTypes.Project,
  mode?: string,
  projectGoals: Array<squadTypes.Goal>
};

class ProjectPermissions extends Component<Props> {
  _showPermissionModal = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "permissions",
      object: this.props.currentProject
    });
  };

  render() {
    const { currentProject, match, mode } = this.props;
    let checkedMode = "page";

    const pageUrl = `/pages/${currentProject.id}`;
    const tasksUrl = `/pages/${currentProject.id}/tasks`;
    const reportUrl = `/p/${currentProject.public_token}`;
    const tvUrl = `/p/${currentProject.public_token}/tv`;

    if (match.path === "/pages/:project" || match.path === "/pages/:goal") {
      checkedMode = "page";
    }

    if (match.path === "/pages/:project/tasks") {
      checkedMode = "tasks";
    }

    if (match.path === "/p/:project") {
      checkedMode = "report";
    }

    if (match.path === "/p/:project/tv") {
      checkedMode = "tv";
    }

    if (currentProject.goals_count === 0) {
      return null;
    }

    return (
      <RadioField className={`icons-only no-spacing ${mode}`}>
        <div className="radio-group">
          <input
            type="radio"
            id="page"
            checked={checkedMode === "page"}
            name="page_mode"
            onChange={() => this.props.push(pageUrl)}
          />
          <label htmlFor="page" data-tip data-for="show-page">
            <IconGoal />
          </label>
          <input
            type="radio"
            id="tasks"
            checked={checkedMode === "tasks"}
            name="page_mode"
            onChange={() => this.props.push(tasksUrl)}
          />
          <label htmlFor="tasks" data-tip data-for="show-tasks">
            {checkedMode !== "tasks" && (
              <img src={IconTaskToggleOff} style={{ width: "2.3rem" }} alt="Switch to tasks mode" />
            )}
            {checkedMode === "tasks" && (
              <img src={IconTaskToggleOn} style={{ width: "2.3rem" }} alt="Switch to tasks mode" />
            )}
          </label>
          <input
            type="radio"
            id="report"
            checked={checkedMode === "report"}
            name="page_mode"
            onChange={() => this.props.push(reportUrl)}
          />
          <label htmlFor="report" data-tip data-for="show-report">
            <IconReport />
          </label>
          <input
            type="radio"
            id="tv"
            checked={checkedMode === "tv"}
            name="page_mode"
            onChange={() => this.props.push(tvUrl)}
          />
          <label htmlFor="tv" data-tip data-for="show-tv">
            <IconTV />
          </label>
        </div>
        <ReactTooltip id="show-page" place="bottom" type="dark" effect="solid" delayShow={100}>
          Switch to goals overview
        </ReactTooltip>
        <ReactTooltip id="show-tasks" place="bottom" type="dark" effect="solid" delayShow={100}>
          Switch to tasks
        </ReactTooltip>
        <ReactTooltip id="show-details" place="bottom" type="dark" effect="solid" delayShow={100}>
          Switch to detailed view
        </ReactTooltip>
        <ReactTooltip id="show-report" place="bottom" type="dark" effect="solid" delayShow={100}>
          Switch to report view
        </ReactTooltip>
        <ReactTooltip id="show-tv" place="bottom" type="dark" effect="solid" delayShow={100}>
          Switch to TV mode
        </ReactTooltip>
      </RadioField>
    );
  }
}

const mapStateToProps = (state, props) => ({
  projectGoals: goalsSelectors.getListByParentId(state.goals.byId, "project", props.match.params.project)
});

const mapDispatchToProps = {
  showModal: uiOperations.showModal,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPermissions);
