// @flow
import React, { Component } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import _ from "lodash";
import styled from "styled-components";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

const Container = styled.div`
  margin: 0 0 ${spacing.x2} 0;

  textarea {
  }

  .tability-mentions__control {
    margin-bottom: ${spacing.x1};
    border-radius: 4px;
    overflow: hidden;
    transition: border 0.5s ease;
    font-size: 1.4rem;
    border: 1px solid ${colors.inputBorder};

    &:focus-within {
      border: 1px solid ${colors.blue};
    }
  }

  .tability-mentions__input,
  .tability-mentions__highlighter {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    transition: 0.3s ease-in-out;
    border: 0;
    line-height: 1.4;
    padding: ${spacing.x1};
    &::placeholder {
      color: #949494;
      font-size: 1.4rem;
    }

    &:focus,
    &:active {
      outline: none;
      border: 0;
    }
  }

  .tability-mentions__suggestions {
    background-color: #fff !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    overflow: hidden;
    padding: 0;
  }
  .tability-mentions__suggestions__list {
    font-size: 14px;
    max-height: 200px;
    overflow: auto;

    .tability-mentions__suggestions__item--focused {
      font-weight: 600;
      background: #f3f3f3;
    }
  }
  .tability-mentions__suggestions__item {
    padding: ${spacing.x1};
    border-bottom: 1px solid #f3f3f3;
    &:last-of-type {
      border-bottom: 0;
    }
  }
  .tability-mention-item {
    background-color: rgba(110, 202, 214, 0.3);
  }
`;

type Props = {
  value: string,
  setValue: Function,
  minimized?: boolean,
  placeholder?: string,
  comment?: boolean
};

class MentionBox extends Component<Props> {
  debouncedFetchMembershipsForAutocomplete: Function;
  constructor(props: any) {
    super(props);
    this.debouncedFetchMembershipsForAutocomplete = _.debounce(this._fetchMembershipsForAutocomplete, 300);
  }

  _onChangeText = (e: any) => {
    const mentionText = e.target.value;
    this.props.setValue(mentionText);
  };

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchMembershipsForAutocomplete = (inputValue: ?string, callback: Function) => {
    // Get default users if nothing selected yet
    if (!inputValue) {
      const slug = getSubdomain() || "";
      const membershipsApiURL = `/workspaces/${slug}/memberships`;
      let membershipsParams = {
        limit: 20
      };
      request
        .get(membershipsApiURL, { params: membershipsParams })
        .then(response => {
          const memberships = response.data;
          if (memberships && memberships.length > 0) {
            const options = memberships.map(membership => {
              const user = membership.user;
              return {
                id: membership.id,
                display: user.fullname || user.email
              };
            });
            return options;
          } else {
            return null;
          }
        })
        .then(callback);
      return;
    }
    const slug = getSubdomain() || "";
    const membershipsApiURL = `/workspaces/${slug}/memberships`;
    let membershipsParams = inputValue
      ? {
          name: inputValue,
          limit: 20
        }
      : null;
    request
      .get(membershipsApiURL, { params: membershipsParams })
      .then(response => {
        const memberships = response.data;
        if (memberships && memberships.length > 0) {
          const options = memberships.map(membership => {
            const user = membership.user;
            return {
              id: membership.id,
              display: user.fullname || user.email
            };
          });
          return options;
        } else {
          return null;
        }
      })
      .then(callback);
  };

  render() {
    const { comment, minimized, placeholder, value } = this.props;

    const minimizedClass = minimized ? "minimized" : "";
    const height = minimized ? "4rem" : "15rem";
    const commentClass = comment ? "comment" : "";
    const _placeholder = placeholder || "Write a comment";
    return (
      <Container className={`${minimizedClass} ${commentClass}`}>
        <MentionsInput
          value={value}
          onChange={this._onChangeText}
          placeholder={_placeholder}
          className="tability-mentions"
          style={{
            input: {
              minHeight: height
            }
          }}
        >
          <Mention
            displayTransform={(id, name) => `@${name}`}
            trigger="@"
            data={this.debouncedFetchMembershipsForAutocomplete}
            className="tability-mention-item"
          />
        </MentionsInput>

        <small className="subtle">You can mention users with @</small>
      </Container>
    );
  }
}

export default MentionBox;
