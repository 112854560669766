//@flow
import * as types from "./types";
import {
  create2FABackupCodes,
  deleteAccount,
  fetchAccount,
  fetchAssetPostUrl,
  uploadAsset,
  updateAccount,
  updatePassword,
  create2FA,
  update2FA
} from "./actions";
import { fetchSubscriptionList } from "state/ducks/subscriptions/actions";
import { fetchGoalSubscriptionList } from "state/ducks/goalSubscriptions/actions";
import { fetchWorkspaceList } from "state/ducks/workspaces/actions";

const uploadAssetDance = (file: any) => (dispatch: Function) => {
  dispatch(fetchAssetPostUrl()).then(action => {
    if (action.type === types.FETCH_ASSET_POST_URL_COMPLETED) {
      const assetPostUrl = action.response.data;
      dispatch(uploadAsset(file, assetPostUrl));
    }
  });
};

const fetchAccountDance = () => (dispatch: Function) => {
  dispatch(fetchAccount()).then(action => {
    if (action.type === types.FETCH_ACCOUNT_COMPLETED) {
      dispatch(fetchSubscriptionList());
      dispatch(fetchGoalSubscriptionList());
      dispatch(fetchWorkspaceList());
    }
  });
};

export {
  create2FABackupCodes,
  deleteAccount,
  fetchAccount,
  fetchAccountDance,
  uploadAssetDance,
  updateAccount,
  updatePassword,
  create2FA,
  update2FA
};
