//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg``;
export default (props: Object) => (
  <Logo viewBox="0 0 18 18" {...props}>
    <title id="Dashboard-Title">Dashboard</title>
    <g
      id="Dashboard-01-Product-UI"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <title id="Dashboard-titleDashboard">Dashboard</title>
      <g
        id="Dashboard-Dashboard---Goal-Detail-B"
        transform="translate(-26.000000, -159.000000)"
        stroke="#6A6A6A"
        strokeWidth="2"
      >
        <g id="Dashboard-Group-17" transform="translate(26.000000, 26.000000)">
          <g id="Dashboard-Group-16" transform="translate(0.000000, 119.000000)">
            <g id="Dashboard-Group-15">
              <g id="Dashboard-Group-14" transform="translate(0.000000, 15.000000)">
                <g id="Dashboard-Group-8" transform="translate(1.000000, 0.000000)">
                  <rect id="Dashboard-Rectangle-path" x="0" y="0" width="6.22222222" height="6.22222222" />
                  <rect id="Dashboard-Rectangle-path2" x="9.77777778" y="0" width="6.22222222" height="6.22222222" />
                  <rect
                    id="Dashboard-Rectangle-path3"
                    x="9.77777778"
                    y="9.77777778"
                    width="6.22222222"
                    height="6.22222222"
                  />
                  <rect id="Dashboard-Rectangle-path4" x="0" y="9.77777778" width="6.22222222" height="6.22222222" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
