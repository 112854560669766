// @flow

export const getSubdomain = () => {
  // URL name of the current workspace - used to create the baseURL below.
  const hostArray = window.location.host.split(".");

  // If we don't have a 3 parts domain then you're probably on localhost.
  if (hostArray.length !== 3) {
    return null;
  }

  return hostArray[0];
};
