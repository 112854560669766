// @flow
import React, { Component } from "react";
import Flag from "v2/components/Flag";

type Props = {
  uiState: Object,
  ignoreCompleted?: boolean
};

type State = {
  displayFlag: boolean
};

class FormFlag extends Component<Props, State> {
  timeoutId = null;
  state = {
    displayFlag: false
  };

  // Displays the flag and sets a 5s timer to hide it
  showFlag = () => {
    if (!this.state.displayFlag) {
      this.setState({ displayFlag: true });
      this.timeoutId = setTimeout(() => {
        this.hideFlag();
      }, 4000);
    }
  };

  // Hides the flag
  hideFlag = () => {
    this.setState({ displayFlag: false });
  };

  componentDidUpdate(prevProps: Props) {
    const currentFormState = this.props.uiState.formState;

    // We only display a flag if the formState has changed and if it's due to a
    // completed or failed request.
    const prevFormState = prevProps.uiState.formState;

    const triggeringStates = this.props.ignoreCompleted ? ["failed"] : ["completed", "failed"];

    if (
      currentFormState !== prevFormState &&
      triggeringStates.indexOf(currentFormState) >= 0 &&
      this.props.uiState.message
    ) {
      this.showFlag();
    }
  }

  // Make sure we clear the timeout if we unmount the component.
  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { uiState } = this.props;
    const { displayFlag } = this.state;
    const flagShowClass = displayFlag ? "show" : "";
    return <Flag className={`${uiState.messageType} ${flagShowClass}`}>{uiState.message}</Flag>;
  }
}

export default FormFlag;
