//@flow

/**
  This file will load the workspace App. It should:
  1. Try to load the current workspace.
  2. Display a loader while loading.
  3. If workspace does not exist, it should say 404
  4. If workspace exists, use the routes
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { sessionOperations } from "state/ducks/session";
import { subscriptionsOperations } from "state/ducks/subscriptions";
import { goalSubscriptionsOperations } from "state/ducks/goalSubscriptions";
import moment from "moment";
import * as constants from "config/constants";

import { WorkspacePublicRoutes, WorkspacePrivateRoutes } from "v2/routes";
import Loader from "v2/components/Loader";

type Props = {
  currentUser: Object,
  fetchCurrentWorkspace: Function,
  fetchSubscriptionList: Function,
  fetchGoalSubscriptionList: Function,
  initApp: Function,
  intercom: Object,
  location: Object, // Router location object
  history: Object
};

type State = {
  workspaceIsLoaded: boolean // This is used to know if we have loaded the currentWorkspace
};

class App extends Component<Props, State> {
  state = {
    workspaceIsLoaded: false
  };

  constructor(props) {
    super(props);
    this.props.initApp();
  }

  componentDidMount = () => {
    const { fetchCurrentWorkspace, fetchSubscriptionList, fetchGoalSubscriptionList } = this.props;
    // First we fetch the current workspace data to make sure we're in the right place
    fetchCurrentWorkspace().then(() => {
      this.setState({
        workspaceIsLoaded: true
      });
    });
    fetchGoalSubscriptionList();
    fetchSubscriptionList();

    // TODO: MOVE THIS BELOW IN THE USER AUTHENTICATION PHASE
    const { currentUser, intercom } = this.props;

    if (currentUser) {
      // Shutdown first to clear the session
      window.Intercom("shutdown");
      window.Intercom("boot", {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: currentUser.id,
        name: currentUser.fullname, // Full name
        email: currentUser.email, // Email address
        created_at: currentUser.created_at.to_i,
        user_hash: intercom.user_hash
      });
    } else {
      window.Intercom("boot", {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID
      });
    }
  };

  componentDidUpdate(prevProps) {
    // TODO: MOVE THIS BELOW IN THE USER AUTHENTICATION PHASE
    const { currentUser, intercom, location } = this.props;
    const { currentUser: prevCurrentUser } = prevProps;
    // Only loads if we did not have the current user yet.
    if (!prevCurrentUser && currentUser) {
      // Shutdown first to clear the session
      window.Intercom("boot", {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: currentUser.id,
        name: currentUser.fullname, // Full name
        email: currentUser.email, // Email address
        created_at: moment(currentUser.created_at).unix(),
        user_hash: intercom.user_hash
      });
      window.wootricSettings = {
        email: currentUser.email, // TODO: Required to uniquely identify a user. Email is recommended but this can be any unique identifier.
        // external_id: 'abc123', // TODO: Reference field for external integrations only. Send it along with email. OPTIONAL
        created_at: moment(currentUser.created_at).unix(), // TODO: The current logged in user's sign-up date as a 10 digit Unix timestamp in seconds. OPTIONAL
        account_token: "NPS-b98d8d1b" // This is your unique account token.
      };
      if (window.wootric && !location.pathname.includes("/jira")) {
        window.wootric("run");
      }
    }
    if (intercom.refresh_intercom) {
      window.Intercom("update");
    }
  }

  render() {
    const { workspaceIsLoaded } = this.state;
    const { location } = this.props;

    if (workspaceIsLoaded) {
      // Render appropriate routes based on whether or not they require auth

      // If the path is one of the public ones, we use the Public Routes component
      if (constants.PUBLIC_PATHS.includes(location.pathname)) {
        return <WorkspacePublicRoutes />;
      }

      // If the path is one of the private ones, we use the private routes component
      return <WorkspacePrivateRoutes />;
    }

    // If you're here, it means that we're still loading the workspace
    return <Loader />;
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  intercom: state.session.intercom
});

const mapDispatchToProps = {
  initApp: sessionOperations.initApp,
  fetchCurrentWorkspace: sessionOperations.fetchCurrentWorkspace,
  fetchSubscriptionList: subscriptionsOperations.fetchSubscriptionList,
  fetchGoalSubscriptionList: goalSubscriptionsOperations.fetchGoalSubscriptionList
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
