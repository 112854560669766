// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// V2 Components
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x2};
`;

const Main = styled.div`
  .button {
    width: 20rem;
  }
`;

const List = styled.ul`
  li {
    margin: 2rem 0;
    a {
      display: block;
      padding: ${spacing.x2};
      border: 1px solid ${colors.blockBorder};
      border-radius: 8px;
      color: ${colors.black};
      font-weight: 600;
      small {
        display: block;
        font-weight: 500;
      }

      width: 30rem;

      &:hover {
        background: #f3f3f3;
      }
    }
  }
`;

type Props = {
  workspaces: squadTypes.NormalizedList<squadTypes.Workspace>
};

type State = {};

class Home extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const { workspaces } = this.props;
    return (
      <WorkspaceLayout>
        <Container>
          <Header>
            <h1>Your workspaces</h1>
          </Header>
          <Main>
            <Link to="/workspaces/new" className="button primary">
              Create a new workspace
            </Link>
            <List>
              {workspaces.allIds.map(workspaceId => {
                const workspace = workspaces.byId[workspaceId];
                return (
                  <li key={workspace.id}>
                    <a href={workspace.url}>
                      {workspace.name}
                      <small className="subtle">{workspace.url}</small>
                    </a>
                  </li>
                );
              })}
            </List>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    workspaces: state.workspaces
  };
};

export default connect(mapStateToProps)(Home);
