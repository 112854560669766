// @flow
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "state/utils";

/* State shape
{
    assetPostUrl: can be used to get a postUrl to upload files,
    uploadedAsset: URL of the last file uploaded
}
*/

// isAppLoaded is used for the initial loading state
const assetPostUrlReducer = createReducer(null)({
  [types.FETCH_ASSET_POST_URL_COMPLETED]: (state, action) => {
    return action.response.data;
  },
  [types.FETCH_ASSET_POST_URL_FAILED]: () => null
});

const uploadedAssetReducer = createReducer(null)({
  [types.UPLOAD_ASSET_COMPLETED]: (state, action) => {
    let url = null;

    // Apparently parseString looks like it's async but it's not. So this should
    // work. See https://github.com/Leonidas-from-XIV/node-xml2js/issues/159
    url = action.response.data.eager[0].secure_url;

    return { url };
  },
  [types.UPLOAD_ASSET_FAILED]: () => null
});

export default combineReducers({
  assetPostUrl: assetPostUrlReducer,
  uploadedAsset: uploadedAssetReducer
});
