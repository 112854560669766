//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 16 22" {...props}>
    <title id="Report-title">Report view</title>
    <g id="Report-Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g id="Report-Icon/report" transform="translate(-8.000000, -4.000000)" stroke="#6A6A6A" strokeWidth="2">
        <g id="Report-clipboard" transform="translate(9.000000, 5.000000)">
          <path
            d="M11.5,3 L12.25,3 C13.2164983,3 14,3.84568436 14,4.88888889 L14,18.1111111 C14,19.1543156 13.2164983,20 12.25,20 L1.75,20 C0.783501688,20 0,19.1543156 0,18.1111111 L0,4.88888889 C0,3.84568436 0.783501688,3 1.75,3 L2.5,3"
            id="Report-Shape"
            strokeLinejoin="round"
          ></path>
          <path
            d="M4.5,2 L5.0489502,2 L5.0489502,2 L5.0489502,1.99993896 C5.0489502,0.895403174 5.94435337,7.89923829e-14 7.04888916,7.68274333e-14 C8.15342495,7.66245334e-14 9.04882813,0.895403174 9.04882813,1.99993896 L9.04882813,2 L9.04882813,2 L9.5,2 C10.3284271,2 11,2.67157288 11,3.5 L11,4 C11,4.55228475 10.5522847,5 10,5 L4,5 C3.44771525,5 3,4.55228475 3,4 L3,3.5 C3,2.67157288 3.67157288,2 4.5,2 Z"
            id="Report-Rectangle-path"
            strokeLinejoin="round"
          ></path>
          <line x1="4" y1="9" x2="10" y2="9" id="Report-Path-3"></line>
          <line x1="4" y1="12" x2="10" y2="12" id="Report-Path-3-Copy"></line>
          <line x1="4" y1="15" x2="7" y2="15" id="Report-Path-3-Copy-2"></line>
        </g>
      </g>
    </g>
  </Logo>
);
