// @flow
import { createReducer } from "state/utils";
import * as searchedGoalsTypes from "state/ducks/searchedGoals/types";
import * as uiTypes from "./types";

const INITIAL_STATE = {
  isFetching: false,
  error: false
};

export default createReducer(INITIAL_STATE)({
  [searchedGoalsTypes.SEARCH_FAILED]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      error: true
    };
  },
  [searchedGoalsTypes.SEARCH_COMPLETED]: state => ({
    ...state,
    isFetching: false
  }),
  [searchedGoalsTypes.SEARCH_REQUESTED]: state => ({
    ...state,
    isFetching: true
  }),
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
