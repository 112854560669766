// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

// Styles helpers
import styled from "styled-components";
import { spacing } from "v2/components/styles/spacing";
import { devices } from "v2/components/styles/devices";

// Ducks stuff
import * as squadTypes from "squadTypes";
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";

// Utils
import { handleChange } from "v2/utils/forms";

// Library Components
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// Tability Components
import FormField from "v2/components/FormField";
import GoogleButtonIcon from "v2/components/_assets/google-button-icon.png";
import InlineMessage from "v2/components/InlineMessage";
import Loader from "v2/components/Loader";
import SessionLayout, { HelpLinks } from "v2/components/SessionLayout";
import SlackButtonIcon from "v2/components/_assets/slack-button-icon.png";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  .button {
    img {
      width: 2rem;
      margin-right: ${spacing.x1};
    }
  }
`;

const OAuthContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    flex: 1;

    &:first-child {
      margin-right: ${spacing.x1};
    }
    &:last-child {
      margin-left: ${spacing.x1};
    }
  }

  @media ${devices.mobile} {
    flex-direction: column;
    > div {
      margin-bottom: ${spacing.x2};

      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-left: 0;
      }
    }
  }
`;

const SSOContainer = styled.div`
  margin-bottom: ${spacing.x4};
`;

const Separator = styled.div`
  margin: ${spacing.x2} 0;
  border-bottom: 2px solid #d1d1d1;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 0.7rem;
  span {
    display: block;
    font-size: 1.4rem;
    line-height: 1.4rem;
    background-color: #fff;
    padding: 0 ${spacing.x2};
    position: relative;
    top: 0.7rem;
  }
`;

type Props = {
  checkCredentials: Function,
  currentWorkspace: squadTypes.Workspace,
  clearLogin: Function,
  login: Function,
  redirectAfterPath: string,
  resendConfirmationEmail: Function,
  ui: Object
};

type State = {
  email: string,
  password: string,
  otp_attempt: string
};

class Login extends Component<Props, State> {
  passwordInput: any;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      otp_attempt: ""
    };

    this.passwordInput = React.createRef();
  }

  componentDidMount() {
    // This will make sure that old state in the Redux store get cleared up.
    this.props.clearLogin();
    window.addEventListener("message", this.msgHandler);
    document.title = "Sign in | Tability";
  }

  // This message handler listens to messages sent by the Google Sign in iframe.
  // If a message === refresh-page then it means that the Google authentication was
  // successful and we will be logged in upon refresh as the session data will be
  // accessible (see how it's persisted in the root index.js file).
  msgHandler = e => {
    if (e.origin !== `https://app.${process.env.REACT_APP_DOMAIN || ""}`) {
      return;
    }
    if (e.data === "refresh-page") {
      window.location.reload();
    }
  };

  login = (e: Object) => {
    e.preventDefault();
    const { email, password, otp_attempt } = this.state;
    const credentials = {
      email,
      password,
      otp_attempt
    };
    this.props.login(credentials);
  };

  checkEmail = (e: Object) => {
    e.preventDefault();
    const { email } = this.state;
    this.props.checkCredentials(email).then(() => {
      this.passwordInput.current.focus();
    });
  };

  resendConfirmation = (e: Object) => {
    e.preventDefault();
    const { email } = this.state;
    const credentials = {
      email
    };

    this.props.resendConfirmationEmail(credentials);
  };

  handleChange = e => handleChange(this, e);

  render() {
    const domain = window.location.port
      ? `${window.location.hostname}:${window.location.port}`
      : `${window.location.hostname}`;

    const oauthState = {
      domain: `${domain}${this.props.redirectAfterPath}`
    };

    const state = btoa(JSON.stringify(oauthState));

    // Creating the OAuth URL for Slack
    const slackClientID = process.env.REACT_APP_SLACK_CLIENT_ID || "";
    const slackRedirectURL = `https://app.${process.env.REACT_APP_DOMAIN || ""}/slack/authenticate`;
    const slackOAuthURL =
      "https://slack.com/oauth/authorize" +
      "?scope=identity.basic,identity.email,identity.avatar" +
      `&client_id=${slackClientID}` +
      `&state=${state}` +
      `&redirect_uri=${encodeURIComponent(slackRedirectURL)}`;

    // Creating the OAuth URL for Google
    const googleClienttID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
    const googleScope = "email profile";
    const googleRedirectURL = `https://app.${process.env.REACT_APP_DOMAIN || ""}/google/authenticate`;
    const googleOauthURL =
      "https://accounts.google.com/o/oauth2/v2/auth" +
      `?scope=${encodeURIComponent(googleScope)}` +
      `&state=${state}` +
      `&client_id=${googleClienttID}` +
      `&response_type=code` +
      `&prompt=select_account` +
      `&redirect_uri=${encodeURIComponent(googleRedirectURL)}`;

    const { currentWorkspace, ui } = this.props;
    const { email, password, otp_attempt } = this.state;

    if (currentWorkspace && currentWorkspace.auth_type === "restricted.sso") {
      const authURL = process.env.REACT_APP_TABILITY_AUTH_URL || "";
      const saml_init_url = `https://${authURL}/saml/init/${currentWorkspace.slug}`;
      return (
        <Container>
          <Helmet>
            <script
              src="https://apis.google.com/js/client:platform.js?onload=start"
              async
              defer
              type="text/javascript"
            />
          </Helmet>
          <SessionLayout title="Log into your account">
            <SSOContainer>
              <a href={saml_init_url} className="button primary">
                Sign into {currentWorkspace.name}
              </a>
            </SSOContainer>
          </SessionLayout>
        </Container>
      );
    }

    return (
      <Container>
        <Helmet>
          <script src="https://apis.google.com/js/client:platform.js?onload=start" async defer type="text/javascript" />
        </Helmet>
        <SessionLayout title="Log into your account">
          <InlineMessage message={ui.message} messageType={ui.messageType} />
          {ui.needConfirmation && (
            <form onSubmit={this.resendConfirmation}>
              {!ui.isFetching && (
                <button className="primary">
                  <span>Resend confirmation email</span>
                </button>
              )}
              {ui.isFetching && (
                <button disabled className="primary">
                  <Loader size="small" />
                </button>
              )}
            </form>
          )}
          {!ui.needConfirmation && !ui.hasCheckedEmail && (
            <form onSubmit={this.checkEmail}>
              <OAuthContainer>
                <div>
                  <a href={googleOauthURL} className="button">
                    <img src={GoogleButtonIcon} alt="" />
                    <span>Sign in with Google</span>
                  </a>
                </div>
                <div>
                  <a href={slackOAuthURL} className="button">
                    <img src={SlackButtonIcon} alt="" />
                    <span>Sign in with Slack</span>
                  </a>
                </div>
              </OAuthContainer>
              <Separator>
                <span>OR</span>
              </Separator>
              <FormField>
                <input
                  className="form-control"
                  type="email"
                  aria-label="Enter your email"
                  autoComplete="off"
                  required
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </FormField>
              {!ui.isFetching && <button className="primary">Continue</button>}
              {ui.isFetching && (
                <button disabled className="primary">
                  <Loader size="small" />
                </button>
              )}
              <HelpLinks>
                <p>
                  Don't have an account? <Link to="/signup">Sign up</Link>
                </p>
                <p>
                  <Link to="/password/reset">Can't Log in?</Link>
                </p>
              </HelpLinks>
            </form>
          )}
          {!ui.needConfirmation && ui.hasCheckedEmail && (
            <form onSubmit={this.login}>
              <OAuthContainer>
                <div>
                  <a href={googleOauthURL} className="button long">
                    <img src={GoogleButtonIcon} alt="" />
                    <span>Sign in with Google</span>
                  </a>
                </div>
                <div>
                  <a href={slackOAuthURL} className="button long">
                    <img src={SlackButtonIcon} alt="" />
                    <span>Sign in with Slack</span>
                  </a>
                </div>
              </OAuthContainer>
              <Separator>
                <span>OR</span>
              </Separator>
              <FormField>
                <input
                  className="form-control"
                  type="email"
                  required
                  autoComplete="off"
                  placeholder="Email"
                  name="email"
                  aria-label="Enter your email"
                  value={email}
                  onChange={this.handleChange}
                />
              </FormField>
              <FormField>
                <input
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  name="password"
                  aria-label={`Enter the password for the email ${email}`}
                  value={password}
                  ref={this.passwordInput}
                  onChange={this.handleChange}
                />
              </FormField>
              {ui.has2FAEnabled && (
                <FormField>
                  <input
                    className="form-control"
                    type="text"
                    required
                    placeholder="Two-Factor Authentication Token"
                    aria-label="Enter your Two-Factor Authenticaion Token"
                    name="otp_attempt"
                    value={otp_attempt}
                    onChange={this.handleChange}
                  />
                </FormField>
              )}
              {!ui.isFetching && <button className="primary">Login</button>}
              {ui.isFetching && (
                <button disabled className="primary">
                  <Loader size="small" />
                </button>
              )}
              <HelpLinks>
                <p>
                  Don't have an account? <Link to="/signup">Sign up</Link>
                </p>
                <p>
                  <Link to="/password/reset">Can't Log in?</Link>
                </p>
              </HelpLinks>
            </form>
          )}
        </SessionLayout>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui.sessionLogin,
  redirectAfterPath: state.session.redirectAfterPath,
  currentWorkspace: state.session.currentWorkspace
});

const mapDispatchToProps = {
  checkCredentials: sessionOperations.checkCredentials,
  clearLogin: uiOperations.clearSessionLogin,
  login: sessionOperations.login,
  resendConfirmationEmail: sessionOperations.resendConfirmationEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
