//@flow
import { request } from "state/utils";
import * as types from "./types";

export const createCommentForGoal = (goalId: string, params: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/goals/${goalId}/comments`, { comment: params }),
    payload: { params }
  };
};

export const createCommentForStatusUpdate = (statusUpdateId: string, params: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/status_updates/${statusUpdateId}/comments`, { comment: params }),
    payload: { params }
  };
};

export const updateComment = (commentId: string, params: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/comments/${commentId}`, { comment: params }),
    payload: { params }
  };
};

export const deleteComment = (commentId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/comments/${commentId}`),
    payload: { id: commentId }
  };
};

export const fetchCommentListForGoal = (goalId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/goals/${goalId}/comments?page=${page}`)
  };
};

export const fetchCommentListForStatusUpdate = (statusUpdateId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/status_updates/${statusUpdateId}/comments?page=${page}`)
  };
};

export const fetchCommentDetails = (commentId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/comments/${commentId}`)
  };
};

export const resetCommentList = () => ({
  type: types.RESET_LIST
});
