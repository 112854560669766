// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import { sessionOperations } from "state/ducks/session";

// Styles helpers
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

// Components
import SessionLayout from "v2/components/SessionLayout";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  .button {
    img {
      width: 2rem;
      margin-right: ${spacing.x1};
    }
  }
`;

const SorryBox = styled.div`
  margin-bottom: ${spacing.x8};
  padding: ${spacing.x4};
  border-radius: 8px;
  background-color: ${colors.warningBg};
  font-size: 1.8rem;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.x2} 0;

  a,
  button {
    margin: ${spacing.x1};
  }
`;

type Props = {
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  logout: Function
};

type State = {};

class Join extends Component<Props, State> {
  logout = (e: Object) => {
    e.preventDefault();
    this.props.logout();
  };

  render() {
    const { currentUser, currentWorkspace } = this.props;
    return (
      <Container>
        <SessionLayout title={`Access forbidden to ${currentWorkspace.name}`}>
          <SorryBox>
            <p>Sorry, you need to be a member to have access to this workspace.</p>
            <p>You are logged in with the email {currentUser.email}.</p>
          </SorryBox>
          <Links>
            <a className="button primary" href={`https://app.${process.env.REACT_APP_DOMAIN || ""}`}>
              See all your workspaces
            </a>
            <button className="secondary" onClick={this.logout}>
              Logout
            </button>
          </Links>
        </SessionLayout>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  currentUser: state.session.currentUser
});

const mapDispatchToProps = {
  logout: sessionOperations.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Join);
