// @flow
import { createReducer } from "state/utils";
import * as workspacesTypes from "state/ducks/workspaces/types";
import * as integrationsTypes from "state/ducks/integrations/types";
import * as uiTypes from "./types";

const INITIAL_STATE = {
  error: false,
  isFetching: false,
  isUpdating: false,
  message: "",
  messageType: "warning",
  jiras: []
};

export default createReducer(INITIAL_STATE)({
  [workspacesTypes.FETCH_LIST_FAILED]: (state, action) => {
    const errors = action.error.response;
    return {
      ...state,
      error: true,
      isFetching: false,
      message: errors,
      messageType: "warning"
    };
  },
  [workspacesTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false
  }),
  [workspacesTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: ""
  }),
  [integrationsTypes.FETCH_DETAILS_FAILED]: (state, action) => {
    const errors = action.error.response;
    return {
      ...state,
      error: true,
      isFetching: false,
      message: errors,
      messageType: "warning"
    };
  },
  [integrationsTypes.FETCH_DETAILS_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false
  }),
  [integrationsTypes.FETCH_DETAILS_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: ""
  }),
  [integrationsTypes.UPDATE_FAILED]: (state, action) => {
    const errors = action.error.response.data;
    return {
      ...state,
      error: true,
      isUpdating: false,
      message: errors,
      messageType: "warning"
    };
  },
  [integrationsTypes.UPDATE_COMPLETED]: state => ({
    ...state,
    error: false,
    isUpdating: false,
    message: "Your configuration has been updated",
    messageType: "success"
  }),
  [integrationsTypes.UPDATE_REQUESTED]: state => ({
    ...state,
    error: false,
    isUpdating: true,
    message: ""
  }),
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
