import React, { Component } from "react";
import { connect } from "react-redux";
import { subscriptionsOperations, subscriptionsSelectors } from "state/ducks/subscriptions";
import * as squadTypes from "squadTypes";

import { spacing } from "v2/components/styles/spacing";

// Components
import ButtonIcon from "v2/components/ButtonIcon";
import IconEyeOn from "v2/components/svg/IconEyeOn";
import IconEyeOff from "v2/components/svg/IconEyeOff";
import ReactTooltip from "react-tooltip";

type Props = {
  className?: "string",
  createSubscription: Function,
  currentProject: squadTypes.Project,
  currentSubscription: ?squadTypes.Subscription,
  deleteSubscription: Function
};

class ProjectSubscription extends Component<Props> {
  toggleSubscription = e => {
    e.preventDefault();
    const { currentSubscription } = this.props;
    currentSubscription ? this.deleteSubscription() : this.createSubscription();
  };

  deleteSubscription = e => {
    const { currentSubscription } = this.props;
    if (currentSubscription) {
      this.props.deleteSubscription(currentSubscription.project_id, currentSubscription.id);
    }
  };

  createSubscription = e => {
    const { currentProject } = this.props;

    const subscriptionParams = {
      project_id: currentProject.id
    };
    this.props.createSubscription(subscriptionParams);
  };

  render() {
    const { currentSubscription } = this.props;

    return (
      <div className={this.props.className} style={{ marginRight: spacing.x1 }}>
        <ButtonIcon data-tip data-for="subscriptionTip" className="subscription" onClick={this.toggleSubscription}>
          {currentSubscription ? <IconEyeOn /> : <IconEyeOff />}
        </ButtonIcon>
        <ReactTooltip id="subscriptionTip" place="bottom" type="dark" effect="solid" delayShow={200}>
          {currentSubscription ? <span>You’re watching this page</span> : <span>You are not watching this page</span>}
        </ReactTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentSubscription: subscriptionsSelectors.getListByParam(
    state.subscriptions.byId,
    "project_id",
    props.currentProject.id
  )[0]
});

const mapDispatchToProps = {
  createSubscription: subscriptionsOperations.createSubscription,
  deleteSubscription: subscriptionsOperations.deleteSubscription
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSubscription);
