import { createGlobalStyle } from "styled-components";
import { colors } from "./styles/colors";
import { devices } from "./styles/devices";
import { spacing } from "./styles/spacing";

const GlobalStyles = createGlobalStyle`
  body, html, #root {
    height: 100%;
    min-height: 100%;

  }

  html {
    font-size: 62.5%;
  }

  body {
    background-color: #fff;
  
    color: ${colors.black};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 1.6rem;
    line-height: 1.4;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  h1 {
    font-size: 2.8rem;
    font-weight: 500;
    @media ${devices.tablet} {
      font-size: 2.4rem;
    }
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 500;
    @media ${devices.tablet} {
      font-size: 1.8rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  a, .link-button { 
    color: ${colors.blue};
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    text-align: left;
    font: inherit;
    height: initial;
    line-height: 1.4;

    &:hover {
      background: transparent;
      color: #203287;
      
    }
    
    &:active {
      filter: brightness(80%);
      border: none;
    }
    
  }

  .subtle, a.subtle {
    color: #6A6A6A;
  }

  .label {
    display: block;
    font-weight: 600;
    color: ${colors.subtleText};
    font-size: 1.2rem;
    margin-bottom: ${spacing.x1};
  }

  a.subtle:hover {
    color: #6A6A6A;
    filter: brightness(80%);
  }

  p {
    margin-bottom: ${spacing.x2};
  }

  small {
    font-size: 1.2rem;
  }

  input[type="text"],
  input[type="url"],
  input[type="email"],
  input[type="number"],
  input[type="password"]
  {
    border: 1px solid #949494;
    border-radius: 4px;
    color: ${colors.black};
    background: #fff;
    height: 3.8rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 1.4rem;
    line-height: 1.4rem;
    padding: 0 ${spacing.x2};
    outline: 0;
    transition: border 0.2s ease;

    &::placeholder {
      color: #949494;
    }

    &:focus {
      border: 2px solid #324DD3;
    }

    &.compact {
      font-size: 1.4rem;
      font-weight: 400;
      height: 3.2rem;
      line-height: 3.2rem;
      padding: 0 0.8rem;
    }
  }

  select {
    border: 1px solid #949494;
    border-radius: 4px;
    color: ${colors.black};
    background: #fff;
    height: 3.8rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 1.4rem;
    line-height: 1.4rem;
    padding: 0 ${spacing.x2};
    outline: 0;
    transition: border 0.2s ease;

    &:focus {
      border: 2px solid #324DD3;
    }
  }

  button, .button {
    outline: none;
    background-color: #F8F8F8;
    border: 1px solid #949494;
    border-radius: 3px;
    color: #6A6A6A;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 3.8rem;
    height: 3.8rem;
    padding: 0 ${spacing.x2};
    transition: all 0.2s ease;
    white-space: nowrap;
    text-decoration: none;


    &:hover, &:focus {
      background: #E9E9E9;
      text-decoration: none;
    }

    
    &:active {
      filter: brightness(85%);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.primary {
      background-color: ${colors.blue};
      border: 0;
      color: #fff;

      &:hover, &:focus{
        background-color: #203287;
      }
    }

    &.danger-primary {
      background-color: ${colors.red};
      border: 0;
      color: #fff;

      &:hover, &:focus{
        background-color: #D0402E;
      }
    }

    &.secondary {
      text-decoration: underline;
      color: ${colors.blue};
      border: 0;
      background-color: transparent;

      &:hover, &:focus{
        color: #203287;
      }

      &:focus {
        border: 2px solid ${colors.blue};
      }

      &:active {
        filter: brightness(80%);
      }
    }
  }

  /* MOBILE STUFF */

  .hide-tablet {
    @media ${devices.tablet} {
      display: none;
    }
  }

  .hide-laptop {
    @media ${devices.laptop} {
      display: none;
    }
  }

  .hide-mobile {
    @media ${devices.mobile} {
      display: none;
    }
  }

  .show-tablet {
    display: none;
    @media ${devices.tablet} {
      display: block;
    }
  }

 
`;

export default GlobalStyles;
