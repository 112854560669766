// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";

import { spacing } from "v2/components/styles/spacing";

import GoalBlockPage from "v2/components/GoalBlockPage";
import IconCornerDown from "v2/components/svg/IconCornerDown";
import Loader from "v2/components/Loader";

// Components

const Container = styled.div`
  h3 {
    margin-top: ${spacing.x4}:;
  }
  ul {
    margin-left: 3.7rem;
    &.level-0 {
      margin-left: 0;
    }
    &.level-1 {
      margin-left: ${spacing.x3};
    }
    li {
      .line {
        display: flex;
        align-items: center;
        > div {
          flex: 1;
        }
        svg {
          margin-right: ${spacing.x1};
        }
      }
    }
  }

  > ul {
    margin-left: 0;
    margin-top: ${spacing.x2};
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      height: 1.3rem;
    }
  }
`;

type Props = {
  goal: squadTypes.Goal,
  hideForm: Function
};

type State = {
  goals: Object,
  isLoading: boolean
};

class GoalForm extends Component<Props, State> {
  state = {
    goals: {
      root: [this.props.goal]
    },
    isLoading: false
  };

  // Look for the children of a goal
  getChildrenGoal = (goal: squadTypes.Goal) => {
    const slug = getSubdomain() || "";
    if (goal && goal.count_of_children_goals > 0) {
      this.setState({
        isLoading: true
      });
      request.post(`/workspaces/${slug}/goals`, { parent_goal: goal.id }).then(response => {
        const children = response.data;

        this.setState({
          goals: {
            ...this.state.goals,
            [goal.id]: children
          },
          isLoading: false
        });

        //children.forEach(child => {
        //  this.getChildrenGoal(child);
        //});
      });
    }
  };

  // Look for the parent of a goal
  getParentGoal = (goal: squadTypes.Goal) => {
    if (goal && goal.parent_goal_id) {
      this.setState({
        isLoading: true
      });

      request.get(`/goals/${goal.parent_goal_id}`).then(response => {
        const parent = response.data;

        this.setState({
          goals: {
            ...this.state.goals,
            root: [parent],
            [parent.id]: [goal]
          },
          isLoading: false
        });
      });
    }
  };

  componentDidMount() {
    if (this.props.goal.parent_goal_id) {
      this.getParentGoal(this.props.goal);
    }
    this.getChildrenGoal(this.props.goal);
  }

  componentDidUpdate(prevProps) {
    if (this.props.goal.id !== prevProps.goal.id) {
      if (this.props.goal.parent_goal_id) {
        this.getParentGoal(this.props.goal);
      }
      this.getChildrenGoal(this.props.goal);
    }
  }

  handleEscPress = e => {
    if (e.keyCode === 27) {
      this.props.hideForm(e);
    }
  };

  handleClose = e => {
    e.preventDefault();
    this.props.hideForm(e);
  };

  renderGoals = (parentID: string, level = 0) => {
    const { goals } = this.state;
    const { goal } = this.props;
    const goalsToDisplay = goals[parentID];

    if (goalsToDisplay) {
      return (
        <ul className={`level-${level}`}>
          {goalsToDisplay.map(goalToDisplay => {
            const isSelected = goal.id === goalToDisplay.id;
            return (
              <li key={goalToDisplay.id}>
                <div className="line">
                  {level > 0 && <IconCornerDown />}
                  <GoalBlockPage className="compact" goal={goalToDisplay} isSelected={isSelected} maskTasks={true} />
                </div>
                {this.renderGoals(goalToDisplay.id, level + 1)}
              </li>
            );
          })}
        </ul>
      );
    }
  };

  render() {
    const { isLoading } = this.state;
    const { goal } = this.props;
    // Display nothing if we don't have a parent or children;
    if (goal.count_of_children_goals === 0 && !goal.parent_goal_id) {
      return null;
    }
    if (isLoading) {
      return (
        <Container>
          <Loader size="small" />
        </Container>
      );
    }
    return <Container>{this.renderGoals("root")}</Container>;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GoalForm);
