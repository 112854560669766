//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 10 10" {...props}>
    <g id="New-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="All-Goals-Copy-2"
        transform="translate(-932.000000, -236.000000)"
        fill={props.color || "#F24833"}
        fillRule="nonzero"
      >
        <g id="Notify" transform="translate(932.000000, 236.000000)">
          <circle id="Oval-Copy-3" cx="5" cy="5" r="5" />
        </g>
      </g>
    </g>
  </Logo>
);
