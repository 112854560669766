// @flow
import { createReducer } from "state/utils";
import * as projectsTypes from "state/ducks/projects/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  errorFields: {},
  formState: "pending",
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [projectsTypes.UPDATE_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [projectsTypes.UPDATE_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "",
      messageType: "success"
    };
  },
  [projectsTypes.UPDATE_FAILED]: (state, action) => {
    const errors = action.error.response.data;
    const errorFields = {};
    if (errors["text"]) {
      errorFields["text"] = `The text ${errors["text"][0]}`;
    }
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not update your project",
      messageType: "warning"
    };
  },
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE,
  [uiTypes.SET_ERROR_FIELDS]: (state, action) => {
    return {
      ...state,
      errorFields: action.errorFields
    };
  }
});
