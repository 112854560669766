//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.4rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 15 17" {...props}>
    <g id="UI-Ready" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="New-Goals-Panel-Copy-7" transform="translate(-387.000000, -745.000000)">
        <g id="Link-actions" transform="translate(376.000000, 736.000000)">
          <g id="Icons-Copy" transform="translate(3.000000, 2.000000)">
            <g id="Group-3" />
          </g>
          <g
            id="trash-2"
            transform="translate(12.000000, 10.000000)"
            stroke="#6A6A6A"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g id="Shape">
              <polyline strokeWidth="2" points="0 2.88888889 1.44444444 2.88888889 13 2.88888889" />
              <path
                d="M12,3 L12,13.5 C12,14.3284271 11.2964475,15 10.4285714,15 L2.57142857,15 C1.70355254,15 1,14.3284271 1,13.5 L1,3 M4,2 L4,1 C4,0.44771525 4.55964406,0 5.25,0 L7.75,0 C8.44035594,0 9,0.44771525 9,1 L9,2"
                strokeWidth="2"
              />
              <path d="M5,7 L5,11.3333333" strokeWidth="2" />
              <path d="M8,7 L8,11.3333333" strokeWidth="2" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
