// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { commentsOperations } from "state/ducks/comments";
import { push } from "react-router-redux";
import moment from "moment";

// Components
import GoalCommentCard from "./GoalCommentCard";
import CommentCard from "./CommentCard";
import CommentForm from "./CommentForm";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";
import Loader from "v2/components/Loader";

import { spacing } from "v2/components/styles/spacing";

const Container = styled.div``;

const CommentsWrapper = styled.div`
  margin-top: ${spacing.x1};
  padding: ${spacing.x1} 0 0 5.8rem;
`;

type Props = {
  comments: squadTypes.NormalizedList<squadTypes.Comment>,
  createCommentForGoal: Function,
  goal: squadTypes.Goal,
  hideForm: Function,
  fetchCommentListForGoal: Function,
  push: Function,
  resetCommentList: Function,
  ui: Object
};

type State = {
  isCommentBeingEdited: Object
};

class GoalComments extends Component<Props, State> {
  state = {
    isCommentBeingEdited: {}
  };

  componentDidMount() {
    const { goal, fetchCommentListForGoal, resetCommentList } = this.props;
    resetCommentList();
    fetchCommentListForGoal(goal.id);
  }

  hideEditForm = (commentId: string) => {
    const { isCommentBeingEdited } = this.state;
    isCommentBeingEdited[commentId] = false;
    this.setState({
      isCommentBeingEdited
    });
  };

  showEditForm = (commentId: string) => {
    const { isCommentBeingEdited } = this.state;
    isCommentBeingEdited[commentId] = true;
    this.setState({
      isCommentBeingEdited
    });
  };

  _hideForm = e => {
    const { goal } = this.props;
    this.props.push(`/pages/${goal.project_id}/${goal.id}`);
    this.props.hideForm(e);
  };

  render() {
    const { comments, goal, ui } = this.props;
    const { isCommentBeingEdited } = this.state;
    return (
      <Container className="modal-wrapper-no-footer">
        <div className="modal-header">
          <h2>{goal.title}</h2>
          <ButtonIcon onClick={this._hideForm}>
            <IconClose style={{ width: "1.2rem" }} />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <div className="label">{moment(goal.created_at).format("D MMM YYYY")}</div>
          <GoalCommentCard goal={goal} />
          {ui.isFetchingComments ? (
            <CommentsWrapper>
              <Loader size="small" />
            </CommentsWrapper>
          ) : (
            <CommentsWrapper>
              {comments.allIds.map(commentId => {
                const comment = comments.byId[commentId];
                if (!comment) {
                  return false;
                }

                if (isCommentBeingEdited[commentId]) {
                  return <CommentForm key={comment.id} comment={comment} hideEditForm={this.hideEditForm} />;
                } else {
                  return <CommentCard key={comment.id} comment={comment} showEditForm={this.showEditForm} />;
                }
              })}
              <CommentForm commentable={goal} createComment={this.props.createCommentForGoal} />
            </CommentsWrapper>
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  comments: state.comments,
  ui: state.ui.statusUpdateComments
});

const mapDispatchToProps = {
  createCommentForGoal: commentsOperations.createCommentForGoal,
  fetchCommentListForGoal: commentsOperations.fetchCommentListForGoal,
  push,
  resetCommentList: commentsOperations.resetCommentList
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalComments);
