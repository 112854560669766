// @flow
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import { withSignedInRedirect, withWorkspaceCheck } from "v2/routes/wrappers";

import * as pages from "v2/pages";
import NotFound from "v2/components/NotFound";

const routes = [
  {
    path: "/login",
    component: withWorkspaceCheck(withSignedInRedirect(pages.SessionLogin)),
    exact: true
  },
  {
    path: "/saml/authenticate",
    component: withSignedInRedirect(pages.SamlAuthenticate),
    exact: true
  },
  {
    path: "/signup",
    component: withWorkspaceCheck(withSignedInRedirect(pages.SessionSignup)),
    exact: true
  },
  {
    path: "/confirm",
    component: withWorkspaceCheck(pages.SessionConfirm),
    exact: true
  },
  {
    path: "/unsubscribe",
    component: withWorkspaceCheck(pages.Unsubscribe),
    exact: true
  },
  {
    path: "/password/reset",
    component: withWorkspaceCheck(withSignedInRedirect(pages.SessionResetPassword)),
    exact: true
  },
  {
    path: "/password/new",
    component: withWorkspaceCheck(withSignedInRedirect(pages.SessionNewPassword)),
    exact: true
  },
  {
    component: NotFound
  }
];

type Props = {};

class WorkspacePublicRoutes extends Component<Props> {
  // This class doesn't need to fetch the currentWorkspace because it should have been done already in _workspaceApps
  // We're also assuming that the user is not logged in.

  // We can safely render the routes directly

  render() {
    return (
      <Switch>
        {routes.map(route => (
          <Route key={route.path || "notfound"} {...route} />
        ))}
      </Switch>
    );
  }
}

export default WorkspacePublicRoutes;
