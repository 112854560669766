// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { accountOperations } from "state/ducks/account";
import { sessionOperations } from "state/ducks/session";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import AccountSettingsNav from "v2/components/AccountSettingsNav";
import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm } from "redux-form";
import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: ${colors.blockBorder};
  }
`;

type Props = {
  currentUser: squadTypes.User,
  fetchAccountDetails: Function,
  handleSubmit: Function,
  ui: Object,
  uiSession: Object,
  resetPassword: Function,
  updatePassword: Function,
  uiSession: Object
};

class AccountSettingsPassword extends Component<Props> {
  // No need to fetch the currentUser on componentDidMount because it's fetched
  // in one of the routes wrapper.

  updatePassword = passwordValues => {
    this.props.updatePassword(passwordValues);
  };

  resetPassword = e => {
    e.preventDefault();
    const { currentUser } = this.props;
    this.props.resetPassword({ email: currentUser.email });
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  render() {
    const { currentUser, handleSubmit, ui, uiSession } = this.props;
    const { errorFields } = ui;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Update";

    return (
      <WorkspaceLayout secondNav={<AccountSettingsNav user={currentUser} />}>
        <Container>
          <Header>
            <h1>Change your password</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <form onSubmit={handleSubmit(this.updatePassword)}>
              <FormField>
                <label htmlFor="current_password">Current password</label>
                <Field
                  component="input"
                  type="password"
                  className={`small ${this.getErrorClass("current_password")}`}
                  name="current_password"
                />
                <span className={`${this.getErrorClass("current_password")}`}>
                  <small>{errorFields["current_password"]}</small>
                </span>
              </FormField>
              <FormField>
                <label htmlFor="password">Password</label>
                <Field
                  component="input"
                  type="password"
                  className={`small ${this.getErrorClass("password")}`}
                  name="password"
                />
                <span className={`${this.getErrorClass("password")}`}>
                  <small>{errorFields["password"]}</small>
                </span>
              </FormField>
              <FormField>
                <label htmlFor="password_confirmation">Password confirmation</label>
                <Field
                  component="input"
                  type="password"
                  className={`small ${this.getErrorClass("password_confirmation")}`}
                  name="password_confirmation"
                />
                <span className={`${this.getErrorClass("password_confirmation")}`}>
                  <small>{errorFields["password_confirmation"]}</small>
                </span>
              </FormField>
              <FormActions>
                <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                  {submitText}
                </button>
                <button disabled={uiSession.isFetching} onClick={this.resetPassword}>
                  {uiSession.isFetching && <Loader size="small" />}
                  {!uiSession.isFetching && <span>Reset</span>}
                </button>
              </FormActions>
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  uiSession: state.ui.sessionResetPassword,
  ui: state.ui.accountSettingsPassword
});

const mapDispatchToProps = {
  updatePassword: accountOperations.updatePassword,
  resetPassword: sessionOperations.resetPassword
};

const _AccountSettingsPassword = reduxForm({
  form: "accountSettingsPassword"
})(AccountSettingsPassword);

export default connect(mapStateToProps, mapDispatchToProps)(_AccountSettingsPassword);
