// @flow
/**
 * This page is used to perform Slack authentication and redirect to the right domain
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { integrationsOperations, integrationsSelectors } from "state/ducks/integrations";
import * as squadTypes from "squadTypes";

// Components
import Loader from "v2/components/Loader";
import Goals from "./Goals";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 0;
  letter-spacing: -0.16px;

  h2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.857143em;
    font-style: inherit;
    font-weight: 600;
    margin-top: 20px;
    text-transform: uppercase;
    color: rgb(94, 108, 132);
    line-height: 12px;
    overflow: hidden;
    margin-bottom: 16px;
  }

  form {
    max-width: 500px;
  }

  button {
    margin-bottom: 10px;
  }
`;

type Props = {
  currentIntegration: squadTypes.Integration,
  currentWorkspace: squadTypes.Workspace,
  location: Object,
  ui: Object,
  fetchJiraConnectGoals: Function,
  fetchJiraDetails: Function
};

class JiraConnectSettings extends Component<Props> {
  componentDidMount() {
    const { fetchJiraDetails, currentWorkspace } = this.props;
    fetchJiraDetails(currentWorkspace.slug);
  }

  render() {
    const { currentIntegration } = this.props;

    const jiraConnectAddonKey = process.env.REACT_APP_JIRA_CONNECT_ADDON_KEY || "";
    if (currentIntegration && !currentIntegration.workspace_id) {
      const jiraInstance = currentIntegration.public_meta.instance;
      return (
        <Container>
          <p>Your instance needs to be associated with a Tability workspace.</p>
          <a
            href={`${jiraInstance}/plugins/servlet/ac/${jiraConnectAddonKey}/configure`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Configure Tability
          </a>
        </Container>
      );
    }

    if (currentIntegration && currentIntegration.workspace_id) {
      return <Goals currentIntegration={currentIntegration} />;
    }

    return (
      <Container>
        <Loader />
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  let currentIntegration = integrationsSelectors.getListByParam(
    state.integrations.byId,
    "integration_type",
    "jira_cloud"
  )[0];
  return {
    currentIntegration,
    currentWorkspace: state.session.currentWorkspace,
    ui: state.ui.jiraConnectGoals
  };
};

const mapDispatchToProps = {
  fetchJiraDetails: integrationsOperations.fetchJiraDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(JiraConnectSettings);
