//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 24 24" {...props}>
    <title id="Unsubscribed-title">Unsubscribed</title>
    <g
      id="Unsubscribed-Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Unsubscribed-eye-off" transform="translate(1.000000, 1.000000)" stroke="#6A6A6A" strokeWidth="2">
        <path
          d="M16.94,16.94 C15.23059,18.2430022 13.1491075,18.9648632 11,19 C4,19 0,11 0,11 C1.2438899,8.68189567 2.96913545,6.65660741 5.06,5.06 M8.9,3.24 C9.5883323,3.07887951 10.2930641,2.99833874 11,3 C18,3 22,11 22,11 C21.3929796,12.1356074 20.6690554,13.2047361 19.84,14.19 M13.12,13.12 C12.3721177,13.9226127 11.2457778,14.2529967 10.1828444,13.9815422 C9.1199109,13.7100876 8.28991239,12.8800891 8.01845783,11.8171556 C7.74700327,10.7542222 8.07738729,9.62788232 8.88,8.88"
          id="Unsubscribed-Shape"
        />
        <path d="M0,0 L22,22" id="Unsubscribed-Shape" />
      </g>
    </g>
  </Logo>
);
