//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 17 17" {...props}>
    <g
      id="Buttons-and-Forms"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Update-Card-Actions---counter-and-active-Copy-2"
        transform="translate(-9.000000, -9.000000)"
        stroke="#6A6A6A"
        strokeWidth="2"
      >
        <g id="Group" transform="translate(2.000000, 2.000000)">
          <g id="thumbs-up" transform="translate(8.000000, 8.000000)">
            <path
              d="M9,5.25 L9,2.25 C9,1.00735931 7.99264069,-4.4408921e-16 6.75,-4.4408921e-16 L3.75,6.75 L3.75,15.0000972 L12.21,15.0000972 C12.9579651,15.0084558 13.5978459,14.4645571 13.71,13.725 L14.745,6.975 C14.811042,6.53989149 14.6826729,6.0977316 14.3938939,5.76563575 C14.1051149,5.43353991 13.6850638,5.24501652 13.245,5.25 L9,5.25 Z M3.75,15 L1.5,15 C0.671572875,15 0,14.3284271 0,13.5 L0,8.25 C0,7.42157288 0.671572875,6.75 1.5,6.75 L3.75,6.75 L3.75,15 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
