// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

// Components
import FormField from "v2/components/FormField";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";

// Search Components
import GoalSearch from "./_quicksearch/GoalSearch";
import MembershipSearch from "./_quicksearch/MembershipSearch";
import PageSearch from "./_quicksearch/PageSearch";
import RecentlyVisited from "./_quicksearch/RecentlyVisited";

const Container = styled.div`
  .results {
    > div {
      margin-top: ${spacing.x2};
    }
  }
  .no-results {
    margin: ${spacing.x1} 0;
    a {
      color: ${colors.subtleText};
    }
  }
  li {
    a {
      display: flex;
      align-items: center;
      padding: ${spacing.x1} ${spacing.x2};
      border-radius: 4px;

      &:hover,
      &:focus {
        background: #f3f3f3;
      }

      svg {
        height: 1.6rem;
        margin-right: ${spacing.x1};
        g {
          stroke: #8089a2;
        }
      }

      &.flex-start {
        align-items: flex-start;

        svg {
          position: relative;
          top: 2px;
        }
      }
    }
  }

  form {
    width: 70%;
  }
`;

type Props = {
  hideForm: Function
};

type State = {
  query: ?string
};

class QuickSearch extends Component<Props, State> {
  searchInput: any;
  state = {
    query: ""
  };

  componentDidMount() {
    if (this.searchInput) {
      this.searchInput.focus();
    }
  }

  _handleChange = (e: any) => {
    const query = e.target.value;
    this.setState({
      query
    });
  };

  render() {
    const { query } = this.state;
    // Only start querying results if we have 2+ chars in the query
    const displayResults = query && query.length > 1;
    return (
      <Container className="modal-wrapper-no-footer">
        <div className="modal-header">
          <h2>Quick search</h2>
          <ButtonIcon onClick={this.props.hideForm}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <form>
            <FormField>
              <input
                type="text"
                placeholder="Search Tability"
                ref={input => (this.searchInput = input)}
                value={query}
                onChange={this._handleChange}
              />
            </FormField>
          </form>
          {!displayResults && <RecentlyVisited hideForm={this.props.hideForm} />}
          {displayResults && (
            <div className="results">
              <PageSearch query={query} hideForm={this.props.hideForm} />
              <GoalSearch query={query} hideForm={this.props.hideForm} />
              <MembershipSearch query={query} hideForm={this.props.hideForm} />
            </div>
          )}
        </div>
      </Container>
    );
  }
}

export default QuickSearch;
