// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { integrationsOperations, integrationsSelectors } from "state/ducks/integrations";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

import SlackIcon from "./_assets/IconSlack.png";
import JiraIcon from "./_assets/IconJira.png";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormFlag from "v2/components/FormFlag";

import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  form {
    max-width: 50rem;
  }

  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

const IntegrationsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IntegrationBlock = styled.div`
  margin-bottom: ${spacing.x3};
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: ${spacing.x3};
  display: flex;
`;

const IntegrationAction = styled.div`
  width: 20rem;
  header {
    text-align: center;
    h3 {
      margin-bottom: ${spacing.x2};
      position: relative;
      span {
        display: inline-block;
        font-size: 1.2rem;
        background-color: ${colors.yellow};
        border-radius: 3px;
        padding: 0.2rem 0.8rem;
        line-height: 1.6rem;
        position: relative;
        right: 0;
        top: -0.2rem;
      }
    }
    margin-bottom: ${spacing.x3};
  }
  display: flex;
  flex-direction: column;
  img {
    width: 80px;
  }
  .connection-details {
    text-align: center;
  }
`;

const IntegrationDescription = styled.div`
  .sorry {
    border-radius: 4px;
    padding: ${spacing.x2};
    background-color: ${colors.warningBg};
  }

  ul {
    list-style-type: disc;
    margin-left: ${spacing.x6};
    margin-bottom: ${spacing.x2};
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  ui: Object,
  jiraUninstall: Function,
  fetchIntegrationList: Function,
  currentIntegration: Object,
  slackUninstall: Function
};

class WorkspaceSettingsIntegrations extends Component<Props> {
  _slackUninstall = (e: Object) => {
    e.preventDefault();
    this.props.slackUninstall();
  };

  _jiraUninstall = (e: Object) => {
    e.preventDefault();
    this.props.jiraUninstall();
  };

  componentDidMount() {
    this.props.fetchIntegrationList();
  }

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentWorkspace, currentIntegration, ui } = this.props;

    // tabilityRedirect is the redirection to use after the app is installed
    const tabilityRedirect = `${currentWorkspace.url}/settings/integrations`;
    const oauthState = {
      redirect_after: tabilityRedirect,
      slug: currentWorkspace.slug
    };

    const state = btoa(JSON.stringify(oauthState));

    // Creating the OAuth URL for Slack
    const slackClientID = process.env.REACT_APP_SLACK_CLIENT_ID || "";
    const slackRedirectURL = `https://app.${process.env.REACT_APP_DOMAIN || ""}/slack/install`;

    let slackOAuthURL;
    slackOAuthURL =
      "https://slack.com/oauth/v2/authorize" +
      "?scope=channels:read,channels:join,chat:write,commands,dnd:read,groups:read,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,team:read,users:read,users:read.email,users:write" +
      "&user_scope=links:read,links:write,users:read,users:read.email" +
      `&client_id=${slackClientID}` +
      `&state=${state}` +
      `&redirect_uri=${encodeURIComponent(slackRedirectURL)}`;

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>Integrations</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <IntegrationsContainer>
              <IntegrationBlock>
                <IntegrationAction>
                  <header>
                    <h3>Slack</h3>
                    <img src={SlackIcon} alt="Slack" />
                  </header>
                  {!currentWorkspace.slack_team_name && (
                    <a href={slackOAuthURL} className="button primary">
                      Connect to Slack
                    </a>
                  )}
                  {currentWorkspace.slack_team_name && (
                    <div className="connection-details">
                      Connected to <b>{currentWorkspace.slack_team_name}</b> on Slack.
                      <br />
                      <button className="secondary" onClick={this._slackUninstall}>
                        Uninstall
                      </button>
                    </div>
                  )}
                </IntegrationAction>
                <IntegrationDescription>
                  <p>Connect your workspace to Slack to get more out of Tability.</p>
                  <ul>
                    <li>Our bot Tabby will send reminders to your teammates in Slack.</li>
                    <li>Get weekly reminders and progress reports in a Slack channel of your choice for your pages.</li>
                    <li>See previews for your pages and goals when sharing links on Slack.</li>
                  </ul>
                </IntegrationDescription>
              </IntegrationBlock>
              <IntegrationBlock>
                <IntegrationAction>
                  <header>
                    <h3>Jira Cloud</h3>
                    <img src={JiraIcon} alt="Jira Cloud" />
                  </header>
                  {!currentIntegration && (
                    <a
                      href="https://marketplace.atlassian.com/apps/1221545/tability-for-jira"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button primary"
                    >
                      Add Tability to Jira
                    </a>
                  )}
                  {currentIntegration && (
                    <div className="connection-details">
                      Connected to <b>{currentIntegration.public_meta.instance}</b>
                    </div>
                  )}
                </IntegrationAction>
                <IntegrationDescription>
                  <p>
                    Connect your workspace to your Jira Cloud instance to link goals in Tability to your tasks and
                    issues in Jira.
                  </p>
                  <ul>
                    <li>Link all the tasks, issues and epics that will contribute to your goals.</li>
                    <li>See all the goals associated to your issues in Jira.</li>
                    <li>Statuses are always kept in sync — no need to switch context.</li>
                  </ul>
                </IntegrationDescription>
              </IntegrationBlock>
              {currentWorkspace.jira_team_name && (
                <IntegrationBlock>
                  <IntegrationAction>
                    <header>
                      <h3>
                        Jira Cloud <span>Deprecated</span>
                      </h3>
                      <img src={JiraIcon} alt="Slack" />
                    </header>
                    {currentWorkspace.jira_team_name && (
                      <div className="connection-details">
                        Connected via https://<b>{currentWorkspace.jira_team_name}</b>.atlassian.net
                        <br />
                        <button className="secondary" onClick={this._jiraUninstall}>
                          Uninstall
                        </button>
                      </div>
                    )}
                  </IntegrationAction>
                  <IntegrationDescription>
                    <p className="warning">This integration is deprecated</p>
                    <p>
                      We encourage you to uninstall this integration and use the newer one listed above. You will get a
                      richer, improve experience.
                    </p>
                  </IntegrationDescription>
                </IntegrationBlock>
              )}
            </IntegrationsContainer>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => {
  const currentWorkspace = state.session.currentWorkspace;
  let currentIntegration = null;
  currentIntegration = integrationsSelectors.getListByParam(
    state.integrations.byId,
    "workspace_id",
    currentWorkspace.id
  )[0];
  return {
    currentWorkspace,
    ui: state.ui.workspaceSettingsNotifications,
    currentIntegration
  };
};

const mapDispatchToProps = {
  fetchIntegrationList: integrationsOperations.fetchIntegrationList,
  slackUninstall: integrationsOperations.slackUninstall,
  jiraUninstall: integrationsOperations.jiraUninstall
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettingsIntegrations);
