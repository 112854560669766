// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { membershipsOperations } from "state/ducks/memberships";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// V2 Components
import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

import Avatar from "v2/components/Avatar";
import ReactTooltip from "react-tooltip";

const Container = styled.div`
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.x2};

  @media ${devices.tablet} {
    flex-direction: column;
  }
`;

const HeaderLeft = styled.div`
  .user-profile {
    display: flex;
    align-items: center;
    margin-top: ${spacing.x1};

    a {
      margin-right: ${spacing.x1};
    }
  }
`;

const HeaderRight = styled.div`
  @media ${devices.tablet} {
    margin-top: ${spacing.x1};
  }
`;

const Content = styled.div``;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: normal;
  line-height: normal;
  font-weight: normal;

  th {
    color: ${colors.subtleText};
  }

  th,
  td {
    border: 0;
    border-collapse: collapse;
    text-align: left;
    margin: 0;
    padding: ${spacing.x1};
    vertical-align: middle;

    &.ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
    }

    ion-icon {
      font-size: 1.8rem;
    }

    &.center {
      text-align: center;
    }
  }

  td {
    big a {
      font-size: 1.8rem;
    }
  }

  tr {
    &.top td {
      vertical-align: top !important;
    }
  }

  .actions {
    min-width: 200px;
    text-align: right;
    white-space: nowrap;

    button,
    a {
      margin-left: ${spacing.x1};
    }

    a:hover {
      text-decoration: underline;
    }
  }
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentWorkspace: squadTypes.Workspace,
  fetchMembershipList: Function,
  memberships: squadTypes.NormalizedList<squadTypes.Membership>,
  showModal: Function,
  ui: Object
};

type State = {
  workspaceMembershipsLimitEnabled: boolean
};

class MembershipList extends Component<Props, State> {
  componentDidMount() {
    this._fetchMore();
  }

  showInviteForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "membership",
      contentAction: "new"
    });
  };

  _fetchMore = () => {
    const { memberships } = this.props;
    const params = {
      page: memberships.currentPage + 1
    };

    return this.props.fetchMembershipList(params);
  };

  render() {
    const { currentWorkspace, currentMembership, memberships, ui } = this.props;
    const inviteButtonDisabled =
      currentWorkspace.memberships_limit <= currentWorkspace.memberships_count &&
      currentWorkspace.memberships_limit !== 0;

    return (
      <WorkspaceLayout>
        <Container>
          <Header>
            <HeaderLeft>
              <h1>Team</h1>
            </HeaderLeft>
            <HeaderRight>
              <div data-tip data-for="disabledButton">
                <button className="primary" onClick={this.showInviteForm} disabled={inviteButtonDisabled}>
                  Invite member
                </button>
              </div>
              {inviteButtonDisabled && (
                <ReactTooltip id="disabledButton" place="bottom" type="dark" effect="solid">
                  You have exceeded your user limit.
                  <br />
                  You can upgrade your plan to add more users.
                </ReactTooltip>
              )}
            </HeaderRight>
          </Header>
          <Content>
            {memberships.hasFetchedListOnce && <h3>{memberships.total} users</h3>}
            {["owner", "admin"].indexOf(currentMembership.role) >= 0 && (
              <p className="light">
                You can manage users in your <Link to="/settings/users">workspace settings</Link>.
              </p>
            )}
            <Table>
              <tbody>
                <tr>
                  <th style={{ width: "8rem" }} />
                  <th>Name</th>
                  <th>Last activity</th>
                </tr>
                {memberships.allIds.map(membershipId => {
                  const membership = memberships.byId[membershipId];
                  if (!membership) {
                    return false;
                  }
                  return (
                    <tr key={membership.id}>
                      <td>
                        <Avatar user={membership.user} size={45} hideName={true} to={`/team/${membership.id}`} />
                      </td>
                      <td>
                        <big>
                          <Link to={`/team/${membership.id}`}>{membership.user.fullname || membership.user.email}</Link>
                        </big>
                      </td>
                      {membership.user.last_sign_in_at && (
                        <td className="light">{moment(membership.user.last_sign_in_at).fromNow()}</td>
                      )}
                      {!membership.user.last_sign_in_at && <td className="light">-</td>}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {memberships.hasNext && !ui.isFetchingGoals && (
              <button
                className="secondary"
                onClick={e => {
                  this._fetchMore();
                  e.preventDefault();
                }}
              >
                Load more
              </button>
            )}
            {(!memberships.hasFetchedListOnce || ui.isFetchingMembers) && <Loader />}
          </Content>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentMembership: state.session.currentMembership,
  currentWorkspace: state.session.currentWorkspace,
  memberships: state.memberships,
  ui: state.ui.membershipList
});

const mapDispatchToProps = {
  fetchMembershipList: membershipsOperations.fetchMembershipList,
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipList);
