//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as constants from "config/constants";

import { MainPrivateRoutes, MainPublicRoutes } from "v2/routes";

type Props = {
  intercom: Object,
  location: Object,
  history: Object
};

type State = {};

class MainApp extends Component<Props, State> {
  componentDidMount = () => {
    // Faster load for the Google Login button, no need to init the app.

    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID
    });
  };

  componentDidUpdate() {
    const { intercom } = this.props;
    if (intercom.refresh_intercom) {
      window.Intercom("update");
    }
  }

  render() {
    const { location } = this.props;

    if (constants.PUBLIC_PATHS.includes(location.pathname)) {
      return <MainPublicRoutes />;
    }

    return <MainPrivateRoutes />;
  }
}

const mapStateToProps = state => ({
  intercom: state.session.intercom
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainApp));
