//@flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { uiOperations } from "state/ducks/ui";

import { createGlobalStyle } from "styled-components";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// Components
import GoalComments from "./GoalComments";
import GoalDependencies from "./GoalDependencies";
import GoalForm from "./GoalForm";
import MembershipForm from "./MembershipForm";
import MoveGoalForm from "./MoveGoalForm";
import ProjectShareForm from "./ProjectShareForm";
import ProjectNewForm from "./ProjectNewForm";
import ProjectPermissions from "./ProjectPermissions";
import QuickSearch from "./QuickSearch";
import StatusUpdateForm from "./StatusUpdateForm";
import StatusUpdateComments from "./StatusUpdateComments";
import StatusUpdateViews from "./StatusUpdateViews";
import TaskForm from "./TaskForm";
import TemplatesBrowser from "./TemplatesBrowser";
import PagesQuotaReached from "./PagesQuotaReached";

const GlobalStyles = createGlobalStyle`
   /* MODAL STUFF */
  .tability-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: ${props => props.maxWidth || "100rem"};
    max-height: 75rem;
    margin: auto;
    transform: none;
    padding: 0;
    background: #fff;
    outline: 0;
    border-radius: 8px;
    overflow: hidden;
    

    @media ${devices.laptop} {
      max-width: 100%;
      width: 100%;
      border-radius: 0;
      height: 100%;
      max-height: 100%;
    }
  }
  .tability-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
  }

  .modal-wrapper {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .modal-wrapper-no-footer {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    display: flex;
    align-items: center;
    padding: ${spacing.x2};
    background-color: #fff;
    color: ${colors.black};

    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.blockBorder};
  }
  .modal-content {
    padding: ${spacing.x2};
    flex: 1;
    overflow: auto;
  }
  .modal-footer {
    border-top: 1px solid ${colors.blockBorder};
    padding: ${spacing.x2};
    display: flex;
    align-items: center;
  }
`;

type Props = {
  hideModal: Function,
  modal: Object,
  width: ?string
};

type State = {
  contentNotSaved: boolean // Use to confirm before closing the modal when editing
};

class AppModal extends Component<Props, State> {
  state = {
    contentNotSaved: false
  };

  handleClose = () => {
    // Ask to confirm closing if contentNotSaved is set to true
    if (this.state.contentNotSaved) {
      if (!window.confirm("Your content is not saved, would you like to keep editing?")) {
        this.setState(
          {
            contentNotSaved: false
          },
          () => {
            this.props.hideModal();
          }
        );
      }
    } else {
      this.props.hideModal();
    }
  };

  componentDidMount() {
    Modal.setAppElement("body");
  }

  handleSetContentNotSaved = (contentNotSaved, callback) => {
    this.setState(
      {
        contentNotSaved
      },
      () => {
        typeof callback === "function" && callback();
      }
    );
  };

  render() {
    const { contentAction, contentType, object, parent, visible, centered } = this.props.modal;

    let size = "small";
    if (contentAction === "dependencies") {
      size = "large";
    }

    let maxWidth = "100rem";
    if (contentType === "project" && contentAction === "templates") {
      maxWidth = "120rem";
    }

    return (
      <Fragment>
        <GlobalStyles maxWidth={maxWidth} />
        <Modal
          className="tability-modal"
          overlayClassName="tability-modal-overlay"
          isOpen={visible}
          onRequestClose={this.handleClose}
          centered={centered}
          size={size}
        >
          {contentType === "quicksearch" && contentAction === "show" && <QuickSearch hideForm={this.handleClose} />}
          {contentType === "goal" && contentAction === "new" && parent && (
            <GoalForm
              handleSetContentNotSaved={this.handleSetContentNotSaved}
              hideForm={this.handleClose}
              section={parent}
              params={object}
            />
          )}
          {contentType === "goal" && contentAction === "edit" && object && (
            <GoalForm
              handleSetContentNotSaved={this.handleSetContentNotSaved}
              hideForm={this.handleClose}
              goal={object}
            />
          )}
          {contentType === "goal" && contentAction === "move" && object && (
            <MoveGoalForm hideForm={this.handleClose} goal={object} />
          )}
          {contentType === "goal" && contentAction === "statusUpdate.views" && object && (
            <StatusUpdateViews hideForm={this.handleClose} statusUpdateId={object.last_status_update_id} />
          )}
          {contentType === "goal" && contentAction === "dependencies" && object && (
            <GoalDependencies hideForm={this.handleClose} goal={object} />
          )}
          {contentType === "workspace" && contentAction === "pages.quota.reached" && (
            <PagesQuotaReached hideForm={this.handleClose} />
          )}
          {contentType === "membership" && contentAction === "new" && <MembershipForm hideForm={this.handleClose} />}
          {contentType === "statusUpdate" && contentAction === "new" && parent && (
            <StatusUpdateForm
              handleSetContentNotSaved={this.handleSetContentNotSaved}
              hideForm={this.handleClose}
              goal={parent}
            />
          )}
          {contentType === "statusUpdate" && contentAction === "edit" && object && parent && (
            <StatusUpdateForm
              handleSetContentNotSaved={this.handleSetContentNotSaved}
              hideForm={this.handleClose}
              goal={parent}
              statusUpdate={object}
            />
          )}
          {contentType === "comments" && contentAction === "view" && parent && object && (
            <StatusUpdateComments hideForm={this.handleClose} statusUpdate={object} goal={parent} />
          )}
          {contentType === "goal.comments" && contentAction === "view" && object && (
            <GoalComments hideForm={this.handleClose} goal={object} />
          )}
          {contentType === "project" && contentAction === "share" && object && (
            <ProjectShareForm hideForm={this.handleClose} project={object} />
          )}
          {contentType === "project" && contentAction === "permissions" && object && (
            <ProjectPermissions hideForm={this.handleClose} project={object} />
          )}
          {contentType === "project" && contentAction === "new" && (
            <ProjectNewForm hideForm={this.handleClose} parentProject={object} />
          )}
          {contentType === "project" && contentAction === "templates" && object && (
            <TemplatesBrowser hideForm={this.handleClose} project={object} />
          )}
          {contentType === "task" && contentAction === "form" && object && (
            <TaskForm hideForm={this.handleClose} project={object} roadmapState={parent} />
          )}
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  modal: state.ui.modal
});

const mapDispatchToProps = {
  hideModal: uiOperations.hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AppModal);
