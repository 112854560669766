//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 17 17" {...props}>
    <g
      id="Buttons-and-Forms"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Update-Card-Actions---counter-and-active"
        transform="translate(-60.000000, -9.000000)"
        stroke="#6A6A6A"
        strokeWidth="2"
      >
        <g id="Group-3" transform="translate(53.000000, 2.000000)">
          <g id="message-circle" transform="translate(8.000000, 8.000000)">
            <path
              d="M15,7.08334586 C15.002844,8.18323397 14.7458674,9.26824622 14.2499774,10.2500075 C13.0507786,12.6494435 10.5990728,14.16563 7.91665414,14.166668 C6.81676603,14.1695357 5.73175378,13.9125591 4.74999248,13.4166692 L0,15 L1.58333083,10.2500075 C1.0874409,9.26824622 0.830464309,8.18323397 0.833332014,7.08334586 C0.834369978,4.40092718 2.35055646,1.94922138 4.74999248,0.750022553 C5.73175378,0.254132621 6.81676603,-0.00284396559 7.91665414,0 L8.33332014,0 C11.9298223,0.198439835 14.8015602,3.07017769 15,6.66667986 L15,7.08334586 L15,7.08334586 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
