// @flow

// eslint-disable-next-line
// TODO: see if this is still required.
// import Popper from "popper.js";

import React from "react";
import ReactDOM from "react-dom";
//import { ConnectedRouter as Router } from "react-router-redux";
import { ConnectedRouter } from "connected-react-router";

import { Provider } from "react-redux";
import history from "config/history";
import * as Sentry from "@sentry/browser";

import App from "v2/app"; // Loading the app from the v2

import configureStore from "state/store";
import { loadState, saveState } from "state/storage";
import Cookies from "universal-cookie";
import moment from "moment";

const cookies = new Cookies();
const COOKIE_STORE = "TABILITY_SESSION";

// Persisted state contains the preferences per workspace. The content is in
// local storage which means that it cannot be shared between workspaces.
const persistedState = loadState();

// Here we replace the auth content of the session with the value stored in the cookie.
// This way it allows us to persist authentication between the workspaces.
// If you sign into https://app.tability.io you will be able to go to
// https://acme.tability.io and still be logged in.

const cookieSession = cookies.get(COOKIE_STORE);

let isAuthenticated = false;
let tokens = null;

if (cookieSession) {
  isAuthenticated = cookieSession.isAuthenticated;
  tokens = cookieSession.tokens;
}

// It is possible to have a different local isAuthenticated state for SAML workspaces that is not the same
// as the rest of the workspaces
if (persistedState && persistedState.session && persistedState.session.isAuthenticated) {
  isAuthenticated = persistedState.session.isAuthenticated;
}

// $FlowFixMe
persistedState.session = {
  // $FlowFixMe
  ...persistedState.session,
  isAuthenticated,
  tokens
};

export const store = configureStore(persistedState);

// Below is the list of services that we need to configure in production
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT
  });
}

// This function caches the state that we want to persist.
store.subscribe(() => {
  // This is the list of state value that we want to persist on refresh.

  const session = {
    isAuthenticated: store.getState().session.isAuthenticated,
    tokens: store.getState().session.tokens,
    samlTokens: store.getState().session.samlTokens,
    recentlyVisited: store.getState().session.recentlyVisited,
    whatsNewVisited: store.getState().session.whatsNewVisited,
    discoveryFlags: store.getState().session.discoveryFlags
  };

  const domain = process.env.REACT_APP_DOMAIN || "";
  // Remove the port
  const cookieDomain = domain.split(":")[0];

  const cookieSession = {
    isAuthenticated: session.isAuthenticated,
    tokens: session.tokens
  };

  // Here we're going to save the session data in cookies to be able to share it
  // between workspaces. See the top of the page to see how the persisted.session
  // gets overwritten.
  cookies.set(COOKIE_STORE, cookieSession, {
    path: "/",
    domain: `.${cookieDomain}`,
    secure: true,
    sameSite: "none",
    expires: moment()
      .add(1, "month")
      .toDate()
  });

  const wizard = store.getState().wizard;

  // Save the state in local storage
  saveState({
    session,
    wizard
  });
});

const root = document.getElementById("root");
if (root) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    root
  );
}
