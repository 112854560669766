//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  width: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 20 22" {...props}>
    <title id="Unlocked-title">Unlocked</title>
    <g
      id="Unlocked-Icon-dump"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Unlocked-unlock" transform="translate(1.000000, 0.000000)" stroke="#6A6A6A" strokeWidth="2">
        <rect id="Unlocked-Rectangle-path" x="0" y="10" width="18" height="11" rx="2"></rect>
        <path
          d="M3.99999743,10 L3.99999743,6 C3.99741839,3.43123449 5.94174231,1.2784468 8.49750395,1.02028906 C11.0532656,0.762131316 13.3888282,2.48260723 13.9,5"
          id="Unlocked-Shape"
        ></path>
      </g>
    </g>
  </Logo>
);
