//@flow
export const SEARCH_REQUESTED = "goals/SEARCH_REQUESTED";
export const SEARCH_COMPLETED = "goals/SEARCH_COMPLETED";
export const SEARCH_FAILED = "goals/SEARCH_FAILED";

export const STATS_REQUESTED = "goals/STATS_REQUESTED";
export const STATS_COMPLETED = "goals/STATS_COMPLETED";
export const STATS_FAILED = "goals/STATS_FAILED";

export const FETCH_DETAILS_REQUESTED = "goals/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "goals/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "goals/FETCH_DETAILS_FAILED";

export const RESET_LIST = "goals/RESET_LIST";
export const SELECT = "goals/SELECT";
export const UNSELECT = "goals/UNSELECT";
