// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

// Styles helpers
import styled from "styled-components";

// Ducks stuff
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";

// Utils
import { handleChange } from "v2/utils/forms";

// Library Components
import { Link } from "react-router-dom";

// Tability Components
import FormField from "v2/components/FormField";
import InlineMessage from "v2/components/InlineMessage";
import Loader from "v2/components/Loader";
import SessionLayout, { HelpLinks } from "v2/components/SessionLayout";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

type Props = {
  clearNewPassword: Function,
  resendConfirmationEmail: Function,
  saveNewPassword: Function,
  location: Object,
  ui: Object
};

type State = {
  email: string,
  password: string,
  password_confirmation: string
};

class NewPassword extends Component<Props, State> {
  // Ref for the email input.
  // We'll need it to focus on the email field if it appears, that way it'll help voice-based browsing
  // to know what's going on (the InlineMessage should play as well).

  emailInput: any;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password_confirmation: ""
    };

    this.emailInput = React.createRef();
  }
  componentDidMount() {
    this.props.clearNewPassword();
    document.title = "Create a new password | Tability";
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.ui.needConfirmation && this.props.ui.needConfirmation) {
      this.emailInput.current.focus();
    }
  }

  updatePassword = (e: Object) => {
    const { password, password_confirmation } = this.state;
    const searchParams = new URLSearchParams(this.props.location.search);
    const reset_password_token = searchParams.get("reset_password_token");

    const params = {
      password,
      password_confirmation,
      reset_password_token
    };

    this.props.saveNewPassword(params);

    e.preventDefault();
  };

  resendConfirmation = (e: Object) => {
    const { email } = this.state;

    const credentials = {
      email
    };

    this.props.resendConfirmationEmail(credentials);

    e.preventDefault();
  };

  handleChange = e => handleChange(this, e);

  render() {
    const { ui } = this.props;
    const { email, password, password_confirmation } = this.state;

    return (
      <Container>
        <SessionLayout title="Reset your password">
          <InlineMessage message={ui.message} messageType={ui.messageType} />
          {ui.needConfirmation && (
            <form onSubmit={this.resendConfirmation}>
              <FormField>
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  aria-label="Add your email"
                  required="true"
                  name="email"
                  autoComplete="off"
                  value={email}
                  ref={this.emailInput}
                  onChange={this.handleChange}
                />
              </FormField>
              {!ui.isFetching && (
                <button className="primary">
                  <span>Resend confirmation email</span>
                </button>
              )}
              {ui.isFetching && (
                <button disabled className="primary">
                  <Loader size="small" />
                </button>
              )}
            </form>
          )}
          {!ui.needConfirmation && (
            <form onSubmit={this.updatePassword}>
              <FormField>
                <input
                  type="password"
                  placeholder="New password"
                  name="password"
                  aria-label="Add a new password"
                  required
                  value={password}
                  onChange={this.handleChange}
                />
              </FormField>
              <FormField>
                <input
                  type="password"
                  placeholder="Confirm new password"
                  aria-label="Confirm your password"
                  required
                  name="password_confirmation"
                  value={password_confirmation}
                  onChange={this.handleChange}
                />
              </FormField>
              {!ui.isFetching && (
                <button type="submit" className="primary">
                  Change password
                </button>
              )}
              {ui.isFetching && (
                <button disabled className="primary">
                  <Loader size="small" />
                </button>
              )}
              <HelpLinks>
                <p>
                  <Link to="/login">Back to login</Link>
                </p>
                <p>
                  <Link to="/signup">Signup for an account</Link>
                </p>
              </HelpLinks>
            </form>
          )}
        </SessionLayout>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui.sessionNewPassword
});

const mapDispatchToProps = {
  saveNewPassword: sessionOperations.saveNewPassword,
  clearNewPassword: uiOperations.clearSessionNewPassword,
  resendConfirmationEmail: sessionOperations.resendConfirmationEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
