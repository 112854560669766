//@flow

const STATE_KEY = "tability_localStorageState";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STATE_KEY);
    if (serializedState === null) {
      return {};
    }
    if (serializedState)
      // Flow triggered errors without this...
      return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

export const saveState = (state: Object) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STATE_KEY, serializedState);
  } catch (err) {
    // Ignore write errors
  }
};
