// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { workspacesOperations } from "state/ducks/workspaces";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import moment from "moment";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm } from "redux-form";
import FormFlag from "v2/components/FormFlag";
import { Link } from "react-router-dom";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }

  select {
    margin-right: ${spacing.x1};
  }
`;

const ReminderContainer = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-right: ${spacing.x1};
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x1};
  input {
    margin-right: ${spacing.x1};
  }
`;

const FormContainer = styled.form`
  margin: 0;
  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  handleSubmit: Function,
  ui: Object,
  updateWorkspace: Function
};

class WorkspaceSettingsNotifications extends Component<Props> {
  componentWillReceiveProps(nextProps) {
    const { currentWorkspace } = nextProps;

    // Checking if we need to redirect to a new workspace if the slug has been
    // updated
    const subdomain = window.location.host.split(".")[0];
    if (currentWorkspace.slug !== subdomain) {
      setTimeout(() => {
        window.location = window.location.href.replace(subdomain, currentWorkspace.slug);
      }, 3000);
    }
  }

  updateWorkspace = workspaceValues => {
    this.props.updateWorkspace(workspaceValues.id, workspaceValues);
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentWorkspace, handleSubmit, ui } = this.props;
    const { errorFields } = ui;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Update";

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>Reminders & notifications</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <p className="light">
              To manage your own notification preferences, please go to your{" "}
              <Link to="/account/notifications">account settings.</Link>
            </p>
            <FormContainer onSubmit={handleSubmit(this.updateWorkspace)}>
              <h2>Reminders settings</h2>
              <FormField>
                <label htmlFor="name">Send weekly reminders on</label>
                <ReminderContainer>
                  <Field
                    component="select"
                    type="text"
                    className={`${this.getErrorClass("weekly_reminder_day")} auto`}
                    name="weekly_reminder_day"
                  >
                    <option value="0">Sunday</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                  </Field>
                  <div>at</div>
                  <Field
                    component="select"
                    type="text"
                    className={`${this.getErrorClass("weekly_reminder_hour")} auto`}
                    name="weekly_reminder_hour"
                  >
                    {Array(24)
                      .fill()
                      .map((_, i) => (
                        <option value={i} key={i}>
                          {i}
                        </option>
                      ))}
                  </Field>
                  <div>:00</div>
                </ReminderContainer>
                <span className={`${this.getErrorClass("weekly_reminder_day")}`}>
                  {errorFields["weekly_reminder_day"] && <small>{errorFields["weekly_reminder_day"]}</small>}
                  {!errorFields["weekly_reminder_day"] && (
                    <small className="subtle">
                      Weekly reminders will be sent every week to goal owners.&nbsp;
                      {currentWorkspace.weekly_reminder_sent_at && (
                        <span>
                          The last reminders were sent {moment(currentWorkspace.weekly_reminder_sent_at).fromNow()}.
                        </span>
                      )}
                    </small>
                  )}
                </span>
              </FormField>
              <FormField>
                <label htmlFor="weekly_subscribed_report_day">Send the weekly subscribed reports on</label>
                <ReminderContainer>
                  <Field
                    component="select"
                    type="text"
                    className={`${this.getErrorClass("weekly_subscribed_report_day")} auto`}
                    name="weekly_subscribed_report_day"
                  >
                    <option value="0">Sunday</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                  </Field>
                  <div>at</div>
                  <Field
                    component="select"
                    type="text"
                    className={`${this.getErrorClass("weekly_subscribed_report_hour")} auto`}
                    name="weekly_subscribed_report_hour"
                  >
                    {Array(24)
                      .fill()
                      .map((_, i) => (
                        <option value={i} key={i}>
                          {i}
                        </option>
                      ))}
                  </Field>
                  <div>:00</div>
                </ReminderContainer>
                <span className={`${this.getErrorClass("weekly_subscribed_report_day")}`}>
                  {errorFields["weekly_subscribed_report_day"] && (
                    <small>{errorFields["weekly_subscribed_report_day"]}</small>
                  )}
                  {!errorFields["weekly_subscribed_report_day"] && (
                    <small className="subtle">
                      Subscribed reports are personalized digests sent via emails.
                      {currentWorkspace.weekly_subscribed_report_sent_at && (
                        <span>
                          The last reports were sent{" "}
                          {moment(currentWorkspace.weekly_subscribed_report_sent_at).fromNow()}.
                        </span>
                      )}
                    </small>
                  )}
                </span>
              </FormField>
              <h2>Default notifications settings</h2>
              <FormField>
                <label>Change the default settings for new users added to this workspace.</label>
                <CheckboxContainer>
                  <Field name="notification_default_weekly_report" component="input" type="checkbox" /> Users receive
                  their weekly report by default
                </CheckboxContainer>
                <CheckboxContainer>
                  <Field name="notification_default_events" component="input" type="checkbox" /> Users receive emails
                  for new events by default
                </CheckboxContainer>
              </FormField>
              <FormActions>
                <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                  {submitText}
                </button>
              </FormActions>
            </FormContainer>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  initialValues: state.session.currentWorkspace,
  ui: state.ui.workspaceSettingsNotifications
});

const mapDispatchToProps = {
  updateWorkspace: workspacesOperations.updateWorkspace
};

const _WorkspaceSettingsNotifications = reduxForm({
  form: "workspaceSettingsNotifications"
})(WorkspaceSettingsNotifications);

export default connect(mapStateToProps, mapDispatchToProps)(_WorkspaceSettingsNotifications);
