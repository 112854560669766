// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import { Redirect } from "react-router-dom";

// Components
import Terms from "./Terms";

type Props = {
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  logout: Function,
  workspaces: squadTypes.NormalizedList<squadTypes.Workspace>
};

type State = {};

class Onboarding extends Component<Props, State> {
  render() {
    const { currentUser } = this.props;
    if (!currentUser.privacy_policy_accepted) {
      return <Terms />;
    } else {
      return <Redirect to="/" />;
    }
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  currentWorkspace: state.session.currentWorkspace
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
