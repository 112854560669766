//@flow
import { request } from "state/utils";
import * as types from "./types";

export const createTask = (goalId: string, taskParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/goals/${goalId}/tasks`, { task: taskParams }),
    payload: { taskParams }
  };
};

export const updateTask = (taskId: string, taskParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/tasks/${taskId}`, { task: taskParams }),
    payload: { taskParams }
  };
};

export const deleteTask = (taskId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/tasks/${taskId}`),
    payload: { id: taskId }
  };
};

export const fetchTaskList = (goalId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/goals/${goalId}/tasks?page=${page}`)
  };
};

export const fetchMembershipTaskList = (membershipId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/memberships/${membershipId}/tasks?page=${page}`)
  };
};

export const fetchTaskDetails = (taskId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/tasks/${taskId}`)
  };
};

export const resetTaskList = () => ({
  type: types.RESET_LIST
});

export const moveTask = (taskId: string, taskParams: Object) => {
  return {
    types: [types.MOVE_REQUESTED, types.MOVE_COMPLETED, types.MOVE_FAILED],
    callAPI: () => request.put(`/tasks/${taskId}/move`, { task: taskParams }),
    payload: {
      taskId,
      taskParams
    }
  };
};
