//@flow
import React from "react";
import { ClipLoader } from "react-spinners";

export default (props: Object) => {
  let size = 30;

  if (props.size && props.size === "small") {
    size = 10;
  }
  return <ClipLoader size={size} sizeUnit={"px"} color="#408C76" />;
};
