// @flow
import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    margin: 20px;
  }

  .button {
    margin-bottom: 90px;
  }
`;

type Props = {};

type State = {};

class NotFound extends Component<Props, State> {
  render() {
    return (
      <Container>
        <h3>You need to be logged into the Tability app to continue</h3>
        <p>Refresh this page once you're signed in.</p>
        <div>
          <a
            className="button primary"
            href={`https://app.${process.env.REACT_APP_DOMAIN || ""}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign in to Tability
          </a>
        </div>
      </Container>
    );
  }
}

export default NotFound;
