// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import { goalsOperations, goalsSelectors } from "state/ducks/goals";
import { sectionsOperations } from "state/ducks/sections";
import { subscriptionsOperations } from "state/ducks/subscriptions";
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

import _ from "lodash/fp";
import * as goalTypes from "state/ducks/goals/types";
import { wizardOperations } from "state/ducks/wizard";
import * as constants from "config/constants";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// V2 Components
import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

// Components
import ProjectAccessForbidden from "v2/components/ProjectAccessForbidden";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GoalDetails from "v2/components/GoalDetails";
import EmptyState from "./EmptyState";
import { Link } from "react-router-dom";

import ProjectMenu from "./ProjectMenu";
import ProjectMode from "./ProjectMode";
import ProjectPanel from "./ProjectPanel";
import ProjectPermissions from "./ProjectPermissions";
import ProjectPublic from "./ProjectPublic";
import ProjectSubscription from "./ProjectSubscription";
import ProjectTitle from "./ProjectTitle";
import Section from "./Section";
import SlackIcon from "v2/components/AppModal/_assets/IconSlack.png";
import ReactTooltip from "react-tooltip";

import ProjectStatsChartVictory from "v2/components/ProjectStatsChartVictory";

import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";
import IconGoal from "v2/components/svg/IconGoal";

// Components
import GoalBlockPage from "v2/components/GoalBlockPage";
import Markdown from "v2/components/Markdown";

const Breadcrumbs = styled.ul`
  li {
    display: inline-block;
    font-weight: 400;
    font-size: 1.4rem;
  }

  li:last-child {
    a {
      font-weight: 600;
    }
  }

  li + li:before {
    content: "/";
    color: ${colors.subtleText};
    padding: 0 8px;
  }
`;

const AddSectionAction = styled.button`
  &.link-button {
    background: #f8f8f8;
    width: 100%;
    display: block;
    color: #6a6a6a;
    opacity: 1;
    transition: all 0.2s ease;
    border-radius: 4px;

    padding: ${spacing.x1};
    margin: ${spacing.x1} 0;
    font-weight: 500;
    display: flex;
    align-items: center;

    width: 12rem;

    background-color: #e9e9e9;
    color: #6a6a6a;
    opacity: 1;
    filter: none;
    border-radius: 4px;
    &:hover,
    &:focus {
      background-color: #e9e9e9;
      color: #6a6a6a;
      filter: brightness(85%);

      opacity: 1;
      border-radius: 4px;
    }
  }
`;

const GoalsContainer = styled.div`
  padding-left: 0;

  .draggable-goal {
  }
`;

const InnerPanel = styled.div`
  background-color: #fff;
  padding: ${spacing.x2};
  margin-bottom: ${spacing.x2};
  border-radius: 8px;
  border: 1px solid ${colors.blockBorder};

  h3 {
    margin-bottom: ${spacing.x2};
  }

  .new-project .link-button {
    background-color: #e9e9e9;
    color: #6a6a6a;
    transition: all 0.2s ease;
    border-radius: 4px;
    padding: ${spacing.x1} ${spacing.x2};
    margin: 0;
    font-size: 1.4rem;
    font-weight: 300;
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    span {
      transition: all 0.2s ease;
      bottom: 0 !important;
      font-weight: 500 !important;
    }
    &:hover {
      background-color: #d4d4d4;
      color: #6a6a6a;
    }

    .line-icon {
      height: 1.7rem;
      padding: 0 1rem 0 0;
    }
  }
`;

const ArchivedBanner = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #955be3;
  color: #fff;
  height: 4rem;
  line-height: 4rem;
  align-items: center;
  border-radius: 4px;
  padding: 0 1.4rem;
  font-weight: 500;
  margin-bottom: 2.2rem;

  a,
  .link-button {
    color: #fff;
    text-decoration: underline;
  }
`;

const MessengerConnect = styled.div`
  display: flex;
  align-items: center;
  span {
    display: flex;
    align-items: center;

    img {
      width: 1.6rem;
      margin-right: ${spacing.x1};
    }
  }
  a {
    flex: 1;
    font-weight: 500;
    display: flex;
    align-items: center;

    img {
      width: 1.6rem;
      margin-right: ${spacing.x1};
    }
  }
`;

const SummaryContainer = styled.div`
  padding: ${spacing.x3};
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x2};
  border-radius: 8px;
  border: 1px solid ${colors.blockBorder};
`;

const SummaryBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${spacing.x4};
  height: 5.5rem;
  justify-content: space-between;

  .header {
    font-size: 1.2rem;
    margin-bottom: ${spacing.x1};
  }

  .content {
    display: flex;
    div {
      height: 24px;
      line-height: 24px;
      min-width: 24px;
      padding: 0 ${spacing.x1};
      font-weight: 600;
      color: #fff;
      border-radius: 4px;
      margin-right: ${spacing.x1};
      &.red {
        background-color: ${colors.red};
      }
      &.yellow {
        background-color: ${colors.yellow};
      }
      &.green {
        background-color: ${colors.green};
      }
      &.grey {
        background-color: ${colors.grey};
      }
      &.black {
        background-color: ${colors.black};
      }
    }
    span {
      font-weight: bold;
      font-size: 2rem;
    }
  }
`;

const Container = styled.div`
  padding: ${spacing.x4};
  padding-bottom: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.x4};

  @media ${devices.tablet} {
    margin-bottom: ${spacing.x2};
  }

  .project-goals-nav-tablet {
    z-index: 80;
    display: none;
    margin-right: ${spacing.x2};

    button {
      background: #e9e9e9;

      &:hover,
      &:focus {
        background: #d4d4d4;
      }
    }
  }

  &.with-goal {
    .project-goals-nav-tablet {
      @media ${devices.tablet} {
        display: flex;
      }
    }
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: ${spacing.x1};
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  @media ${devices.tablet} {
    display: none;
  }
`;

const Main = styled.div`
  flex: 1;
  position: relative;
`;

const ProjectContent = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > div {
    margin-bottom: ${spacing.x4};

    @media ${devices.tablet} {
      margin-bottom: ${spacing.x2};
    }
  }

  .project-goals-nav-laptop {
    z-index: 80;
    display: none;
    margin-right: ${spacing.x2};

    button {
      background: #e9e9e9;

      &:hover,
      &:focus {
        background: #d4d4d4;
      }
    }
  }

  .project-goals-container {
    flex: 4;
    overflow: auto;
    margin-right: ${spacing.x2};

    @media ${devices.tablet} {
      margin-right: 0;
    }
  }

  .project-meta-container {
    flex: 2;
    overflow: auto;
    margin-left: ${spacing.x2};

    @media ${devices.tablet} {
      display: none;
    }
  }
  .project-goal-details-container {
    flex: 8;
    overflow: auto;
  }

  &.with-goal {
    .project-goals-nav-laptop {
      @media ${devices.laptop} {
        display: flex;
      }
      @media ${devices.tablet} {
        display: none;
      }
    }
    .project-goals-container {
      flex: 4;

      @media ${devices.laptop} {
        position: absolute;
        z-index: 81;
        max-width: 90%;
        left: 4rem;
        top: 0;
        bottom: 0;
        transform: translateX(-200vw);
        transition: transform 0.2s ease-out 0s;

        &.goal-nav-open {
          transform: translateX(0);
        }
      }
    }

    .project-meta-container {
      display: none;
    }
    .project-goal-details-container {
      display: block;
    }
  }

  .project-content-wrapper {
    display: flex;

    .project-details-container {
      flex: 3;
    }
    .project-details-container {
      flex: 3;
    }
  }
`;

const MobileNavMask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 79;
  background: rgba(9, 30, 66, 0.54);
  animation: 0.2s ease-out 0s 1 normal none running eMLfYp;
`;

type Props = {
  createSection: Function,
  createProject: Function,
  createdProject: squadTypes.Project,
  currentGoal: squadTypes.Goal,
  currentProject: squadTypes.Project,
  currentWorkspace: squadTypes.Workspace,
  fetchProjectDetails: Function,
  fetchProjectGoalList: Function,
  fetchProjectTasks: Function,
  fetchProjectList: Function,
  fetchSectionList: Function,
  fetchSubscriptionDetails: Function,
  goals: squadTypes.NormalizedList<squadTypes.Goal>,
  location: Object,
  logVisit: Function,
  match: Object,
  moveGoal: Function,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  projectGoals: Array<squadTypes.Goal>,
  push: Function,
  sections: squadTypes.NormalizedList<squadTypes.Section>,
  showModal: Function,
  resetGoalList: Function,
  resetSectionList: Function,
  updateProject: Function,
  expandProjectNav: Function,
  workspaceNavUI: Object,
  displayedProjectTour: Function,
  projectTourDisplayed: boolean,
  ui: Object
};

// We store the goals in state to be able to handle moving them faster.
// That's mainly because of the ordering and the fact that goals can move
// between sections.
// The code is cray - I know.
type State = {
  goalItems: Object, // Hash in the format sectionId => [goal, goal, goal]
  goalStateInitialized: boolean, // Used to check if we did a first pass to add
  // goals to state
  steps: Array<Object>,
  emptyFormMasked: boolean,
  isGoalNavOpen: boolean
};

class ProjectDetails extends Component<Props, State> {
  state = {
    goalItems: {},
    goalStateInitialized: false,
    isGoalNavOpen: false,
    maskGoalDetails: false,
    steps: [],
    emptyFormMasked: true
  };

  _toggleGoalNav = () => {
    this.setState({
      isGoalNavOpen: !this.state.isGoalNavOpen
    });
  };

  _closeGoalNav = () => {
    this.setState({
      isGoalNavOpen: false
    });
  };

  // a little function to help us with reordering the result
  reorderGoals = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Drag & drop logic
  // This function replace updates the state of a particular section with a new
  // order.
  updateGoalItems = (sectionId, items) => {
    const { goalItems } = this.state;
    goalItems[sectionId] = items;
    this.setState({
      goalItems
    });
  };

  onDragEnd = result => {
    const { moveGoal } = this.props;
    const { goalItems } = this.state;
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // If we move items within the same section
      // Perform a re-ordering
      const nextGoalItems = this.reorderGoals(goalItems[destination.droppableId], source.index, destination.index);
      // Update the state
      this.updateGoalItems(destination.droppableId, nextGoalItems);
    } else {
      // If we move items between sections
      // Perform the move
      const result = this.moveGoalItem(
        goalItems[source.droppableId],
        goalItems[destination.droppableId],
        source,
        destination
      );

      goalItems[source.droppableId] = result[source.droppableId];
      goalItems[destination.droppableId] = result[destination.droppableId];

      // Update the state
      this.setState({
        goalItems
      });
    }

    const goalParams = {
      section_id: destination.droppableId,
      position: destination.index
    };

    moveGoal(draggableId, goalParams).then((action, state) => {
      if (action.type === goalTypes.MOVE_COMPLETED) {
        this.props.fetchProjectGoalList(this.props.match.params.project);
      }
    });
  };

  /**
   * Moves an item from one list to another list.
   */
  moveGoalItem = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  componentDidMount() {
    const {
      currentProject,
      fetchProjectDetails,
      fetchProjectGoalList,
      fetchProjectTasks,
      fetchSectionList,
      fetchSubscriptionDetails,
      match,
      resetGoalList,
      resetSectionList
    } = this.props;

    // First we unselect the goal and reset the list from the redux store. This
    // avoids having discrepancies in the UI.

    // Only fetch the project if we don't already have the data
    //const preloadedProjectId = currentProject ? currentProject.id : "";
    const matchedProjectId = match.params.project;

    resetGoalList();
    //if (matchedProjectId !== preloadedProjectId) {
    resetSectionList();
    fetchProjectDetails(matchedProjectId);
    fetchSubscriptionDetails(matchedProjectId);
    //}

    fetchSectionList(matchedProjectId);
    fetchProjectGoalList(matchedProjectId);
    fetchProjectTasks(matchedProjectId);

    if (currentProject) {
      // Log the visit
      if (!match.params.goal) {
        const page = {
          pageType: "project",
          pageTitle: currentProject.title,
          pageUrl: `/pages/${currentProject.id}`
        };
        this.props.logVisit(page);
      }

      document.title = `${currentProject.title} | Tability`;
    }
  }

  componentDidUpdate(prevProps) {
    // Reload project if we switched project
    const {
      currentProject,
      createdProject,
      fetchProjectDetails,
      fetchProjectGoalList,
      fetchSectionList,
      match,
      resetSectionList
    } = this.props;

    // Reload everything if we switched projects
    if (match.params.project !== prevProps.match.params.project) {
      resetSectionList();
      fetchProjectDetails(match.params.project);
      fetchProjectGoalList(match.params.project);
      fetchSectionList(match.params.project);
    }

    // If we have a different project
    if (
      (currentProject && !prevProps.currentProject) ||
      (currentProject && prevProps.currentProject && prevProps.currentProject.id !== currentProject.id)
    ) {
      // Log the visit
      // If this is not a goal page
      document.title = `${currentProject.title} | Tability`;
      if (!match.params.goal) {
        const page = {
          pageType: "project",
          pageTitle: currentProject.title,
          pageUrl: `/pages/${currentProject.id}`
        };
        this.props.logVisit(page);
      }
    }

    const newlyCreatedProject = createdProject;
    if (newlyCreatedProject) {
      if (!prevProps.createdProject || newlyCreatedProject.id !== prevProps.createdProject.id) {
        this.props.push(`/pages/${newlyCreatedProject.id}`);
      }
    }

    // Set the goalItems for the drag & drop
    const { sections, goals, push } = this.props;
    const { goalItems, goalStateInitialized } = this.state;
    const prevSections = prevProps.sections.allIds;
    const prevGoals = prevProps.goals;

    if (goalStateInitialized && _.isEqual(prevSections, sections.allIds) && _.isEqual(prevGoals, goals)) {
      return;
    }

    sections.allIds.forEach(sectionId => {
      const sectionGoals = goalsSelectors.getListByParentId(goals.byId, "section", sectionId);
      // Initiate the display goal form values
      goalItems[sectionId] = sectionGoals;
    });

    this.setState({
      goalItems,
      goalStateInitialized: true
    });

    // Redirect to goal if it's the first goal
    // Let's see if goals.total changed from 1 to 0
    if (prevGoals.total === 0 && goals.total === 1) {
      // if that's the case check if we have a new createdID too!
      if (prevGoals.createdId === null && goals.createdId) {
        // We have added the first goal, and we have a createdId. We're good to redirect to the goal.
        const goalToRedirectTo = goals.byId[goals.createdId];
        push(`/pages/${goalToRedirectTo.project_id}/${goalToRedirectTo.id}`);
      }
    }
  }

  showProjectNewForm = (e: Object) => {
    const { currentProject } = this.props;
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "new",
      object: currentProject
    });
  };

  showPagesQuotaReached = (e: Object) => {
    e.preventDefault();
    this.props.showModal({
      contentType: "workspace",
      contentAction: "pages.quota.reached"
    });
  };

  openIntercom = (e: Object) => {
    e.preventDefault();
    window.Intercom("show");
  };

  createSection = (e: Object) => {
    e.preventDefault();
    const { currentProject } = this.props;
    const params = {
      title: "Untitled section"
    };
    this.props.createSection(currentProject.id, params);
  };

  emptyShowGoals = e => {
    e.preventDefault();
    this.setState({
      emptyFormMasked: false
    });
  };

  emptyMaskGoals = () => {
    this.setState({
      emptyFormMasked: true
    });
  };

  unarchiveProject = e => {
    e.preventDefault();
    const { currentProject } = this.props;
    if (
      window.confirm(
        `Are you sure you want to un-archive this page? This action will also un-archive all its sub-pages`
      )
    ) {
      const params = {
        is_archived: false
      };
      this.props.updateProject(currentProject.id, params);
    }
  };

  _dismissMessengerIntegration = e => {
    e.preventDefault();
    const { currentProject } = this.props;
    const params = {
      dismiss_messenger_integration: true
    };
    this.props.updateProject(currentProject.id, params);
  };

  render() {
    const { currentGoal, currentProject, currentWorkspace, sections, projectGoals, projects, match, ui } = this.props;

    const hasCurrentGoal = (currentGoal && true) || match.params.goal;
    const projectIdFromUrl = match.params.project;
    if (ui.AccessIsForbidden[projectIdFromUrl]) {
      return <ProjectAccessForbidden />;
    }

    if (currentProject && currentProject.current_user_permission === "none") {
      return <ProjectAccessForbidden />;
    }
    if (!currentProject) {
      return (
        <WorkspaceLayout>
          <Container>
            <Loader />
          </Container>
        </WorkspaceLayout>
      );
    }

    if (!currentProject) {
      return <Loader />;
    }

    const breadcrumbs = [];

    if (currentProject.parent_id && projects.byId[currentProject.parent_id]) {
      const parentProject = projects.byId[currentProject.parent_id];
      breadcrumbs.unshift({
        label: parentProject.title,
        path: `/pages/${parentProject.id}`
      });

      if (parentProject.parent_id) {
        breadcrumbs.unshift({
          label: "..."
        });
      }
    }

    breadcrumbs.unshift({
      label: "Pages",
      path: "/pages"
    });

    const useCompactGoal = hasCurrentGoal && currentGoal;

    const mainClass = hasCurrentGoal ? "with-goal" : "";

    const progressPrct = Math.round(currentProject.cached_prct_progress_normalized * 100);

    let pageQuotaExceeded = false;
    // Check if we have exceeded the quota of pages.
    // This is only applied to plans that have the new billing packages
    if (
      currentWorkspace.active_projects_limit !== 0 &&
      currentWorkspace.active_projects_count >= currentWorkspace.active_projects_limit &&
      !constants.BILLING_PER_USER_PLANS.includes(currentWorkspace.stripe_cache_subscription_plan)
    ) {
      pageQuotaExceeded = true;
    }

    const displaySlackIntegrationMessenger =
      currentWorkspace.slack_team_name &&
      !currentProject.slack_channel_id &&
      !currentProject.dismiss_messenger_integration &&
      currentProject.current_user_permission === "edit";
    const displaySlackIntegration = currentProject.slack_channel_name && currentWorkspace.slack_team_name;
    const { isGoalNavOpen } = this.state;
    const navClassName = isGoalNavOpen ? "goal-nav-open" : "";
    const ariaExpanded = isGoalNavOpen ? "true" : "false";

    return (
      <WorkspaceLayout>
        {isGoalNavOpen && <MobileNavMask onClick={this._closeGoalNav} />}
        <Container>
          <Breadcrumbs>
            {breadcrumbs.map((crumb, index) => {
              if (crumb.path) {
                return (
                  <li key={index}>
                    <Link className="subtle" to={crumb.path}>
                      {crumb.label}
                    </Link>
                  </li>
                );
              } else {
                return <li>{crumb.label}</li>;
              }
            })}
          </Breadcrumbs>
          <Header className={`${mainClass}`}>
            <HeaderLeft>
              <ProjectTitle currentProject={currentProject} />
              <ProjectPermissions currentProject={currentProject} className="hide-tablet" />
              <ProjectSubscription currentProject={currentProject} className="hide-tablet" />
              {ui.isFetchingGoals && <Loader size="small" />}
            </HeaderLeft>
            <HeaderRight>
              <ProjectPublic currentProject={currentProject} />
              <ProjectMode currentProject={currentProject} match={match} hasCurrentGoal={hasCurrentGoal} />
              <ProjectMenu currentProject={currentProject} />
            </HeaderRight>
            <div className="project-goals-nav-tablet">
              <ButtonIcon
                aria-expanded={ariaExpanded}
                aria-label="Goal navigation button"
                onClick={this._toggleGoalNav}
              >
                <IconGoal />
              </ButtonIcon>
            </div>
          </Header>
          {currentProject.is_archived && (
            <ArchivedBanner>
              <div>This page is archived</div>
              <div data-tip data-for="quota-exceeded">
                <button className="link-button" onClick={this.unarchiveProject} disabled={pageQuotaExceeded}>
                  Unarchive
                </button>
              </div>
            </ArchivedBanner>
          )}
          <Main>
            <ProjectContent className={`${mainClass}`}>
              <div className="project-goals-nav-laptop">
                <ButtonIcon
                  aria-expanded={ariaExpanded}
                  aria-label="Goal navigation button"
                  onClick={this._toggleGoalNav}
                >
                  <IconGoal />
                </ButtonIcon>
              </div>
              <div className={`${navClassName} project-goals-container`}>
                {currentProject.cached_total_goals === 0 && projectGoals.length === 0 && this.state.emptyFormMasked && (
                  <EmptyState project={currentProject} showGoals={this.emptyShowGoals} />
                )}
                {ui.isFetchingGoals && projectGoals.length === 0 && <Loader />}
                {(!this.state.emptyFormMasked || projectGoals.length > 0 || currentProject.cached_total_goals > 0) && (
                  <div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      {sections.allIds.map((sectionId, sectionIndex) => {
                        const section = sections.byId[sectionId];
                        if (!section) {
                          return false;
                        }
                        const goalItems = this.state.goalItems[section.id];
                        return (
                          <Droppable droppableId={section.id} key={section.id}>
                            {(provided, snapshot) => (
                              <Section
                                project={currentProject}
                                section={section}
                                sectionsCount={sections.total}
                                goalsCount={projectGoals.length}
                                hasCurrentGoal={hasCurrentGoal}
                              >
                                <GoalsContainer ref={provided.innerRef} {...provided.droppableProps}>
                                  {goalItems &&
                                    goalItems.map((goal, index) => {
                                      if (!goal) {
                                        return false;
                                      }
                                      const isSelected =
                                        hasCurrentGoal && currentGoal ? goal.id === currentGoal.id : false;
                                      const isLastClass = index === goalItems.length ? "last-item" : "";
                                      return (
                                        <Draggable
                                          key={goal.id}
                                          draggableId={goal.id}
                                          index={index}
                                          isDragDisabled={currentProject.current_user_permission !== "edit"}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              className="draggable-goal"
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <GoalBlockPage
                                                goal={goal}
                                                isSelected={isSelected}
                                                maskTasks={true}
                                                closeGoalNav={this._closeGoalNav}
                                                className={`compact ${isLastClass}`}
                                                viewOnly={currentProject.current_user_permission !== "edit"}
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  {provided.placeholder}
                                </GoalsContainer>
                              </Section>
                            )}
                          </Droppable>
                        );
                      })}
                    </DragDropContext>
                    {currentProject.current_user_permission === "edit" && (
                      <AddSectionAction className="link-button" onClick={this.createSection}>
                        <span>+ Section</span>
                      </AddSectionAction>
                    )}
                  </div>
                )}
              </div>
              <div className="project-goal-details-container">
                <div className="project-content-wrapper">
                  <div className="project-details-container">
                    {currentProject.cached_total_goals > 0 && (
                      <SummaryContainer>
                        <SummaryBlock>
                          <div className="header">Progress</div>
                          <div className="content">
                            <span>{progressPrct}%</span>
                          </div>
                        </SummaryBlock>
                        {!useCompactGoal && (
                          <SummaryBlock className="hide-mobile">
                            <div className="header">Updated</div>
                            <div className="content">
                              <span>{currentProject.update_frequency}</span>
                            </div>
                          </SummaryBlock>
                        )}
                        <ReactTooltip id={`closedGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                          {currentProject.cached_closed_goals} goal{currentProject.cached_closed_goals !== 1 && "s"}{" "}
                          closed
                        </ReactTooltip>
                        <ReactTooltip id={`draftGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                          {currentProject.cached_draft_goals} goal{currentProject.cached_draft_goals !== 1 && "s"}{" "}
                          pending updates
                        </ReactTooltip>
                        <ReactTooltip id={`greenGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                          {currentProject.cached_active_green_goals} goal
                          {currentProject.cached_active_green_goals !== 1 && "s"} on-track
                        </ReactTooltip>
                        <ReactTooltip id={`yellowGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                          {currentProject.cached_active_yellow_goals} goal
                          {currentProject.cached_active_yellow_goals !== 1 && "s"} at-risk
                        </ReactTooltip>
                        <ReactTooltip id={`redGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                          {currentProject.cached_active_red_goals} goal
                          {currentProject.cached_active_red_goals !== 1 && "s"} off-track
                        </ReactTooltip>
                      </SummaryContainer>
                    )}
                    <ProjectStatsChartVictory project={currentProject} />
                  </div>
                  <div className="project-meta-container">
                    {currentProject.description && (
                      <InnerPanel>
                        <h3>Page summary</h3>
                        <Markdown source={currentProject.description} />
                      </InnerPanel>
                    )}
                    {displaySlackIntegrationMessenger && (
                      <InnerPanel className="integration">
                        <MessengerConnect>
                          <Link to={`/pages/${currentProject.id}/settings/notifications`}>
                            <img src={SlackIcon} alt="Slack" className="slack-image" />
                            Connect to your team's Slack channel
                          </Link>
                          <ButtonIcon className="colored" onClick={this._dismissMessengerIntegration}>
                            <IconClose />
                          </ButtonIcon>
                        </MessengerConnect>
                      </InnerPanel>
                    )}
                    {displaySlackIntegration && (
                      <InnerPanel className="integration">
                        <MessengerConnect>
                          <span>
                            <img src={SlackIcon} alt="Slack" className="slack-image" />
                            Connected to the<b>&nbsp;{currentProject.slack_channel_name}&nbsp;</b>channel on Slack
                          </span>
                        </MessengerConnect>
                      </InnerPanel>
                    )}
                    <ProjectPanel currentProject={currentProject} />
                  </div>
                </div>

                {useCompactGoal && (
                  <GoalDetails
                    goal={currentGoal}
                    viewOnly={currentProject && currentProject.current_user_permission === "view"}
                  />
                )}
              </div>
            </ProjectContent>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentWorkspace: state.session.currentWorkspace,
  currentGoal: goalsSelectors.getObjectById(state.goals.byId, props.match.params.goal),
  currentProject: projectsSelectors.getObjectById(state.projects.byId, props.match.params.project),
  createdProject: projectsSelectors.getObjectById(state.projects.byId, state.projects.createdId),
  sections: state.sections,
  goals: state.goals,
  projectGoals: goalsSelectors.getListByParentId(state.goals.byId, "project", props.match.params.project),
  projectTourDisplayed: state.wizard.projectTourDisplayed,
  projects: state.projects,
  ui: state.ui.projectDetails,
  workspaceNavUI: state.ui.workspaceNav
});

const mapDispatchToProps = {
  createSection: sectionsOperations.createSection,
  createProject: projectsOperations.createProject,
  displayedProjectTour: wizardOperations.displayedProjectTour,
  push,
  fetchProjectDetails: projectsOperations.fetchProjectDetails,
  fetchProjectTasks: projectsOperations.fetchProjectTasks,
  fetchProjectGoalList: goalsOperations.fetchProjectGoalList,
  fetchProjectList: projectsOperations.fetchProjectList,
  fetchSectionList: sectionsOperations.fetchSectionList,
  fetchSubscriptionDetails: subscriptionsOperations.fetchSubscriptionDetails,
  logVisit: sessionOperations.logVisit,
  moveGoal: goalsOperations.moveGoal,
  resetGoalList: goalsOperations.resetGoalList,
  resetSectionList: sectionsOperations.resetSectionList,
  updateProject: projectsOperations.updateProject,
  expandProjectNav: uiOperations.expandProjectNav,
  showModal: uiOperations.showModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDetails));
