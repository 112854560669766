// @flow
import { createReducer } from "state/utils";
import * as membershipsTypes from "state/ducks/memberships/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  errorFields: {},
  formState: "pending",
  message: "",
  messageType: "warning"
};

export default createReducer(INITIAL_STATE)({
  [membershipsTypes.CREATE_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [membershipsTypes.CREATE_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "Your user has been invited",
      messageType: "success"
    };
  },
  [membershipsTypes.CREATE_FAILED]: (state, action) => {
    const errors = action.error.response.data.errors;
    let message = "Sorry but we could not invite the users.";
    if (errors) {
      if (errors.user_id) {
        message = "This user has already been invited.";
      }
      if (errors.user_limit) {
        message = "You have exceeded your user limit. You'll need to upgrade your plan to add more users.";
      }
    }
    const error = action.error.response.data.error;
    if (error) {
      if (error.includes("Email has already been taken")) {
        message = "Sorry, this email has already been taken.";
      }
    }
    return {
      formState: "failed",
      message: `${message}`,
      messageType: "warning"
    };
  },
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
