// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  background: #f3f5f8;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

type Props = {
  message?: string
};

type State = {};

class NotFound extends Component<Props, State> {
  render() {
    const { message } = this.props;
    return (
      <Container>
        {message && <div>{message}</div>}
        {!message && <div>Sorry, you need to be logged in to access this page.</div>}
        <div>
          <Link to="/">Go back to the login page</Link>
        </div>
      </Container>
    );
  }
}

export default NotFound;
