// @flow
import { createReducer } from "state/utils";
import * as membershipsTypes from "state/ducks/memberships/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  errorFields: {},
  formState: "pending",
  message: "",
  messageType: "warning",
  slackState: "pending",
  slackMessage: ""
};

export default createReducer(INITIAL_STATE)({
  [membershipsTypes.UPDATE_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [membershipsTypes.UPDATE_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "The user has been updated",
      messageType: "success"
    };
  },
  [membershipsTypes.UPDATE_FAILED]: (state, action) => {
    const errors = action.error.response.data;
    const errorFields = {};
    if (errors["role"]) {
      errorFields["role"] = "This role is not permitted";
    }
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not update this user",
      messageType: "warning"
    };
  },
  [membershipsTypes.RESEND_CONFIRMATION_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [membershipsTypes.RESEND_CONFIRMATION_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "A new confirmation email has been sent",
      messageType: "success"
    };
  },
  [membershipsTypes.RESEND_CONFIRMATION_FAILED]: (state, action) => {
    const errorFields = {};
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not send a confirmation email to this user",
      messageType: "warning"
    };
  },
  [membershipsTypes.DELETE_REQUESTED]: (state, action) => {
    return {
      errorFields: [],
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [membershipsTypes.DELETE_COMPLETED]: (state, action) => {
    return {
      errorFields: [],
      formState: "completed",
      message: "The user has been deleted",
      messageType: "success"
    };
  },
  [membershipsTypes.DELETE_FAILED]: (state, action) => {
    const errorFields = {};
    return {
      errorFields,
      formState: "failed",
      message: "Sorry but we could not delete this user",
      messageType: "warning"
    };
  },
  [membershipsTypes.SYNC_SLACK_REQUESTED]: (state, action) => {
    return {
      ...state,
      slackState: "requesting",
      slackMessage: ""
    };
  },
  [membershipsTypes.SYNC_SLACK_COMPLETED]: (state, action) => {
    return {
      ...state,
      slackState: "completed",
      slackMessage: ""
    };
  },
  [membershipsTypes.SYNC_SLACK_FAILED]: (state, action) => {
    return {
      ...state,
      slackState: "failed",
      slackMessage: "Sorry but we could not sync your account."
    };
  },
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
