// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { checkCurrentWorkspace } from "state/ducks/session/actions";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import * as constants from "config/constants";
import { Helmet } from "react-helmet";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import { Link } from "react-router-dom";

import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  checkCurrentWorkspace: Function,
  ui: Object
};

type State = {
  payAnnually: boolean
};

class WorkspaceSettingsPlan extends Component<Props, State> {
  state = {
    payAnnually: false
  };

  componentDidMount() {
    this.props.checkCurrentWorkspace();
  }

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentWorkspace } = this.props;

    // The frequency can be extracted from the plan name
    const currentPlanID = currentWorkspace.stripe_cache_subscription_plan;
    //const targetPlanID = payAnnually ? "annually-standard" : "monthly-standard";

    const planNameMapping = {
      [constants.PLAN_ANNUALLY_STANDARD]: "Legacy Annually Teams",
      [constants.PLAN_MONTHLY_STANDARD]: "Legacy Monthly Teams",
      [constants.PLAN_ANNUALLY_BUSINESS]: "Legacy Annually Business",
      [constants.PLAN_MONTHLY_BUSINESS]: "Legacy Monthly Business",
      [constants.PLAN_MONTHLY_PACKAGE_PILOT]: "Monthly Pilot",
      [constants.PLAN_ANNUALLY_PACKAGE_PILOT]: "Annually Pilot",
      [constants.PLAN_MONTHLY_PACKAGE_TEAMS]: "Monthly Teams",
      [constants.PLAN_ANNUALLY_PACKAGE_TEAMS]: "Annually Teams",
      [constants.PLAN_MONTHLY_PACKAGE_BUSINESS]: "Monthly Business",
      [constants.PLAN_ANNUALLY_PACKAGE_BUSINESS]: "Annually Business",
      [constants.PLAN_MONTHLY_PACKAGE_UNLIMITED]: "Monthly Unlimited",
      [constants.PLAN_ANNUALLY_PACKAGE_UNLIMITED]: "Annually Unlimited"
    };

    // Set the plan name based on the cached subscription plan
    let planName = "Free"; // Default to free
    // If there's a plan, get the public name from the mapping
    if (currentPlanID) {
      planName = planNameMapping[currentPlanID];
    }

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Helmet>
          <script>
            {`
          _refersion(function(){ _rfsn._setSource("STRIPE"); _rfsn._addCart('${currentWorkspace.stripe_customer_id}'); });
          `}
          </script>
        </Helmet>
        <Container>
          <Header>
            <h1>Your plan has been changed!</h1>
          </Header>
          <Main>
            <div>
              <p>You are now on the {planName} plan.</p>
              <p>
                <Link to="/settings/billing">Back to billing</Link>
              </p>
            </div>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  ui: state.ui.workspaceSettingsPlan
});

const mapDispatchToProps = {
  checkCurrentWorkspace
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettingsPlan);
