//@flow
import React from "react";
import styled from "styled-components";
import Select from "react-select";

import { colors } from "v2/components/styles/colors";

const SelectWrapper = styled.div`
  .option-label {
    color: ${colors.black};
  }
  .option-path {
    color: ${colors.subtleText};
    font-size: 1.2rem;
  }
  .ellipsed-label {
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .react-select__option--is-selected {
    background-color: #f3f3f3;

    .option-label {
      color: ${colors.black};
    }
    .option-path {
      color: ${colors.subtleText};
    }
  }
`;

export default (props: any) => {
  return (
    <SelectWrapper>
      <Select {...props} />
    </SelectWrapper>
  );
};
