// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import * as squadTypes from "squadTypes";
import { push } from "react-router-redux";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormFlag from "v2/components/FormFlag";
import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import Loader from "v2/components/Loader";
import ProjectSettingNav from "v2/components/ProjectSettingNav";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

type Props = {
  currentProject: squadTypes.Project,
  deleteProject: Function,
  fetchProjectDetails: Function,
  handleSubmit: Function,
  match: Object,
  push: Function,
  confirmDelete: string,
  ui: Object
};

class ProjectSettingsDelete extends Component<Props> {
  componentDidMount() {
    const { currentProject, fetchProjectDetails, match } = this.props;
    // Only fetch the project if we don't already have the data
    if (!currentProject || match.params.project !== currentProject.id) {
      fetchProjectDetails(match.params.project);
    }
  }

  deleteProject = values => {
    const { currentProject } = this.props;
    if (values.confirmDelete !== "DELETE") {
      return;
    }
    if (window.confirm(`Are you sure you want to delete this project?`)) {
      this.props.deleteProject(currentProject.id);
      this.props.push("/pages");
    }
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentProject, confirmDelete, handleSubmit, ui } = this.props;
    if (!currentProject) {
      return (
        <WorkspaceLayout secondNav={<div />}>
          <Container>
            <Loader />
          </Container>
        </WorkspaceLayout>
      );
    }

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Delete permanently";

    return (
      <WorkspaceLayout secondNav={<ProjectSettingNav project={currentProject} />}>
        <Container>
          <Header>
            <span>
              <Link to={`/pages/${currentProject.id}`}>{currentProject.title}</Link>
            </span>
            <h1>Delete your page</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <p>
              Use this form to delete <b>{currentProject.title}</b> and all its data.
            </p>
            <p>
              This action cannot be reversed and will permanently delete your page and all the historic data associated.
            </p>
            <p>You will need to type in DELETE to confirm.</p>
            <form onSubmit={handleSubmit(this.deleteProject)}>
              <FormField>
                <label htmlFor="confirmDelete">Confirm the deletion</label>
                <Field
                  component="input"
                  type="text"
                  name="confirmDelete"
                  placeholder="Type DELETE to confirm"
                  className="tiny"
                  autoComplete="off"
                />
              </FormField>
              <FormActions>
                <button
                  type="submit"
                  className="danger-primary auto-size"
                  disabled={ui.formState === "requesting" || confirmDelete !== "DELETE"}
                >
                  {submitText}
                </button>
              </FormActions>
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentProject = projectsSelectors.getObjectById(state.projects.byId, props.match.params.project);
  const selector = formValueSelector("projectSettingsDelete");
  return {
    currentProject,
    ui: state.ui.projectSettingsDelete,
    confirmDelete: selector(state, "confirmDelete")
  };
};

const mapDispatchToProps = {
  push,
  deleteProject: projectsOperations.deleteProject,
  fetchProjectDetails: projectsOperations.fetchProjectDetails
};

const _ProjectSettingsDelete = reduxForm({
  form: "projectSettingsDelete"
})(ProjectSettingsDelete);

export default connect(mapStateToProps, mapDispatchToProps)(_ProjectSettingsDelete);
