// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import WorkspaceNotifications from "./WorkspaceNotifications";

type Props = {
  match: Object
};

class WorkspaceOnboarding extends Component<Props> {
  render() {
    // The match below exist to keep the process flexible if we want to add more steps later
    const { match } = this.props;
    const onboardingStep = match.params.step;
    switch (onboardingStep) {
      case "notifications":
        return <WorkspaceNotifications />;
      default:
        return <div>Sorry...</div>;
    }
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceOnboarding);
