//@flow
import React from "react";
import styled from "styled-components";

import { colors } from "v2/components/styles/colors";

const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  width: 3.1rem;
  height: 3.1rem;

  background: rgba(21, 21, 21, 0.09);
  border: 0;
  padding: 0;
  &:active {
    filter: brightness(0.7);
  }
  &:focus {
    border: 0;
  }
  &:hover,
  &:focus {
    background: rgba(21, 21, 21, 0.2);
  }

  img {
    width: 1.6rem;
    &.large {
      width: 1.8rem;
    }
  }

  svg {
    width: 1.6rem;
    g {
      stroke: #6a6a6a;
    }
    &.blue {
      g {
        stroke: #324dd3;
      }
    }
    &.red {
      g {
        stroke: ${colors.red};
      }
    }
  }
  cursor: pointer;
`;

type Props = {
  children: any
};

export default ({ children, ...props }: Props) => {
  return <Container {...props}>{children}</Container>;
};
