// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Switch, Route, Redirect } from "react-router-dom";
import * as squadTypes from "squadTypes";
import { accountOperations } from "state/ducks/account";
import { workspacesOperations, workspacesSelectors } from "state/ducks/workspaces";
import * as integrationTypes from "state/ducks/integrations/types";
import { integrationsOperations, integrationsSelectors } from "state/ducks/integrations";
import Loader from "v2/components/Loader";

import NoLayoutLogin from "v2/components/NoLayoutLogin";
import * as pages from "v2/pages";

import NotFound from "v2/components/NotFound";

const routes = [
  {
    path: "/settings",
    component: pages.JiraConnectSettings,
    exact: true
  },
  {
    path: "/goals",
    component: pages.JiraConnectGoals,
    exact: true
  },
  {
    component: NotFound
  }
];

type Props = {
  currentIntegration: squadTypes.Integration,
  currentUser: squadTypes.User,
  fetchAccount: Function,
  fetchIntegrationDetails: Function,
  fetchWorkspaceDetails: Function,
  isAuthenticated: boolean,
  location: Object
};

class JiraConnectRoutes extends Component<Props> {
  componentDidMount() {
    const { currentUser, fetchAccount, location, fetchIntegrationDetails, fetchWorkspaceDetails } = this.props;

    // get the params from the URL
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const remoteId = params.get("remote_id");

      // Now we fetch the integration details based on the remote id
      fetchIntegrationDetails(remoteId).then(action => {
        if (action.type === integrationTypes.FETCH_DETAILS_COMPLETED) {
          const workspaceId = action.response.data.workspace_id;
          if (workspaceId) {
            // Get the details of the workspace
            fetchWorkspaceDetails(workspaceId);
          }
        }
      });
    }

    if (!currentUser) {
      fetchAccount();
    }
  }

  render() {
    const { currentUser, currentIntegration, isAuthenticated } = this.props;

    // Return to login if the user is not authenticated
    if (!isAuthenticated) {
      // Return Login Component
      return <NoLayoutLogin />;
    }

    // Fetch the user if they're not set. We know that we can get a currentUser
    // because the authentication was successful.
    if (!currentUser || !currentIntegration) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    if (!currentUser.fullname || !currentUser.privacy_policy_accepted) {
      // Display message to finish onboarding
      return <Redirect to="/onboarding" />;
    }

    return (
      <Switch>
        {routes.map(route => (
          <Route key={route.path || "notfound"} {...route} />
        ))}
      </Switch>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { location } = props;
  let remoteId = null;
  let currentIntegration = null;
  let currentWorkspace = null;

  if (location && location.search) {
    const params = new URLSearchParams(location.search);
    remoteId = params.get("remote_id");

    currentIntegration = integrationsSelectors.getListByParam(state.integrations.byId, "remote_id", remoteId)[0];
    if (currentIntegration) {
      const workspaceId = currentIntegration.workspace_id;
      if (workspaceId) {
        currentWorkspace = workspacesSelectors.getListByParam(state.workspaces.byId, "workspace_id", workspaceId)[0];
      }
    }
  }
  return {
    currentUser: state.session.currentUser,
    currentIntegration,
    currentWorkspace,
    isAuthenticated: state.session.isAuthenticated
  };
};

const mapDispatchToProps = {
  fetchAccount: accountOperations.fetchAccount,
  fetchIntegrationDetails: integrationsOperations.fetchIntegrationDetails,
  fetchWorkspaceDetails: workspacesOperations.fetchWorkspaceDetails
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JiraConnectRoutes));
