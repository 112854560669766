// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import { push } from "react-router-redux";
import styled from "styled-components";
import _ from "lodash";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import moment from "moment";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

// V2 Components
import AsyncSelect from "v2/components/AsyncSelect";
import Datetime from "react-datetime";
import FormFlag from "v2/components/FormFlag";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import RadioField from "v2/components/RadioField";
import Loader from "v2/components/Loader";
import ProjectSettingNav from "v2/components/ProjectSettingNav";

import Toggle from "v2/components/Toggle";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }

  .small-radio {
    max-width: 30rem;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
`;

const FormLine = styled.div`
  display: flex;
  > div {
    margin-right: 1.6rem;
  }
`;

type Props = {
  currentProject: squadTypes.Project,
  defaultOption: Object,
  fetchProjectDetails: Function,
  formHasDefaultDates: any,
  handleSubmit: Function,
  match: Object,
  push: Function,
  toggleProjectNav: Function,
  ui: Object,
  updateProject: Function,
  workspaceNavUI: Object
};

type Myprops = {
  input: Object
};
class MyDatetime extends Component<Myprops> {
  onChange = moment => {
    const { input } = this.props;
    let isoDate;
    try {
      isoDate = moment.toISOString();
    } catch (e) {
      isoDate = moment;
    }

    input.onChange(isoDate);
  };

  render() {
    const { input } = this.props;
    const valueMoment = moment(input.value);
    return <Datetime {...this.props} input={true} value={valueMoment} onChange={this.onChange} />;
  }
}

type State = {
  selectedOption: ?Object,
  projectOptions: Array<Object>
};

class ProjectSettings extends Component<Props, State> {
  state = {
    selectedOption: null,
    projectOptions: []
  };

  debouncedFetchProjectsForAutocomplete: Function;
  constructor(props) {
    super(props);
    this.debouncedFetchProjectsForAutocomplete = _.debounce(this._fetchProjectsForAutocomplete, 300);
  }

  componentDidMount() {
    const { currentProject, defaultOption, fetchProjectDetails, match } = this.props;
    // Only fetch the project if we don't already have the data
    if (!currentProject || match.params.project !== currentProject.id) {
      fetchProjectDetails(match.params.project);
    }

    if (currentProject && !defaultOption && currentProject.parent_id) {
      this.props.fetchProjectDetails(currentProject.parent_id);
    }

    if (defaultOption) {
      const selectedOption = this.props.defaultOption;
      this.setState({
        selectedOption
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentProject && !prevProps.currentProject && this.props.currentProject.parent_id) {
      this.props.fetchProjectDetails(this.props.currentProject.parent_id);
    }

    if (this.props.defaultOption && !prevProps.defaultOption) {
      const selectedOption = this.props.defaultOption;
      this.setState({
        selectedOption
      });
    }
  }

  updateProject = projectValues => {
    const { selectedOption } = this.state;
    let parent_id = null;
    if (selectedOption) {
      parent_id = selectedOption.value;
    }

    projectValues = {
      ...projectValues,
      parent_id
    };

    this.props.updateProject(projectValues.id, projectValues);
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchProjectsForAutocomplete = (inputValue: ?String, callback: Function) => {
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const projectsApiURL = `/workspaces/${slug}/projects`;
    let params = {
      title: inputValue,
      limit: 15
    };
    if (!inputValue) {
      params = {
        limit: 15
      };
    }

    request.get(projectsApiURL, { params }).then(response => {
      const projects = response.data;
      if (projects && projects.length > 0) {
        const projectOptions = projects.map(project => {
          return {
            value: project.id,
            label: project.title,
            path: project.path
          };
        });
        callback(projectOptions);
      } else {
        callback();
      }
    });
  };

  _formatProjectOptionLabel = (props, params) => {
    if (params.context === "menu") {
      return (
        <div>
          <div className="option-label">{props.label}</div>
          <div className="option-path">Pages / {props.path.label}</div>
        </div>
      );
    } else {
      return <div className="">{props.label}</div>;
    }
  };

  handleProjectSelect = (option: any, actionMeta: any) => {
    if (option) {
      this.setState({ selectedOption: option });
    } else {
      this.setState({ selectedOption: null });
    }
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentProject, defaultOption, handleSubmit, ui, formHasDefaultDates } = this.props;
    const { selectedOption } = this.state;
    const { errorFields } = ui;

    if (!currentProject) {
      return (
        <WorkspaceLayout secondNav={<div />}>
          <Container>
            <Loader />
          </Container>
        </WorkspaceLayout>
      );
    }

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Update";

    return (
      <WorkspaceLayout secondNav={<ProjectSettingNav project={currentProject} />}>
        <Container>
          <Header>
            <span>
              <Link to={`/pages/${currentProject.id}`}>{currentProject.title}</Link>
            </span>
            <h1>Page Details</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <form onSubmit={handleSubmit(this.updateProject)}>
              <FormField>
                <label htmlFor="title">Page title</label>
                <Field component="input" type="text" className={`tiny ${this.getErrorClass("title")}`} name="title" />
                {errorFields["title"] && (
                  <span className={`${this.getErrorClass("title")}`}>
                    <small>{errorFields["title"]}</small>
                  </span>
                )}
              </FormField>
              <FormField>
                <label htmlFor="title">Parent page</label>
                <AsyncSelect
                  isClearable
                  classNamePrefix="react-select"
                  placeholder="Select parent page"
                  defaultValue={defaultOption}
                  value={selectedOption}
                  loadOptions={this.debouncedFetchProjectsForAutocomplete}
                  defaultOptions={true}
                  className={`${this.getErrorClass("parent_id")} tiny-select`}
                  //classNamePrefix="react-filter"
                  onChange={this.handleProjectSelect}
                  formatOptionLabel={this._formatProjectOptionLabel}
                />
                {errorFields["parent_id"] && (
                  <span className={`${this.getErrorClass("parent_id")}`}>
                    <small>{errorFields["parent_id"]}</small>
                  </span>
                )}
              </FormField>
              <RadioField className="bottom-space small-radio">
                <label>Update frequency</label>
                <div className="radio-group">
                  <Field component="input" type="radio" name="update_frequency" id="weekly" value="weekly" />
                  <label htmlFor="weekly">Weekly</label>
                  <Field component="input" type="radio" name="update_frequency" id="monthly" value="monthly" />
                  <label htmlFor="monthly">Monthly</label>
                  <Field component="input" type="radio" name="update_frequency" id="quarterly" value="quarterly" />
                  <label htmlFor="quarterly">Quarterly</label>
                </div>
                <span className={`${this.getErrorClass("update_frequency")}`}>
                  <small>{errorFields["update_frequency"]}</small>
                </span>
              </RadioField>
              <FormField>
                <label htmlFor="description">Summary</label>
                <Field
                  component="textarea"
                  type="text"
                  className={`small ${this.getErrorClass("description")}`}
                  name="description"
                />
                <span className={`${this.getErrorClass("description")}`}>
                  {errorFields["description"] && <small>{errorFields["description"]}</small>}
                  {!errorFields["description"] && (
                    <small className="light">
                      Add a description to your page. You can use{" "}
                      <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank" rel="noopener noreferrer">
                        Markdown
                      </a>{" "}
                      to format your text.
                    </small>
                  )}
                </span>
              </FormField>
              <h2>Page defaults</h2>
              <p>Set the default settings for the goals in that page. This will only affect newly created goals.</p>
              <FormField>
                <ToggleContainer>
                  <Toggle>
                    <Field
                      component="input"
                      className="tgl tgl-light"
                      id="cb1"
                      type="checkbox"
                      name="has_default_dates"
                    />
                    <label className="tgl-btn" htmlFor="cb1" />
                    <div>Enable default dates</div>
                  </Toggle>
                </ToggleContainer>
              </FormField>
              {formHasDefaultDates && (
                <FormLine>
                  <FormField>
                    <label>Default start date</label>
                    <Field
                      component={MyDatetime}
                      name="default_start_date"
                      props={{
                        dateFormat: "D MMM YYYY",
                        inputProps: { placeholder: "Start date" },
                        timeFormat: false,
                        closeOnSelect: true
                      }}
                    />
                  </FormField>
                  <FormField>
                    <label>Default due date (optional)</label>
                    <Field
                      component={MyDatetime}
                      name="default_deadline"
                      props={{
                        dateFormat: "D MMM YYYY",
                        inputProps: { placeholder: "Due date" },
                        timeFormat: false,
                        closeOnSelect: true
                      }}
                    />
                  </FormField>
                </FormLine>
              )}

              <FormActions>
                <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                  {submitText}
                </button>
              </FormActions>
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentProject = projectsSelectors.getObjectById(state.projects.byId, props.match.params.project);
  let defaultOption = null;
  if (currentProject) {
    const defaultParent = projectsSelectors.getObjectById(state.projects.byId, currentProject.parent_id);
    if (defaultParent) {
      defaultOption = {
        value: defaultParent.id,
        label: defaultParent.title,
        path: defaultParent.path
      };
    }
  }
  const selector = formValueSelector("projectSettings");
  return {
    currentProject,
    defaultOption,
    initialValues: currentProject,
    ui: state.ui.projectSettings,
    workspaceNavUI: state.ui.workspaceNav,
    formHasDefaultDates: selector(state, "has_default_dates")
  };
};

const mapDispatchToProps = {
  push,
  fetchProjectDetails: projectsOperations.fetchProjectDetails,
  toggleProjectNav: uiOperations.toggleProjectNav,
  updateProject: projectsOperations.updateProject
};

const _ProjectSettings = reduxForm({
  form: "projectSettings"
})(ProjectSettings);

export default connect(mapStateToProps, mapDispatchToProps)(_ProjectSettings);
