// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { workspacesOperations } from "state/ducks/workspaces";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import * as constants from "config/constants";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm } from "redux-form";
import FormFlag from "v2/components/FormFlag";
import { Link } from "react-router-dom";

import Loader from "v2/components/Loader";
import ReactTooltip from "react-tooltip";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

const FeaturePanel = styled.div`
  &.disabled {
    border: 1px solid ${colors.grey};
    padding: ${spacing.x1};
    margin-bottom: ${spacing.x2};
    border-radius: 4px;
    opacity: 0.7;
    width: 50%;
  }
`;

const Info = styled.div`
  background-color: ${colors.infoBg};
  padding: ${spacing.x2};
  margin: ${spacing.x1} 0;
  width: 50%;
  border-radius: 4px;
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentWorkspace: squadTypes.Workspace,
  handleSubmit: Function,
  ui: Object,
  updateWorkspace: Function
};

class WorkspaceSettingsSecurity extends Component<Props> {
  updateWorkspace = workspaceValues => {
    const { currentWorkspace } = this.props;
    let { accepted_domains, restricted_domains, restricted_ips, otp_required } = workspaceValues;
    accepted_domains = accepted_domains ? accepted_domains.replace(" ", "").split(",") : [];
    restricted_domains = restricted_domains ? restricted_domains.replace(" ", "").split(",") : [];
    restricted_ips = restricted_ips ? restricted_ips.replace(" ", "").split(",") : [];
    const params = {
      accepted_domains,
      restricted_domains,
      restricted_ips,
      otp_required
    };
    this.props.updateWorkspace(currentWorkspace.id, params);
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentMembership, currentWorkspace, handleSubmit, ui } = this.props;
    const { errorFields } = ui;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Update";

    const hasAccessControlPlan = constants.BILLING_ACCESS_CONTROL_PLANS.includes(
      currentWorkspace.stripe_cache_subscription_plan
    );
    const classDisabled = !hasAccessControlPlan ? "disabled" : "";

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>Security</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <form onSubmit={handleSubmit(this.updateWorkspace)}>
              {currentWorkspace.auth_type === "shared" && (
                <FormField>
                  <h2>Workspace signups</h2>
                  <label htmlFor="accepted_domains">Email domains allowed to join automatically</label>
                  <Field
                    component="input"
                    type="text"
                    className={`small ${this.getErrorClass("accepted_domains")}`}
                    placeholder={"Ex: acme.com"}
                    name="accepted_domains"
                  />
                  {!errorFields["accepted_domains"] && (
                    <span className="light">
                      <small>Users with these email domains can automatically join your workspace.</small>
                    </span>
                  )}
                  {errorFields["accepted_domains"] && (
                    <span className={`${this.getErrorClass("accepted_domains")}`}>
                      <small>{errorFields["accepted_domains"]}</small>
                    </span>
                  )}
                </FormField>
              )}
              <h2>Access restrictions and whitelisting</h2>
              {!hasAccessControlPlan && (
                <Info>
                  {currentMembership.role === "owner" && <Link to="/settings/billing">Upgrade your plan</Link>}
                  {currentMembership.role !== "owner" && <span>Upgrade your plan</span>} to be able to configure access
                  restrictions.
                </Info>
              )}
              {!hasAccessControlPlan && (
                <ReactTooltip id="businessPlanOnly" place="right" type="dark" effect="solid" delayShow={200}>
                  You need to upgrade your plan to use access restrictions
                </ReactTooltip>
              )}
              <FeaturePanel data-tip data-for="businessPlanOnly" className={classDisabled}>
                <p>
                  Use the setting below to restrict access to the content of your workspace (pages, goals, status
                  updates...).
                </p>
                {currentWorkspace.auth_type === "shared" && (
                  <FormField>
                    <label htmlFor="restricted_domains">Require 2-factor authentication to access content</label>
                    <Field
                      component="select"
                      className={`super-tiny`}
                      name="otp_required"
                      disabled={!hasAccessControlPlan}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Field>
                    {!errorFields["otp_required"] && (
                      <span className="light">
                        <small>
                          Prevent users from seeing content unless they have 2-factor authentication enabled
                        </small>
                      </span>
                    )}
                    {errorFields["otp_required"] && (
                      <span className={`${this.getErrorClass("otp_required")}`}>
                        <small>{errorFields["otp_required"]}</small>
                      </span>
                    )}
                  </FormField>
                )}
                <FormField>
                  <label htmlFor="restricted_domains">Restrict access to certain email domains</label>
                  <Field
                    component="input"
                    type="text"
                    className={`small ${this.getErrorClass("restricted_domains")}`}
                    placeholder={"Ex: acme.com"}
                    name="restricted_domains"
                    disabled={!hasAccessControlPlan}
                  />
                  {!errorFields["restricted_domains"] && (
                    <span className="light">
                      <small>Only users with these email domains will be able to access content.</small>
                    </span>
                  )}
                  {errorFields["restricted_domains"] && (
                    <span className={`${this.getErrorClass("restricted_domains")}`}>
                      <small>{errorFields["restricted_domains"]}</small>
                    </span>
                  )}
                </FormField>
                <FormField>
                  <label htmlFor="restricted_ips">Restrict access to certain IP addresses</label>
                  <Field
                    component="input"
                    type="text"
                    className={`small ${this.getErrorClass("restricted_ips")}`}
                    placeholder={"Ex: 104.192.143.1, 2401:1d80:1010::150, 104.192.143.0/28"}
                    name="restricted_ips"
                    disabled={!hasAccessControlPlan}
                  />
                  {!errorFields["restricted_ips"] && (
                    <span className="light">
                      <small>Only allow access to this workspace from the following IPs.</small>
                    </span>
                  )}
                  {errorFields["restricted_ips"] && (
                    <span className={`${this.getErrorClass("restricted_ips")}`}>
                      <small>{errorFields["restricted_ips"]}</small>
                    </span>
                  )}
                </FormField>
              </FeaturePanel>

              <FormActions>
                <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                  {submitText}
                </button>
              </FormActions>
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => {
  const accepted_domains = state.session.currentWorkspace.accepted_domains.toString();
  const restricted_domains = state.session.currentWorkspace.restricted_domains.toString();
  const restricted_ips = state.session.currentWorkspace.restricted_ips.toString();
  const { otp_required } = state.session.currentWorkspace;
  return {
    currentMembership: state.session.currentMembership,
    currentWorkspace: state.session.currentWorkspace,
    initialValues: { accepted_domains, restricted_domains, restricted_ips, otp_required },
    ui: state.ui.workspaceSettingsSecurity
  };
};

const mapDispatchToProps = {
  updateWorkspace: workspacesOperations.updateWorkspace
};

const _WorkspaceSettingsSecurity = reduxForm({
  form: "workspaceSettingsSecurity"
})(WorkspaceSettingsSecurity);

export default connect(mapStateToProps, mapDispatchToProps)(_WorkspaceSettingsSecurity);
