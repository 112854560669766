// @flow
import { createReducer } from "state/utils";
import * as commentsTypes from "state/ducks/comments/types";

const INITIAL_STATE = {
  isFetchingComments: true
};

export default createReducer(INITIAL_STATE)({
  [commentsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    isFetchingComments: false
  }),
  [commentsTypes.FETCH_LIST_COMPLETED]: state => ({
    isFetchingComments: false
  }),
  [commentsTypes.FETCH_LIST_REQUESTED]: state => ({
    isFetchingComments: true
  })
});
