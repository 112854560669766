// @flow
import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

const Container = styled.div`
  padding: ${spacing.x4};
  background: #f8f8f8;
  height: 100%;
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.black};
    font-weight: 600;
    font-size: 1.4rem;
  }
  .title {
    font-size: 1.8rem;
    font-weight: 600;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: ${colors.subtleText};
    transition: all ease 0.2s;
    &.active,
    &:hover {
      color: ${colors.black};
      font-weight: 600;
      border-left: 4px solid ${colors.blockBorder};
      padding-left: ${spacing.x2};
    }

    display: inline-block;
    margin: ${spacing.x1} 0;
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: ${colors.blockBorder};
  }
`;

export default (props: Object) => (
  <Container>
    <Header>
      <span>
        <Link to={`/pages/${props.project.id}`}>{props.project.title}</Link>
      </span>
      <div className="title">Page Settings</div>
    </Header>
    <Main>
      <NavLink to={`/pages/${props.project.id}/settings`} exact={true}>
        Page details
      </NavLink>
      <NavLink to={`/pages/${props.project.id}/settings/notifications`}>Notifications</NavLink>
      <hr />
      <NavLink to={`/pages/${props.project.id}/settings/delete`}>Delete page</NavLink>
    </Main>
  </Container>
);
