// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { accountOperations } from "state/ducks/account";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import { uiOperations } from "state/ducks/ui";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import AccountSettingsNav from "v2/components/AccountSettingsNav";
import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm } from "redux-form";
import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: ${colors.blockBorder};
    margin: ${spacing.x4} 0;
  }
`;

const OTPSettings = styled.div`
  .download-button {
    margin-right: 1.6rem;
  }
`;

const BackupCodes = styled.div``;

type Props = {
  clearPage: Function,
  create2FABackupCodes: Function,
  currentUser: squadTypes.User,
  handleSubmit: Function,
  ui: Object,
  create2FA: Function,
  update2FA: Function
};

class AccountSettings2FA extends Component<Props> {
  componentDidMount() {
    this.props.clearPage();
  }

  create2FABackupCodes = values => {
    const params = {
      current_password: values.current_password_codes
    };
    this.props.create2FABackupCodes(params);
  };

  update2FA = values => {
    const params = {
      ...values,
      otp_required_for_login: false
    };
    this.props.update2FA(params);
  };

  render() {
    const { currentUser, handleSubmit, ui } = this.props;

    const generateText = ui.isGeneratingCode ? <Loader size="small" /> : "Generate Backup Codes";

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.isUpdating ? <Loader size="small" /> : "Disable 2FA";

    return (
      <WorkspaceLayout secondNav={<AccountSettingsNav user={currentUser} />}>
        <Container>
          <Header>
            <h1>2-Factor authentication</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <h2>
              2-factor authentication is enabled &nbsp;
              <span role="img" aria-label="confirmed">
                &#x2714;
              </span>
            </h2>
            <p>We strongly suggest that you generate and print backup codes using the button below.</p>
            <p>
              These are single-use tokens to be used instead of your two-factor token in the event that you lose access
              to your two-factor device.
            </p>
            <p>
              <b>Note: any existing backup codes will be invalidated by clicking the button.</b>
            </p>
            <form onSubmit={handleSubmit(this.create2FABackupCodes)}>
              <OTPSettings>
                <FormField>
                  <label htmlFor="current_password">Current password</label>
                  <Field
                    component="input"
                    type="password"
                    className={`tiny`}
                    required={true}
                    name="current_password_codes"
                  />
                </FormField>
                <FormActions>
                  <button type="submit" className="primary auto-size" disabled={ui.formState === "requesting"}>
                    {generateText}
                  </button>
                </FormActions>
              </OTPSettings>
            </form>
            {ui.backupCodes && (
              <BackupCodes>
                <h2>Backup Codes</h2>
                <p>
                  Please print these codes and store them in a secured location. This is the last time you will be able
                  to see them.
                </p>
                <ul>
                  {ui.backupCodes.map((code, index) => {
                    return <li key={index}>{code}</li>;
                  })}
                </ul>
              </BackupCodes>
            )}
            <hr />
            <h2>Disable 2-Factor Authentication</h2>
            <p className="attention">
              Disabling 2-factor authentication will reset your 2-factor secret. You will have to scan your QR code
              again the next time you enable 2-factor authentication.
            </p>
            <form onSubmit={handleSubmit(this.update2FA)}>
              <OTPSettings>
                <p>Add your password and a valid authentication token to disable 2-factor authentication.</p>
                <FormField>
                  <label htmlFor="current_password">Current password</label>
                  <Field component="input" type="password" className={`tiny`} required={true} name="current_password" />
                </FormField>
                <FormField>
                  <label htmlFor="current_password">Validation code</label>
                  <Field component="input" type="text" className={`tiny`} required={true} name="otp_validation" />
                </FormField>
                <FormActions>
                  <button type="submit" className="danger-secondary auto-size" disabled={ui.formState === "requesting"}>
                    {submitText}
                  </button>
                </FormActions>
              </OTPSettings>
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  ui: state.ui.accountSettings2FA
});

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  create2FABackupCodes: accountOperations.create2FABackupCodes,
  update2FA: accountOperations.update2FA
};

const _AccountSettings2FA = reduxForm({
  form: "accountSettings2FADisable"
})(AccountSettings2FA);

export default connect(mapStateToProps, mapDispatchToProps)(_AccountSettings2FA);
