// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import * as squadTypes from "squadTypes";
import { push } from "react-router-redux";
import styled from "styled-components";

import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

// V2 Components
import FormFlag from "v2/components/FormFlag";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import Loader from "v2/components/Loader";
import ProjectSettingNav from "v2/components/ProjectSettingNav";
import Select from "v2/components/Select";
import SlackIcon from "v2/components/_assets/IconSlack.png";

import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x1};
  input {
    margin-right: ${spacing.x1};
  }
`;

const StyledForm = styled.form`
  .react-filter__control {
    box-shadow: none;
    min-width: 15rem;
    margin-right: ${spacing.x2};
  }

  .react-filter__control--is-focused {
    box-shadow: none;
  }

  .react-filter__value-container {
    padding: 0 0 0 ${spacing.x1};
    margin: 0;

    input {
      font-size: 1.4rem !important;
      height: 2.4rem !important;
      margin: 0;
      padding: 0;
    }
  }
`;

const IntegrationBlock = styled.div`
  margin-bottom: ${spacing.x3};
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: ${spacing.x3};
  display: flex;
`;

const IntegrationAction = styled.div`
  width: 20rem;
  header {
    text-align: center;
    h3 {
      margin-bottom: ${spacing.x2};
      position: relative;
      span {
        display: inline-block;
        font-size: 1.2rem;
        background-color: ${colors.yellow};
        border-radius: 3px;
        padding: 0.2rem 0.8rem;
        line-height: 1.6rem;
        position: relative;
        right: 0;
        top: -0.2rem;
      }
    }
    margin-bottom: ${spacing.x3};
  }
  display: flex;
  flex-direction: column;
  img {
    width: 80px;
  }
  .connection-details {
    text-align: center;
  }
`;

const IntegrationDescription = styled.div`
  .sorry {
    border-radius: 4px;
    padding: ${spacing.x2};
    background-color: ${colors.warningBg};
  }

  ul {
    list-style-type: disc;
    margin-left: ${spacing.x6};
    margin-bottom: ${spacing.x2};
  }
`;

const FormLine = styled.div`
  display: flex;
  label {
    margin-right: ${spacing.x1};
  }
`;

const Info = styled.div`
  background-color: ${colors.infoBg};
  padding: ${spacing.x2};
  margin: 0;
  border-radius: 4px;
  margin-bottom: ${spacing.x2};
  max-width: 50rem;
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentProject: squadTypes.Project,
  currentWorkspace: squadTypes.Workspace,
  deleteProject: Function,
  fetchProjectDetails: Function,
  handleSubmit: Function,
  match: Object,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  push: Function,
  toggleProjectNav: Function,
  ui: Object,
  updateProject: Function,
  workspaceNavUI: Object
};

type State = {
  selectedSlackChannel: ?Object,
  slackChannelOptions: Array<string>,
  isLoadingOptions: boolean
};

class ProjectSettingsNotifications extends Component<Props, State> {
  state = {
    selectedSlackChannel: null,
    slackChannelOptions: [],
    isLoadingOptions: false
  };

  componentDidMount() {
    const { currentProject, fetchProjectDetails, match } = this.props;
    // Only fetch the project if we don't already have the data
    if (!currentProject || match.params.project !== currentProject.id) {
      fetchProjectDetails(match.params.project);
    }
    if (currentProject) {
      this._fetchSlackChannelsForAutocomplete();
      const selectedSlackChannel = {
        value: currentProject.slack_channel_id,
        label: currentProject.slack_channel_name
      };
      this.setState({
        selectedSlackChannel
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentProject && this.props.currentProject) {
      this._fetchSlackChannelsForAutocomplete();
      const { currentProject } = this.props;
      const selectedSlackChannel = {
        value: currentProject.slack_channel_id,
        label: currentProject.slack_channel_name
      };
      this.setState({
        selectedSlackChannel
      });
    }
  }

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchSlackChannelsForAutocomplete = () => {
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const { currentProject } = this.props;
    if (!currentProject) {
      return false;
    }
    const slackChannelsAPIUrl = `/workspaces/${slug}/slack_channels`;
    this.setState({
      isLoadingOptions: true
    });
    request.get(slackChannelsAPIUrl).then(response => {
      const channels = response.data.slack_channels;
      if (channels && channels.length > 0) {
        const slackChannelOptions = channels.map(channel => {
          return {
            value: channel.id,
            label: `#${channel.name}`
          };
        });
        this.setState({
          slackChannelOptions,
          isLoadingOptions: false
        });
      } else {
        this.setState({
          slackChannelOptions: [],
          isLoadingOptions: false
        });
      }
    });
  };

  handleSlackChannelSelect = (option: any, actionMeta: any) => {
    if (option) {
      this.setState({ selectedSlackChannel: option });
    } else {
      this.setState({ selectedSlackChannel: null });
    }
  };

  updateProject = projectValues => {
    const { selectedSlackChannel } = this.state;
    let slack_channel_id = null;
    let slack_channel_name = null;
    if (selectedSlackChannel) {
      slack_channel_name = selectedSlackChannel.label;
      slack_channel_id = selectedSlackChannel.value;
    }

    const { notify_weekly, notify_all_events } = projectValues;

    const params = {
      notify_all_events,
      notify_weekly,
      slack_channel_id,
      slack_channel_name
    };

    this.props.updateProject(projectValues.id, params);
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentMembership, currentProject, currentWorkspace, handleSubmit, ui } = this.props;
    const { errorFields } = ui;
    const { selectedSlackChannel, slackChannelOptions } = this.state;

    if (!currentProject) {
      return (
        <WorkspaceLayout secondNav={<div />}>
          <Container>
            <Loader />
          </Container>
        </WorkspaceLayout>
      );
    }

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Update";

    return (
      <WorkspaceLayout secondNav={<ProjectSettingNav project={currentProject} />}>
        <Container>
          <Header>
            <span>
              <Link to={`/pages/${currentProject.id}`}>{currentProject.title}</Link>
            </span>
            <h1>Page notifications</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            {!currentWorkspace.slack_team_name && (
              <IntegrationBlock>
                <IntegrationAction>
                  <header>
                    <h3>Slack</h3>
                    <img src={SlackIcon} alt="Slack" />
                  </header>
                </IntegrationAction>
                <IntegrationDescription>
                  <p>Connect your workspace to Slack to enable Slack notifications for your pages.</p>
                  <ul>
                    <li>Our bot Tabby will send reminders to your teammates in Slack.</li>
                    <li>Get weekly reminders and progress reports in a Slack channel of your choice for your pages.</li>
                    <li>See previews for your pages and goals when sharing links on Slack.</li>
                  </ul>
                  {["admin", "owner"].indexOf(currentMembership.role) >= 0 && (
                    <p>
                      <Link to="/settings/integrations">Connect your workspace to Slack.</Link>
                    </p>
                  )}
                  {["admin", "owner"].indexOf(currentMembership.role) < 0 && (
                    <p>
                      <b>
                        Please ask an administrator to enable the Slack integration. You will then be able to configure
                        Slack notifications for your pages.
                      </b>
                    </p>
                  )}
                </IntegrationDescription>
              </IntegrationBlock>
            )}
            {currentWorkspace.slack_team_name && (
              <StyledForm onSubmit={handleSubmit(this.updateProject)} autoComplete="off">
                <Info>Invite our bot @Tabby to your private Slack channels if you do not see them listed below.</Info>
                <FormField>
                  <FormLine>
                    <label htmlFor="slack_channel_id">Slack channel</label>
                    {this.state.isLoadingOptions && <Loader size="small" />}
                  </FormLine>
                  <Select
                    value={selectedSlackChannel}
                    options={slackChannelOptions}
                    classNamePrefix="react-filter"
                    isClearable={true}
                    onChange={this.handleSlackChannelSelect}
                    className={`${this.getErrorClass("slack_channel_id")} tiny-select`}
                  />

                  <span className={`${this.getErrorClass("slack_channel_id")}`}>
                    {errorFields["slack_channel_id"] && <small>{errorFields["slack_channel_id"]}</small>}
                    {!errorFields["slack_channel_id"] && (
                      <small className="light">Send notifications related to this page to a Slack channel</small>
                    )}
                  </span>
                </FormField>
                <FormField>
                  <label>Configure the notifications to your Slack channel</label>
                  <CheckboxContainer>
                    <Field name="notify_weekly" component="input" type="checkbox" /> Send weekly reminders and reports
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Field name="notify_all_events" component="input" type="checkbox" /> Send notifications for all
                    events
                  </CheckboxContainer>
                </FormField>
                <FormActions>
                  <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                    {submitText}
                  </button>
                </FormActions>
              </StyledForm>
            )}
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentProject = projectsSelectors.getObjectById(state.projects.byId, props.match.params.project);
  return {
    currentProject,
    currentMembership: state.session.currentMembership,
    currentWorkspace: state.session.currentWorkspace,
    initialValues: currentProject,
    projects: state.projects,
    ui: state.ui.projectSettings,
    workspaceNavUI: state.ui.workspaceNav
  };
};

const mapDispatchToProps = {
  push,
  updateProject: projectsOperations.updateProject,
  fetchProjectDetails: projectsOperations.fetchProjectDetails
};

const _ProjectSettingsNotifications = reduxForm({
  form: "projectSettingsNotifications"
})(ProjectSettingsNotifications);

export default connect(mapStateToProps, mapDispatchToProps)(_ProjectSettingsNotifications);
