//@flow
export const FETCH_ACCOUNT_REQUESTED = "account/FETCH_ACCOUNT_REQUESTED";
export const FETCH_ACCOUNT_COMPLETED = "account/FETCH_ACCOUNT_COMPLETED";
export const FETCH_ACCOUNT_FAILED = "account/FETCH_ACCOUNT_FAILED";

export const FETCH_ASSET_POST_URL_REQUESTED = "account/FETCH_ASSET_POST_URL_REQUESTED";
export const FETCH_ASSET_POST_URL_COMPLETED = "account/FETCH_ASSET_POST_URL_COMPLETED";
export const FETCH_ASSET_POST_URL_FAILED = "account/FETCH_ASSET_POST_URL_FAILED";

export const UPDATE_ACCOUNT_REQUESTED = "account/UPDATE_ACCOUNT_REQUESTED";
export const UPDATE_ACCOUNT_COMPLETED = "account/UPDATE_ACCOUNT_COMPLETED";
export const UPDATE_ACCOUNT_FAILED = "account/UPDATE_ACCOUNT_FAILED";

export const UPDATE_ACCOUNT_PASSWORD_REQUESTED = "account/UPDATE_ACCOUNT_PASSWORD_REQUESTED";
export const UPDATE_ACCOUNT_PASSWORD_COMPLETED = "account/UPDATE_ACCOUNT_PASSWORD_COMPLETED";
export const UPDATE_ACCOUNT_PASSWORD_FAILED = "account/UPDATE_ACCOUNT_PASSWORD_FAILED";

export const UPLOAD_ASSET_REQUESTED = "account/UPLOAD_ASSET_REQUESTED";
export const UPLOAD_ASSET_COMPLETED = "account/UPLOAD_ASSET_COMPLETED";
export const UPLOAD_ASSET_FAILED = "account/UPLOAD_ASSET_FAILED";

export const DELETE_ACCOUNT_REQUESTED = "account/DELETE_ACCOUNT_REQUESTED";
export const DELETE_ACCOUNT_COMPLETED = "account/DELETE_ACCOUNT_COMPLETED";
export const DELETE_ACCOUNT_FAILED = "account/DELETE_ACCOUNT_FAILED";

export const CREATE_2FA_REQUESTED = "account/CREATE_2FA_REQUESTED";
export const CREATE_2FA_COMPLETED = "account/CREATE_2FA_COMPLETED";
export const CREATE_2FA_FAILED = "account/CREATE_2FA_FAILED";

export const UPDATE_2FA_REQUESTED = "account/UPDATE_2FA_REQUESTED";
export const UPDATE_2FA_COMPLETED = "account/UPDATE_2FA_COMPLETED";
export const UPDATE_2FA_FAILED = "account/UPDATE_2FA_FAILED";

export const CREATE_2FA_BACKUP_CODES_REQUESTED = "account/CREATE_2FA_BACKUP_CODES_REQUESTED";
export const CREATE_2FA_BACKUP_CODES_COMPLETED = "account/CREATE_2FA_BACKUP_CODES_COMPLETED";
export const CREATE_2FA_BACKUP_CODES_FAILED = "account/CREATE_2FA_BACKUP_CODES_FAILED";
