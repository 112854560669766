//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg``;
export default (props: Object) => (
  <Logo viewBox="0 0 20 19" {...props}>
    <title id="Notifications-title">Notifications</title>
    <g id="Notifications-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Notifications-sidebar" transform="translate(-19.000000, -336.000000)">
        <g id="Notifications-Group-17-Copy" transform="translate(13.000000, 32.000000)">
          <g id="Notifications-Notifications" transform="translate(7.000000, 305.000000)">
            <g>
              <path
                d="M18,13.5023924 L0,13.5023924 C1.49116882,13.5023924 2.7,12.293347 2.7,10.8019139 L2.7,6.30111641 C2.70000005,2.82110589 5.52060611,-3.99751121e-16 9,0 C12.4793939,3.99751121e-16 15.2999999,2.82110589 15.3,6.30111641 L15.3,10.8019139 C15.3,12.293347 16.5088312,13.5023924 18,13.5023924 L18,13.5023924 Z"
                id="Notifications-Shape"
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M9,18 C10.1045695,18 11,17.1045695 11,16 L7,16 C7,17.1045695 7.8954305,18 9,18 Z"
                id="Notifications-Oval"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
