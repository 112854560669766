// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { devices } from "v2/components/styles/devices";
import { spacing } from "./styles/spacing";

import { Link } from "react-router-dom";
import Wordmark from "v2/components/_assets/tability_logo_full.png";

const Container = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${devices.mobile} {
    width: 100%;
    padding: ${spacing.x4};
    justify-content: flex-start;
  }
`;

const Header = styled.div`
  text-align: center;
  img {
    height: 4rem;
    display: block;
    margin-bottom: ${spacing.x8};
  }

  h1 {
    margin-bottom: ${spacing.x4};
  }
`;

type Props = {
  user: any
};

type State = {};

class OTPRequired extends Component<Props, State> {
  render() {
    const { user } = this.props;
    return (
      <Container>
        <Header>
          <Link to="/">
            <img src={Wordmark} alt="Tability" />
          </Link>
          <h1>2-Factor authentication is required</h1>
        </Header>
        <p>Hi {user.fullname}! There's just one more step before accessing this workspace.</p>
        <p>
          Please go to your <Link to="/account/2fa">account settings</Link> to enable 2-factor authentication.
        </p>
      </Container>
    );
  }
}

export default OTPRequired;
