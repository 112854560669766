//@flow
import { request } from "state/utils";
import * as types from "./types";
import { getSubdomain } from "state/utils/url";

const slug = getSubdomain() || "";

export const createGoalSubscription = (goal_subscriptionParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () =>
      request.post(`/workspaces/${slug}/goal_subscriptions`, { goal_subscription: goal_subscriptionParams }),
    payload: { goal_subscriptionParams }
  };
};

export const deleteGoalSubscription = (goalId: string, goal_subscriptionId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/goal_subscriptions/${goalId}`),
    payload: { id: goal_subscriptionId }
  };
};

export const fetchGoalSubscriptionList = (params?: Object) => {
  const filters = [];
  if (params) {
    if (params.page) {
      filters.push(`page=${params.page}`);
    }
  }
  const filtersQuery = filters.join("&");
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/workspaces/${slug}/goal_subscriptions?${filtersQuery}`)
  };
};

export const fetchGoalSubscriptionDetails = (goalId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/goal_subscriptions/${goalId}`)
  };
};

export const resetGoalSubscriptionList = () => ({
  type: types.RESET_LIST
});
