//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router-dom";
import * as squadTypes from "squadTypes";
import moment from "moment";
import numeral from "numeral";
import { goalsOperations } from "state/ducks/goals";
import { uiOperations } from "state/ducks/ui";

import { goalsSelectors } from "state/ducks/goals";
import { Link } from "react-router-dom";
import styled from "styled-components";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// Components
import Avatar from "v2/components/Avatar";
import ButtonIcon from "v2/components/ButtonIcon";
import DropdownMenu from "v2/components//DropdownMenu";
import IconMenu from "v2/components/svg/IconMenu";
import StatusBox from "v2/components/StatusBox";

import IconNotify from "v2/components/svg/IconNotify";
import IconList from "v2/components/svg/IconList";
import IconStatus from "v2/components/svg/IconStatus";
import ReactTooltip from "react-tooltip";
import TaskList from "v2/components/TaskList";
import Markdown from "v2/components/Markdown";

const GoalWarning = styled.div`
  margin-right: ${spacing.x1};
  a:hover {
    filter: none;
  }
  .tooltip {
    text-align: center;
  }
`;

const Block = styled.div`
  background-color: #fff;
  border: 1px solid ${colors.blockBorder};
  border-radius: 8px;
  padding: ${spacing.x2};
  margin-bottom: ${spacing.x1};
  background-color: #fff;

  .title-closed {
    text-decoration: line-through;
  }

  &.goal-closed {
    opacity: 0.5;
  }

  &:hover,
  &.selected {
    background-color: rgba(21, 21, 21, 0.05);
    cursor: pointer;
    opacity: 1;
  }

  .block-header {
    display: flex;
    justify-content: space-between;

    .updated {
      margin-right: ${spacing.x1};
      font-size: 1.2rem;
      line-height: 2.4rem;
      display: inline-block;
    }

    .block-title {
      display: flex;
      font-size: 1.6rem;
      flex: 1;
      font-weight: 400;
      a,
      .link-button {
        color: ${colors.black};
      }

      .title-open,
      .title-closed {
        margin-right: ${spacing.x1};
      }
    }
  }

  .block-subtitle {
    display: flex;
    align-items: flex-start;

    .block-subtitle-progress {
      margin-right: ${spacing.x1};
      font-size: 1.4rem;
      white-space: nowrap;
    }
  }

  .block-description {
    font-size: 1.4rem;
    font-weight: 500;
    color: ${colors.subtleText};
    margin-top: ${spacing.x1};
  }

  .block-comment {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${colors.black};
    margin-top: ${spacing.x1};

    p {
      max-width: 50rem;
    }
  }

  .block-footer {
    margin-top: ${spacing.x2};
    color: ${colors.subtleText};
    display: flex;
    justify-content: space-between;
    align-items: center;

    .block-footer-actions {
      flex: 2;
      display: flex;

      .icon {
        margin-right: ${spacing.x1};
      }
    }

    .counter {
      margin-left: 0.4rem;
    }

    .block-footer-progress {
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      max-width: 50rem;
      .block-footer-deadline {
        margin-left: ${spacing.x1};
      }

      .block-footer-progress-bar {
        display: flex;
        align-items: center;
        flex: 1;
      }
    }
  }

  .block-actions {
    margin-top: ${spacing.x1};
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
    }
  }

  .compact-menu {
    display: none;
  }

  &.compact {
    position: relative;
    padding: ${spacing.x1};
    border: 0px solid ${colors.blockBorder};
    .title-open,
    .title-closed {
      font-size: 1.6rem;
    }
    .block-subtitle-progress {
      display: none;
    }
    .block-comment {
      display: none;
    }
    .block-footer {
      display: none;
    }
    .compact-menu {
      background: #f3f3f3;
      display: none;
      position: absolute;
      z-index: 40;
      right: ${spacing.x1};
      top: 0.4rem;

      .icon-dependencies {
        margin-right: ${spacing.x1};
      }
    }

    &:hover {
      .compact-menu {
        display: flex;
      }
    }
  }

  @media ${devices.tablet} {
    .block-header {
      flex-direction: column;

      .block-subtitle {
        margin-top: ${spacing.x1};
        .block-subtitle-progress {
          margin-left: 0;
        }
      }
    }

    .block-footer {
      .block-footer-progress {
        flex: 3;
      }
      .block-footer-deadline {
        display: none;
      }
    }
  }
`;

type Props = {
  closeGoalNav?: Function,
  basePath?: string,
  deleteGoal: Function,
  goal: squadTypes.Goal,
  maskActions?: boolean,
  maskTasks?: boolean,
  showProject?: boolean,
  isEmbedded?: boolean, // used to open in a new page
  showModal: Function,
  updateGoal: Function,
  isSelected: boolean,
  className: string,
  push: Function,
  workspace?: squadTypes.Workspace
};

class GoalLine extends Component<Props> {
  _navToGoal = e => {
    // We need to identify if the event comes from an action or not
    const { goal, closeGoalNav, isEmbedded, workspace } = this.props;

    let goalUrl = "";
    if (isEmbedded && workspace) {
      goalUrl = `${workspace.url}/pages/${goal.project_id}/${goal.id}`;
      window.open(goalUrl);
    } else {
      if (this.props.basePath) {
        const url = `${this.props.basePath}?selected=${goal.id}`;
        this.props.push(url);
      } else {
        this.props.push(`/pages/${goal.project_id}/${goal.id}`);
      }
      if (closeGoalNav) {
        closeGoalNav();
      }
    }
  };

  // This function pops up the status update form.
  showStatusUpdateNewForm = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.showModal({
      contentType: "statusUpdate",
      contentAction: "new",
      parent: this.props.goal
    });
  };

  // This function pops up the dependencies form
  showDependenciesForm = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.showModal({
      contentType: "goal",
      contentAction: "dependencies",
      object: this.props.goal
    });
  };

  toggleCloseGoal = e => {
    e.preventDefault();
    const { goal, updateGoal } = this.props;
    if (goal) {
      const state = goal.state === "open" ? "closed" : "open";
      const params = { state };
      updateGoal(goal.id, params);
    }
  };

  showGoalEditForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "goal",
      contentAction: "edit",
      object: this.props.goal
    });
  };

  showMoveGoalForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "goal",
      contentAction: "move",
      object: this.props.goal
    });
  };

  closeGoal = (e: Object) => {
    const { goal, updateGoal } = this.props;
    e.preventDefault();

    const params = {
      state: "closed"
    };

    updateGoal(goal.id, params);
  };

  openGoal = (e: Object) => {
    const { goal, updateGoal } = this.props;
    e.preventDefault();

    const params = {
      state: "open"
    };

    updateGoal(goal.id, params);
  };

  deleteGoal = e => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to delete this goal?`)) {
      const id = e.currentTarget.dataset.id;
      this.props.deleteGoal(id);
    }
  };

  getMenuItems = () => {
    const { goal } = this.props;
    const items = [];
    if (goal.state === "open") {
      items.push(
        <button className="link-button" onClick={this.closeGoal}>
          Close goal
        </button>
      );
    }
    if (goal.state === "closed") {
      items.push(
        <button className="link-button" onClick={this.openGoal}>
          Re-open goal
        </button>
      );
    }
    items.push(
      <button className="link-button" onClick={this.showGoalEditForm}>
        Edit
      </button>
    );
    items.push(
      <button className="link-button" onClick={this.showMoveGoalForm}>
        Move
      </button>
    );
    items.push(
      <button className="link-button" data-id={goal.id} onClick={this.deleteGoal}>
        Delete
      </button>
    );

    return items;
  };

  render() {
    const { goal, maskActions, isSelected, showProject, isEmbedded, workspace } = this.props;
    const { owner } = goal;

    let goalUrl = `/pages/${goal.project_id}/${goal.id}`;

    if (this.props.basePath) {
      goalUrl = `${this.props.basePath}?selected=${goal.id}`;
    }
    if (isEmbedded && workspace) {
      goalUrl = `${workspace.url}/pages/${goal.project_id}/${goal.id}`;
    }

    // This gets executed if displayGoalEditForm === false
    let progressScore = goal.cached_score;
    let prctProgress = 0;
    let progressWithFormat = "";
    let colorToString = "";

    switch (goal.cached_color) {
      case "red":
        colorToString = "Off track";
        break;
      case "yellow":
        colorToString = "At risk";
        break;
      case "green":
        colorToString = "On track";
        break;
      default:
        colorToString = "Pending";
        break;
    }

    // Set the progress score using the format if provided.
    if (goal.score_format && goal.target) {
      if (goal.cached_score || goal.cached_score === 0) {
        progressScore = parseFloat(goal.cached_score);
        prctProgress = goal.prct_target_normalized * 100;
        progressWithFormat = goal.score_format.replace("_target_", numeral(progressScore).format("0[.]00a"));
      } else {
        progressScore = "?";
      }
    }

    const Progress = styled.div`
      flex: 1;
      height: 0.8rem;
      background-color: ${colors.progressBarBg};
      border-radius: 4px;
      overflow: hidden;
      margin-left: ${spacing.x1};
    `;

    const ProgressBar = styled.div`
      height: 0.8rem;
      width: ${Math.round(prctProgress)}%;
      background-color: ${colors.progressBar};
      overflow: hidden;
      border-radius: 4px;
    `;

    let className = "";

    if (isSelected) {
      className = `selected`;
    }

    const isCompact = this.props.className && this.props.className.indexOf("compact") >= 0;

    return (
      <Block onClick={this._navToGoal} className={`${className} ${this.props.className} goal-${goal.state}`}>
        <div className="block-header">
          <div className="block-title">
            {goal.cached_previous_color && <StatusBox className={`${goal.cached_previous_color} previous`} />}
            {!goal.cached_previous_color && <StatusBox className={`transparent previous`} />}
            <StatusBox className={`${goal.cached_color} ${goal.state}`} />
            {!isEmbedded && (
              <Link className={`title-${goal.state}`} to={goalUrl}>
                {goal.project && showProject && <span className="subtle">{goal.project.title} / </span>}
                {goal.title}
              </Link>
            )}
            {isEmbedded && (
              <a
                href={goalUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={`title-${goal.state}`}
                to={`/pages/${goal.project_id}/${goal.id}`}
              >
                {goal.project && showProject && <span className="subtle">{goal.project.title} / </span>}
                {goal.title}
              </a>
            )}
          </div>
          <div className="block-subtitle">
            {goal.is_pending_update && !goal.is_overdue && goal.state === "open" && (
              <GoalWarning role="img" aria-label="warning">
                <IconNotify data-tip data-for={`warning-${goal.id}`} />
                <ReactTooltip id={`warning-${goal.id}`} place="bottom" type="dark" effect="solid" className="tooltip">
                  This goal is due for an update.
                  {goal.cached_status_update_date && (
                    <span>
                      <br />
                      The last update was {moment(goal.cached_status_update_date).fromNow()}.
                    </span>
                  )}
                </ReactTooltip>
              </GoalWarning>
            )}
            {goal.is_overdue && goal.state === "open" && (
              <GoalWarning role="img" aria-label="warning">
                <IconNotify data-tip data-for={`warning-${goal.id}`} color={colors.black} />
                <ReactTooltip id={`warning-${goal.id}`} place="bottom" type="dark" effect="solid" className="tooltip">
                  This goal is overdue.
                  {goal.deadline && (
                    <span>
                      <br />
                      The deadline was {moment(goal.deadline).fromNow()}.
                    </span>
                  )}
                </ReactTooltip>
              </GoalWarning>
            )}
            {progressWithFormat && <div className="block-subtitle-progress">{progressWithFormat}</div>}
            {!progressWithFormat && <div className="block-subtitle-progress">{colorToString}</div>}
            <Avatar
              className="block-avatar"
              user={owner}
              to={`/team/${goal.membership_id}`}
              hideName={true}
              size={24}
            />
            <div className="compact-menu">
              {(goal.count_of_children_goals > 0 || goal.parent_goal_id) && (
                <div data-tip data-for={`dependencies-${goal.id}`} className="icon-dependencies">
                  <ButtonIcon onClick={this.showDependenciesForm}>
                    <IconList />
                    {goal.count_of_children_goals > 0 && <span>&nbsp;{goal.count_of_children_goals}</span>}
                  </ButtonIcon>
                  <ReactTooltip
                    id={`dependencies-${goal.id}`}
                    place="bottom"
                    type="dark"
                    effect="solid"
                    className="tooltip"
                    delayShow={500}
                  >
                    View dependencies
                  </ReactTooltip>
                </div>
              )}
              <DropdownMenu
                position="right"
                trigger={
                  <ButtonIcon>
                    <IconMenu />
                  </ButtonIcon>
                }
                items={this.getMenuItems()}
              />
            </div>
          </div>
        </div>
        {!isCompact && (
          <div className="block-comment">
            <Markdown source={goal.cached_text} />
          </div>
        )}
        {!isCompact && (
          <div className="block-footer">
            {!maskActions && (
              <div className="block-footer-actions">
                <div className="icon" data-tip data-for={`add-status-${goal.id}`}>
                  <ButtonIcon onClick={this.showStatusUpdateNewForm}>
                    <IconStatus />
                  </ButtonIcon>
                  <ReactTooltip
                    id={`add-status-${goal.id}`}
                    place="bottom"
                    type="dark"
                    effect="solid"
                    className="tooltip"
                    delayShow={500}
                  >
                    Add a new update
                  </ReactTooltip>
                </div>
                {(goal.count_of_children_goals > 0 || goal.parent_goal_id) && (
                  <div className="icon" data-tip data-for={`dependencies-${goal.id}`}>
                    <ButtonIcon onClick={this.showDependenciesForm}>
                      <IconList />
                      {goal.count_of_children_goals > 0 && <span>{goal.count_of_children_goals}</span>}
                    </ButtonIcon>
                    <ReactTooltip
                      id={`dependencies-${goal.id}`}
                      place="bottom"
                      type="dark"
                      effect="solid"
                      className="tooltip"
                      delayShow={500}
                    >
                      View dependencies
                    </ReactTooltip>
                  </div>
                )}
                <div className="icon">
                  <DropdownMenu
                    position="left"
                    trigger={
                      <ButtonIcon>
                        <IconMenu />
                      </ButtonIcon>
                    }
                    items={this.getMenuItems()}
                  />
                </div>
              </div>
            )}
            <div className="block-footer-progress">
              {(progressScore || progressScore === 0) && (goal.initial_value || goal.initial_value === 0) && (
                <div className="block-footer-progress-bar">
                  <span>{Math.round(prctProgress)}%</span>
                  <Progress>
                    <ProgressBar />
                  </Progress>
                </div>
              )}
              {goal.state === "closed" && (
                <div className="block-footer-deadline">
                  <span>Closed</span>
                </div>
              )}
              {goal.state === "open" && (
                <div className="block-footer-deadline">
                  {goal.deadline && moment(goal.deadline).isAfter(moment()) && (
                    <span data-tip data-for={`goal-${goal.id}-deadline`}>
                      {moment(goal.deadline)
                        .fromNow(true)
                        .replace("a ", "1 ")}{" "}
                      left
                    </span>
                  )}
                  {goal.deadline && moment(goal.deadline).isBefore(moment()) && (
                    <span
                      data-tip
                      data-for={`goal-${goal.id}-deadline`}
                      className={goal.state === "open" ? "danger" : ""}
                    >
                      Due {moment(goal.deadline).fromNow()}
                    </span>
                  )}
                  <ReactTooltip
                    id={`goal-${goal.id}-deadline`}
                    place="bottom"
                    type="dark"
                    effect="solid"
                    className="tooltip"
                    delayShow={50}
                  >
                    {moment(goal.deadline).format("D MMM YYYY")}
                  </ReactTooltip>
                </div>
              )}
            </div>
          </div>
        )}
        {!isCompact && !this.props.maskTasks && <TaskList goal={goal} liteView={true} />}
      </Block>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentGoal: goalsSelectors.getObjectById(state.goals.byId, props.match.params.goal),
  memberships: state.memberships
});

const mapDispatchToProps = {
  push,
  deleteGoal: goalsOperations.deleteGoal,
  showModal: uiOperations.showModal,
  updateGoal: goalsOperations.updateGoal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoalLine));
