// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { notificationsOperations } from "state/ducks/notifications";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

import Avatar from "v2/components/Avatar";
import BotAvatar from "v2/components/_assets/tabbot-surprised.png";
import IconNotify from "v2/components/svg/IconNotify";
import Markdown from "v2/components/Markdown";
import StatusBox from "v2/components/StatusBox";
import EmptyStateImg from "./empty_notifications_tabby.png";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// V2 Components
import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${devices.tablet} {
    flex-direction: column;
  }
`;

const HeaderLeft = styled.div`
  .user-profile {
    display: flex;
    align-items: center;
    margin-top: ${spacing.x1};

    a {
      margin-right: ${spacing.x1};
    }
  }
`;

const HeaderRight = styled.div`
  @media ${devices.tablet} {
    margin-top: ${spacing.x1};
  }
`;

const Main = styled.div``;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: normal;
  line-height: normal;
  font-weight: normal;

  th {
    color: ${colors.subtleText};
  }

  th,
  td {
    border: 0;
    border-collapse: collapse;
    text-align: left;
    margin: 0;
    padding: ${spacing.x1};
    vertical-align: middle;

    &.ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
    }

    ion-icon {
      font-size: 1.8rem;
    }

    &.center {
      text-align: center;
    }
  }

  td {
    big a {
      font-size: 1.8rem;
    }
  }

  tr {
    &.top td {
      vertical-align: top !important;
    }
  }

  .actions {
    min-width: 200px;
    text-align: right;
    white-space: nowrap;

    button,
    a {
      margin-left: ${spacing.x1};
    }

    a:hover {
      text-decoration: underline;
    }
  }
`;

const NotificationTR = styled.tr`
  &.unread td {
    background-color: ${colors.infoBg};
  }

  a {
    color: ${colors.black};
  }

  p {
    margin: 0;
    padding: 0;
  }

  .meta {
    color: ${colors.black};
    display: flex;
  }
  .comment {
    font-family: "Helvetica";
    font-style: italic;
    padding: ${spacing.x1} 0;
  }

  .content {
    padding-top: ${spacing.x2};
  }
`;

const AvatarContainer = styled.div`
  img {
    width: 3.2rem;
  }

  position: relative;
  .super {
    position: absolute;
    top: 0;
    right: 10px;
  }
`;

const EmptyState = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20rem;

    div {
      text-align: center;
    }
    img {
      height: 164px;
    }
    h3 {
      padding: ${spacing.x2} 0;
    }
    .button {
      width: 24rem;
    }
  }
`;

type Props = {
  fetchNotificationList: Function,
  fetchUnseenCount: Function,
  massUpdateNotification: Function,
  updateNotification: Function,
  resetNotificationList: Function,
  notifications: squadTypes.NormalizedList<squadTypes.Notification>,
  ui: Object
};

type State = {};

class NotificationList extends Component<Props, State> {
  componentDidMount() {
    this.props.resetNotificationList();
    this.markAllAsSeen();
  }

  // This function extracts the current page from the Redux state and
  // fetches the next page.
  fetchMore() {
    const { notifications } = this.props;
    const params = {
      page: notifications.currentPage + 1
    };
    return this.props.fetchNotificationList(params);
  }

  markAsRead = e => {
    const notificationId = e.currentTarget.dataset.id;
    this.props.updateNotification(notificationId, { is_read: true });
    return true;
  };

  markAllAsRead = () => {
    const params = {
      is_read: true
    };
    this.props.massUpdateNotification(params).then(() => {
      this.props.resetNotificationList();
      this.fetchMore();
    });
  };

  markAllAsSeen = () => {
    const params = {
      is_seen: true
    };
    this.props.massUpdateNotification(params).then(() => {
      this.fetchMore();
      this.props.fetchUnseenCount();
    });
  };

  render() {
    const { notifications, ui } = this.props;

    return (
      <WorkspaceLayout>
        <Container>
          <Header>
            <HeaderLeft>
              <h1>Notifications</h1>
            </HeaderLeft>
            <HeaderRight>
              <button className="primary" onClick={this.markAllAsRead}>
                Mark all as read
              </button>
            </HeaderRight>
          </Header>
          <Main>
            {ui.isFetchingNotifications && <Loader />}
            {!ui.isFetchingNotifications && notifications.allIds.length === 0 && (
              <EmptyState>
                <div>
                  <div>
                    <img src={EmptyStateImg} alt="No projects" />
                  </div>
                  <h3>You don't have any notifications.</h3>
                </div>
              </EmptyState>
            )}
            {!ui.isFetchingNotifications && notifications.allIds.length > 0 && (
              <p className="light">
                You can manage your notification preferences in your{" "}
                <Link to="/account/notifications">account settings.</Link>
              </p>
            )}
            {!ui.isFetchingNotifications && notifications.allIds.length > 0 && (
              <Table>
                <tbody>
                  {notifications.allIds.map(notificationId => {
                    const notification = notifications.byId[notificationId];
                    if (!notification) {
                      return false;
                    }
                    const userMeta = JSON.parse(notification.source_meta);
                    const unseenClass = notification.is_seen ? "" : "unseen";
                    const unreadClass = notification.is_read ? "" : "unread";

                    return (
                      <NotificationTR key={notification.id} className={`${unseenClass} ${unreadClass} top`}>
                        {userMeta && (!userMeta.type || (userMeta.type && userMeta.type !== "bot")) && (
                          <td>
                            <Avatar user={userMeta} size={32} hideName={true} pictureOnly={true} />
                          </td>
                        )}
                        {(!userMeta || userMeta.type === "bot") && (
                          <td>
                            <AvatarContainer>
                              <img src={BotAvatar} alt="Tabot" />
                              <div className="super">
                                {notification.notification_type === "goal.pending.flagged" && <IconNotify />}
                                {notification.notification_type === "goal.overdue.flagged" && (
                                  <IconNotify color={colors.black} />
                                )}
                              </div>
                            </AvatarContainer>
                          </td>
                        )}
                        <td className="content">
                          <Link
                            onClick={this.markAsRead}
                            data-id={notification.id}
                            to={`${notification.notification_path}`}
                          >
                            <div>
                              <div className="meta">
                                {notification.notification_type === "status_update.created" && (
                                  <StatusBox className={JSON.parse(notification.target_meta).color} />
                                )}
                                <Markdown source={notification.notification_text} />
                              </div>
                              {notification.notification_type === "comment.status_update.created" && (
                                <div className="comment">
                                  <Markdown source={JSON.parse(notification.target_meta).text} />
                                </div>
                              )}
                            </div>
                          </Link>
                        </td>
                        <td className="light">{moment(notification.created_at).fromNow()}</td>
                      </NotificationTR>
                    );
                  })}
                </tbody>
              </Table>
            )}
            {notifications.hasNext && (
              <button
                className="secondary"
                onClick={e => {
                  this.fetchMore();
                  e.preventDefault();
                }}
              >
                Load more
              </button>
            )}
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications,
  ui: state.ui.notificationList
});

const mapDispatchToProps = {
  fetchNotificationList: notificationsOperations.fetchNotificationList,
  fetchUnseenCount: notificationsOperations.fetchUnseenCount,
  massUpdateNotification: notificationsOperations.massUpdateNotification,
  resetNotificationList: notificationsOperations.resetNotificationList,
  updateNotification: notificationsOperations.updateNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
