// @flow
import { createReducer } from "state/utils";
import * as accountTypes from "state/ducks/account/types";
import * as uiTypes from "./types";

/**
 * The form state will go through a cycle:
 *     initial -> requesting -> [completed|failed]
 *     initial:             initial state of the form, you can click on the submit button
 *     requesting:          submit button has been clicked and request is in progress
 *     [completed|failed]:  the request has either completed or failed
 *
 * The errorFields will contain the list of fields that have issues
 * The message field will contain the error message
 * Message type is used to display a warning or error message
 */

const INITIAL_STATE = {
  formState: "pending",
  message: "",
  messageType: "warning",
  url_2fa: "",
  errorFields: [],
  isGeneratingCode: false,
  isCreating2FA: false,
  isUpdating: false,
  backupCodes: null
};

export default createReducer(INITIAL_STATE)({
  [accountTypes.CREATE_2FA_BACKUP_CODES_FAILED]: (state, action) => {
    return {
      ...state,
      isGeneratingCode: false,
      formState: "failed",
      message: "Sorry, we could not generate your backup codes.",
      messageType: "warning"
    };
  },
  [accountTypes.CREATE_2FA_BACKUP_CODES_REQUESTED]: (state, action) => {
    return {
      ...state,
      isGeneratingCode: true,
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [accountTypes.CREATE_2FA_BACKUP_CODES_COMPLETED]: (state, action) => {
    return {
      ...state,
      isGeneratingCode: false,
      formState: "completed",
      message: "Your backup codes have been generated.",
      messageType: "success",
      backupCodes: action.response.data.backup_codes
    };
  },
  [accountTypes.UPDATE_2FA_FAILED]: (state, action) => {
    return {
      ...state,
      isUpdating: false,
      formState: "failed",
      message: "Sorry, we could not update your security preferences.",
      messageType: "warning"
    };
  },
  [accountTypes.UPDATE_2FA_REQUESTED]: (state, action) => {
    return {
      ...state,
      isUpdating: true,
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [accountTypes.UPDATE_2FA_COMPLETED]: (state, action) => {
    return {
      ...state,
      isUpdating: false,
      formState: "completed",
      message: "Your security preferences have been saved.",
      messageType: "success"
    };
  },
  [accountTypes.CREATE_2FA_FAILED]: (state, action) => {
    return {
      ...state,
      isCreating2FA: false,
      formState: "failed",
      message: "Sorry, we could not generate your 2FA secret.",
      messageType: "warning"
    };
  },
  [accountTypes.CREATE_2FA_REQUESTED]: (state, action) => {
    return {
      ...state,
      isCreating2FA: true,
      formState: "requesting",
      message: "",
      messageType: "warning"
    };
  },
  [accountTypes.CREATE_2FA_COMPLETED]: (state, action) => {
    return {
      ...state,
      isCreating2FA: false,
      formState: "completed",
      message: "",
      messageType: "success",
      url_2fa: action.response.data.url_2fa
    };
  },
  [uiTypes.CLEAR_PAGE]: () => INITIAL_STATE
});
