//@ flow
const sizes = {
  mobile: "576px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "1200px"
};

export const devices = {
  mobile: `(max-width: ${sizes.mobile})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  desktop: `(max-width: ${sizes.desktop})`,
  minTablet: `(min-width: 769px)`
};
