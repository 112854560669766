//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { tasksOperations } from "state/ducks/tasks";
import _ from "lodash/fp";

// Components
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import TaskItem from "v2/components/TaskItem";
import TaskForm from "v2/components/TaskForm";
import Loader from "v2/components/Loader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Container = styled.div`
  margin: ${spacing.x1} 0;

  .tip {
    margin-top: 8px;
    display: inline-block;
  }

  .task-list {
    max-height: 40rem;
    overflow: auto;
  }

  .header {
    display: flex;

    .action {
      display: none;
    }

    &:hover .action {
      display: inline-block;
    }
  }
`;

const LiteContainer = styled.div`
  margin-top: ${spacing.x2};

  border-top: 1px solid ${colors.blockBorder};
  padding-top: ${spacing.x2};
`;

type Props = {
  createTask: Function,
  currentTaskIds: Array<string>,
  deleteTask: Function,
  displayTip?: boolean,
  liteView: boolean, // LiteView mode is used for the dashboard
  hideTitle: boolean, // LiteView mode is used for the dashboard
  moveTask: Function,
  fetchTaskList: Function,
  goal: squadTypes.Goal,
  roadmapState?: string,
  tasks: Object,
  ui: Object,
  updateTask: Function
};

type State = {
  displayTaskInput: string,
  url: string,
  title: ?string,
  currentTasksItems: Array<string>
};

class Tasks extends Component<Props, State> {
  focusInput: any;
  state = {
    currentTasksItems: [],
    displayTaskInput: "",
    url: "",
    title: ""
  };

  componentDidMount() {
    const { goal, fetchTaskList } = this.props;
    // LiteView means the goals were preloaded at the project level. Therefore we don't need to do it for each goal.
    // Saves many queries on the project page.
    if (!this.props.liteView) {
      if (goal.tasks_count > 0) {
        fetchTaskList(goal.id);
      }
    }
    const { currentTaskIds } = this.props;
    this.setState({ currentTasksItems: currentTaskIds });
  }

  componentDidUpdate(prevProps) {
    const { currentTaskIds } = this.props;
    // LiteView means the goals were preloaded at the project level. Therefore we don't need to do it for each goal.
    // Saves many queries on the project page.

    // If the order of the tasks is different, reassign the state
    if (!_.isEqual(currentTaskIds, prevProps.currentTaskIds)) {
      this.setState({ currentTasksItems: currentTaskIds });
    }

    if (!this.props.liteView) {
      if (this.props.goal.id !== prevProps.goal.id) {
        const { goal, fetchTaskList } = this.props;
        if (goal.tasks_count > 0) {
          fetchTaskList(goal.id);
        }
      }
    }
  }

  _toggleTask = (task: squadTypes.Task) => {
    const state = task.state === "open" ? "closed" : "open";

    const params = {
      state
    };

    this.props.updateTask(task.id, params);
  };

  _getMenuItems = (task: squadTypes.Task) => {
    const items = [];
    items.push(<button className="task-button">Delete</button>);

    return items;
  };

  _onDragEnd = result => {
    const { source, destination, draggableId } = result;
    const { currentTasksItems } = this.state;
    const { moveTask } = this.props;
    if (!source || !destination) {
      return;
    }
    const nextTaskItems = this.reorderTasks(currentTasksItems, source.index, destination.index);
    this.setState({ currentTasksItems: nextTaskItems });

    const taskParams = {
      position: destination.index
    };

    moveTask(draggableId, taskParams);
  };

  // a little function to help us with reordering the result
  reorderTasks = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  render() {
    const { tasks, hideTitle, liteView, ui } = this.props;
    const { currentTasksItems } = this.state;

    // View without the headers and form
    if (liteView) {
      if (currentTasksItems.length === 0) {
        return null;
      }
      return (
        <LiteContainer
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className="label">Related Tasks</div>
          <div className="task-list">
            {currentTasksItems.map(taskId => {
              const task = tasks.byId[taskId];
              if (task) {
                return <TaskItem key={task.id} task={task} />;
              } else {
                return null;
              }
            })}
          </div>
        </LiteContainer>
      );
    }

    // View with headers and form for the goal view
    return (
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {!hideTitle && <h3>Tasks {ui.isFetchingTasks && <Loader size="small" />}</h3>}
        <DragDropContext onDragEnd={this._onDragEnd}>
          <Droppable droppableId="task-list">
            {provided => (
              <div className="task-list" ref={provided.innerRef} {...provided.droppableProps}>
                {currentTasksItems.map((taskId, index) => {
                  const task = tasks.byId[taskId];
                  if (!task) {
                    return null;
                  }
                  return (
                    <Draggable draggableId={task.id} index={index} key={task.id}>
                      {(provided, snapshot) => (
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <TaskItem isDragging={snapshot.isDragging} task={task} withDrag={true} />
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <TaskForm goal={this.props.goal} roadmapState={this.props.roadmapState} />
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentTaskIds = state.tasks.allIds.filter(taskId => {
    const task = state.tasks.byId[taskId];
    if (task && task.goal_id === props.goal.id) {
      return true;
    } else {
      return false;
    }
  });

  return {
    currentTaskIds,
    tasks: state.tasks,
    ui: state.ui.goalTasks
  };
};

const mapDispatchToProps = {
  fetchTaskList: tasksOperations.fetchTaskList,
  deleteTask: tasksOperations.deleteTask,
  moveTask: tasksOperations.moveTask,
  updateTask: tasksOperations.updateTask
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
