// @flow
import { createReducer } from "state/utils";
import * as reducerUtils from "state/utils/reducers";
import * as types from "./types";

import { INITIAL_LIST_STATE } from "config/constants";

// This is going to be a paginated list
const INITIAL_STATE = {
  ...INITIAL_LIST_STATE,
  isPaginated: true,
  unseenCount: 0
};

const normalizedStateReducer = createReducer(INITIAL_STATE)({
  // Add single object to the reducer
  [types.UPDATE_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  // Remove single object from the reducer
  // Get list of objects
  [types.FETCH_LIST_COMPLETED]: (state, action) => reducerUtils.paginateList(state, action),
  [types.RESET_LIST]: (state, action) => INITIAL_STATE,
  [types.FETCH_UNSEEN_COUNT_COMPLETED]: (state, action) => {
    return {
      ...state,
      unseenCount: action.response.data
    };
  },
  [types.FETCH_UNSEEN_COUNT_FAILED]: state => {
    return {
      ...state,
      unseenCount: 0
    };
  }
});

export default normalizedStateReducer;
