// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { accountOperations } from "state/ducks/account";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import AccountSettingsNav from "v2/components/AccountSettingsNav";
import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormFlag from "v2/components/FormFlag";
import Toggle from "v2/components/Toggle";
import QRCode from "qrcode.react";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: ${colors.blockBorder};
  }
`;

const ToggleContainer = styled.div`
  display: flex;
`;

const OTPSettings = styled.div`
  .download-button {
    margin-bottom: 1.6rem;
    width: 30rem;
  }
`;

type Props = {
  currentUser: squadTypes.User,
  current_password: string,
  fetchAccountDetails: Function,
  handleSubmit: Function,
  ui: Object,
  create2FA: Function,
  update2FA: Function,
  otp_required_for_login: boolean,
  updatePassword: Function,
  uiSession: Object
};

class AccountSettings2FA extends Component<Props> {
  // No need to fetch the currentUser on componentDidMount because it's fetched
  // in one of the routes wrapper.
  componentWillReceiveProps(nextProps) {
    if (!this.props.otp_required_for_login && nextProps.otp_required_for_login === true) {
      this.props.create2FA();
    }
  }

  update2FA = values => {
    this.props.update2FA(values);
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  render() {
    const { currentUser, handleSubmit, ui, otp_required_for_login } = this.props;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Activate";

    return (
      <WorkspaceLayout secondNav={<AccountSettingsNav user={currentUser} />}>
        <Container>
          <Header>
            <h1>2-Factor authentication</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <form onSubmit={handleSubmit(this.update2FA)}>
              <FormField>
                <ToggleContainer>
                  <Toggle>
                    <Field
                      component="input"
                      className="tgl tgl-light"
                      id="otp_required_for_login"
                      type="checkbox"
                      name="otp_required_for_login"
                    />
                    <label className="tgl-btn" htmlFor="otp_required_for_login" />
                    <div>Enable 2-factor authentication</div>
                  </Toggle>
                </ToggleContainer>
              </FormField>
              {otp_required_for_login && ui.isCreating2FA && <Loader />}
              {otp_required_for_login && !ui.isCreating2FA && (
                <OTPSettings>
                  <h2>Step 1. Download a 2-factor app</h2>
                  <p>You'll need a 2-factor app, like Google Authenticator, to proceed.</p>
                  <div>
                    <a
                      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="button download-button"
                    >
                      Download on the App Store
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="button download-button"
                    >
                      Download on Google Play
                    </a>
                  </div>
                  <h2>Step 2. Scan your QR Code</h2>
                  <p>Use your 2-factor app to scan the QR code below.</p>
                  <QRCode value={ui.url_2fa} />
                  <h2>Step 3. Verify</h2>
                  <p>Add your password and a valid authentication token to enable 2-factor authentication.</p>
                  <FormField>
                    <label htmlFor="current_password">Current password</label>
                    <Field
                      component="input"
                      type="password"
                      required={true}
                      className={`tiny ${this.getErrorClass("current_password")}`}
                      name="current_password"
                    />
                  </FormField>
                  <FormField>
                    <label htmlFor="current_password">Validation code</label>
                    <Field
                      component="input"
                      type="text"
                      required={true}
                      className={`tiny ${this.getErrorClass("otp_validation")}`}
                      name="otp_validation"
                    />
                  </FormField>
                  <FormActions>
                    <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                      {submitText}
                    </button>
                  </FormActions>
                </OTPSettings>
              )}
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const formSelector = formValueSelector("accountSettings2FAEnable");

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  initialValues: {
    otp_required_for_login: state.session.currentUser.otp_required_for_login
  },
  ui: state.ui.accountSettings2FA,
  current_password: formSelector(state, "current_password"),
  otp_required_for_login: formSelector(state, "otp_required_for_login")
});

const mapDispatchToProps = {
  updatePassword: accountOperations.updatePassword,
  create2FA: accountOperations.create2FA,
  update2FA: accountOperations.update2FA
};

const _AccountSettings2FA = reduxForm({
  form: "accountSettings2FAEnable"
})(AccountSettings2FA);

export default connect(mapStateToProps, mapDispatchToProps)(_AccountSettings2FA);
