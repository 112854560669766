//@flow
import _ from "lodash/fp";

// Used to add new objects to the existing state. Useful when listing Goals
// for multiple sections for instance.
export const addList = (state: Object, action: Object) => {
  const results = action.response.data;
  const isPaginated = false; // Make sure we don't treat this as a paginated object.

  const byId = _.indexBy(item => item.id, results);
  const allIds = results.map(item => item.id);
  const total = results.length;

  return {
    ...state,
    isPaginated,
    allIds: _.union(state.allIds, allIds),
    total: state.total + total,
    byId: { ...state.byId, ...byId },
    hasFetchedListOnce: true
  };
};

// Used to add new objects to the existing state. Useful when listing Goals
// for multiple sections for instance.
export const paginateList = (state: Object, action: Object) => {
  const results = action.response.data;
  const isPaginated = true; // Make sure we don't treat this as a paginated object.

  const currentPage = Number(action.response.headers["x-page"]);
  const hasNext = action.response.headers.link && action.response.headers.link.includes('rel="next"');

  const byId = _.indexBy(item => item.id, results);
  const allIds = results.map(item => item.id);
  const total = Number(action.response.headers["x-total"]);

  return {
    ...state,
    currentPage,
    hasNext,
    isPaginated,
    allIds: _.union(allIds, state.allIds),
    total: total,
    byId: { ...state.byId, ...byId },
    hasFetchedListOnce: true
  };
};

// Used to replace the current list of object in state and save memory
export const replaceList = (state: Object, action: Object) => {
  const results = action.response.data;
  const isPaginated = false; // Make sure we don't treat this as a paginated object.

  const byId = _.indexBy(item => item.id, results);
  const allIds = results.map(item => item.id);
  const total = results.length;

  return {
    ...state,
    isPaginated,
    allIds,
    total,
    byId,
    hasFetchedListOnce: true
  };
};

export const addObject = (state: Object, action: Object) => {
  const result = action.response.data;
  const byId = _.set(result.id, result, state.byId);
  const allIdsClone = Array.from(state.allIds);
  let total = state.total;

  // Only add the ID if it's not already in the collection of IDs
  if (allIdsClone.indexOf(result.id) === -1) {
    allIdsClone.push(result.id);
    total += 1;
  }
  let createdId = null;

  // Only set the createdId if the action is a create action
  if (action.type.includes("CREATE")) {
    createdId = result.id;
  }

  return {
    ...state,
    allIds: allIdsClone,
    byId,
    createdId,
    total,
    addedId: result.id
  };
};

export const removeObject = (state: Object, action: Object) => {
  const { id } = action;
  const byId = _.unset(id, state.byId);
  const allIds = state.allIds.filter(objectId => objectId !== id);
  const selectedId = state.selectedId === id ? null : state.selectedId;
  const createdId = state.createdId === id ? null : state.createdId;
  const total = state.total - 1;
  return {
    ...state,
    byId,
    createdId,
    selectedId,
    allIds,
    total
  };
};

export const selectObject = (state: Object, action: Object) => {
  return {
    ...state,
    selectedId: action.selectedId
  };
};
