// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";

// Components
import Enable2FA from "./_enable2FA";
import Disable2FA from "./_disable2FA";

type Props = {
  currentUser: squadTypes.User
};

class AccountSettings2FA extends Component<Props> {
  render() {
    const { currentUser } = this.props;

    if (currentUser.otp_required_for_login) {
      return <Disable2FA />;
    } else {
      return <Enable2FA />;
    }
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings2FA);
