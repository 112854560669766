//@flow
export const CREATE_REQUESTED = "status_updates/CREATE_REQUESTED";
export const CREATE_COMPLETED = "status_updates/CREATE_COMPLETED";
export const CREATE_FAILED = "status_updates/CREATE_FAILED";

export const UPDATE_REQUESTED = "status_updates/UPDATE_REQUESTED";
export const UPDATE_COMPLETED = "status_updates/UPDATE_COMPLETED";
export const UPDATE_FAILED = "status_updates/UPDATE_FAILED";

export const DELETE_REQUESTED = "status_updates/DELETE_REQUESTED";
export const DELETE_COMPLETED = "status_updates/DELETE_COMPLETED";
export const DELETE_FAILED = "status_updates/DELETE_FAILED";

export const FETCH_LIST_REQUESTED = "status_updates/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "status_updates/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "status_updates/FETCH_LIST_FAILED";

export const FETCH_DETAILS_REQUESTED = "status_updates/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "status_updates/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "status_updates/FETCH_DETAILS_FAILED";

export const RESET_LIST = "status_updates/RESET_LIST";
