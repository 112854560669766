//@flow
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router-dom";
import * as squadTypes from "squadTypes";

import { Link } from "react-router-dom";
import styled from "styled-components";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// Components
import StatusBox from "v2/components/StatusBox";

import IconNotify from "v2/components/svg/IconNotify";
import ReactTooltip from "react-tooltip";

const GoalWarning = styled.div`
  margin-right: ${spacing.x1};
  a:hover {
    filter: none;
  }
  .tooltip {
    text-align: center;
  }
`;

const Block = styled.div`
  border: 1px solid ${colors.blockBorder};
  border-radius: 8px;
  padding: ${spacing.x2} 0;
  margin-bottom: ${spacing.x1};

  .title-closed {
    text-decoration: line-through;
  }

  &.goal-closed {
    opacity: 0.5;
  }

  &:hover,
  &.selected {
    cursor: pointer;
    opacity: 1;
  }

  .block-header {
    display: flex;
    justify-content: space-between;

    .updated {
      margin-right: ${spacing.x1};
      font-size: 1.2rem;
      line-height: 2.4rem;
      display: inline-block;
    }

    .block-title {
      display: flex;
      font-size: 1.6rem;
      flex: 1;
      font-weight: 400;
      a,
      .link-button {
        color: ${colors.black};
      }

      .title-open,
      .title-closed {
        margin-right: ${spacing.x1};
      }
    }
  }

  .block-subtitle {
    display: flex;
    align-items: flex-start;

    .block-subtitle-progress {
      margin-right: ${spacing.x1};
      font-size: 1.4rem;
      white-space: nowrap;
    }
  }

  .block-actions {
    margin-top: ${spacing.x1};
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
    }
  }

  .compact-menu {
    display: none;
  }

  &.compact {
    position: relative;
    padding: ${spacing.x1} 0;
    border: 0px solid ${colors.blockBorder};
    .title-open,
    .title-closed {
      font-size: 1.6rem;
    }
    .block-subtitle-progress {
      display: none;
    }
    .block-comment {
      display: none;
    }
    .block-footer {
      display: none;
    }
    .compact-menu {
      background: #fff;
      display: none;
      position: absolute;
      z-index: 40;
      right: ${spacing.x1};
      top: 0.4rem;
    }

    &:hover {
      .compact-menu {
        display: block;
      }
    }
  }

  @media ${devices.tablet} {
    .block-header {
      flex-direction: column;

      .block-subtitle {
        margin-top: ${spacing.x1};
        .block-subtitle-progress {
          margin-left: 0;
        }
      }
    }

    .block-footer {
      .block-footer-progress {
        flex: 3;
      }
      .block-footer-deadline {
        display: none;
      }
    }
  }
`;

type Props = {
  closeGoalNav?: Function,
  basePath?: string,
  deleteGoal: Function,
  goal: squadTypes.Goal,
  maskTasks?: boolean,
  showProject?: boolean,
  isEmbedded?: boolean, // used to open in a new page
  showModal: Function,
  updateGoal: Function,
  isSelected: boolean,
  className: string,
  push: Function,
  workspace?: squadTypes.Workspace
};

class GoalLine extends Component<Props> {
  render() {
    const { goal } = this.props;

    let goalUrl = `/pages/${goal.project_id}/${goal.id}`;

    if (this.props.basePath) {
      goalUrl = `${this.props.basePath}?selected=${goal.id}`;
    }

    return (
      <Block className={`compact goal-${goal.state}`}>
        <div className="block-header">
          <div className="block-title">
            {goal.cached_previous_color && <StatusBox className={`${goal.cached_previous_color} previous`} />}
            {!goal.cached_previous_color && <StatusBox className={`transparent previous`} />}
            <StatusBox className={`${goal.cached_color} ${goal.state}`} />
            <Link className={`title-${goal.state}`} to={goalUrl}>
              {goal.title}
            </Link>
          </div>
          <div className="block-subtitle">
            {goal.is_pending_update && !goal.is_overdue && goal.state === "open" && (
              <GoalWarning role="img" aria-label="warning">
                <IconNotify data-tip data-for={`warning-${goal.id}`} />
                <ReactTooltip id={`warning-${goal.id}`} place="bottom" type="dark" effect="solid" className="tooltip">
                  This goal is due for an update.
                  {goal.cached_status_update_date && (
                    <span>
                      <br />
                      The last update was {moment(goal.cached_status_update_date).fromNow()}.
                    </span>
                  )}
                </ReactTooltip>
              </GoalWarning>
            )}
            {goal.is_overdue && goal.state === "open" && (
              <GoalWarning role="img" aria-label="warning">
                <IconNotify data-tip data-for={`warning-${goal.id}`} color={colors.black} />
                <ReactTooltip id={`warning-${goal.id}`} place="bottom" type="dark" effect="solid" className="tooltip">
                  This goal is overdue.
                  {goal.deadline && (
                    <span>
                      <br />
                      The deadline was {moment(goal.deadline).fromNow()}.
                    </span>
                  )}
                </ReactTooltip>
              </GoalWarning>
            )}
          </div>
        </div>
      </Block>
    );
  }
}

const mapStateToProps = (state, props) => ({
  memberships: state.memberships
});

const mapDispatchToProps = {
  push
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoalLine));
