// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import * as squadTypes from "squadTypes";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import { handleChange, parseTargetWithFormat } from "v2/utils/forms";
import { goalsOperations } from "state/ducks/goals";
import { sectionsOperations } from "state/ducks/sections";
import { projectsSelectors } from "state/ducks/projects";
import { uiOperations } from "state/ducks/ui";
import * as goalTypes from "state/ducks/goals/types";
import _ from "lodash";

// Components
import Datetime from "react-datetime";
import FormField from "v2/components/FormField";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import Select from "v2/components/Select";
import Loader from "v2/components/Loader";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";

import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

const Container = styled.form``;

const LineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    flex: 1;
    margin-right: ${spacing.x2};
    &:last-child {
      flex: 1;
      margin-right: 0;
    }
  }

  @media ${devices.tablet} {
    flex-direction: column;
    > div {
      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-left: 0;
      }
    }
  }
`;

const OtherLineContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ScoreField = styled.div`
  display: flex;
  align-items: center;
  input {
    flex: 1;

    &:focus + div,
    &:focus ~ div {
      border-bottom: 2px solid ${colors.blue};
    }
  }
  .prefix,
  .suffix {
    color: ${colors.subtleText};
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    outline: none;
    transition: border 0.5s ease;
    box-sizing: border-box;
    font-size: 1.4rem;
    line-height: 1;
    height: 4rem;
    padding: 0;
    margin: 0;
  }
  .prefix {
    padding-right: ${spacing.x1};
  }
  .suffix {
    padding-left: ${spacing.x1};
  }
`;

type Props = {
  createGoal: Function,
  currentUser: squadTypes.User,
  fetchSectionDetails: Function,
  goal: squadTypes.Goal,
  hideForm: Function,
  params?: Object,
  project: squadTypes.Project,
  section: squadTypes.Section,
  setErrorFields: Function,
  updateGoal: Function,
  handleSetContentNotSaved: Function,
  ui: Object
};

type State = {
  parentCandidates: Array<squadTypes.Goal>,
  dueDate: any,
  deadline: ?Date,
  startDate: any,
  start_date: ?Date,
  goal_type: string,
  emailErrorClass?: string,
  selectedGoalOption: ?Object,
  selectedOption: ?Object,
  targetWithFormat: string,
  initialValue: number,
  title: string,
  description: string
};

class GoalForm extends Component<Props, State> {
  focusInput: any;
  debouncedFetchMembershipsForAutocomplete: Function;
  state = {
    parentCandidates: [],
    dueDate: "",
    deadline: null,
    startDate: "",
    goal_type: "linear",
    start_date: null,
    emailErrorClass: "",
    selectedGoalOption: null,
    selectedOption: {
      label: this.props.currentUser.fullname,
      value: this.props.currentUser.email
    },
    initialValue: 0,
    targetWithFormat: "",
    title: "",
    description: ""
  };

  constructor(props) {
    super(props);
    this.debouncedFetchMembershipsForAutocomplete = _.debounce(this._fetchMembershipsForAutocomplete, 300);
  }

  componentDidMount() {
    if (this.props.goal) {
      const goal = this.props.goal;

      const { owner } = goal;

      const name = owner ? goal.owner.fullname || goal.owner.email : "";
      const email = owner ? goal.owner.email : "";
      const selectedOption = {
        label: name,
        value: email
      };

      let targetWithFormat;
      if (goal.score_format && goal.target !== null) {
        targetWithFormat = goal.score_format.replace("_target_", goal.target.toString());
      } else {
        targetWithFormat = "";
      }

      this.setState({
        dueDate: moment(goal.deadline).utc(),
        deadline: goal.deadline,
        startDate: moment(goal.start_date),
        goal_type: goal.goal_type,
        start_date: goal.start_date,
        selectedOption,
        initialValue: goal.initial_value,
        targetWithFormat,
        title: goal.title,
        description: goal.description || ""
      });
    } else if (this.props.params) {
      const params = this.props.params;
      this.setState({
        dueDate: moment(params.deadline).utc(),
        deadline: params.deadline,
        goal_type: params.goal_type,
        startDate: moment(params.start_date),
        start_date: params.start_date,
        selectedOption: params.selectedOption,
        targetWithFormat: params.targetWithFormat,
        title: params.title
      });
    } else {
      const { project } = this.props;

      if (project.has_default_dates) {
        this.setState({
          start_date: project.default_start_date,
          deadline: project.default_deadline,
          startDate: moment(project.default_start_date),
          dueDate: moment(project.default_deadline)
        });
      }
    }

    this.focusInput.focus();
    this.getParentGoalCandidates();
  }

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  getParentGoalCandidates = () => {
    const { goal, project } = this.props;
    const slug = getSubdomain() || "";
    let parentCandidates = [];
    if (project && project.parent_id) {
      request
        .post(`/workspaces/${slug}/goals`, {
          project: project.parent_id,
          order_attribute: "title",
          order_direction: "asc"
        })
        .then(response => {
          parentCandidates = response.data;
          let selectedCandidate = null;
          if (goal) {
            selectedCandidate = parentCandidates.filter(goalCandidate => {
              return goalCandidate.id === goal.parent_goal_id;
            });
            selectedCandidate = selectedCandidate[0];
          }
          let selectedGoalOption = null;

          if (selectedCandidate) {
            selectedGoalOption = {
              label: selectedCandidate.title,
              value: selectedCandidate.id
            };
          }
          this.setState({
            parentCandidates,
            selectedGoalOption
          });
        });
    }
  };

  getParentGoalsForAutoComplete = () => {
    const { parentCandidates } = this.state;
    const options = parentCandidates.map(goal => {
      return {
        label: goal.title,
        value: goal.id,
        section: goal.section,
        project: goal.project
      };
    });
    return options;
  };

  _formatGoalOptionLabel = (props, params) => {
    if (params.context === "menu") {
      return (
        <div>
          <div className="option-label">{props.label}</div>
          <div className="option-path">
            {props.project.title} / {props.section.title}
          </div>
        </div>
      );
    } else {
      return <div className="">{props.label}</div>;
    }
  };

  createGoal = (e: Object) => {
    const { handleSetContentNotSaved } = this.props;
    e.preventDefault();
    const { section, setErrorFields } = this.props;
    const {
      goal_type,
      selectedOption,
      selectedGoalOption,
      title,
      targetWithFormat,
      initialValue,
      start_date,
      deadline,
      description
    } = this.state;

    const owner_email = selectedOption ? selectedOption.value : null;
    const parent_goal_id = selectedGoalOption ? selectedGoalOption.value : null;

    // We extract the right values to create goals.
    const { target, score_format } = parseTargetWithFormat(targetWithFormat);

    // We make sure that the target has a numerical value if it was provided.
    if (targetWithFormat && !target) {
      setErrorFields({
        target: "Sorry, we couldn't find a number in your target (ex: 1,000 users, $10,000)"
      });
      return false;
    }

    const params = {
      start_date,
      goal_type,
      deadline,
      owner_email,
      target,
      title,
      description,
      score_format,
      parent_goal_id,
      initial_value: initialValue
    };
    this.props.createGoal(section.id, params).then(action => {
      if (action.type === goalTypes.CREATE_COMPLETED) {
        this.props.fetchSectionDetails(section.id);
        handleSetContentNotSaved(false, () => {
          this.props.hideForm(e);
        });
      }
    });
  };

  updateGoal = (e: Object) => {
    const { handleSetContentNotSaved } = this.props;
    e.preventDefault();
    const { goal, setErrorFields } = this.props;
    const {
      goal_type,
      selectedOption,
      selectedGoalOption,
      title,
      targetWithFormat,
      initialValue,
      start_date,
      deadline,
      description
    } = this.state;

    const owner_email = selectedOption ? selectedOption.value : null;
    const parent_goal_id = selectedGoalOption ? selectedGoalOption.value : null;

    // We extract the right values to create goals.
    const { target, score_format } = parseTargetWithFormat(targetWithFormat);

    // We make sure that the target has a numerical value if it was provided.
    if (targetWithFormat && !target) {
      setErrorFields({
        target: "Sorry, we couldn't find a number in your target (ex: 1,000 users, $10,000)"
      });
      return false;
    }

    const params = {
      start_date,
      goal_type,
      deadline,
      owner_email,
      target,
      title,
      score_format,
      description,
      initial_value: initialValue,
      parent_goal_id
    };
    this.props.updateGoal(goal.id, params).then(action => {
      if (action.type === goalTypes.UPDATE_COMPLETED) {
        this.props.fetchSectionDetails(goal.section_id);
        handleSetContentNotSaved(false, () => {
          this.props.hideForm(e);
        });
      }
    });
  };

  handleChange = e => {
    const { handleSetContentNotSaved } = this.props;
    handleSetContentNotSaved(true);
    handleChange(this, e);
  };

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchMembershipsForAutocomplete = (inputValue: ?string, callback: Function) => {
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const membershipsApiURL = `/workspaces/${slug}/memberships`;
    let membershipsParams = inputValue
      ? {
          name: inputValue
        }
      : null;
    request.get(membershipsApiURL, { params: membershipsParams }).then(response => {
      const users = response.data;
      if (users && users.length > 0) {
        const userOptions = users.map(result => {
          const user = result.user;
          return {
            value: user.email,
            label: user.fullname || user.email
          };
        });
        callback(userOptions);
      } else {
        callback();
      }
    });
    return;
  };

  handleOwnerSelect = (option: any, actionMeta: any) => {
    const { handleSetContentNotSaved } = this.props;
    handleSetContentNotSaved(true);
    if (option) {
      this.setState({ selectedOption: option });
    } else {
      this.setState({ selectedOption: null });
    }
  };

  handleParentGoalSelect = (option: any, actionMeta: any) => {
    const { handleSetContentNotSaved } = this.props;
    handleSetContentNotSaved(true);
    if (option) {
      this.setState({ selectedGoalOption: option });
    } else {
      this.setState({ selectedGoalOption: null });
    }
  };

  handleDateChange = moment => {
    const { handleSetContentNotSaved } = this.props;
    handleSetContentNotSaved(true);
    let isoDate;
    try {
      isoDate = moment.toISOString();
    } catch (e) {
      isoDate = moment;
    }
    this.setState({
      dueDate: moment,
      deadline: isoDate
    });
  };

  handleStartDateChange = moment => {
    const { handleSetContentNotSaved } = this.props;
    handleSetContentNotSaved(true);
    let isoDate;
    try {
      isoDate = moment.toISOString();
    } catch (e) {
      isoDate = moment;
    }
    this.setState({
      startDate: moment,
      start_date: isoDate
    });
  };

  handleEscPress = e => {
    if (e.keyCode === 27) {
      this.props.hideForm(e);
    }
  };

  render() {
    const {
      description,
      goal_type,
      dueDate,
      startDate,
      selectedOption,
      selectedGoalOption,
      targetWithFormat,
      initialValue,
      title
    } = this.state;
    const { currentUser, goal, hideForm, ui } = this.props;
    const parentGoalsOptions = this.getParentGoalsForAutoComplete();

    const { score_format } = parseTargetWithFormat(targetWithFormat);
    const format = score_format ? score_format.split("_target_") : [];

    let submitText;
    if (ui.formState === "requestiong") {
      submitText = <Loader size="small" />;
    } else {
      if (goal) {
        submitText = "Save";
      } else {
        submitText = "Create";
      }
    }
    const { errorFields } = ui;

    const submitAction = goal ? this.updateGoal : this.createGoal;
    return (
      <Container className="modal-wrapper" autoComplete="off" onSubmit={submitAction}>
        <div className="modal-header">
          {!goal && <h2>Create a new goal</h2>}
          {goal && <h2>Edit goal</h2>}
          <ButtonIcon type="button" onClick={this.props.hideForm}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <LineContainer>
            <FormField style={{ flex: 2 }}>
              <label className="label">Title</label>
              <input
                ref={input => {
                  this.focusInput = input;
                }}
                placeholder="What does success look like?"
                type="text"
                required={true}
                onChange={this.handleChange}
                name="title"
                value={title}
                onKeyDown={this.handleEscPress}
                className={`${this.getErrorClass("title")}`}
              />
              {errorFields["title"] && (
                <span className={`${this.getErrorClass("title")}`}>
                  <small>{errorFields["title"]}</small>
                </span>
              )}
              {!errorFields["title"] && (
                <small className="subtle field-tip">Describe your goal by focusing on the outcome</small>
              )}
            </FormField>
            <FormField>
              <label className="label">Owner</label>
              <AsyncCreatableSelect
                isClearable
                defaultValue={{
                  label: currentUser.fullname,
                  value: currentUser.email
                }}
                value={selectedOption}
                loadOptions={this.debouncedFetchMembershipsForAutocomplete}
                defaultOptions={true}
                classNamePrefix="react-select"
                formatCreateLabel={value => {
                  return `Invite by email "${value}"`;
                }}
                onChange={this.handleOwnerSelect}
                className={`${this.getErrorClass("membership")}`}
              />
              {errorFields["membership"] && (
                <span className={`${this.getErrorClass("membership")}`}>
                  <small>{errorFields["membership"]}</small>
                </span>
              )}
              {!errorFields["membership"] && (
                <small className="subtle field-tip">Search by name or invite by email.</small>
              )}
            </FormField>
          </LineContainer>
          <LineContainer>
            <FormField style={{ flex: 2 }}>
              <label className="label">Description</label>
              <textarea
                type="text"
                onChange={this.handleChange}
                name="description"
                value={description}
                onKeyDown={this.handleEscPress}
                className={`${this.getErrorClass("description")}`}
              />
              {errorFields["description"] && (
                <span className={`${this.getErrorClass("description")}`}>
                  <small>{errorFields["description"]}</small>
                </span>
              )}
              {!errorFields["description"] && (
                <small className="subtle field-tip">Describe your goal in more details</small>
              )}
            </FormField>
            <div>
              {parentGoalsOptions && parentGoalsOptions.length > 0 && (
                <FormField>
                  <label htmlFor="title" className="label">
                    Parent goal
                  </label>
                  <Select
                    isClearable
                    placeholder="Select..."
                    options={parentGoalsOptions}
                    classNamePrefix="react-select"
                    closeOnSelect={true}
                    onChange={this.handleParentGoalSelect}
                    value={selectedGoalOption}
                    className={`${this.getErrorClass("parent_goal_id")}`}
                    formatOptionLabel={this._formatGoalOptionLabel}
                  />
                  <span className={`${this.getErrorClass("parent_goal_id")}`}>
                    <small>{errorFields["parent_goal_id"]}</small>
                  </span>
                </FormField>
              )}
            </div>
          </LineContainer>
          <OtherLineContainer>
            <FormField>
              <div className="label">Goal type</div>
              <select
                name="goal_type"
                value={goal_type}
                onChange={this.handleChange}
                style={{ marginRight: spacing.x2 }}
              >
                <option value="linear">Get to</option>
                <option value="stay_above">Stay above</option>
                <option value="stay_below">Stay below</option>
                <option value="no_target">No target</option>
              </select>
            </FormField>

            {goal_type !== "no_target" && (
              <FormField style={{ flex: 1 }}>
                <label className="label">Target value</label>
                <input
                  type="text"
                  placeholder="eg: $140/user, NPS 60, 50 demos"
                  onChange={this.handleChange}
                  name="targetWithFormat"
                  required={true}
                  value={targetWithFormat}
                  onKeyDown={this.handleEscPress}
                />
                {errorFields["target"] && (
                  <span className="error">
                    <small>{errorFields["target"]}</small>
                  </span>
                )}
                {!errorFields["target"] && goal_type === "linear" && (
                  <small className="subtle field-tip">This is where you'd like to end</small>
                )}
                {!errorFields["target"] && goal_type === "stay_above" && (
                  <small className="subtle field-tip">Keep your metric above this target</small>
                )}
                {!errorFields["target"] && goal_type === "stay_below" && (
                  <small className="subtle field-tip">Keep your metric below this target</small>
                )}
              </FormField>
            )}
            {goal_type === "linear" && (
              <FormField
                style={{ display: "flex", marginRight: spacing.x2, marginLeft: spacing.x2, justifyContent: "center" }}
              >
                <span>from</span>
              </FormField>
            )}
            {goal_type === "linear" && (
              <FormField style={{ flex: 1 }}>
                <label className="label">Initial value</label>
                <ScoreField>
                  {format[0] && (
                    <div className="prefix">
                      <span>{format[0]}</span>
                    </div>
                  )}
                  <input
                    type="text"
                    placeholder="Default to 0"
                    onChange={this.handleChange}
                    name="initialValue"
                    required={true}
                    value={initialValue}
                    onKeyDown={this.handleEscPress}
                  />
                  {format[1] && (
                    <div className="suffix">
                      <span>{format[1]}</span>
                    </div>
                  )}
                </ScoreField>
                {errorFields["initial_value"] && (
                  <span className="error">
                    <small>{errorFields["initial_value"]}</small>
                  </span>
                )}
                {!errorFields["initial_value"] && (
                  <small className="subtle field-tip">This is where your goal starts</small>
                )}
              </FormField>
            )}
            <div style={{ flex: 1 }} />
          </OtherLineContainer>
          <LineContainer>
            <FormField>
              <label className="label">Start date</label>
              <Datetime
                dateFormat="D MMM YYYY"
                name="startDate"
                value={startDate}
                onChange={this.handleStartDateChange}
                inputProps={{ placeholder: "Start date" }}
                timeFormat={false}
                closeOnSelect={true}
              />
              <small className="subtle field-tip">When do you start working on your goal?</small>
            </FormField>
            <FormField>
              <label className="label">Due date (optional)</label>
              <Datetime
                dateFormat="D MMM YYYY"
                name="dueDate"
                value={dueDate}
                onChange={this.handleDateChange}
                inputProps={{ placeholder: "Due date" }}
                timeFormat={false}
                closeOnSelect={true}
                disableCloseOnClickOutside={false}
              />
              <small className="subtle field-tip">When is your goal due?</small>
            </FormField>
            <div style={{ flex: 2 }} />
          </LineContainer>
        </div>
        <div className="modal-footer">
          <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
            {submitText}
          </button>
          <button type="button" onClick={hideForm} className="secondary">
            Cancel
          </button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  let project = null;
  if (props.goal) {
    project = projectsSelectors.getObjectById(state.projects.byId, props.goal.project_id);
  }

  if (!project && props.section) {
    project = projectsSelectors.getObjectById(state.projects.byId, props.section.project_id);
  }

  return {
    currentUser: state.session.currentUser,
    ui: state.ui.goalForm,
    memberships: state.memberships,
    project
  };
};

const mapDispatchToProps = {
  createGoal: goalsOperations.createGoal,
  setErrorFields: uiOperations.setErrorFields,
  updateGoal: goalsOperations.updateGoal,
  fetchSectionDetails: sectionsOperations.fetchSectionDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalForm);
