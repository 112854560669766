// @flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import { goalsOperations, goalsSelectors } from "state/ducks/goals";
import { sectionsOperations } from "state/ducks/sections";
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import _ from "lodash/fp";
import { wizardOperations } from "state/ducks/wizard";
import moment from "moment";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

import Goal from "./Goal";

// Components
import IconNotify from "v2/components/svg/IconNotify";
import { Link } from "react-router-dom";
import Loader from "v2/components/Loader";
import Markdown from "v2/components/Markdown";
import EmptyStateImg from "./empty_goals_tabby.png";
import ReactTooltip from "react-tooltip";
import Logo from "v2/components/_assets/tability_icon_black.png";

// Components
import LoginRequired from "v2/components/LoginRequired";
import ProjectPublic from "v2/pages/projectDetails/ProjectPublic";

import IconPrivate from "v2/components/_assets/IconPrivate.png";
import IconPublic from "v2/components/svg/IconPublic";
import ProjectMode from "v2/pages/projectDetails/ProjectMode";

const V2Wrapper = styled.div`
  background-color: #fff;
  min-height: 100%;
`;

const V2Container = styled.div`
  width: 80rem;
  margin: 0 auto;
`;

const V2Header = styled.div`
  padding-top: ${spacing.x4};
  display: flex;
  justify-content: space-between;
`;

const V2HeaderLeft = styled.div`
  display: flex;
`;

const V2HeaderRight = styled.div`
  display: flex;
`;

const V2Main = styled.div``;

const ProjectDescription = styled.div`
  margin-top: ${spacing.x4};
  color: ${colors.subtleText};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.8rem;
  line-height: 1.4;
`;

const SectionContainer = styled.div`
  margin: ${spacing.x4} 0;
  padding-bottom: ${spacing.x4};
`;

const SectionTitleContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.blockBorder};

  h2 {
    font-size: 2.6rem;
  }
  > div {
    display: flex;
    align-items: center;
  }

  margin-bottom: 0;
  padding-bottom: ${spacing.x2};
`;

const SectionProgress = styled.div`
  background-color: #e7e7e7;
  opacity: 1;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6rem;
  min-width: 6rem;
  height: 3.4rem;
  font-size: 1.4rem;
  border-radius: 16px;
  margin-left: ${spacing.x1};
  font-weight: 600;

  position: relative;
  overflow: hidden;

  .progress-bar {
    background-color: ${colors.subtleText};
    border-radius: 4px;
    height: 0.4rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const GoalBlock = styled.div`
  display: flex;
  margin-top: ${spacing.x4};

  .title-closed {
    text-decoration: line-through;
  }
`;

const GoalMeta = styled.div`
  margin-left: ${spacing.x4};
  flex: 1;
`;

const GoalContent = styled.div`
  flex: 3;

  a {
    color: ${colors.black};
  }
`;

const Comment = styled.div`
  color: ${colors.black};
  font-size: 1.8rem;
  line-height: 1.4;
  margin-top: ${spacing.x2};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const CommentMeta = styled.div`
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  margin-top: ${spacing.x1};
  span {
    margin: 0 ${spacing.x1};
  }
  color: ${colors.subtleText};
  a {
    color: ${colors.subtleText};
  }
`;

const EmptyState = styled.div`
  display: flex;
  margin: 10vh 0 10rem 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      text-align: center;
    }
    img {
      height: 164px;
    }
    h3 {
      padding: ${spacing.x2} 0;
    }
    .button {
      width: 24rem;
    }
    p {
      width: 30rem;
      text-align: center;
    }
  }
`;

const LogoLink = styled.a`
  margin-right: ${spacing.x1};
  img {
    width: 3rem;
  }
`;

const ArchivedBanner = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #955be3;
  color: #fff;
  height: 4rem;
  line-height: 4rem;
  align-items: center;
  border-radius: 4px;
  padding: 0 1.4rem;
  font-weight: 500;
  margin-top: ${spacing.x2};
`;

const LockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  width: 3.4rem;
  height: 3.4rem;

  &.auto-width {
    width: auto;
    padding: 0 0.8rem;
  }

  img {
    width: 1.6rem;
  }

  svg {
    &.red {
      g {
        stroke: ${colors.red};
      }
    }
  }
`;

const GoalWarning = styled.div`
  margin-right: ${spacing.x1};
  a:hover {
    filter: none;
  }
  .tooltip {
    text-align: center;
  }
`;

const SummaryContainer = styled.div`
  padding: ${spacing.x2};
  border: 1px solid ${colors.blockBorder};
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  margin-top: ${spacing.x4};
  border-radius: 0;
  border-left: 0;
  border-right: 0;
`;

const SummaryBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${spacing.x4};

  .header {
    font-size: 1.4rem;
    margin-bottom: ${spacing.x1};
  }

  .content {
    display: flex;
    div {
      height: 24px;
      line-height: 24px;
      min-width: 24px;
      padding: 0 ${spacing.x1};
      font-weight: 600;
      color: #fff;
      border-radius: 4px;
      margin-right: ${spacing.x1};
      &.red {
        background-color: ${colors.red};
      }
      &.yellow {
        background-color: ${colors.yellow};
      }
      &.green {
        background-color: ${colors.green};
      }
      &.grey {
        color: ${colors.subtleText};
        background-color: ${colors.grey};
      }
    }
    span {
      font-weight: bold;
      font-size: 2rem;
      line-height: 24px;
    }
  }
`;

type Props = {
  currentGoal: squadTypes.Goal,
  currentProject: squadTypes.Project,
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  fetchProjectDetails: Function,
  fetchProjectGoalList: Function,
  fetchSectionList: Function,
  goals: squadTypes.NormalizedList<squadTypes.Goal>,
  location: Object,
  match: Object,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  projectGoals: Array<squadTypes.Goal>,
  push: Function,
  sections: squadTypes.NormalizedList<squadTypes.Section>,
  showModal: Function,
  resetGoalList: Function,
  resetSectionList: Function,
  expandProjectNav: Function,
  workspaceNavUI: Object,
  displayedProjectTour: Function,
  projectTourDisplayed: boolean,
  ui: Object
};

// We store the goals in state to be able to handle moving them faster.
// That's mainly because of the ordering and the fact that goals can move
// between sections.
// The code is cray - I know.
type State = {
  goalItems: Object, // Hash in the format sectionId => [goal, goal, goal]
  goalStateInitialized: boolean, // Used to check if we did a first pass to add
  // goals to state
  steps: Array<Object>
};

class ProjectDetails extends Component<Props, State> {
  state = {
    goalItems: {},
    goalStateInitialized: false,
    maskGoalDetails: false,
    steps: []
  };

  // a little function to help us with reordering the result
  reorderGoals = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  componentDidMount() {
    const {
      currentProject,
      fetchProjectDetails,
      fetchProjectGoalList,
      fetchSectionList,
      match,
      resetGoalList,
      resetSectionList
    } = this.props;

    // First we unselect the goal and reset the list from the redux store. This
    // avoids having discrepancies in the UI.

    // Only fetch the project if we don't already have the data
    const preloadedProjectId = currentProject ? currentProject.id : "";
    const matchedProjectId = match.params.project;

    resetGoalList();
    if (matchedProjectId !== preloadedProjectId) {
      resetSectionList();
      fetchProjectDetails(matchedProjectId);
    }

    fetchSectionList(matchedProjectId);
    fetchProjectGoalList(matchedProjectId);
  }

  _showTVDashboard = () => {
    this.props.push(`${this.props.location.pathname}/tv`);
  };
  _showPage = () => {
    this.props.push(`/pages/${this.props.currentProject.id}`);
  };

  componentDidUpdate(prevProps) {
    const { sections, goals } = this.props;
    const { goalItems, goalStateInitialized } = this.state;
    const prevSections = prevProps.sections.allIds;
    const prevGoals = prevProps.goals;
    if (goalStateInitialized && _.isEqual(prevSections, sections.allIds) && _.isEqual(prevGoals, goals)) {
      return;
    }

    sections.allIds.forEach(sectionId => {
      const sectionGoals = goalsSelectors.getListByParentId(goals.byId, "section", sectionId);

      // Initiate the display goal form values
      goalItems[sectionId] = sectionGoals;
    });

    this.setState({
      goalItems,
      goalStateInitialized: true
    });
  }

  _showShareModal = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "share",
      object: this.props.currentProject
    });
  };

  render() {
    const {
      currentProject,
      currentUser,
      currentWorkspace,
      goals,
      sections,
      projectGoals,
      projects,
      match,
      ui
    } = this.props;
    const projectIdFromUrl = match.params.project;
    if (ui.AccessIsForbidden[projectIdFromUrl]) {
      return <LoginRequired />;
    }

    const isAnonymousOnAFreePlan = !currentUser && !currentWorkspace.stripe_cache_subscription_plan;

    if (currentProject && currentProject.is_public && isAnonymousOnAFreePlan) {
      let message = "";
      message = "Sorry, reports cannot be made publicly accessible under the free plan.";
      return <LoginRequired message={message} />;
    }

    if (projects.fetchDetailsFailed) {
      return <div>Sorry, we could not load this page.</div>;
    }
    if (!currentProject) {
      return <div>Loading the page.</div>;
    }

    const progressPrct = Math.round(currentProject.cached_prct_progress_normalized * 100);
    return (
      <V2Wrapper>
        <V2Container>
          <V2Header>
            <V2HeaderLeft>
              <LogoLink href="https://app.tability.io/signup?src=preview">
                <img src={Logo} alt="Tability" />
              </LogoLink>
              <h2>{currentProject.title}</h2>
              {!currentUser && currentProject.is_public && (
                <LockContainer>
                  <IconPublic data-tip data-for="unlocked" className="blue" />
                  <ReactTooltip id="unlocked" place="bottom" type="dark" effect="solid">
                    This page is public — anyone with the link can access it.
                  </ReactTooltip>
                </LockContainer>
              )}
              {!currentUser && !currentProject.is_public && (
                <LockContainer>
                  <img src={IconPrivate} alt="Private" data-tip data-for="locked" />
                  <ReactTooltip id="locked" place="bottom" type="dark" effect="solid">
                    This report is private — only logged-in users can see it.
                  </ReactTooltip>
                </LockContainer>
              )}
            </V2HeaderLeft>
            <V2HeaderRight>
              {currentUser && <ProjectPublic currentProject={currentProject} />}
              {currentUser && <ProjectMode currentProject={currentProject} match={match} hasCurrentGoal={false} />}

              {!currentUser && (
                <Link to={`/pages/${currentProject.id}`} className="button primary  flex-start">
                  Log in to see more details
                </Link>
              )}
            </V2HeaderRight>
          </V2Header>
          {currentProject.is_archived && (
            <ArchivedBanner>
              <div>This page is archived</div>
            </ArchivedBanner>
          )}
          <V2Main>
            {currentProject.cached_total_goals > 0 && (
              <SummaryContainer>
                <SummaryBlock>
                  <div className="header">Progress</div>
                  <div className="content">
                    <span>{progressPrct}%</span>
                  </div>
                </SummaryBlock>
                <SummaryBlock>
                  <div className="header">Goals closed</div>
                  <div className="content">
                    <span data-tip data-for={`closedGoals-${currentProject.id}`}>
                      {currentProject.cached_closed_goals}/{currentProject.cached_total_goals}
                    </span>
                  </div>
                </SummaryBlock>
                <SummaryBlock>
                  <div className="header">Goals status</div>
                  <div className="content">
                    <div className="grey" data-tip data-for={`draftGoals-${currentProject.id}`}>
                      {currentProject.cached_draft_goals}
                    </div>
                    <div className="green" data-tip data-for={`greenGoals-${currentProject.id}`}>
                      {currentProject.cached_active_green_goals}
                    </div>
                    <div className="yellow" data-tip data-for={`yellowGoals-${currentProject.id}`}>
                      {currentProject.cached_active_yellow_goals}
                    </div>
                    <div className="red" data-tip data-for={`redGoals-${currentProject.id}`}>
                      {currentProject.cached_active_red_goals}
                    </div>
                  </div>
                </SummaryBlock>
                <SummaryBlock>
                  <div className="header">Updated</div>
                  <div className="content">
                    <span> {currentProject.update_frequency}</span>
                  </div>
                </SummaryBlock>
                <ReactTooltip id={`closedGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_closed_goals} goal{currentProject.cached_closed_goals !== 1 && "s"} closed
                </ReactTooltip>
                <ReactTooltip id={`draftGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_draft_goals} goal{currentProject.cached_draft_goals !== 1 && "s"} pending
                  updates
                </ReactTooltip>
                <ReactTooltip id={`greenGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_active_green_goals} goal
                  {currentProject.cached_active_green_goals !== 1 && "s"} on-track
                </ReactTooltip>
                <ReactTooltip id={`yellowGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_active_yellow_goals} goal
                  {currentProject.cached_active_yellow_goals !== 1 && "s"} at-risk
                </ReactTooltip>
                <ReactTooltip id={`redGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                  {currentProject.cached_active_red_goals} goal{currentProject.cached_active_red_goals !== 1 && "s"}{" "}
                  off-track
                </ReactTooltip>
              </SummaryContainer>
            )}
            {currentProject.description && (
              <ProjectDescription>
                <Markdown source={currentProject.description} />
              </ProjectDescription>
            )}

            {ui.isFetchingGoals && <Loader />}

            {!ui.isFetchingGoals && (
              <Fragment>
                {currentProject.cached_total_goals === 0 && projectGoals.length === 0 && (
                  <EmptyState>
                    <div>
                      <div>
                        <img src={EmptyStateImg} alt="No goals" />
                      </div>
                      <h3>This page doesn't have any goals yet.</h3>
                    </div>
                  </EmptyState>
                )}
                {sections.allIds.length > 0 && goals.allIds.length > 0 && (
                  <Fragment>
                    {sections.allIds.map((sectionId, sectionIndex) => {
                      const section = sections.byId[sectionId];
                      const progressPrct = Math.round(section.cached_prct_progress_normalized * 100);
                      if (!section) {
                        return false;
                      }
                      const goalItems = this.state.goalItems[section.id];
                      return (
                        <SectionContainer key={section.id}>
                          <SectionTitleContainer>
                            <h2>{section.title}</h2>
                            <SectionProgress>
                              <div>{progressPrct}%</div>
                            </SectionProgress>
                          </SectionTitleContainer>
                          {goalItems &&
                            goalItems.map((goal, index) => {
                              if (!goal) {
                                return false;
                              }
                              return (
                                <GoalBlock key={goal.id}>
                                  <GoalContent>
                                    <Link className={`title-${goal.state}`} to={`/pages/${goal.project_id}/${goal.id}`}>
                                      <h3>{goal.title}</h3>
                                    </Link>
                                    <Comment>
                                      {goal.cached_text && <Markdown source={goal.cached_text} />}
                                      {!goal.cached_text && <p className="subtle">-</p>}
                                    </Comment>
                                    <CommentMeta>
                                      {goal.is_pending_update && !goal.is_overdue && goal.state === "open" && (
                                        <GoalWarning role="img" aria-label="warning">
                                          <IconNotify data-tip data-for={`warning-${goal.id}`} />
                                          <ReactTooltip
                                            id={`warning-${goal.id}`}
                                            place="bottom"
                                            type="dark"
                                            effect="solid"
                                            delayShow={0}
                                          >
                                            This goal is due for an update.
                                            {goal.cached_status_update_date && (
                                              <span>
                                                <br />
                                                The last update was {moment(goal.cached_status_update_date).fromNow()}.
                                              </span>
                                            )}
                                          </ReactTooltip>
                                        </GoalWarning>
                                      )}
                                      {moment(goal.cached_status_update_date).fromNow()}
                                      {goal.cached_comments_count > 0 && (
                                        <Fragment>
                                          <span>&middot;</span>
                                          <Link
                                            className={`title-${goal.state}`}
                                            to={`/pages/${goal.project_id}/${goal.id}/${goal.last_status_update_id}`}
                                          >
                                            {goal.cached_comments_count} comment
                                            {goal.cached_comments_count !== 1 && <Fragment>"s"</Fragment>}
                                          </Link>
                                        </Fragment>
                                      )}
                                    </CommentMeta>
                                  </GoalContent>
                                  <GoalMeta>
                                    <Goal goal={goal} />
                                  </GoalMeta>
                                </GoalBlock>
                              );
                            })}
                        </SectionContainer>
                      );
                    })}
                  </Fragment>
                )}
              </Fragment>
            )}
          </V2Main>
        </V2Container>
      </V2Wrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentProject = projectsSelectors.getObjectById(state.projects.byId, state.projects.addedId);
  return {
    currentWorkspace: state.session.currentWorkspace,
    currentGoal: goalsSelectors.getObjectById(state.goals.byId, props.match.params.goal),
    currentProject,
    currentUser: state.session.currentUser,
    createdProject: projectsSelectors.getObjectById(state.projects.byId, state.projects.createdId),
    sections: state.sections,
    goals: state.goals,
    projectGoals: goalsSelectors.getListByParentId(state.goals.byId, "project", props.match.params.project),
    projectTourDisplayed: state.wizard.projectTourDisplayed,
    projects: state.projects,
    ui: state.ui.projectPreview,
    workspaceNavUI: state.ui.workspaceNav
  };
};

const mapDispatchToProps = {
  createSection: sectionsOperations.createSection,
  createProject: projectsOperations.createProject,
  displayedProjectTour: wizardOperations.displayedProjectTour,
  push,
  fetchProjectDetails: projectsOperations.fetchProjectDetails,
  fetchProjectGoalList: goalsOperations.fetchProjectGoalList,
  fetchSectionList: sectionsOperations.fetchSectionList,
  logVisit: sessionOperations.logVisit,
  moveGoal: goalsOperations.moveGoal,
  resetGoalList: goalsOperations.resetGoalList,
  resetSectionList: sectionsOperations.resetSectionList,
  expandProjectNav: uiOperations.expandProjectNav,
  showModal: uiOperations.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
