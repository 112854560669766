//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 22 22" {...props}>
    {" "}
    <g
      id="Icon-dump"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g id="pause-circle" transform="translate(1.000000, 1.000000)" stroke="#6A6A6A" strokeWidth="2">
        <circle id="Oval" cx="10" cy="10" r="10"></circle>
        <line x1="8" y1="13" x2="8" y2="7" id="Shape"></line>
        <line x1="12" y1="13" x2="12" y2="7" id="Shape"></line>
      </g>
    </g>
  </Logo>
);
