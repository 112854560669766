//@flow
export const CREATE_REQUESTED = "links/CREATE_REQUESTED";
export const CREATE_COMPLETED = "links/CREATE_COMPLETED";
export const CREATE_FAILED = "links/CREATE_FAILED";

export const UPDATE_REQUESTED = "links/UPDATE_REQUESTED";
export const UPDATE_COMPLETED = "links/UPDATE_COMPLETED";
export const UPDATE_FAILED = "links/UPDATE_FAILED";

export const DELETE_REQUESTED = "links/DELETE_REQUESTED";
export const DELETE_COMPLETED = "links/DELETE_COMPLETED";
export const DELETE_FAILED = "links/DELETE_FAILED";

export const FETCH_LIST_REQUESTED = "links/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "links/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "links/FETCH_LIST_FAILED";

export const FETCH_DETAILS_REQUESTED = "links/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "links/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "links/FETCH_DETAILS_FAILED";

export const RESET_LIST = "links/RESET_LIST";
