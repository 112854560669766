// @flow
import { createReducer } from "state/utils";
import * as reducerUtils from "state/utils/reducers";
import * as types from "./types";

import { INITIAL_LIST_STATE } from "config/constants";

const normalizedStateReducer = createReducer(INITIAL_LIST_STATE)({
  // Add single object to the reducer
  [types.CREATE_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  [types.FETCH_DETAILS_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  [types.UPDATE_COMPLETED]: (state, action) => reducerUtils.addObject(state, action),
  // Remove single object from the reducer
  [types.FETCH_DETAILS_FAILED]: (state, action) => reducerUtils.removeObject(state, action),
  [types.DELETE_COMPLETED]: (state, action) => reducerUtils.removeObject(state, action),
  // Get list of objects
  [types.FETCH_LIST_COMPLETED]: (state, action) => reducerUtils.replaceList(state, action),
  [types.RESET_LIST]: (state, action) => INITIAL_LIST_STATE,
  [types.SELECT]: (state, action) => reducerUtils.selectObject(state, action),
  [types.UNSELECT]: (state, action) => ({
    ...state,
    selectedId: null
  })
});

/*
// The initial state is an empty array
const paginatedListReducer = createReducer(INITIAL_PAGINATED_LIST_STATE)({
  [types.FETCH_LIST_COMPLETED]: (state, action) => {
    // We get the pagination data from the response
    const currentPage = Number(action.response.headers["x-page"]);
    const hasNext = action.response.headers.link && action.response.headers.link.includes('rel="next"');
    const items = [...state.items, ...action.response.data];
    const total = Number(action.response.headers["x-total"]);

    return {
      currentPage,
      hasNext,
      items,
      total
    };
  },
  [types.RESET_LIST]: () => INITIAL_PAGINATED_LIST_STATE
});
*/

export default normalizedStateReducer;
