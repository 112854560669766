// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";

import { spacing } from "v2/components/styles/spacing";
import { devices } from "v2/components/styles/devices";

import { handleChange } from "v2/utils/forms";
import { accountOperations } from "state/ducks/account";
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";

// Components
import Wordmark from "v2/components/_assets/tability_logo_final.png";
import FormField from "v2/components/FormField";
import InlineMessage from "v2/components/InlineMessage";
import { Link } from "react-router-dom";
import Loader from "v2/components/Loader";

const avatarBg = [
  "#C5A5EF",
  "#B184EA",
  "#8150C4",
  "#83D1DC",
  "#60AEB9",
  "#2E6A6E",
  "#7E98E6",
  "#5C76DE",
  "#324DD3",
  "#5568B4",
  "#F4CDC9",
  "#F69380"
];

const Container = styled.main`
  height: 100%;
  display: flex;
  > div {
    display: flex;
    flex: 1;
    align-items: stretch;
  }
  .left-panel {
    background-color: #0a5055;
    justify-content: flex-end;
    padding-right: ${spacing.x10};

    @media ${devices.tablet} {
      display: none;
    }
  }
  .right-panel {
    padding-left: ${spacing.x10};
    @media ${devices.tablet} {
      padding: ${spacing.x4};
      justify-content: center;
    }
  }
`;

const AccountContainer = styled.div`
  .avatar-line {
    display: flex;
    align-items: center;

    button {
      margin-left: ${spacing.x2};
    }

    margin-bottom: ${spacing.x2};
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #FFFFFF;
    font-size: 2.4rem;
    height: 8rem;
    width: 8rem;
    overflow: hidden;
    background-color: ${props => props.bgColor};
    img {
      width: 100%;
    }
  }
    input[type="file"] {
      display: none;
    }
  }

  .form-actions {
    text-align: right;
  }
`;

const Grid = styled.div`
  max-width: 35rem;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "header"
    "main"
    "footer";
  .grid-header {
    grid-area: header;
    display: flex;
    align-items: flex-end;
    margin-bottom: 69px;

    img {
      height: 40px;
    }
  }
  .grid-main {
    grid-area: main;
    h1 {
      margin-bottom: ${spacing.x2};
    }
  }
  .grid-footer {
    grid-area: footer;
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
  }

  @media ${devices.tablet} {
    max-width: initial;
  }
`;

const StepBlock = styled.div`
  display: flex;
  padding: 35px 25px;

  color: #3b8b90;

  border-bottom: 1px solid #3b8b90;

  &:last-child {
    border-bottom: 0;
  }

  &.selected {
    color: #fff;
  }

  > div {
    &:first-child {
      width: 60px;
      font-size: 2.4rem;
      font-weight: 500;
    }

    &:nth-child(2) {
      big {
        display: block;
        font-weight: 500;
        font-size: 2.4rem;
      }
      span {
        margin-top: 20px;
        display: block;
      }
    }
  }
`;

const FooterBlock = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  span {
    display: block;
    margin: 0 ${spacing.x1};
  }
  a,
  .link-button {
    color: #3b8b90;
    &:hover {
      color: #3b8b90;
      filter: brightness(90%);
    }
    text-decoration: underline;
    margin-right: ${spacing.x2};
  }
`;

type Props = {
  clearPage: Function,
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  fetchAssetPostUrl: Function,
  logout: Function,
  ui: Object,
  updateAccount: Function,
  uploadAsset: Function,
  uploadedAsset: any
};

type State = {
  avatar_url: string,
  fullname: string,
  file: any
};

class ProfileNew extends Component<Props, State> {
  state = {
    avatar_url: this.props.currentUser.avatar_url || "",
    fullname: this.props.currentUser.fullname || "",
    file: null
  };

  componentDidMount() {
    this.props.clearPage();
  }

  componentDidUpdate() {
    const { uploadedAsset } = this.props;

    // If we've uploaded an asset, and this asset url is different from the avatar
    if (uploadedAsset && uploadedAsset.url && this.state.avatar_url !== uploadedAsset.url) {
      this.setState({
        avatar_url: uploadedAsset.url
      });
    }
  }

  // This function pops up the file uploader
  handleClick = e => {
    e.preventDefault();
    this.refs.fileUploader.click();
  };

  // This function uploads the file to S3
  handleUploadFile = event => {
    const file = event.target.files[0];

    this.props.uploadAsset(file);
  };

  updateAccount = (e: Object) => {
    e.preventDefault();
    const { avatar_url, fullname } = this.state;
    const params = {
      avatar_url,
      fullname
    };
    this.props.updateAccount(params);
  };

  logout = (e: Object) => {
    e.preventDefault();
    this.props.logout();
  };

  handleChange = e => handleChange(this, e);

  render() {
    const { currentUser, currentWorkspace, ui } = this.props;
    const { avatar_url, fullname } = this.state;

    let avatarInitials = fullname
      .trim()
      .split(" ")
      .map(x => x.charAt(0).toUpperCase())
      .slice(0, 2)
      .join("");

    let hash = currentUser.id.charCodeAt(0);
    const colorIndex = hash % avatarBg.length;
    const bgColor = avatarBg[colorIndex];

    return (
      <Container>
        <div className="left-panel">
          <Grid>
            <div className="grid-header">
              <Link to="/">
                <img src={Wordmark} alt="Tability" />
              </Link>
            </div>
            <div className="grid-main">
              <StepBlock className="selected">
                <div>1</div>
                <div>
                  <big>Set up your account</big>
                  <span>Tell us a bit about yourself.</span>
                </div>
              </StepBlock>
              {currentWorkspace && (
                <StepBlock>
                  <div>2</div>
                  <div>
                    <big>Set up your workspace</big>
                  </div>
                </StepBlock>
              )}
              {currentWorkspace && (
                <StepBlock>
                  <div>3</div>
                  <div>
                    <big>Set up your workflow</big>
                  </div>
                </StepBlock>
              )}
            </div>
            <div className="grid-footer">
              {currentUser && (
                <FooterBlock>
                  <button className="link-button" onClick={this.logout}>
                    Logout
                  </button>
                  <Link to="/account">Account settings</Link>
                </FooterBlock>
              )}
            </div>
          </Grid>
        </div>
        <div className="right-panel">
          <Grid>
            <AccountContainer className="grid-main" bgColor={bgColor}>
              <h1>Set up your account</h1>
              <div className="avatar-line">
                {avatar_url && (
                  <div className="avatar">
                    {!ui.isUploading && <img src={avatar_url} alt="User profile" />}
                    {ui.isUploading && <Loader size="small" />}
                  </div>
                )}
                {!avatar_url && (
                  <div className="avatar">
                    {ui.isUploading && <Loader size="small" />}
                    {!ui.isUploading && <span>{avatarInitials}</span>}
                  </div>
                )}
                <input
                  type="file"
                  id="file"
                  ref="fileUploader"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={this.handleUploadFile}
                />
                <button className="" onClick={this.handleClick}>
                  Upload picture
                </button>
              </div>
              <div className="profile-details">
                <form onSubmit={this.updateAccount}>
                  <InlineMessage message={ui.message} messageType={ui.messageType} />
                  <FormField>
                    <input
                      type="text"
                      className="form-control"
                      name="fullname"
                      placeholder="Full Name"
                      aria-label="Enter your full name"
                      required={true}
                      value={fullname}
                      onChange={this.handleChange}
                    />
                  </FormField>
                  <div className="form-actions">
                    {!ui.isFetching && (
                      <button type="submit" className="primary">
                        Next step →
                      </button>
                    )}
                    {ui.isFetching && (
                      <button disabled className="primary">
                        <Loader size="small" />
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </AccountContainer>
          </Grid>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  uploadedAsset: state.account.uploadedAsset,
  currentUser: state.session.currentUser,
  currentWorkspace: state.session.currentWorkspace,
  ui: state.ui.onboarding
});

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  logout: sessionOperations.logout,
  updateAccount: accountOperations.updateAccount,
  uploadAsset: accountOperations.uploadAssetDance
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNew);
