// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { projectsOperations, projectsSelectors } from "state/ducks/projects";
import { goalsOperations, goalsSelectors } from "state/ducks/goals";
import { sectionsOperations } from "state/ducks/sections";
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import _ from "lodash/fp";
import { wizardOperations } from "state/ducks/wizard";

import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

// Components
import Loader from "v2/components/Loader";
import { Redirect } from "react-router-dom";
import Goal from "./Goal";
import Section from "./Section";
import EmptyStateImg from "./empty_goals_tabby.png";
import Logo from "v2/components/_assets/tability_icon_black.png";
import IconPlay from "v2/components/svg/IconPlay";
import IconPause from "v2/components/svg/IconPause";
import ButtonIcon from "v2/components/ButtonIcon";
import IconPrivate from "v2/components/_assets/IconPrivate.png";
import IconPublic from "v2/components/svg/IconPublic";
import ReactTooltip from "react-tooltip";
import ProjectMode from "v2/pages/projectDetails/ProjectMode";

const GlobalStyle = createGlobalStyle`
body::-webkit-scrollbar {
    width: 1rem;
}
body::-webkit-scrollbar-track {
    background-color: #151515;
}
body::-webkit-scrollbar-thumb {
  background-color: #373737;
  outline: 1px solid slategrey;
}
`;

const DashboardContainer = styled.div`
  padding: ${spacing.x2};
  background: #000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const Header = styled.div`
  color: #fff;
  align-items: center;
  display: flex;
  margin-bottom: ${spacing.x2};
  h2 {
    font-size: 2.4rem;
    font-weight: 600;
  }
`;

const Main = styled.div`
  flex: 1;
`;

const SummaryContainer = styled.div`
  padding: ${spacing.x3};
  background-color: #373737;
  display: flex;
  align-items: center;
  margin: ${spacing.x3} 0;
  border-radius: 8px;
`;

const SummaryBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${spacing.x4};

  .header {
    font-size: 1.6rem;
    margin-bottom: ${spacing.x1};
    color: #9b9b9b;
  }

  .content {
    display: flex;
    color: #fff;
    div {
      height: 32px;
      line-height: 32px;
      min-width: 32px;
      padding: 0 ${spacing.x1};
      font-weight: 600;
      font-size: 2rem;
      text-align: center;
      color: #fff;
      border-radius: 4px;
      margin-right: ${spacing.x1};
      &.red {
        background-color: ${colors.red};
      }
      &.yellow {
        background-color: ${colors.yellow};
      }
      &.green {
        background-color: ${colors.green};
      }
      &.grey {
        background-color: ${colors.grey};
        color: #6a6a6a;
      }
      &.black {
        background-color: ${colors.black};
      }
    }
    span {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.2ren;
    }
  }
`;

const Footer = styled.div``;

const EmptyState = styled.div`
  display: flex;
  margin: 5rem 0 10rem 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      text-align: center;
    }
    img {
      height: 164px;
    }
    h3 {
      padding: ${spacing.x2} 0;
    }
    .button {
      width: 24rem;
    }
    p {
      width: 30rem;
      text-align: center;
    }
  }
`;

const LogoLink = styled.a`
  margin-right: ${spacing.x1};
  img {
    width: 3rem;
  }
`;

const ArchivedBanner = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #955be3;
  color: #fff;
  height: 4rem;
  line-height: 4rem;
  align-items: center;
  border-radius: 4px;
  padding: 0 1.4rem;
  font-weight: 500;
  margin-bottom: 2.2rem;
`;

const ButtonContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  > div {
    margin-left: ${spacing.x1};
  }
  display: flex;
`;

const LockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  width: 3.4rem;
  height: 3.4rem;

  &.auto-width {
    width: auto;
    padding: 0 0.8rem;
  }

  img {
    width: 1.6rem;
  }

  svg {
    &.red {
      g {
        stroke: ${colors.red};
      }
    }
  }
`;

type Props = {
  currentGoal: squadTypes.Goal,
  currentProject: squadTypes.Project,
  currentUser: squadTypes.User,
  fetchProjectDetails: Function,
  fetchProjectGoalList: Function,
  fetchSectionList: Function,
  goals: squadTypes.NormalizedList<squadTypes.Goal>,
  location: Object,
  match: Object,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  projectGoals: Array<squadTypes.Goal>,
  push: Function,
  sections: squadTypes.NormalizedList<squadTypes.Section>,
  resetGoalList: Function,
  resetSectionList: Function,
  expandProjectNav: Function,
  workspaceNavUI: Object,
  displayedProjectTour: Function,
  projectTourDisplayed: boolean,
  ui: Object
};

// We store the goals in state to be able to handle moving them faster.
// That's mainly because of the ordering and the fact that goals can move
// between sections.
// The code is cray - I know.
type State = {
  goalItems: Object, // Hash in the format sectionId => [goal, goal, goal]
  goalStateInitialized: boolean, // Used to check if we did a first pass to add
  // goals to state
  steps: Array<Object>,
  isPlaying: boolean
};

class ProjectDetails extends Component<Props, State> {
  state = {
    goalItems: {},
    goalStateInitialized: false,
    maskGoalDetails: false,
    steps: [],
    isPlaying: false
  };

  scrollInterval;

  // a little function to help us with reordering the result
  reorderGoals = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  componentDidMount() {
    const {
      currentProject,
      fetchProjectDetails,
      fetchProjectGoalList,
      fetchSectionList,
      location,
      match,
      resetGoalList,
      resetSectionList
    } = this.props;

    window.Intercom("shutdown");

    // First we unselect the goal and reset the list from the redux store. This
    // avoids having discrepancies in the UI.

    // Only fetch the project if we don't already have the data
    const preloadedProjectId = currentProject ? currentProject.id : "";
    const matchedProjectId = match.params.project;

    resetGoalList();
    if (matchedProjectId !== preloadedProjectId) {
      resetSectionList();
      fetchProjectDetails(matchedProjectId);
    }

    fetchSectionList(matchedProjectId);
    fetchProjectGoalList(matchedProjectId);

    if (location && location.search) {
      const params = new URLSearchParams(location.search);

      const play = params.get("play");
      if (play) {
        this._startPlaying();
      }
    }
  }

  // This function simulate the start by redirecting to the path with the query param play=1
  // We're doing this because we'll need to refresh the page, so it's simpler to make the play param part of the URL
  // so that it's automatically triggered on refresh.
  _start = () => {
    const { location } = this.props;
    window.location.replace(`${location.pathname}?play=1`);
  };

  // The stop function simply redirects to the pathname without the query param.
  _stop = () => {
    const { location } = this.props;
    window.location.replace(`${location.pathname}`);
  };

  _startPlaying = () => {
    if (typeof window === "undefined") {
      return;
    }

    const scrollDelay = 60000; // scroll every 60 seconds
    const refreshDelay = 600000; // refresh every 10 minutes
    const behavior = "smooth";
    let scrollPosition = 0;

    const scrollDistance = window.innerHeight - 20;
    this.scrollInterval = setInterval(() => {
      scrollPosition += scrollDistance;

      if (!document.body) {
        return;
      }

      if (scrollPosition >= document.body.offsetHeight) {
        scrollPosition = 0;
      }

      window.scrollTo({ top: scrollPosition, left: 0, behavior });
    }, scrollDelay);

    setTimeout(() => {
      window.location.reload();
    }, refreshDelay);
    this.setState({ isPlaying: true });
  };

  // We need this because if you navigate to a different project the component
  // is still the same so it won't update the content unless we told it to.
  componentWillReceiveProps(nextProps) {
    const { fetchProjectDetails, fetchProjectGoalList, fetchSectionList, match, resetSectionList } = this.props;
    if (match.params.project !== nextProps.match.params.project) {
      resetSectionList();
      fetchProjectDetails(nextProps.match.params.project);
      fetchProjectGoalList(nextProps.match.params.project);
      fetchSectionList(nextProps.match.params.project);
    }
  }

  componentDidUpdate(prevProps) {
    const { sections, goals } = this.props;
    const { goalItems, goalStateInitialized } = this.state;
    const prevSections = prevProps.sections.allIds;
    const prevGoals = prevProps.goals;
    if (goalStateInitialized && _.isEqual(prevSections, sections.allIds) && _.isEqual(prevGoals, goals)) {
      return;
    }

    sections.allIds.forEach(sectionId => {
      const sectionGoals = goalsSelectors.getListByParentId(goals.byId, "section", sectionId);

      // Initiate the display goal form values
      goalItems[sectionId] = sectionGoals;
    });

    this.setState({
      goalItems,
      goalStateInitialized: true
    });
  }

  // render the content of the page
  _renderContent = () => {
    const { currentProject, projectGoals, sections, ui } = this.props;

    if (ui.isFetchingGoals) {
      return null;
    }

    if (currentProject.cached_total_goals === 0 && projectGoals.length === 0) {
      return (
        <EmptyState>
          <div>
            <div>
              <img src={EmptyStateImg} alt="No goals" />
            </div>
            <h3>This page doesn't have any goals yet.</h3>
          </div>
        </EmptyState>
      );
    }

    return sections.allIds.map((sectionId, sectionIndex) => {
      const section = sections.byId[sectionId];
      if (!section) {
        return false;
      }
      const goalItems = this.state.goalItems[section.id];
      return (
        <Section key={section.id} section={section} sectionsCount={sections.total} goalsCount={projectGoals.length}>
          {goalItems &&
            goalItems.map((goal, index) => {
              if (!goal) {
                return false;
              }
              return <Goal key={goal.id} goal={goal} isSelected={false} className="top-align" />;
            })}
        </Section>
      );
    });
  };

  render() {
    const { currentProject, projects, match, ui } = this.props;
    const { isPlaying } = this.state;
    if (projects.fetchDetailsFailed) {
      return <Redirect to={`/pages/${match.params.project}`} />;
    }
    if (!currentProject) {
      return <div>Loading the project</div>;
    }

    const progressPrct = Math.round(currentProject.cached_prct_progress_normalized * 100);

    const pieData = [];
    if (currentProject.cached_draft_goals) {
      pieData.push({
        name: "Goals pending updates",
        value: currentProject.cached_draft_goals,
        color: colors.grey
      });
    }
    if (currentProject.cached_active_red_goals) {
      pieData.push({ name: "Goals off-track", value: currentProject.cached_active_red_goals, color: colors.red });
    }
    if (currentProject.cached_active_yellow_goals) {
      pieData.push({
        name: "Goals at-risk",
        value: currentProject.cached_active_yellow_goals,
        color: colors.yellow
      });
    }
    if (currentProject.cached_active_green_goals) {
      pieData.push({
        name: "Goals on-track",
        value: currentProject.cached_active_green_goals,
        color: colors.green
      });
    }
    if (currentProject.cached_closed_goals) {
      pieData.push({ name: "Closed goals", value: currentProject.cached_closed_goals, color: colors.black });
    }

    return (
      <DashboardContainer>
        <GlobalStyle />
        <Header>
          <LogoLink href="https://app.tability.io/signup?src=preview">
            <img src={Logo} alt="Tability" />
          </LogoLink>
          <h2>{currentProject.title}</h2>
          {currentProject.is_public && (
            <LockContainer>
              <IconPublic data-tip data-for="unlocked" className="blue" />
              <ReactTooltip id="unlocked" place="bottom" type="dark" effect="solid">
                This page is public — anyone with the link can access it.
              </ReactTooltip>
            </LockContainer>
          )}
          {!currentProject.is_public && (
            <LockContainer>
              <img src={IconPrivate} alt="Private" data-tip data-for="locked" />
              <ReactTooltip id="locked" place="bottom" type="dark" effect="solid">
                This report is private — only logged-in users can see it.
              </ReactTooltip>
            </LockContainer>
          )}
          <ButtonContainer>
            <ProjectMode currentProject={currentProject} match={match} hasCurrentGoal={false} mode="dark" />
            {!isPlaying && (
              <ButtonIcon onClick={() => this._start()}>
                <IconPlay />
              </ButtonIcon>
            )}
            {isPlaying && (
              <ButtonIcon onClick={() => this._stop()}>
                <IconPause />
              </ButtonIcon>
            )}
          </ButtonContainer>
        </Header>
        <Main>
          {currentProject.is_archived && (
            <ArchivedBanner>
              <div>This page is archived</div>
            </ArchivedBanner>
          )}
          {currentProject.cached_total_goals > 0 && (
            <SummaryContainer>
              <SummaryBlock>
                <div className="header">Progress</div>
                <div className="content">
                  <span>{progressPrct}%</span>
                </div>
              </SummaryBlock>
              <SummaryBlock>
                <div className="header">Goals closed</div>
                <div className="content">
                  <span data-tip data-for={`closedGoals-${currentProject.id}`}>
                    {currentProject.cached_closed_goals}/{currentProject.cached_total_goals}
                  </span>
                </div>
              </SummaryBlock>
              <SummaryBlock>
                <div className="header">Goals status</div>
                <div className="content">
                  <div className="grey" data-tip data-for={`draftGoals-${currentProject.id}`}>
                    {currentProject.cached_draft_goals}
                  </div>
                  <div className="green" data-tip data-for={`greenGoals-${currentProject.id}`}>
                    {currentProject.cached_active_green_goals}
                  </div>
                  <div className="yellow" data-tip data-for={`yellowGoals-${currentProject.id}`}>
                    {currentProject.cached_active_yellow_goals}
                  </div>
                  <div className="red" data-tip data-for={`redGoals-${currentProject.id}`}>
                    {currentProject.cached_active_red_goals}
                  </div>
                </div>
              </SummaryBlock>
              <SummaryBlock>
                <div className="header">Updated</div>
                <div className="content">
                  <span>{currentProject.update_frequency}</span>
                </div>
              </SummaryBlock>
              <ReactTooltip id={`draftGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                {currentProject.cached_draft_goals} goal{currentProject.cached_draft_goals !== 1 && "s"} pending updates
              </ReactTooltip>
              <ReactTooltip id={`greenGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                {currentProject.cached_active_green_goals} goal
                {currentProject.cached_active_green_goals !== 1 && "s"} on-track
              </ReactTooltip>
              <ReactTooltip id={`yellowGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                {currentProject.cached_active_yellow_goals} goal
                {currentProject.cached_active_yellow_goals !== 1 && "s"} at-risk
              </ReactTooltip>
              <ReactTooltip id={`redGoals-${currentProject.id}`} place="bottom" type="dark" effect="solid">
                {currentProject.cached_active_red_goals} goal
                {currentProject.cached_active_red_goals !== 1 && "s"} off-track
              </ReactTooltip>
            </SummaryContainer>
          )}
          {ui.isFetchingGoals && <Loader />}
          {this._renderContent()}
        </Main>
        <Footer></Footer>
      </DashboardContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentProject = projectsSelectors.getObjectById(state.projects.byId, state.projects.addedId);
  return {
    currentWorkspace: state.session.currentWorkspace,
    currentGoal: goalsSelectors.getObjectById(state.goals.byId, props.match.params.goal),
    currentProject,
    currentUser: state.session.currentUser,
    createdProject: projectsSelectors.getObjectById(state.projects.byId, state.projects.createdId),
    sections: state.sections,
    goals: state.goals,
    projectGoals: goalsSelectors.getListByParentId(state.goals.byId, "project", props.match.params.project),
    projectTourDisplayed: state.wizard.projectTourDisplayed,
    projects: state.projects,
    ui: state.ui.projectPreviewTV,
    workspaceNavUI: state.ui.workspaceNav
  };
};

const mapDispatchToProps = {
  createSection: sectionsOperations.createSection,
  createProject: projectsOperations.createProject,
  displayedProjectTour: wizardOperations.displayedProjectTour,
  push,
  fetchProjectDetails: projectsOperations.fetchProjectDetails,
  fetchProjectGoalList: goalsOperations.fetchProjectGoalList,
  fetchSectionList: sectionsOperations.fetchSectionList,
  logVisit: sessionOperations.logVisit,
  moveGoal: goalsOperations.moveGoal,
  resetGoalList: goalsOperations.resetGoalList,
  resetSectionList: sectionsOperations.resetSectionList,
  expandProjectNav: uiOperations.expandProjectNav
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
