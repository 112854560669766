// @flow
import {
  checkCredentials,
  checkCurrentWorkspace,
  confirmEmail,
  fetchCurrentWorkspace,
  googleAuth,
  slackAuth,
  initializeSessionCompleted,
  initializeSessionStarted,
  login,
  magicLogin,
  logout,
  logVisit,
  refreshAccessTokens,
  resendConfirmationEmail,
  resetPassword,
  saveNewPassword,
  setRedirectAfterPath,
  signup,
  toggleDiscoveryFlag,
  unsubscribe,
  visitWhatsNew,
  setSAMLTokens
} from "./actions";

const initApp = () => (dispatch: Function) => {
  dispatch(initializeSessionStarted());
};

export {
  checkCredentials,
  checkCurrentWorkspace,
  fetchCurrentWorkspace,
  confirmEmail,
  googleAuth,
  slackAuth,
  initApp,
  initializeSessionCompleted,
  login,
  magicLogin,
  logout,
  logVisit,
  refreshAccessTokens,
  resendConfirmationEmail,
  resetPassword,
  saveNewPassword,
  setRedirectAfterPath,
  signup,
  toggleDiscoveryFlag,
  unsubscribe,
  visitWhatsNew,
  setSAMLTokens
};
