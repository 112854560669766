// @flow
import { createReducer } from "state/utils";
import * as sessionTypes from "state/ducks/session/types";
import * as uiTypes from "./types";

const INITIAL_STATE = {
  error: false,
  isFetching: false,
  isSendingConfirmation: false,
  message: "",
  messageType: "warning",
  needConfirmation: false,
  hasCheckedEmail: false,
  has2FAEnabled: false
};

export default createReducer(INITIAL_STATE)({
  [sessionTypes.LOGIN_FAILED]: (state, action) => {
    const errors = action.error.response.data.error;
    let needConfirmation = false;
    if (/confirm.*email/.test(errors)) {
      needConfirmation = true;
    }
    return {
      ...state,
      error: true,
      isFetching: false,
      message: errors,
      messageType: "warning",
      needConfirmation: needConfirmation
    };
  },
  [sessionTypes.LOGIN_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    messageType: "success",
    message: "You have successfully signed in."
  }),
  [sessionTypes.LOGIN_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: ""
  }),
  [sessionTypes.RESEND_CONFIRMATION_FAILED]: (state, action) => ({
    ...state,
    error: true,
    isFetching: false,
    needConfirmation: false,
    messageType: "warning",
    message: "Sorry, we could not send you your confirmation email."
  }),
  [sessionTypes.RESEND_CONFIRMATION_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    needConfirmation: false,
    messageType: "success",
    message: "Your email confirmation has been sent."
  }),
  [sessionTypes.RESEND_CONFIRMATION_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: ""
  }),
  [sessionTypes.CHECK_CREDENTIALS_FAILED]: (state, action) => ({
    ...state,
    error: true,
    isFetching: false,
    needConfirmation: false,
    messageType: "warning",
    message: "Sorry, we could not check your email.",
    hasCheckedEmail: false,
    has2FAEnabled: false
  }),
  [sessionTypes.CHECK_CREDENTIALS_COMPLETED]: (state, action) => ({
    ...state,
    error: false,
    isFetching: false,
    needConfirmation: false,
    messageType: "success",
    message: "",
    hasCheckedEmail: true,
    has2FAEnabled: action.response.data.has_2fa_enabled
  }),
  [sessionTypes.CHECK_CREDENTIALS_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: ""
  }),
  [uiTypes.CLEAR_SESSION_LOGIN]: () => INITIAL_STATE
});
