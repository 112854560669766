// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import GoalBlockPage from "v2/components/GoalBlockPage";
import IconCornerDown from "v2/components/svg/IconCornerDown";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";
import Loader from "v2/components/Loader";

// Components
import { spacing } from "v2/components/styles/spacing";

const Container = styled.div`
  ul {
    margin-left: 3.7rem;
    &.level-0 {
      margin-left: 0;
    }
    &.level-1 {
      margin-left: ${spacing.x3};
    }
    li {
      .line {
        display: flex;
        align-items: center;
        > div {
          flex: 1;
        }
        svg {
          margin-right: ${spacing.x1};
        }
      }
    }
  }

  > ul {
    margin-left: 0;
    margin-top: ${spacing.x2};
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      height: 1.3rem;
    }
  }
`;

type Props = {
  goal: squadTypes.Goal,
  hideForm: Function,
  location: Object
};

type State = {
  goals: Object,
  isLoading: boolean
};

class GoalForm extends Component<Props, State> {
  state = {
    goals: {
      root: [this.props.goal]
    },
    isLoading: true
  };

  // Look for the children of a goal
  getChildrenGoal = (goal: squadTypes.Goal) => {
    const slug = getSubdomain() || "";
    if (goal && goal.count_of_children_goals > 0) {
      this.setState({
        isLoading: true
      });
      request.post(`/workspaces/${slug}/goals`, { parent_goal: goal.id }).then(response => {
        const children = response.data;

        this.setState({
          goals: {
            ...this.state.goals,
            [goal.id]: children
          },
          isLoading: false
        });

        children.forEach(child => {
          this.getChildrenGoal(child);
        });
      });
    }
  };

  // Look for the parent of a goal
  getParentGoal = (goal: squadTypes.Goal) => {
    if (goal && goal.parent_goal_id) {
      this.setState({
        isLoading: true
      });

      request.get(`/goals/${goal.parent_goal_id}`).then(response => {
        const parent = response.data;

        this.setState({
          goals: {
            ...this.state.goals,
            root: [parent],
            [parent.id]: [goal]
          },
          isLoading: false
        });

        if (parent.parent_goal_id) {
          this.getParentGoal(parent);
        } else {
          this.getChildrenGoal(parent);
        }
      });
    }
  };

  componentDidMount() {
    if (this.props.goal.parent_goal_id) {
      this.getParentGoal(this.props.goal);
    } else {
      this.getChildrenGoal(this.props.goal);
    }
  }

  handleEscPress = e => {
    if (e.keyCode === 27) {
      this.props.hideForm(e);
    }
  };

  handleClose = e => {
    e.preventDefault();
    this.props.hideForm(e);
  };

  renderGoals = (parentID: string, level = 0) => {
    const { goals } = this.state;
    const goalsToDisplay = goals[parentID];

    if (goalsToDisplay) {
      return (
        <ul className={`level-${level}`}>
          {goalsToDisplay.map(goal => (
            <li key={goal.id}>
              <div className="line">
                {level > 0 && <IconCornerDown />}
                <GoalBlockPage goal={goal} level={level} showProject={true} maskTasks={true} maskActions={true} />
              </div>
              {this.renderGoals(goal.id, level + 1)}
            </li>
          ))}
        </ul>
      );
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Container className="modal-wrapper-no-footer">
        <div className="modal-header">
          <h3>Goal dependencies</h3>
          <ButtonIcon onClick={this.handleClose}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          {isLoading && <Loader size="small" />}
          {this.renderGoals("root")}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GoalForm);
