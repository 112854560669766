//@flow
import React, { Component } from "react";
import styled from "styled-components";
import * as squadTypes from "squadTypes";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

// Components
import BotAvatarHappy from "v2/components/_assets/tabbot-happy.png";

const Container = styled.div`
  display: flex;
  background-color: #e9e9e9;
  margin-top: 0;
  margin-bottom: ${spacing.x2};
  padding: ${spacing.x2};
  border-radius: 4px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Author = styled.div`
  a {
    color: ${colors.black};
  }

  span {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 10px;
    background-color: #e6e8ec;
    color: #8089a2;
  }
`;

const Meta = styled.div`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${spacing.x1};

  .auto-label {
    display: inline-block;
    background-color: #a9a9a9;
    font-size: 1.2rem;
    padding: 0.4rem ${spacing.x1};
    border-radius: 2px;
  }
`;

const StatusData = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  margin-right: ${spacing.x2};
  img {
    height: 3.2rem;
  }
`;

const Details = styled.div``;

type Props = {
  goal: squadTypes.Goal,
  workspace: squadTypes.Workspace,
  updateGoal: Function
};

class StatusUpdateCard extends Component<Props> {
  render() {
    const { workspace } = this.props;

    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return (
      <Container>
        <AvatarContainer>
          <img src={BotAvatarHappy} alt="Tabot" />
        </AvatarContainer>
        <Card>
          <Meta>
            <div>
              <Author>Tabby</Author>
            </div>
            <StatusData />
          </Meta>
          <Details>
            <p>
              Hi! This goal has just been created. The first update is due{" "}
              <b>next {weekdays[workspace.weekly_reminder_day]}</b> but you can already submit your check-ins.
            </p>
          </Details>
        </Card>
      </Container>
    );
  }
}

export default StatusUpdateCard;
