// @flow
import { createStore, applyMiddleware, combineReducers } from "redux";
//import { routerMiddleware, routerReducer } from "react-router-redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import * as reducers from "state/ducks";
import { composeWithDevTools } from "redux-devtools-extension";
//import analyticsMiddleware from "./middlewares/analyticsMiddleware";
import apiMiddleware from "./middlewares/apiMiddleware";
import history from "config/history";

export default function configureStore(initialState?: Object) {
  const rootReducer = combineReducers({ ...reducers, router: connectRouter(history), form: formReducer });

  let middlewares = [thunkMiddleware, routerMiddleware(history), apiMiddleware];
  if (process.env.NODE_ENV !== "production") {
    middlewares = [...middlewares, createLogger()];
  }
  return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
}
