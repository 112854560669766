// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { accountOperations } from "state/ducks/account";
import * as squadTypes from "squadTypes";
import styled from "styled-components";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import AccountSettingsNav from "v2/components/AccountSettingsNav";
import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: ${colors.blockBorder};
  }
`;

type Props = {
  currentUser: squadTypes.User,
  email: string,
  handleSubmit: Function,
  name: string,
  ui: Object,
  deleteAccount: Function
};

class AccountSettingsDelete extends Component<Props> {
  deleteAccount = userValues => {
    const { currentUser } = this.props;
    this.props.deleteAccount(currentUser.id);
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentUser, email, handleSubmit, ui } = this.props;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Delete permanently";

    return (
      <WorkspaceLayout secondNav={<AccountSettingsNav user={currentUser} />}>
        <Container>
          <Header>
            <h1>Delete your account</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <p>Use this form to delete your account and all its data.</p>
            <p>This action cannot be reversed and will permanently delete all your data.</p>
            <p>You will need to type in your email ({currentUser.email}) to confirm.</p>
            <form onSubmit={handleSubmit(this.deleteAccount)}>
              <FormField>
                <label htmlFor="name">Email</label>
                <Field component="input" type="text" name="email" className="tiny" />
              </FormField>
              <FormActions>
                <button
                  type="submit"
                  className="danger-primary auto-size"
                  disabled={ui.formState === "requesting" || email !== currentUser.email}
                >
                  {submitText}
                </button>
              </FormActions>
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  ui: state.ui.workspaceSettingsDelete,
  email: formSelector(state, "email")
});

const mapDispatchToProps = {
  deleteAccount: accountOperations.deleteAccount
};

const formSelector = formValueSelector("accountSettingsDelete");

const _AccountSettingsDelete = reduxForm({
  form: "accountSettingsDelete"
})(AccountSettingsDelete);

export default connect(mapStateToProps, mapDispatchToProps)(_AccountSettingsDelete);
