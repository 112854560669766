//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import * as squadTypes from "squadTypes";
import moment from "moment";
import { goalsOperations } from "state/ducks/goals";
import { reactionsOperations } from "state/ducks/reactions";
import { uiOperations } from "state/ducks/ui";

// Components
import Avatar from "v2/components/Avatar";
import ButtonIcon from "v2/components/ButtonIcon";
import IconComment from "v2/components/svg/IconComment";
import IconLike from "v2/components/svg/IconLike";
import StatusBox from "v2/components/StatusBox";
import ReactTooltip from "react-tooltip";
import Markdown from "v2/components/Markdown";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

const UpdateContainer = styled.div`
  display: flex;
  border-top: 1px solid ${colors.blockBorder};
  padding: ${spacing.x2} ${spacing.x2} 0 ${spacing.x2};
  margin-top: ${spacing.x2};

  &.latest {
    background: #f3f3f3;
    border-radius: 8px;
    padding: ${spacing.x2};
    margin-top: 0;
    border-top: 0;
  }
`;
const AvatarWrapper = styled.div`
  margin-right: ${spacing.x2};
`;
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.x1};
`;

const Progress = styled.div`
  display: flex;
`;

const Actions = styled.div`
  display: flex;
  button {
    margin-right: ${spacing.x1};
    span {
      padding-left: 4px;
    }
    padding: 0 ${spacing.x1};
  }
`;

const ContentMain = styled.div`
  margin-bottom: ${spacing.x1};
`;
const ContentFooter = styled.div``;
type Props = {
  currentMembership: squadTypes.Membership,
  createReactionForGoal: Function,
  deleteReaction: Function,
  fetchGoalDetails: Function,
  goal: squadTypes.Goal,
  match: Object,
  showModal: Function
};

class StatusUpdateCard extends Component<Props> {
  componentDidMount() {
    const { match } = this.props;
    if ("first" === match.params.statusUpdate) {
      this.showComments();
    }
  }

  showComments = (e?: Object) => {
    if (e) {
      e.preventDefault();
    }

    this.props.showModal({
      contentType: "goal.comments",
      contentAction: "view",
      object: this.props.goal,
      centered: false
    });
  };

  addReaction = (e: Object) => {
    e.preventDefault();
    const { goal } = this.props;
    this.props.createReactionForGoal(goal.id, {}).then(() => {
      this.props.fetchGoalDetails(goal.id);
    });
  };

  deleteReaction = (e: Object) => {
    e.preventDefault();
    const { goal } = this.props;
    this.props.deleteReaction(goal.reactionable_id).then(() => {
      this.props.fetchGoalDetails(goal.id);
    });
  };

  render() {
    const { goal } = this.props;
    const { owner } = goal;
    let scoreWithFormat = "";
    let colorToString = "";
    colorToString = "Goal created";

    return (
      <UpdateContainer>
        <AvatarWrapper>
          <Avatar user={owner} size={24} data-tip data-for={`status-${owner.id}-author`} />
          {owner && (
            <ReactTooltip id={`status-${owner.id}-author`} place="bottom" type="dark" effect="solid" delayShow={500}>
              {owner.fullname}
            </ReactTooltip>
          )}
        </AvatarWrapper>
        <ContentWrapper>
          <ContentHeader>
            <Progress>
              <StatusBox className="grey" />
              {scoreWithFormat && <span>{scoreWithFormat}</span>}
              {!scoreWithFormat && <span>{colorToString}</span>}
            </Progress>
            <Actions>
              <ButtonIcon className="auto-width" onClick={this.showComments}>
                <IconComment />
                {goal.comments_count > 0 && <span className="counter">{goal.comments_count}</span>}
              </ButtonIcon>
              {!goal.reactionable_id && (
                <ButtonIcon className="auto-width" onClick={this.addReaction}>
                  <IconLike className="like" />
                  {goal.reactions_count > 0 && <span className="counter">{goal.reactions_count}</span>}
                </ButtonIcon>
              )}
              {goal.reactionable_id && (
                <ButtonIcon className="auto-width" onClick={this.deleteReaction}>
                  <IconLike className="like remove" />
                  {goal.reactions_count > 0 && <span className="counter">{goal.reactions_count}</span>}
                </ButtonIcon>
              )}
            </Actions>
          </ContentHeader>
          {goal.description && (
            <ContentMain>
              <Markdown source={goal.description} />
            </ContentMain>
          )}
          <ContentFooter>
            <small className="subtle">{moment(goal.created_at).fromNow()}</small>
          </ContentFooter>
        </ContentWrapper>
      </UpdateContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentMembership: state.session.currentMembership
});

const mapDispatchToProps = {
  fetchGoalDetails: goalsOperations.fetchGoalDetails,
  deleteReaction: reactionsOperations.deleteReaction,
  showModal: uiOperations.showModal,
  createReactionForGoal: reactionsOperations.createReactionForGoal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatusUpdateCard));
