// @flow
import { createReducer } from "state/utils";
import * as types from "./types";

const INITIAL_STATE = {
  expandedProjects: {}
};

export default createReducer(INITIAL_STATE)({
  [types.TOGGLE_PROJECT_NAV]: (state, action) => {
    const { expandedProjects } = state;
    const { projectId } = action;

    if (expandedProjects[projectId]) {
      expandedProjects[projectId] = false;
    } else {
      expandedProjects[projectId] = true;
    }

    return {
      expandedProjects
    };
  },
  [types.EXPAND_PROJECT_NAV]: (state, action) => {
    const { expandedProjects } = state;
    const { projectId } = action;

    expandedProjects[projectId] = true;

    return {
      expandedProjects
    };
  }
});
