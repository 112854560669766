//@flow
export const CREATE_REQUESTED = "comments/CREATE_REQUESTED";
export const CREATE_COMPLETED = "comments/CREATE_COMPLETED";
export const CREATE_FAILED = "comments/CREATE_4_GOAL_FAILED";

export const UPDATE_REQUESTED = "comments/UPDATE_REQUESTED";
export const UPDATE_COMPLETED = "comments/UPDATE_COMPLETED";
export const UPDATE_FAILED = "comments/UPDATE_FAILED";

export const DELETE_REQUESTED = "comments/DELETE_REQUESTED";
export const DELETE_COMPLETED = "comments/DELETE_COMPLETED";
export const DELETE_FAILED = "comments/DELETE_FAILED";

export const FETCH_LIST_REQUESTED = "comments/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "comments/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "comments/FETCH_LIST_FAILED";

export const FETCH_DETAILS_REQUESTED = "comments/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "comments/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "comments/FETCH_DETAILS_FAILED";

export const RESET_LIST = "comments/RESET_LIST";
