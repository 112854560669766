import React, { Component } from "react";
import { connect } from "react-redux";
import { searchedGoalsOperations } from "state/ducks/searchedGoals";
import { goalsOperations } from "state/ducks/goals";
import { push } from "react-router-redux";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import moment from "moment";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

import GoalLine from "./GoalLine";
import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

// Components
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GoalDetails from "v2/components/GoalDetails";
import GoalBlockPage from "v2/components/GoalBlockPage";
import Filters from "./Filters";
import ReactTooltip from "react-tooltip";

const Header = styled.div`
  margin-bottom: ${spacing.x4};
`;

const Container = styled.div`
  padding: ${spacing.x4};
  padding-bottom: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  flex: 1;
  position: relative;
`;

const GoalsContent = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > div {
    margin-bottom: ${spacing.x4};
  }

  .goals-container {
    flex: 4;
    overflow: auto;
    margin-right: ${spacing.x2};
  }

  .goal-details-container {
    flex: 8;
    display: block;
    overflow: auto;
  }
`;

const GoalGridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 5rem 18rem 18rem 10rem 18rem 15rem;
  grid-template-rows: 1fr;
  grid-template-areas: "tgoal tdependency tproject tprogress towner tdeadline tactions";

  padding: ${spacing.x1} ${spacing.x2};

  .tgoal {
    grid-area: tgoal;
  }
  .tdependency {
    grid-area: tdependency;
  }
  .tproject {
    grid-area: tproject;
  }
  .tprogress {
    grid-area: tprogress;
  }
  .towner {
    grid-area: towner;
  }
  .tdeadline {
    grid-area: tdeadline;
  }
  .tactions {
    grid-area: tactions;
  }
  .theader {
    color: ${colors.subtleText};

    a, .link-button {
      color: ${colors.subtleText};
      text-decoration: underline;

      span {
        transform
      }
    }
  }
  .desc {
    display: inline-block;
    margin-left: 0.4rem;
    transform: rotate(90deg);
  }
  .asc {
    display: inline-block;
    margin-left: 0.4rem;
    transform: rotate(-90deg);
  }
`;

const NoGoals = styled.div`
  text-align: center;
  margin: 5rem auto;
  width: 55rem;
  p {
    font-size: 1.6rem;
    margin-bottom: ${spacing.x1};
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;

  .stat {
    margin-left: ${spacing.x2};
    margin-right: 0.4rem;
    height: 1.3rem;
  }

  span {
    font-size: 1.6rem;
  }
`;

const StatsData = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin: ${spacing.x1} 0;
  text-align: right;

  div {
    position: relative;
    font-size: 1.6rem;
    padding: 0.5rem;
    margin-left: 1.5rem;
    border-radius: 2px;
    color: ${colors.black} !important;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      border-radius: 1.5px;
    }

    &.red::after {
      background: ${colors.red};
    }
    &.yellow::after {
      background: ${colors.yellow};
    }
    &.green::after {
      background: ${colors.green};
    }
    &.grey::after {
      background: ${colors.grey};
    }
  }
`;

const ExportLink = styled.div`
  text-align: right;

  div:first-child {
    display: inline-block;
  }
`;

const ProgressContainer = styled.div`
  width: 5rem;
  margin-right: ${spacing.x2};
`;

const DEFAULT_ORDER_ATTRIBUTE = "created_at";
const DEFAULT_ORDER_DIRECTION = "desc";

type Props = {
  currentGoal: squadTypes.Goal,
  fetchGoalDetails: Function,
  fetchGoalSearch: Function,
  fetchGoalSearchStats: Function,
  goals: squadTypes.NormalizedList<squadTypes.Goal>,
  location: Object,
  match: Object,
  push: Function,
  resetGoalList: Function,
  ui: Object
};

class GoalList extends Component<Props, State> {
  componentDidMount() {
    document.title = "Goals | Tability";
    const { fetchGoalDetails, resetGoalList, location, match } = this.props;

    resetGoalList();
    this.fetchMore(match.params.filter, 1);

    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const selected = params.get("selected");
      if (selected) {
        fetchGoalDetails(selected);
      }
    }
  }

  downloadExport = e => {
    e.preventDefault();
    const { match } = this.props;
    const filter = match.params.filter;
    const slug = getSubdomain() || "";

    let filterHash = {};
    if (filter) {
      const decodedFilter = atob(filter);
      filterHash = JSON.parse(decodedFilter);
    }

    let { project, membership, state, status, tag, timeline, custom_start_date, custom_deadline } = filterHash;
    let start_date, deadline;
    let startMoment, deadlineMoment;

    switch (timeline) {
      case "current_quarter":
        startMoment = moment().startOf("quarter");
        deadlineMoment = moment(startMoment).endOf("quarter");

        start_date = startMoment.toISOString();
        deadline = deadlineMoment.toISOString();

        break;
      case "last_quarter":
        startMoment = moment()
          .subtract(3, "months")
          .startOf("quarter");
        deadlineMoment = moment(startMoment).endOf("quarter");

        start_date = startMoment.toISOString();
        deadline = deadlineMoment.toISOString();

        break;
      case "next_quarter":
        startMoment = moment()
          .add(3, "months")
          .startOf("quarter");
        deadlineMoment = moment(startMoment).endOf("quarter");

        start_date = startMoment.toISOString();
        deadline = deadlineMoment.toISOString();

        break;
      case "custom":
        start_date = custom_start_date;
        deadline = custom_deadline;
        break;
      default:
        start_date = null;
        deadline = null;
    }

    const membershipIds = membership ? membership.map(hash => hash.value) : null;

    const params = {
      project,
      membership: membershipIds,
      state,
      status,
      tag,
      start_date,
      deadline
    };

    const show_archived = params ? params.show_archived || null : null;
    const encodedParams = params ? btoa(JSON.stringify(params)) : "";
    request.post(`/workspaces/${slug}/goals/export`, { filter: encodedParams, show_archived }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const linkName = `goals-${moment().format("Y-MM-DD")}.csv`;
      link.setAttribute("download", linkName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  componentWillReceiveProps(nextProps) {
    const { location, resetGoalList } = this.props;

    const currentFilter = this.props.match.params.filter;
    const nextFilter = nextProps.match.params.filter;
    if (currentFilter !== nextFilter) {
      resetGoalList();
      this.fetchMore(nextFilter, 1);
    }

    const nextLocation = nextProps.location;

    if (nextLocation && nextLocation.search) {
      const nextParams = new URLSearchParams(nextLocation.search);
      const nextSelected = nextParams.get("selected");
      if (nextSelected) {
        let currentSelected = null;
        if (location && location.search) {
          const currentParams = new URLSearchParams(location.search);
          currentSelected = currentParams.get("selected");
        }
        if (nextSelected !== currentSelected) {
          this.props.fetchGoalDetails(nextSelected);
        }
      }
    }
  }

  // This function extracts the current page from the Redux state and
  // fetches the next page.
  updateSort(order_param) {
    const { filter } = this.props.match.params;
    let filterHash = {};
    if (filter) {
      const decodedFilter = atob(filter);
      filterHash = JSON.parse(decodedFilter);
    }

    let {
      project,
      membership,
      state,
      status,
      tag,
      timeline,
      custom_start_date,
      custom_deadline,
      order_attribute,
      order_direction
    } = filterHash;

    // Set the default value in case they're not provided in the filters
    order_attribute = order_attribute || DEFAULT_ORDER_ATTRIBUTE;
    order_direction = order_direction || DEFAULT_ORDER_DIRECTION;

    // If user clicked on the same order param, change the direction
    if (order_attribute === order_param) {
      // Change the direction
      if (order_direction === "asc") {
        order_direction = "desc";
      } else {
        order_direction = "asc";
      }
    } else {
      // Default order direction is alwasy desc
      order_direction = "desc";
    }

    order_attribute = order_param;

    const params = {
      project,
      membership,
      state,
      status,
      tag,
      timeline,
      custom_start_date,
      custom_deadline,
      order_attribute,
      order_direction
    };
    const encodedParams = btoa(JSON.stringify(params));
    this.props.push(`/goals/${encodedParams}`);
  }

  // This function extracts the current page from the Redux state and
  // fetches the next page.
  fetchMore(filter, page = null) {
    const { goals } = this.props;
    let filterHash = {};
    if (filter) {
      const decodedFilter = atob(filter);
      filterHash = JSON.parse(decodedFilter);
    }

    let {
      project,
      membership,
      state,
      status,
      tag,
      timeline,
      custom_start_date,
      custom_deadline,
      order_attribute,
      order_direction
    } = filterHash;
    let start_date, deadline;
    let startMoment, deadlineMoment;

    switch (timeline) {
      case "current_quarter":
        startMoment = moment().startOf("quarter");
        deadlineMoment = moment(startMoment).endOf("quarter");

        start_date = startMoment.toISOString();
        deadline = deadlineMoment.toISOString();

        break;
      case "last_quarter":
        startMoment = moment()
          .subtract(3, "months")
          .startOf("quarter");
        deadlineMoment = moment(startMoment).endOf("quarter");

        start_date = startMoment.toISOString();
        deadline = deadlineMoment.toISOString();

        break;
      case "next_quarter":
        startMoment = moment()
          .add(3, "months")
          .startOf("quarter");
        deadlineMoment = moment(startMoment).endOf("quarter");

        start_date = startMoment.toISOString();
        deadline = deadlineMoment.toISOString();

        break;
      case "custom":
        start_date = custom_start_date;
        deadline = custom_deadline;
        break;
      default:
        start_date = null;
        deadline = null;
    }

    order_attribute = order_attribute || DEFAULT_ORDER_ATTRIBUTE;
    order_direction = order_direction || DEFAULT_ORDER_DIRECTION;

    const membershipIds = membership ? membership.map(hash => hash.value) : null;

    const params = {
      page: page || goals.currentPage + 1,
      project,
      membership: membershipIds,
      state,
      status,
      tag,
      start_date,
      deadline,
      order_attribute,
      order_direction
    };
    this.props.fetchGoalSearchStats(params);
    return this.props.fetchGoalSearch(params);
  }

  render() {
    const { currentGoal, currentWorkspace, goals, location, ui } = this.props;
    const { stats } = goals;
    let selectedGoal = null;
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      selectedGoal = params.get("selected");
    }
    const hasCurrentGoal = (currentGoal && true) || selectedGoal;
    // Get the filter information from the URL itself
    const { filter } = this.props.match.params;
    let filterHash = {};
    if (filter) {
      const decodedFilter = atob(filter);
      filterHash = JSON.parse(decodedFilter);
    }

    const defaultOrderAttribute = filterHash["order_attribute"] || DEFAULT_ORDER_ATTRIBUTE;
    const defaultOrderDirection = filterHash["order_direction"] || DEFAULT_ORDER_DIRECTION;

    return (
      <WorkspaceLayout>
        <Container>
          <Header>
            <h1>Goals</h1>
            <Filters />
            {goals.total === 0 && !ui.isFetchingGoals && <h3>We could not find any goals matching your selection.</h3>}
            {goals.total > 0 && stats.prct_target_normalized !== null && (
              <ProgressWrapper>
                <ProgressContainer>
                  <CircularProgressbar
                    percentage={Math.round(stats.prct_target_normalized * 100)}
                    text={`${Math.round(stats.prct_target_normalized * 100)}%`}
                    backgroundPadding={6}
                    strokeWidth={4}
                    styles={{
                      text: {
                        fontSize: "2.6rem",
                        fontWeight: "500",
                        fill: colors.black
                      },
                      path: { stroke: colors.progressBar },
                      trail: { stroke: colors.progressBarBg }
                    }}
                  />
                </ProgressContainer>
                <h3>
                  {goals.total} goal{goals.total !== 1 && "s"} matching
                </h3>
                <StatsData>
                  <div className="grey" data-tip data-for={`draftGoals`}>
                    {stats.draft_goals}
                  </div>
                  <div className="green" data-tip data-for={`greenGoals`}>
                    {stats.active_green_goals}
                  </div>
                  <div className="yellow" data-tip data-for={`yellowGoals`}>
                    {stats.active_yellow_goals}
                  </div>
                  <div className="red" data-tip data-for={`redGoals`}>
                    {stats.active_red_goals}
                  </div>
                  <div className="black" data-tip data-for={`closedGoals`}>
                    {stats.closed_goals} / <span className="light">{stats.total_goals} closed</span>
                  </div>
                </StatsData>
                <ReactTooltip id={`closedGoals`} place="bottom" type="dark" effect="solid">
                  {stats.closed_goals} goal{stats.closed_goals !== 1 && "s"} closed
                </ReactTooltip>
                <ReactTooltip id={`draftGoals`} place="bottom" type="dark" effect="solid">
                  {stats.draft_goals} goal{stats.draft_goals !== 1 && "s"} pending updates
                </ReactTooltip>
                <ReactTooltip id={`greenGoals`} place="bottom" type="dark" effect="solid">
                  {stats.active_green_goals} goal{stats.active_green_goals !== 1 && "s"} on-track
                </ReactTooltip>
                <ReactTooltip id={`yellowGoals`} place="bottom" type="dark" effect="solid">
                  {stats.active_yellow_goals} goal{stats.active_yellow_goals !== 1 && "s"} at-risk
                </ReactTooltip>
                <ReactTooltip id={`redGoals`} place="bottom" type="dark" effect="solid">
                  {stats.active_red_goals} goal{stats.active_red_goals !== 1 && "s"} off-track
                </ReactTooltip>
                {currentWorkspace.stripe_cache_subscription_plan && (
                  <ExportLink>
                    <button onClick={this.downloadExport}>Export to CSV</button>
                  </ExportLink>
                )}
                {!currentWorkspace.stripe_cache_subscription_plan && (
                  <ExportLink>
                    <div data-tip data-for="export-button">
                      <button disabled={true}>Export to CSV</button>
                    </div>
                    <ReactTooltip id="export-button" place="bottom" type="dark" effect="solid">
                      You need to upgrade your plan to use this feature.
                    </ReactTooltip>
                  </ExportLink>
                )}
              </ProgressWrapper>
            )}
          </Header>
          <Main>
            <GoalsContent>
              <div className="goals-container">
                {goals && !ui.isFetchingGoals && goals.total === 0 && (
                  <NoGoals>
                    <p>We could not find any goals matching the filters.</p>
                  </NoGoals>
                )}
                {goals && goals.total > 0 && !hasCurrentGoal && (
                  <GoalGridRow>
                    <div className="tgoal theader">
                      <button className="link-button" onClick={() => this.updateSort("cached_color_index")}>
                        Goal
                        {defaultOrderAttribute === "cached_color_index" && (
                          <span className={defaultOrderDirection}>&#x279C;</span>
                        )}
                      </button>
                    </div>
                    <div className="tdependency theader"></div>
                    <div className="tproject theader">Page</div>
                    <div className="tprogress theader">
                      <button className="link-button" onClick={() => this.updateSort("prct_target_normalized")}>
                        Progress
                        {defaultOrderAttribute === "prct_target_normalized" && (
                          <span className={defaultOrderDirection}>&#x279C;</span>
                        )}
                      </button>
                    </div>
                    <div className="towner theader">Owner</div>
                    <div className="tdeadline theader">
                      <button className="link-button" onClick={() => this.updateSort("deadline")}>
                        Deadline
                        {defaultOrderAttribute === "deadline" && (
                          <span className={defaultOrderDirection}>&#x279C;</span>
                        )}
                      </button>
                    </div>
                    <div className="tactions theader"></div>
                  </GoalGridRow>
                )}
                {goals &&
                  goals.allIds.map(goalId => {
                    const goal = goals.byId[goalId];
                    if (!goal) {
                      return false;
                    }
                    const isSelected = hasCurrentGoal && currentGoal ? goal.id === currentGoal.id : false;
                    if (hasCurrentGoal) {
                      return (
                        <GoalBlockPage
                          key={goal.id}
                          goal={goal}
                          showProject={true}
                          isSelected={isSelected}
                          maskTasks={true}
                          className="compact"
                          currentGoal={currentGoal}
                          basePath={window.location.pathname}
                        />
                      );
                    }
                    return <GoalLine key={goal.id} goal={goal} isSelected={isSelected} currentGoal={currentGoal} />;
                  })}
                {goals.hasNext && !ui.isFetchingGoals && (
                  <button
                    className="secondary"
                    onClick={e => {
                      this.fetchMore(this.props.match.params.filter);
                      e.preventDefault();
                    }}
                  >
                    Load more
                  </button>
                )}
                {(!goals.hasFetchedListOnce || ui.isFetchingGoals) && <Loader />}
              </div>
              {hasCurrentGoal && (
                <div className="goal-details-container">
                  {currentGoal && <GoalDetails goal={currentGoal} />}
                  {!currentGoal && <Loader />}
                </div>
              )}
            </GoalsContent>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { location } = props;
  let currentGoal = null;
  if (location && location.search) {
    const params = new URLSearchParams(location.search);
    const selected = params.get("selected");
    if (selected && state.goals.byId) {
      currentGoal = state.goals.byId[selected];
    }
  }
  return {
    currentWorkspace: state.session.currentWorkspace,
    currentGoal,
    goals: state.searchedGoals,
    ui: state.ui.goalList
  };
};

const mapDispatchToProps = {
  fetchGoalDetails: goalsOperations.fetchGoalDetails,
  fetchGoalSearch: searchedGoalsOperations.fetchGoalSearch,
  fetchGoalSearchStats: searchedGoalsOperations.fetchGoalSearchStats,
  resetGoalList: searchedGoalsOperations.resetGoalList,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalList);
