//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
// Components
import { spacing } from "v2/components/styles/spacing";
import ButtonIcon from "v2/components/ButtonIcon";
import IconPlus from "v2/components/svg/IconPlus";
import TagForm from "./TagForm";
import { Link } from "react-router-dom";

const Container = styled.div`
  .tag-list {
    margin-bottom: ${spacing.x1};
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button {
      margin-bottom: ${spacing.x1};
    }
  }
  .header {
    display: flex;

    .action {
      display: none;
    }

    &:hover .action {
      display: inline-block;
    }
  }
`;

const Tag = styled.div`
  display: inline-block;
  padding: 0 ${spacing.x1};
  height: 3.2rem;
  line-height: 3.2rem;
  margin-right: ${spacing.x1};
  margin-bottom: ${spacing.x1};
  color: #fff;
  background-color: #6096ec;
  border-radius: 15px;
  font-size: 1.4rem;
  font-weight: 400;

  a {
    color: #fff;
  }
`;

type Props = {
  updateGoal: Function,
  goal: squadTypes.Goal,
  ui: Object
};

type State = {
  displayTagInput: string,
  url: string,
  title: ?string
};

class Tags extends Component<Props, State> {
  focusInput: any;
  state = {
    displayTagInput: "",
    url: "",
    title: ""
  };

  showTagInput = e => {
    e.preventDefault();
    this.setState({
      displayTagInput: "new"
    });
  };

  hideTagInput = () => {
    this.setState({
      displayTagInput: ""
    });
  };

  getTagLink = (tagId: string) => {
    const params = {
      tag: tagId
    };
    const encodedParams = btoa(JSON.stringify(params));
    return `/goals/${encodedParams}`;
  };

  render() {
    const { goal } = this.props;
    const tags = goal.tags ? goal.tags : [];
    return (
      <Container>
        {!this.state.displayTagInput && (
          <div className="tag-list">
            {tags.map(tag => (
              <Tag key={tag.id}>
                <Link to={this.getTagLink(tag.id)}>{tag.title}</Link>
              </Tag>
            ))}
            <ButtonIcon onClick={this.showTagInput}>
              <IconPlus style={{ width: "1.2rem" }} />
            </ButtonIcon>
          </div>
        )}
        {this.state.displayTagInput === "new" && <TagForm goal={this.props.goal} hideTagInput={this.hideTagInput} />}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
