// @flow
import { createReducer } from "state/utils";
import * as uiTypes from "./types";

const INITIAL_STATE = {
  visible: false,
  contentType: "",
  contentAction: "",
  object: null,
  parent: null,
  centered: true
};

export default createReducer(INITIAL_STATE)({
  [uiTypes.SHOW_MODAL]: (state, action) => {
    const centered = action.centered;
    return {
      ...state,
      visible: true,
      contentType: action.contentType,
      contentAction: action.contentAction,
      object: action.object,
      parent: action.parent,
      centered: centered
    };
  },
  [uiTypes.HIDE_MODAL]: state => INITIAL_STATE,
  [uiTypes.CLEAR_MODAL]: () => INITIAL_STATE
});
