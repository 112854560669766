// @flow
import { createReducer } from "state/utils";
import * as projectsTypes from "state/ducks/projects/types";
import * as goalsTypes from "state/ducks/goals/types";
import * as tasksTypes from "state/ducks/tasks/types";

const INITIAL_STATE = {
  isFetchingProjects: true,
  isFetchingGoals: true,
  isFetchingTasks: true
};

export default createReducer(INITIAL_STATE)({
  [projectsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingProjects: false
  }),
  [projectsTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingProjects: false
  }),
  [projectsTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingProjects: true
  }),

  // handles loading for fetching goals
  [goalsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingGoals: false
  }),
  [goalsTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingGoals: false
  }),
  [goalsTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingGoals: true
  }),
  // handles loading for fetching tasks
  [tasksTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingTasks: false
  }),
  [tasksTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingTasks: false
  }),
  [tasksTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingTasks: true
  })
});
