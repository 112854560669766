// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { devices } from "v2/components/styles/devices";
import { spacing } from "./styles/spacing";

import { Link } from "react-router-dom";
import NotFoundBg from "./_assets/notfound.png";
import Wordmark from "v2/components/_assets/tability_logo_full.png";

const Container = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${devices.mobile} {
    width: 100%;
    padding: ${spacing.x4};
  }
`;

const Header = styled.div`
  text-align: center;
  img {
    height: 4rem;
    display: block;
    margin-bottom: ${spacing.x8};
  }

  h1 {
    margin-bottom: ${spacing.x4};
  }
`;

const Background = styled.div`
  padding: ${spacing.x2};

  img {
    width: 39rem;
  }

  @media ${devices.mobile} {
    img {
      width: 100%;
    }
  }
`;

type Props = {};

type State = {};

class NotFound extends Component<Props, State> {
  componentDidMount() {
    document.title = "This page does not exist | Tability";
  }
  render() {
    return (
      <Container>
        <Header>
          <Link to="/">
            <img src={Wordmark} alt="Tability" />
          </Link>
          <h1>Are you lost?</h1>
        </Header>
        <Background>
          <img src={NotFoundBg} alt="Tabby lost in the desert" />
        </Background>
        <p>The page you are looking for is not here. Click below to retrace your steps.</p>
        <p>
          <Link to="/">Back to main</Link>
        </p>
      </Container>
    );
  }
}

export default NotFound;
