// @flow
import { createReducer } from "state/utils";
import * as sessionTypes from "state/ducks/session/types";
import * as uiTypes from "./types";

const INITIAL_STATE = {
  error: false,
  isFetching: false,
  message: "",
  messageType: "warning",
  signedUp: false
};

export default createReducer(INITIAL_STATE)({
  [sessionTypes.SIGNUP_FAILED]: (state, action) => {
    const errors = action.error.response.data.error;
    return {
      ...state,
      error: true,
      isFetching: false,
      message: errors,
      messageType: "warning",
      signedUp: false
    };
  },
  [sessionTypes.SIGNUP_COMPLETED]: state => ({
    ...state,
    error: false,
    isFetching: false,
    messageType: "success",
    message: "Great! Please check your inbox to confirm your email.",
    signedUp: true
  }),
  [sessionTypes.SIGNUP_REQUESTED]: state => ({
    ...state,
    error: false,
    isFetching: true,
    message: "",
    signedUp: false
  }),
  [uiTypes.CLEAR_SESSION_SIGNUP]: () => INITIAL_STATE
});
