//@flow

export const INITIAL_LIST_STATE = {
  byId: {}, // Objects organized by Ids
  currentPage: 0, // current page seen (pagination-only)
  hasNext: false, // used to determine if we should fetch more results (pagination-only)
  fetchDetailsFailed: false,
  hasFetchedListOnce: false, // used to determine if we've done the initial fetch.
  isPaginated: false, // determines if it's a paginated list
  allIds: [], // List of Ids
  total: 0, // Total count of root objects
  addedId: null, // Last object added to the list
  selectedId: null, // Current object viewed or selected,
  createdId: null // Last object created
};

export const DOMAIN_REGEX = /^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i; // eslint-disable-line
export const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i; // eslint-disable-line

// LOCALIZATION
export const SHORT_DATE_FORMAT = "YYYY-MM-DD";
export const LOCAL_DATE_FORMAT = "LL";

// Billing
export const PLAN_ANNUALLY_STANDARD = "annually-standard";
export const PLAN_MONTHLY_STANDARD = "monthly-standard";
export const PLAN_ANNUALLY_BUSINESS = "annually-business";
export const PLAN_MONTHLY_BUSINESS = "monthly-business";

export const BILLING_MONTHLY_PLANS = [PLAN_MONTHLY_STANDARD, PLAN_MONTHLY_BUSINESS];
export const BILLING_ANNUALLY_PLANS = [PLAN_ANNUALLY_STANDARD, PLAN_ANNUALLY_BUSINESS];

export const PLAN_MONTHLY_PACKAGE_PILOT = "monthly-package-pilot";
export const PLAN_ANNUALLY_PACKAGE_PILOT = "annually-package-pilot";
export const PLAN_MONTHLY_PACKAGE_TEAMS = "monthly-package-teams";
export const PLAN_ANNUALLY_PACKAGE_TEAMS = "annually-package-teams";
export const PLAN_MONTHLY_PACKAGE_BUSINESS = "monthly-package-business";
export const PLAN_ANNUALLY_PACKAGE_BUSINESS = "annually-package-business";
export const PLAN_MONTHLY_PACKAGE_UNLIMITED = "monthly-package-unlimited";
export const PLAN_ANNUALLY_PACKAGE_UNLIMITED = "annually-package-unlimited";
export const BILLING_PACKAGE_MONTHLY_PLANS = [
  PLAN_MONTHLY_PACKAGE_PILOT,
  PLAN_MONTHLY_PACKAGE_TEAMS,
  PLAN_MONTHLY_PACKAGE_BUSINESS,
  PLAN_MONTHLY_PACKAGE_UNLIMITED
];
export const BILLING_PACKAGE_ANNUALLY_PLANS = [
  PLAN_ANNUALLY_PACKAGE_PILOT,
  PLAN_ANNUALLY_PACKAGE_TEAMS,
  PLAN_ANNUALLY_PACKAGE_BUSINESS,
  PLAN_ANNUALLY_PACKAGE_UNLIMITED
];

export const BILLING_PER_USER_PLANS = [...BILLING_ANNUALLY_PLANS, ...BILLING_MONTHLY_PLANS];

export const BILLING_PACKAGE_PLANS = [...BILLING_PACKAGE_MONTHLY_PLANS, ...BILLING_PACKAGE_ANNUALLY_PLANS];

export const BILLING_ACCESS_CONTROL_PLANS = [
  PLAN_MONTHLY_BUSINESS,
  PLAN_ANNUALLY_BUSINESS,
  PLAN_MONTHLY_PACKAGE_TEAMS,
  PLAN_ANNUALLY_PACKAGE_TEAMS,
  PLAN_MONTHLY_PACKAGE_BUSINESS,
  PLAN_ANNUALLY_PACKAGE_BUSINESS,
  PLAN_MONTHLY_PACKAGE_UNLIMITED,
  PLAN_ANNUALLY_PACKAGE_UNLIMITED
];

export const PUBLIC_PATHS = [
  "/jira/install",
  "/slack/install",
  "/google/authenticate",
  "/slack/authenticate",
  "/login",
  "/signup",
  "/confirm",
  "/password/reset",
  "/password/new",
  "/saml/authenticate",
  "/unsubscribe"
];
