//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";

import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import { push } from "react-router-redux";
import * as squadTypes from "squadTypes";

import styled from "styled-components";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

import Avatar from "v2/components/Avatar";
import DropdownMenu from "v2/components/DropdownMenu";

import Logo from "v2/components/_assets/tability_icon_black.png";
import IconDashboard from "v2/components/svg/IconDashboard";
import ReactTooltip from "react-tooltip";

const PrincipalNav = styled.div`
  width: 6rem;
  background-color: #8cc1b5;

  display: flex;
  flex-direction: column;

  .nav {
    display: flex;
    width: 100%;
    background-color: #8cc1b5;
    border-left: 5px solid #8cc1b5;
    align-items: center;
    justify-content: center;
    height: 5rem;

    svg {
      width: 1.8rem;
      position: relative;
      right: 5px;

      g {
        stroke: #fff;
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      background-color: #fff;
      border-left: 5px solid #fff;
      outline: 0;

      svg {
        g {
          stroke: #0a5055;
        }
      }
    }

    &.active {
      background-color: #f8f8f8;
      border-left: 5px solid #0a5055;

      svg {
        g {
          stroke: #0a5055;
        }
      }

      &:hover,
      &:focus,
      &:focus-within {
        background-color: #fff;
        outline: 0;
        svg {
          g {
            stroke: #0a5055;
          }
        }
      }
    }
  }
`;

const HeaderNavLinks = styled.div`
  margin: ${spacing.x4} 0 ${spacing.x10} 0;
  a {
    img {
      width: 3rem;
      position: relative;
      right: 5px;
    }
    padding: 0;
    margin: 0;
  }

  .link-button {
    margin-top: ${spacing.x1};
  }

  @media ${devices.tablet} {
    margin: ${spacing.x2} 0;
  }
`;

const MainNavLinks = styled.div`
  flex: 1;
`;

const BottomNavLinks = styled.div`
  margin-bottom: ${spacing.x4};

  .avatar {
    position: relative;
    right: 5px;
  }

  @media ${devices.tablet} {
    margin-bottom: ${spacing.x2};
  }
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  logout: Function,
  notifications: Object,
  push: Function,
  secondNav?: any,
  showModal: Function,
  ui: Object,
  visitWhatsNew: Function,
  whatsNewVisited: string
};

class WorkspaceLayout extends Component<Props> {
  logout = (e: Object) => {
    e.preventDefault();
    this.props.logout();
  };

  _getAccountMenuItems = () => {
    const items = [];
    items.push(<NavLink to="/account">Manage your account</NavLink>);
    items.push(
      <button className="link-button" onClick={this.logout}>
        Logout
      </button>
    );
    return items;
  };

  render() {
    const { currentUser } = this.props;

    return (
      <PrincipalNav aria-label="Main navigation">
        <HeaderNavLinks className="header">
          <Link to="/" className="logo nav">
            <img src={Logo} alt="Tability" />
          </Link>
        </HeaderNavLinks>
        <MainNavLinks>
          <ul>
            <li>
              <NavLink to="/" exact={true} data-tip data-for="dashboardTip" className="nav">
                <IconDashboard />
              </NavLink>
              <ReactTooltip id="dashboardTip" place="right" type="dark" effect="solid">
                Your workspaces
              </ReactTooltip>
            </li>
          </ul>
        </MainNavLinks>
        <BottomNavLinks>
          <DropdownMenu
            className="nav"
            position="left"
            trigger={<Avatar user={currentUser} size={28} />}
            items={this._getAccountMenuItems()}
          />
        </BottomNavLinks>
      </PrincipalNav>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  currentUser: state.session.currentUser,
  currentMembership: state.session.currentMembership,
  notifications: state.notifications,
  ui: state.ui.workspaceNav,
  whatsNewVisited: state.session.whatsNewVisited
});

const mapDispatchToProps = {
  push,
  logout: sessionOperations.logout,
  visitWhatsNew: sessionOperations.visitWhatsNew,
  showModal: uiOperations.showModal
};

export default //withRouter(
connect(mapStateToProps, mapDispatchToProps)(WorkspaceLayout);
//);
