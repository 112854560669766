// @flow
/**
 * This page is used to perform Slack authentication and redirect to the right domain
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { integrationsOperations, integrationsSelectors } from "state/ducks/integrations";

// Components
import Loader from "v2/components/Loader";
import FormField from "v2/components/FormField";
import FormActions from "v2/components/FormActions";
import Select from "v2/components/Select";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  letter-spacing: -0.16px;

  h1 {
    font-weight: 500;
    color: #172b4d;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.24px;
    margin-bottom: 35px;
  }

  form {
    max-width: 500px;
  }

  button {
    margin-bottom: 10px;
  }
`;

type Props = {
  currentIntegration: Object,
  isAdminOfIntegrationWorkspace: boolean,
  integrationWorkspace: Object,
  location: Object,
  ui: Object,
  fetchIntegrationDetails: Function,
  fetchWorkspaceList: Function,
  updateIntegration: Function,
  availableMemberships: Array<Object>
};

type State = {
  selectedWorkspace: ?Object,
  workspaceOptions: Array<Object>
};

class JiraConnectSettings extends Component<Props, State> {
  state = {
    workspaceOptions: [],
    selectedWorkspace: {
      value: "",
      label: "Select a workspace"
    }
  };

  componentDidMount() {
    const { location, fetchIntegrationDetails } = this.props;
    // get the params from the URL
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const remoteId = params.get("remote_id");
      fetchIntegrationDetails(remoteId).then(() => {
        this._setDefaults();
      });
    }
  }

  _setDefaults = () => {
    const { currentIntegration, availableMemberships } = this.props;

    let selectedWorkspace = {
      value: "",
      label: "Select a workspace"
    };

    const workspaceOptions = availableMemberships.map(m => {
      const option = {
        value: m.workspace_id,
        label: m.name,
        url: `https://${m.slug}.${process.env.REACT_APP_DOMAIN || ""}`
      };

      if (currentIntegration && m.workspace_id === currentIntegration.workspace_id) {
        selectedWorkspace = option;
      }
      return option;
    });

    this.setState({
      selectedWorkspace,
      workspaceOptions
    });
  };

  _formatWorkspaceOptionLabel = (props, params) => {
    if (params.context === "menu") {
      return (
        <div>
          <div className="option-label">{props.label}</div>
          <div className="option-path">{props.url}</div>
        </div>
      );
    } else {
      return <div className="">{props.label}</div>;
    }
  };

  /*
  _registerWebhook = () => {
    const url = "/rest/api/2/webhook";
    const data = {
      webhooks: [
        {
          events: [
            "jira:issue_updated",
            "jira:issue_deleted"
          ]
        }
      ],
      url: "https://notifications-tability.ngrok.io/jira"
    }
    if (window.AP) {
      window.AP.request({
        url,
        contentType: 'application/json',
        headers: {
          'Accept': 'application/json'
        },
        type: "POST",
        data: JSON.stringify(data),
        success: (responseText) => {
          console.log(responseText)
        },
        error: function(xhr, statusText, errorThrown){
          console.log(xhr.responseText);
        }
      })
    }
  }
  */

  _handleWorkspaceSelect = (option: any, actionMeta: any) => {
    if (option) {
      this.setState({ selectedWorkspace: option });
    } else {
      this.setState({ selectedWorkspace: null });
    }
  };

  _handleSave = () => {
    const { currentIntegration, updateIntegration } = this.props;
    const { selectedWorkspace } = this.state;

    const workspace_id = selectedWorkspace ? selectedWorkspace.value : null;
    const params = {
      workspace_id
    };

    updateIntegration(currentIntegration.id, params);
  };

  render() {
    const { ui, currentIntegration, integrationWorkspace, isAdminOfIntegrationWorkspace } = this.props;
    const { selectedWorkspace, workspaceOptions } = this.state;
    const buttonText = ui.isUpdating ? <Loader size="small" /> : "Save";

    if (ui.isFetching) {
      return (
        <Container>
          <Loader />
        </Container>
      );
    }

    if (!currentIntegration) {
      return (
        <Container>
          <h1>Configure Tability for Jira</h1>
          <p>Sorry, we could not find your integration.</p>
          <p>Please contact our team via support@tability.io so that we can look into it.</p>
        </Container>
      );
    }

    // Show an error message if the user can't change the integration workspace
    if (integrationWorkspace && !isAdminOfIntegrationWorkspace) {
      return (
        <Container>
          <h1>Configure Tability for Jira</h1>
          <p>
            The integration is currently associated with the workspace{" "}
            <a href={integrationWorkspace.url} target="_blank" rel="noopener noreferrer">
              {integrationWorkspace.name}
            </a>
            .
          </p>
          <p>You will need to be an admin of that workspace to change this configuration.</p>
        </Container>
      );
    }

    return (
      <Container>
        <h1>Configure Tability for Jira</h1>
        <form>
          <FormField>
            <label>Tability workspace</label>
            <p className="light">Select the corresponding Tability workspace for this Jira instance.</p>
            {integrationWorkspace && (
              <p>
                Your integration is currently associated with the workspace <b>{integrationWorkspace.name}</b>.
              </p>
            )}
            <Select
              value={selectedWorkspace}
              options={workspaceOptions}
              classNamePrefix="react-select"
              onChange={this._handleWorkspaceSelect}
              className={`small`}
              formatOptionLabel={this._formatWorkspaceOptionLabel}
            />
            {ui.error && (
              <span className="error">
                <small>{ui.message.error}</small>
              </span>
            )}
          </FormField>
          <FormActions>
            <button onClick={this._handleSave} type="submit" className="primary" disabled={ui.isUpdating}>
              {buttonText}
            </button>
          </FormActions>
          {!ui.error && ui.message && <p className={`${ui.messageType}`}>{ui.message}</p>}
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { location } = props;
  let remoteId = null;
  let currentIntegration = null;
  let integrationWorkspace = null;
  let isAdminOfIntegrationWorkspace = false;

  // Get the current integration details
  if (location && location.search) {
    const params = new URLSearchParams(location.search);
    remoteId = params.get("remote_id");

    currentIntegration = integrationsSelectors.getListByParam(state.integrations.byId, "remote_id", remoteId)[0];

    // If we have an integration, get the details of the corresponding workspace
    // The workspace details have been fetched in the wrapper for the integration routes
    if (currentIntegration) {
      integrationWorkspace = state.workspaces.byId[currentIntegration.workspace_id];
    }
  }

  const availableMemberships = state.session.memberships.filter(m => {
    // Add the list of the workspaces where the current user is an admin
    if (["owner", "admin"].includes(m.role)) {
      // Check if the current user will be allowed to change the existing integration workspace
      if (currentIntegration && currentIntegration.workspace_id && m.workspace_id === currentIntegration.workspace_id) {
        isAdminOfIntegrationWorkspace = true;
      }
      return true;
    }

    return false;
  });
  return {
    currentIntegration,
    ui: state.ui.jiraConnectSettings,
    availableMemberships,
    integrationWorkspace,
    isAdminOfIntegrationWorkspace
  };
};

const mapDispatchToProps = {
  fetchIntegrationDetails: integrationsOperations.fetchIntegrationDetails,
  updateIntegration: integrationsOperations.updateIntegration
};

export default connect(mapStateToProps, mapDispatchToProps)(JiraConnectSettings);
