// @flow

// This file contains helpers function to make requests to the API endpoints
import axios from "axios";
import { store } from "../../index";
import { sessionOperations } from "state/ducks/session";
import { tabilityApiUrl } from "config/services";

// Get the tokens from the store
// If toke has expired then dispatch a fetchNewToken
// Then perform the request

import { isAccessTokenExpired } from "./tokens";

const getSamlTokens = () => {
  const state = store.getState();

  // First we're going to look to subdomain specific access tokens.
  const { session } = state;
  const { currentSubdomain, samlTokens } = session;

  return samlTokens[currentSubdomain];
};

// Constructs the Auth Headers by looking for an access token
// This function is async to make sure that we wait on the response from the
// auth server before dispatching the request.
const authHeaders = async () => {
  // Get a new access token if the current one is expired
  let tokens, access_token;
  let useSamlTokens = false;

  const samlTokens = getSamlTokens();

  // If we have the subdomains, let's use them.
  if (samlTokens) {
    access_token = samlTokens.access_token;
    tokens = samlTokens;
    useSamlTokens = true;
  } else {
    // Otherwise, let's use the shared credentials
    tokens = store.getState().session.tokens;
    access_token = tokens ? tokens.access_token : null;
  }

  // Refresh the access tokens if the current ones are expired
  if (access_token && isAccessTokenExpired(access_token) && tokens.refresh_token) {
    await store.dispatch(sessionOperations.refreshAccessTokens(tokens));

    if (useSamlTokens) {
      tokens = getSamlTokens();
      access_token = tokens.access_token;
    } else {
      tokens = store.getState().session.tokens;
      access_token = tokens.access_token;
    }
  }

  if (access_token) return { Authorization: `Bearer ${access_token}` };
  else return {};
};

// Create our axios instance and sets the common JSON headers\
let instance;
if (tabilityApiUrl) {
  instance = axios.create({
    baseURL: `${tabilityApiUrl}/v1`
  });
} else {
  instance = axios.create();
}

// Common Headers for all requests
const jsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Tability-Platform": "web"
};

// We create the auth headers at the time of the request
instance.interceptors.request.use(async function(config) {
  let _authHeaders = {};
  // Some calls must not pass auth headers
  // uncomment for signed in calls.
  if (!config.ignoreAuthHeaders) _authHeaders = await authHeaders();

  config.headers = { ...jsonHeaders, ..._authHeaders };
  return config;
});

export default instance;
