// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as squadTypes from "squadTypes";
import { membershipsOperations, membershipsSelectors } from "state/ducks/memberships";
import { uiOperations } from "state/ducks/ui";
import * as membershipTypes from "state/ducks/memberships/types";
import styled from "styled-components";

// Components
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import Avatar from "v2/components/Avatar";

import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  form {
  }

  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin: ${spacing.x2} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: flex-start;
  .avatar {
    position: relative;
    top: -15px;
  }
  h2 {
    margin-left: ${spacing.x2};
  }
`;

const ConfirmationLine = styled.div`
  display: flex;
  margin-top: ${spacing.x1};
  align-items: center;
  ion-icon {
    font-size: 1.8rem;
    margin-right: 4px;
  }

  button {
    margin-left: ${spacing.x2};
  }
`;

type Props = {
  clearPage: Function,
  currentMembership: squadTypes.Membership,
  currentWorkspace: squadTypes.Workspace,
  deleteMembership: Function,
  dispatch: Function,
  selectedMembership: squadTypes.Membership,
  fetchMembershipDetails: Function,
  resendConfirmationEmail: Function,
  syncSlackAccount: Function,
  match: Object,
  handleSubmit: Function,
  ui: Object,
  updateMembership: Function
};

class WorkspaceSettingsMembershipDetails extends Component<Props> {
  componentDidMount() {
    this.props.clearPage();
  }
  componentWillMount() {
    const { selectedMembership, fetchMembershipDetails, match } = this.props;
    const preloadedMembershipId = selectedMembership ? selectedMembership.id : "";
    if (match.params.membership !== preloadedMembershipId) {
      fetchMembershipDetails(match.params.membership);
    }
  }

  updateMembership = membershipValues => {
    this.props.updateMembership(membershipValues.id, membershipValues);
  };

  deleteMembership = (e: Object) => {
    e.preventDefault();
    const { deleteMembership, selectedMembership } = this.props;
    if (!selectedMembership) {
      return false;
    }
    const { user } = selectedMembership;

    if (window.confirm(`Are you sure you want to remove ${user.fullname || user.email} from your workspace?`)) {
      deleteMembership(selectedMembership.id).then(action => {
        if (action.type === membershipTypes.DELETE_COMPLETED) {
          this.props.dispatch(push(`/settings/users`));
        }
      });
    }
  };

  resendConfirmationEmail = e => {
    e.preventDefault();
    const { selectedMembership } = this.props;
    if (!selectedMembership) {
      return false;
    }
    const { user } = selectedMembership;
    const credentials = {
      email: user.email
    };
    this.props.resendConfirmationEmail(credentials);
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  _syncSlackAccount = e => {
    const { selectedMembership, syncSlackAccount } = this.props;
    e.preventDefault();
    syncSlackAccount(selectedMembership.id);
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentMembership, currentWorkspace, selectedMembership, handleSubmit, ui } = this.props;
    const { errorFields } = ui;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Update";

    const slackText = ui.slackState === "requesting" ? <Loader size="small" /> : "Sync Slack account";

    if (!selectedMembership) {
      return (
        <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
          <Container>
            <Loader />
          </Container>
        </WorkspaceLayout>
      );
    }

    const { user } = selectedMembership;

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <TitleBlock>
              <Avatar className="avatar" user={user} pictureOnly={true} hideName={true} size={60} />
              <h2>{user.fullname || user.email}</h2>
            </TitleBlock>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <h2>User settings</h2>
            <form onSubmit={handleSubmit(this.updateMembership)}>
              <FormField>
                <label htmlFor="fullname">Full name</label>
                <span>{user.fullname || <em>-</em>}</span>
                <span className="subtle">
                  <small>Only the user can update their name.</small>
                </span>
              </FormField>
              <FormField>
                <label htmlFor="fullname">Email</label>
                <span>{user.email}</span>
                <span className="subtle">
                  <small>Only the user can update their email.</small>
                </span>
              </FormField>
              {currentWorkspace.auth_type === "shared" && (
                <FormField>
                  <label htmlFor="fullname">2-factor authentication</label>
                  {user.otp_required_for_login && (
                    <span role="img" aria-label="2-fa enabled">
                      &#x2714; Enabled
                    </span>
                  )}
                  {!user.otp_required_for_login && (
                    <span role="img" aria-label="2-fa disabled">
                      &#x274c; Disabled
                    </span>
                  )}
                  <span className="subtle">
                    <small>Only the user can enable 2-factor authentication.</small>
                  </span>
                </FormField>
              )}
              <FormField>
                <label htmlFor="confirmation status">Confirmation status</label>
                {user.confirmed_at && (
                  <ConfirmationLine>
                    <span role="img" aria-label="confirmed">
                      &#x2714;
                    </span>
                    &nbsp;Confirmed
                  </ConfirmationLine>
                )}
                {!user.confirmed_at && (
                  <ConfirmationLine>
                    <span role="img" aria-label="confirmed">
                      &#x274c;
                    </span>{" "}
                    Pending confirmation
                    <button onClick={this.resendConfirmationEmail}>Resend confirmation</button>
                  </ConfirmationLine>
                )}
              </FormField>
              <FormField>
                <label htmlFor="is_enabled">Status</label>
                <Field
                  component="select"
                  type="text"
                  className={`small ${this.getErrorClass("is_enabled")} tiny`}
                  name="is_enabled"
                >
                  <option value="true">enabled</option>
                  <option value="false">disabled</option>
                </Field>
                <span className={`${this.getErrorClass("is_enabled")}`}>
                  <small>{errorFields["is_enabled"]}</small>
                </span>
              </FormField>
              <FormField>
                <label htmlFor="role">Role</label>
                <Field
                  component="select"
                  type="text"
                  className={`small ${this.getErrorClass("role")} tiny`}
                  name="role"
                >
                  <option value="user">user</option>
                  <option value="admin">admin</option>
                  {currentMembership.role === "owner" && <option value="owner">owner</option>}
                </Field>
                <span className={`${this.getErrorClass("role")}`}>
                  <small>{errorFields["role"]}</small>
                </span>
              </FormField>
              <FormActions>
                <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                  {submitText}
                </button>
                <button
                  className="danger auto-size"
                  disabled={ui.formState === "requesting"}
                  onClick={this.deleteMembership}
                >
                  Remove from workspace
                </button>
              </FormActions>
            </form>
            <h2>Slack settings</h2>
            {!currentWorkspace.slack_team_name && (
              <p>
                You need first to <Link to="/settings/integrations">connect your workspace to Slack</Link>.
              </p>
            )}
            {currentWorkspace.slack_team_name && (
              <div>
                {selectedMembership.has_slack_id && (
                  <p>
                    Connected to Slack via{" "}
                    <b>
                      {currentWorkspace.slack_team_name} ({user.email})
                    </b>
                    .
                  </p>
                )}
                {!selectedMembership.has_slack_id && (
                  <p>Their account is not yet connected to Slack. Use the button below to sync your account.</p>
                )}
                <button onClick={this._syncSlackAccount} className="auto-size">
                  {slackText}
                </button>
                {ui.slackState === "failed" && (
                  <p className="danger">
                    <br />
                    Sorry, we could not connect their account using the email <b>{user.email}</b>.
                  </p>
                )}
              </div>
            )}
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selectedMembership = membershipsSelectors.getObjectById(state.memberships.byId, props.match.params.membership);
  return {
    currentMembership: state.session.currentMembership,
    currentWorkspace: state.session.currentWorkspace,
    selectedMembership,
    initialValues: selectedMembership,
    ui: state.ui.workspaceSettingsMembershipDetails
  };
};

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  deleteMembership: membershipsOperations.deleteMembership,
  fetchMembershipDetails: membershipsOperations.fetchMembershipDetails,
  updateMembership: membershipsOperations.updateMembership,
  resendConfirmationEmail: membershipsOperations.resendConfirmationEmail,
  syncSlackAccount: membershipsOperations.syncSlackAccount
};

const _WorkspaceSettingsMembershipDetails = reduxForm({
  form: "workspaceSettingsMembershipDetails"
})(WorkspaceSettingsMembershipDetails);

export default connect(mapStateToProps, mapDispatchToProps)(_WorkspaceSettingsMembershipDetails);
