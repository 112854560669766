// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { workspacesOperations } from "state/ducks/workspaces";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import moment from "moment";
import StripeCheckout from "react-stripe-checkout";
import * as constants from "config/constants";

// Components
import { Link } from "react-router-dom";
import StripeImg from "./_assets/powered_by_stripe.svg";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  form {
    max-width: 50rem;
  }

  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

const BillingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  h3 {
    margin-bottom: ${spacing.x2};
  }
`;

const PlanDetails = styled.div`
  flex: 3;

  margin-right: ${spacing.x3};

  font-size: 1.6rem;
  .light {
    font-size: 1.4rem;
  }

  .warning {
    background-color: ${colors.warningBg};
    font-size: 1.6rem;
    border-radius: 8px;
    padding: ${spacing.x2};
    margin-bottom: ${spacing.x2};
    color: ${colors.black};
  }
`;

const PlanContent = styled.div`
  section {
    margin: 0 ${spacing.x2} ${spacing.x2} ${spacing.x2};
    display: flex;
    align-items: flex-start;
  }
  label {
    display: inline-block;
    width: 250px;
    font-weight: 600;
  }
`;

const CardDetails = styled.div`
  flex: 2;

  .details {
    margin: ${spacing.x2} 0;
  }
`;

const PoweredStripe = styled.div`
  margin-top: ${spacing.x2};
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  handleSubmit: Function,
  name: string,
  ui: Object,
  cancelAndFetchSubscription: Function,
  deleteCardAndFetchWorkspace: Function,
  fetchSubscriptionDetails: Function,
  updateAndFetchCustomerDetails: Function,
  updateCardAndFetchSubscription: Function
};

class WorkspaceSettingsBilling extends Component<Props> {
  componentDidMount() {
    const { currentWorkspace, fetchSubscriptionDetails } = this.props;

    if (currentWorkspace.stripe_cache_subscription_plan || currentWorkspace.stripe_has_card) {
      fetchSubscriptionDetails();
    }
  }
  onToken = (tokenData: Object) => {
    const params = {
      token_data: tokenData
    };
    this.props.updateCardAndFetchSubscription(params);
  };
  handleCancel = e => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to cancel your subscription?")) {
      this.props.cancelAndFetchSubscription();
    }
  };
  handleDeleteCard = e => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to remove your payment details?")) {
      this.props.deleteCardAndFetchWorkspace();
    }
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentWorkspace, ui } = this.props;

    const subscription = currentWorkspace.stripe_subscription;
    const plan = subscription ? subscription.plan : null;
    const planName = plan ? plan.nickname : "Free";
    const planID = plan ? plan.id : "";
    const quantity = subscription ? subscription.quantity : 0;
    const cost = plan ? (plan.amount * quantity) / 100 : 0;

    const isRequesting = ui.isFetchingStripeDetails || ui.isUpdatingCardDetails || ui.isCancelingSubscription;

    let pageQuotaExceeded = false;
    //let usePackagedPlans = false;     // Option to see if we need to use a packagedPlans approach
    let usePerUserPlans = false;
    let pagesCountClassname = "";
    // Check if we have exceeded the quota of pages.
    // This is only applied to plans that have the new billing packages
    if (constants.BILLING_PACKAGE_PLANS.includes(currentWorkspace.stripe_cache_subscription_plan)) {
      //usePackagedPlans = true;
      usePerUserPlans = false;
      if (
        currentWorkspace.active_projects_limit !== 0 &&
        currentWorkspace.active_projects_count >= currentWorkspace.active_projects_limit
      ) {
        pageQuotaExceeded = true;
        pagesCountClassname = "danger";
      }
    }

    if (constants.BILLING_PER_USER_PLANS.includes(currentWorkspace.stripe_cache_subscription_plan)) {
      usePerUserPlans = true;
    }

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>Plan & Billing</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <BillingContainer>
              <PlanDetails>
                <h3>Plan details</h3>
                {pageQuotaExceeded && (
                  <div className="warning">
                    You have reached your quota of active pages. You can&nbsp;
                    <Link to="/settings/plan">upgrade your plan</Link> to increase your quota.
                  </div>
                )}

                {isRequesting && <Loader size="small" />}
                {!isRequesting && (
                  <PlanContent>
                    <section>
                      <label>Plan</label>
                      {planID && <span>{planName}</span>}
                      {!planID && (
                        <span>
                          {planName} - {!planID && <Link to="/settings/plan">Upgrade</Link>}
                        </span>
                      )}
                    </section>
                    {usePerUserPlans && (
                      <section>
                        <label>Users</label>
                        {currentWorkspace.memberships_limit !== 0 && (
                          <span>
                            {quantity} / {currentWorkspace.memberships_limit}
                          </span>
                        )}
                        {currentWorkspace.memberships_limit === 0 && <span>{quantity}</span>}
                      </section>
                    )}
                    <section>
                      <label>Active pages</label>
                      {currentWorkspace.active_projects_limit !== 0 && (
                        <span>
                          <b className={pagesCountClassname}>{currentWorkspace.active_projects_count}</b> /{" "}
                          {currentWorkspace.active_projects_limit}
                        </span>
                      )}
                      {currentWorkspace.active_projects_limit === 0 && (
                        <span>
                          <b>{currentWorkspace.active_projects_count}</b> / Unlimited
                        </span>
                      )}
                    </section>
                    <section>
                      <label>
                        Cost
                        <br />
                        <small className="light">Billing period</small>
                      </label>
                      <span>
                        ${cost.toLocaleString()}
                        <br />
                        {subscription && (
                          <small className="light">
                            {`${moment.unix(subscription.current_period_start).format("MMMM Do")}
                            - 
                            ${moment.unix(subscription.current_period_end).format("MMMM Do YYYY")}`}
                          </small>
                        )}
                      </span>
                    </section>
                  </PlanContent>
                )}
              </PlanDetails>
              <CardDetails>
                <h3>Billing details {ui.isFetchingStripeDetails}</h3>
                {isRequesting && <Loader size="small" />}
                {!isRequesting && !planID && (
                  <Link to="/settings/plan" className="button primary" style={{ maxWidth: "20rem" }}>
                    Upgrade your plan
                  </Link>
                )}
                {!isRequesting && (
                  <div>
                    {!currentWorkspace.stripe_card && planID && <p>You don't have a credit card.</p>}

                    {currentWorkspace.stripe_card && currentWorkspace.stripe_customer && (
                      <div className="details">
                        <p>
                          <b>{currentWorkspace.stripe_card.name}</b>
                          <br />
                          {currentWorkspace.stripe_customer.email}
                          <br />
                          {currentWorkspace.stripe_card.address_line1}
                          <br />
                          {currentWorkspace.stripe_card.address_zip}, {currentWorkspace.stripe_card.address_city}
                          <br />
                          {currentWorkspace.stripe_card.address_country}
                          <br />
                        </p>
                        <p>
                          <b>Credit card details</b>
                          <br />
                          Card ending with: ****{currentWorkspace.stripe_card.last4}
                          <br />
                          Card expiring: {currentWorkspace.stripe_card.exp_month}/
                          {currentWorkspace.stripe_card.exp_year}
                          <br />
                          {currentWorkspace.stripe_card.address_country}
                          <br />
                        </p>
                      </div>
                    )}
                    {planID && (
                      <StripeCheckout
                        name="Billing information"
                        allowRememberMe={false}
                        token={this.onToken}
                        stripeKey={process.env.REACT_APP_STRIPE_API_KEY}
                        billingAddress={true}
                        panelLabel="Update payment details"
                      >
                        {currentWorkspace.stripe_card && <button>Update payment details</button>}
                        {!currentWorkspace.stripe_card && <button>Add payment details</button>}
                      </StripeCheckout>
                    )}
                    <PoweredStripe>
                      <img src={StripeImg} alt="Powered by Stripe" />
                    </PoweredStripe>
                    {planID && (
                      <p>
                        <Link to="/settings/plan">Change plan</Link>
                      </p>
                    )}
                    {subscription && (
                      <p>
                        <button className="link-button" onClick={this.handleCancel}>
                          Cancel subscription
                        </button>
                      </p>
                    )}
                    {currentWorkspace.stripe_card && (
                      <p>
                        <button className="link-button" onClick={this.handleDeleteCard}>
                          Remove payment details
                        </button>
                      </p>
                    )}
                  </div>
                )}
              </CardDetails>
            </BillingContainer>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  ui: state.ui.workspaceSettingsBilling
});

const mapDispatchToProps = {
  fetchSubscriptionDetails: workspacesOperations.fetchSubscriptionDetails,
  updateCardAndFetchSubscription: workspacesOperations.updateCardAndFetchSubscription,
  cancelAndFetchSubscription: workspacesOperations.cancelAndFetchSubscription,
  deleteCardAndFetchWorkspace: workspacesOperations.deleteCardAndFetchWorkspace
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettingsBilling);
