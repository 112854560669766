// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import moment from "moment";
import numeral from "numeral";

import { handleChange } from "v2/utils/forms";
import * as statusUpdateTypes from "state/ducks/statusUpdates/types";
import { statusUpdatesOperations } from "state/ducks/statusUpdates";
import { goalsOperations } from "state/ducks/goals";
import { projectsOperations } from "state/ducks/projects";
import { sectionsOperations } from "state/ducks/sections";
import { request } from "state/utils";

// Components
import RadioField from "v2/components/RadioField";
import FormField from "v2/components/FormField";
import Loader from "v2/components/Loader";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";
import MentionBox from "v2/components/MentionBox";
import TaskList from "v2/components/TaskList";
import Datetime from "react-datetime";

import IconEdit from "v2/components/svg/IconEdit";
import IconAsanaPng from "v2/components/_assets/IconAsana.png";
import IconBitbucketPng from "v2/components/_assets/IconBitbucket.png";
import IconConfluencePng from "v2/components/_assets/IconConfluence.png";
import IconDropboxPng from "v2/components/_assets/IconDropbox.png";
import IconGithubPng from "v2/components/_assets/IconGithub.png";
import IconGoogleDrivePng from "v2/components/_assets/IconGoogleDrive.png";
import IconJiraPng from "v2/components/_assets/IconJira.png";
import IconLinkPng from "v2/components/_assets/IconLink.png";
import IconNotionPng from "v2/components/_assets/IconNotion.png";
import IconSlackPng from "v2/components/_assets/IconSlack.png";
import IconTrelloPng from "v2/components/_assets/IconTrello.png";
import IconTwitterPng from "v2/components/_assets/IconTwitter.png";

import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

const Container = styled.div`
  h4 {
    margin-bottom: ${spacing.x2};
  }

  .close-option {
    display: flex;
  }

  .projection {
    display: inline-block;
    margin-top: ${spacing.x1};
  }

  .modal-content {
    padding: 0;
  }
`;

const UpdateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;

  .status-update {
    padding: ${spacing.x2};
    flex: 3;
    margin-right: ${spacing.x2};
    border-right: 1px solid ${colors.blockBorder};
    height: 100%;
  }
  .tasks {
    padding: ${spacing.x2};
    flex: 3;
  }

  @media ${devices.laptop} {
    flex-direction: column;

    .status-update {
      margin-right: 0;
      border-right: 0;
      margin-bottom: ${spacing.x2};
      height: auto;

      border-bottom: 1px solid ${colors.blockBorder};
    }
  }
`;

const ScoreField = styled.div`
  display: flex;
  align-items: center;
  input.tiny {
    min-width: 10rem;
    width: auto;
    margin-right: 0;
  }
  .prefix,
  .suffix {
    color: ${colors.subtleText};
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    outline: none;
    transition: border 0.5s ease;
    box-sizing: border-box;
    font-size: 1.4rem;
    line-height: 1;
    height: 4rem;
    padding: 0;
    margin: 0;
  }
  .prefix {
    padding-right: ${spacing.x1};
  }
  .suffix {
    padding-left: ${spacing.x1};
  }
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex: 1;

    &:nth-child(2) {
      justify-content: flex-end;
    }
  }
`;

const PrimaryLink = styled.div`
  margin: ${spacing.x2} 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;

  .icon {
    display: block;
    min-width: 2rem;
    min-height: 2rem;
    margin-left: ${spacing.x1};

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1.4rem;
    }
  }
`;

const Line = styled.div`
  display: flex;
  span {
    display: inline-block;
    margin-right: ${spacing.x1};
  }
`;

const PopperAnchor = styled.button`
  transition: all ease 0.2s;
  margin-left: 4px;
`;

const CumulInput = styled.div`
  display: flex;

  input.cumul {
    border-radius: 4px 0 0 4px;
  }

  .cumul-result {
    height: 3.8rem;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.inputBorder};
    border-left: 0;
    padding: 0 ${spacing.x2};
    color: ${colors.subtleText};
    font-size: 1.4rem;
    border-radius: 0 4px 4px 0;
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  goal: squadTypes.Goal,
  hideForm: Function,
  updateGoal: Function,
  createStatusUpdate: Function,
  updateStatusUpdate: Function,
  fetchGoalDetails: Function,
  fetchProjectDetails: Function,
  fetchSectionDetails: Function,
  statusUpdate?: squadTypes.StatusUpdate,
  handleSetContentNotSaved: Function,
  ui: Object
};

type State = {
  text: string,
  color: string,
  score: ?any,
  displayCloseButton: boolean,
  isCumul: boolean,
  cumulValue: number,
  placeholder: any,
  projectedScore: ?number,
  targetGrowth: ?number,
  update_date: Date,
  primaryLink: ?squadTypes.Link,
  showUpdateDateForm: boolean
};

class StatusUpdateNewForm extends Component<Props, State> {
  state = {
    isCumul: false,
    cumulValue: 0,
    text: "",
    color: "green",
    score: "",
    placeholder: "Value",
    update_date: moment().toISOString(),
    displayCloseButton: false,
    projectedScore: null,
    targetGrowth: null,
    primaryLink: null,
    showUpdateDateForm: false
  };

  componentDidMount() {
    const { goal, statusUpdate } = this.props;
    let { projectedScore, targetGrowth, update_date } = this.state;
    let state = { ...this.state };

    // display the close button if there's no target.
    if (goal.target === null) {
      state = {
        ...state,
        displayCloseButton: true
      };
    }

    // Set the growth target
    if (goal.target !== null && goal.initial_value !== null) {
      targetGrowth = goal.target - goal.initial_value;
    }

    let today = moment(update_date);

    const initialColor = goal.cached_color === "grey" ? "green" : goal.cached_color;
    state = {
      ...state,
      color: initialColor,
      score: "",
      placeholder: goal.cached_score ? goal.cached_score.toString() : "Value"
    };

    if (statusUpdate) {
      state = {
        ...state,
        text: statusUpdate.text,
        color: statusUpdate.color,
        score: statusUpdate.score,
        placeholder: statusUpdate.score,
        update_date: statusUpdate.update_date
      };
      today = moment(statusUpdate.update_date);

      // Calculate the growth from initial value
      const growth = statusUpdate.score - goal.initial_value;
      if (targetGrowth) {
        // Initialize target completion
        let targetCompletion = 0;
        targetCompletion = growth / targetGrowth;

        // display close goal button if above 80% completion
        if (targetCompletion >= 0.9) {
          state = {
            ...state,
            displayCloseButton: true
          };
        }
      }
    }

    // Set the projected score, only if we have a start and end dates
    if (goal.target !== null && goal.initial_value !== null && goal.start_date && goal.deadline && targetGrowth) {
      const start = moment(goal.start_date);
      const end = moment(goal.deadline);

      const daysTotal = end.diff(start, "days");
      const daysSinceStart = today.diff(start, "days");
      projectedScore = (targetGrowth * daysSinceStart) / daysTotal + goal.initial_value;

      if (moment().isBefore(start)) {
        projectedScore = goal.initial_value;
      }
      if (moment().isAfter(end)) {
        projectedScore = goal.target;
      }
    }

    // if our goal is to be above or below target we do a different calculation
    if (goal.goal_type === "stay_above" || goal.goal_type === "stay_below") {
      projectedScore = goal.target;
      targetGrowth = goal.target;
    }

    state = {
      ...state,
      projectedScore,
      targetGrowth
    };

    // Don't display the close button if goal is already closed.
    if (goal.state === "closed") {
      state = {
        ...state,
        displayCloseButton: false
      };
    }
    this.setState(state);
    this._fetchPrimaryLink();
  }

  _fetchPrimaryLink = () => {
    const { goal } = this.props;
    if (!goal.primary_link_id) {
      return false;
    }
    const linksApiURL = `/links/${goal.primary_link_id}`;

    request.get(linksApiURL).then(response => {
      this.setState({
        primaryLink: response.data
      });
    });
    return true;
  };

  createOrUpdateStatusUpdate = (e: Object) => {
    const { close } = e.currentTarget.dataset;

    const { statusUpdate } = this.props;
    if (statusUpdate) {
      this.updateStatusUpdate(e, close);
    } else {
      this.createStatusUpdate(e, close);
    }
  };

  createStatusUpdate = (e: Object, close: ?boolean) => {
    e.preventDefault();
    const { handleSetContentNotSaved } = this.props;
    const { goal } = this.props;
    const { text, isCumul, cumulValue, color, score, update_date } = this.state;

    const params = {
      text,
      color,
      score: isCumul ? cumulValue : score,
      update_date
    };
    this.props.createStatusUpdate(goal.id, params).then(action => {
      if (action.type === statusUpdateTypes.CREATE_COMPLETED) {
        if (close) {
          this.props.updateGoal(goal.id, { state: "closed" }).then(() => {
            this.props.fetchGoalDetails(goal.id);
            this.props.fetchProjectDetails(goal.project_id);
            this.props.fetchSectionDetails(goal.section_id);
          });
        } else {
          this.props.fetchGoalDetails(goal.id);
          this.props.fetchProjectDetails(goal.project_id);
          this.props.fetchSectionDetails(goal.section_id);
        }
        handleSetContentNotSaved(false, () => {
          this.props.hideForm(e);
        });
      } else {
        console.log("ERROR");
      }
    });
  };

  updateStatusUpdate = (e: Object, close: ?boolean) => {
    e.preventDefault();
    const { handleSetContentNotSaved } = this.props;
    const { goal, statusUpdate } = this.props;
    const { text, color, score, update_date } = this.state;
    if (!statusUpdate) {
      return false;
    }

    const params = {
      text,
      color,
      score,
      update_date
    };
    this.props.updateStatusUpdate(statusUpdate.id, params).then(action => {
      if (action.type === statusUpdateTypes.UPDATE_COMPLETED) {
        // Only fetchGoal again if we updated the last status of the goal

        if (close) {
          this.props.updateGoal(goal.id, { state: "closed" }).then(() => {
            this.props.fetchGoalDetails(goal.id);
            this.props.fetchProjectDetails(goal.project_id);
            this.props.fetchSectionDetails(goal.section_id);
          });
        } else {
          if (statusUpdate.id === goal.last_status_update_id) {
            this.props.fetchGoalDetails(goal.id);
            this.props.fetchProjectDetails(goal.project_id);
            this.props.fetchSectionDetails(goal.section_id);
          }
        }

        handleSetContentNotSaved(false, () => {
          this.props.hideForm(e);
        });
      } else {
        console.log("ERROR");
      }
    });
  };

  handleChange = e => {
    const { handleSetContentNotSaved } = this.props;
    handleSetContentNotSaved(true);
    handleChange(this, e);
  };

  handleScoreChange = e => {
    e.preventDefault();
    const { handleSetContentNotSaved } = this.props;
    handleSetContentNotSaved(true);
    const { goal } = this.props;
    const { projectedScore, targetGrowth } = this.state;
    let value = e.target.value;
    value = value.replace(/[^-0-9.+]+/g, "");

    let suggestedColor = null;
    let diffToProjection = 0;

    let state: { score: string, color?: string, displayCloseButton?: boolean } = {
      score: value,
      displayCloseButton: false
    };

    let cumulValue = 0;

    // Detect if it's a cumulative update. If that's the case we set the state to true.
    // We can only do the cumulative update on new status
    if (value && value[0] === "+" && !this.props.statusUpdate) {
      let valueToAdd = parseFloat(value.replace("+", ""));
      valueToAdd = isNaN(valueToAdd) ? 0 : valueToAdd;
      cumulValue = goal.cached_score + valueToAdd;
      state = {
        ...state,
        isCumul: true,
        cumulValue
      };
    } else {
      state = {
        ...state,
        isCumul: false,
        cumulValue: 0
      };
    }

    const valueForGrowth = state.isCumul ? cumulValue : value;

    // Calculate the growth from initial value
    const growth = valueForGrowth - goal.initial_value;

    // Initialize target completion
    let targetCompletion = 0;

    // Check what % of target we accomplished
    if (targetGrowth) {
      targetCompletion = growth / targetGrowth;

      // display close goal button if above 80% completion
      if (targetCompletion >= 0.9 && goal.state === "open") {
        state = {
          ...state,
          displayCloseButton: true
        };
      }
    }

    if (projectedScore || projectedScore === 0) {
      if (valueForGrowth) {
        const projectedGrowth = projectedScore - goal.initial_value || 1;

        diffToProjection = growth / projectedGrowth;

        // if our goal is to be above or below target we do a different calculation
        if (goal.goal_type === "stay_above") {
          diffToProjection = 1 + (valueForGrowth - goal.target) / Math.abs(goal.target);
        }
        if (goal.goal_type === "stay_below") {
          diffToProjection = 1 + (goal.target - valueForGrowth) / Math.abs(goal.target);
        }

        if (diffToProjection >= 0.8) {
          suggestedColor = "green";
        }

        if (diffToProjection < 0.8) {
          suggestedColor = "yellow";
        }

        if (diffToProjection < 0.5) {
          suggestedColor = "red";
        }

        if (suggestedColor) {
          state = {
            ...state,
            color: suggestedColor
          };
        }
      }
    }

    this.setState(state);
  };

  _handleMentionChange = (value: string) => {
    const { handleSetContentNotSaved } = this.props;
    handleSetContentNotSaved(true);
    this.setState({
      text: value
    });
  };

  _handleUpdateDateChange = momentValue => {
    const { handleSetContentNotSaved } = this.props;
    handleSetContentNotSaved(true);
    let isoDate;
    try {
      isoDate = momentValue.toISOString();
    } catch (e) {
      isoDate = momentValue;
    }

    // Set the projected score, only if we have a start and end dates
    const { goal } = this.props;
    let { projectedScore, targetGrowth } = this.state;
    if (goal.target !== null && goal.initial_value !== null && goal.start_date && goal.deadline && targetGrowth) {
      const start = moment(goal.start_date);
      const end = moment(goal.deadline);
      let today = momentValue;

      const daysTotal = end.diff(start, "days");
      const daysSinceStart = today.diff(start, "days");
      projectedScore = (targetGrowth * daysSinceStart) / daysTotal + goal.initial_value;

      if (today.isBefore(start)) {
        projectedScore = goal.initial_value;
      }
    }
    this.setState({
      update_date: isoDate,
      projectedScore
    });
  };

  _getLinkIcon = (link: squadTypes.Link) => {
    const url = link.url;
    let icon = IconLinkPng;

    switch (true) {
      case /asana.com/.test(url):
        icon = IconAsanaPng;
        break;
      case /bitbucket.org/.test(url):
        icon = IconBitbucketPng;
        break;
      case /atlassian.net\/wiki/.test(url):
        icon = IconConfluencePng;
        break;
      case /dropbox.com/.test(url):
        icon = IconDropboxPng;
        break;
      case /github.com/.test(url):
        icon = IconGithubPng;
        break;
      case /docs.google.com/.test(url):
        icon = IconGoogleDrivePng;
        break;
      case /atlassian.net/.test(url):
        icon = IconJiraPng;
        break;
      case /notion.so/.test(url):
        icon = IconNotionPng;
        break;
      case /slack.com/.test(url):
        icon = IconSlackPng;
        break;
      case /trello.com/.test(url):
        icon = IconTrelloPng;
        break;
      case /twitter.com/.test(url):
        icon = IconTwitterPng;
        break;
      default:
        break;
    }

    return icon;
  };

  _showUpdateDateForm = e => {
    e.preventDefault();
    this.setState({
      showUpdateDateForm: true
    });
  };

  today = moment();
  _isValidDate = current => {
    return current.isBefore(this.today);
  };

  render() {
    const { currentWorkspace, goal, hideForm, statusUpdate, ui } = this.props;
    const {
      isCumul,
      cumulValue,
      text,
      placeholder,
      score,
      color,
      projectedScore,
      displayCloseButton,
      primaryLink,
      update_date
    } = this.state;

    const updateDateMoment = moment(update_date);
    const cumulClass = isCumul ? "cumul" : "";
    let format = [];
    let projectedScoreWithFormat = "";
    let placeholderText = "";
    placeholderText = `Write a short comment about what happened last week.`;

    if (goal.target !== null) {
      format = goal.score_format.split("_target_");
      if (projectedScore !== null) {
        //$FlowFixMe
        projectedScoreWithFormat = goal.score_format.replace("_target_", numeral(projectedScore).format("0[.]00a"));
      }
    }

    if (currentWorkspace.is_read_only) {
      return (
        <Container className="modal-wrapper-no-footer">
          <div className="modal-header">
            <h3>Updates are restricted</h3>
            <ButtonIcon onClick={this.props.hideForm}>
              <IconClose />
            </ButtonIcon>
          </div>
          <div className="modal-content">
            <UpdateWrapper>
              <p>
                Hi, you have exceeded the limit of{" "}
                <b>{currentWorkspace.monthly_status_updates_limit} status updates/month</b> for your workspace.
              </p>
              <p>
                You have made <b>{currentWorkspace.current_month_status_updates_count} status updates</b> this month.
              </p>
              <p>
                You can <a href={`${currentWorkspace.url}/settings/billing`}>upgrade your subscription</a> to remove
                that restriction.
              </p>
            </UpdateWrapper>
          </div>
        </Container>
      );
    }
    return (
      <Container className="modal-wrapper">
        <div className="modal-header">
          <h2>Progress update</h2>
          <ButtonIcon onClick={this.props.hideForm}>
            <IconClose />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <UpdateWrapper>
            <div className="status-update">
              <h4>{goal.title}</h4>
              {primaryLink && (
                <PrimaryLink>
                  <b>Reference link:</b>
                  <a className="icon" href={primaryLink.url} target="_blank" rel="noopener noreferrer">
                    <img src={this._getLinkIcon(primaryLink)} alt="" />
                  </a>
                  <a href={primaryLink.url} target="_blank" rel="noopener noreferrer">
                    {primaryLink.integration_title || primaryLink.title}
                  </a>
                </PrimaryLink>
              )}
              <form onSubmit={this.createOrUpdateStatusUpdate} autoComplete="off">
                {!this.state.showUpdateDateForm && (
                  <FormField>
                    <label className="label">Date</label>
                    <Line>
                      <span>{updateDateMoment.format("D MMM YYYY")}</span>
                      <PopperAnchor className="link-button" onClick={this._showUpdateDateForm}>
                        <IconEdit />
                      </PopperAnchor>
                    </Line>
                  </FormField>
                )}
                {this.state.showUpdateDateForm && (
                  <FormField>
                    <label className="label">Date</label>
                    <Datetime
                      dateFormat="D MMM YYYY"
                      name="update_date"
                      value={updateDateMoment}
                      onChange={this._handleUpdateDateChange}
                      inputProps={{ placeholder: "Date", className: "tiny", required: true }}
                      timeFormat={false}
                      closeOnSelect={true}
                      isValidDate={this._isValidDate}
                    />
                  </FormField>
                )}
                {goal.target !== null && (
                  <FormField>
                    <label className="label">Progress</label>
                    <ScoreField>
                      {format[0] && (
                        <div className="prefix">
                          <span>{format[0]}</span>
                        </div>
                      )}
                      <CumulInput>
                        <input
                          type="text"
                          className={`tiny ${cumulClass}`}
                          placeholder={placeholder}
                          onChange={this.handleScoreChange}
                          name="score"
                          value={score}
                          required={true}
                        />
                        {isCumul === true && <div className="cumul-result">= {cumulValue}</div>}
                      </CumulInput>
                      {format[1] && (
                        <div className="suffix">
                          <span>{format[1]}</span>
                        </div>
                      )}
                    </ScoreField>
                    <small className="projection subtle">
                      {!statusUpdate && <span>Type '+' to add to the previous value</span>}
                      {!statusUpdate && projectedScoreWithFormat && <span> – </span>}
                      {projectedScoreWithFormat && <span>Projection: {projectedScoreWithFormat}</span>}
                    </small>
                  </FormField>
                )}
                <RadioField style={{ marginBottom: spacing.x3 }}>
                  <label className="label">Status</label>
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="green"
                      checked={color === "green"}
                      name="color"
                      value={"green"}
                      onChange={this.handleChange}
                    />
                    <label htmlFor="green" className="green">
                      On track
                    </label>
                    <input
                      type="radio"
                      id="yellow"
                      checked={color === "yellow"}
                      name="color"
                      value={"yellow"}
                      onChange={this.handleChange}
                    />
                    <label htmlFor="yellow" className="yellow">
                      At risk
                    </label>
                    <input
                      type="radio"
                      id="red"
                      checked={color === "red"}
                      name="color"
                      value={"red"}
                      onChange={this.handleChange}
                    />
                    <label htmlFor="red" className="red">
                      Off track
                    </label>
                  </div>
                </RadioField>
                <FormField>
                  <label className="label">Comment</label>
                  <MentionBox setValue={this._handleMentionChange} value={text} placeholder={placeholderText} />
                </FormField>
              </form>
            </div>
            <div className="tasks">
              <h4>Tasks</h4>
              <TaskList goal={goal} hideTitle={true} displayTip={true} />
            </div>
          </UpdateWrapper>
        </div>
        <div className="modal-footer">
          <ActionsContainer>
            <div>
              {ui.isFetching && (
                <button disabled={true}>
                  <Loader size="small" />
                </button>
              )}
              {!statusUpdate && !ui.isFetching && (
                <button type="submit" className="primary" onClick={this.createOrUpdateStatusUpdate}>
                  Create
                </button>
              )}
              {statusUpdate && !ui.isFetching && (
                <button type="submit" className="primary" onClick={this.createOrUpdateStatusUpdate}>
                  Update
                </button>
              )}
              <button onClick={hideForm} type="submit" className="secondary" disabled={ui.isFetching}>
                Cancel
              </button>
            </div>
            <div>
              {displayCloseButton && (
                <button
                  onClick={this.createOrUpdateStatusUpdate}
                  className="auto-size"
                  data-close="true"
                  disabled={ui.isFetching}
                >
                  Close goal after update
                </button>
              )}
            </div>
          </ActionsContainer>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  ui: state.ui.statusUpdateForm
});

const mapDispatchToProps = {
  createStatusUpdate: statusUpdatesOperations.createStatusUpdate,
  updateStatusUpdate: statusUpdatesOperations.updateStatusUpdate,
  updateGoal: goalsOperations.updateGoal,
  fetchGoalDetails: goalsOperations.fetchGoalDetails,
  fetchProjectDetails: projectsOperations.fetchProjectDetails,
  fetchSectionDetails: sectionsOperations.fetchSectionDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusUpdateNewForm);
