//@flow
export const CHECK_CREDENTIALS_REQUESTED = "session/CHECK_CREDENTIALS_REQUESTED";
export const CHECK_CREDENTIALS_COMPLETED = "session/CHECK_CREDENTIALS_COMPLETED";
export const CHECK_CREDENTIALS_FAILED = "session/CHECK_CREDENTIALS_FAILED";

export const LOGOUT = "session/LOGOUT";
export const LOG_VISIT = "session/LOG_VISIT";
export const WHATSNEW_VISITED = "session/WHATSNEW_VISITED";
export const REDIRECT_AFTER_LOGIN = "session/REDIRECT_AFTER_LOGIN";
export const SET_REDIRECT_AFTER_PATH = "session/SET_REDIRECT_AFTER_PATH";
export const TOGGLE_FLAG = "session/TOGGLE_FLAG";

export const SET_SAML_TOKENS = "session/SET_SAML_TOKENS";

export const INITIALIZE_STARTED = "session/INITIALIZE_STARTED";
export const INITIALIZE_COMPLETED = "session/INITIALIZE_COMPLETED";

export const CHECK_CURRENT_WORKSPACE_REQUESTED = "session/CHECK_CURRENT_WORKSPACE_REQUESTED";
export const CHECK_CURRENT_WORKSPACE_COMPLETED = "session/CHECK_CURRENT_WORKSPACE_COMPLETED";
export const CHECK_CURRENT_WORKSPACE_FAILED = "session/CHECK_CURRENT_WORKSPACE_FAILED";

export const FETCH_CURRENT_WORKSPACE_REQUESTED = "session/FETCH_CURRENT_WORKSPACE_REQUESTED";
export const FETCH_CURRENT_WORKSPACE_COMPLETED = "session/FETCH_CURRENT_WORKSPACE_COMPLETED";
export const FETCH_CURRENT_WORKSPACE_FAILED = "session/FETCH_CURRENT_WORKSPACE_FAILED";

export const MAGIC_LOGIN_REQUESTED = "session/MAGIC_LOGIN_REQUESTED";
export const MAGIC_LOGIN_COMPLETED = "session/MAGIC_LOGIN_COMPLETED";
export const MAGIC_LOGIN_FAILED = "session/MAGIC_LOGIN_FAILED";

export const LOGIN_REQUESTED = "session/LOGIN_REQUESTED";
export const LOGIN_COMPLETED = "session/LOGIN_COMPLETED";
export const LOGIN_FAILED = "session/LOGIN_FAILED";

export const SLACK_AUTH_REQUESTED = "session/SLACK_AUTH_REQUESTED";
export const SLACK_AUTH_COMPLETED = "session/SLACK_AUTH_COMPLETED";
export const SLACK_AUTH_FAILED = "session/SLACK_AUTH_FAILED";

export const GOOGLE_AUTH_REQUESTED = "session/GOOGLE_AUTH_REQUESTED";
export const GOOGLE_AUTH_COMPLETED = "session/GOOGLE_AUTH_COMPLETED";
export const GOOGLE_AUTH_FAILED = "session/GOOGLE_AUTH_FAILED";

export const SIGNUP_REQUESTED = "session/SIGNUP_REQUESTED";
export const SIGNUP_COMPLETED = "session/SIGNUP_COMPLETED";
export const SIGNUP_FAILED = "session/SIGNUP_FAILED";

export const REFRESH_TOKENS_REQUESTED = "session/REFRESH_TOKENS_REQUESTED";
export const REFRESH_TOKENS_COMPLETED = "session/REFRESH_TOKENS_COMPLETED";
export const REFRESH_TOKENS_FAILED = "session/REFRESH_TOKENS_FAILED";

export const RESEND_CONFIRMATION_REQUESTED = "session/RESEND_CONFIRMATION_REQUESTED";
export const RESEND_CONFIRMATION_COMPLETED = "session/RESEND_CONFIRMATION_COMPLETED";
export const RESEND_CONFIRMATION_FAILED = "session/RESEND_CONFIRMATION_FAILED";

export const EMAIL_CONFIRMATION_REQUESTED = "session/EMAIL_CONFIRMATION_REQUESTED";
export const EMAIL_CONFIRMATION_COMPLETED = "session/EMAIL_CONFIRMATION_COMPLETED";
export const EMAIL_CONFIRMATION_FAILED = "session/EMAIL_CONFIRMATION_FAILED";

export const PASSWORD_RESET_REQUESTED = "session/PASSWORD_RESET_REQUESTED";
export const PASSWORD_RESET_COMPLETED = "session/PASSWORD_RESET_COMPLETED";
export const PASSWORD_RESET_FAILED = "session/PASSWORD_RESET_FAILED";

export const PASSWORD_SAVE_NEW_REQUESTED = "session/PASSWORD_SAVE_NEW_REQUESTED";
export const PASSWORD_SAVE_NEW_COMPLETED = "session/PASSWORD_SAVE_NEW_COMPLETED";
export const PASSWORD_SAVE_NEW_FAILED = "session/PASSWORD_SAVE_NEW_FAILED";

export const UNSUBSCRIBE_REQUESTED = "session/UNSUBSCRIBE_REQUESTED";
export const UNSUBSCRIBE_COMPLETED = "session/UNSUBSCRIBE_COMPLETED";
export const UNSUBSCRIBE_FAILED = "session/UNSUBSCRIBE_FAILED";
