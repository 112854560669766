// @flow
import { createReducer } from "state/utils";
import * as linkTypes from "state/ducks/links/types";

const INITIAL_STATE = {
  isFetchingLinks: true
};

export default createReducer(INITIAL_STATE)({
  [linkTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingLinks: false
  }),
  [linkTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingLinks: false
  }),
  [linkTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingLinks: true
  })
});
