//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1.3rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 14 14" {...props}>
    <g
      id="UI-Ready-to-Ship"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Dependencies-view---icons/unopened-Copy"
        transform="translate(-315.000000, -440.000000)"
        stroke="#A9A9A9"
        strokeWidth="2"
      >
        <g id="Group-44" transform="translate(280.000000, 271.000000)">
          <g id="Group-43" transform="translate(8.000000, 76.000000)">
            <g id="Group-27-Copy" transform="translate(28.000000, 88.000000)">
              <g id="corner-downRight-copy-7" transform="translate(0.000000, 6.000000)">
                <polyline id="Shape" points="8.25 4.5 12 8.25 8.25 12" />
                <path d="M0,0 L0,5.25 C0,6.90685425 1.34314575,8.25 3,8.25 L12,8.25" id="Shape" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
