import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1.6rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 24 18" {...props}>
    <title id="Subscribed-title">Subscribed</title>
    <g
      id="Subscribed-Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Subscribed-eye" transform="translate(1.000000, 1.000000)" stroke="#6A6A6A" strokeWidth="2">
        <path d="M0,8 C0,8 4,0 11,0 C18,0 22,8 22,8 C22,8 18,16 11,16 C4,16 0,8 0,8 Z" id="Subscribed-Shape" />
        <circle id="Subscribed-Oval" cx="11" cy="8" r="3" />
      </g>
    </g>
  </Logo>
);
