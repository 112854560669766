// @flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import { Link, NavLink } from "react-router-dom";

import styled from "styled-components";

import { spacing } from "v2/components/styles/spacing";

const Container = styled.div`
  padding: ${spacing.x4};
  background: #3b7377;
  height: 100%;
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  color: #fff;
  a {
    color: #fff;
    font-weight: 600;
    font-size: 1.4rem;
  }
  .title {
    font-size: 1.8rem;
    font-weight: 600;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: #fff;
    transition: all ease 0.2s;

    &.active,
    &:hover {
      color: #fff;
      border-left: 4px solid #f4cdc9;
      padding-left: ${spacing.x2};
      font-weight: 600;
    }

    display: inline-block;
    margin: ${spacing.x1} 0;
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: #5c9d8a;
  }
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentWorkspace: squadTypes.Workspace
};

class WorkspaceSettingsNav extends Component<Props> {
  render() {
    const { currentMembership, currentWorkspace } = this.props;
    return (
      <Container>
        <Header>
          <span>
            <Link to={`/settings`}>{currentWorkspace && <Fragment>{currentWorkspace.name}</Fragment>}</Link>
          </span>
          <div className="title">Workspace settings</div>
        </Header>
        <Main>
          <NavLink to="/settings" exact={true}>
            Workspace details
          </NavLink>
          <NavLink to="/settings/users">Users</NavLink>
          <NavLink to="/settings/notifications">Reminders & notifications</NavLink>
          <NavLink to="/settings/integrations">Integrations</NavLink>
          <NavLink to="/settings/security">Security</NavLink>
          <NavLink to="/settings/sso">SSO</NavLink>
          {currentMembership.role === "owner" && (
            <Fragment>
              <NavLink to="/settings/billing">Plan & billing</NavLink>
              <hr />
              <NavLink to="/settings/delete">Delete workspace</NavLink>
            </Fragment>
          )}
        </Main>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentMembership: state.session.currentMembership,
  currentWorkspace: state.session.currentWorkspace
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettingsNav);
