//@flow
import React from "react";
import styled from "styled-components";

import { colors } from "v2/components/styles/colors";

const Logo = styled.svg`
  width: 1.2rem;
  &.grey {
    g {
      stroke: #6a6a6a;
    }
  }

  &.transparent {
    g {
      stroke: transparent;
    }
  }

  &.black {
    g {
      stroke: ${colors.black};
    }
  }

  &.green {
    g {
      stroke: ${colors.green};
    }
  }

  &.yellow {
    g {
      stroke: ${colors.yellow};
    }
  }

  &.red {
    g {
      stroke: ${colors.red};
    }
  }
`;
export default (props: Object) => (
  <Logo viewBox="0 0 8 8" {...props}>
    <g
      id="UI-Ready-to-Ship"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Tree-view---expand-view"
        transform="translate(-494.000000, -299.000000)"
        stroke={props.stroke || "#FFFFFF"}
        strokeWidth="2"
      >
        <g id="check" transform="translate(495.000000, 300.000000)">
          <polyline id="Shape-Copy-6" points="6 0 1.875 6 0 3.27272727" />
        </g>
      </g>
    </g>
  </Logo>
);
