//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";

import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import { push } from "react-router-redux";
import * as squadTypes from "squadTypes";

import styled from "styled-components";
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

import AppModal from "v2/components/AppModal";
import WorkspaceNav from "./_WorkspaceNav";
import MainNav from "./_MainNav";

import Wordmark from "v2/components/_assets/tability_logo_final.png";

const whatsNewReference = "2020-04-09T03:20:02.520Z";

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: stretch;
`;

const MobileHeader = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  padding: ${spacing.x1};
  background-color: #0a5055;
  color: #fff;
  align-items: center;
  justify-content: space-between;

  img {
    height: 2.5rem;
  }

  button {
    padding: 0 ${spacing.x1};
    height: 3rem;
    line-height: 3rem;
    color: ${colors.black};
    border: 0;
    font-size: 1.6rem;
  }

  @media ${devices.tablet} {
    display: flex;
  }
`;

const MobileNavMask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 199;
  background: rgba(9, 30, 66, 0.54);
  animation: 0.2s ease-out 0s 1 normal none running eMLfYp;
`;

const Nav = styled.nav`
  z-index: 200;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;

  @media ${devices.tablet} {
    transform: translateX(-200vw);
    transition: transform 0.2s ease-out 0s;

    &.mobile-nav-open {
      transform: translateX(0);
    }
  }
`;

const SecondaryNav = styled.div`
  width: 24rem;
  background-color: #fff;

  @media ${devices.tablet} {
    width: 24rem;
  }
`;

const Main = styled.main`
  padding-left: ${props => props.navWidth};
  background-color: #f8f8f8;
  flex: 1;
  height: 100%;
  min-height: 100%;

  @media ${devices.tablet} {
    padding-left: 0;
    padding-top: 5rem;
  }
`;

type Props = {
  children: any,
  currentMembership: squadTypes.Membership,
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  logout: Function,
  notifications: Object,
  push: Function,
  secondNav?: any,
  showModal: Function,
  ui: Object,
  visitWhatsNew: Function,
  whatsNewVisited: string
};

type State = {
  isNavOpen: boolean
};

class WorkspaceLayout extends Component<Props, State> {
  state = {
    isNavOpen: false
  };

  logout = (e: Object) => {
    e.preventDefault();
    this.props.logout();
  };

  showWhatsNew = e => {
    this.props.visitWhatsNew();
  };

  _openNav = () => {
    this.setState({
      isNavOpen: true
    });
  };

  _closeNav = () => {
    this.setState({
      isNavOpen: false
    });
  };

  _showQuickSearch = (e?: Object) => {
    if (e) {
      e.preventDefault();
    }

    this.props.showModal({
      contentType: "quicksearch",
      contentAction: "show"
    });
  };

  _getHelpMenuItems = () => {
    const items = [];
    const hasWhatsNew = this._hasWhatsNew();

    const hasWhatsNewClass = hasWhatsNew ? "notify-whatsnew" : "";
    items.push(
      <a
        href="https://blog.tability.io/tag/whatsnew/"
        rel="noopener noreferrer"
        target="_blank"
        onClick={this.showWhatsNew}
        className={`${hasWhatsNewClass}`}
      >
        What's new?
      </a>
    );
    items.push(
      <a href="https://trello.com/b/jFtwfQIG/tability-roadmap" rel="noopener noreferrer" target="_blank">
        Roadmap
      </a>
    );
    items.push(
      <a href="https://docs.tability.io" rel="noopener noreferrer" target="_blank">
        Documentation
      </a>
    );
    items.push(
      <a href="https://docs.tability.io/support" rel="noopener noreferrer" target="_blank">
        Support
      </a>
    );

    return items;
  };

  _hasWhatsNew = () => {
    const referenceTime = this.props.whatsNewVisited || this.props.currentUser.created_at;
    return moment(whatsNewReference)
      .utc()
      .isAfter(moment(referenceTime));
  };

  _getAccountMenuItems = () => {
    const items = [];
    items.push(<NavLink to="/account">Manage your account</NavLink>);
    items.push(
      <button className="link-button" onClick={this.logout}>
        Logout
      </button>
    );
    return items;
  };

  render() {
    const subdomain = window.location.host.split(".")[0];
    const { children, secondNav } = this.props;
    const { isNavOpen } = this.state;

    const navClassName = isNavOpen ? "mobile-nav-open" : "";
    const ariaExpanded = isNavOpen ? "true" : "false";

    // Defines the width of the nav and the padding required for main
    // Max nav width = 6rem for main + 24rem for second = 34rem
    const navWidth = secondNav ? "30rem" : "6rem";
    return (
      <Container>
        {isNavOpen && <MobileNavMask onClick={this._closeNav} />}
        <Nav className={`${navClassName}`} navWidth={navWidth}>
          {subdomain !== "app" && <WorkspaceNav />}
          {subdomain === "app" && <MainNav />}
          {secondNav && <SecondaryNav>{secondNav}</SecondaryNav>}
        </Nav>
        <Main navWidth={navWidth}>
          <MobileHeader>
            <button aria-expanded={ariaExpanded} aria-label="Mobile navigation button" onClick={this._openNav}>
              <span aria-label="Menu">&#x2630;</span>
            </button>
            <Link to="/">
              <img src={Wordmark} alt="Tability" />
            </Link>
          </MobileHeader>

          <AppModal />
          {children}
        </Main>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  currentUser: state.session.currentUser,
  currentMembership: state.session.currentMembership,
  notifications: state.notifications,
  ui: state.ui.workspaceNav,
  whatsNewVisited: state.session.whatsNewVisited
});

const mapDispatchToProps = {
  push,
  logout: sessionOperations.logout,
  visitWhatsNew: sessionOperations.visitWhatsNew,
  showModal: uiOperations.showModal
};

export default //withRouter(
connect(mapStateToProps, mapDispatchToProps)(WorkspaceLayout);
//);
