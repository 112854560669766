// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { workspacesOperations } from "state/ducks/workspaces";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import * as constants from "config/constants";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import FormFlag from "v2/components/FormFlag";
import { Link } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};

    &.normal {
      color: ${colors.black};
    }
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

const Info = styled.div`
  background-color: ${colors.infoBg};
  padding: ${spacing.x2};
  margin: ${spacing.x1} 0;
  width: 50%;
  border-radius: 4px;
`;

const FormSeparator = styled.div`
  margin: 0 0 ${spacing.x2} 0;
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentWorkspace: squadTypes.Workspace,
  auth_type: string,
  handleSubmit: Function,
  ui: Object,
  updateWorkspace: Function
};

class WorkspaceSettingsSecurity extends Component<Props> {
  updateWorkspace = workspaceValues => {
    const { currentWorkspace } = this.props;
    let { auth_type, idp_cert, idp_sso_target_url } = workspaceValues;

    // We avoid setting up auth type to restricted.sso directly. This will be done automatically the first time a user
    // logs in via SSO.
    if (currentWorkspace.auth_type === "shared") {
      auth_type = "shared";
    }
    const params = {
      auth_type,
      idp_cert,
      idp_sso_target_url
    };
    this.props.updateWorkspace(currentWorkspace.id, params);
  };

  // This function is used to decide if we need to show an error class for a
  // given field.
  getErrorClass = (field: string) => {
    const { errorFields } = this.props.ui;
    return errorFields[field] ? "error" : "";
  };

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { auth_type, currentMembership, currentWorkspace, handleSubmit, ui } = this.props;
    const authURL = process.env.REACT_APP_TABILITY_AUTH_URL || "";
    const saml_consumer_url = `https://${authURL}/saml/consume/${currentWorkspace.slug}`;
    const saml_metadata_url = `https://${authURL}/saml/metadata/${currentWorkspace.slug}`;
    const saml_init_url = `https://${authURL}/saml/init/${currentWorkspace.slug}`;

    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.formState === "requesting" ? <Loader size="small" /> : "Update";

    const hasAccessControlPlan = constants.BILLING_ACCESS_CONTROL_PLANS.includes(
      currentWorkspace.stripe_cache_subscription_plan
    );

    if (!hasAccessControlPlan) {
      return (
        <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
          <Container>
            <Header>
              <h1>SSO</h1>
            </Header>
            <Main>
              <h4>Single Sign-On is not available on your plan</h4>
              <Info>
                {currentMembership.role === "owner" && <Link to="/settings/billing">Upgrade to the Business plan</Link>}
                {currentMembership.role !== "owner" && <span>Upgrade to the Business plan</span>} to configure Single
                Sign-On.
              </Info>
              <p>Workspace that are subscribed to the Business plan can use SAML to implement Single Sign-On (SSO).</p>
              <p>
                You can learn more in{" "}
                <a href="https://docs.tability.io/sso" target="_blank" rel="noopener noreferrer">
                  our integration guides
                </a>{" "}
                for Okta, OneLogin and Microsoft Azure Directory.
              </p>
            </Main>
          </Container>
        </WorkspaceLayout>
      );
    }

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>SSO</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <p>
              Check our integration guides for Okta, OneLogin and Microsoft Azure Directory in our{" "}
              <a href="https://docs.tability.io/sso" target="_blank" rel="noopener noreferrer">
                documentation
              </a>
              .
            </p>
            <form onSubmit={handleSubmit(this.updateWorkspace)}>
              <FormField>
                <label>Select how users can create an account and sign into your workspace</label>
                <label className="normal">
                  <Field component="input" type="radio" name="auth_type" value="shared" />
                  &nbsp;
                  <b>Default:</b> Allow users to sign in via email, Google Apps and Slack.
                </label>
                <label className="normal">
                  <Field component="input" type="radio" name="auth_type" value="restricted.sso" />
                  &nbsp;
                  <b>SAML SSO:</b> Enable Single Sign On with SAML 2.0
                </label>
              </FormField>
              {auth_type === "restricted.sso" && (
                <div>
                  {currentWorkspace.auth_type === "restricted.sso" && <Info>SAML SSO is enabled for all users.</Info>}
                  {currentWorkspace.auth_type === "shared" && (
                    <h2>1. Add your SAML configuration to your Identity Provider</h2>
                  )}
                  {currentWorkspace.auth_type === "restricted.sso" && <h2>SAML configuration</h2>}
                  <FormField>
                    <label htmlFor="accepted_domains">SP Entity ID</label>
                    <span>{saml_metadata_url}</span>
                  </FormField>
                  <FormField>
                    <label htmlFor="accepted_domains">SP Consumer URL</label>
                    <span>{saml_consumer_url}</span>
                  </FormField>
                  {currentWorkspace.auth_type === "shared" && (
                    <div>
                      <h2>2. Add your Identity Provider Settings</h2>
                      <FormField>
                        <label htmlFor="accepted_domains">IDP SSO URL</label>
                        <Field
                          component="input"
                          type="url"
                          className={`small`}
                          placeholder={"SSO URL"}
                          name="idp_sso_target_url"
                        />
                        <span className="subtle">
                          <small>URL of your identity provider for the authorization requests</small>
                        </span>
                      </FormField>
                      <FormField>
                        <label htmlFor="accepted_domains">IDP certificate</label>
                        <Field
                          component="textarea"
                          className={`small`}
                          placeholder={"IDP certificate"}
                          name="idp_cert"
                        />
                        <span className="subtle">
                          <small>
                            Copy your certificate, including the "BEGIN CERTIFICATE" and "END CERTIFICATE" lines
                          </small>
                        </span>
                        <FormSeparator>
                          {!currentWorkspace.idp_sso_target_url && (
                            <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                              {submitText}
                            </button>
                          )}
                          {currentWorkspace.idp_sso_target_url && (
                            <button type="submit" disabled={ui.formState === "requesting"}>
                              {submitText}
                            </button>
                          )}
                        </FormSeparator>
                      </FormField>
                    </div>
                  )}
                  {currentWorkspace.auth_type === "restricted.sso" && (
                    <div>
                      <h2>Identity Provider Settings</h2>
                      <FormField>
                        <label htmlFor="accepted_domains">IDP SSO URL</label>
                        <Field
                          component="input"
                          type="url"
                          className={`small`}
                          placeholder={"SSO URL"}
                          name="idp_sso_target_url"
                          disabled
                        />
                        <span className="subtle">
                          <small>URL of your identity provider for the authorization requests</small>
                        </span>
                      </FormField>
                    </div>
                  )}
                  {currentWorkspace.idp_sso_target_url && currentWorkspace.auth_type === "shared" && (
                    <div>
                      <h2>3. Sign in via SSO to test and validate your configuration</h2>
                      <FormSeparator>
                        <a href={saml_init_url} target="_blank" rel="noreferrer noopener" className="button primary">
                          Log in via SSO
                        </a>
                      </FormSeparator>
                      <span className="subtle">
                        <small>You need to sign in using your Identity Provider to enabled SSO via SAML.</small>
                      </span>
                    </div>
                  )}
                </div>
              )}
              {auth_type === "shared" && (
                <FormActions>
                  <button type="submit" className="primary" disabled={ui.formState === "requesting"}>
                    {submitText}
                  </button>
                </FormActions>
              )}
            </form>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const formSelector = formValueSelector("workspaceSettingsSSO");

const mapStateToProps = state => {
  const accepted_domains = state.session.currentWorkspace.accepted_domains.toString();
  const restricted_domains = state.session.currentWorkspace.restricted_domains.toString();
  const restricted_ips = state.session.currentWorkspace.restricted_ips.toString();
  const { otp_required, auth_type, idp_cert, idp_sso_target_url } = state.session.currentWorkspace;
  return {
    auth_type: formSelector(state, "auth_type"),
    currentMembership: state.session.currentMembership,
    currentWorkspace: state.session.currentWorkspace,
    initialValues: {
      auth_type,
      accepted_domains,
      restricted_domains,
      restricted_ips,
      otp_required,
      idp_cert,
      idp_sso_target_url
    },
    ui: state.ui.workspaceSettingsSSO
  };
};

const mapDispatchToProps = {
  updateWorkspace: workspacesOperations.updateWorkspace
};

const _WorkspaceSettingsSecurity = reduxForm({
  form: "workspaceSettingsSSO"
})(WorkspaceSettingsSecurity);

export default connect(mapStateToProps, mapDispatchToProps)(_WorkspaceSettingsSecurity);
