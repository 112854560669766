//@flow
import { request } from "state/utils";
import * as types from "./types";
import { getSubdomain } from "state/utils/url";

const slug = getSubdomain() || "";

export const slackInstall = (slug: string, body: Object) => ({
  types: [types.SLACK_INSTALL_REQUESTED, types.SLACK_INSTALL_COMPLETED, types.SLACK_INSTALL_FAILED],
  callAPI: () => {
    if (!slug) {
      return Promise.reject(new Error("Couldn't find a subdomain"));
    }

    return request.post(`/workspaces/${slug}/slack`, body);
  }
});

export const slackUninstall = () => ({
  types: [types.SLACK_UNINSTALL_REQUESTED, types.SLACK_UNINSTALL_COMPLETED, types.SLACK_UNINSTALL_FAILED],
  callAPI: () => {
    const subdomain = getSubdomain();
    if (!subdomain) {
      return Promise.reject(new Error("Couldn't find a subdomain"));
    }
    return request.delete(`/workspaces/${subdomain}/slack`);
  }
});

export const jiraInstall = (slug: string, body: Object) => ({
  types: [types.JIRA_INSTALL_REQUESTED, types.JIRA_INSTALL_COMPLETED, types.JIRA_INSTALL_FAILED],
  callAPI: () => {
    if (!slug) {
      return Promise.reject(new Error("Couldn't find a subdomain"));
    }

    return request.post(`/workspaces/${slug}/jira`, body);
  }
});

export const jiraList = (slug: string) => ({
  types: [types.JIRA_LIST_REQUESTED, types.JIRA_LIST_COMPLETED, types.JIRA_LIST_FAILED],
  callAPI: () => {
    if (!slug) {
      return Promise.reject(new Error("Couldn't find a subdomain"));
    }

    return request.get(`/workspaces/${slug}/jira`);
  }
});

export const fetchJiraDetails = (slug: string) => ({
  types: [types.FETCH_JIRA_REQUESTED, types.FETCH_JIRA_COMPLETED, types.FETCH_JIRA_FAILED],
  callAPI: () => {
    if (!slug) {
      return Promise.reject(new Error("Couldn't find a subdomain"));
    }

    return request.get(`/workspaces/${slug}/services/jira`);
  }
});

export const jiraUninstall = () => ({
  types: [types.JIRA_UNINSTALL_REQUESTED, types.JIRA_UNINSTALL_COMPLETED, types.JIRA_UNINSTALL_FAILED],
  callAPI: () => {
    const subdomain = getSubdomain();
    if (!subdomain) {
      return Promise.reject(new Error("Couldn't find a subdomain"));
    }
    return request.delete(`/workspaces/${subdomain}/jira`);
  }
});

// NEW INTEGRATIONS CALLS
export const updateIntegration = (integrationId: string, integrationParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/integrations/${integrationId}`, { integration: integrationParams }),
    payload: { integrationParams }
  };
};

export const deleteIntegration = (integrationId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/integrations/${integrationId}`),
    payload: { id: integrationId }
  };
};

export const fetchIntegrationList = (params?: Object) => {
  const filters = [];
  if (params) {
    if (params.page) {
      filters.push(`page=${params.page}`);
    }
    if (params.name) {
      filters.push(`name=${params.name}`);
    }
  }
  const filtersQuery = filters.join("&");
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/workspaces/${slug}/integrations?${filtersQuery}`)
  };
};

export const fetchIntegrationDetails = (integrationId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/integrations/${integrationId}`)
  };
};

export const resetIntegrationList = () => ({
  type: types.RESET_LIST
});
