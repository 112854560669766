//@flow
import { request } from "state/utils";
import * as types from "./types";
import { getSubdomain } from "state/utils/url";

const slug = getSubdomain() || "";

export const createProject = (projectParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () => request.post(`/workspaces/${slug}/projects`, { project: projectParams }),
    payload: { projectParams }
  };
};

export const copyProject = (projectId: string) => {
  return {
    types: [types.COPY_REQUESTED, types.COPY_COMPLETED, types.COPY_FAILED],
    callAPI: () => request.post(`/projects/${projectId}/copy`),
    payload: { projectId }
  };
};

export const updateProject = (projectId: string, projectParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () => request.put(`/projects/${projectId}`, { project: projectParams }),
    payload: { projectParams }
  };
};

export const shareProject = (projectId: string, projectParams: Object) => {
  return {
    types: [types.SHARE_REQUESTED, types.SHARE_COMPLETED, types.SHARE_FAILED],
    callAPI: () => request.post(`/projects/${projectId}/share`, projectParams),
    payload: { projectParams }
  };
};

export const deleteProject = (projectId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/projects/${projectId}`),
    payload: { id: projectId }
  };
};

export const fetchProjectList = (params?: Object) => {
  const page = params ? params.page || 1 : 1;
  let parentFilter = "";
  if (params && params.parent !== undefined) {
    parentFilter = `&parent=${params.parent}`;
  }
  if (params && params.show_archived) {
    parentFilter = `${parentFilter}&show_archived=true`;
  }
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/workspaces/${slug}/projects?page=${page}${parentFilter}`),
    payload: params
  };
};

export const fetchProjectDetails = (projectId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/projects/${projectId}`),
    payload: { projectId }
  };
};

export const fetchProjectTrends = (projectId: string, params: ?any) => {
  return {
    types: [types.FETCH_TRENDS_REQUESTED, types.FETCH_TRENDS_COMPLETED, types.FETCH_TRENDS_FAILED],
    callAPI: () => request.get(`/projects/${projectId}/trends`, { params })
  };
};

export const fetchProjectTasks = (projectId: string, params: ?any) => {
  return {
    types: [types.FETCH_TASKS_REQUESTED, types.FETCH_TASKS_COMPLETED, types.FETCH_TASKS_FAILED],
    callAPI: () => request.get(`/projects/${projectId}/tasks`, { params })
  };
};

export const resetProjectList = () => ({
  type: types.RESET_LIST
});
