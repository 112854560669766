// @flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Styles helpers
import styled from "styled-components";

// Ducks stuff
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import * as sessionTypes from "state/ducks/session/types";
import { push } from "react-router-redux";

// Utils
import { handleChange } from "v2/utils/forms";

// Library Components
import { Link } from "react-router-dom";

// Tability Components
import FormActions from "v2/components/FormActions";
import FormField from "v2/components/FormField";
import InlineMessage from "v2/components/InlineMessage";
import Loader from "v2/components/Loader";
import SessionLayout from "v2/components/SessionLayout";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  clearConfirm: Function,
  confirmEmail: Function,
  location: Object,
  push: Function,
  resendConfirmationEmail: Function,
  ui: Object
};

type State = {
  email: string
};

class Confirm extends Component<Props, State> {
  state = {
    email: ""
  };
  componentDidMount() {
    this.props.clearConfirm();
    document.title = "Confirming your email | Tability";
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const confirmationToken = searchParams.get("token");
    this.props.confirmEmail(confirmationToken).then(action => {
      if (action.type === sessionTypes.EMAIL_CONFIRMATION_COMPLETED) {
        setTimeout(() => {
          this.props.push("/");
        }, 3000);
      }
    });
  }

  resendConfirmation = (e: Object) => {
    const { email } = this.state;

    const credentials = {
      email
    };

    this.props.resendConfirmationEmail(credentials);

    e.preventDefault();
  };

  handleChange = e => handleChange(this, e);

  render() {
    const { ui } = this.props;
    const { email } = this.state;
    return (
      <Container>
        <SessionLayout title="Confirm your email">
          <InlineMessage message={ui.message} messageType={ui.messageType} />
          {ui.isFetching && (
            <Fragment>
              <p>
                Please wait while we are confirming your email. <Loader size="small" />
              </p>
            </Fragment>
          )}
          {ui.tokenInvalid && !ui.isConfirmed && (
            <form onSubmit={this.resendConfirmation}>
              <FormField>
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  autoComplete="off"
                  aria-label="Add your email to resend the confirmation"
                  name="email"
                  required
                  value={email}
                  onChange={this.handleChange}
                />
              </FormField>
              {!ui.isFetching && (
                <FormActions>
                  <button type="submit" className="primary action">
                    <span>Resend confirmation email</span>
                  </button>
                  <Link to="/login" className="button secondary action">
                    Back to login
                  </Link>
                </FormActions>
              )}
              {ui.isFetching && (
                <button disabled className="primary">
                  <Loader size="small" />
                </button>
              )}
            </form>
          )}
          {ui.isConfirmed && !ui.tokenInvalid && <p>You will soon be redirected to Tability.</p>}
          {ui.isConfirmed && (
            <FormActions>
              <Link to="/login" className="button primary">
                Sign in
              </Link>
            </FormActions>
          )}
        </SessionLayout>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui.sessionConfirm
});

const mapDispatchToProps = {
  confirmEmail: sessionOperations.confirmEmail,
  clearConfirm: uiOperations.clearSessionConfirm,
  push,
  resendConfirmationEmail: sessionOperations.resendConfirmationEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
