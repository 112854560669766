// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { workspacesOperations } from "state/ducks/workspaces";
import { checkCurrentWorkspace } from "state/ducks/session/actions";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import * as constants from "config/constants";
import { handleChange } from "v2/utils/forms";
import * as workspaceTypes from "state/ducks/workspaces/types";
import { push } from "react-router-redux";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormFlag from "v2/components/FormFlag";
import Loader from "v2/components/Loader";
import TickPng from "./_assets/tick.png";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

const Plans = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  table {
    img {
      width: 2rem;
    }

    td {
      padding: ${spacing.x1} ${spacing.x2};
      border-bottom: 1px solid #e9e9e9;
      width: 16%;
      &:nth-child(2n) {
        background-color: #f3f3f3;
      }
      hr {
        margin: 2rem 0;
      }
      big {
        transition: all 0.4s ease;
        font-size: 3.6rem;
        line-height: 1.2;
        display: block;
        sup {
          vertical-align: super;
          font-size: 20px;
        }
      }
    }
  }
`;

const Toggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${spacing.x2};
  .tgl {
    display: none;
    
    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .tgl-btn {
      box-sizing: border-box;
      &::selection {
        background: none;
      }
    }
    
    + .tgl-btn {
      outline: 0;
      display: block;
      margin: 0 ${spacing.x2};
      width: 4em;
      height: 2em;
      position: relative;
      cursor: pointer;
      user-select: none;
      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }
      
      &:after {
        left: 0;
      }
      
      &:before {
        display: none;
      }
    }
    
    &:checked + .tgl-btn:after {
      left: 50%;
    }
  }

  // themes
  .tgl-light {
    + .tgl-btn {
      background: ${colors.grey};
      border-radius: 2em;
      padding: 2px;
      transition: all .4s ease;
      &:after {
        border-radius: 50%;
        background: #fff;
        transition: all .2s ease;
        
      }
    }
    
      
    }
  }

  .save {
    display: inline-block;
    border-radius: 4px;
    padding: 0.4rem ${spacing.x1};
    margin-left: ${spacing.x1};
    background: ${colors.green};
    color: #fff;
  }

  div {
    width: 200px;
    &:first-of-type {
      text-align: right;
    }
  }
`;

const FrequencyTip = styled.div`
  color: ${colors.subtleText};
  text-align: center;
  width: 40rem;
  margin: 0 auto;
  margin-bottom: ${spacing.x2};
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  checkCurrentWorkspace: Function,
  ui: Object,
  cancelSubscription: Function,
  push: Function
};

type State = {
  payAnnually: boolean
};

class WorkspaceSettingsPlan extends Component<Props, State> {
  state = {
    payAnnually: false
  };

  componentDidMount() {
    this.props.checkCurrentWorkspace();
  }

  handleCancelSubscription = e => {
    e.preventDefault();
    if (!window.confirm("Are you sure you want to go back to the Free Plan?")) {
      return false;
    }
    this.props.cancelSubscription().then(action => {
      if (action.type === workspaceTypes.CANCEL_SUBSCRIPTION_COMPLETED) {
        this.props.push("/settings/billing");
      }
    });
  };

  handlePlanSelect = e => {
    e.preventDefault();
    const plan = e.target.dataset.plan;
    this.props.push(`/settings/plan/confirm?plan=${plan}`);
  };

  handleChange = e => handleChange(this, e);

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentWorkspace, ui } = this.props;
    const { payAnnually } = this.state;

    // Set the frequency based on the payAnnually boolean
    const frequency = payAnnually ? "annually" : "monthly";

    // The frequency can be extracted from the plan name
    const currentPlanID = currentWorkspace.stripe_cache_subscription_plan;
    const currentPlanIsAnnual = currentPlanID && currentPlanID.indexOf("annually") >= 0;
    //const targetPlanID = payAnnually ? "annually-standard" : "monthly-standard";

    const planNameMapping = {
      [constants.PLAN_ANNUALLY_STANDARD]: "Legacy Annually Teams",
      [constants.PLAN_MONTHLY_STANDARD]: "Legacy Annually Teams",
      [constants.PLAN_ANNUALLY_BUSINESS]: "Legacy Annually Business",
      [constants.PLAN_MONTHLY_BUSINESS]: "Legacy Annually Business",
      [constants.PLAN_MONTHLY_PACKAGE_PILOT]: "Annually Pilot",
      [constants.PLAN_ANNUALLY_PACKAGE_PILOT]: "Annually Pilot",
      [constants.PLAN_MONTHLY_PACKAGE_TEAMS]: "Annually Teams",
      [constants.PLAN_ANNUALLY_PACKAGE_TEAMS]: "Annually Teams",
      [constants.PLAN_MONTHLY_PACKAGE_BUSINESS]: "Annually Business",
      [constants.PLAN_ANNUALLY_PACKAGE_BUSINESS]: "Annually Business",
      [constants.PLAN_MONTHLY_PACKAGE_UNLIMITED]: "Annually Unlimited",
      [constants.PLAN_ANNUALLY_PACKAGE_UNLIMITED]: "Annually Unlimited"
    };

    // Set the plan name based on the cached subscription plan
    let planName = "Free"; // Default to free
    // If there's a plan, get the public name from the mapping
    if (currentWorkspace.stripe_cache_subscription_plan) {
      planName = planNameMapping[currentWorkspace.stripe_cache_subscription_plan];
    }

    const pilotPrice = payAnnually ? 39 : 49;
    const teamsPrice = payAnnually ? 119 : 149;
    const businessPrice = payAnnually ? 239 : 299;
    const unlimitedPrice = payAnnually ? 799 : 999;

    // This is the content of the submit button. Either text or a loader.
    const freeSubmitText = ui.isCancelingSubscription ? <Loader size="small" /> : "Select";

    // Disable the free downgrade if we do not have a plan (we're already on Free)
    const freeDisabled = !currentPlanID;

    // Upgrade button state logic
    let pilotUpgradeDisabled = false;
    let teamsUpgradeDisabled = false;
    let businessUpgradeDisabled = false;
    let unlimitedUpgradeDisabled = false;

    // Disable upgrade if current plan has a annual frequency. People should contact us directly for upgrades
    if (currentPlanIsAnnual) {
      pilotUpgradeDisabled = true;
      teamsUpgradeDisabled = true;
      businessUpgradeDisabled = true;
      unlimitedUpgradeDisabled = true;
    }

    // Disable monthly upgrades if they're already the current plan
    if (currentPlanID) {
      if (frequency === "monthly" && currentPlanID === constants.PLAN_MONTHLY_PACKAGE_PILOT) {
        pilotUpgradeDisabled = true;
      }
      if (frequency === "monthly" && currentPlanID === constants.PLAN_MONTHLY_PACKAGE_TEAMS) {
        teamsUpgradeDisabled = true;
      }
      if (frequency === "monthly" && currentPlanID === constants.PLAN_MONTHLY_PACKAGE_BUSINESS) {
        businessUpgradeDisabled = true;
      }
      if (frequency === "monthly" && currentPlanID === constants.PLAN_MONTHLY_PACKAGE_UNLIMITED) {
        unlimitedUpgradeDisabled = true;
      }
    }

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>Choose your plan (current: {planName})</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            <Toggle>
              <div>
                <b>Pay monthly</b>
              </div>
              <input
                className="tgl tgl-light"
                id="cb1"
                type="checkbox"
                checked={payAnnually}
                onChange={this.handleChange}
                name="payAnnually"
              />
              <label className="tgl-btn" htmlFor="cb1" />
              <div>
                <b>Pay annually</b>
                <span className="save">Save 20%</span>
              </div>
            </Toggle>
            <FrequencyTip>Choose a package, pay the same price every month.</FrequencyTip>
            <Plans>
              <table>
                <tr>
                  <td></td>
                  <td>
                    <h3>Free</h3>
                    <big>
                      <sup>$</sup>0
                    </big>
                    <b>Forever</b>
                    <hr />
                    <p>The essential to get started for teams new to Tability.</p>
                  </td>
                  <td>
                    <h3>Pilot</h3>
                    <big>
                      <sup>$</sup>
                      {pilotPrice}
                    </big>
                    <b>/month</b>
                    <hr />
                    <p>For small teams looking to increase focus and accountability.</p>
                  </td>
                  <td>
                    <h3>Teams</h3>
                    <big>
                      <sup>$</sup>
                      {teamsPrice}
                    </big>
                    <b>/month</b>
                    <hr />
                    <p>For teams of 20+ people with full functionality and SSO.</p>
                  </td>
                  <td>
                    <h3>Business</h3>
                    <big>
                      <sup>$</sup>
                      {businessPrice}
                    </big>
                    <b>/month</b>
                    <hr />
                    <p>For orgs of 100+ people looking for full visibility and transparency.</p>
                  </td>
                  <td>
                    <h3>Unlimited</h3>
                    <big>
                      <sup>$</sup>
                      {unlimitedPrice}
                    </big>
                    <b>/month</b>
                    <hr />
                    <p>Stress-free pricing for large companies. Fixed costs for unlimited usage.</p>
                  </td>
                </tr>
                <tr>
                  <td>Unlimited users</td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                </tr>
                <tr>
                  <td>Pages</td>
                  <td>1</td>
                  <td>Up to 5</td>
                  <td>Up to 20</td>
                  <td>Up to 60</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Page permissions</td>
                  <td>-</td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                </tr>
                <tr>
                  <td>CSV Export</td>
                  <td>-</td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                </tr>
                <tr>
                  <td>Public report previews</td>
                  <td>-</td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                </tr>
                <tr>
                  <td>Advanced access controls</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                </tr>
                <tr>
                  <td>Enforce 2FA</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                </tr>
                <tr>
                  <td>SAML SSO</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                  <td>
                    <img src={TickPng} alt="checked" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="light">All prices are USD</p>
                  </td>
                  <td>
                    <button onClick={this.handleCancelSubscription} disabled={freeDisabled}>
                      {freeSubmitText}
                    </button>
                  </td>
                  <td>
                    <button
                      className="button primary"
                      data-plan={`${frequency}-package-pilot`}
                      disabled={pilotUpgradeDisabled}
                      onClick={this.handlePlanSelect}
                    >
                      Upgrade
                    </button>
                    {currentWorkspace.stripe_cache_subscription_plan === constants.PLAN_MONTHLY_PACKAGE_PILOT &&
                      !payAnnually && <p className="light">You're already on the Monthly Pilot plan.</p>}
                    {currentPlanIsAnnual && (
                      <p>
                        You're on an annual plan. Please&nbsp;
                        <button
                          className="link-button"
                          onClick={e => {
                            e.preventDefault();
                            window.Intercom("show");
                          }}
                        >
                          contact us
                        </button>
                        &nbsp;if you need to change your subscription.
                      </p>
                    )}
                  </td>
                  <td>
                    <button
                      className="button primary"
                      data-plan={`${frequency}-package-teams`}
                      disabled={teamsUpgradeDisabled}
                      onClick={this.handlePlanSelect}
                    >
                      Upgrade
                    </button>
                    {currentWorkspace.stripe_cache_subscription_plan === constants.PLAN_MONTHLY_PACKAGE_TEAMS &&
                      !payAnnually && <p className="light">You're already on the Monthly Teams plan.</p>}
                    {currentPlanIsAnnual && (
                      <p>
                        You're on an annual plan. Please&nbsp;
                        <button
                          className="link-button"
                          onClick={e => {
                            e.preventDefault();
                            window.Intercom("show");
                          }}
                        >
                          contact us
                        </button>
                        &nbsp;if you need to change your subscription.
                      </p>
                    )}
                  </td>
                  <td>
                    <button
                      className="button primary"
                      data-plan={`${frequency}-package-business`}
                      disabled={businessUpgradeDisabled}
                      onClick={this.handlePlanSelect}
                    >
                      Upgrade
                    </button>
                    {currentWorkspace.stripe_cache_subscription_plan === constants.PLAN_MONTHLY_PACKAGE_BUSINESS &&
                      !payAnnually && <p className="light">You're already on the Monthly Business plan.</p>}
                    {currentPlanIsAnnual && (
                      <p>
                        You're on an annual plan. Please&nbsp;
                        <button
                          className="link-button"
                          onClick={e => {
                            e.preventDefault();
                            window.Intercom("show");
                          }}
                        >
                          contact us
                        </button>
                        &nbsp;if you need to change your subscription.
                      </p>
                    )}
                  </td>
                  <td>
                    <button
                      className="button primary"
                      data-plan={`${frequency}-package-unlimited`}
                      disabled={unlimitedUpgradeDisabled}
                      onClick={this.handlePlanSelect}
                    >
                      Upgrade
                    </button>
                    {currentWorkspace.stripe_cache_subscription_plan === constants.PLAN_MONTHLY_PACKAGE_UNLIMITED &&
                      !payAnnually && <p className="light">You're already on the Monthly Unlimited plan.</p>}
                    {currentPlanIsAnnual && (
                      <p>
                        You're on an annual plan. Please&nbsp;
                        <button
                          className="link-button"
                          onClick={e => {
                            e.preventDefault();
                            window.Intercom("show");
                          }}
                        >
                          contact us
                        </button>
                        &nbsp;if you need to change your subscription.
                      </p>
                    )}
                  </td>
                </tr>
              </table>
            </Plans>
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  ui: state.ui.workspaceSettingsPlan
});

const mapDispatchToProps = {
  checkCurrentWorkspace,
  cancelSubscription: workspacesOperations.cancelSubscription,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettingsPlan);
