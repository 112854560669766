//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";

import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import { push } from "react-router-redux";
import * as squadTypes from "squadTypes";

import styled from "styled-components";
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

import Avatar from "v2/components/Avatar";
import DropdownMenu from "v2/components/DropdownMenu";

import Logo from "v2/components/_assets/tability_icon_black.png";
import IconDashboard from "v2/components/svg/IconDashboard";
import IconHelp from "v2/components/svg/IconHelp";
import IconMagnify from "v2/components/svg/IconMagnify";
import IconNotifications from "v2/components/svg/IconNotifications";
import IconTeam from "v2/components/svg/IconTeam";
import IconSettings from "v2/components/svg/IconSettings";
import IconGoal from "v2/components/svg/IconGoal";
import IconPage from "v2/components/svg/IconPage";
import ReactTooltip from "react-tooltip";

const whatsNewReference = "2020-04-09T03:20:02.520Z";

const PrincipalNav = styled.div`
  width: 6rem;
  background-color: #0a5055;

  display: flex;
  flex-direction: column;

  .nav {
    display: flex;
    width: 100%;
    background-color: #0a5055;
    border-left: 5px solid #0a5055;
    align-items: center;
    justify-content: center;
    height: 5rem;

    svg {
      width: 1.8rem;
      position: relative;
      right: 5px;

      g {
        stroke: #fff;
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      background-color: #3b7377;
      border-left: 5px solid #3b7377;
      outline: 0;
    }

    &.active {
      background-color: #f8f8f8;
      border-left: 5px solid #f4cdc9;

      svg {
        g {
          stroke: #0a5055;
        }
      }

      &:hover,
      &:focus,
      &:focus-within {
        background-color: #3b7377;
        outline: 0;
        svg {
          g {
            stroke: #fff;
          }
        }
      }
    }
  }
`;

const HeaderNavLinks = styled.div`
  margin: ${spacing.x4} 0 ${spacing.x10} 0;
  a {
    img {
      width: 3rem;
      position: relative;
      right: 5px;
    }
    padding: 0;
    margin: 0;
  }

  .link-button {
    margin-top: ${spacing.x1};
  }

  @media ${devices.tablet} {
    margin: ${spacing.x2} 0;
  }
`;

const MainNavLinks = styled.div`
  flex: 1;
`;

const BottomNavLinks = styled.div`
  margin-bottom: ${spacing.x4};

  .avatar {
    position: relative;
    right: 5px;
  }

  @media ${devices.tablet} {
    margin-bottom: ${spacing.x2};
  }
`;

const NotificationContainer = styled.span`
  position: relative;
`;

const NotificationBadge = styled.div`
  position: absolute;
  font-size: 1rem;
  font-weight: 600;
  background-color: ${colors.red};
  color: #fff;
  padding: 4px;
  min-width: 20px;
  line-height: 1rem;
  border-radius: 25%;
  text-align: center;
  top: -15px;
  right: -5px;
`;

type Props = {
  currentMembership: squadTypes.Membership,
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  logout: Function,
  notifications: Object,
  push: Function,
  secondNav?: any,
  showModal: Function,
  ui: Object,
  visitWhatsNew: Function,
  whatsNewVisited: string
};

class WorkspaceLayout extends Component<Props> {
  logout = (e: Object) => {
    e.preventDefault();
    this.props.logout();
  };

  showWhatsNew = e => {
    this.props.visitWhatsNew();
  };

  _showQuickSearch = (e?: Object) => {
    if (e) {
      e.preventDefault();
    }

    this.props.showModal({
      contentType: "quicksearch",
      contentAction: "show"
    });
  };

  _getHelpMenuItems = () => {
    const items = [];
    const hasWhatsNew = this._hasWhatsNew();

    const hasWhatsNewClass = hasWhatsNew ? "notify-whatsnew" : "";
    items.push(
      <a
        href="https://blog.tability.io/tag/whatsnew/"
        rel="noopener noreferrer"
        target="_blank"
        onClick={this.showWhatsNew}
        className={`${hasWhatsNewClass}`}
      >
        What's new?
      </a>
    );
    items.push(
      <a href="https://trello.com/b/jFtwfQIG/tability-roadmap" rel="noopener noreferrer" target="_blank">
        Roadmap
      </a>
    );
    items.push(
      <a href="https://docs.tability.io" rel="noopener noreferrer" target="_blank">
        Documentation
      </a>
    );
    items.push(
      <a href="https://docs.tability.io/support" rel="noopener noreferrer" target="_blank">
        Support
      </a>
    );

    return items;
  };

  _hasWhatsNew = () => {
    const referenceTime = this.props.whatsNewVisited || this.props.currentUser.created_at;
    return moment(whatsNewReference)
      .utc()
      .isAfter(moment(referenceTime));
  };

  _getAccountMenuItems = () => {
    const items = [];
    items.push(<NavLink to="/account">Manage your account</NavLink>);
    items.push(
      <button className="link-button" onClick={this.logout}>
        Logout
      </button>
    );
    return items;
  };

  render() {
    const { currentWorkspace, currentMembership, currentUser, notifications } = this.props;

    const unseenNotificationsCount = notifications.unseenCount;
    const unseenNotificationsText = unseenNotificationsCount > 99 ? "99+" : unseenNotificationsCount;

    const hasWhatsNew = this._hasWhatsNew();
    const hasWhatsNewClass = hasWhatsNew ? "notify" : "";

    return (
      <PrincipalNav aria-label="Main navigation">
        <HeaderNavLinks className="header">
          <Link to="/" className="logo nav">
            <img src={Logo} alt="Tability" />
          </Link>
          <button className="link-button nav" data-tip data-for="searchTip" onClick={this._showQuickSearch}>
            <IconMagnify />
          </button>
          <ReactTooltip id="searchTip" place="right" type="dark" effect="solid">
            Quick search
          </ReactTooltip>
        </HeaderNavLinks>
        {!currentWorkspace.is_suspended && !currentWorkspace.access_restricted && (
          <MainNavLinks>
            <ul>
              <li>
                <NavLink to="/" exact={true} data-tip data-for="dashboardTip" className="nav">
                  <IconDashboard />
                </NavLink>
                <ReactTooltip id="dashboardTip" place="right" type="dark" effect="solid">
                  Dashboard
                </ReactTooltip>
              </li>
              <li>
                <NavLink to="/pages" data-tip data-for="projectsTip" className="nav">
                  <IconPage />
                </NavLink>
                <ReactTooltip id="projectsTip" place="right" type="dark" effect="solid">
                  Pages
                </ReactTooltip>
              </li>
              <li>
                <NavLink
                  to="/goals/eyJzdGF0ZSI6WyJvcGVuIl19"
                  data-tip
                  data-for="goalsTip"
                  isActive={(match, location) => location.pathname.includes("/goals")}
                  className="nav"
                >
                  <IconGoal />
                </NavLink>
                <ReactTooltip id="goalsTip" place="right" type="dark" effect="solid">
                  Open Goals
                </ReactTooltip>
              </li>
              <li>
                <NavLink to="/team" data-tip data-for="teamTip" className="nav">
                  <IconTeam />
                </NavLink>
                <ReactTooltip id="teamTip" place="right" type="dark" effect="solid">
                  Team
                </ReactTooltip>
              </li>
              <li>
                <NavLink to="/notifications" exact={true} data-tip data-for="notificationsTip" className="nav">
                  <NotificationContainer>
                    <IconNotifications />
                    {unseenNotificationsCount > 0 && <NotificationBadge>{unseenNotificationsText}</NotificationBadge>}
                  </NotificationContainer>
                </NavLink>
                <ReactTooltip id="notificationsTip" place="right" type="dark" effect="solid">
                  Notifications
                </ReactTooltip>
              </li>
            </ul>
          </MainNavLinks>
        )}
        <BottomNavLinks>
          {(currentMembership.role === "admin" || currentMembership.role === "owner") && (
            <NavLink to="/settings" data-tip data-for="settingsTip" className="settings nav">
              <IconSettings />
            </NavLink>
          )}
          <ReactTooltip id="settingsTip" place="right" type="dark" effect="solid">
            Workspace settings
          </ReactTooltip>
          <DropdownMenu
            className={`nav ${hasWhatsNewClass}`}
            position="left"
            trigger={<IconHelp />}
            items={this._getHelpMenuItems()}
          />
          <DropdownMenu
            className="nav"
            position="left"
            trigger={<Avatar user={currentUser} size={28} />}
            items={this._getAccountMenuItems()}
          />
        </BottomNavLinks>
      </PrincipalNav>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  currentUser: state.session.currentUser,
  currentMembership: state.session.currentMembership,
  notifications: state.notifications,
  ui: state.ui.workspaceNav,
  whatsNewVisited: state.session.whatsNewVisited
});

const mapDispatchToProps = {
  push,
  logout: sessionOperations.logout,
  visitWhatsNew: sessionOperations.visitWhatsNew,
  showModal: uiOperations.showModal
};

export default //withRouter(
connect(mapStateToProps, mapDispatchToProps)(WorkspaceLayout);
//);
