//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg``;
export default (props: Object) => (
  <Logo viewBox="0 0 20 17" {...props}>
    <title id="Team-Team-title">Team</title>
    <g
      id="Team-01-Product-UI"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Team-Dashboard---Goal-Detail-B"
        transform="translate(-26.000000, -212.000000)"
        stroke="#6A6A6A"
        strokeWidth="2"
      >
        <g id="Team-Group-17" transform="translate(26.000000, 26.000000)">
          <g id="Team-Group-16" transform="translate(0.000000, 119.000000)">
            <g id="Team-Group-15">
              <g id="Team-Group-14" transform="translate(0.000000, 15.000000)">
                <g id="Team-Group-12" transform="translate(1.000000, 53.000000)">
                  <path
                    d="M13.0909091,14.7272727 L13.0909091,13.0909091 C13.0909091,11.2834317 11.6256592,9.81818182 9.81818182,9.81818182 L3.27272727,9.81818182 C1.46524991,9.81818182 1.81672859e-16,11.2834317 0,13.0909091 L0,14.7272727"
                    id="Team-Shape"
                  />
                  <circle id="Team-Oval" cx="6.54545455" cy="3.27272727" r="3.27272727" />
                  <path
                    d="M18,14.7272727 L18,13.0909091 C17.9988875,11.59946 16.9895445,10.2974074 15.5454545,9.92454545"
                    id="Team-Shape2"
                  />
                  <path
                    d="M12.2727273,0.106363636 C13.7208394,0.477138917 14.7336899,1.78199276 14.7336899,3.27681818 C14.7336899,4.77164361 13.7208394,6.07649745 12.2727273,6.44727273"
                    id="Team-Shape3"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
