// @flow
/**
 * This page is used to perform Slack authentication and redirect to the right domain
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { integrationsOperations } from "state/ducks/integrations";
import * as integrationsTypes from "state/ducks/integrations/types";
import { uiOperations } from "state/ducks/ui";

// Components
import Loader from "v2/components/Loader";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 10vh 0 0 0;
  ion-icon {
    font-size: 2.2rem;
    padding-right: 0.8rem;
  }
`;

type Props = {
  clearPage: Function,
  slackInstall: Function,
  ui: Object,
  location: Object
};

type State = {};

class SlackInstall extends Component<Props, State> {
  componentDidMount() {
    const { location } = this.props;
    this.props.clearPage();
    // get the params from the URL

    if (location && location.search) {
      const params = new URLSearchParams(location.search);

      const code = params.get("code");
      const state = params.get("state");

      const stateDecoded = state ? JSON.parse(atob(state)) : null;

      if (stateDecoded) {
        const slug = stateDecoded.slug;

        const slackInstallParams = {
          code,
          state
        };

        // Then post the auth request to the backend
        this.props.slackInstall(slug, slackInstallParams).then(action => {
          if (action.type === integrationsTypes.SLACK_INSTALL_COMPLETED) {
            window.location.href = `${stateDecoded.redirect_after}`;
          }
        });
      }
    }

    // If the action is successful, redirect to the domain param
  }

  render() {
    const { location, ui } = this.props;
    let redirect_url;
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const state = params.get("state");

      const stateDecoded = state ? JSON.parse(atob(state)) : null;

      if (!stateDecoded) {
        return false;
      }
      redirect_url = stateDecoded.redirect_after;
    }
    return (
      <Container>
        {!ui.isFetching && !ui.error && <div>Redirecting you.</div>}

        {!ui.isFetching && ui.error && (
          <div>
            <p>Sorry, we could not log you in.</p>
            <p>
              <a href={redirect_url}>Go back to your settings</a>
            </p>
          </div>
        )}
        {ui.isFetching && <Loader />}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui.slackInstall
});

const mapDispatchToProps = {
  clearPage: uiOperations.clearPage,
  slackInstall: integrationsOperations.slackInstall
};

export default connect(mapStateToProps, mapDispatchToProps)(SlackInstall);
