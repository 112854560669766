export const colors = {
  black: "#151515",
  blue: "#324DD3",
  green: "#408C76",
  grey: "#D4D4D4",
  greyLight: "#F3F3F3",
  orange: "#F69380",
  red: "#F24833",
  yellow: "#F5C02B",
  inputBorder: "#949494",
  blockBorder: "#d1d1d1",
  progressBar: "#A9A9A9",
  progressBarBg: "#E9E9E9",
  subtleText: "#6A6A6A",
  dangerBg: "rgba(170, 59, 39, 0.1)",
  infoBg: "rgba(46, 83, 186, 0.1)",
  successBg: "rgba(95, 146, 103, 0.1)",
  warningBg: "rgba(221, 179, 26, 0.1)"
};
