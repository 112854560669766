//@flow
import styled from "styled-components";
import { colors } from "v2/components/styles/colors";
import { spacing } from "v2/components/styles/spacing";

export default styled.div`
  margin-bottom: ${spacing.x3};

  .field-tip {
    display: block;
    margin-top: ${spacing.x1};
  }
  display: flex;
  flex-direction: column;

  .react-select__control {
    border: 1px solid ${colors.inputBorder};
    border-radius: 4px;
    box-shadow: 0;
    padding: 0 0 0 1.6rem;
    margin: 0;
    background: transparent;
  }

  .small-select,
  .small {
    width: 50%;
  }

  .tiny-select,
  .tiny {
    width: 25%;
  }

  .super-tiny {
    width: 10rem;
  }

  .react-select__control--is-focused {
    box-shadow: none;
    border: 0;
    min-height: 3.2rem !important;
    border: 2px solid ${colors.blue};
  }

  .react-select__indicator-separator {
    height: auto;
  }

  .react-select__value-container {
    padding: 0;
    margin: 0;

    > div {
      margin: 0 2px;
      padding: 0;
    }

    input {
      font-size: 1.4rem !important;
      height: 4.2rem !important;
      line-height: 4.2rem !important;
      margin: 0;
      padding: 0;
    }
  }

  .react-select__placeholder {
    color: #949494;
    font-size: 1.4rem !important;
  }

  .react-select__option {
    background: #fff;
    color: ${colors.black};
  }

  .react-select__option--is-selected,
  .react-select__option--is-focused {
    background-color: #f3f3f3;
  }

  .react-select__option--is-selected {
    font-weight: 600;
  }

  .react-select__value-container {
    input {
      height: 3.4rem !important;
      line-height: 3.4rem !important;
    }
  }

  .react-select__control {
    padding: 0 0 0 0.8rem;
    height: 3.8rem !important;
    min-height: 3.8rem !important;
    box-sizing: border-box;
  }

  .react-select__indicator {
    padding: 0 0.2rem;
  }

  .compact {
    .react-select__value-container {
      input {
        height: 3.2rem !important;
        line-height: 3.2rem !important;
      }
    }

    .react-select__control {
      padding: 0 0 0 0.8rem;
      height: 3.2rem !important;
      min-height: 3.2rem !important;
      box-sizing: border-box;
    }

    .react-select__indicator {
      padding: 0 0.2rem;
    }
  }

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    transition: 0.3s ease-in-out;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${spacing.x1};
    min-height: 15rem;
    &::placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }

  .error {
    color: ${colors.red};
  }
`;
