// @flow
import moment from "moment";
import * as constants from "config/constants";

// Need to change that to formatTinyDate
export const formatShortDate = (date: any) => moment(date).format("D MMM");

export const formatLabelDate = (date: any) => moment(date).format("D MMM YYYY");

export const shortDate = (date: any) => moment(date).format(constants.SHORT_DATE_FORMAT);

export const localDate = (date: any) => moment(date).format(constants.LOCAL_DATE_FORMAT);

export const secondsToHms = (duration: number) => {
  const d = Number(duration);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + "h" : "";
  const mDisplay = m > 0 ? m + "m" : "";
  const sDisplay = s > 0 ? s + "s" : "";
  return hDisplay + mDisplay + sDisplay;
};

export const secondsToHm = (duration: number) => {
  const d = Number(duration);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);

  const hDisplay = h > 0 ? h + "h" : "";
  const mDisplay = m > 0 ? m + "m" : "";
  return hDisplay + mDisplay;
};

export const secondsToHmsQuoted = (duration: number) => {
  const d = Number(duration);
  const m = Math.floor(d / 60);
  const s = Math.floor((d % 3600) % 60);

  const mDisplay = m > 0 ? m + "'" : "";
  const sDisplay = s > 0 ? s : "";
  return mDisplay + sDisplay;
};

export const humanizedDuration = (durationSeconds: number) => {
  if (durationSeconds === 0) return "0s";
  const d = moment.duration(durationSeconds, "seconds");
  return d.humanize();
};
