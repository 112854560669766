//@flow
import React, { Fragment } from "react";
import styled from "styled-components";
import * as squadTypes from "squadTypes";

const avatarBg = [
  "#C5A5EF",
  "#B184EA",
  "#8150C4",
  "#83D1DC",
  "#60AEB9",
  "#2E6A6E",
  "#7E98E6",
  "#5C76DE",
  "#324DD3",
  "#5568B4",
  "#F4CDC9",
  "#F69380"
];

const Picture = styled.div`
  background-color: ${props => props.bgColor};
  background-image: ${props => props.bgImage};
  transition: all 0.5s ease;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: ${props => props.fontSize}rem;
  height: ${props => props.avatarSize}rem;
  width: ${props => props.avatarSize}rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  span {
    color: #fff;
    margin: 0;
  }
`;

type Props = {
  user: squadTypes.User, // Used for color hash
  size?: number
};

export default (props: Props) => {
  const { user, size } = props;

  const avatarSize = size ? size / 10 : 4.8;

  // Setting the font size for the initials
  const fontSize = 0.4 * avatarSize;

  // Setting the defaults
  let avatarImg = null;
  let avatarInitials = "";
  let name = "Account deleted";

  if (user && user.avatar_url) {
    avatarImg = `url(${user.avatar_url})`;
  }

  if (user) {
    name = user.fullname || user.email;
  }
  if (user && user.fullname && !user.avatar_url) {
    avatarInitials = user.fullname
      .trim()
      .split(" ")
      .map(x => x.charAt(0).toUpperCase())
      .slice(0, 2)
      .join("");
  }

  let hash = user ? user.id.charCodeAt(0) : 10;
  const colorIndex = hash % avatarBg.length;

  return (
    <Picture
      className="avatar"
      bgColor={avatarBg[colorIndex]}
      bgImage={avatarImg}
      fontSize={fontSize}
      avatarSize={avatarSize}
      aria-label={name}
    >
      {avatarInitials && <Fragment>{avatarInitials}</Fragment>}
    </Picture>
  );
};
