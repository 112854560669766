//@flow
import { request } from "state/utils";
import * as types from "./types";

export const createProjectPermission = (projectId: string, projectPermissionParams: Object) => {
  return {
    types: [types.CREATE_REQUESTED, types.CREATE_COMPLETED, types.CREATE_FAILED],
    callAPI: () =>
      request.post(`/projects/${projectId}/project_permissions`, { project_permission: projectPermissionParams }),
    payload: { projectPermissionParams }
  };
};

export const updateProjectPermission = (projectPermissionId: string, projectPermissionParams: Object) => {
  return {
    types: [types.UPDATE_REQUESTED, types.UPDATE_COMPLETED, types.UPDATE_FAILED],
    callAPI: () =>
      request.put(`/project_permissions/${projectPermissionId}`, { project_permission: projectPermissionParams }),
    payload: { projectPermissionParams }
  };
};

export const deleteProjectPermission = (projectPermissionId: string) => {
  return {
    types: [types.DELETE_REQUESTED, types.DELETE_COMPLETED, types.DELETE_FAILED],
    callAPI: () => request.delete(`/project_permissions/${projectPermissionId}`),
    payload: { id: projectPermissionId }
  };
};

export const fetchProjectPermissionList = (projectId: string, params?: Object) => {
  const page = params ? params.page || 1 : 1;
  return {
    types: [types.FETCH_LIST_REQUESTED, types.FETCH_LIST_COMPLETED, types.FETCH_LIST_FAILED],
    callAPI: () => request.get(`/projects/${projectId}/project_permissions?page=${page}`)
  };
};

export const fetchProjectPermissionDetails = (projectPermissionId: string) => {
  return {
    types: [types.FETCH_DETAILS_REQUESTED, types.FETCH_DETAILS_COMPLETED, types.FETCH_DETAILS_FAILED],
    callAPI: () => request.get(`/project_permissions/${projectPermissionId}`)
  };
};

export const resetProjectPermissionList = () => ({
  type: types.RESET_LIST
});
