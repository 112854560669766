//@flow
export const SLACK_INSTALL_REQUESTED = "session/SLACK_INSTALL_REQUESTED";
export const SLACK_INSTALL_COMPLETED = "session/SLACK_INSTALL_COMPLETED";
export const SLACK_INSTALL_FAILED = "session/SLACK_INSTALL_FAILED";

export const SLACK_UNINSTALL_REQUESTED = "session/SLACK_UNINSTALL_REQUESTED";
export const SLACK_UNINSTALL_COMPLETED = "session/SLACK_UNINSTALL_COMPLETED";
export const SLACK_UNINSTALL_FAILED = "session/SLACK_UNINSTALL_FAILED";

export const JIRA_INSTALL_REQUESTED = "session/JIRA_INSTALL_REQUESTED";
export const JIRA_INSTALL_COMPLETED = "session/JIRA_INSTALL_COMPLETED";
export const JIRA_INSTALL_FAILED = "session/JIRA_INSTALL_FAILED";

export const JIRA_LIST_REQUESTED = "session/JIRA_LIST_REQUESTED";
export const JIRA_LIST_COMPLETED = "session/JIRA_LIST_COMPLETED";
export const JIRA_LIST_FAILED = "session/JIRA_LIST_FAILED";

export const JIRA_UNINSTALL_REQUESTED = "session/JIRA_UNINSTALL_REQUESTED";
export const JIRA_UNINSTALL_COMPLETED = "session/JIRA_UNINSTALL_COMPLETED";
export const JIRA_UNINSTALL_FAILED = "session/JIRA_UNINSTALL_FAILED";

export const UPDATE_REQUESTED = "integrations/UPDATE_REQUESTED";
export const UPDATE_COMPLETED = "integrations/UPDATE_COMPLETED";
export const UPDATE_FAILED = "integrations/UPDATE_FAILED";

export const DELETE_REQUESTED = "integrations/DELETE_REQUESTED";
export const DELETE_COMPLETED = "integrations/DELETE_COMPLETED";
export const DELETE_FAILED = "integrations/DELETE_FAILED";

export const FETCH_JIRA_REQUESTED = "integrations/FETCH_JIRA_REQUESTED";
export const FETCH_JIRA_COMPLETED = "integrations/FETCH_JIRA_COMPLETED";
export const FETCH_JIRA_FAILED = "integrations/FETCH_JIRA_FAILED";

export const FETCH_LIST_REQUESTED = "integrations/FETCH_LIST_REQUESTED";
export const FETCH_LIST_COMPLETED = "integrations/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "integrations/FETCH_LIST_FAILED";

export const FETCH_DETAILS_REQUESTED = "integrations/FETCH_DETAILS_REQUESTED";
export const FETCH_DETAILS_COMPLETED = "integrations/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "integrations/FETCH_DETAILS_FAILED";

export const RESET_LIST = "integrations/RESET_LIST";
