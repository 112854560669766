//@flow
import React from "react";
import styled from "styled-components";

import { colors } from "v2/components/styles/colors";

const Logo = styled.svg`
  height: 1.3rem;

  g {
    fill: ${colors.subtleText};
  }
`;
export default (props: Object) => (
  <Logo viewBox="0 0 14 13" {...props}>
    <g id="UI-Ready-to-Ship" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dependencies-view---icons/unopened" transform="translate(-496.000000, -421.000000)">
        <g id="Group-15-Copy-2" transform="translate(496.000000, 420.000000)">
          <g id="Group-15">
            <g id="Group-20">
              <g id="Group-7" transform="translate(0.000000, 1.000000)">
                <path
                  d="M5.2,1.3 L13,1.3"
                  id="Shape"
                  stroke="#6A6A6A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.2,6.5 L13,6.5"
                  id="Shape-Copy"
                  stroke="#6A6A6A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.2,11.7 L13,11.7"
                  id="Shape-Copy-2"
                  stroke="#6A6A6A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle id="Oval" fill="#6A6A6A" cx="1.3" cy="1.3" r="1.3" />
                <circle id="Oval-Copy-5" fill="#6A6A6A" cx="1.3" cy="6.5" r="1.3" />
                <circle id="Oval-Copy-6" fill="#6A6A6A" cx="1.3" cy="11.7" r="1.3" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Logo>
);
