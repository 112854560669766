// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { workspacesOperations } from "state/ducks/workspaces";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import StripeCheckout from "react-stripe-checkout";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";
import { Link } from "react-router-dom";
import * as workspaceTypes from "state/ducks/workspaces/types";
import { push } from "react-router-redux";

// Components
import { handleChange } from "v2/utils/forms";
import StripeImg from "../workspaceSettingsBilling/_assets/powered_by_stripe.svg";
import ReactTooltip from "react-tooltip";

//V2 styling
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

import FormFlag from "v2/components/FormFlag";

import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";
import WorkspaceSettingNav from "v2/components/WorkspaceSettingsNav";

const Container = styled.div`
  background: #fff;
  padding: ${spacing.x4};
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: ${spacing.x4};
  a {
    color: ${colors.subtleText};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const Main = styled.div`
  form {
    max-width: 50rem;
  }

  label {
    margin-bottom: ${spacing.x1};
    display: inline-block;
    color: ${colors.subtleText};
  }

  .bottom-space {
    margin-bottom: ${spacing.x2};
  }

  h2 {
    margin-bottom: ${spacing.x2};
  }
`;

const PoweredStripe = styled.div`
  margin-top: ${spacing.x2};
`;

const BillingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  h3 {
    margin-bottom: ${spacing.x2};
  }
`;

const PlanDetails = styled.div`
  flex: 3;
`;

const PlanContent = styled.div`
  font-size: 1.6rem;
  .subtle {
    font-size: 1.4rem;
  }
  section {
    margin: 0 ${spacing.x2} ${spacing.x2} ${spacing.x2};
    display: flex;
    align-items: flex-start;
  }
  label {
    display: inline-block;
    width: 250px;
    font-weight: 600;
  }
`;

const CardDetails = styled.div`
  flex: 2;

  .details {
    margin: ${spacing.x2} 0;
  }
`;

type Props = {
  currentWorkspace: squadTypes.Workspace,
  fetchSubscriptionDetails: Function,
  ui: Object,
  updateCardAndFetchSubscription: Function,
  updateSubscription: Function,
  location: Object,
  push: Function
};

type State = {
  planDetails: Object,
  isFetchingPlanDetails: boolean
};

class WorkspaceSettingsPlanConfirm extends Component<Props, State> {
  state = {
    planDetails: {},
    isFetchingPlanDetails: false
  };

  componentDidMount() {
    const { currentWorkspace, fetchSubscriptionDetails, location } = this.props;

    if (currentWorkspace.stripe_cache_subscription_plan || currentWorkspace.stripe_has_card) {
      fetchSubscriptionDetails();
    }

    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const plan = params.get("plan");
      if (plan) {
        this.getPlanData(plan);
      }
    }
  }

  getPlanData = (plan: string) => {
    const slug = getSubdomain() || "";
    this.setState({
      isFetchingPlanDetails: true
    });

    request.get(`/workspaces/${slug}/stripe/${plan}`).then(response => {
      const planDetails = response.data;

      this.setState({
        planDetails,
        isFetchingPlanDetails: false
      });
    });
  };

  onToken = (tokenData: Object) => {
    const params = {
      token_data: tokenData
    };
    this.props.updateCardAndFetchSubscription(params);
  };

  handleConfirmation = e => {
    e.preventDefault();
    const { planDetails } = this.state;

    if (!planDetails.id) {
      return;
    }
    const params = {
      plan: planDetails.id
    };
    this.props.updateSubscription(params).then(action => {
      if (action.type === workspaceTypes.UPDATE_SUBSCRIPTION_COMPLETED) {
        this.props.push("/settings/plan/changed");
      }
    });
  };

  handleChange = e => handleChange(this, e);

  render() {
    // handleSubmit comes from redux-form module.
    // The ui props contains the state of the ui and is updated by the API call cycle
    const { currentWorkspace, ui } = this.props;
    const { isFetchingPlanDetails, planDetails } = this.state;
    const currentPlanID = currentWorkspace.stripe_cache_subscription_plan;
    const currentPlanIsAnnual = currentPlanID && currentPlanID.indexOf("annually") >= 0;

    let totalCost = 0;

    totalCost = planDetails.amount / 100;

    // Disable upgrade if we have already an annual subscription
    let upgradeDisabled = currentWorkspace.stripe_cache_subscription_status === "active" && currentPlanIsAnnual;

    // Disable if we're trying to upgrade the same plan
    if (currentWorkspace.stripe_cache_subscription_plan === planDetails.id) {
      upgradeDisabled = true;
    }

    if (!planDetails.metadata) {
      return (
        <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
          <Container>
            <Header>
              <h1>Confirm your new plan</h1>
            </Header>
            <Main>
              <Loader />
            </Main>
          </Container>
        </WorkspaceLayout>
      );
    }
    let pagesQuota = parseInt(planDetails.metadata.pages_quota);
    const notEnoughQuota = pagesQuota !== 0 && pagesQuota < currentWorkspace.active_projects_count;
    // This is the content of the submit button. Either text or a loader.
    const submitText = ui.isUpdatingSubscription ? <Loader size="small" /> : "Confirm subscription";

    const buttonDisabled = ui.isUpdatingCardDetails || ui.isUpdatingSubscription || upgradeDisabled || notEnoughQuota;

    const isRequesting = ui.isUpdatingCardDetails || ui.isFetchingStripeDetails;

    return (
      <WorkspaceLayout secondNav={<WorkspaceSettingNav />}>
        <Container>
          <Header>
            <h1>Confirm your new plan</h1>
          </Header>
          <Main>
            <FormFlag uiState={ui} />
            {isRequesting && <Loader />}
            {!currentWorkspace.stripe_card && !isRequesting && (
              <div>
                <h3>Payment details</h3>
                <p>Please add your payment details to be able to upgrade your plan.</p>
                <p>You will be able to confirm your subscription before being charged.</p>
                <StripeCheckout
                  name="Billing information"
                  allowRememberMe={false}
                  token={this.onToken}
                  stripeKey={process.env.REACT_APP_STRIPE_API_KEY}
                  billingAddress={true}
                  panelLabel="Add payment details"
                >
                  <button className="primary">Add payment details</button>
                </StripeCheckout>
              </div>
            )}
            {currentWorkspace.stripe_card && (
              <BillingContainer>
                <PlanDetails>
                  {isFetchingPlanDetails && <Loader size="small" />}
                  {!isFetchingPlanDetails && planDetails.id && (
                    <PlanContent>
                      <section>
                        <label>Plan</label>
                        <span>{planDetails.nickname}</span>
                      </section>
                      <section>
                        <label>Pages quota</label>
                        {pagesQuota !== 0 && (
                          <div>
                            <span>
                              Up to <b>{planDetails.metadata.pages_quota} active pages</b>
                            </span>
                            <br />
                            <span className="subtle">
                              You're currently using {currentWorkspace.active_projects_count} pages
                            </span>
                          </div>
                        )}
                        {pagesQuota === 0 && (
                          <div>
                            <span>Unlimited</span>
                            <br />
                            <span className="subtle">
                              You're currently using {currentWorkspace.active_projects_count} pages
                            </span>
                          </div>
                        )}
                      </section>
                      <section>
                        <label>Cost</label>
                        <div>
                          <span>${totalCost.toLocaleString()}</span>
                          {planDetails.metadata.frequency === "annual" && <span>/year</span>}
                          {planDetails.metadata.frequency === "monthly" && <span>/month</span>}
                        </div>
                      </section>
                      <section>
                        <div data-tip data-for="not-enough-quota">
                          <button className="primary" disabled={buttonDisabled} onClick={this.handleConfirmation}>
                            {submitText}
                          </button>
                        </div>
                        <Link className="button secondary" to="/settings/plan">
                          Cancel
                        </Link>
                      </section>
                      {notEnoughQuota && (
                        <ReactTooltip id={`not-enough-quota`} place="bottom" type="dark" effect="solid">
                          You need to select a plan with at least {currentWorkspace.active_projects_count} pages to
                          upgrade.
                        </ReactTooltip>
                      )}
                    </PlanContent>
                  )}
                </PlanDetails>
                <CardDetails>
                  {currentWorkspace.stripe_card && !isRequesting && (
                    <div className="details">
                      <p>
                        <b>{currentWorkspace.stripe_card.name}</b>
                        <br />
                        {currentWorkspace.stripe_customer.email}
                        <br />
                        {currentWorkspace.stripe_card.address_line1}
                        <br />
                        {currentWorkspace.stripe_card.address_zip}, {currentWorkspace.stripe_card.address_city}
                        <br />
                        {currentWorkspace.stripe_card.address_country}
                        <br />
                      </p>
                      <p>
                        <b>Credit card details</b>
                        <br />
                        Card ending with: ****{currentWorkspace.stripe_card.last4}
                        <br />
                        Card expiring: {currentWorkspace.stripe_card.exp_month}/{currentWorkspace.stripe_card.exp_year}
                        <br />
                        {currentWorkspace.stripe_card.address_country}
                        <br />
                      </p>
                      <StripeCheckout
                        name="Billing information"
                        allowRememberMe={false}
                        token={this.onToken}
                        stripeKey={process.env.REACT_APP_STRIPE_API_KEY}
                        billingAddress={true}
                        panelLabel="Add payment details"
                      >
                        <button>Change payment details</button>
                      </StripeCheckout>
                      <PoweredStripe>
                        <img src={StripeImg} alt="Powered by Stripe" />
                      </PoweredStripe>
                    </div>
                  )}
                </CardDetails>
              </BillingContainer>
            )}
          </Main>
        </Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentWorkspace: state.session.currentWorkspace,
  ui: state.ui.workspaceSettingsPlanConfirm
});

const mapDispatchToProps = {
  fetchSubscriptionDetails: workspacesOperations.fetchSubscriptionDetails,
  updateCardAndFetchSubscription: workspacesOperations.updateCardAndFetchSubscription,
  updateSubscription: workspacesOperations.updateSubscription,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettingsPlanConfirm);
