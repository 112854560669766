// @flow
import { createReducer } from "state/utils";
import * as sectionsTypes from "state/ducks/sections/types";
import * as goalsTypes from "state/ducks/goals/types";

const INITIAL_STATE = {
  isFetchingSection: true,
  isFetchingGoals: true
};

export default createReducer(INITIAL_STATE)({
  [sectionsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingSection: false
  }),
  [sectionsTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingSection: false
  }),
  [sectionsTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingSection: true
  }),
  [goalsTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingGoals: false
  }),
  [goalsTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingGoals: false
  }),
  [goalsTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingGoals: true
  })
});
