//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1.2rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 24 24" {...props}>
    <g
      id="Icon-dump"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="edit-2" transform="translate(1.000000, 1.000000)" stroke="#000000" strokeWidth="2">
        <polygon id="Shape" points="13 0 18 5 5 18 0 18 0 13" />
      </g>
    </g>
  </Logo>
);
