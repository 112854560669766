// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import { goalsOperations, goalsSelectors } from "state/ducks/goals";
import { tasksOperations } from "state/ducks/tasks";
import * as squadTypes from "squadTypes";
import styled from "styled-components";
import moment from "moment";

// V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// V2 Components
import Avatar from "v2/components/Avatar";
import EmptyProjectsTabby from "v2/components/_assets/empty_projects_tabby.png";
import GoalBlockPage from "v2/components/GoalBlockPage";
import IconPage from "v2/components/svg/IconPage";
import IconGoal from "v2/components/svg/IconGoal";
import IconNotify from "v2/components/svg/IconNotify";
import IconCheck from "v2/components/svg/IconCheck";
import IconTask from "v2/components/_assets/IconTask.png";
import Loader from "v2/components/Loader";
import WorkspaceLayout from "v2/components/WorkspaceLayout";

import { Link } from "react-router-dom";

const V2Container = styled.div`
  padding: ${spacing.x4};
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    padding: ${spacing.x2};
  }
`;

const TaskButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;

  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  margin-right: ${spacing.x1};
`;

const V2Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.x2};
`;

const V2HeaderLeft = styled.div`
  .user-profile {
    display: flex;
    align-items: center;
    margin-top: ${spacing.x1};

    a {
      margin-right: ${spacing.x1};
    }
  }
`;

const V2HeaderRight = styled.div`
  @media ${devices.tablet} {
    display: none;
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 10%;
    div {
      text-align: center;
    }
    img {
      height: 164px;
    }
    h3 {
      padding: ${spacing.x2} 0;
    }
  }
`;

const Main = styled.div`
  flex: 1;
  position: relative;
`;

const MainContent = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > div {
    margin-bottom: ${spacing.x4};

    @media ${devices.tablet} {
      margin-bottom: ${spacing.x2};
    }
  }

  .main-goals-container {
    flex: 4;
    overflow: auto;
    margin-right: ${spacing.x2};

    @media ${devices.tablet} {
      margin-right: 0;
    }
  }

  .main-meta-container {
    flex: 2;
    overflow: auto;
  }

  @media ${devices.tablet} {
    display: block;
    overflow: auto;
  }
`;

const GoalsContainer = styled.div`
  padding-left: 0;
  width: 100%;

  h3 {
    margin-bottom: ${spacing.x2};
  }
`;

const GoalWarning = styled.div`
  justify-self: flex-end;
  .tooltip {
    text-align: center;
  }
`;

const WarningMessage = styled.div`
  border-radius: 8px;
  background-color: ${colors.warningBg};
  border: 1px solid ${colors.yellow};
  display: flex;
  flex-direction: column;
  padding: ${spacing.x2} ${spacing.x3};
  margin-bottom: ${spacing.x2};
`;

const GoalsSection = styled.div`
  margin-bottom: ${spacing.x4};

  padding: ${spacing.x2};
  background: #fff;
  border: 1px solid ${colors.blockBorder};
  border-radius: 8px;

  .new-section {
    margin-top: ${spacing.x2};

    &:first-child {
      margin-top: 0;
    }
  }
`;

const WelcomePanel = styled.div`
  margin-bottom: ${spacing.x4};

  padding: ${spacing.x2};
  background: #fff;
  border-radius: 8px;
  border: 1px solid ${colors.blockBorder};

  h3 {
    margin-bottom: ${spacing.x2};
  }
  .welcome-subtitle {
    font-weight: bold;
    font-size: 1.2rem;
    color: #6a6a6a;
    margin-bottom: 0.8rem;
  }
  .welcome-content {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    margin-bottom: ${spacing.x1};

    span {
      display: inline-block;
      width: 2.4rem;
    }
  }
`;

const ProjectTitle = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: ${spacing.x2};

  a {
    color: ${colors.black};
  }

  span {
    display: inline-block;
    font-size: 1.4;
    font-weight: 500;
    color: ${colors.subtleText};
    margin-left: 0.4rem;
  }
`;

const Recent = styled.div`
  padding: ${spacing.x2};
  margin-bottom: ${spacing.x4};
  border: 1px solid ${colors.blockBorder};
  background-color: #fff;
  border-radius: 8px;

  h4 {
    margin: ${spacing.x2} 0 ${spacing.x1} 0;

    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    margin: ${spacing.x2} 0;
    li {
      padding: 0.4rem;
      border-radius: 4px;

      &:hover {
        background-color: #f3f3f3;
      }
      a {
        display: flex;
        align-items: flex-start;
        color: ${colors.black};

        span {
          flex: 1;
        }

        small {
          color: ${colors.subtleText};
          display: block;
        }

        svg.icon {
          width: 1.6rem;
          min-width: 1.6rem;
          margin-right: ${spacing.x1};
          position: relative;
          top: 2px;
          g {
            stroke: #8089a2;
          }
        }
      }
    }
  }

  p {
    padding: ${spacing.x2} 0 0 0;
    margin: 0;
  }
`;

type Props = {
  currentUser: squadTypes.User,
  currentWorkspace: squadTypes.Workspace,
  currentMembership: squadTypes.Membership,
  fetchMembershipGoalList: Function,
  fetchMembershipTaskList: Function,
  goals: squadTypes.NormalizedList<squadTypes.Goal>,
  weeklyGoals: Array<squadTypes.Goal>,
  pendingGoals: Array<squadTypes.Goal>,
  monthlyGoals: Array<squadTypes.Goal>,
  quarterlyGoals: Array<squadTypes.Goal>,
  logout: Function,
  projects: squadTypes.NormalizedList<squadTypes.Project>,
  tasks: squadTypes.NormalizedList<squadTypes.Task>,
  subscriptions: squadTypes.NormalizedList<squadTypes.Subscription>,
  goalSubscriptions: squadTypes.NormalizedList<squadTypes.GoalSubscription>,
  resetGoalList: Function,
  resetTaskList: Function,
  recentlyVisited: Array<Object>,
  showModal: Function,
  ui: Object
};

type State = {};

class Home extends Component<Props, State> {
  componentDidMount() {
    const { currentMembership, fetchMembershipGoalList, fetchMembershipTaskList } = this.props;

    // Reset the list of goals and tasks
    this.props.resetGoalList();
    this.props.resetTaskList();

    // Fetch the list of goals and tasks for the member
    fetchMembershipGoalList(currentMembership.id);
    fetchMembershipTaskList(currentMembership.id);
  }

  showProjectNewForm = (e: Object) => {
    e.preventDefault();
    this.props.showModal({
      contentType: "project",
      contentAction: "new"
    });
  };

  showInviteForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "membership",
      contentAction: "new"
    });
  };

  displayGoals = (goals, frequency) => {
    if (!goals[0].project) {
      return false;
    }

    let prevProjectId = null;
    let prevSectionId = null;
    let projectTitle = null;
    let sectionTitle = null;
    let blockClass = "";
    /**
     * In the script below we iterate on the goals and display the corresponding project / section if necessary
     */
    return (
      <div>
        <h3>Your {frequency} check-ins</h3>
        <GoalsSection>
          {goals.map(goal => {
            if (!goal.project) {
              return false;
            }
            // If current project id is different from prevProjectId
            // - set prevProjectId to currentProjectId
            // - set projectTitle to currentProjectTitle
            // Otherwise
            // - set projectTitle to null
            if (goal.project_id !== prevProjectId) {
              prevProjectId = goal.project_id;
              projectTitle = goal.project.title;
              blockClass = "new-section";
            } else {
              projectTitle = null;
              blockClass = "";
            }

            // If current section id is different from prevSectionId
            // - set prevSectionId to currentSectionId
            // - set projectTitle to currentSectionTitle
            // Otherwise
            // - set sectionTitle to null
            if (goal.section_id !== prevSectionId && goal.project.sections_count > 1) {
              prevSectionId = goal.section_id;
              if (goal.section) {
                projectTitle = goal.project.title;
                sectionTitle = goal.section.title;
                blockClass = "new-section";
              } else {
                sectionTitle = null;
                blockClass = "";
              }
            } else {
              sectionTitle = null;
            }

            // Display the goal, with project and section if necessary
            return (
              <div key={goal.id} className={blockClass}>
                {projectTitle && (
                  <ProjectTitle>
                    <Link to={`/pages/${goal.project_id}`}>{projectTitle}</Link>
                    {sectionTitle && <span>/</span>}
                    {sectionTitle && <span>{sectionTitle}</span>}
                  </ProjectTitle>
                )}
                <GoalBlockPage key={goal.id} goal={goal} />
              </div>
            );
          })}
        </GoalsSection>
      </div>
    );
  };

  render() {
    const {
      currentUser,
      currentMembership,
      currentWorkspace,
      goals,
      tasks,
      pendingGoals,
      weeklyGoals,
      monthlyGoals,
      quarterlyGoals,
      subscriptions,
      goalSubscriptions,
      ui
    } = this.props;
    const over_80prct_limit =
      currentWorkspace.monthly_status_updates_limit > 0
        ? currentWorkspace.current_month_status_updates_count / currentWorkspace.monthly_status_updates_limit > 0.8
        : false;
    const updates_left =
      currentWorkspace.monthly_status_updates_limit - currentWorkspace.current_month_status_updates_count;

    let hasWorkToDo = false;

    // If we have finished checking the tasks and goals, have a look at the content to see if there's work to do
    if (!ui.isFetchingGoals && !ui.isFetchingTasks) {
      // User has work if they have pending goals
      if (goals.total > 0 && pendingGoals.length > 0) {
        hasWorkToDo = true;
      }
      if (tasks.total > 0) {
        hasWorkToDo = true;
      }
    }

    // V2 stuff
    const name = currentUser.fullname || currentUser.email;

    return (
      <WorkspaceLayout>
        <V2Container>
          <V2Header>
            <V2HeaderLeft>
              <h1>Your dashboard</h1>
              <div className="user-profile">
                <Link to={`/team/${currentMembership.id}`}>
                  <Avatar user={currentUser} size={32} />
                </Link>
                <Link to={`/team/${currentMembership.id}`} className="subtle">
                  {name}
                </Link>
              </div>
            </V2HeaderLeft>
            <V2HeaderRight>
              <button className="primary-outline" onClick={this.showInviteForm}>
                + Invite your team
              </button>
            </V2HeaderRight>
          </V2Header>
          {currentWorkspace.projects_count === 0 && (
            <EmptyState>
              <div>
                <div>
                  <img src={EmptyProjectsTabby} alt="Your workspace doesn't have any pages" />
                </div>
                <h3>Create your first page to get started</h3>
                <button onClick={this.showProjectNewForm} className="primary">
                  Create page
                </button>
              </div>
            </EmptyState>
          )}
          {currentWorkspace.projects_count > 0 && (
            <Main>
              <MainContent>
                <div className="main-goals-container">
                  {over_80prct_limit && !currentWorkspace.is_read_only && (
                    <WarningMessage>
                      <p>Hi, you have used more than 80% of your monthly status updates allowance.</p>
                      <p>
                        You have {updates_left} update{updates_left > 1 && "s"} left for this month.
                      </p>
                      <p>
                        You can <a href={`${currentWorkspace.url}/settings/billing`}>upgrade your subscription</a> to
                        remove that restriction.
                      </p>
                    </WarningMessage>
                  )}
                  {currentWorkspace.is_read_only && (
                    <WarningMessage>
                      <p>
                        Hi, you have exceeded the limit of{" "}
                        <b>{currentWorkspace.monthly_status_updates_limit} status updates/month</b> for your workspace.
                        You have made <b>{currentWorkspace.current_month_status_updates_count} status updates</b> this
                        month.
                      </p>
                      <p>
                        You can still see all the data in your workspace but <b>new updates are restricted</b>. You can{" "}
                        <a href={`${currentWorkspace.url}/settings/billing`}>upgrade your subscription</a> to remove
                        that restriction.
                      </p>
                    </WarningMessage>
                  )}
                  {!ui.FetchingGoals && !ui.isFetchingTasks && (
                    <WelcomePanel>
                      <h3>
                        {moment()
                          .locale(currentWorkspace.time_zone)
                          .format("D MMMM YYYY")}
                      </h3>
                      <div className="welcome-subtitle">THIS WEEK</div>
                      {goals.total > 0 && pendingGoals.length > 0 && (
                        <div className="welcome-content">
                          <span>
                            <IconNotify />
                          </span>
                          You have {pendingGoals.length} check-ins to complete below.
                        </div>
                      )}
                      {hasWorkToDo === false && goals.total > 0 && (
                        <div className="welcome-content">
                          <span>
                            <IconCheck className="grey" />
                          </span>
                          Everything is up to date!
                        </div>
                      )}
                      {tasks.total > 0 && (
                        <div className="welcome-content">
                          <TaskButton bgImage={IconTask} />
                          You have {tasks.total} tasks to complete below.
                        </div>
                      )}
                      {goals.total === 0 && <div className="welcome-content">You don't have any goals yet.</div>}
                    </WelcomePanel>
                  )}
                  {ui.isFetchingGoals && <Loader />}
                  {goals.total > 0 && (
                    <GoalsContainer>
                      {weeklyGoals.length > 0 && this.displayGoals(weeklyGoals, "weekly")}
                      {monthlyGoals.length > 0 && this.displayGoals(monthlyGoals, "monthly")}
                      {quarterlyGoals.length > 0 && this.displayGoals(quarterlyGoals, "quarterly")}
                    </GoalsContainer>
                  )}
                </div>
                <div className="main-meta-container">
                  <Recent>
                    <h3>Watched goals</h3>
                    {goalSubscriptions.allIds.length === 0 && <p>You haven't subscribed to a goal yet.</p>}
                    {goalSubscriptions.allIds.length > 0 && (
                      <ul>
                        {goalSubscriptions.allIds.map(gsId => {
                          const goalSubscription = goalSubscriptions.byId[gsId];
                          if (!goalSubscription || !goalSubscription.goal) {
                            return false;
                          }
                          const { goal } = goalSubscription;
                          return (
                            <li key={gsId} className="flex-start">
                              <Link to={`/pages/${goal.project_id}/${goal.id}`}>
                                <IconGoal className="icon" />
                                <span>{goal.title}</span>
                                {goal.is_pending_update && !goal.is_overdue && goal.state === "open" && (
                                  <GoalWarning aria-label="warning">
                                    <IconNotify />
                                  </GoalWarning>
                                )}
                                {goal.is_overdue && goal.state === "open" && (
                                  <GoalWarning role="img" aria-label="warning">
                                    <IconNotify color={colors.black} />
                                  </GoalWarning>
                                )}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </Recent>
                  <Recent>
                    <h3>Watched pages</h3>
                    {subscriptions.allIds.length === 0 && <p>You haven't subscribed to a page yet.</p>}
                    {subscriptions.allIds.length > 0 && (
                      <ul>
                        {subscriptions.allIds.map(gsId => {
                          const subscription = subscriptions.byId[gsId];
                          if (!subscription || !subscription.project) {
                            return false;
                          }
                          const { project } = subscription;
                          return (
                            <li key={gsId} className="flex-start">
                              <Link to={`/pages/${project.id}`}>
                                <IconPage className="icon" />
                                <div>
                                  <span>{project.title}</span>
                                  {project.path && <small className="light">{project.path.label}</small>}
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </Recent>
                </div>
              </MainContent>
            </Main>
          )}
        </V2Container>
      </WorkspaceLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentWorkspace: state.session.currentWorkspace,
    currentUser: state.session.currentUser,
    currentMembership: state.session.currentMembership,
    goals: state.goals,
    pendingGoals: goalsSelectors.getListByParam(state.goals.byId, "is_pending_update", true),
    weeklyGoals: goalsSelectors.getListByParam(state.goals.byId, "update_frequency", "weekly"),
    monthlyGoals: goalsSelectors.getListByParam(state.goals.byId, "update_frequency", "monthly"),
    quarterlyGoals: goalsSelectors.getListByParam(state.goals.byId, "update_frequency", "quarterly"),
    projects: state.projects,
    tasks: state.tasks,
    ui: state.ui.workspaceHome,
    subscriptions: state.subscriptions,
    goalSubscriptions: state.goalSubscriptions
  };
};

const mapDispatchToProps = {
  fetchMembershipGoalList: goalsOperations.fetchMembershipGoalList,
  fetchMembershipTaskList: tasksOperations.fetchMembershipTaskList,
  resetGoalList: goalsOperations.resetGoalList,
  resetTaskList: tasksOperations.resetTaskList,
  showModal: uiOperations.showModal,
  logout: sessionOperations.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
