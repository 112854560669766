//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { push } from "react-router-redux";
import * as squadTypes from "squadTypes";
import { statusUpdatesOperations, statusUpdatesSelectors } from "state/ducks/statusUpdates";
import { statusUpdateViewsOperations } from "state/ducks/statusUpdateViews";
import { tasksOperations } from "state/ducks/tasks";
import { goalsOperations } from "state/ducks/goals";
import { reactionsOperations } from "state/ducks/reactions";
import { sessionOperations } from "state/ducks/session";
import { uiOperations } from "state/ducks/ui";
import * as goalTypes from "state/ducks/goals/types";

//V2 styling
import { colors } from "v2/components/styles/colors";
import { devices } from "v2/components/styles/devices";
import { spacing } from "v2/components/styles/spacing";

// Components
import Avatar from "v2/components/Avatar";
import ButtonIcon from "v2/components/ButtonIcon";
import DropdownMenu from "v2/components/DropdownMenu";
import GoalSubscription from "./GoalSubscription";
import IconClose from "v2/components/svg/IconClose";
import IconMenu from "v2/components/svg/IconMenu";
import ButtonIconLink from "v2/components/ButtonIconLink";
import Loader from "v2/components/Loader";
import Markdown from "v2/components/Markdown";
import StatusBox from "v2/components/StatusBox";

// Cards
import FirstUpdateCard from "./FirstUpdateCard";
import StatusUpdateCard from "./StatusUpdateCard";
import JustCreatedCard from "./JustCreatedCard";
import OverdueCard from "./OverdueCard";
import GoalDependencies from "./GoalDependencies";
import UpdatePendingCard from "./UpdatePendingCard";

// Charts
import StatusUpdatesLinearChartVictory from "./StatusUpdatesLinearChartVictory";
import StatusUpdatesStayAboveChartVictory from "./StatusUpdatesStayAboveChartVictory";
import StatusUpdatesStayBelowChartVictory from "./StatusUpdatesStayBelowChartVictory";
import StatusUpdatesNoTargetChartVictory from "./StatusUpdatesNoTargetChartVictory";
import Links from "./Links";
import TaskList from "v2/components/TaskList";
import Tags from "./Tags";
import ReactTooltip from "react-tooltip";

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: ${spacing.x4} 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  border: 1px solid ${colors.blockBorder};
  background-color: #fff;
  border-radius: 8px;

  .goal-details-columns-wrapper {
    display: flex;
    flex: 1;
    overflow: auto;
  }
  .goal-details-main {
    display: flex;
    flex: 3;
    min-width: 45rem;
    flex-direction: column;
    border-right: 1px solid ${colors.blockBorder};
  }

  .goal-details-meta {
    padding: ${spacing.x2};
    min-width: 29rem;
    width: 35rem;
    flex: 2;
    h3 {
      margin: ${spacing.x4} 0 ${spacing.x2} 0;
    }
  }

  @media ${devices.tablet} {
    .goal-details-columns-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: auto;
    }
    .goal-details-main {
      display: flex;
      min-width: 100%;
      flex-direction: column;
      border-right: 1px solid ${colors.blockBorder};
    }

    .goal-details-meta {
      border-top: 1px solid ${colors.blockBorder};
      margin-top: ${spacing.x2};
      padding: 0 ${spacing.x2} ${spacing.x2} ${spacing.x2};
      min-width: 100%;
      width: 100%;
      h3 {
        margin: ${spacing.x4} 0 ${spacing.x2} 0;
      }
    }
  }

  .goal-header {
    position: relative;

    .title-closed {
      text-decoration: line-through;
    }

    h2 {
      display: flex;
      align-items: center;
      a {
        margin-left: 0;
        color: ${colors.black};
      }
    }

    .progress {
      display: flex;
      align-items: center;
      span {
        margin-left: ${spacing.x1};
      }
    }
  }
`;

const HeaderWrapper = styled.div`
  padding: ${spacing.x2} ${spacing.x2} ${spacing.x1} ${spacing.x2};
  border-bottom: 1px solid ${colors.blockBorder};

  .primary {
    margin-right: ${spacing.x1};
  }
`;

const Wrapper = styled.div`
  padding: ${spacing.x2} ${spacing.x2} 0 ${spacing.x2};

  h3 {
    margin-bottom: ${spacing.x2};
  }
`;

const TopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .status {
    display: flex;
    align-items: center;
  }

  div.actions {
    display: flex;
    align-items: center;
  }

  .goal-subscription button {
    margin-right: ${spacing.x1};
  }

  position: relative;

  @media ${devices.laptop} {
    flex-direction: column;
    align-items: flex-start;

    .actions {
      margin-top: ${spacing.x1};
    }
  }
`;

const MetaAvatar = styled.div`
  padding: 0;
  margin: 0 0 ${spacing.x2} 0;

  a {
    color: ${colors.black};
  }
  .avatar {
    display: flex;
    align-items: center;
  }

  .avatar-link {
    margin-left: ${spacing.x1};
  }
`;

const Meta = styled.div`
  padding: 0;

  a {
    color: ${colors.black};
  }
  .avatar {
    display: flex;
    align-items: center;
  }

  .avatar-link {
    margin-left: ${spacing.x1};
  }
`;

const UpdateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-left: ${spacing.x1};
  }
`;

const MetaDetails = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: ${spacing.x2};
  a {
    color: ${colors.black};
  }

  > div {
    flex: 1;
  }
`;

const Updates = styled.div`
  .empty-state {
    margin-top: ${spacing.x1};
  }
`;

const LinkLabel = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-right: 0.8rem;

  span {
    &:nth-child(2) {
      font-size: 1.4rem;
      color: #6a6a6a;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 ${spacing.x1};
      background-color: #eaeaea;
      border-radius: 4px;
    }
  }
`;

const RecentAvatarsWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.subtleText};
  font-weight: 400;
  font-size: 1.6rem;

  span {
    display: inline-block;
    margin-left: ${spacing.x1};
  }
`;

const RecentAvatars = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row-reverse;
  position: relative;
  right: 5px;
  margin-left: ${spacing.x1};
  > div {
    width: 22px;
    > div {
      min-width: 27px;
      border: 2px solid #fff;
    }
  }
`;

type Props = {
  createReactionForGoal: Function,
  currentGoal: squadTypes.Goal,
  currentStatusUpdates: Array<squadTypes.StatusUpdate>,
  currentWorkspace: squadTypes.Workspace,
  deleteGoal: Function,
  deleteReaction: Function,
  fetchGoalDetails: Function,
  fetchStatusUpdateList: Function,
  fetchStatusUpdateViewList: Function,
  goal: squadTypes.Goal,
  match: Object,
  logVisit: Function,
  updateGoal: Function,
  resetTaskList: Function,
  resetStatusUpdateViewList: Function,
  statusUpdateViews: Object,
  showModal: Function,
  push: Function,
  ui: Object,
  viewOnly?: boolean
};

type State = {
  displayStatusUpdateNewForm: boolean,
  displayLinkInput: boolean
};

class GoalDetails extends Component<Props, State> {
  focusInput: any;
  state = {
    displayStatusUpdateNewForm: false,
    displayLinkInput: false
  };

  // Goal Menu reference
  goalMenu: any;

  componentDidMount() {
    const {
      goal,
      logVisit,
      match,
      fetchGoalDetails,
      fetchStatusUpdateList,
      fetchStatusUpdateViewList,
      resetTaskList,
      resetStatusUpdateViewList
    } = this.props;
    resetTaskList();
    fetchGoalDetails(goal.id);
    fetchStatusUpdateList(goal.id);
    if (goal.last_status_update_id) {
      resetStatusUpdateViewList();
      fetchStatusUpdateViewList(goal.last_status_update_id, { ignore_author: true, ignore_current_user: true });
    }
    if (match && match.path === "/pages/:project/:goal/comments") {
      this.showComments();
    }
    // Log the visit
    if (goal) {
      document.title = `${goal.title} | Tability`;
      const page = {
        pageType: "goal",
        pageTitle: goal.title,
        pageUrl: `/pages/${goal.project_id}/${goal.id}`
      };
      logVisit(page);
    }
  }

  componentDidUpdate(prevProps) {
    // Reload if we switched goals
    if (prevProps.goal.id !== this.props.goal.id) {
      const {
        goal,
        fetchGoalDetails,
        fetchStatusUpdateList,
        fetchStatusUpdateViewList,
        resetTaskList,
        resetStatusUpdateViewList
      } = this.props;
      resetTaskList();
      fetchGoalDetails(goal.id);
      fetchStatusUpdateList(goal.id);
      if (goal.last_status_update_id) {
        resetStatusUpdateViewList();
        fetchStatusUpdateViewList(goal.last_status_update_id, { ignore_author: true, ignore_current_user: true });
      }
      if (goal) {
        document.title = `${goal.title} | Tability`;
        const page = {
          pageType: "goal",
          pageTitle: goal.title,
          pageUrl: `/pages/${goal.project_id}/${goal.id}`
        };
        this.props.logVisit(page);
      }
    }
  }

  toggleCloseGoal = e => {
    e.preventDefault();
    const { goal, updateGoal } = this.props;
    if (goal) {
      const state = goal.state === "open" ? "closed" : "open";
      const params = { state };
      updateGoal(goal.id, params);
    }
  };

  showStatusUpdateNewForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "statusUpdate",
      contentAction: "new",
      parent: this.props.goal
    });
    this.setState({ displayStatusUpdateNewForm: true });
  };

  deleteGoal = e => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to delete this goal?`)) {
      const id = e.currentTarget.dataset.id;
      this.props.deleteGoal(id).then(action => {
        if (action.type === goalTypes.DELETE_COMPLETED) {
          this.props.push(`/pages/${this.props.goal.project_id}`);
        }
      });
    }
  };

  showGoalEditForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "goal",
      contentAction: "edit",
      object: this.props.goal
    });
  };

  showMoveGoalForm = e => {
    e.preventDefault();
    this.props.showModal({
      contentType: "goal",
      contentAction: "move",
      object: this.props.goal
    });
  };

  closeGoal = (e: Object) => {
    const { goal, updateGoal } = this.props;
    e.preventDefault();

    const params = {
      state: "closed"
    };

    updateGoal(goal.id, params);
  };

  openGoal = (e: Object) => {
    const { goal, updateGoal } = this.props;
    e.preventDefault();

    const params = {
      state: "open"
    };

    updateGoal(goal.id, params);
  };

  getMenuItems = () => {
    const { goal, viewOnly } = this.props;
    const items = [];
    if (goal.state === "open") {
      items.push(
        <button className="link-button" onClick={this.closeGoal}>
          Close goal
        </button>
      );
    }
    if (goal.state === "closed") {
      items.push(
        <button className="link-button" onClick={this.openGoal}>
          Re-open goal
        </button>
      );
    }
    if (!viewOnly) {
      items.push(
        <button className="link-button" onClick={this.showGoalEditForm}>
          Edit
        </button>
      );
      items.push(
        <button className="link-button" onClick={this.showMoveGoalForm}>
          Move
        </button>
      );
      items.push(
        <button className="link-button" data-id={goal.id} onClick={this.deleteGoal}>
          Delete
        </button>
      );
    }

    return items;
  };

  // REACTIONS ACTIONS

  showComments = (e?: Object) => {
    if (e) {
      e.preventDefault();
    }
    this.props.showModal({
      contentType: "goal.comments",
      contentAction: "view",
      object: this.props.goal,
      centered: false
    });
  };

  addReaction = (e: Object) => {
    e.preventDefault();
    const { goal } = this.props;
    this.props.createReactionForGoal(goal.id, {}).then(() => {
      this.props.fetchGoalDetails(goal.id);
    });
  };

  deleteReaction = (e: Object) => {
    e.preventDefault();
    const { goal } = this.props;
    this.props.deleteReaction(goal.reactionable_id).then(() => {
      this.props.fetchGoalDetails(goal.id);
    });
  };

  renderGoalHeader = () => {
    const { currentGoal } = this.props;

    return (
      <div className="goal-header">
        {currentGoal.project && (
          <b>
            <small className="subtle">{currentGoal.project.title}</small>
          </b>
        )}
        {currentGoal.section && (
          <b>
            &nbsp;<small>/</small>&nbsp;
          </b>
        )}
        {currentGoal.section && (
          <b>
            <small className="subtle">{currentGoal.section.title}</small>
          </b>
        )}
        <h2>
          <StatusBox className={`${currentGoal.cached_color} ${currentGoal.state}`} />

          <Link to={`/pages/${currentGoal.project_id}/${currentGoal.id}`} className={`title-${currentGoal.state}`}>
            {currentGoal.title}
          </Link>
        </h2>
      </div>
    );
  };

  _getLinkLabel = (goal: squadTypes.Goal) => {
    if (goal.links_count === 0) {
      return (
        <LinkLabel>
          <span>Links</span>
        </LinkLabel>
      );
    } else {
      return (
        <LinkLabel>
          <span>Links</span>
          <span>{goal.links_count}</span>
        </LinkLabel>
      );
    }
  };

  _getTagLabel = (goal: squadTypes.Goal) => {
    if (!goal.tags || goal.tags.length === 0) {
      return (
        <LinkLabel>
          <span>Tags</span>
        </LinkLabel>
      );
    } else {
      return (
        <LinkLabel>
          <span>Tags</span>
          <span>{goal.tags.length}</span>
        </LinkLabel>
      );
    }
  };

  _showRecentViews = (e?: Object) => {
    if (e) {
      e.preventDefault();
    }
    this.props.showModal({
      contentType: "goal",
      contentAction: "statusUpdate.views",
      object: this.props.goal,
      centered: false
    });
  };

  _getRecentViews = () => {
    const { statusUpdateViews } = this.props;

    if (statusUpdateViews.total === 0) {
      return false;
    }
    const maxToDisplay = 3;

    const slicedIds = statusUpdateViews.allIds.slice(0, maxToDisplay);

    const tooltipPeople = statusUpdateViews.total === 1 ? "teammate has" : "teammates have";

    return (
      <RecentAvatarsWrapper>
        <span>Read by&nbsp;&nbsp;</span>
        <RecentAvatars data-tip data-for="recent-views" onClick={this._showRecentViews}>
          {slicedIds.map(id => {
            const view = statusUpdateViews.byId[id];
            return (
              <div key={id}>
                <Avatar user={view.user} key={id} hideName={true} pictureOnly={true} size={27} />
              </div>
            );
          })}
        </RecentAvatars>
        <ReactTooltip id="recent-views" place="bottom" type="dark" effect="solid" delayShow={100}>
          {statusUpdateViews.total} {tooltipPeople} seen the most recent update
        </ReactTooltip>
        {statusUpdateViews.total > maxToDisplay && <span>+{statusUpdateViews.total - maxToDisplay}</span>}
      </RecentAvatarsWrapper>
    );
    //{statusUpdateViews.map(view => {
    //  return (
    //    <Avatar key={view.id} user={view.user} hideName={true} size={25} />
    //  )
    //})}
  };

  render() {
    const { goal, currentStatusUpdates, currentWorkspace, ui } = this.props;
    const { owner } = goal;
    let targetWithFormat, initialWithFormat;
    //let progressPrct = "";
    if (goal.target) {
      targetWithFormat = goal.score_format.replace("_target_", parseFloat(goal.target).toLocaleString("en-US"));
      initialWithFormat = goal.score_format.replace("_target_", parseFloat(goal.initial_value).toLocaleString("en-US"));
      //if (goal.cached_score) {
      //  progressPrct = Math.round((goal.cached_score / goal.target) * 100).toLocaleString("en-US");
      //}
    } else {
      targetWithFormat = "";
    }

    const updates = currentStatusUpdates.sort((a, b) => {
      return a.update_date < b.update_date ? 1 : b.update_date < a.update_date ? -1 : 0;
    });

    const closePath =
      window.location.pathname.indexOf("/goals") >= 0 ? window.location.pathname : `/pages/${goal.project_id}`;

    if (ui.isFetchingGoal && !goal) {
      return (
        <Container>
          <Wrapper>
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          </Wrapper>
        </Container>
      );
    }

    let prctProgress = 0;
    // Set the progress score using the format if provided.
    if (goal.score_format && goal.target) {
      if (goal.cached_score || goal.cached_score === 0) {
        prctProgress = goal.prct_target_normalized * 100;
      }
    }

    let progressColor = colors.grey;
    switch (goal.cached_color) {
      case "red":
        progressColor = colors.red;
        break;
      case "yellow":
        progressColor = colors.yellow;
        break;
      case "green":
        progressColor = colors.green;
        break;
      case "grey":
        progressColor = colors.grey;
        break;
      default:
        progressColor = colors.grey;
    }

    const Progress = styled.div`
      flex: 1;
      height: 0.8rem;
      background-color: #e9e9e9;
      border-radius: 4px;
      overflow: hidden;
      margin-left: ${spacing.x1};
    `;

    const ProgressBar = styled.div`
      height: 0.8rem;
      width: ${Math.round(prctProgress)}%;
      background-color: ${progressColor};
      overflow: hidden;
      border-radius: 4px;
    `;

    const ProgressWrapper = styled.div`
      display: flex;
      align-items: center;
      span {
        margin-right: ${spacing.x2};
        font-size: 1.6rem;
      }
      padding: ${spacing.x2} ${spacing.x2} 0 ${spacing.x2};
    `;

    return (
      <Container>
        <HeaderWrapper>
          <TopHeader>
            {this.renderGoalHeader()}
            <div className="actions">
              {goal.state === "open" && (
                <button className="primary" onClick={this.showStatusUpdateNewForm}>
                  Update progress
                </button>
              )}
              <GoalSubscription currentGoal={goal} className="goal-subscription" />
              <DropdownMenu
                trigger={
                  <ButtonIcon>
                    <IconMenu />
                  </ButtonIcon>
                }
                items={this.getMenuItems()}
              />
              <ButtonIconLink to={closePath} style={{ marginLeft: spacing.x1 }} className="hide-laptop">
                <IconClose style={{ width: "1.2rem" }} />
              </ButtonIconLink>
            </div>
          </TopHeader>
        </HeaderWrapper>
        <div className="goal-details-columns-wrapper">
          <div className="goal-details-main">
            {goal.goal_type === "linear" && goal.score_format && goal.target !== null && (
              <Wrapper>
                <StatusUpdatesLinearChartVictory goal={goal} statusUpdates={currentStatusUpdates} />
              </Wrapper>
            )}
            {goal.goal_type === "stay_above" && goal.score_format && goal.target !== null && (
              <Wrapper>
                <StatusUpdatesStayAboveChartVictory goal={goal} statusUpdates={currentStatusUpdates} />
              </Wrapper>
            )}
            {goal.goal_type === "stay_below" && goal.score_format && goal.target !== null && (
              <Wrapper>
                <StatusUpdatesStayBelowChartVictory goal={goal} statusUpdates={currentStatusUpdates} />
              </Wrapper>
            )}
            {goal.goal_type === "no_target" && (
              <Wrapper>
                <StatusUpdatesNoTargetChartVictory goal={goal} statusUpdates={currentStatusUpdates} />
              </Wrapper>
            )}
            {(goal.target || goal.target === 0) && (
              <ProgressWrapper>
                <span>{Math.round(prctProgress)}%</span>
                <Progress>
                  <ProgressBar />
                </Progress>
              </ProgressWrapper>
            )}
            <Wrapper className="grey_bg">
              <Updates>
                <UpdateHeader>
                  <h3>Activity</h3>
                  <div>{this._getRecentViews()}</div>
                </UpdateHeader>
                {this.props.ui.isFetchingStatusUpdates && <Loader />}
                {!this.props.ui.isFetchingStatusUpdates && (
                  <div style={{ paddingBottom: spacing.x2 }}>
                    {goal.state === "open" && updates.length === 0 && !goal.is_pending_update && !goal.is_overdue && (
                      <JustCreatedCard
                        goal={goal}
                        workspace={currentWorkspace}
                        updateGoal={this.showStatusUpdateNewForm}
                      />
                    )}
                    {goal.is_pending_update && goal.state === "open" && !goal.is_overdue && (
                      <UpdatePendingCard
                        goal={goal}
                        closeGoal={this.closeGoal}
                        updateGoal={this.showStatusUpdateNewForm}
                      />
                    )}
                    {goal.is_overdue && goal.state === "open" && <OverdueCard goal={goal} closeGoal={this.closeGoal} />}
                    {updates.map((statusUpdate, index) => {
                      const isLatest = index === 0;
                      return (
                        <StatusUpdateCard
                          latest={isLatest}
                          key={statusUpdate.id}
                          goal={goal}
                          statusUpdate={statusUpdate}
                        />
                      );
                    })}
                    <FirstUpdateCard goal={goal} />
                  </div>
                )}
              </Updates>
            </Wrapper>
          </div>
          <div className="goal-details-meta">
            <h3 className="show-tablet">Goal details</h3>
            <MetaAvatar>
              <div className="label">Owner</div>
              <div className="avatar">
                <Avatar user={owner} hideName={true} size={25} />
                &nbsp;
                {owner && (
                  <Link to={`/team/${goal.membership_id}`} className="avatar-link">
                    {owner.fullname || owner.email}
                  </Link>
                )}
                {!owner && <span className="light">Account deleted</span>}
              </div>
            </MetaAvatar>
            {goal.description && (
              <Meta>
                <MetaDetails>
                  <div>
                    <div className="label">Description:</div>
                    <Markdown source={goal.description} />
                  </div>
                </MetaDetails>
              </Meta>
            )}
            <Meta>
              <MetaDetails>
                {goal.goal_type === "linear" && (
                  <div>
                    <div className="label">Initial value:</div>
                    {initialWithFormat}
                  </div>
                )}
                {targetWithFormat && (
                  <div>
                    <div className="label">Target:</div>
                    {targetWithFormat}
                  </div>
                )}
              </MetaDetails>
              <MetaDetails>
                <div>
                  <div className="label">Start date</div>
                  {moment(goal.start_date)
                    .utc()
                    .format("D MMM YYYY")}
                </div>
                {goal.deadline && (
                  <div>
                    <div className="label">Deadline</div>
                    {moment(goal.deadline)
                      .utc()
                      .format("D MMM YYYY")}
                  </div>
                )}
              </MetaDetails>
            </Meta>
            <TaskList goal={goal} />
            {(goal.count_of_children_goals > 0 || goal.parent_goal_id) && <h3>Related items</h3>}
            {(goal.count_of_children_goals > 0 || goal.parent_goal_id) && <GoalDependencies goal={goal} />}
            <h3>Tags</h3>
            <Tags goal={goal} />
            <h3>Links</h3>
            <Links goal={goal} />
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentGoal: state.goals.byId[props.goal.id],
  currentStatusUpdates: statusUpdatesSelectors.getListByParentId(state.statusUpdates.byId, "goal", props.goal.id),
  statusUpdateViews: state.statusUpdateViews,
  currentWorkspace: state.session.currentWorkspace,
  ui: state.ui.goalDetails
});

const mapDispatchToProps = {
  createReactionForGoal: reactionsOperations.createReactionForGoal,
  deleteGoal: goalsOperations.deleteGoal,
  deleteReaction: reactionsOperations.deleteReaction,
  fetchGoalDetails: goalsOperations.fetchGoalDetails,
  updateGoal: goalsOperations.updateGoal,
  logVisit: sessionOperations.logVisit,
  fetchStatusUpdateList: statusUpdatesOperations.fetchStatusUpdateList,
  fetchStatusUpdateViewList: statusUpdateViewsOperations.fetchStatusUpdateViewList,
  resetStatusUpdateViewList: statusUpdateViewsOperations.resetStatusUpdateViewList,
  resetTaskList: tasksOperations.resetTaskList,
  showModal: uiOperations.showModal,
  push
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoalDetails));
