//@flow
import * as types from "./types";

export const clearGoogleLogin = () => ({
  type: types.CLEAR_GOOGLE_AUTH
});

export const clearPage = () => ({
  type: types.CLEAR_PAGE
});

export const clearSessionConfirm = () => ({
  type: types.CLEAR_SESSION_CONFIRM
});

export const clearSessionLogin = () => ({
  type: types.CLEAR_SESSION_LOGIN
});

export const clearSessionNewPassword = () => ({
  type: types.CLEAR_SESSION_NEW_PASSWORD
});

export const clearSessionResetPassword = () => ({
  type: types.CLEAR_SESSION_RESET_PASSWORD
});

export const clearSessionSignup = () => ({
  type: types.CLEAR_SESSION_SIGNUP
});

type ModalProps = {
  contentAction: string,
  contentType: string,
  object?: Object,
  parent?: Object,
  centered?: boolean
};
export const showModal = ({ contentType, contentAction, object, parent, centered }: ModalProps) => ({
  type: types.SHOW_MODAL,
  contentType,
  contentAction,
  object,
  parent,
  centered
});

export const hideModal = () => ({
  type: types.HIDE_MODAL
});

export const clearModal = () => ({
  type: types.HIDE_MODAL
});

export const setErrorFields = (errorFields: Object) => ({
  type: types.SET_ERROR_FIELDS,
  errorFields
});

export const expandProjectNav = (projectId: string) => ({
  type: types.EXPAND_PROJECT_NAV,
  projectId
});

export const toggleProjectNav = (projectId: string) => ({
  type: types.TOGGLE_PROJECT_NAV,
  projectId
});

export const toggleArchived = () => ({
  type: types.TOGGLE_ARCHIVED
});

export const selectChildProject = (projectId: string, childId: string) => ({
  type: types.SELECT_CHILD_PROJECT,
  projectId,
  childId
});
