//@flow
import React from "react";
import styled from "styled-components";

import { spacing } from "v2/components/styles/spacing";
import { devices } from "v2/components/styles/devices";

import { Link } from "react-router-dom";
import Wordmark from "v2/components/_assets/tability_logo_full.png";

const Container = styled.div`
  width: 45rem;
  min-height: 60rem;

  @media ${devices.mobile} {
    width: 100%;
    padding: ${spacing.x4};
  }
`;

const Header = styled.header`
  img {
    height: 4rem;
    display: block;
    margin-bottom: ${spacing.x8};
  }

  h1 {
    margin-bottom: ${spacing.x4};
  }
`;

const Footer = styled.footer`
  border-top: 1px solid #d1d1d1;
  margin-top: ${spacing.x2};
  padding-top: ${spacing.x2};
  display: flex;
  justify-content: center;
  color: #6a6a6a;
  font-size: 1.2rem;

  a {
    color: #6a6a6a;
    display: block;
    margin: 0 ${spacing.x1};
  }
`;

export const HelpLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${spacing.x3} 0 ${spacing.x2} 0;
`;

type Props = {
  title: string,
  children: any
};

export default (props: Props) => (
  <Container>
    <Header>
      <Link to="/">
        <img src={Wordmark} alt="Tability" />
      </Link>
      <h1>{props.title}</h1>
    </Header>
    <main>{props.children}</main>
    <Footer>
      <a href="https://docs.tability.io/terms-of-use" target="_blank" rel="noopener noreferrer">
        Term of use
      </a>
      <a href="https://docs.tability.io/privacy-policy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
    </Footer>
  </Container>
);
