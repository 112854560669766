//@flow

export const handleChange = (component: Object, e: Object) => {
  const target = e.target;
  const value = target.type === "checkbox" ? target.checked : target.value;
  const name = target.name;

  component.setState({
    [name]: value
  });
};

export const validateEmail = (email: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const parseTargetWithFormat = (targetWithFormat: string) => {
  // Here we're parsing the targetWithFormat input to extract the format
  // and the actual target.
  const targetRegex = /^[^-0-9]*((?:-{0,1})\d+(?:,{1}\d+)*(?:\.{1}\d+){0,1}).*$/;

  const matches = targetWithFormat.match(targetRegex);

  let target, score_format;
  if (matches) {
    target = matches[1];
    score_format = matches[0].replace(target, "_target_");
    target = target.replace(",", "");
  } else {
    target = null;
    score_format = null;
  }

  return { target, score_format };
};
