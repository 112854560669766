//@flow
import React from "react";
import styled from "styled-components";

const Logo = styled.svg`
  height: 1rem;
`;
export default (props: Object) => (
  <Logo viewBox="0 0 8 14" {...props}>
    <g id="Side-navigation" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Page-View-Copy-4" transform="translate(-704.000000, -336.000000)" fill="#6A6A6A" fillRule="nonzero">
        <g id="chevronRight-copy-2" transform="translate(705.000000, 337.000000)">
          <path
            d="M-0.707106781,-0.707106781 C-0.34662282,-1.06759074 0.220608236,-1.09532028 0.612899443,-0.790295388 L0.707106781,-0.707106781 L6.70710678,5.29289322 C7.06759074,5.65337718 7.09532028,6.22060824 6.79029539,6.61289944 L6.70710678,6.70710678 L0.707106781,12.7071068 C0.316582489,13.0976311 -0.316582489,13.0976311 -0.707106781,12.7071068 C-1.06759074,12.3466228 -1.09532028,11.7793918 -0.790295388,11.3871006 L-0.707106781,11.2928932 L4.585,6 L-0.707106781,0.707106781 C-1.06759074,0.34662282 -1.09532028,-0.220608236 -0.790295388,-0.612899443 L-0.707106781,-0.707106781 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </Logo>
);
