// @flow
import { createReducer } from "state/utils";
import * as goalTypes from "state/ducks/goals/types";
import * as statusUpdateTypes from "state/ducks/statusUpdates/types";

const INITIAL_STATE = {
  isFetchingGoal: true,
  isFetchingStatusUpdates: true
};

export default createReducer(INITIAL_STATE)({
  [goalTypes.FETCH_DETAILS_FAILED]: (state, action) => ({
    ...state,
    isFetchingGoal: false
  }),
  [goalTypes.FETCH_DETAILS_COMPLETED]: state => ({
    ...state,
    isFetchingGoal: false
  }),
  [goalTypes.FETCH_DETAILS_REQUESTED]: state => ({
    ...state,
    isFetchingGoal: true
  }),
  [statusUpdateTypes.FETCH_LIST_FAILED]: (state, action) => ({
    ...state,
    isFetchingStatusUpdates: false
  }),
  [statusUpdateTypes.FETCH_LIST_COMPLETED]: state => ({
    ...state,
    isFetchingStatusUpdates: false
  }),
  [statusUpdateTypes.FETCH_LIST_REQUESTED]: state => ({
    ...state,
    isFetchingStatusUpdates: true
  })
});
