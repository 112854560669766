// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as squadTypes from "squadTypes";
import { withRouter } from "react-router";
import styled from "styled-components";

import * as projectsTypes from "state/ducks/projects/types";

import { projectsOperations } from "state/ducks/projects";
import { goalsOperations } from "state/ducks/goals";
import { sectionsOperations } from "state/ducks/sections";

// Components
import Loader from "v2/components/Loader";

import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

// Templates
import KPIsTemplate from "./_templates/kpis.tpl.json";
import OKRsTemplate from "./_templates/okrs.tpl.json";
import AARRRTemplate from "./_templates/aarrr.tpl.json";
import ProjectHealthTemplate from "./_templates/project-health.tpl.json";
import Markdown from "v2/components/Markdown";
import ButtonIcon from "v2/components/ButtonIcon";
import IconClose from "v2/components/svg/IconClose";

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 230px auto 320px;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header header header"
    "sidebar main help";
`;

const TemplateList = styled.div`
  grid-area: sidebar;
  background-color: #f2f2f2;
  padding: 20px;

  header {
    margin-top: ${spacing.x1};
    margin-bottom: ${spacing.x2};
    font-weight: 600;
  }

  li {
    margin-bottom: ${spacing.x1};
    .link-button,
    a {
      padding: 4px 8px;
      border-radius: 4px;
      display: block;
      width: 100%;
      text-align: left;
      color: #151515;
      &:hover,
      &.selected {
        background-color: rgba(15, 15, 15, 0.06);
      }
    }
  }
`;
const TemplateContent = styled.div`
  padding: 20px 0 20px 20px;
  grid-area: main;
  flex: 1;
  position: relative;

  .cover {
    grid-area: main;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0;
    z-index: -1;
    transition: all 0s ease;

    &.highlight {
      opacity: 0.9;
      z-index: 10;
      transition: all 0.2s ease;
    }
  }

  .description {
    margin: 10px 0;
  }

  div {
    box-sizing: border-box;
  }

  .template-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      margin-right: 10px;
    }
  }

  .template-meta {
    display: flex;

    h3 {
      margin-right: 10px;
    }
    .frequency {
      outline: none;
      background-color: #e9e9e9;
      border: 0;
      border-radius: 3px;
      color: #6a6a6a;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      line-height: 30px;
      height: 30px;
      padding: 0 24px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      white-space: nowrap;

      &.highlight {
        z-index: 20;
      }
    }
  }

  .template {
    padding: 20px;
    margin: 20px 0;
    border-radius: 4px;
    position: relative;

    border: 1px solid ${colors.blockBorder};
  }

  .section {
    font-size: 16px;
    font-weight: 600;
  }

  .frequency,
  .section,
  .title,
  .status,
  .target {
    cursor: pointer;
    transition: transform ease 0.2s;
  }

  .frequency.highlight,
  .section.highlight,
  .title.highlight,
  .status.highlight,
  .target.highlight {
    position: relative;
    z-index: 20;
  }

  .last {
    border-bottom: 0 !important;
    margin-bottom: 24px;
  }

  .goal,
  .goal-header {
    display: flex;
    border-bottom: 1px solid #e9e9e9;
    padding: 8px;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 24px;
    }

    .status {
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      border-radius: 4px;
      background-color: #ccd0da;
      margin-right: 10px;

      &.red {
        background-color: ${colors.red};
      }
      &.yellow {
        background-color: ${colors.yellow};
      }
      &.green {
        background-color: ${colors.green};
      }
    }

    .title {
      width: 350px;
    }
    .target {
    }
    margin: 5px 0;
  }

  .goal-header {
    color: #666;
    .title {
      width: 380px;
    }
  }
`;

const TemplateHelp = styled.div`
  padding: 20px;
  grid-area: help;
  position: relative;

  .cover {
    grid-area: help;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0;
    z-index: -1;
    transition: all 0s ease;

    &.highlight {
      z-index: 1;
      opacity: 0.9;
      transition: all 0.2s ease;
    }
  }

  strong {
    margin: 0 0 10px 0;
    display: block;
  }

  > div {
    border-radius: 4px;
    padding: ${spacing.x2};
    transition: all ease 0.3s;

    p:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .overview.highlight,
  .frequency.highlight,
  .section.highlight,
  .title.highlight,
  .status.highlight,
  .target.highlight {
    position: relative;
    z-index: 12;
    background-color: rgba(15, 15, 15, 0.06);
  }

  .frequency,
  .section,
  .title,
  .status,
  .target {
    cursor: pointer;
  }
`;

type Props = {
  fetchProjectDetails: Function,
  fetchProjectGoalList: Function,
  fetchSectionList: Function,
  resetSectionList: Function,
  updateProject: Function,
  hideForm: Function,
  project: squadTypes.Project,
  ui: Object
};

type State = {
  selectedTemplate: string,
  highlightItem: string,
  highlightCover: boolean
};

class ProjectTemplates extends Component<Props, State> {
  state = {
    selectedTemplate: "okrs",
    highlightItem: "overview",
    highlightCover: false
  };

  _highlightItem = e => {
    this.setState({
      highlightItem: e.currentTarget.dataset.item,
      highlightCover: true
    });
  };

  _unhighlightEverything = () => {
    this.setState({
      highlightItem: "overview",
      highlightCover: false
    });
  };

  selectTemplate = (e: Object) => {
    e.preventDefault();
    const templateId = e.currentTarget.dataset.template;
    this.setState({
      selectedTemplate: templateId
    });
  };

  handlePopulateProject = (e: Object) => {
    e.preventDefault();
    const {
      hideForm,
      project,
      fetchProjectDetails,
      fetchProjectGoalList,
      fetchSectionList,
      resetSectionList
    } = this.props;
    const { selectedTemplate } = this.state;
    const templateId = selectedTemplate;

    let template = "";
    switch (templateId) {
      case "okrs":
        template = JSON.stringify(OKRsTemplate);
        break;
      case "kpis":
        template = JSON.stringify(KPIsTemplate);
        break;
      case "aarrr":
        template = JSON.stringify(AARRRTemplate);
        break;
      case "project-health":
        template = JSON.stringify(ProjectHealthTemplate);
        break;
      default:
        template = "";
    }

    const params = {
      template
    };
    this.props.updateProject(project.id, params).then(action => {
      if (action.type === projectsTypes.UPDATE_COMPLETED) {
        resetSectionList();
        fetchProjectDetails(project.id);
        fetchProjectGoalList(project.id);
        fetchSectionList(project.id);

        hideForm();
      }
    });
  };

  getSelectedCss = template => {
    const { selectedTemplate } = this.state;

    if (template === selectedTemplate) {
      return "selected";
    } else {
      return "";
    }
  };

  render() {
    const { ui, hideForm } = this.props;
    const { highlightItem, highlightCover, selectedTemplate } = this.state;

    const cssOverview = highlightItem === "overview" ? "highlight" : "";
    const cssStatus = highlightItem === "status" ? "highlight" : "";
    const cssSection = highlightItem === "section" ? "highlight" : "";
    const cssTitle = highlightItem === "title" ? "highlight" : "";
    const cssTarget = highlightItem === "target" ? "highlight" : "";
    const cssCover = highlightCover ? "highlight" : "";

    let templateData = {};

    switch (selectedTemplate) {
      case "okrs":
        templateData = OKRsTemplate;
        break;
      case "aarrr":
        templateData = AARRRTemplate;
        break;
      case "project-health":
        templateData = ProjectHealthTemplate;
        break;
      default:
        templateData = OKRsTemplate;
    }

    const submitText = ui.isUpdating ? <Loader size="small" /> : "Use this template";
    let goalsCount = 0;

    return (
      <div className="modal-wrapper">
        <div className="modal-header">
          <h2>Use a templates</h2>
          <ButtonIcon onClick={hideForm}>
            <IconClose style={{ width: "1.2rem" }} />
          </ButtonIcon>
        </div>
        <div className="modal-content">
          <Container>
            <TemplateList>
              <header>Default templates</header>
              <ul>
                <li>
                  <button
                    className={`link-button ${this.getSelectedCss("okrs")}`}
                    data-template="okrs"
                    onClick={this.selectTemplate}
                  >
                    OKRs
                  </button>
                </li>
                <li>
                  <button
                    className={`link-button ${this.getSelectedCss("aarrr")}`}
                    data-template="aarrr"
                    onClick={this.selectTemplate}
                  >
                    AARRR initiatives
                  </button>
                </li>
                <li>
                  <button
                    className={`link-button ${this.getSelectedCss("project-health")}`}
                    data-template="project-health"
                    onClick={this.selectTemplate}
                  >
                    Team Health
                  </button>
                </li>
              </ul>
            </TemplateList>
            <TemplateContent>
              <div className="template-header">
                <h3>{templateData.title}</h3>
              </div>
              <div className="template">
                <div className={`cover ${cssCover}`} />
                {templateData.sections.map((section, index) => (
                  <div>
                    {templateData.sections.length > 1 && (
                      <div
                        data-item="section"
                        className={`section ${cssSection}`}
                        onMouseEnter={this._highlightItem}
                        onMouseLeave={this._unhighlightEverything}
                        key={index}
                      >
                        {section.title}
                      </div>
                    )}
                    <div className="goal-header">
                      <div className="title">Goal</div>
                      <div className="target">Target</div>
                    </div>
                    {section.goals &&
                      section.goals.map((goal, jndex) => {
                        const target = goal.target
                          ? goal.score_format.replace("_target_", parseFloat(goal.target).toLocaleString())
                          : "-";
                        let displayColor = "green";
                        if (goalsCount === 1) {
                          displayColor = "red";
                        }
                        if (goalsCount > 3) {
                          displayColor = "yellow";
                        }
                        goalsCount++;
                        return (
                          <div className="goal" key={jndex}>
                            <div
                              data-item="status"
                              className={`status ${cssStatus} ${displayColor}`}
                              onMouseEnter={this._highlightItem}
                              onMouseLeave={this._unhighlightEverything}
                            />
                            <div
                              data-item="title"
                              className={`title ${cssTitle}`}
                              onMouseEnter={this._highlightItem}
                              onMouseLeave={this._unhighlightEverything}
                            >
                              {goal.title}
                            </div>
                            <div
                              data-item="target"
                              className={`target ${cssTarget}`}
                              onMouseEnter={this._highlightItem}
                              onMouseLeave={this._unhighlightEverything}
                            >
                              {target}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ))}
              </div>
            </TemplateContent>
            <TemplateHelp>
              <div className={`cover ${cssCover}`} />
              <div data-item="overview" className={`overview ${cssOverview}`}>
                <strong>Overview</strong>
                <Markdown source={templateData.description} />
              </div>

              {templateData.section_title && (
                <div
                  data-item="section"
                  className={`section ${cssSection}`}
                  onMouseEnter={this._highlightItem}
                  onMouseLeave={this._unhighlightEverything}
                >
                  <strong>{templateData.section_title}</strong>
                  <p>{templateData.section_description}</p>
                </div>
              )}
              {templateData.goal_title && (
                <div
                  data-item="title"
                  className={`title ${cssTitle}`}
                  onMouseEnter={this._highlightItem}
                  onMouseLeave={this._unhighlightEverything}
                >
                  <strong>{templateData.goal_title}</strong>
                  <p>{templateData.goal_description}</p>
                </div>
              )}
              {templateData.target_title && (
                <div
                  data-item="target"
                  className={`target ${cssTarget}`}
                  onMouseEnter={this._highlightItem}
                  onMouseLeave={this._unhighlightEverything}
                >
                  <strong>{templateData.target_title}</strong>
                  <p>{templateData.target_description}</p>
                </div>
              )}
              {templateData.status_title && (
                <div
                  data-item="status"
                  className={`status ${cssStatus}`}
                  onMouseEnter={this._highlightItem}
                  onMouseLeave={this._unhighlightEverything}
                >
                  <strong>{templateData.status_title}</strong>
                  <p>{templateData.status_description}</p>
                </div>
              )}
            </TemplateHelp>
          </Container>
        </div>
        <div className="modal-footer">
          <button className="primary" onClick={this.handlePopulateProject}>
            {submitText}
          </button>
          <button className="secondary" onClick={this.props.hideForm}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui.projectNew
});

const mapDispatchToProps = {
  updateProject: projectsOperations.updateProject,
  fetchProjectDetails: projectsOperations.fetchProjectDetails,
  fetchProjectGoalList: goalsOperations.fetchProjectGoalList,
  fetchSectionList: sectionsOperations.fetchSectionList,
  resetSectionList: sectionsOperations.resetSectionList
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectTemplates));
