// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { sessionOperations } from "state/ducks/session";

// Components
import Loader from "v2/components/Loader";
import SessionLayout, { HelpLinks } from "v2/components/SessionLayout";

// Styles helpers
import { spacing } from "v2/components/styles/spacing";
import { colors } from "v2/components/styles/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  .button {
    img {
      width: 2rem;
      margin-right: ${spacing.x1};
    }
  }
`;

const SorryBox = styled.div`
  margin-bottom: ${spacing.x8};
  padding: ${spacing.x4};
  border-radius: 8px;
  background-color: ${colors.warningBg};
  font-size: 1.8rem;
`;

const InfoBox = styled.div`
  margin-bottom: ${spacing.x8};
  padding: ${spacing.x4};
  border-radius: 8px;
  background-color: ${colors.infoBg};
  font-size: 1.8rem;
`;

type Props = {
  unsubscribe: Function,
  location: Object,
  ui: Object
};

class Unsubscribe extends Component<Props> {
  componentDidMount() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const unsubscribeToken = searchParams.get("token");
    const unsubscribeSetting = searchParams.get("setting");
    this.props.unsubscribe(unsubscribeToken, unsubscribeSetting);
  }

  render() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const unsubscribeSetting = searchParams.get("setting");

    const setting = {
      notification_mentions_email: "mentions",
      notification_reminders_email: "check-ins reminders",
      notification_events_email: "activity notifications",
      notification_weekly_report_email: "the weekly summary report"
    };

    const settingTitle = unsubscribeSetting
      ? setting[unsubscribeSetting] || "your notifications"
      : "your notifications";
    const { ui } = this.props;
    return (
      <Container>
        <SessionLayout title={`Unsubscribing you from ${settingTitle}`}>
          {ui.isFetching && (
            <InfoBox>
              <Loader />
              Please wait while we are update your notifications.
            </InfoBox>
          )}
          {ui.isUnsubscribed && <InfoBox>{ui.message}</InfoBox>}
          {!ui.isFetching && !ui.isUnsubscribed && <SorryBox>{ui.message}</SorryBox>}
          <HelpLinks>
            <Link to="/login" className="button primary">
              Sign in
            </Link>
          </HelpLinks>
        </SessionLayout>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui.unsubscribe
});

const mapDispatchToProps = {
  unsubscribe: sessionOperations.unsubscribe
};

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
