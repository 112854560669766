// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { devices } from "v2/components/styles/devices";
import { spacing } from "./styles/spacing";

import { Link } from "react-router-dom";
import Wordmark from "v2/components/_assets/tability_logo_full.png";

const Container = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${devices.mobile} {
    width: 100%;
    padding: ${spacing.x4};
    justify-content: flex-start;
  }
`;

const Header = styled.div`
  text-align: center;
  img {
    height: 4rem;
    display: block;
    margin-bottom: ${spacing.x8};
  }

  h1 {
    margin-bottom: ${spacing.x4};
  }
`;

type Props = {
  membership: any
};

type State = {};

class Suspended extends Component<Props, State> {
  render() {
    return (
      <Container>
        <Header>
          <Link to="/">
            <img src={Wordmark} alt="Tability" />
          </Link>
          <h1>Your workspace has been suspended</h1>
        </Header>
        {["admin", "owner"].includes(this.props.membership.role) && (
          <p>
            Please check your <Link to="/settings">workspace settings</Link> to unlock your workspace.
          </p>
        )}
        {["user"].includes(this.props.membership.role) && (
          <p>
            Go back to the <a href={`https://app.${process.env.REACT_APP_DOMAIN || ""}`}>list of workspaces</a>.
          </p>
        )}
      </Container>
    );
  }
}

export default Suspended;
