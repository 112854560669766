//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as squadTypes from "squadTypes";
import { handleChange } from "v2/utils/forms";
import { goalsOperations } from "state/ducks/goals";
import { request } from "state/utils";
import { getSubdomain } from "state/utils/url";

// Components
import FormField from "v2/components/FormField";
import FormActions from "v2/components/FormActions";
import CreatableSelect from "react-select/lib/Creatable";

const Container = styled.div`
  form {
    margin: 0;
  }
`;

type Props = {
  hideTagInput: Function,
  updateGoal: Function,
  link?: squadTypes.Link,
  goal: squadTypes.Goal,
  ui: Object
};

type State = {
  selectedTags: Array<Object>,
  tagOptions: Array<Object>
};

class LinkForm extends Component<Props, State> {
  focusInput: any;

  constructor(props) {
    super(props);

    const defaultTags = props.goal.tags.map(tag => ({
      label: tag.title,
      value: tag.id
    }));
    this.state = {
      selectedTags: defaultTags,
      tagOptions: []
    };
  }

  componentDidMount() {
    const { goal } = this.props;
    this._fetchTagsForAutocomplete();

    if (!goal.tags) {
      goal.tags = [];
    }

    this.focusInput.focus();
  }

  handleChange = e => handleChange(this, e);

  // This function is going to fetch the list of tags using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  _fetchTagsForAutocomplete = params => {
    // Get the current subdomain as it'll be used in the API path
    const slug = getSubdomain() || "";
    const tagsApiURL = `/workspaces/${slug}/tags`;
    request.get(tagsApiURL, { params }).then(response => {
      const tags = response.data;
      if (tags && tags.length > 0) {
        const tagOptions = tags.map(tag => {
          return {
            value: tag.id,
            label: tag.title
          };
        });
        this.setState({ tagOptions });
      } else {
        this.setState({
          tagOptions: []
        });
      }
    });
  };

  _handleTagSelect = (option: any) => {
    if (option) {
      this.setState({ selectedTags: option });
    } else {
      this.setState({ selectedTags: [] });
    }
  };

  updateGoal = e => {
    e.preventDefault();
    const { goal } = this.props;
    const { selectedTags } = this.state;

    const tags = selectedTags.map(option => option.label);
    const tag_list = tags.join(",");

    const params = {
      tags: tag_list
    };

    return this.props.updateGoal(goal.id, params).then(() => {
      this.props.hideTagInput();
    });
  };

  // This function is used to decide if we need to show an error class for a
  // given field.

  render() {
    const { selectedTags, tagOptions } = this.state;
    return (
      <Container>
        <form onSubmit={this.updateGoal}>
          <FormField>
            <CreatableSelect
              ref={input => {
                this.focusInput = input;
              }}
              createOptionPosition="first"
              allowCreateWhileLoading={true}
              isClearable
              defaultValue={selectedTags}
              isMulti
              options={tagOptions}
              classNamePrefix="react-select"
              formatCreateLabel={value => {
                return `Add tag`;
              }}
              onChange={this._handleTagSelect}
            />
          </FormField>
          <FormActions>
            <button type="submit" className="primary auto-size">
              Update tags
            </button>
            <button className="secondary" onClick={() => this.props.hideTagInput()}>
              Cancel
            </button>
          </FormActions>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  ui: state.ui.goalLinks
});

const mapDispatchToProps = {
  updateGoal: goalsOperations.updateGoal
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkForm);
